





















































import {Component, Vue, Emit, Prop} from 'vue-property-decorator'
import { GetterNames, MutationNames } from '@/store/types'
import { Getter, Mutation } from 'vuex-class'
import DateRangePicker from "@/components/DateRangePicker.vue"
import {httpGet, httpPost, httpPostFile} from "@/util/http"
import {apiUrl, apiUrl3} from "@/domain/constants"
import * as XLSX from 'xlsx'


@Component({
  components: {
    DateRangePicker
  }
})
export default class EventsPrintModalView extends Vue {
  @Getter(GetterNames.getLoadingDataNames) private getLoadingDataNames!: any
  @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
  @Prop({type: Array, default: () => []}) private event!: any
  private isShown = false
  private fieldsName = ''

  private show(events: any, day: any, isField: boolean) {
    const eventIds: any = []
    this.isShown = false

    events.forEach((event: any) => {
      eventIds.push(event.id)
    })

    if (isField) {
      this.fetchFieldTasksExcelData(eventIds)
    } else {
      this.fetchExcelData(eventIds, day)
    }

  }

  private fetchFieldTasksExcelData(fields: any) {
    this.setLoadingDataNames('TechmapBoardOnselectDay')
    httpPostFile({
      url: `${apiUrl}/telematics/get/field/report/`,
      isSecureRequest: true,
      detailResponse: true,
      body: {
        fields
      },
      onSuccess: json => {
        if (json) {
          const url = window.URL.createObjectURL(json)
          const a = document.createElement('a')
          a.href = url
          a.download = "egistic-kz.xlsx"
          document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click()
          a.remove()
        }
      },
      onError: error => {
        console.log(error)
      },
      doFinally: () => {
        this.setLoadingDataNames('TechmapBoardOnselectDay')
      }
    })
  }

  private fetchExcelData(events: any, day?: string) {
    this.setLoadingDataNames('TechmapBoardOnselectDay')
    httpPostFile({
      url: `${apiUrl}/telematics/get/field/task/report/`,
      isSecureRequest: true,
      detailResponse: true,
      body: {
        tasks: events,
        date: day ? day : ''
      },
      onSuccess: json => {
        if (json) {
          const url = window.URL.createObjectURL(json)
          const a = document.createElement('a')
          a.href = url
          a.download = "egistic-kz.xlsx"
          document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click()
          a.remove()
        }
      },
      onError: error => {
        console.log(error)
      },
      doFinally: () => {
        this.setLoadingDataNames('TechmapBoardOnselectDay')
      }
    })
  }


  private hide() {
    this.isShown = false
  }
}
