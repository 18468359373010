













import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator'
import { Getter, Action, Mutation } from 'vuex-class'
import { GetterNames, ActionNames, MutationNames } from '@/store/types'
import { Route } from 'vue-router'
import ViewActivatorCard, { TextWithIcon } from './ViewActivatorCard.vue'
import ViewTable from './ViewTable.vue'

@Component({
    components: {
        ViewActivatorCard,
        ViewTable
    }
})
export default class ViewTableWrapper extends Vue {
    @Prop({default: null}) private data!: any
    private id = Math.random().toString(16).substring(5)
    private cardToggle = false
    private farmId = -1

    private mounted() {
      this.farmId = this.data.farmMainTable[0][0].id
    }

    private closeTable() {
        this.toggleTable(false)
    }

    private toggleTable(val: boolean) {
        this.cardToggle = val
    }

    private rerenderData(index: number) {
        const d = this.data
        this.data = []
        this.data = d
    }

    private updateFieldInfo(data: any) {
        this.$emit('saveChanges', data)
    }
}
