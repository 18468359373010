import { render, staticRenderFns } from "./NewProductModel.vue?vue&type=template&id=20f014cd&scoped=true&"
import script from "./NewProductModel.vue?vue&type=script&lang=ts&"
export * from "./NewProductModel.vue?vue&type=script&lang=ts&"
import style0 from "./NewProductModel.vue?vue&type=style&index=0&id=20f014cd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20f014cd",
  null
  
)

export default component.exports