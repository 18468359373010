export default {
    title: "Кабинетке кіру",
    register: "Немесе | Тегін тіркеліңіз",
    number: "Телефон нөмірі немесе e-mail",
    remember: "Мені есте сақтау",
    password_forgotten: "Құпия сөзді ұмыттыңыз ба?",
    email_confirm: "Поштаны растау",
    password_reset: {
        title: "Құпиясөзді қалпына келтіру",
        explanation_1: "Құпиясөзді қалпына келтіру үшін email көрсетіңіз",
        notification: "Сіздің поштаңызға  | растау сілтемесі жіберілді. Кіру үшін оған өтіңіз.",
        explanation_2: "Жаңа құпиясөз ойлап табыңыз",
        new_password: "Жаңа құпиясөз",
        password_repeat: "Құпиясөзді растау",
        success: "Құпиясөз сәтті өзгертілді!",
        message: "Жаңа құпия сөзді пайдаланып кіріңіз"
    },
    errors: {
        google: "Google account проблемасы, қайталап көріңіз",
        typo: "Логин немесе құпиясөз дұрыс енгізілмеген",
        email: "Email-a пішімі қате",
        not_found: "Email деректері бар пайдаланушы табылмады",
        server: "Сервер қатесі",
        data: "Деректер дұрыс енгізілмеді",
        password: "Пароль кемінде 6 таңбадан тұруы керек"
    }
}