

























import { Component, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { GetterNames } from '@/store/types'
import { Route } from 'vue-router'
import { httpGet } from '@/util/http'
import { baseUrl, apiUrl } from '@/domain/constants'
@Component({
  components: {
  }
})
export default class HandBookView extends Vue {
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: Route
    private currentObject: any = {
        id: 0,
        ru_name: '',
        description: '',
        infopageimage_set: []
    }
    private getImageUrl(imageUrl: any) {
        if (!imageUrl) {
            return 'https://cabinet.egistic.kz/media/front/no-image.png'
        }
        return `${baseUrl}${imageUrl}`
    }
    private mounted() {
        httpGet({
            url: `${apiUrl}/handbook/infopagenested/${this.currentRoute.params.categoryId}`,
            isSecureRequest: true,
            onSuccess: json => {
                this.currentObject = json
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                //
            }
        })
    }
    private close() {
        this.$router.push(`/handbook/${this.currentRoute.params.category}`)
    }
}
