export default {
    main: {
        services: "Біздің қызметтер: ",
        to_farm: "Шаруашылыққа өту",
        faq: {
            title: "Жиі қойылатын сұрақтар: ",
            subtitle: "Егер сізде әлі де сұрақтар қалса, сіз нөмірге қоңырау шала аласыз +7 705 739 3965."
        },
        for_pc: "Жеке компьютерлерге",
        for_mobile: "iPhone және iPad құрылғыларына",
        for_tablets: "Android телефон және планшеттарына",
        feedback: "Клиенттердің пікірлері",
        media: "Біз туралы БАҚ-та жазады:",
        mobility: "Қозғалғыштық және тиімділік. Egistic қолжетімді:"
    },
    about_us: {
        team: "Біздің команда",
        partners: "Стратегиялық әріптестік",
        support: "Көмек"
    },
    services: "Egistic келесі қызметтерді ұсынады:",
    news: {
        title: "Жаңалықтар",
        others: "Басқа жаңалықтар",
        sections: {
            all: "Барлық",
            news: "Жаңалықтар",
            promotions: "Акциялар",
            updates: "Жаңартулар"
        }
    },
    support: {
        webinars: {
            title: "Вебинарлар",
            details: "EGISTIC командасының вебинар бейнелері, ол платформаны мәселелерді шешу үшін қалай пайдалану керектігін және оның нәтижесі қандай болатынын егжей-тегжейлі көрсетеді. Болашақ вебинарлардың кестесі де бар.",
            content: {
                future: "Вебинарлар кестесі",
                past: "Вебинар жазбалары"
            }
        },
        lessons: {
            title: "Бейненұсқаулық",
            details: "Мұнда сіз және сіздің қызметкерлеріңіз үшін EGISTIC функционалдығы туралы егжей-тегжейлі нұсқауларды таба аласыз, сонымен қатар платформаның жаңа жаңартуларына шолу жасай аласыз.",
            content: {
                lessons: "Бейненұсқаулық",
                where_start: "Неден бастау керек?",
                how_manage: "Шаруашылықты қалай басқаруға болады?",
                features: "Функционал",
                all: "Барлық бейнелер"
            }
        },
        user_guide: {
            title: "Пайдаланушы нұсқаулығы",
            details: "Пайдаланушы нұсқаулығы-бұл сізге және сіздің командаңызға мәтін мен фотосуреттер түрінде ұсынылған пайдалы ресурс. Сізге ыңғайлы болу үшін нұсқаулықты PDF форматында жүктеуге болады.",
            content: {
                title: "«Egistic» сервисінің пайдаланушы нұсқаулығы"
            }
        }
    }
}