import { Module, GetterTree, ActionTree, MutationTree } from 'vuex'
import { GetterNames, ActionNames, MutationNames } from '../../types'
import { httpGet } from '@/util/http'
import { apiUrl } from '@/domain/constants'
import router from '@/router'

const states = {
    workspacePermissions: {}
}

export type IState = typeof states

const mutations: MutationTree<IState> = {
}

const actions: ActionTree<IState, any> = {
    [ActionNames.fetchPermissions]({ state, commit, dispatch }) {
        commit(MutationNames.setLoadingDataNames, 'fetchPermissions')
        httpGet({
            url: `${apiUrl}/workspace/get/permission/list/`,
            isSecureRequest: true,
            onSuccess: json => {
                if (json.hasOwnProperty('group_user_add_edit')) {
                    state.workspacePermissions = json
                }
                dispatch(ActionNames.fetchFarmsList, {reload: true})
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                commit(MutationNames.setLoadingDataNames, 'fetchPermissions')
            }
        })
    },
    [ActionNames.setPermissionList]({state, dispatch}, {permissions, workspaceToken}) {
        const tokenExpireDate = new Date()
        const tokenExpireMillis = 1800000
        tokenExpireDate.setTime(tokenExpireDate.getTime() + tokenExpireMillis)
        dispatch(ActionNames.ON_SIGN,
            {
                token: workspaceToken,
                expiresIn: tokenExpireDate.getMilliseconds()
            })
        state.workspacePermissions = permissions
        dispatch(ActionNames.fetchFarmsList, {reload: true})
        router.push({name: 'cadastresList'}).catch(e => {/**/})
    }
}

const getters: GetterTree<IState, any> = {
    [GetterNames.getPermissions]: state => {
        return state.workspacePermissions
    }
}

const workspace: Module<IState, any> = {
    state: states,
    actions,
    mutations,
    getters
}

export default workspace