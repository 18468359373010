export default {
    guide: "Жолбасшы",
    cell: {
        edit: "Ұяшықты редакциялау",
        add: "Ұяшықты қосыңыз",
        delete: "Ұяшықты жұлу",
        combine: "Ұяшықтарды біріктіру",
        split: "Ұяшықты бөлу",
        info: "Ұяшық туралы ақпарат"
    },
    vertix: {
        delete: "Жою шыңына",
        delete_several: "Бірнеше шыңдарды жою"
    },
    hole: {
        create: "Тесік жасаңыз"
    },
    farm: {
        new: "Жаңа шаруашылық"
    },
    close: "Жабу",
    broadcast: "Іздеу",
    back: "Артқа",
    search: "Іздеу",
    forward: "Алдыға",
    learn_more: "Толығырақ",
    reset: "Тастау",
    add: "Қосу",
    edit: "Редакциялау",
    change: "Өзгерту",
    settings: "Теңшелім",
    add_note: "Жазба қосу",
    add_storage: "Қойма қосу",
    add_operation: "Операцияны қосу",
    delete: "Өшіру",
    print: "Басып шығару",
    exit: "Шығу",
    tile: "Тақта",
    table: "Кесте",
    report: "Есеп беру",
    change_status: "Жұмысты «Дайын» күйіне өзгерту",
    cancel_work: "Жұмысты тоқтату",
    steps: {
        prev: "Алдыңғы",
        next: "Келесі"
    },
    download: {
        title: "Жүктеу",
        excel: "Excel жүктеу"
    },
    choose: {
        map: "Картадан таңдау"
    },
    employees: "Қызметкерлер",
    subscribe: "Жазылысты рәсімдеу",
    actions: "Әрекеттер",
    sort: "Сұрыптау",
    broadcasting: "Телематика",
    service_book: "Қызмет кітабы",
    manage_notifs: "Хабарландыруларды басқару"

}