























































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Getter, Action, Mutation } from 'vuex-class'
import { GetterNames, ActionNames, MutationNames } from '@/store/types'
import { httpGet, httpPost, httpDelete } from '@/util/http'
import { baseUrl, apiUrl } from '@/domain/constants'
import NewWorkspaceModel from './components/newWorkspaceModel.vue'
import WorkspaceInfoModel from './components/WorkspaceInfoModel.vue'
import {permissionTypes} from '@/data/rolesData'
import i18n from '@/i18n'
@Component({
    components: {
        NewWorkspaceModel,
        WorkspaceInfoModel
    }
})
export default class WorkspaceView extends Vue {
    @Action(ActionNames.setPermissionList) private setPermissionList!: any
    @Getter(GetterNames.getSidebarModel) private getSidebarModel!: any
    @Getter(GetterNames.getLoadingDataNames) private getLoadingDataNames!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    @Mutation(MutationNames.setSideboardIsShown) private setSideboardIsShown !: any
    private workspace = {
        viewType: 1,
        groupType: 1
    }
    private permissionTypes: any = permissionTypes
    private workspaceData: any = []
    private sorting = {
        dropdown: false,
        filter_string: '',
        sort_string: 'owner_workspace__name',
        order: ''
    }
    private pagination: any = {
        current: 1,
        count: 1,
        next: null,
        previous: null
    }

    get getWorkspaceData() {
        if (this.workspace.groupType === 1) {
            return this.workspaceData
        } else if (this.workspace.groupType === 2) {
            return this.workspaceData.filter((x: any) => x.is_owner)
        } else {
            return this.workspaceData.filter((x: any) => !x.is_owner)
        }
    }

    private getPaginationData() {
        const result = []
        if (this.pagination.current > 3) {
            result.push('...')
        }
        for (let i = this.pagination.current > 2 ? this.pagination.current - 2 : 1; i <= this.pagination.current + 2 && i <= this.pagination.count; i++) {
            result.push(i.toString())
        }
        if (this.pagination.count - this.pagination.current > 2 ) {
            result.push('...')
        }
        return result
    }

    private openWorkspace(id: any) {
        if (this.getLoadingDataNames('workspaceOpenWorkspace')) return
        this.setLoadingDataNames('workspaceOpenWorkspace')
        httpGet({
            url:  `${apiUrl}/workspace/${id}/`,
            isSecureRequest: true,
            onSuccess: json => {
                if (json.permission_list) {
                    this.setPermissionList({
                        permissions: json.permission_list,
                        workspaceToken: json.token
                    })
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.close()
                this.setLoadingDataNames('workspaceOpenWorkspace')
            }
        })
    }

    private getImageUrl(imageUrl: any) {
        if (!imageUrl) {
            return 'https://cabinet.egistic.kz/media/front/no-image.png'
        }
        return `${imageUrl}`
    }

    private normalizeRoles(roles: any) {
        let str = ''
        for (const role of roles) {
            const permission = this.permissionTypes.find((x: any) => x.num === role)
            if (permission) {
                if (roles.indexOf(role) === roles.length - 1) {
                    str += permission.name
                } else {
                    str += permission.name + ', '
                }
            }
        }
        return str
    }

    private mounted() {
        this.fetchWorkspaceData(1)
        this.setSideboardIsShown(true)
    }

    @Watch('sorting.order')
    private onchangeFilterOrder(val: any, old: any) {
        if (val !== old) {
            this.fetchWorkspaceData(1)
        }
    }
    @Watch('sorting.sort_string')
    private onchangeFilterType(val: any, old: any) {
        if (val !== old) {
            this.fetchWorkspaceData(1)
        }
    }
    @Watch('pagination.current')
    private onchangePage(val: any, old: any) {
        if (val !== old) {
            this.fetchWorkspaceData(this.pagination.current)
        }
    }

    @Watch('workspace.groupType')
    private onchangeGroupType(val: any, old: any) {
        if (val !== old) {
            this.fetchWorkspaceData(1)
        }
    }

    private changePage(val: any) {
        if (val === 'next') {
            if (this.pagination.next !== null) {
                this.pagination.current = this.pagination.next
            }
        } else if (val === 'prev') {
            if (this.pagination.previous !== null) {
                this.pagination.current = this.pagination.previous
            }
        } else {
            if (!isNaN(val)) {
                this.pagination.current = Number(val)
            }
        }
    }

    private fetchWorkspaceData(page: any) {
        this.setLoadingDataNames('workspaceFetchWorkspaces')
        httpPost({
            url:  `${apiUrl}/workspace/filter/?page=${page}`,
            isSecureRequest: true,
            body: {
                filter_string: this.sorting.filter_string,
                sort_string: this.sorting.order + this.sorting.sort_string,
                which_workspace: ['all', 'my', 'other'][this.workspace.groupType - 1]
            },
            onSuccess: json => {
                this.pagination.current = page
                this.pagination.count = json.count
                this.pagination.next = json.next
                this.pagination.previous = json.previous
                this.workspaceData = json.results
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('workspaceFetchWorkspaces')
            }
        })
    }

    private acceptWorkspace(val: any, id: any, isCurrent: any) {
        if (isCurrent) {
            alert(i18n.t('messages.instructions.close_n_try_again'))
            return
        }
        const q = val ? i18n.t('messages.confirm.project.accept') as string : i18n.t('messages.confirm.project.decline') as string
        if (confirm(q)) {
            this.setLoadingDataNames('workspaceAcceptWorkspace')
            httpPost({
                url: `${apiUrl}/workspace/accept/role/workspace/`,
                isSecureRequest: true,
                body: {
                    is_accept: val,
                    role_workspace: id
                },
                onSuccess: json => {
                    this.fetchWorkspaceData(1)
                },
                doFinally: () => {
                    this.setLoadingDataNames('workspaceAcceptWorkspace')
                }
            })
        }

    }

    private deleteWorkspace(val: any, isCurrent: any) {
        if (isCurrent) {
            alert(i18n.t('messages.instructions.close_n_try_again'))
            return
        }
        if (confirm(i18n.t('messages.confirm.project.delete') as string)) {
            this.setLoadingDataNames('workspaceDeleteWorkspace')
            httpDelete({
                url: `${apiUrl}/workspace/${val}/`,
                isSecureRequest: true,
                onSuccess: () => {
                    alert(i18n.t('messages.success.deleted'))
                },
                onError: error => {
                    console.log(error)
                },
                doFinally: () => {
                    this.setLoadingDataNames('workspaceDeleteWorkspace')
                    this.fetchWorkspaceData(1)
                }
            })
        }
    }

    private closeSortingDropdown() {
        this.sorting.dropdown = false
    }

    private close() {
        this.$router.push(`/cadastres`).catch(e => {/**/})
    }
}
