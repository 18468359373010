



































import { Component, Vue, Watch} from 'vue-property-decorator'
import { GetterNames, MutationNames } from '@/store/types'
import { Getter, Mutation } from 'vuex-class'
import olStyle from 'ol/style/Style'
import olFill from 'ol/style/Fill'
import olStroke from 'ol/style/Stroke'
import olCircle from 'ol/style/Circle'
import olText from 'ol/style/Text'
import olMultiPoint from 'ol/geom/MultiPoint'
import { geojson2wkt } from '@/utils/services'
import * as turf from '@turf/turf'
@Component
export default class FieldEdit extends Vue {
    @Getter(GetterNames.getMapData) private mapData !: any
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: any
    @Getter(GetterNames.getFieldFromRoute) private getFieldFromRoute!: any
    @Getter(GetterNames.getFieldEditGeom) private getFieldEditGeom!: any
    @Mutation(MutationNames.setFieldEditGeom) private setFieldEditGeom!: any
    private drawnFeatures: any = []
    private selectedErrorIndex: any = -1

    @Watch('getFieldFromRoute')
    private onloadField(val: any, old: any) {
        if (this.drawnFeatures.length === 0) {
            this.parseFieldFromRoute()
        }
        if (val && val !== old) {
            this.parseFieldFromRoute()
        }
    }

    @Watch('drawnFeatures')
    private onchanged() {
        if (this.getFieldFromRoute && this.getFieldFromRoute.geom) {
          const result = geojson2wkt(this.drawnFeatures[0].geometry)
          if (result) {
            this.setFieldEditGeom({
              fieldId: this.drawnFeatures[0].id,
              geometry: result
            })
          }
        }
    }

    private mounted() {
        this.parseFieldFromRoute()
    }

    private parseFieldFromRoute() {
        if (this.getFieldFromRoute && this.getFieldFromRoute.geom) {
            const result = geojson2wkt(this.getFieldFromRoute.geom)
            this.drawnFeatures = []
            if (result) {
                this.setFieldEditGeom({
                    fieldId: -1,
                    geometry: null,
                    cachedGeometry: result
                })
            }
            this.drawnFeatures.push({
                type: "Feature",
                id: this.getFieldFromRoute.id,
                properties: {
                    id: this.getFieldFromRoute.id
                },
                geometry: this.getFieldFromRoute.geom
            })
        }
    }

    private styleFuncFactory() {
        return (feature: any) => {
            const firstconcat = [].concat.apply([], feature.getGeometry().getCoordinates())
            const coordinates = [].concat.apply([], firstconcat)
            const area = turf.area(turf.toWgs84(turf.multiPolygon(feature.getGeometry().getCoordinates())))
            const styles = [
                new olStyle({
                    stroke: new olStroke({
                        color: '#FFC700',
                        width: 2,
                        lineDash: [8, 16]
                    }),
                    fill: new olFill({
                        color: 'rgba(255, 199, 0, 0.2)'
                    }),
                    text: new olText({
                        scale: 1.5,
                        text: `${Math.round(area / 100) / 100} га`,
                        fill: new olFill({
                            color: '#FFFFFF'
                        }),
                        stroke: new olStroke({
                            color: 'rgb(34, 34, 34)'
                        })
                    })
                }),
                new olStyle({
                    image: new olCircle({
                        radius: 5,
                        stroke: new olStroke({
                            color: '#FFFFFF',
                            width: 2
                        }),
                        fill: new olFill({
                            color: '#4B970F'
                        })
                    }),
                    geometry: new olMultiPoint(coordinates)
                })
            ]
            return styles
        }
    }

    private toggleError(index: any) {
        this.selectedErrorIndex = this.selectedErrorIndex === index ? -1 : index
    }

    private closeError(index: any) {
        this.selectedErrorIndex = -1
    }

    private removeError(index: any) {
        this.getFieldEditGeom.errorPoints.splice(index, 1)
        this.selectedErrorIndex = -1
    }
}
