













import { Component, Vue, Watch } from 'vue-property-decorator'
import { State, Getter, Action, Mutation, namespace } from 'vuex-class'
import { GetterNames, MutationNames, ActionNames } from '@/store/types'
import { Route } from 'vue-router'
import i18n from '@/i18n'
@Component
export default class SideboardBase extends Vue {
    public $refs!: {
        SideboardWrapper: any
    }
    private actualWidth: any = 400
    @Getter(GetterNames.getSideboardIsShown) private sideboardIsShown !: any
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: Route
    @Getter(GetterNames.getTelematicsInfo) private getTelematicsInfo!: any
    @Getter(GetterNames.getRegisterFarms) private getRegisterFarms!: any
    @Mutation(MutationNames.setSideboardIsShown) private setSideboardIsShown!: any
    @Mutation(MutationNames.setTelematicsInfo) private setTelematicsInfo!: any
    @Mutation(MutationNames.setRegisterFarms) private setRegisterFarms!: any
    @Getter(GetterNames.getPlanningData) private getPlanningData!: any

    private mounted() {
        this.actualWidth = this.currentContentWidth()
        document.addEventListener('click', () => {
            this.actualWidth = this.currentContentWidth()
        })
    }

    @Watch('currentRoute')
    private checkIfRouteChanged() {
        this.actualWidth = this.currentContentWidth()
        setTimeout(() => this.checkifchanged(), 100)
    }

    @Watch('sideboardIsShown')
    private checkifchanged() {
        this.actualWidth = this.currentContentWidth()
    }

    @Watch('getPlanningData.page')
    private onChangePlanningPage(newVal: any) {

        setTimeout(() => this.checkifchanged(), 1000)
    }

    private toggleSidebar() {
        if ((this.getRegisterFarms.cadastres.find((x: any) => x.editing) || this.getRegisterFarms.newField) &&
            (this.currentRoute.name === 'registercadastre' || this.currentRoute.name === 'editfarm')) {
            if (this.getRegisterFarms.editStage !== 1 && this.getRegisterFarms.editStage !== 0) {
                if (!confirm(i18n.t('messages.confirm.exit.not_saved') as string)) {
                    return
                }
            }
            if (!this.getRegisterFarms.newField) {
                this.setRegisterFarms({ addType: 0 })
            }
            this.setRegisterFarms({ editType: 'none', editStage: 0, newField: false })
            for (const [i, item] of this.getRegisterFarms.cadastres.entries()) {
                if (item.editing) {
                    this.getRegisterFarms.cadastres[i].editing = false
                }
            }
        }
        if (this.currentRoute.name === 'farmtelematics') {
            if (this.getTelematicsInfo.geozone.edit > 0) {
                this.setTelematicsInfo({ geozone: { edit: 2 } })
                return
            }
        }
        this.actualWidth = this.currentContentWidth()
        this.setSideboardIsShown(!this.sideboardIsShown)
    }

    private currentContentWidth() {
        if (this.$refs.SideboardWrapper && this.$refs.SideboardWrapper.firstChild) {
            return this.$refs.SideboardWrapper.firstChild.offsetWidth
        }
        return this.actualWidth
    }
}
