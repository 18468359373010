export default {
    title: "Хозяйство",
    farms: "Хозяйства",
    current: "Текущее хозяйство",
    list: {
        title: "Список хозяйств",
        empty: "Список хозяйств пуст"
    },
    empty: {
        header: "Еще нет хозяйств",
        text: "Создайте хозяйство чтобы анализировать данные по ним"
    },
    edit: {
        title: "Редактировать хозяйство",
        name: "Название хозяйства",
        season_not_chosen: "для полей {msg} сезон не выбран",
        cadastral_add: {
            title: "Добавить кадастр",
            cadastral_num: "Номер кадастра",
            fields_num: "Количество полей",
            add: "Добавить кадастр",
            upload: "Загрузить файл",
            farm_name_placeholder: "Введите название",
            cadastre_input_placeholder: "Введите номер кадастра",
            no_info: "Нет данных",
            hectare: "га"
        },
        field: {
            edit: "Редактировать поле",
            add: "Добавить поле",
            choose: "Выберите поле"
        }
    },
    new: {
        title: "Новое хозяйство",
        by_cadastre: {
            title: "По кадастру",
            cadastres: "Кадастры"
        },
        by_map: {
            title: "По карте",
            new: "Новое поле",
            found: "Найдено {msg} кадастров",
            instruction: "Выберите ваши кадастры на поле справа, кликая на обведенную область",
            continue_adding: "Следующий шаг"
        },
        regions: "Регионы"
    },
    location: "Локация",
    area: "Площадь",
    data: "Данные за {msg}",
    data_for: "Данные за ",
    problem_zones: "Проблемные зоны",
    conclusion: "Заключение",
    total_conclusion: "Общее заключение",
    fields: {
        title: "Поля",
        all: "Все поля",
        about: "Информация о поле",
        reproduction: "Репродукция"
    },
    rating: {
        title: "Рейтинг",
        rating_for: "Рейтинг за"
    },
    techmap: {
        title: 'Технологическая карта'
    },
    season: {
        title: "Выбор сезона",
        start: "Дата начала сезона",
        end: "Дата окончания сезона",
        apply: "Применить к полю",
        by_fields: "по полям",
        by_crops: "по культуре"
    },
    subscription: {
        title: "Подписка | (1 га = {msg})",
        amount: "Сумма",
        total: "К оплате: ",
        payment: {
            title: "Способ оплаты",
            amount: "Счет на оплату",
            card: {
                title: "Оплата картой",
                description: "Через PayBox"
            },
            invoice: {
                title: "Выставить счет на оплату",
                description: "Через кассу отделения любых банков"
            }
        },
        info: {
            title: "Что такое подписка?",
            text: "Оформляя подписку Egistic Limited вы получите следующие услуги за один посевной сезон | (на один год) ",
            services: [
                "Сервис прогнозирования урожайности",
                "Сервис обнаружения проблемных зон",
                "Сервис классификации сорняков и культур",
                "Сервис визуализации вегетационной кривой с возможностью фиксирования стадии созревания",
                "Сервис подсчета осадков и активных температур",
                "Сервис фильтрации полей с их рейтингом по основным параметрам",
                "Сервис возможности работы с оффлайн картами"
            ]
        }
    },
    telematics: {
        title: "Телематика",
        broadcast: {
            title: "Трансляция",
            watch: "Следить",
            history: "История",
            width: "Ширина охвата",
            speed: "Скорость",
            remain_fuel: "Остаток топлива"
        },
        events: {
            title: "Мероприятия",
            plan: "План на обработку гектаров",
            fact: "Реальный факт обработанной площади",
            consumption: "Расход топлива",
            date: "Дата получения последних данных",
            fuel: "Уровень топлива",
            coordinates: "Координаты",
            num_machines: "Количество агрегатов",
            status: "Статус мероприятия",
            operational: "Оперативные мероприятия",
            device: {
                title: "Техника",
                category: "Категория техники",
                min_speed: "MIN скорость(км/час)",
                max_speed: "MAX скорость(км/час)"
            },
            agregate: {
                title: "Агрегат",
                category: "Категория агрегата"
            },
            treatment_depth: "Глубина обработки(см)"
        },
        play: "Проиграть",
        pause: "Пауза",
        goto_field: "Перейти к полю",
        timeline: "Настроить таймлайн",
        overspeed: "Превышение скорости",
        average_speed: "Средняя скорость",
        stop: "Остановка техники",
        stop_reason: "Причина остановки",
        by_machine: {
            title: "по агрегату",
            on_base: "На базе"
        },
        by_field: {
            title: "по полю"
        },
        filter: {
            title: "Фильтр",
            all: "Все",
            online: "Online",
            offline: "Offline",
            within: "На базе",
            outside: "За геозоной",
            for_day: "За сутки",
            for_week: "За неделю"
        },
        agregate: {
            title: "Техника"
        }
    },
    agrochem_analys: {
        title: ["Агрохим анализ почвы", "Агрохим анализ"],
        probe: "1 проба - {msg} га",
        report: "Агрохимическая картограмма",
        upload_results: "Загрузить результаты",
        upload_excel_msg: "Для загрузки результатов агрохим анализа загрузите файл в формате Excel (.xls) для клекти, следуя образцу.",
        download_ex: "Скачать образец",
        latitude: "Широта",
        longitude: "Долгота",
        empty: {
            heading: "Еще нет результатов",
            description: "Закажите новый агрохим анализ"
        },
        non_empty: {
            company: "Название компании:",
            price: "Цена за гектар:",
            website: "Сайт:",
            contacts: "Контакты:"
        },
        get: {
            area: "Площадь отбора пробы: | Площадь:",
            sample_points: "Всего точек для пробы:",
            price: "Цена за гектар:",
            total: "Сумма:",
            number: "Номер телефона для связи",
            success: "Ваш заказ успешно выполнен | Заказ на агрохим анализ почвы на хозйство успешно выполнено. Ждите звонка от наших партнеров."
        },
        acidity: {
            title: "Степень кислотности, pH солевой выдержки",
            indicators: ["очень кислая", "сильнокислая", "среднокислая", "слабокислая", "нейтральная", "слабощелочная", "среднощелочная", "сильнощелочная", "очень сильнощелочная"]
        },
        phosphorus: {
            name: "Фосфор",
            title: "Содержание подвижного фосфора, мг/кг",
            indicators: ["оч. низкое", "низкое", "среднее", "повышенное", "высокое", "оч. высокое"]
        },
        potassium: {
            name: "Калий",
            title: "Содержание подвижного калия, мг/кг",
            indicators: ["оч. низкое", "низкое", "среднее", "повышенное", "высокое", "оч. высокое"]
        },
        nitrogen: {
            name: "Азот",
            title: "Содержание легкогидролизуемого азота, мг/кг",
            indicators: ["оч. низкое", "низкое", "среднее", "повышенное", "высокое", "оч. высокое"]
        },
        sulfur: {
            name: "Сера",
            title: "Содержание серы, мг/кг",
            indicators: ["оч. низкое", "низкое", "среднее", "повышенное", "высокое", "оч. высокое"]
        },
        humus: {
            name: "Гумус",
            title: "Содержание  гумуса, %",
            indicators: ["оч. низкое", "низкое", "среднее", "повышенное", "высокое", "оч. высокое"]
        }

    },
    delete: "Удалить хозяйство",
    delete_title: "Удаление поля",
    divide: "Разделить поле",
    settings: {
        title: "Настройки",
        change_borders: "Изменить границы",
        divide: "Разделить на поля",
        clear: "Очистить"
    }
}