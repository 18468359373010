





















































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import {httpGet, httpPost, httpPut} from '@/util/http'
import {apiUrl, apiUrl3} from '@/domain/constants'
import { GetterNames, MutationNames } from '@/store/types'
import { Getter, Mutation } from 'vuex-class'
import DatePicker from '@/components/DatePicker.vue'
import TimePicker from '@/components/TimePicker.vue'

@Component({
    components: {
        DatePicker,
        TimePicker
    }
})
export default class ChangeAgregateModal extends Vue {
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    // @Mutation(MutationNames.setTechnicEventTelematics) private setTechnicEventTelematics!: any

    @Getter(GetterNames.getTelematicsInfo) private getTelematicsInfo!: any
    // @Getter(GetterNames.getTechnicEventTelematics) private getTechnicEventTelematics!: any
    private currentType = 1
    private loadedData = 0
    private twoDrivers = true
    private isUpdate = false
    private isHistory = false
    private partsQuantity = 1
    private events = {
        origin: [],
        converted: [],
        selected: null as any,
        selectedName: '',
        onerror: ''
    }
    private trailerCategories = {
        origin: [],
        converted: [],
        selected: {
            id: ''
        },
        selectedName: ''
    }
    private trailers = {
        origin: [],
        converted: [],
        selected: {
            id: ''
        },
        selectedName: '',
        onerror: ''
    }
    private speeds = {
        maxSpeed: {
            value: null,
            onerror: ''
        },
        minSpeed: {
            value: null,
            onerror: ''
        }
    }
    private drivers = {
        origin: [],
        converted: [],
        driver: [
            {
                selected: {
                    id: ''
                },
                selectedName: '',
                onerror: '',
                startTime: {
                    selectedName: '00:00',
                    onerror: ''
                },
                endTime: {
                    selectedName: '23:59',
                    onerror: ''
                }
            },
            {
                selected: {
                    id: ''
                },
                selectedName: '',
                onerror: '',
                startTime: {
                    selectedName: '00:00',
                    onerror: ''
                },
                endTime: {
                    selectedName: '23:59',
                    onerror: ''
                }
            }
        ]
    }
    private tractor = {
        origin: [],
        converted: [],
        selected: -1,
        selected_name: '',
        selected_items: [],
        onerror: ''
    }
    private folder = {
        origin: [],
        converted: [],
        selected: null as any,
        selected_name: '',
        onerror: ''
    }
    private complexId = -1
    private geozone = true
    private isShown = false
    private currentTractor = {
        id: -1,
        name: '',
        trailer: {
            id: '',
            name: '',
            category: {
              name: ''
            }
        },
        task: {
            id: '',
            name: ''
        },
        partId: null,
        driver: {
            id: '',
            full_name: '',
            start_time: '',
            end_time: ''
        },
        folder: null,
        eventTask: null as any
    }

    private taskNameEvent = null as any
    private currentTechnicIndex = -1

    private show(type: number, tractor?: any, isHistory?: boolean, partsQuantity: number = 1) {
        // console.log(type, tractor)
        if (tractor.eventTaks !== undefined) {
            this.taskNameEvent = tractor.eventTaks.name
        }
        // console.log(this.getTelematicsInfo.tractorsData, 'getTelematicsInfo')
        this.currentTractor = tractor
        this.partsQuantity = partsQuantity
        this.loadedData = 0
        this.isHistory = false // isHistory
        this.drivers = {
            origin: [],
            converted: [],
            driver: [
                {
                    selected: {
                        id: ''
                    },
                    selectedName: '',
                    onerror: '',
                    startTime: {
                        selectedName: '',
                        onerror: ''
                    },
                    endTime: {
                        selectedName: '',
                        onerror: ''
                    }
                },
                {
                    selected: {
                        id: ''
                    },
                    selectedName: '',
                    onerror: '',
                    startTime: {
                        selectedName: '',
                        onerror: ''
                    },
                    endTime: {
                        selectedName: '',
                        onerror: ''
                    }
                }
            ]
        }
        this.events = {
            origin: [],
            converted: [],
            selected: {
                id: ''
            },
            selectedName: '',
            onerror: ''
        }
        this.trailerCategories = {
            origin: [],
            converted: [],
            selected: {
                id: ''
            },
            selectedName: ''
        }
        this.trailers = {
            origin: [],
            converted: [],
            selected: {
                id: ''
            },
            selectedName: '',
            onerror: ''
        }
        this.tractor = {
            origin: [],
            converted: [],
            selected: -1,
            selected_name: '',
            selected_items: [],
            onerror: ''
        }
        this.folder = {
            origin: [],
            converted: [],
            selected: null,
            selected_name: '',
            onerror: ''
        }
        this.speeds = {
            maxSpeed: {
                value: null,
                onerror: ''
            },
            minSpeed: {
                value: null,
                onerror: ''
            }
        }

        if (tractor && tractor.speeds) {
            this.speeds.maxSpeed.value = tractor.speeds.maxSpeed ? tractor.speeds.maxSpeed.toString() : null
            this.speeds.minSpeed.value = tractor.speeds.minSpeed ?  tractor.speeds.minSpeed.toString() : null
        }
        if (tractor && tractor.isUpdate) {
            this.isUpdate = tractor.isUpdate
        }
        if (tractor && tractor.complexId) {
            this.complexId = tractor.complexId
        }
        this.twoDrivers = true
        this.currentType = type
        this.isShown = true
        this.loadInputData()
        if (type === 3) {
            if (tractor && tractor.name) {
                this.tractor.selected_name = tractor.name
            }
            this.fetchComplexData()
        }
        if (isHistory) {
            this.isHistory = isHistory
        }
    }

    private loadInputData() {
        this.fetchAutoCategories()
        this.fetchEvents()
        this.fetchTrailers()
        this.fetchMembers()
    }

    private hide() {
        this.isShown = false
    }

    @Watch('speeds.maxSpeed.value')
    private checkForMaxNumber(newVal: any, oldVal: any) {
        if (isNaN(Number(newVal))) {
            this.speeds.maxSpeed.onerror = 'Введите число'
        } else {
            this.speeds.maxSpeed.onerror = ''
        }
    }

    @Watch('speeds.minSpeed.value')
    private checkForMinNumber(newVal: any, oldVal: any) {
        if (isNaN(Number(newVal))) {
            this.speeds.minSpeed.onerror = 'Введите число'
        } else {
            this.speeds.minSpeed.onerror = ''
        }
    }

    private onSelectEvent(item: string) {
        const event: any = this.events.origin.find((x: any) => x.name === item)
        if (event) {
            this.events.selected = event
            this.events.selectedName = event.name
        }
    }

    private onSelectTrailerCategory(item: string) {
        this.trailers.selected.id = ''
        this.trailers.selectedName = ''
        const trailerCategory = this.trailerCategories.origin.find((x: any) => x.name === item)
        const trailers: any = this.trailers.origin.filter((x: any) => x.category_name === item)
        if (trailerCategory) {
            this.trailerCategories.selected = trailerCategory
            this.trailerCategories.selectedName = item
        }
        this.trailers.converted = trailers.map((x: any) => `${x.name} (${x.coverage})`)
    }

    private onSelectTrailer(item: string) {
        const trailer = this.trailers.origin.find((x: any) => `${x.name} (${x.coverage})` === item)
        if (trailer) {
          this.trailers.selected = trailer
          this.trailers.selectedName = item
        }
    }

    private onSelectDriver(item: string, index: number) {
        const driver = this.drivers.origin.find((x: any) => x.user_fullname === item)
        if (driver) {
            this.drivers.driver[index].selected = driver
            this.drivers.driver[index].selectedName = item
        }
    }

    private onselectTractor(item: any) {
        const tractor: any = this.tractor.origin.find((x: any) => `${x.name} (${x.uid})` === item)
        if (tractor) {
            this.tractor.selected = tractor.id
            this.tractor.selected_name = item
            this.tractor.onerror = ''
        }
    }

    private onselectFolder(item: any) {
        const folder: any = this.folder.origin.find((x: any) => `${x.name} (${x.tractors_count})` === item)
        if (folder) {
            this.folder.selected = folder.id
            this.folder.selected_name = item
            this.folder.onerror = ''
        }
    }

    private fetchEvents() {
        httpGet({
            url:  `${apiUrl}/techmap/static/`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                if (json.ok) {
                    this.events.origin = json.json
                    this.events.converted = json.json.map((x: any) => x.name)
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.loadedData++
            }
        })
    }

    private fetchAutoCategories() {
        httpGet({
            url:  `${apiUrl}/techmap/get/tractors/`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                if (json.ok) {
                    this.trailerCategories.origin = json.json.trailer_category
                    this.trailerCategories.converted = json.json.trailer_category.map((x: any) => x.name)
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.loadedData++
            }
        })
    }

    private fetchTrailers() {
        this.setLoadingDataNames('fetchTrailers')
        httpGet({
            url:  `${apiUrl}/yields/trailer/`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                if (json.ok) {
                    this.trailers.origin = json.json
                    this.trailers.converted = json.json.map((x: any) => `${x.name} (${x.coverage})`)
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('fetchTrailers')
                this.loadedData++
                if (this.currentTractor && this.currentTractor.trailer) {
                    const trailer: any = this.trailers.origin.find((x: any) => x.id === this.currentTractor.trailer.id)
                    if (trailer) {
                        this.trailers.selected = trailer
                        this.trailers.selectedName = trailer.name

                        const category: any = this.trailerCategories.origin.find((x: any) => x.name === trailer.category_name)
                        if (category) {
                            this.trailerCategories.selected = category
                            this.trailerCategories.selectedName = category.name
                        }

                        const folder: any = this.folder.origin.find((x: any) => x.id === this.currentTractor.folder)
                        if (folder) {
                            this.folder.selected = folder
                            this.folder.selected_name = folder.name
                        }
                    }
                }

                if (this.currentTractor.eventTask !== null && this.currentTractor.eventTask !== undefined) {
                    const event1: any = this.events.origin.find((x: any) => x.name === this.taskNameEvent)// this.currentTractor.eventTask.id)
                    this.events.selected = event1
                    this.events.selectedName = event1.name
                }

                if (!this.currentTractor || !this.currentTractor.task) return
                // console.log(this.currentTractor)
                const event: any = this.events.origin.find((x: any) => x.name === this.currentTractor.task.name) // this.currentTractor.task.name)
                if (event) {
                    this.events.selected = event
                    this.events.selectedName = event.name
                }
                // const event: any = this.events.origin.find((x: any) => x.name === this.taskNameEvent) // this.currentTractor.task.name)
                // if (event) {
                //     this.events.selected = event
                //     this.events.selectedName = event.name
                // }
            }
        })
    }

    private fetchMembers() {
        httpGet({
            url:  `${apiUrl}/workspace/get/employee/by/workspace/`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                if (json.ok) {
                    const drivers = json.json.filter((member: any) => member.roles.includes(6))
                    this.drivers.origin = drivers
                    this.drivers.converted = drivers.map((x: any) => `${x.user_fullname}`)
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.loadedData++
                this.fetchCurrentDrivers()
            }
        })
    }

    private fetchCurrentDrivers() {
        if (this.isHistory && this.currentTractor.driver) {
            this.twoDrivers = false
            // this.drivers.driver[0].selectedName = this.currentTractor.driver.full_name
            this.drivers.driver[0].selected = this.currentTractor.driver
            this.drivers.driver[0].startTime.selectedName = this.currentTractor.driver.start_time
            this.drivers.driver[0].endTime.selectedName = this.currentTractor.driver.end_time
        } else {
            if (!this.currentTractor || !this.currentTractor.id) return
            httpGet({
                url:  `${apiUrl}/yields/tractor/${this.currentTractor.id}/shift/`,
                isSecureRequest: true,
                detailResponse: true,
                onSuccess: json => {
                    if (json.ok) {
                        if (json.json.length === 1) {
                            this.twoDrivers = false
                        }
                        json.json.forEach((driver: any, index: number) => {
                            this.drivers.driver[index].selectedName = driver.driver.full_name
                            this.drivers.driver[index].selected = driver.driver
                            this.drivers.driver[index].startTime.selectedName = driver.start_time
                            this.drivers.driver[index].endTime.selectedName = driver.end_time
                        })
                    }
                },
                onError: error => {
                    console.log(error)
                }
            })
        }
    }

    private fetchComplexData() {
        this.folder.origin = this.getTelematicsInfo.folders
        this.folder.converted = this.getTelematicsInfo.folders.map((x: any) => `${x.name} (${x.tractors_count})`)
        if (this.folder.selected) {
            const folder: any = this.folder.origin.find((x: any) => x.id === this.folder.selected)
            if (folder) {
                this.folder.selected_name = `${folder.name} (${folder.tractors_count})`
            }
        }
        this.setLoadingDataNames('newComplexModelGetData')
        httpGet({
            url: `${apiUrl}/yields/tractor/without/folder/${this.$route.params.farmId}/`,
            isSecureRequest: true,
            onSuccess: json => {
                this.tractor.origin = json.tractors
                this.tractor.converted = json.tractors.map((x: any) => `${x.name} (${x.uid})`)
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('newComplexModelGetData')
            }
        })
    }

    private updateEvent() {
        let counter = 0
        if (this.events.selectedName === '') {
            this.events.onerror = 'Выберите мероприятие'
            counter++
        } else {
            this.events.onerror = ''
        }

        if (this.speeds.maxSpeed.onerror !== '') {
            counter++
        }

        if (this.speeds.minSpeed.onerror !== '') {
            counter++
        }

        // if (this.trailers.selectedName === '' || this.trailers.selected.id === '') {
        //   this.trailers.onerror = 'Выберите агрегат'
        //   counter++
        // } else {
        //   this.trailers.onerror = ''
        // }

        if (counter === 0) {
            this.updateCurrentEvent()
        }
    }

    private updateCurrentEvent() {
        const newData: any = {}
        if (this.events.selected.id) {
            newData.task_static = this.events.selected.id
        }
        if (this.trailers.selected.id) {
            newData.trailer = this.trailers.selected.id
        }
        if (this.isHistory && this.currentTractor.partId) {
            this.setLoadingDataNames('updateCurrentEvent')
            httpPost({
                url:  `${apiUrl}/telematics/get/shift/reports/${this.currentTractor.partId}/recreate/`,
                isSecureRequest: true,
                detailResponse: true,
                body: {
                    trailer: newData
                },
                onSuccess: json => {
                    if (json.ok) {
                        this.setLoadingDataNames('updateCurrentEvent')
                        this.$alert('Агрегат успешно обнавлен', 'Сохранено', 'success')
                        this.$emit('reload', json.json)
                        this.hide()
                    } else {
                        this.setLoadingDataNames('updateCurrentEvent')
                        this.$alert('Ошибка сервера', 'Ошибка', 'error')
                    }
                },
                onError: error => {
                    console.log(error)
                }
            })
        } else {
            newData.max_speed = this.speeds.maxSpeed.value
            newData.min_speed = this.speeds.minSpeed.value

            httpPost({
                url:  `${apiUrl}/yields/tractor/${this.currentTractor.id}/trailer/add/`,
                isSecureRequest: true,
                detailResponse: true,
                body: newData,
                onSuccess: json => {
                    if (json.ok) {
                        this.$alert('Агрегат успешно обнавлен', 'Сохранено', 'success')
                        this.$emit('reload', json.json)
                        this.hide()
                    } else {
                        this.$alert('Ошибка сервера', 'Ошибка', 'error')
                    }
                },
                onError: error => {
                    console.log(error)
                }
            })
        }
    }

    private checkForError() {
        let counter = 0

        if (this.tractor.selected === null) {
            this.tractor.onerror = 'Выберите технику'
            counter++
        }
        if (this.folder.selected === null) {
            this.folder.onerror = 'Выберите группу'
            counter++
        }

        if (this.trailers.selectedName === '' || this.trailers.selected.id === '') {
            this.trailers.onerror = 'Выберите агрегат'
            counter++
        } else {
            this.trailers.onerror = ''
        }
        this.drivers.driver.forEach((driver: any, index: number) => {
            if (index === 1 && !this.twoDrivers) return

            if (!driver.selectedName) {
                this.drivers.driver[index].onerror = 'Выберите механизатора'
                counter++
            } else {
                this.drivers.driver[index].onerror = ''
            }

            const regExp = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/
            if (!driver.startTime.selectedName.match(regExp)) {
                this.drivers.driver[index].startTime.onerror = 'Введите время'
                counter++
            } else {
                this.drivers.driver[index].startTime.onerror = ''
            }

            if (!driver.endTime.selectedName.match(regExp)) {
                this.drivers.driver[index].endTime.onerror = 'Введите время'
                counter++
            } else {
                this.drivers.driver[index].endTime.onerror = ''
            }
        })
        return counter > 0
    }

    private createNewComplex() {
        if (this.checkForError()) return
        if (this.isUpdate) {
            return this.updateNewComplex()
        }
        this.updateCurrentDriver(this.drivers.driver[0], 1)

        if (this.twoDrivers) {
            this.updateCurrentDriver(this.drivers.driver[1], 2)
        }
        this.setLoadingDataNames('newFolderModelAddFolder')
        httpPost({
            url:  `${apiUrl}/yields/folder/tractor/`,
            isSecureRequest: true,
            detailResponse: true,
            body: {
                tractor: this.tractor.selected,
                trailer: this.trailers.selected.id,
                folder: this.folder.selected,
                applied_geozone: this.geozone && this.getTelematicsInfo.geozone.data ? this.getTelematicsInfo.geozone.data.id : null,
                task_static: this.events.selected.id
            },
            onSuccess: json => {
                if (json.ok) {
                    this.$alert('Комплекс успешно добавлен', 'Сохранено', 'success')
                    this.$emit('reload', {index: this.currentTractor.id, event: this.events})
                    this.hide()
                } else {
                    this.$alert('Ошибка сервера', 'Ошибка', 'error')
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('newFolderModelAddFolder')
            }
        })
    }

    private updateNewComplex() {
        if (this.checkForError()) return
        this.updateCurrentDriver(this.drivers.driver[0], 1)
        if (this.twoDrivers) {
            this.updateCurrentDriver(this.drivers.driver[1], 2)
        }
        this.setLoadingDataNames('newFolderModelAddFolder')
        httpPut({
            url:  `${apiUrl}/yields/folder/tractor/${this.complexId}`,
            isSecureRequest: true,
            detailResponse: true,
            body: {
                tractor: this.tractor.selected,
                trailer: this.trailers.selected.id,
                folder: this.folder.selected.id,
                applied_geozone: this.geozone && this.getTelematicsInfo.geozone.data ? this.getTelematicsInfo.geozone.data.id : null,
                task_static: this.events.selected.id
            },
            onSuccess: json => {
                if (json.ok) {
                    this.$alert('Комплекс успешно обновлен', 'Сохранено', 'success')
                    this.$emit('reload')
                    this.hide()
                } else {
                    this.$alert('Ошибка сервера', 'Ошибка', 'error')
                }

            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('newFolderModelAddFolder')
            }
        })
    }

    private changeDriverCount(value: boolean) {
        this.twoDrivers = value
        this.drivers.driver[1].onerror = ''
        this.drivers.driver[1].startTime.onerror = ''
        this.drivers.driver[1].endTime.onerror = ''
    }

    private updateDrivers() {
        let counter = 0
        this.drivers.driver.forEach((driver: any, index: number) => {
            if (index === 1 && !this.twoDrivers) return

            if (!driver.selectedName) {
                this.drivers.driver[index].onerror = 'Выберите механизатора'
                counter++
            } else {
                this.drivers.driver[index].onerror = ''
            }

            const regExp = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/
            if (!driver.startTime.selectedName.match(regExp)) {
                this.drivers.driver[index].startTime.onerror = 'Введите время'
                counter++
            } else {
                this.drivers.driver[index].startTime.onerror = ''
            }

            if (!driver.endTime.selectedName.match(regExp)) {
                this.drivers.driver[index].endTime.onerror = 'Введите время'
                counter++
            } else {
                this.drivers.driver[index].endTime.onerror = ''
            }
        })

        if (counter === 0) {
            if (this.twoDrivers && this.partsQuantity === 1 && this.isHistory) {
                this.divideDayToParts(this.drivers.driver)
                return
            }

            this.updateCurrentDriver(this.drivers.driver[0], 1)

            if (this.twoDrivers) {
                this.updateCurrentDriver(this.drivers.driver[1], 2)
            }
        }
    }

    private updateCurrentDriver(driver: any, shift: number) {
        let driverId = -1
        if (driver.selected.id) {
            driverId = driver.selected.id
        } else if (driver.selected.user_id) {
            driverId = driver.selected.user_id
        }
        if (this.isHistory) {
            this.setLoadingDataNames('updateCurrentDriver')
            httpPost({
            url:  `${apiUrl}/telematics/get/shift/reports/${this.currentTractor.partId}/recreate/`,
            isSecureRequest: true,
            detailResponse: true,
            body: {
                tractor_shift: {
                driver: driverId,
                start_time: driver.startTime.selectedName + ':00',
                end_time: driver.endTime.selectedName + ':00'
                }
            },
            onSuccess: json => {
                if (json.ok) {
                    this.setLoadingDataNames('updateCurrentDriver')
                    this.$alert('Смена успешно обнавлена', 'Сохранено', 'success')
                    this.$emit('reload', json.json)
                    this.hide()
                } else {
                this.setLoadingDataNames('updateCurrentDriver')
                this.$alert('Ошибка сервера', 'Ошибка', 'error')
                }
            },
            onError: error => {
                console.log(error)
            }
            })
        } else {
            let tractor = -1
            if (this.currentTractor && this.currentTractor.id) {
                tractor = this.currentTractor.id
            } else if (this.tractor.selected) {
                tractor = this.tractor.selected
            }
            httpPost({
                url:  `${apiUrl}/yields/tractor/${tractor}/shift/add/`,
                isSecureRequest: true,
                detailResponse: true,
                body: {
                    driver: driverId,
                    start_time: driver.startTime.selectedName,
                    end_time: driver.endTime.selectedName,
                    shifts: shift
                },
                onSuccess: json => {
                    if ( this.currentType !== 3) {
                        if (json.ok && shift === 1 && !this.twoDrivers || json.ok && shift === 2 && this.twoDrivers) {
                            this.$alert('Механизаторы успешно обнавлены', 'Сохранено', 'success')
                            this.$emit('reload', json.json)
                            this.hide()
                        } else {
                            this.$alert('Ошибка сервера', 'Ошибка', 'error')
                        }
                    }
                },
                onError: error => {
                    console.log(error)
                }
            })
        }
    }

    private divideDayToParts(drivers: any) {
        this.setLoadingDataNames('divideDayToParts')
        httpPost({
            url:  `${apiUrl}/telematics/get/shift/reports/recreate/new/`,
            isSecureRequest: true,
            detailResponse: true,
            body: {
                report_id: this.currentTractor.partId,
                group_user: Number(this.$route.params.farmId),
                shifts: [
                    {
                        driver: drivers[0].selected.user_id ? drivers[0].selected.user_id : drivers[0].selected.id,
                        start_time: drivers[0].startTime.selectedName,
                        end_time: drivers[0].endTime.selectedName
                    },
                    {
                        driver: drivers[1].selected.user_id ? drivers[1].selected.user_id : drivers[1].selected.id,
                        start_time: drivers[1].startTime.selectedName,
                        end_time: drivers[1].endTime.selectedName
                    }
                ]
            },
            onSuccess: json => {
                if (json.ok) {
                    console.log(json.json)
                    this.$alert('Смена успешно обнавлена', 'Сохранено', 'success')
                    this.$emit('reload', json.json)
                    this.hide()
                } else {
                    this.$alert('Ошибка сервера', 'Ошибка', 'error')
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('divideDayToParts')
            }
        })
    }

}
