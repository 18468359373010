import Vue from 'vue'
import Router from 'vue-router'

import handbookRoutes from '@/routes/handbook/router'
import homeviewRoutes from '@/routes/homeView/router'

import MainPage from '@/views/MainPage/Index.vue'
import MainContainer from '@/views/Index.vue'

Vue.use(Router)
const DEFAULT_TITLE = 'EGISTIC - система управления хозяйством для сельхозтоваропроизводителей и консультантов в области сельского хозяйства. Настало время менять систему агробизнеса.'
const router =  new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: MainContainer,
            children: [
                {
                    path: '/',
                    component: MainPage,
                    children: [
                        ...homeviewRoutes,
                        ...handbookRoutes
                    ]
                }
            ]
        },
        {
            path: '*',
            redirect: {
                name: 'cadastresList'
            }
        }
    ]

})

router.afterEach((to: any, from: any) => {
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE
    })
})

export default router