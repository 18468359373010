



























































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { httpGet } from '@/util/http'
import { apiUrl, apiUrl3 } from '@/domain/constants'
import { GetterNames, MutationNames } from '@/store/types'
import { Getter, Mutation } from 'vuex-class'
import EventView from './EventView.vue'

@Component({
    components: {
        EventView
    }
})
export default class EventsContainer extends Vue {
    @Prop({default: 0}) private fieldId!: number
    @Prop() private newTractors!: any
    @Getter(GetterNames.getCurrentRoute) private currentRoute!: any
    @Getter(GetterNames.getLoadingDataNames) private getLoadingDataNames!: any
    @Getter(GetterNames.getFarmFromRoute) private getFarmFromRoute!: any
    @Getter(GetterNames.getPermissions) private getPermissions!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    @Mutation(MutationNames.setTelematicsInfo) private setTelematicsInfo!: any
    @Mutation(MutationNames.setIsDataLoading) private setIsDataLoading !: any
    private dataLoading = false
    private events: any = []
    private newEvents: any = []
    private activitiesOpen = false
    private viewEventID = -1


    private getEventDates(startDate: any, endDate: any) {
        if (!endDate) {
          endDate = (new Date()).toLocaleDateString("sv-SE")
        }
        if (startDate && endDate) {
            if (startDate === endDate) {
                return `${startDate.substring(8, 10)}.${startDate.substring(5, 7)}.${startDate.substring(0, 4)}`
            } else {
                return `от ${startDate.substring(8, 10)}.${startDate.substring(5, 7)}.${startDate.substring(0, 4)} -` +
                    ` до ${endDate.substring(8, 10)}.${endDate.substring(5, 7)}.${endDate.substring(0, 4)}`
            }
        } else {
            return 'ДД.ММ.ГГГГ'
        }
    }


    private openFieldRoute(routeName: any, farmId?: any, fieldId?: any) {
      if (this.getFarmFromRoute) {
        const currentFarmId = `${this.$route.params.farmId}`
        const currentFieldId = this.fieldId
        this.$router.push({
          name: routeName,
          params: {
            farmId: farmId ? farmId : currentFarmId,
            subfieldId: fieldId ? String(fieldId) : String(currentFieldId)
          }
        }).catch(e => { /* */ })
      }
    }

    private mounted() {
        this.fetchEvents()
        this.setTelematicsInfo({
          by_agregate: false
        })
        if (this.$route.params.currentevent) {
          this.viewEventID = Number(this.$route.params.currentevent)
        }
    }

    private fetchEvents() {
        this.dataLoading = true
        httpGet({
            url:  `${apiUrl}/technology/fact/tasks/by/field/${this.fieldId}/`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                if (json.ok) {
                    this.events = json.json
                    this.newEvents = json.json
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.dataLoading = false
            }
        })
    }

    private toggleEventView(id: any, activities?: any) {
        if (activities === 'toggle') {
          this.activitiesOpen = !this.activitiesOpen
        } else {
          this.activitiesOpen = false
        }
        this.setTelematicsInfo({
            geozone: {
                data: null,
                view: false,
                edit: 0,
                type: 'edit'
            },
            folders: [],
            tractorsData: [],
            autoplay: false,
            tracktime: 0,
            autoTracking: {
                mainTractorID: -1,
                tracking: false
            },
            arrowsView: false,
            speedLimitsView: false,
            stopsView: false,
            fieldEvent: {
                reportType: 1,
                event: null,
                tractor: null,
                flaws: false,
                overlaps: false
            },
            controlPanelExtension: false
        })
        this.setIsDataLoading(false)
        if (this.viewEventID === id) {
            this.viewEventID = -1
        } else {
            this.viewEventID = id
        }
    }

    private openFieldTelematics(id: any) {
        this.$router.push(`/cadastres/farm/${this.getFarmFromRoute.id}/subfields/${id}/telematics`)
    }

}
