




























import { Component, Vue, Emit } from 'vue-property-decorator'
import { GetterNames } from '@/store/types'
import { Getter } from 'vuex-class'
@Component
export default class AdvertisementModel extends Vue {
    @Getter(GetterNames.getFarms) private getFarms!: any
    private isShown = false

    private show(systemType: any, id: any) {
        this.isShown = true
    }
    @Emit('confirm')
    private confirm() {
        return {}
    }
    private hide() {
        this.isShown = false
        this.confirm()
    }
}
