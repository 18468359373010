


























































import { Component, Vue } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import { GetterNames, MutationNames } from '@/store/types'
import i18n from '@/i18n'

@Component
export default class Panels extends Vue {
    @Getter(GetterNames.getRegisterFarms) private getRegisterFarms!: any
    @Mutation(MutationNames.setRegisterFarms) private setRegisterFarms!: any
    private warning = false
    private getHelpInfoText() {
        if (this.getRegisterFarms.editType === 'edit') {
            if (this.getRegisterFarms.editStage === 1) {
                return i18n.t('messages.instructions.cadastre_edit.edit.1')
            }
        }
        if (this.getRegisterFarms.editType === 'draw') {
            if (this.getRegisterFarms.editStage === 1) {
                return i18n.t('messages.instructions.cadastre_edit.draw.1')
            } else if (this.getRegisterFarms.editStage === 2) {
                return i18n.t('messages.instructions.cadastre_edit.draw.2')
            }
        }
        if (this.getRegisterFarms.editType === 'delete') {
            if (this.getRegisterFarms.editStage === 1) {
                return i18n.t('messages.instructions.cadastre_edit.delete.1')
            }
            if (this.getRegisterFarms.editStage === 2) {
                return i18n.t('messages.instructions.cadastre_edit.delete.2')
            }
        }
        if (this.getRegisterFarms.editType === 'merge') {
            if (this.getRegisterFarms.editStage === 1) {
                return i18n.t('messages.instructions.cadastre_edit.merge.1')
            }
            if (this.getRegisterFarms.editStage === 2) {
                return i18n.t('messages.instructions.cadastre_edit.merge.2')
            }
        }
        if (this.getRegisterFarms.editType === 'split') {
            if (this.getRegisterFarms.editStage === 1) {
                return i18n.t('messages.instructions.cadastre_edit.split.1')
            }
            if (this.getRegisterFarms.editStage === 2) {
                return i18n.t('messages.instructions.cadastre_edit.split.2')
            }
        }
        if (this.getRegisterFarms.editType === 'vertix') {
            if (this.getRegisterFarms.editStage === 1) {
                return i18n.t('messages.instructions.cadastre_edit.vertix.1')
            }
            if (this.getRegisterFarms.editStage === 2) {
                return i18n.t('messages.instructions.cadastre_edit.vertix.2')
            }
        }
        if (this.getRegisterFarms.editType === 'vertices') {
            if (this.getRegisterFarms.editStage === 1) {
                return i18n.t('messages.instructions.cadastre_edit.vertices.1')
            }
            if (this.getRegisterFarms.editStage === 1.5) {
                return i18n.t('messages.instructions.cadastre_edit.vertices.1_5')
            }
            if (this.getRegisterFarms.editStage === 2) {
                return i18n.t('messages.instructions.cadastre_edit.vertices.2')
            }
        }
        if (this.getRegisterFarms.editType === 'hole') {
            if (this.getRegisterFarms.editStage === 1) {
                return i18n.t('messages.instructions.cadastre_edit.hole.1')
            }
            if (this.getRegisterFarms.editStage === 1.5) {
                return i18n.t('messages.instructions.cadastre_edit.hole.1_5')
            }
            if (this.getRegisterFarms.editStage === 2) {
                return i18n.t('messages.instructions.cadastre_edit.hole.2')
            }
        }
        if (this.getRegisterFarms.editType === 'info') {
            if (this.getRegisterFarms.editStage === 1) {
                return i18n.t('messages.instructions.cadastre_edit.info.1')
            }
        }
        return 'none'
    }

    private activateButton(type: any, stage: any) {
        if (this.getRegisterFarms.newField) {
            return
        }
        if (this.getRegisterFarms.editType === type) {
            this.setRegisterFarms({editType: 'none', editStage: 0})
            this.warning = false
        } else {
            if (this.getRegisterFarms.editStage === 2) {
                this.warning = true
                return
            } else {
                this.warning = false
            }
            this.setRegisterFarms({editType: type, editStage: stage})
        }
    }

    private cancelEditing() {
        this.warning = false
        this.setRegisterFarms({editStage: 3})
    }

    private saveEditing() {
        this.warning = false
        this.setRegisterFarms({editStage: 4})
    }
}
