export default {
    main: "Main",
    home: "Home",
    about_us: "About us",
    services: "Services",
    news: "News",
    support: "Support",
    farm: "Farm",
    contacts: "Contacts",
    workspace: {
        main: "Main",
        workspace: {
            title: "Workspace",
            option: "Show all"
        },
        employees: "Employees",
        farm: "Farm",
        autopark: "Autopark",
        storage: "Storage",
        guide: "Guide",
        dispatcher: "Dispatcher",
        profile: "Profile"
    }
}