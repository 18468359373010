








































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { GetterNames, MutationNames } from '@/store/types'
import { Getter, Mutation } from 'vuex-class'
import { httpPut, httpDelete } from '@/util/http'
import { apiUrl, baseUrl } from '@/domain/constants'
import ChangeFolderModel from './ChangeFolderModel.vue'
import NewComplexModel from './NewComplexModel.vue'
import NewTractorModel from './NewTractorModel.vue'
import ChangeAgregateModal from '@/views/MainPage/HomeView/Sideboards/FarmBoard/TechMapBoard/components/ChangeAgregateModel.vue'
import i18n from '@/i18n'
@Component({
    components: {
        ChangeFolderModel,
        NewComplexModel,
        NewTractorModel,
        ChangeAgregateModal
    }
})
export default class AgroComplex extends Vue {
    @Prop({default: null}) private tractorTelematics!: any
    @Prop({default: false}) private visible!: any
    @Prop({default: null}) private geozone!: any
    @Getter(GetterNames.getTelematicsInfo) private getTelematicsInfo!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    @Mutation(MutationNames.setLoadingTractorInfo) private setLoadingTractorInfo!: any
    @Mutation(MutationNames.setTelematicsInfo) private setTelematicsInfo!: any
    private extended = false
    private allparams = false
    private activeHistory = false

    get complex() {
        if (this.tractorTelematics) return this.tractorTelematics.complex
        else return null
    }

    get speed() {
        if (this.tractorTelematics.data.length > 0) {
            const speed = this.tractorTelematics.data[this.tractorTelematics.currentIndex].speed
            return speed
        } else {
            return false
        }
    }

    get fuel() {
        if (this.tractorTelematics.data.length > 0) {
            return this.tractorTelematics.fuelData[this.tractorTelematics.currentIndex].y
        } else {
            return false
        }
    }

    get params() {
        if (this.tractorTelematics.data.length > 0) {
            return this.tractorTelematics.data[this.tractorTelematics.currentIndex].parameters
        } else {
            return false
        }
    }

    private updateCoverage(value: any) {
        this.getTelematicsInfo.tractorsData[this.tractorTelematics.index].coverage = Number(value)
    }

    private updateMinSpeed(value: any) {
        this.getTelematicsInfo.tractorsData[this.tractorTelematics.index].MIN_SPEED = Number(value)
    }

    private updateMaxSpeed(value: any) {
        this.getTelematicsInfo.tractorsData[this.tractorTelematics.index].MAX_SPEED = Number(value)
    }

    private getTrackingStatus() {
        if (this.getTelematicsInfo.autoTracking.mainTractorID === this.tractorTelematics.tractorID) {
            return this.getTelematicsInfo.autoTracking.tracking
        } else return false
    }

    private getImageUrl(imageUrl: any) {
        return `${baseUrl}${imageUrl}`
    }

    private getBroadCastStatus() {
        if (this.tractorTelematics.SOCKET_STATUS === 0) return i18n.t('headers.workspace.farm.telematics.broadcast.title')
        else if (this.tractorTelematics.SOCKET_STATUS === 1) return 'Идет cоединение'
        else if (this.tractorTelematics.SOCKET_STATUS === 2) return i18n.t('general.process.broadcasting')
        else if (this.tractorTelematics.SOCKET_STATUS === 3) return i18n.t('general.process.connection_failed')
    }

    private changeLiveBroadcast() {
        const index = this.getTelematicsInfo.tractorsData.findIndex((x: any) => x.tractorID === this.tractorTelematics.tractorID)
        if (index !== -1) {
            this.getTelematicsInfo.tractorsData.forEach((x: any) => {
                if (x.IS_HISTORY) {
                    this.getTelematicsInfo.tractorsData[x.index].clearData()
                    this.getTelematicsInfo.tractorsData[x.index].IS_HISTORY = false
                }
            })
            this.getTelematicsInfo.tractorsData[index].clearData()
            this.getTelematicsInfo.tractorsData[index].IS_LIVE = !this.tractorTelematics.IS_LIVE
        }
    }

    private changeAutoTracking() {
        if (this.tractorTelematics.tractorID === this.getTelematicsInfo.autoTracking.mainTractorID) {
            this.getTelematicsInfo.autoTracking.tracking = !this.getTelematicsInfo.autoTracking.tracking
        } else if (this.tractorTelematics.data.length > 0) {
            this.setTelematicsInfo({
                autoTracking: {
                    mainTractorID: this.tractorTelematics.tractorID,
                    tracking: true
                }
            })
        }
    }

    private changeHistoryBroadcast() {
        const index = this.getTelematicsInfo.tractorsData.findIndex((x: any) => x.tractorID === this.tractorTelematics.tractorID)
        if (index !== -1) {
           if (this.getTelematicsInfo.tractorsData[index].IS_HISTORY) {
                this.getTelematicsInfo.tractorsData[index].clearData()
                this.getTelematicsInfo.tractorsData[index].IS_HISTORY = false
                this.getTelematicsInfo.tractorsData[index].IS_LIVE = true

            } else {
                this.getTelematicsInfo.tractorsData.forEach((x: any) => {
                    if (x.IS_HISTORY) {
                        this.getTelematicsInfo.tractorsData[x.index].clearData()
                        this.getTelematicsInfo.tractorsData[x.index].IS_HISTORY = false
                        this.getTelematicsInfo.tractorsData[x.index].IS_LIVE = true
                    }
                })
                this.getTelematicsInfo.tractorsData[index].clearData()
                this.getTelematicsInfo.tractorsData[index].IS_LIVE = false

                const historyStart = this.getTelematicsInfo.historyStart
                const historyEnd = this.getTelematicsInfo.historyEnd
                let startDate: any = ''
                let endDate: any = ''
                if (historyStart && historyEnd) {
                    startDate = this.formatDate(historyStart)
                    endDate = this.formatDate(historyEnd)
                } else {
                    const today = new Date()
                    endDate = this.formatDate(today)
                    const yesterday = new Date(today.getTime())
                    yesterday.setDate(today.getDate() - 1)
                    startDate = this.formatDate(yesterday)
                }

                this.getTelematicsInfo.tractorsData[index].historyStartDate = startDate
                this.getTelematicsInfo.tractorsData[index].historyEndDate = endDate
                this.getTelematicsInfo.tractorsData[index].IS_HISTORY = true


                this.setTelematicsInfo({
                    autoTracking: {
                        mainTractorID: this.tractorTelematics.tractorID,
                        tracking: true
                    }
                })
            }
        }
    }

    private formatDate(date: any) {
        const d = new Date(date)
        let month = '' + (d.getMonth() + 1)
        let day = '' + d.getDate()
        const year = d.getFullYear()
        if (month.length < 2) {
            month = '0' + month
        }
        if (day.length < 2) {
            day = '0' + day
        }
        return [year, month, day].join('-') + ' ' + d.toLocaleTimeString('en-GB')
    }

    private applyGeozone() {
        if (this.geozone === null) {
            this.$alert('Геозона не определена', 'Отклонено', 'warning')
            return
        }
        let text = 'Вы уверены, что хотите прекратить отслеживание техники по геозоне?'
        if (this.complex.applied_geozone === null) {
            text = 'Вы уверены, что хотите отслеживать технику по геозоне?'
        }
        this.$confirm(text, 'Подтверждение', 'info').then(() => {
            this.setLoadingDataNames('AgroComplexUpdateGeozone')
            httpPut({
                url: `${apiUrl}/yields/folder/tractor/${this.complex.id}/`,
                isSecureRequest: true,
                detailResponse: true,
                body: {
                    applied_geozone: this.complex.applied_geozone ? null : this.geozone.id
                },
                onSuccess: json => {
                    if (json.ok) {
                        this.$alert('Отслеживание техники по геозоне успешно изменено', 'Сохранено', 'success')
                        const folderIndex = this.getTelematicsInfo.folders.findIndex((x: any) => x.tractors.find((y: any) => y.id === this.complex.id))
                        if (folderIndex !== -1) {
                            const tractorIndex = this.getTelematicsInfo.folders[folderIndex].tractors.findIndex((x: any) => x.id === this.complex.id)
                            this.getTelematicsInfo.folders[folderIndex].tractors[tractorIndex].applied_geozone = json.json.applied_geozone
                        }
                    } else {
                        this.$alert('Ошибка сервера', 'Ошибка', 'error')
                    }
                },
                onError: error => {
                    console.log(error)
                },
                doFinally: () => {
                    this.setLoadingDataNames('AgroComplexUpdateGeozone')
                }
            })
        }).catch(e => {/* */})
    }

    private deleteComplex() {
        this.$confirm(`Вы действительно хотите удалить этот комплекс?`, 'Подтверждение', 'info').then(() => {
            this.setLoadingDataNames('AgroComplexDeleteComplex')
            httpDelete({
                url: `${apiUrl}/yields/folder/tractor/${this.complex.id}/`,
                isSecureRequest: true,
                detailResponse: true,
                onSuccess: json => {
                    if (json.ok) {
                        this.$alert('Комплекс успешно удален', 'Удалено', 'success')
                        this.$emit('reload')
                    } else {
                        this.$alert('Ошибка сервера', 'Ошибка', 'error')
                    }
                },
                onError: error => {
                    console.log(error)
                },
                doFinally: () => {
                    this.setLoadingDataNames('AgroComplexDeleteComplex')
                }
            })
        }).catch(e => {/* */})
    }
}
