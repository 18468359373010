// field: {
//     id: number // field id
//     geometry: GeoJSON // MyltiPolygon
//     area: number // area
//     name: string //
// }
interface FarmArgs {
    id: any
    documentId: string
    location: string
    area: number
    fields: any[]
    cadastre: any
    readonly: boolean
}

export default class RegisterFarm {
    public number: string       // cadastre number in format of 01-001-012-085 xx-xxx-xxx-xxx
    public id: any              // id of cadastre for identify
    public documentId: string   // documentId of cadastre
    public location: string     // location of cadastre
    public area: number         // total area in he
    public fields: any[]        // array of fields
    public editing: boolean     // status of edit
    public cadastre: any        // initial data
    public readonly: boolean    // if readonly input
    public oldfarm: boolean     // if old farm

    constructor() {
        this.number = ''
        this.id = ''
        this.documentId = ''
        this.location = ''
        this.area = 0
        this.fields = []
        this.editing = false
        this.cadastre = null
        this.readonly = false
        this.oldfarm = false
    }

    public setData({id, documentId, location, area, fields, cadastre, readonly}: FarmArgs) {
        this.id = id
        this.documentId = documentId
        this.location = location
        this.area = area
        this.fields = fields
        this.cadastre = cadastre
        this.readonly = readonly
    }

    public clear() {
        this.id = ''
        this.documentId = ''
        this.location = ''
        this.area = 0
        this.fields = []
        this.editing = false
        this.cadastre = null
        this.readonly = false
    }
}