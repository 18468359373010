import {IState} from './'

export type State = IState

export const Action = {
}

export const Mutation = {
    setSidebarModel: 'setSidebarModel',
    changeNeedToConfirmMail: 'changeNeedToConfirmMail',
    setSignError: 'setSignError',
    setIsDataLoading: 'setIsDataLoading',
    setLoadingDataNames: 'setLoadingDataNames',
    setLoadingTractorInfo: 'setLoadingTractorInfo',
    setFullBlindLoading: 'setFullBlindLoading',
    setCurrentRoute: 'setCurrentRoute',
    setSideboardIsShown: 'SideboardIsShown',
    setNewFarmRequest: 'setNewFarmRequest',
    setNoteSupport: 'setNoteSupport',
    setMapScreen: 'setMapScreen',
    setGuideTourActivators: 'setGuideTourActivators',
    setPhotosData: 'setPhotosData',
    setErrorHandler: 'setErrorHandler',
    setFieldByAllnotes: 'setFieldByAllnotes',
    setTechnologyEvents: 'setTechnologyEvents',
    setAddAllNote: 'setAddAllNote'
}

export const Getter = {
    getSidebarModel: 'getSidebarModel',
    getCurrentRoute: 'getCurrentRoute',
    getSignViewData: 'getSignViewData',
    getSideboardIsShown: 'getSideboardIsShown',
    getIsDataLoading: 'getIsDataLoading',
    getLoadingDataNames: 'getLoadingDataNames',
    getLoadingTractorInfo: 'getLoadingTractorInfo',
    getFullBlindLoading: 'getFullBlindLoading',
    getNewFarmRequest: 'getNewFarmRequest',
    getNoteSupport: 'getNoteSupport',
    getMapScreen: 'getMapScreen',
    getGuideTourActivators: 'getGuideTourActivators',
    getPhotosData: 'getPhotosData',
    getErrorHandler: 'getErrorHandler',
    getFieldByAllnotes: 'getFieldByAllnotes',
    getTechnologyEvents: 'getTechnologyEvents',
    getAddAllNote: 'getAddAllNote'
}
