




















import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import {ActionNames, GetterNames, MutationNames} from '@/store/types'
import {Action, Getter, Mutation} from 'vuex-class'
import FieldSlider from './FieldSlider.vue'
import {httpGet} from '@/util/http'
import {apiUrl} from '@/domain/constants'
import { CultureColors } from '@/data/FieldCulture'
import i18n from '@/i18n'

@Component({
    components: {
        FieldSlider
    }
})
export default class PlanningRequests extends Vue {
    @Action(ActionNames.updateMapDataInRegion) private updateMapDataInRegion!: any
    @Getter(GetterNames.getMapData) private mapData !: any
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: any
    @Getter(GetterNames.getFarmFromRoute) private getFarmFromRoute!: any
    @Getter(GetterNames.getFarmMapControllers) private getFarmMapControllers!: any
    @Getter(GetterNames.getTaskData) private getTaskData!: any
    @Getter(GetterNames.getPlanningData) private getPlanningData!: any

    @Mutation(MutationNames.setPlanningData) private setPlanningData!: any
    @Mutation(MutationNames.setPlanningDataField) private setPlanningDataField!: any
    @Prop() private fields!: any
    @Prop() private vlView!: any

    private CultureColors: any = CultureColors
    private selectedFeatures: any[] = []
    private otherCultures: any = []
    private textScale = 1
    private boundaries: any = [0, 0, 0, 0]
    private selectedFields: any = []
    private selectedPointId = -1
    private cultureData: any = null
    private currentSeason = 2022

    private mounted() {
        this.getCultureData()
        if ( this.getPlanningData.season) {
            this.currentSeason = Number(this.getPlanningData.season)
        }
        this.setPlanningData({selectedFieldsMap: []})
    }

    @Watch('getPlanningData.season')
    private onChangePlanningSeason(newVal: any) {
        this.currentSeason = newVal
    }

    @Watch('selectedFeatures')
    private featureSelected(newArr: any) {
        if (newArr.length === 0) return
        else {
            // if (this.getPlanningData.mapSelection) {
                if (newArr[0].properties) {
                    const fieldId = newArr[0].properties.id
                    const tempArr = this.getPlanningData.selectedFields
                    const fieldData = this.getPlanningData.fields.find((x: any) => x.id === fieldId)
                    // console.log(fieldData, 'fieldData')

                    // if (fieldData && fieldData.technology_exists) {
                    //     this.$alert('Поле ' + fieldData.name + '  уже добавлено', 'Ошибка', 'warning')
                    //     return
                    // }

                    if (tempArr.includes(fieldId)) {
                        tempArr.splice(tempArr.indexOf(fieldId), 1)
                    } else {
                        tempArr.push(fieldId)
                    }
                    this.setPlanningData({selectedFields: tempArr, fieldOnMap: true})
                }
            // } else {
            //     if (newArr[0].properties) {
            //         if (newArr[0].properties.type === 'fieldPlanning') {
            //             this.selectedPointId = newArr[0].properties.pointid
            //             this.selectedFeatures = []
            //             return
            //         }
            //     }
            // }
        }
        this.selectedPointId = -1
        this.selectedFeatures = []
    }

    @Watch('getTaskData.taskId')
    private getBoundaries(newTask: any, oldTask: any) {
      if (!this.getTaskData) return
      let Xmin = Number.MAX_SAFE_INTEGER
      let Xmax = Number.MIN_SAFE_INTEGER
      let Ymin = Number.MAX_SAFE_INTEGER
      let Ymax = Number.MIN_SAFE_INTEGER

      this.getTaskData.fields.forEach((fieldData: any) => {
        const field = this.getFarmFromRoute.fields.find((x: any) => x.id.toString() === fieldData.id.toString())
        if ( field && field.geom) {
          for (const arr of field.geom.coordinates[0][0]) {
            Xmax = arr[0] > Xmax ? arr[0] : Xmax
            Xmin = arr[0] < Xmin ? arr[0] : Xmin
            Ymax = arr[1] > Ymax ? arr[1] : Ymax
            Ymin = arr[1] < Ymin ? arr[1] : Ymin
          }
        }
      })
      this.boundaries = [Xmin, Ymin, Xmax, Ymax]
      if (this.getTaskData.fields.length > 0) {
        this.updateMapDataInRegion({ boundaries: this.boundaries })
      }
    }

    @Watch('mapData.zoom')
    private zoomChanged(currentZoom: number) {
      this.textScale = this.mapZoomToTextScale(currentZoom)
    }

    private mapZoomToTextScale(currentZoom: number) {
      let maxScale = 3
      if (this.currentRoute.name === 'farm') {
        maxScale = this.getFarmMapControllers.textMaxScale
      }
      const minScale = 1
      const zoomRatio = (currentZoom - this.mapData.MIN_ZOOM - 2) / (this.mapData.MAX_ZOOM - this.mapData.MIN_ZOOM)
      return zoomRatio * (maxScale - minScale) + minScale
    }

    private getCultureColor(field: any) {
        // if (this.getPlanningData.selectedFields.includes(field.id)) return this.getColor(this.getPlanningData.culture)
        if (!this.getFarmMapControllers.fill) return '#FFFFFF00'
        if (!this.cultureData) return '#FFFFFF00'
        const currentFieldData = this.cultureData.find((x: any) => x.id === field.id)
        const currentFieldSeason = currentFieldData.crop_rotations.find((y: any, index: number) => y.crop_rotation && Number(y.x_axis_name) === Number(this.currentSeason))

        if (currentFieldSeason) {
            return this.getColor(currentFieldSeason.crop_rotation.culture) + '4D'
        }
        return 'transparent'
    }

    private getColor(parentCulture: any) { // пщеница
        const result = this.CultureColors.defined.find((x: any) => {
            const crop = x.crop.toLowerCase()
            if (parentCulture && parentCulture.toLowerCase().includes(crop)) {
                return x
            }
        })
        if (result) {
            return result.color
        }
        const result2 = this.otherCultures.find((x: any) => {
            if (x.culture && parentCulture) {
                if (x.culture.toLowerCase().includes(parentCulture.toLowerCase()) ||
                    parentCulture.toLowerCase().includes(x.culture.toLowerCase())) {
                    return x
                }
            } else if (x.culture === parentCulture || (x.culture === '' && parentCulture === null)
                || (x.culture === null && parentCulture === '')) {
                return x
            }
        })
        if (result2) {
            return this.CultureColors.others[result2.index]
        } else {
            if (this.otherCultures.length > 0) {
                const index = this.otherCultures[this.otherCultures.length - 1].index
                this.otherCultures.push({
                    culture: parentCulture,
                    index: (index + 1) % 10
                })
                return this.CultureColors.others[index + 1]
            } else {
                this.otherCultures.push({
                    culture: parentCulture,
                    index: 0
                })
                return this.CultureColors.others[0]
            }
        }
    }

    private getCultureData() {
        httpGet({
            url:  `${apiUrl}/get/map/crop_rotation/${this.$route.params.farmId}/`,
            isSecureRequest: true,
            onSuccess: json => {
                this.cultureData = json.crop_rotaions
                this.setPlanningData({culturesData: json.culture_data, cropRotations: json.crop_rotaions})
            },

            onError: error => {
                console.log(error)
            }
        })
    }

    private getFieldFillText(field: any) {
        let currentFieldSeason = null
        if (this.cultureData) {
            const currentFieldData = this.cultureData.find((x: any) => x.id === field.id)
            if (currentFieldData.crop_rotations) {
                currentFieldSeason = currentFieldData.crop_rotations.find((y: any, index: number) => y.crop_rotation && Number(y.x_axis_name) === Number(this.currentSeason))
            }
        }

        if (this.currentRoute.name === 'planning') {
            if (this.getFarmMapControllers.textType === 0) {
                return `№${field.name}\n${Math.round(field.area / 100) / 100} ${i18n.t('general.units.he')}`
            } else if (this.getFarmMapControllers.textType === 1) {
                return ''
            } else if (this.getFarmMapControllers.textType === 2) {
                return `№${field.name}`
            } else if (this.getFarmMapControllers.textType === 3) {
                return currentFieldSeason && currentFieldSeason.crop_rotation.culture ?
                    `${currentFieldSeason.crop_rotation.culture.substring(0, currentFieldSeason.crop_rotation.culture.indexOf(' '))}\n${currentFieldSeason.crop_rotation.culture.substring(currentFieldSeason.crop_rotation.culture.indexOf(' ') + 1)}` : ''
            } else if (this.getFarmMapControllers.textType === 4) {
                return `${Math.round(field.area / 100) / 100}га`
            } else {
                return ''
            }
        } else {
            return `№${field.name}\n${Math.round(field.area / 100) / 100} ${i18n.t('general.units.he')}`
        }
    }

    private getIsFieldVisible(fieldId: any) {
        if (this.currentRoute.name === 'planning') {
            return this.getFarmMapControllers.selectedFields.includes(fieldId)
        } else return true
    }

    private isSelectedField(id: any) {
        if (this.currentRoute.name === 'planning') {
            return this.getPlanningData.selectedFields.includes(id)
        } else return false
    }

    private getStrokeWidth() {
        if (this.currentRoute.name === 'planning') {
            return Number(this.getFarmMapControllers.strokeWidth)
        } else return 1
    }

    private getStrokeColor() {
        if (this.currentRoute.name === 'planning') {
            return this.getFarmMapControllers.strokeColor
        } else return '#FFFFFF'
    }

    private getTextColor() {
        // return '#000000'//'#FFFFFF'
        if (this.currentRoute.name === 'planning') {
            return this.getFarmMapControllers.textColor
        } else return '#FFFFFF'
    }

    @Watch('getFarmMapControllers.textMaxScale')
    private onchangeMaxScale(val: any, old: any) {
        if (val && val !== old) {
            this.textScale = this.mapZoomToTextScale(this.mapData.zoom)
        }
    }

    // @Watch('getPlanningData.selectedFields')
    // private selectedFieldWidth(val: any) {
    //     console.log(val)
    // }
}
