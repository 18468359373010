import { render, staticRenderFns } from "./CadastreRequests.vue?vue&type=template&id=642acb4f&scoped=true&"
import script from "./CadastreRequests.vue?vue&type=script&lang=ts&"
export * from "./CadastreRequests.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "642acb4f",
  null
  
)

export default component.exports