















































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import {httpDelete, httpPost, httpPut} from '@/util/http'
import { apiUrl, apiUrl3 } from '@/domain/constants'
import { GetterNames, MutationNames } from '@/store/types'
import { Getter, Mutation } from 'vuex-class'
import DatePicker from '@/components/DatePicker.vue'
import TimePicker from '@/components/TimePicker.vue'
import TaskModalScript from './TaskModalScript'
import Farm from '@/models/Farm'
@Component({
    components: {
        DatePicker,
        TimePicker
    }
})
export default class CultureTaskModal extends Vue {
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    @Getter(GetterNames.getFarmFromRoute) private getFarmFromRoute!: Farm
    private isShown = false
    private isUpdate = false
    private event = new TaskModalScript()
    private taskId = -1

    private show(taskId: number, taskName: string, taskCulture: string) {
        // console.log(taskCulture, 'taskCulture')
        this.isShown = true
        this.isUpdate = false
        this.taskId = taskId

        this.event = new TaskModalScript()
        const farmId = Number(this.$route.params.farmId) || -1
        this.event.fetchData(taskId, taskName, taskCulture, farmId)
        const totalArea = this.getFarmFromRoute.fields.find((x: any) => x.id === this.$route.params.subfieldId)
        this.event.totalAreaOfFields = (totalArea.area / 10000).toFixed(2)
        // this.event.totalAreaOfFields = (this.getFarmFromRoute.fields.find((x: any) => x.id === parseInt(this.$route.params.subfieldId)).area / 10000).toFixed(2)
    }

    private updateEvent() {
        if (this.event.checkForErrors()) return
        const putBody: any = this.event.GetPostBody()
        const updateUrl = `${apiUrl3}/techmap/by/culture/${this.taskId}/data/create/`
        this.setLoadingDataNames('NewEventModalUpdateEvent')
        httpPost({
            url: updateUrl,
            isSecureRequest: true,
            detailResponse: true,
            body: putBody,
            onSuccess: json => {
                if (json.ok) {
                    this.$alert('Данные успешно обновлены', 'Обновлено', 'success')
                    this.$emit('reload')
                    this.hide()
                } else {
                    this.$alert(`${JSON.stringify(json.json)}`, 'Ошибка', 'error')
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('NewEventModalUpdateEvent')
            }
        })
    }

    // private deleteEventParam(paramType: string, paramId: number) {
    //     console.log(this.taskId, paramType, paramId)
    //     if (!paramId) return
    //     const deleteUrl = `${apiUrl3}/techmap/by/culture/${this.taskId}/${paramType}/${paramId}/delete/`
    //     this.setLoadingDataNames('deleteEventParam')
    //     httpDelete({
    //         url: deleteUrl,
    //         isSecureRequest: true,
    //         detailResponse: true,
    //         onSuccess: json => {
    //             if (json.ok) {
    //                 // this.$alert('Данные успешно удалены', 'Удалено', 'success')
    //             } else {
    //                 // this.$alert(`${JSON.stringify(json.json)}`, 'Ошибка', 'error')
    //             }
    //         },
    //         onError: error => {
    //             console.log(error)
    //         },
    //         doFinally: () => {
    //             this.setLoadingDataNames('deleteEventParam')
    //         }
    //     })
    // }

    private deleteEventParam(paramType: string, paramId: number) {
        if (!paramId) return
        const deleteUrl = `${apiUrl}/technology/task/${paramType}/${paramId}/`
        this.setLoadingDataNames('deleteEventParam')
        httpDelete({
            url: deleteUrl,
            isSecureRequest: true,
            onSuccess: json => {
                this.$alert('Данные успешно удалены', 'Удалено', 'success')
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('deleteEventParam')
            }
        })
    }

    private hide() {
        this.isShown = false
    }
}
