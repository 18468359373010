























































































































import { Vue, Component } from "vue-property-decorator"
import TimePicker from '@/components/TimePicker.vue'
import {httpGet, httpPost} from "@/util/http"
import {apiUrl} from "@/domain/constants"
import {Mutation} from "vuex-class"
import {MutationNames} from "@/store/types"
@Component({
    components: {
        TimePicker
    }
})
export default class PlanningAddTechnic extends Vue {
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    private isShown = false
    private twoDrivers = false

    private agregates: any = null

    private trailers = {
        selected: new Array(),
        origin: new Array(),
        converted: new Array(),
        categories: new Array(),
        categoryTrailers: new Array()
    }

    private technology: any =  {
        id: '',
        tractor: '',
        trailer: '',
        trailerId: -1,
        trailerName: '',
        trailerCategory: '',
        trailerCategoryId: -1,
        performance: '',
        performance_measure: '',
        fuel_consumption_rate_for_ha: '',
        required_volume: '',
        cost: '',
        average_speed: '',
        required_hours: '',

        drivers: [
            {
                driver: null,
                start_time: '',
                end_time: '',
                shift: 1,
                salary_rate_for_ha: '',
                cost: ''
            },
            {
                driver: '',
                start_time: '',
                end_time: '',
                shift: 2,
                salary_rate_for_ha: '',
                cost: ''
            }
        ]
    }
    private cultureId: any = -1
    private area: any = 0

    private mounted() {
        this.getAgregates()
    }

    private onSelectTrailerCategory(item: any) {
        const trailer = this.trailers.origin.find((x: any) => x.category_name === item)
        if (trailer) {
            this.technology.trailerCategory = item
        }
    }

    private onSelectTrailer(item: any) {
        console.log(this.agregates.map((x: any) => x.name))
        console.log(this.trailers)
        const trailer = this.trailers.origin.find((x: any) => `${x.name} (${x.coverage})` === item)
        console.log(trailer)
        if (trailer) {
            this.technology.trailerName = item
            this.technology.trailerId = trailer.id
            this.technology.trailerCategoryId = trailer.category
        }
    }

    private getRequiredFuelVolume() {
        let cost = 0
        cost = Math.floor(Number(this.area) * Number(this.technology.fuel_consumption_rate_for_ha))
        this.technology.required_volume = cost || 0
        return cost.toString()
    }

    private getPerformanceCost() {
        let cost = 0
        cost = this.technology.required_volume * Number(this.technology.cost)
        this.technology.performance_measure = cost
        return cost.toString()
    }

    private getAgregates() {
        this.setLoadingDataNames('modelGetCars')
        httpGet({
            url:  `${apiUrl}/yields/tractor/`,
            isSecureRequest: true,
            onSuccess: json => {
                this.agregates = json
                // this.getFoldersData()
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('modelGetCars')
                this.getTrailers()
            }
        })
    }

    private getTrailers() {
        httpGet({
            url:  `${apiUrl}/yields/trailer/`,
            isSecureRequest: true,
            onSuccess: json => {
                this.trailers.origin = json
                this.trailers.converted = json.map((x: any) => `${x.name} (${x.coverage})`)
                this.trailers.categoryTrailers = json.map((x: any) => `${x.name} (${x.coverage})`)
                this.trailers.categories = [...new Set(json.map((x: any) => x.category_name))]
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                //
            }
        })
    }

    private checkForError() {
        let counter = 0
        if (this.technology.trailerCategory === '') {
            Vue.alert('', 'Выберите Категорию агрегата', 'warning')
            counter++
        }
        if (this.technology.trailerName === '') {
            Vue.alert('', 'Выберите Название агрегата', 'warning')
            counter++
        }
        if (this.technology.average_speed === '') {
            Vue.alert('', 'Выберите Cреднию скорость', 'warning')
            counter++
        }
        if (this.technology.cost === '') {
            Vue.alert('', 'Выберите Стоимость', 'warning')
            counter++
        }
        if (this.technology.fuel_consumption_rate_for_ha === '') {
            Vue.alert('', 'Выберите Норму расхода топлива', 'warning')
            counter++
        }
        return counter === 0
    }

    private addTechnic() {
        if (!this.checkForError()) return
        const drivers = [{
            driver: '',
            start_time: this.technology.drivers[0].start_time,
            end_time: this.technology.drivers[0].end_time,
            shift: 1,
            salary_rate_for_ha: this.technology.drivers[0].salary_rate_for_ha,
            cost: 0
        }]
        if (this.twoDrivers) {
            drivers.push({
                driver: '',
                start_time: this.technology.drivers[1].start_time,
                end_time: this.technology.drivers[1].end_time,
                shift: 2,
                salary_rate_for_ha: this.technology.drivers[1].salary_rate_for_ha,
                cost: 0
            })
        }
        httpPost({
            url:  `${apiUrl}/technology/task/tractor/create/`,
            isSecureRequest: true,
            detailResponse: true,
            body: {
                tractor: {
                    plan_task: this.cultureId,
                    tractors: [this.technology.trailerCategoryId],
                    trailer: this.technology.trailerId,
                    average_speed: Number(this.technology.average_speed),
                    fuel_consumption_rate_for_ha: this.technology.fuel_consumption_rate_for_ha || 0,
                    required_volume: this.technology.required_volume || 0,
                    cost: this.technology.performance_measure || 0,
                    fuel_price: this.technology.cost || 0
                },
                drivers
            },
            onSuccess: json => {
                if (json.ok) {
                    console.log(json)
                    // this.savedTractorData = true
                    // tractor.saved = true
                    // tractor.id = json.json.tractor.id
                    // this.currentTractorIndex = newIndex
                    // const newDate = json.json.calculate.end_date
                    // if (newDate) {
                    //     const date = new Date(newDate)
                    //     this.technologyDetails.end_date = date.toLocaleDateString('ru')
                    // }
                    Vue.alert('', 'Вы успешно добавили технику', 'success')
                } else {
                    this.$alert('Ошибка сервера', 'Ошибка', 'error')
                }
            },
            onError: error => {
                console.log(error)
            }
        })
    }

    private show(id: any, area: any) {
        this.cultureId = id
        this.area = area
        this.isShown = true
        console.log(id)
    }

    private hide() {
        this.isShown = false
    }
}
