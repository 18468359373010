


































import {Component, Vue, Watch} from 'vue-property-decorator'
import {Getter, Mutation} from 'vuex-class'
import {GetterNames, MutationNames} from '@/store/types'
import {httpGet} from '@/util/http'
import Farm from '@/models/Farm'
import i18n from '@/i18n'
import {apiUrl, apiUrl2, baseUrl} from '@/domain/constants'
import {Route} from 'vue-router'
import NoteWindow from "@/views/MainPage/HomeView/Sideboards/FarmBoard/NotesBoard/NoteWindow.vue"
import NewNoteAlert from "@/views/MainPage/HomeView/Sideboards/FarmBoard/SubfieldBoard/NoteBoard/NewNoteAlert.vue"

@Component({
    components: {
        NoteWindow,
        NewNoteAlert
    }
})
export default class AllNotes extends Vue {
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: Route
    @Getter(GetterNames.getFarmFromRoute) private getFarmFromRoute!: Farm
    @Getter(GetterNames.getLastNewNotePoint) private getLastNewNotePoint!: any
    @Getter(GetterNames.getAddAllNote) private getAddAllNote!: any
    @Getter(GetterNames.getSubfieldNoteData) private getSubfieldNoteData!: any

    @Mutation(MutationNames.setSubfieldNoteData) private setSubfieldNoteData!: any
    @Mutation(MutationNames.setAddAllNote) private setAddAllNote!: any
    private searchText = ''
    private notes: any = []

    private newNotesList: any = []

    private mounted() {
        this.getNotesList()
    }

    private addNotes() {
        if (this.getAddAllNote) {
            this.setAddAllNote(false)
        } else return this.setAddAllNote(true)
    }

    @Watch('getLastNewNotePoint')
    private setNoteModalActive() {
        if (this.getLastNewNotePoint.length !== 0) {
            (this.$refs.newNoteAlert as any).show()
        }
    }

    private getFilteredNotes() {
        const searchValue = this.searchText.trim()
        const searchResult = []
        for (const note of this.newNotesList) {
            if (note.text.toLowerCase().includes(searchValue.toLowerCase())) {
                searchResult.push(note)
            } else if (this.getDatetoString(note.created_at).toString().toLowerCase().includes(searchValue.toLowerCase())) {
                searchResult.push(note)
            }
        }
        return searchResult
    }

    private getDatetoString(date: any) {
        if (date) {
            const months = [ i18n.t('general.dates.months[0]') as string, i18n.t('general.dates.months[1]') as string, i18n.t('general.dates.months[2]') as string, i18n.t('general.dates.months[3]') as string, i18n.t('general.dates.months[4]') as string, i18n.t('general.dates.months[5]') as string, i18n.t('general.dates.months[6]') as string, i18n.t('general.dates.months[7]') as string, i18n.t('general.dates.months[8]') as string, i18n.t('general.dates.months[9]') as string, i18n.t('general.dates.months[10]') as string, i18n.t('general.dates.months[11]') as string]
            return date.substring(8, 10) + ' ' + months[Number(date.substring(5, 7)) - 1] + ', ' + date.substring(11, 16)
        }
        return i18n.t('general.dates.format_date')
    }

    private getNotesList() {
        httpGet({
            url:  `${apiUrl2}/notes/by_group_user/${this.currentRoute.params.farmId}/`,
            isSecureRequest: true,
            onSuccess: json => {
                if (json) {
                    this.notes = json
                    this.setSubfieldNoteData({
                        fieldId: 0,
                        currentNoteId: null,
                        data: json.reverse()
                    })
                    this.listGetSubfieldNoteData()
                }
            },
            onError: error => {
                console.log(error)
            }
        })
    }

    private listGetSubfieldNoteData() {
        let imageObj: any = []
        let yValue: any = null
        return this.getSubfieldNoteData.data.forEach((x: any) => {
            x.points.forEach((y: any) => {
                yValue = y
                if (x.images.length > 0) {
                    imageObj = x.images[0].point !== null ? x.images.find((image: any) => image.point.coordinates[0] === y.coordinates[0] && image.point.coordinates[1] === y.coordinates[1]) : ''
                }
            })
            this.newNotesList.push({
                coordinates: yValue.coordinates,
                problem: x.problem,
                image: imageObj.image_squeezed ? imageObj.image_squeezed : imageObj.image,
                images: x.images,
                created_at: x.created_at,
                divided_cadastre_user: x.divided_cadastre_user,
                employee_count: x.employee_count,
                employees_name: x.employees_name,
                fields: x.fields,
                id: x.id,
                is_my: x.is_my,
                is_replied: x.is_replied,
                name: x.name,
                owner_full_name: x.owner_full_name,
                round_date: x.round_date,
                text: x.text,
                idNotes: ''
            })
        })
    }

    private close() {
        this.$router.push(`/cadastres/farm/${this.getFarmFromRoute.id}`)
    }
}
