





















































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { httpPost, httpPut } from '@/util/http'
import { apiUrl3 } from '@/domain/constants'
import { GetterNames, MutationNames } from '@/store/types'
import { Getter, Mutation } from 'vuex-class'
import DatePicker from '@/components/DatePicker.vue'
import TimePicker from '@/components/TimePicker.vue'
import NewEventScript from './NewEventScript'

@Component({
    components: {
        DatePicker,
        TimePicker
    }
})
export default class NewEventModal extends Vue {
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    private isShown = false
    private isUpdate = false
    private event = new NewEventScript()
    private searchField = ''

    private getFieldsList() {
      const searchValue = this.searchField.replace('№', '').trim()
      const searchResult = []
      for (const field of this.event.fields.origin) {
        if (field.name.toLowerCase().includes(searchValue.toLowerCase()) ) {
          searchResult.push(field)
        }
      }

      return searchResult
    }

    private show(techmapId: number, isTemplate: boolean, isField: boolean) {
        this.isShown = true
        this.isUpdate = false
        this.fetchData(techmapId, isTemplate, isField, null)
    }

    private fetchData(techmapId: number, isTemplate: boolean, isField: boolean, event: any) {
        this.event = new NewEventScript()
        const farmId = Number(this.$route.params.farmId) || -1
        const fieldId = Number(this.$route.params.subfieldId) || -1
        this.event.fetchData(techmapId, isTemplate, isField, farmId, fieldId, this.isUpdate, event)
    }

    private addEvent() {
        const el1: any = this.$refs.startDateModel
        const el2: any = this.$refs.endDateModel
        if (el1) {
            this.event.START_DATE = el1.getDate()
        }
        if (el2) {
            this.event.END_DATE = el2.getDate()
        }
        if (this.event.checkForErrors()) return
        const postBody = this.event.GetPostBody()
        this.setLoadingDataNames('NewEventModalAddEvent')
        httpPost({
            url: `${apiUrl3}/techmap/task/`,
            isSecureRequest: true,
            detailResponse: true,
            body: postBody,
            onSuccess: json => {
                if (json.ok) {
                    this.$alert('Мероприятие успешно добавлено', 'Добавлено', 'success')
                    this.$emit('reload')
                    this.hide()
                } else {
                    this.$alert(`${JSON.stringify(json.json)}`, 'Ошибка', 'error')
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('NewEventModalAddEvent')
            }
        })
    }

    private update(event: any, techmapId: number, isTemplate: boolean, isField: any) {
        this.isShown = true
        this.isUpdate = true
        this.fetchData(techmapId, isTemplate, isField, event)
    }

    private updateEvent() {
        const el1: any = this.$refs.startDateModel
        const el2: any = this.$refs.endDateModel
        if (el1) {
            this.event.START_DATE = el1.getDate()
        }
        if (el2) {
            this.event.END_DATE = el2.getDate()
        }
        if (this.event.checkForErrors()) return
        const putBody: any = this.event.GetPostBody()
        let updateUrl = `${apiUrl3}/techmap/task/${this.event.UPDATE_EVENT.id}/`
        if (this.event.IS_FIELD) {
            updateUrl = `${apiUrl3}/techmap/task/field/${this.event.UPDATE_EVENT.id}/`
        }
        this.setLoadingDataNames('NewEventModalUpdateEvent')
        httpPut({
            url: updateUrl,
            isSecureRequest: true,
            detailResponse: true,
            body: putBody,
            onSuccess: json => {
                if (json.ok) {
                    this.$alert('Мероприятие успешно обновлено', 'Обновлено', 'success')
                    this.$emit('reload')
                    this.hide()
                } else {
                    this.$alert(`${JSON.stringify(json.json)}`, 'Ошибка', 'error')
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('NewEventModalUpdateEvent')
            }
        })
    }

    private hide() {
        this.isShown = false
    }
}
