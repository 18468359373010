


























import { Component, Vue, Prop} from 'vue-property-decorator'
import { Action, Mutation } from 'vuex-class'
import { ActionNames, MutationNames } from '@/store/types'
import NewSeasonModel from './NewSeasonModel.vue'
import i18n from '@/i18n'
@Component({
    components: {
        NewSeasonModel
    }
})
export default class SeasonModel extends Vue {
    @Prop({default: []}) private seasons!: any
    @Action(ActionNames.reloadCurrentFarm) private reloadCurrentFarm!: any
    @Action(ActionNames.changeFarmSeason) private changeFarmSeason!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    private seasonDropdown = false

    private reloadFarm(season: any) {
        this.reloadCurrentFarm()
    }

    private getCurrentSeason() {
        const season = this.seasons.find((x: any) => x.is_current)
        if (season) {
            return i18n.t('headers.workspace.fields.analytics.cur_season')  + season.name
        } else {
            return i18n.t('headers.workspace.fields.analytics.no_season')
        }
    }

    private normalizeDate(date: string) {
        return date.substring(8, 10) + '.' + date.substring(5, 7) + '.' + date.substring(0, 4)
    }

    private changeCurrentSeason(season: any) {
        if (!season.is_current) {
            this.changeFarmSeason(season.id)
        }
        this.seasonDropdown = false
    }

    private closeSeasonDropdown() {
        this.seasonDropdown = false
    }
}
