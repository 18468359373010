import * as turf from '@turf/turf'

interface FarmArgs {
    id: number
    name: string
    fieldCount: number
    area: number
    oblast: string
    region: string
    color: string
}

export default class Farm {
    public id: number
    public name: string
    public ratingDate: string
    public isPaid: any
    public oblast: string
    public region: string
    public boundaries: number[]
    public center: number[]
    public area: number
    public fieldCount: number
    public fields: any[]
    public cadastres: any[]
    public seasons: any[]
    public fullData: boolean
    public color: string

    constructor({id, name, fieldCount, area, oblast, region, color}: FarmArgs) {
        this.id = id
        this.name = name
        this.ratingDate = ''
        this.isPaid = null
        this.oblast = oblast
        this.region = region
        this.center = []
        this.boundaries = []
        this.area = area
        this.fieldCount = fieldCount
        this.fields = []
        this.cadastres = []
        this.seasons = []
        this.fullData = false
        this.color = color
    }

    public setProperties() {
        let Xmin = Number.MAX_SAFE_INTEGER
        let Xmax = Number.MIN_SAFE_INTEGER
        let Ymin = Number.MAX_SAFE_INTEGER
        let Ymax = Number.MIN_SAFE_INTEGER
        for (const field of this.fields) {
            const bbox = turf.bbox(turf.multiPolygon(field.geom.coordinates))
            if (bbox) {
                Xmax = bbox[2] > Xmax ? bbox[2] : Xmax
                Xmin = bbox[0] < Xmin ? bbox[0] : Xmin
                Ymax = bbox[3] > Ymax ? bbox[3] : Ymax
                Ymin = bbox[1] < Ymin ? bbox[1] : Ymin
            }
        }
        this.boundaries = [Xmin, Ymin, Xmax, Ymax]
        this.center = [(Xmax + Xmin) / 2, (Ymax + Ymin) / 2]
        let area = 0
        for (const field of this.fields) {
            area += field.area
        }
        this.area = area
        this.fieldCount = this.fields.length
        this.fullData = true
    }

    public setFields(fields: any) {
        this.fields = fields
    }

    public getArea() {
        return (this.area / 10000).toFixed(2)
    }

    public getRegionName() {
        if (!this.region && !this.oblast) {
            return 'Нет данных'
        } else {
            return (this.oblast ? this.oblast : '') + (this.region ? (', ' + this.region) : '')
        }
    }

    public getFieldById(id: string) {
        if (this.fields) {
            return this.fields.find(x => x.id === parseInt(id, 10))
        }
        return false
    }

    public getFieldBoundaries(field: any) {
        let Xmin = Number.MAX_SAFE_INTEGER
        let Xmax = Number.MIN_SAFE_INTEGER
        let Ymin = Number.MAX_SAFE_INTEGER
        let Ymax = Number.MIN_SAFE_INTEGER
        for (const arr of field.geom.coordinates[0][0]) {
            Xmax = arr[0] > Xmax ? arr[0] : Xmax
            Xmin = arr[0] < Xmin ? arr[0] : Xmin
            Ymax = arr[1] > Ymax ? arr[1] : Ymax
            Ymin = arr[1] < Ymin ? arr[1] : Ymin
        }
        return [Xmax, Xmin, Ymax, Ymin]
    }

    public getCenter(fieldId: any) {
        const field = this.getFieldById(fieldId)
        const boundaries = this.getFieldBoundaries(field)
        return [ (boundaries[0] + boundaries[1]) / 2, (boundaries[2] + boundaries[3]) / 2 ]
    }

    public getZoom(fieldId: any) {
        const field = this.getFieldById(fieldId)
        const boundaries = this.getFieldBoundaries(field)
        const dividend1 = 2 * Math.PI * 6378137 * 0.9 * window.innerHeight
        const dividend2 = 2 * Math.PI * 6378137 * 0.8 * window.innerWidth
        const divisor1 = boundaries[2] - boundaries[3]
        const divisor2 = boundaries[0] - boundaries[1]
        return Math.log(Math.min((dividend1 / divisor1), (dividend2 / divisor2))) / Math.log(2) - 8
    }

    public getNextFieldId(id: string) {
        if (this.fields) {
            const currentIndex = this.fields.findIndex(x => x.id === parseInt(id, 10))
            const nextIndex = currentIndex < this.fields.length - 1 ? currentIndex + 1 : 0
            return this.fields[nextIndex].id
        }
        return -1
    }

    public getPrevFieldId(id: string) {
        if (this.fields) {
            const currentIndex = this.fields.findIndex(x => x.id === parseInt(id, 10))
            const prevIndex = currentIndex > 0 ? currentIndex - 1 : this.fields.length - 1
            return this.fields[prevIndex].id
        }
        return -1
    }
}