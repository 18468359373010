






































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import { GetterNames, MutationNames } from '@/store/types'
import '@splidejs/splide/dist/css/themes/splide-default.min.css'
import { httpGet, httpPost } from '@/util/http'
import { apiUrl } from '@/domain/constants'
@Component
export default class FieldIndicatorsSlider extends Vue {
    public $refs!: {
        indicatorsSlide: any
    }
    @Getter(GetterNames.getFarmFromRoute) private getFarmFromRoute!: any
    @Getter(GetterNames.getSideboardIsShown) private sideboardIsShown !: any
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: any
    @Getter(GetterNames.getCurrentFarmIndicators) private getCurrentFarmIndicators!: any
    @Mutation(MutationNames.setCurrentFarmIndicators) private setCurrentFarmIndicators!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    private indicatorsData = {
        rewind : false,
        autoWidth: true,
        pagination: false,
        gap: '8px',
        perMove: 2,
        focus: 'center'
    }

    private normalizeDate(date: any) {
        if (date) {
            return date.substring(8, 10) + '.' + date.substring(5, 7)
        } else {
            return ''
        }
    }

    private setIndicatorData(val: any, date: any, index: any) {
        if (this.getCurrentFarmIndicators.selectedDate === date) {
            this.getPngData(val, '')
        } else {
            this.getPngData(val, date)
            if (this.$refs.indicatorsSlide) {
                this.$refs.indicatorsSlide.$data.splide.go(index)
            }
        }
    }

    private mounted() {
        this.getIndicatorDates(this.getFarmFromRoute.id, null)
    }

    private getPngData(val: any, date: any) {
        this.setCurrentFarmIndicators({data: [], selectedDate: date})
        this.setLoadingDataNames(`FarmIndicatorsSliderGetPngData${val}-${date}`)
        httpGet({
            url: `${apiUrl}/all/images/${val}${date ? `?date=${date}` : ''}`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                if (json.ok) {
                    this.setCurrentFarmIndicators({data: json.json})
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames(`FarmIndicatorsSliderGetPngData${val}-${date}`)
            }
        })
    }

    @Watch('getFarmFromRoute.id')
    private getIndicatorDates(val: any, old: any) {
        this.setCurrentFarmIndicators({farmId: null, dates: [], data: [], selectedDate: null})
        if (val && val !== old) {
            this.setLoadingDataNames(`FarmIndicatorsSliderGetDates${val}`)
            httpPost({
                url:  `${apiUrl}/rating/get/static/`,
                isSecureRequest: true,
                detailResponse: true,
                body: {
                    group_user: val
                },
                onSuccess: json => {
                    if (json.ok) {
                        if (json.json.dates.length > 0) {
                            const result = json.json.dates.reverse()
                            this.setCurrentFarmIndicators({
                                farmId: val,
                                dates: result,
                                data: []
                            })
                            this.getPngData(val, null)
                        } else {
                            this.setCurrentFarmIndicators({farmId: val})
                        }
                    }
                },
                onError: error => {
                    console.log(error)
                },
                doFinally: () => {
                    this.setLoadingDataNames(`FarmIndicatorsSliderGetDates${val}`)
                }
            })
        }
    }
}
