import i18n from '@/i18n'
export const clgreenGistogram = [
    {
        color: '#d7191c',
        from: -Infinity,
        to: -1
    },
    {
        color: '#e13e2d',
        from: -1,
        to: -0.5
    },
    {
        color: '#ea633e',
        from: -0.5,
        to: 0
    },
    {
        color: '#f48950',
        from: 0,
        to: 0.5
    },
    {
        color: '#fdae61',
        from: 0.5,
        to: 1
    },
    {
        color: '#fec379',
        from: 1,
        to: 1.5
    },
    {
        color: '#fed791',
        from: 1.5,
        to: 2
    },
    {
        color: '#ffeba8',
        from: 2,
        to: 2.5
    },
    {
        color: '#ffffc0',
        from: 2.5,
        to: 3
    },
    {
        color: '#e9f6ab',
        from: 3,
        to: 3.5
    },
    {
        color: '#d3ec95',
        from: 3.5,
        to: 4
    },
    {
        color: '#bce380',
        from: 4,
        to: 4.5
    },
    {
        color: '#a6d96a',
        from: 4.5,
        to: 5
    },
    {
        color: '#83c960',
        from: 5,
        to: 5.5
    },
    {
        color: '#60b855',
        from: 5.5,
        to: 6
    },
    {
        color: '#3da74b',
        from: 6,
        to: 6.5
    },
    {
        color: '#1a9641',
        from: 6.5,
        to: 7
    },
    {
        color: '#1a9641',
        from: 7,
        to: 100
    }
]

export const ndviGistogram = [
    {
        color: '#d7191c',
        from: -1,
        to: 0
    },
    {
        color: '#df382a',
        from: 0,
        to: 0.033
    },
    {
        color: '#e75839',
        from: 0.033,
        to: 0.066
    },
    {
        color: '#ef7747',
        from: 0.066,
        to: 0.1
    },
    {
        color: '#f79756',
        from: 0.1,
        to: 0.133
    },
    {
        color: '#feb266',
        from: 0.133,
        to: 0.166
    },
    {
        color: '#fec47a',
        from: 0.166,
        to: 0.2
    },
    {
        color: '#fed58e',
        from: 0.2,
        to: 0.25
    },
    {
        color: '#ffe6a2',
        from: 0.25,
        to: 0.3
    },
    {
        color: '#fff7b6',
        from: 0.3,
        to: 0.35
    },
    {
        color: '#f6fbb7',
        from: 0.35,
        to: 0.4
    },
    {
        color: '#e3f3a5',
        from: 0.4,
        to: 0.45
    },
    {
        color: '#d0eb93',
        from: 0.45,
        to: 0.5
    },
    {
        color: '#bee381',
        from: 0.5,
        to: 0.55
    },
    {
        color: '#abdb6e',
        from: 0.55,
        to: 0.6
    },
    {
        color: '#90cf63',
        from: 0.6,
        to: 0.65
    },
    {
        color: '#72c15b',
        from: 0.65,
        to: 0.7
    },
    {
        color: '#55b252',
        from: 0.7,
        to: 0.8
    },
    {
        color: '#37a449',
        from: 0.8,
        to: 0.9
    },
    {
        color: '#1a9641',
        from: 0.9,
        to: 1
    }
]

export const ndviContrastedGistogram = [
    {
        color: '#999999',
        from: -1,
        to: 0
    },
    {
        color: '#5d006f',
        from: 0,
        to: 0.017
    },
    {
        color: '#6a006b',
        from: 0.017,
        to: 0.034
    },
    {
        color: '#790067',
        from: 0.034,
        to: 0.05
    },
    {
        color: '#880062',
        from: 0.050,
        to: 0.067
    },
    {
        color: '#97005d',
        from: 0.067,
        to: 0.084
    },
    {
        color: '#ad0059',
        from: 0.084,
        to: 0.101
    },
    {
        color: '#c60054',
        from: 0.101,
        to: 0.118
    },
    {
        color: '#e0004e',
        from: 0.118,
        to: 0.135
    },
    {
        color: '#f90049',
        from: 0.1358,
        to: 0.152
    },
    {
        color: '#ff063f',
        from: 0.152,
        to: 0.169
    },
    {
        color: '#ff0f32',
        from: 0.169,
        to: 0.186
    },
    {
        color: '#ff1824',
        from: 0.186,
        to: 0.203
    },
    {
        color: '#ff2116',
        from: 0.203,
        to: 0.22
    },
    {
        color: '#ff4010',
        from: 0.222,
        to: 0.236
    },
    {
        color: '#ff740b',
        from: 0.236,
        to: 0.253
    },
    {
        color: '#ffa806',
        from: 0.253,
        to: 0.270
    },
    {
        color: '#ffdc02',
        from: 0.2705,
        to: 0.287
    },
    {
        color: '#e7ea00',
        from: 0.287,
        to: 0.304
    },
    {
        color: '#b0d700',
        from: 0.304,
        to: 0.321
    },
    {
        color: '#78c300',
        from: 0.321,
        to: 0.338
    },
    {
        color: '#41b000',
        from: 0.338,
        to: 0.355
    },
    {
        color: '#22a200',
        from: 0.355,
        to: 0.371
    },
    {
        color: '#219700',
        from: 0.371,
        to: 0.388
    },
    {
        color: '#1f8d00',
        from: 0.388,
        to: 0.405
    },
    {
        color: '#1d8300',
        from: 0.405,
        to: 0.422
    },
    {
        color: '#1c7b00',
        from: 0.422,
        to: 0.439
    },
    {
        color: '#1a7400',
        from: 0.439,
        to: 0.456
    },
    {
        color: '#186e00',
        from: 0.456,
        to: 0.473
    },
    {
        color: '#166800',
        from: 0.473,
        to: 0.49
    },
    {
        color: '#136202',
        from: 0.49,
        to: 0.507
    },
    {
        color: '#0e5d17',
        from: 0.507,
        to: 0.524
    },
    {
        color: '#09572c',
        from: 0.524,
        to: 0.540
    },
    {
        color: '#045141',
        from: 0.540,
        to: 0.557
    },
    {
        color: '#004c53',
        from: 0.557,
        to: 0.574
    }
]

export const ndmiGistogram = [
    {
        color: '#e8f6c6',
        from: -Infinity,
        to: -0.875
    },
    {
        color: '#d0edc0',
        from: -0.875,
        to: -0.75
    },
    {
        color: '#b9e4ba',
        from: -0.75,
        to: -0.625
    },
    {
        color: '#a1dab4',
        from: -0.625,
        to: -0.5
    },
    {
        color: '#89d1b8',
        from: -0.5,
        to: -0.375
    },
    {
        color: '#71c8bc',
        from: -0.375,
        to: -0.25
    },
    {
        color: '#59bfc0',
        from: -0.25,
        to: -0.125
    },
    {
        color: '#41b6c4',
        from: -0.125,
        to: 0
    },
    {
        color: '#3ba8c1',
        from: 0,
        to: 0.125
    },
    {
        color: '#369bbe',
        from: 0.125,
        to: 0.25
    },
    {
        color: '#318dbb',
        from: 0.25,
        to: 0.375
    },
    {
        color: '#2c7fb8',
        from: 0.375,
        to: 0.5
    },
    {
        color: '#2a6caf',
        from: 0.5,
        to: 0.625
    },
    {
        color: '#2859a6',
        from: 0.625,
        to: 0.75
    },
    {
        color: '#2859a6',
        from: 0.75,
        to: 0.875
    },
    {
        color: '#253494',
        from: 0.875,
        to: 1
    }
]

export const ndviRanges = [
    {
        from: -1,
        to: 0.025,
        text: i18n.t('data.gistogram.ndvi[0]')
    },
    {
        from: 0.025,
        to: 0.2,
        text: i18n.t('data.gistogram.ndvi[1]')
    },
    {
        from: 0.2,
        to: 0.5,
        text: i18n.t('data.gistogram.ndvi[2]')
    },
    {
        from: 0.5,
        to: 0.7,
        text: i18n.t('data.gistogram.ndvi[3]')
    },
    {
        from: 0.7,
        to: 1,
        text: i18n.t('data.gistogram.ndvi[4]')
    }
]

export const ndmiRanges = [
    {
        text: i18n.t('data.gistogram.ndmi[0]'),
        from: -0.1,
        to: -0.625
    },
    {
        text: i18n.t('data.gistogram.ndmi[1]'),
        from: -0.625,
        to: -0.5
    },
    {
        text: i18n.t('data.gistogram.ndmi[2]'),
        from: -0.5,
        to: -0.375
    },
    {
        text: i18n.t('data.gistogram.ndmi[3]'),
        from: -0.375,
        to: -0.25
    },
    {
        text: i18n.t('data.gistogram.ndmi[4]'),
        from: -0.25,
        to: 0.125
    },
    {
        text: i18n.t('data.gistogram.ndmi[5]'),
        from: 0.125,
        to: 0.25
    },
    {
        text: i18n.t('data.gistogram.ndmi[6]'),
        from: 0.25,
        to: 0.375
    },
    {
        text: i18n.t('data.gistogram.ndmi[7]'),
        from: 0.375,
        to: 0.5
    },
    {
        text: i18n.t('data.gistogram.ndmi[8]'),
        from: 0.5,
        to: 0.75
    },
    {
        text: i18n.t('data.gistogram.ndmi[9]'),
        from: 0.75,
        to: 1
    }
]

export const temperatureRanges = [
    {
        color: '#b53c59',
        value: 50
    },
    {
        color: '#d5453c',
        value: 45
    },
    {
        color: '#e26a23',
        value: 40
    },
    {
        color: '#f1780c',
        value: 36
    },
    {
        color: '#ff8100',
        value: 32
    },
    {
        color: '#ffab00',
        value: 28
    },
    {
        color: '#ffc900',
        value: 24
    },
    {
        color: '#ffdd00',
        value: 21
    },
    {
        color: '#ffed00',
        value: 18
    },
    {
        color: '#f3fb02',
        value: 15
    },
    {
        color: '#c8f550',
        value: 12
    },
    {
        color: '#a0eb5f',
        value: 9
    },
    {
        color: '#8cdc78',
        value: 6
    },
    {
        color: '#78d2aa',
        value: 3
    },
    {
        color: '#28c8c8',
        value: 0
    },
    {
        color: '#00bed2',
        value: -3
    },
    {
        color: '#00b2d7',
        value: -6
    },
    {
        color: '#00a6dc',
        value: -9
    },
    {
        color: '#009ee1',
        value: -12
    },
    {
        color: '#0091e6',
        value: -15
    },
    {
        color: '#0382e6',
        value: -18
    },
    {
        color: '#0c78e1',
        value: -21
    },
    {
        color: '#136ec8',
        value: -24
    },
    {
        color: '#1a64b4',
        value: -28
    },
    {
        color: '#1f5aaa',
        value: -32
    },
    {
        color: '#2350a0',
        value: -36
    },
    {
        color: '#264696',
        value: -40
    },
    {
        color: '#273c8c',
        value: -45
    },
    {
        color: '#283282',
        value: -50
    }
]

export const precipitationRanges = [
    {
        color: '#646464',
        value: 50
    },
    {
        color: '#ff00a1',
        value: 20
    },
    {
        color: '#ff0000',
        value: 10
    },
    {
        color: '#ff6500',
        value: 7
    },
    {
        color: '#ffa100',
        value: 5
    },
    {
        color: '#ffd200',
        value: 2
    },
    {
        color: '#ffff00',
        value: 1
    },
    {
        color: '#cfff00',
        value: 0.70
    },
    {
        color: '#8fff00',
        value: 0.50
    },
    {
        color: '#01ff00',
        value: 0.35
    },
    {
        color: '#3167ff',
        value: 0.25
    },
    {
        color: '#5d8bff',
        value: 0.20
    },
    {
        color: '#89afff',
        value: 0.17
    },
    {
        color: '#c7b7ef',
        value: 0.14
    },
    {
        color: '#d9cdf7',
        value: 0.11
    },
    {
        color: '#c8c878',
        value: 0.08
    }
]

export const cloudsRanges = [
    {
        color: '#ffffff',
        value: 100
    },
    {
        color: '#f3f3f3',
        value: 90
    },
    {
        color: '#e7e7e7',
        value: 80
    },
    {
        color: '#dadada',
        value: 70
    },
    {
        color: '#cccdce',
        value: 60
    },
    {
        color: '#b5bdc7',
        value: 50
    },
    {
        color: '#9eadbe',
        value: 40
    },
    {
        color: '#8a9fb9',
        value: 30
    },
    {
        color: '#748faf',
        value: 20
    },
    {
        color: '#6384aa',
        value: 10
    }
]

export const pressureRanges = [
    {
        color: '#2f0708',
        value: 790
    },
    {
        color: '#6d1b32',
        value: 785
    },
    {
        color: '#9d2c59',
        value: 778
    },
    {
        color: '#c8496d',
        value: 772
    },
    {
        color: '#d58749',
        value: 766
    },
    {
        color: '#d6b63d',
        value: 760
    },
    {
        color: '#c2d33f',
        value: 754
    },
    {
        color: '#6bc153',
        value: 748
    },
    {
        color: '#50ad83',
        value: 742
    },
    {
        color: '#4379b7',
        value: 736
    },
    {
        color: '#5751a2',
        value: 730
    },
    {
        color: '#271c65',
        value: 723
    },
    {
        color: '#9c129c',
        value: 717
    },
    {
        color: '#ff33ff',
        value: 711
    },
    {
        color: '#eeeeee',
        value: 705
    }
]