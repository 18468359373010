import Vue from 'vue'
import {moduleTypes, permissionModules} from '@/data/rolesData'
import { httpPost } from '@/util/http'
import { apiUrl } from '@/domain/constants'
import i18n from '@/i18n'
export default class NewMemberScript {
    public IS_FAKE = false

    public email = {
        selectedName: '',
        onerror: ''
    }
    public username = {
        selectedName: '',
        onerror: ''
    }
    public FIRST_NAME = {
        selectedName: '',
        onerror: ''
    }
    public LAST_NAME = {
        selectedName: '',
        onerror: ''
    }
    public EXIST_ACCOUNT = false
    public roles: any = {
        selected: [],
        selectedNames: [],
        onerror: ''
    }
    public farms: any = {
        origin: [],
        converted: [],
        selected: []
    }
    public selectedFarms: any = []
    public permissions: any = {
        group_user_add_edit: false,
        group_user_delete: false,
        group_user_make_payment: false,
        group_user_create_season: false,
        group_user_kletka_rating: false,
        group_user_agro_analys: false,
        group_user_telematica: false,
        kletka_passport: false,
        kletka_delete: false,
        kletka_open_history: false,
        kletka_add_history: false,
        kletka_results: false,
        kletka_yield_prediction: false,
        kletka_open_notice: false,
        kletka_add_edit_delete_notice: false,
        kletka_analytics: false,
        task_open: false,
        task_add_edit_delete: false,
        task_is_done: false,
        employee_open: false,
        employee_add_edit: false,
        autopark_open: false,
        autopark_add_edit_delete: false,
        autopark_open_service_book: false,
        autopark_add_edit_delete_service_operation: false,
        storage_open: false,
        storage_add_edit_delete: false,
        storage_open_remain: false,
        storage_edit_remain: false,
        storage_add_operation: false,
        storage_all_operations: false,
        profile_my_order: false,
        notification_manage: false
    }
    public allFieldsIsFullUsername: any = false
    private allFieldsIsFull: any = false

    public toggleIsFake() {
        this.IS_FAKE = !this.IS_FAKE
        this.email.selectedName = ''
    }

    public setSelectedPermission(permission: any) {
        if (this.roles.selected.includes(permission.num)) {
            const numIndex = this.roles.selected.findIndex((x: number) => x === permission.num)
            const nameIndex = this.roles.selectedNames.findIndex((x: string) => x === permission.name)
            if (numIndex !== -1) this.roles.selected.splice(numIndex, 1)
            if (nameIndex !== -1) this.roles.selectedNames.splice(nameIndex, 1)
        } else {
            this.roles.selected.push(permission.num)
            this.roles.selectedNames.push(permission.name)
        }
        this.setSelectedPermissionModules()
    }

    public setSelectedPermissionModules() {
        for (const module in this.permissions) {
            if (module) {
                this.permissions[module] = false
            }
        }
        for (const role of this.roles.selected) {
            const permission = permissionModules.find((x: any) => x.num === role)
            if (permission) {
                for (const module of permission.modules) {
                    this.permissions[module] = true
                }
            }
        }
    }

    public onSelectFarm(item: string, index: number) {
        if (this.farms.selected[index]) {
            const farm = this.farms.origin.find((x: any) => x.name === item)
            if (farm) {
                this.farms.selected[index].id = farm.id
                this.farms.selected[index].name = farm.name
                this.farms.selected[index].fields.origin = farm.divideds
                this.farms.selected[index].fields.selected = farm.divideds.map((x: any) => x.id)
                this.farms.selected[index].fields.selectedNames = farm.divideds.map((x: any) => `№${x.name}`)
            }
        }
    }

    public selectAllFields(index: number) {
        if (this.farms.selected[index]) {
            if (this.farms.selected[index].fields.selected.length === this.farms.selected[index].fields.origin.length) {
                this.farms.selected[index].fields.selected = []
                this.farms.selected[index].fields.selectedNames = []
            } else {
                this.farms.selected[index].fields.selected = this.farms.selected[index].fields.origin.map((x: any) => x.id)
                this.farms.selected[index].fields.selectedNames = this.farms.selected[index].fields.origin.map((x: any) => `№${x.name}`)
            }
        }
    }

    public selectFarmField(index: number, field: any) {
        if (this.farms.selected[index]) {
            const idIndex = this.farms.selected[index].fields.selected.findIndex((x: number) => x === field.id)
            const nameIndex = this.farms.selected[index].fields.selectedNames.findIndex((x: string) => x === `№${field.name}`)
            if (idIndex !== -1) {
                this.farms.selected[index].fields.selected.splice(idIndex, 1)
            } else this.farms.selected[index].fields.selected.push(field.id)
            if (nameIndex !== -1) {
                this.farms.selected[index].fields.selectedNames.splice(nameIndex, 1)
            } else this.farms.selected[index].fields.selectedNames.push(`№${field.name}`)
        }
    }

    public deleteFarmAtIndex(index: number) {
        if (this.farms.selected[index]) {
            this.farms.selected.splice(index, 1)
        }
    }

    public addFarm() {
        this.farms.selected.push({
            id: -1,
            name: '',
            onerror: '',
            fields: {
                origin: [],
                selected: [],
                selectedNames: [],
                onerror: ''
            }
        })
    }

    public changeModuleStatus(module: any) {
        this.permissions[module] = !this.permissions[module]
    }

    public checkIfSelectedAllGroup(groupName: any) {
        const group = moduleTypes.find((x: any) => x.container === groupName)
        if (group && group.modules) {
            const length = group.modules.length
            let sum = 0
            for (const module of group.modules) {
                if (this.permissions[module.value]) {
                    sum += 1
                }
            }
            if (sum === length) {
                return true
            }
        }
        return false
    }

    public changeGroupStatus(groupName: any) {
        const group = moduleTypes.find((x: any) => x.container === groupName)
        if (group && group.modules) {
            if (this.checkIfSelectedAllGroup(groupName)) {
                for (const module of group.modules) {
                    this.permissions[module.value] = false
                }
            } else {
                for (const module of group.modules) {
                    this.permissions[module.value] = true
                }
            }
        }
    }

    public getGroupProgress(groupName: any) {
        const group = moduleTypes.find((x: any) => x.container === groupName)
        if (group && group.modules) {
            const length = group.modules.length
            let sum = 0
            for (const module of group.modules) {
                if (this.permissions[module.value]) {
                    sum += 1
                }
            }
            return Math.round(sum * 100 / length)
        }
        return 0
    }

    public checkEmail() {
        if (this.email.selectedName === '') {
            this.email.selectedName = ''
            this.username.selectedName = ''
            this.FIRST_NAME.selectedName = ''
            this.LAST_NAME.selectedName = ''
            this.allFieldsIsFull = false
        }
        const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/
        if (this.email.selectedName.match(mailformat)) {
            httpPost({
                url: `${apiUrl}/get/by/username/`,
                isSecureRequest: true,
                detailResponse: true,
                body: {
                    username: this.email.selectedName
                },
                onSuccess: json => {
                    console.log(json)
                    if (json.ok) {
                        if (json.json.hasOwnProperty('email')) {
                            console.log('email')
                            this.EXIST_ACCOUNT = false
                            if (json.json.email !== '' && json.json.first_name !== '' && json.json.last_name !== '') {
                                this.email.selectedName = json.json.email
                                this.username.selectedName = json.json.username
                                this.FIRST_NAME.selectedName = json.json.first_name
                                this.LAST_NAME.selectedName = json.json.last_name
                                this.allFieldsIsFull = true
                            }
                        } else if (json.json === 'Not found') {
                            console.log('not found')
                            this.EXIST_ACCOUNT = false
                        } else {
                            console.log('else')
                            if (json.json.hasOwnProperty('username')) {
                                console.log('username property')
                                Vue.alert(i18n.t('messages.success.account_found') as string, '', 'info').then(() => {
                                    this.username.selectedName = json.json.username
                                    this.FIRST_NAME.selectedName = json.json.first_name
                                    this.LAST_NAME.selectedName = json.json.last_name
                                    this.EXIST_ACCOUNT = true
                                })
                            }
                        }
                    }
                },
                doFinally: () => {
                    //
                }
            })
        }
    }

    public checkNumber() {
        if (this.username.selectedName === '') {
            this.email.selectedName = ''
            this.username.selectedName = ''
            this.FIRST_NAME.selectedName = ''
            this.LAST_NAME.selectedName = ''
            this.allFieldsIsFullUsername = false
        }
        let str = this.username.selectedName.replace(/[^\d.-]/g, '')
        if (str) {
            str = `+${str}`
            this.username.selectedName = str
            console.log(this.username.selectedName)
            httpPost({
                url: `${apiUrl}/get/by/username/`,
                isSecureRequest: true,
                detailResponse: true,
                body: {
                    username: this.username.selectedName
                },
                onSuccess: json => {
                    if (json.ok) {
                        if (!json.json.status) {
                            console.log(json.json)
                            this.email.selectedName = json.json.email
                            this.username.selectedName = json.json.username
                            this.FIRST_NAME.selectedName = json.json.first_name
                            this.LAST_NAME.selectedName = json.json.last_name
                            this.allFieldsIsFullUsername = true
                        }
                    }
                },
                doFinally: () => {
                    //
                }
            })
        } else this.username.selectedName = ''
    }

    public checkForErrors() {
        let counter = 0
        const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/
        if (this.username.selectedName === '') {
            if (!this.IS_FAKE && !this.email.selectedName.match(mailformat)) {
                this.email.onerror = `${i18n.t('messages.instructions.email')}`
                counter++
            } else {
                this.email.onerror = ''
            }
        }
        // if (this.FIRST_NAME.selectedName === '') {
        //     this.FIRST_NAME.onerror = `${i18n.t('messages.instructions.employee_name')}`
        //     counter++
        // }
        // if (this.LAST_NAME.selectedName === '') {
        //     this.LAST_NAME.onerror = `${i18n.t('messages.instructions.employee_surname')}`
        //     counter++
        // }
        if (counter === 1 ) {
            if (this.username.selectedName === '') {
                this.username.onerror = `${i18n.t('messages.instructions.phone')}`
                counter++
            } else {
                let str = this.username.selectedName
                str = str.replace(/[^\d.-]/g, '')
                if (`+${str}` !== this.username.selectedName) {
                    this.username.onerror = `${i18n.t('messages.instructions.phone')}`
                    counter++
                }
            }
        }
        if (this.roles.selected.length === 0) {
            this.roles.onerror = `${i18n.t('messages.selections.access_type')}`
            counter++
        }
        return counter > 0
    }

    public getPostBody() {
        const farms = []
        const fields = []
        for (const farm of this.farms.selected) {
            if (farm.id !== -1 && farm.fields.selected.length > 0) {
                farms.push(farm.id)
                fields.push(...farm.fields.selected)
            }
        }
        const postBody = {
            username: this.username.selectedName.replaceAll(' ', '').replaceAll('(', '').replaceAll(')', ''),
            first_name: this.FIRST_NAME.selectedName,
            last_name: this.LAST_NAME.selectedName,
            roles: this.roles.selected,
            group_users: farms,
            divided_cadastre_users: fields,
            ...this.permissions
        }
        if (this.IS_FAKE) {
            postBody.is_fake = true
        } else {
            postBody.is_fake = false
            postBody.email = this.email.selectedName
        }
        return postBody
    }

    public getPutBody() {
        const farms = []
        const fields = []
        for (const farm of this.farms.selected) {
            if (farm.id !== -1 && farm.fields.selected.length > 0) {
                farms.push(farm.id)
                fields.push(...farm.fields.selected)
            }
        }
        const putBody = {
            username: this.username.selectedName.replaceAll(' ', '').replaceAll('(', '').replaceAll(')', ''),
            first_name: this.FIRST_NAME.selectedName,
            last_name: this.LAST_NAME.selectedName,
            email: this.email.selectedName,
            group_users: farms,
            divided_cadastre_users: fields,
            ...this.permissions,
            roles: this.roles.selected
        }
        return putBody
    }
}