








































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Getter, Action, Mutation } from 'vuex-class'
import Sideboard from '@/components/Sideboard.vue'
import {GetterNames, MutationNames, ActionNames} from '@/store/types'
import { apiUrl } from '@/domain/constants'
import { httpGet, httpPut, httpPostFormData } from '@/util/http'
import Farm from '@/models/Farm'
import i18n from '@/i18n'
import { geojson2wkt } from '@/utils/services'
import RegisterFarm from '@/models/RegisterFarm'
import {ASTANA_GEOLOCATION, ASTANA_BOUNDARIES } from '@/domain/constants'
@Component({
    components: {
        Sideboard
    },
    beforeRouteLeave(to: any, from: any, next: any) {
        if (to.params.isDataSaved === 'true') {
            next()
        } else if (confirm(i18n.t('messages.confirm.exit.not_saved') as string)) {
            next()
        } else {
            // next(false)
        }
    }
})
export default class EditFarm extends Vue {
    @Action(ActionNames.fetchFarmsList) private fetchFarmsList !: any
    @Action(ActionNames.updateMapDataInRegion) private updateMapDataInRegion!: any

    @Getter(GetterNames.getFarmFromRoute) private getFarmFromRoute!: Farm
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: any
    @Getter(GetterNames.getMapData) private mapData !: any
    @Getter(GetterNames.getSidebarModel) private getSidebarModel!: any
    @Getter(GetterNames.getRegisterFarms) private getRegisterFarms!: any
    @Getter(GetterNames.getLoadingDataNames) private getLoadingDataNames!: any

    @Mutation(MutationNames.setCurrentCadastreIndex) private setCurrentCadastreIndex!: any
    @Mutation(MutationNames.setSideboardIsShown) private setSideboardIsShown !: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    @Mutation(MutationNames.setRegisterFarms) private setRegisterFarms!: any
    private farm = {
        name: '',
        onerror: ''
    }
    private optionStatus = false
    private optionid = 0
    private borderColors: any = ['#B2F507', '#06BAF2', '#5AF5D9', '#F37500', '#FC7BE7', '#719D00', '#AA27E8', '#2B4DFF', '#4F0BBD', '#AD3E00']

    get getOldFarm() {
        const farm = this.getRegisterFarms.cadastres.find((x: any) => x.oldfarm)
        if (farm) {
            return farm
        } else {
            return false
        }
    }

    private getBorderColor(documentId: any) {
        if (documentId) {
            const cadastre = this.getRegisterFarms.cadastres.find((x: any) => x.documentId === documentId)
            const cadastres = this.getRegisterFarms.cadastres.filter((x: any) => x.documentId)
            if (cadastre && cadastres) {
                const index = cadastres.indexOf(cadastre)
                if (index !== -1) {
                    return this.borderColors[index % 10]
                }
            }
        }
        return 'rgba(40, 49, 70, 0.12)'
    }

    @Watch('getFarmFromRoute')
    private onchangeFarm(val: any, old: any) {
        if (!old) {
            this.fillData()
        }
    }

    private mounted() {
        if (this.getFarmFromRoute) {
            this.fillData()
        } else {
            this.fetchFarmsList()
        }
    }

    private fillData() {
        const registerFarms: RegisterFarm[] = []
        this.setRegisterFarms({
            cadastres: registerFarms,
            editType: 'none',
            editStage: 0,
            selectedCadastres: [],
            errorPoints: [],
            newField: false
        })
        const farm = JSON.parse(JSON.stringify(this.getFarmFromRoute))
        this.farm.name = farm.name
        const farmFields: any = []
        for (const i of farm.fields) {
            farmFields.push({
                id: i.id,
                area: i.area,
                geometry: i.geom,
                name: i.name
            })
        }
        const oldFarm = new RegisterFarm()
        oldFarm.number = farm.id.toString()
        oldFarm.oldfarm = true
        oldFarm.setData({
            id: farm.id,
            documentId: farm.id.toString(),
            location: `${farm.region ? `${farm.region}, ` : ''}${farm.oblast ? `${farm.oblast}, ` : ''}`,
            area: farm.area,
            fields: farmFields,
            cadastre: {
                area: farm.area,
                boundary: farm.boundaries,
                center: {
                    x: farm.center[0],
                    y: farm.center[1]
                },
                documentId: farm.id.toString(),
                field: JSON.parse(JSON.stringify(farmFields)),
                geojson: null,
                id: farm.id,
                oblast: farm.oblast ? farm.oblast : '',
                region: farm.region ? farm.region : ''
            },
            readonly: true
        })
        this.getRegisterFarms.cadastres.push(oldFarm)
        this.getRegisterFarms.cadastres.push(new RegisterFarm())
    }

    private UpdateFarm() {
        if (this.farm.name === '') {
            this.$alert(i18n.t('messages.instructions.farm_name') as string, 'Предупреждение', 'warning')
            this.farm.onerror = i18n.t('messages.instructions.farm_name') as string
            return
        }
        const cadastreInfo: any = []
        const newCadastres = this.getRegisterFarms.cadastres.filter((x: any) => !x.oldfarm)
        for (const cadastre of newCadastres) {
            if (cadastre.fields.length !== 0) {
                const editedArr: any = []
                for (const field of cadastre.fields) {
                    const result = geojson2wkt(field.geometry)
                    if (result) {
                        editedArr.push({
                            geometry: result,
                            name: field.name
                        })
                    }
                }
                cadastreInfo.push({
                    id: isNaN(cadastre.id) ? null : cadastre.id,
                    meta: {
                        edited: editedArr,
                        added: [],
                        itself: false
                    }
                })
            }
        }
        const divideCadastreUserUpdate: any = []
        const oldFarm = this.getRegisterFarms.cadastres.find((x: any) => x.oldfarm)
        for (const field of oldFarm.fields) {
            const cachedField = oldFarm.cadastre.field.find((x: any) => field.id === x.id)
            if (cachedField) {
                if (JSON.stringify(cachedField.geometry) === JSON.stringify(field.geometry)) {
                    divideCadastreUserUpdate.push({
                        id: field.id,
                        geom: field.geometry
                    })
                } else {
                    divideCadastreUserUpdate.push({
                        id: field.id,
                        geom: field.geometry
                    })
                }
            } else {
                divideCadastreUserUpdate.push({
                    geom: field.geometry
                })
            }
        }
        this.setLoadingDataNames('editFarmUpdateFarm')
        httpPut({
            url:  `${apiUrl}/group/user/update/${this.currentRoute.params.farmId}`,
            isSecureRequest: true,
            detailResponse: true,
            body: {
                name: this.farm.name,
                cadastre_info: cadastreInfo,
                divided_cadastre_user_update: divideCadastreUserUpdate
            },
            onSuccess: json => {
                if (json.ok) {
                    this.$alert(i18n.t('messages.success.changes_saved') as string, i18n.t('messages.success.changes_saved') as string, 'success')
                    .then(() => {
                        this.$router.push({
                            name: 'farm',
                            params: {
                                farmId: this.getFarmFromRoute.id.toString(),
                                isDataSaved: 'true'
                            }
                        })
                        this.fetchFarmsList({reload: true})
                    })
                } else if (json.status === 400) {
                    this.$alert(i18n.t('messages.errors.farm_exists') as string, 'Предупреждение', 'warning')
                } else if (json.status === 422) {
                    const errors: any = []
                    if (json.json.hasOwnProperty('cadastre_info')) {
                        for (const cadastre of json.json.cadastre_info) {
                            if (Object.keys(cadastre).length !== 0) {
                                for (const field of cadastre.meta.edited) {
                                    if (Object.keys(field).length !== 0) {
                                        if (field.geometry && field.geometry.point) {
                                            const points = field.geometry.point.replaceAll(/[()]/g, '').split(' ')
                                            errors.push({
                                                coordinates: [Number(points[1]), Number(points[2])],
                                                problem_type: field.geometry.problem_type
                                            })
                                        }
                                    }
                                }
                            }
                        }
                    }
                    if (json.json.hasOwnProperty('divided_cadastre_user_update')) {
                        for (const error of json.json.divided_cadastre_user_update) {
                            if (Object.keys(error).length > 0) {
                                if (error.point && error.problem_type) {
                                    const points = error.point.replaceAll(/[()]/g, '').split(' ')
                                    errors.push({
                                        coordinates: [Number(points[1]), Number(points[2])],
                                        problem_type: error.problem_type
                                    })
                                }
                            }
                        }
                    }
                    this.$alert('Ошибка в геометрии хозяйства. Проверьте нарисованные поля.', 'Предупреждение', 'warning').then(() => {
                        this.setRegisterFarms({errorPoints: errors})
                    })
                } else {
                    this.$alert('Ошибка сервера', 'Ошибка', 'error')
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('editFarmUpdateFarm')
            }
        })
    }

    private filesUploaded(uploadedFile: any) {
        if (uploadedFile.length > 0) {
            this.$ga.event('farm edit', 'upload file', 'from editFarm', 1)
            const formData = new FormData()
            for (const file of uploadedFile) {
                formData.append('files', file)
            }
            this.setLoadingDataNames('editFarmFileUpload')
            httpPostFormData({
                url: `${apiUrl}/get/geom/from/files/`,
                isSecureRequest: true,
                detailResponse: true,
                body: formData,
                onSuccess: json => {
                    if (json.ok) {
                        const newcadastre = json.json
                        if (!this.getRegisterFarms.cadastres.find((x: any) => x.documentId === json.json.documentId)) {
                            const cadastre = new RegisterFarm()
                            cadastre.number = newcadastre.documentId
                            cadastre.setData({
                                id: newcadastre.id,
                                documentId: newcadastre.documentId,
                                location: `${newcadastre.region ? `${newcadastre.region}, ` : ''}${newcadastre.oblast}`,
                                area: newcadastre.area,
                                fields: newcadastre.field,
                                cadastre: newcadastre,
                                readonly: true
                            })
                            this.getRegisterFarms.cadastres.push(cadastre)
                            this.setMapData()
                        }
                    } else {
                        if (json.status === 400) {
                            this.$alert(json.json.files, 'Ошибка файла', 'warning')
                        } else {
                            this.$alert('Ошибка сервера', 'Ошибка', 'error')
                        }
                    }
                },
                onError: error => {
                    console.log(error)
                },
                doFinally: () => {
                    this.setLoadingDataNames('editFarmFileUpload')
                    const upload = this.$refs.uploadfile as any
                    if (upload) {
                        upload.value = ''
                    }
                }
            })
        }
    }

    private setEditCadastre(index: any) {
        if (this.getRegisterFarms.cadastres[index].cadastre) {
            this.getRegisterFarms.cadastres.map((x: any, i: any) => {
                if (x.editing) {
                    this.getRegisterFarms.cadastres[i].editing = false
                }
            })
            const cadastre = this.getRegisterFarms.cadastres[index].cadastre
            this.updateMapDataInRegion({
                animateBbox: cadastre.boundary
            })
            this.getRegisterFarms.cadastres[index].editing = true
            this.setRegisterFarms({editType: 'none', editStage: 0})
            this.setSideboardIsShown(false)
        }
    }

    private searchCadastre(cadastreNumber: any, index: any) {
        const registerFarm = this.getRegisterFarms.cadastres[index]
        if (registerFarm) {
            if (registerFarm.readonly === false) {
                if (cadastreNumber.length === 14 || cadastreNumber.length === 15) {
                    const documentId = cadastreNumber.replaceAll('-', '')
                    if (!this.getRegisterFarms.cadastres.find((x: any) => x.documentId === documentId)) {
                        if (this.getLoadingDataNames(`editFarmSearchCadastre-${documentId}`)) return
                        this.setLoadingDataNames(`addFarmsearchCadastre-${documentId}`)
                        httpGet({
                            url:  `${apiUrl}/cadastre_by_value/${cadastreNumber.replaceAll('-', '')}/`,
                            isSecureRequest: true,
                            detailResponse: true,
                            onSuccess: json => {
                                if (json.ok) {
                                    if (this.getRegisterFarms.cadastres[index].number === cadastreNumber) {
                                        if (!this.getRegisterFarms.cadastres.find((x: any) => x.documentId === documentId)) {
                                            if (Object.keys(json.json).length === 0) {
                                                this.getRegisterFarms.cadastres[index].clear()
                                                this.setMapData()
                                                this.$alert('Кадастр не найден', 'Предупреждение', 'warning')
                                            } else {
                                                let field: any = null
                                                if (json.json.field.length > 0) {
                                                    field = JSON.parse(JSON.stringify(json.json.field))
                                                } else {
                                                    field = [{
                                                        id: json.json.documentId,
                                                        geometry: json.json.geomjson,
                                                        area: json.json.area,
                                                        name: null
                                                    }]
                                                }
                                                this.getRegisterFarms.cadastres[index].setData({
                                                    id: json.json.id,
                                                    documentId: json.json.documentId,
                                                    location: `${json.json.region ? `${json.json.region}, ` : ''}${json.json.oblast}`,
                                                    area: json.json.area,
                                                    fields: field,
                                                    cadastre: json.json,
                                                    readonly: false
                                                })
                                                this.setMapData()
                                            }
                                        }
                                    }
                                } else {
                                    this.getRegisterFarms.cadastres[index].clear()
                                    this.setMapData()
                                    this.$alert('Ошибка сервера', 'Ошибка', 'error')
                                }
                            },
                            onError: error => {
                                console.log(error)
                            },
                            doFinally: () => {
                                this.setLoadingDataNames(`addFarmsearchCadastre-${documentId}`)
                            }
                        })
                    }
                } else {
                    this.getRegisterFarms.cadastres[index].clear()
                    this.setMapData()
                }
            }
        }
    }

    private setMapData() {
        if (this.getRegisterFarms.cadastres.filter((x: any) => x.cadastre).length === 0) {
            this.setCurrentCadastreIndex(7952883)
            this.updateMapDataInRegion({
                boundaries: ASTANA_BOUNDARIES,
                center: ASTANA_GEOLOCATION
            })
        } else {
            let Xmin = Number.MAX_SAFE_INTEGER
            let Xmax = Number.MIN_SAFE_INTEGER
            let Ymin = Number.MAX_SAFE_INTEGER
            let Ymax = Number.MIN_SAFE_INTEGER
            this.getRegisterFarms.cadastres.filter((x: any) => x.cadastre).map((cadastre: any) => {
                const boundaries = cadastre.cadastre.boundary
                Xmax = boundaries[2] > Xmax ? boundaries[2] : Xmax
                Xmin = boundaries[0] < Xmin ? boundaries[0] : Xmin
                Ymax = boundaries[3] > Ymax ? boundaries[3] : Ymax
                Ymin = boundaries[1] < Ymin ? boundaries[1] : Ymin
            })
            const cadastres = this.getRegisterFarms.cadastres.filter((x: any) => x.cadastre)
            this.setCurrentCadastreIndex(`-${cadastres[cadastres.length - 1].documentId}`)
            this.updateMapDataInRegion( {
                animateBbox: [Xmin, Ymin, Xmax, Ymax]
            })
        }
    }

    private deleteCadastre(index: any) {
        this.getRegisterFarms.cadastres.splice(index, 1)
        this.setMapData()
    }

    private addNewField() {
        this.getRegisterFarms.cadastres.push(new RegisterFarm())
    }

    private openFileUpload() {
        const upload = this.$refs.uploadfile as any
        if (upload) {
            upload.click()
        }
    }

    private toggleMorebtnDropdown(val: number) {
        if (val === this.optionid) {
            this.optionStatus = this.optionStatus === true ? false : true
        } else {
            this.optionid = val
            this.optionStatus = true
        }
    }

    private closeMorebtnDropdown() {
        this.optionStatus = false
    }

    private comebackToFarm() {
        this.$router.push(`/cadastres/farm/${this.getFarmFromRoute.id}`)
    }
}
