













































































import { Component, Vue } from 'vue-property-decorator'
import {Getter, Mutation} from 'vuex-class'
import {GetterNames, MutationNames} from '@/store/types'
import {httpDelete, httpGet} from '@/util/http'
import Farm from '@/models/Farm'
import i18n from '@/i18n'
import {apiUrl, baseUrl} from '@/domain/constants'
import {Route} from 'vue-router'

@Component
export default class CadastreInfo extends Vue {
  @Getter(GetterNames.getCadastreData) private getCadastreData!: any

  private toStringArea(area: any) {
      return Math.round(area / 100) / 100 + ' га'
  }

  private getFormattedCadastre(cadastre: any) {
    return cadastre ? [...cadastre].map((d, i) => i % 3 === cadastre.length % 3 ? '-' + d : d).join('') : ''
  }

  private checkForDeadline(date: any) {

    if (date) {
      const currentDate = new Date(date)
      const now = new Date()
      const daysCount = Math.round((currentDate.getTime() - now.getTime()) / (1000 * 60 * 60 * 24))

      return daysCount < 182
    }
    return false
  }

  private close() {
    this.$router.back()
  }
}
