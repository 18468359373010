

























































import { Component, Vue, Emit, Prop } from 'vue-property-decorator'
import i18n from '@/i18n'

@Component
export default class DateRangePicker extends Vue {
    @Prop({default: 7}) private maxDay !: number
    @Prop({default: true}) private timeSelection!: boolean
    @Prop({default: false}) private futureselection !: boolean
    @Prop({default: false}) private singleSelection!: boolean
    private isShown = false
    private selected = {
        fromDate: '',
        toDate: ''
    }
    private time = {
        fromHour: '12',
        fromMinute: '00',
        toHour: '23',
        toMinute: '59'
    }
    private date = {
        month: 7,
        year: 2020
    }

    get getCurrentMonthYear() {
        const date = new Date(this.date.year, this.date.month, 1)
        const month = date.toLocaleString('ru', { month: 'long' })
        return month.charAt(0).toUpperCase() + month.slice(1) + ' ' + this.date.year
    }

    get daysOfWeek() {
        return [i18n.t('general.dates.week[0]') as string, i18n.t('general.dates.week[1]') as string, i18n.t('general.dates.week[2]') as string, i18n.t('general.dates.week[3]') as string, i18n.t('general.dates.week[4]') as string, i18n.t('general.dates.week[5]') as string, i18n.t('general.dates.week[6]') as string]
    }

    get getCurrentMonthDays() {
        const daysInMonth =  new Date(this.date.year, this.date.month + 1, 0).getDate()
        let firstDay = new Date(this.date.year + "-" + (this.date.month + 1) + "-1").getDay()
        firstDay = (firstDay === 0) ? 7 : firstDay
        const calendarArray = []
        let count = 1
        for (let i = 0; i < 6; i++) {
            const weekArray = []
            for (let j = 0; j < 7; j++) {
                if (i === 0) {
                    if (j + 1 < firstDay) {
                        weekArray.push('')
                    } else {
                        weekArray.push(this.date.year + '-' + (this.date.month + 1) + '-' + count)
                        count += 1
                    }
                } else {
                    if (count <= daysInMonth) {
                        weekArray.push(this.date.year + '-' + (this.date.month + 1) + '-' + count)
                        count += 1
                    } else {
                        weekArray.push('')
                    }
                }
            }
            calendarArray.push(weekArray)
        }
        return calendarArray
    }

    private checkTime() {
        const fromHour = Number(this.time.fromHour)
        const fromMinute = Number(this.time.fromMinute)
        const toHour = Number(this.time.toHour)
        const toMinute = Number(this.time.toMinute)
        this.time.fromHour = fromHour > 23 ? '23' : fromHour <= 0 ? '00' :
            fromHour.toString().length === 1 ? '0' + fromHour.toString() : fromHour.toString()
        this.time.fromMinute = fromMinute > 59 ? '59' : fromMinute <= 0 ? '00' :
            fromMinute.toString().length === 1 ? '0' + fromMinute.toString() : fromMinute.toString()
        this.time.toHour = toHour > 23 ? '23' : toHour <= 0 ? '00' :
            toHour.toString().length === 1 ? '0' + toHour.toString() : toHour.toString()
        this.time.toMinute = toMinute > 59 ? '59' : toMinute <= 0 ? '00' :
            toMinute.toString().length === 1 ? '0' + toMinute.toString() : toMinute.toString()
    }

    private selectDay(date: any) {
        if (date === '') return
        if (this.ifMuted(date)) return
        if (this.selected.fromDate === '' || this.singleSelection) {
            this.selected.fromDate = date
        } else {
            if (new Date(this.selected.fromDate).getTime() <= new Date(date).getTime()) {
                if (this.selected.toDate !== '') {
                    this.selected.fromDate = date
                    this.selected.toDate = ''
                } else {
                    this.selected.toDate = date
                }
            } else {
                this.selected.fromDate = date
                this.selected.toDate = ''
            }
        }
    }

    private ifMuted(date: any) {
        if (date === '' || (this.singleSelection && !this.futureselection)) return false
        const d = new Date()
        const today = d.getFullYear() + '-' + (d.getMonth() + 1 ) + '-' + d.getDate()
        if (new Date(today).getTime() < new Date(date).getTime() && !this.futureselection) {
            return true
        } else {
            if (this.selected.fromDate !== '' && this.selected.toDate === '') {
                const fromDate = new Date(this.selected.fromDate)
                const tillDate = fromDate.setDate(fromDate.getDate() + this.maxDay)
                if (new Date(date).getTime() < tillDate) {
                    return false
                } else {
                    return true
                }
            } else {
                return false
            }
        }
    }

    private ifInRange(date: any) {
        if (this.selected.fromDate === '' || this.selected.toDate === '') return false
        if (new Date(this.selected.fromDate).getTime() < new Date(date).getTime() &&
            new Date(this.selected.toDate).getTime() > new Date(date).getTime()) {
                return true
        } else {
            return false
        }
    }
    private checkIfSelected(date: any) {
        if (date === '') {
            return false
        }
        return date === this.selected.fromDate || date === this.selected.toDate
    }
    private normalizeDate(date: any) {
        if (date === '') return ''
        else return date.split('-')[2]
    }
    private nextMonth() {
        if (this.date.month + 1 === 12) {
            this.date.year += 1
            this.date.month = 0
        } else {
            this.date.month += 1
        }
    }
    private previousMonth() {
        if (this.date.month - 1 === -1) {
            this.date.year -= 1
            this.date.month = 11
        } else {
            this.date.month -= 1
        }
    }

    private mounted() {
        const d = new Date()
        this.date.month = d.getMonth()
        this.date.year = d.getFullYear()
    }

    private show() {
        this.isShown = !this.isShown
    }


    private setTime(startDate: any, endDate: any) {
        this.selected.fromDate = startDate.split(' ')[0]
        this.selected.toDate = endDate.split(' ')[0]
        this.time = {
            fromHour: startDate.split(' ')[1].split(':')[0],
            fromMinute: startDate.split(' ')[1].split(':')[1],
            toHour: endDate.split(' ')[1].split(':')[0],
            toMinute: endDate.split(' ')[1].split(':')[1]
        }
    }

    private discard() {
        this.selected = {
            fromDate: '',
            toDate: ''
        }
        this.time = {
            fromHour: '12',
            fromMinute: '00',
            toHour: '23',
            toMinute: '59'
        }
    }

    private hide() {
        this.isShown = false
    }

    private getStartDate() {
        if (this.selected.fromDate) {
            return `${this.formatDate(new Date(this.selected.fromDate))} ${this.time.fromHour}:${this.time.fromMinute}:00`
        } else return ''

    }
    private getEndDate() {
        if (this.selected.toDate) {
            return `${this.formatDate(new Date(this.selected.toDate))} ${this.time.toHour}:${this.time.toMinute}:00`
        } else return ''
    }

    private formatDate(date: any) {
        const d = new Date(date)
        let month = '' + (d.getMonth() + 1)
        let day = '' + d.getDate()
        const year = d.getFullYear()
        if (month.length < 2) {
            month = '0' + month
        }
        if (day.length < 2) {
            day = '0' + day
        }
        return [year, month, day].join('-')
    }

    @Emit('confirm')
    private confirm() {
        return {}
    }
}
