
































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { httpGet, httpDelete } from '@/util/http'
import { apiUrl } from '@/domain/constants'
import { Action, Mutation } from 'vuex-class'
import { ActionNames, MutationNames } from '@/store/types'
import { permissionTypes } from '@/data/rolesData'
import TransferMember from './TransferMember.vue'
import NewMemberModal from './NewMemberModal.vue'
import RegisterModal from './RegisterModal.vue'
import AddTelegramModel from './AddTelegramModel.vue'

@Component({
    components: {
        TransferMember,
        NewMemberModal,
        RegisterModal,
        AddTelegramModel
    }
})
export default class MembersTable extends Vue {
    @Prop({default: 0}) private folder!: number
    @Prop({default: ''}) private search!: string
    @Action(ActionNames.fetchFarmsList) private fetchFarmsList!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    private permissionTypes = permissionTypes
    private pages = {
        count: 1,
        next: null,
        previous: null
    }
    private sorts: any = {
        user__first_name: '',
        user__username: '',
        roles: '',
        user__email: ''
    }
    private data: any = []
    private isDataLoading = false
    private lastQuery = ''

    @Watch('search')
    private onchangeSearch(val: string, old: string) {
        if (val !== old) {
            if (val === '') {
                this.getData(1)
            } else {
                if (val.length > 3) {
                    this.getData(1)
                }
            }
        }
    }

    private getPermissionTypes(roles: any) {
        let str = ''
        for (const role of this.permissionTypes) {
            if (roles.includes(role.num)) {
                str += role.name + ', '
            }
        }
        if (str !== '') {
            return str.substring(0, str.length - 2)
        }
        return str
    }

    private mounted() {
        this.getData(1)
    }

    private getData(page: number) {
        if (page === 1) this.data = []
        this.isDataLoading = true
        const query = []
        query.push(`page=${page}`)
        if (this.folder) {
            query.push(`folder=${this.folder}`)
        }
        if (this.search) {
            query.push(`search_text=${this.search}`)
        }
        console.log(this.sorts)
        for (const [key, value] of Object.entries(this.sorts)) {
            if (value) {
                query.push(`ordering=${value}${key}`)
            }
        }
        if (this.lastQuery === query.join('&')) return
        this.lastQuery = query.join('&')
        httpGet({
            url: `${apiUrl}/workspace/get/folders/employees/?${query.join('&')}`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                console.log(json)
                if (json.ok) {
                    if (page === 1) this.data = []
                    this.pages.count = json.json.count
                    this.pages.next = json.json.next
                    this.pages.previous = json.json.previous
                    this.data.push(...json.json.results)
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.isDataLoading = false
                this.lastQuery = ''
            }
        })
    }

    private toggleSort(key: string, val: string) {
        if (Object.prototype.hasOwnProperty.call(this.sorts, `${key}`)) {
            this.sorts[`${key}`] = this.sorts[`${key}`] === val ? '' : val
            this.getData(1)
        }
    }

    private deleteMember(id: number) {
        this.$confirm(`${this.$i18n.t('messages.questions.delete.staff')}`, 'Подтверждение', 'warning').then(() => {
            this.setLoadingDataNames('membersDeleteMember')
            httpDelete({
                url: `${apiUrl}/workspace/change/permissions/in/workspace/${id}`,
                isSecureRequest: true,
                detailResponse: true,
                onSuccess: json => {
                    if (json.ok) {
                        this.$alert(`${this.$i18n.t('messages.success.deleted')}`, 'Успешно', 'success').then(() => {
                            this.getData(1)
                        })
                    } else {
                        this.$alert(JSON.stringify(json.json), 'Ошибка', 'error')
                    }
                },
                onError: error => {
                    console.log(error)
                },
                doFinally: () => {
                    this.setLoadingDataNames('membersDeleteMember')
                }
            })
        })
    }

    private appointDirector(id: any) {
        this.$confirm('Вы уверены что хотите передать доступ "Директор"?', 'Подтверждение', 'warning').then(() => {
            this.setLoadingDataNames('membersViewAppoitDirector')
            httpGet({
                url:  `${apiUrl}/workspace/change/director/to/${id}`,
                isSecureRequest: true,
                detailResponse: true,
                onSuccess: json => {
                    if (json.ok) {
                        this.$alert('Доступ успешно передан', 'Успешно', 'success').then(() => {
                            this.getData(1)
                            this.fetchFarmsList({reload: true})
                        })
                    } else {
                        this.$alert(JSON.stringify(json.json), 'Ошибка', 'error')
                    }
                },
                onError: error => {
                    console.log(error)
                },
                doFinally: () => {
                    this.setLoadingDataNames('membersViewAppoitDirector')

                }
            })
        })
    }
}
