export default {
    title: "Pабочий проект",
    plural: "Рабочие проекты",
    area: "Рабочая область",
    new: "Новый проект",
    save: "Сохранить проект",
    add: "Добавить проект",
    edit: {
        change: "Изменить рабочий проект",
        add: "Добавить рабочий проект"
    },
    details: {
        title: "Название",
        photo: "Фото",
        owner: "Владелец",
        position: "Должность"
    },
    types: {
        all: "Все проекты",
        my: "Мои проекты",
        other: "Чужие проекты"
    },
    sort: {
        title: "Сортировать",
        by_name: "по имени",
        by_date: "по дате",
        by_area: "по общей площади",
        ascending: "по возрастанию",
        descending: "по убыванию",
        access_type: "по типу доступа",
        notification_type: "по типу уведомлений"
    },
    participants: {
        list: "Список участников",
        fullname: "ФИО сотрудника",
        last_access: {
            title: "Последний доступ",
            not_activated: "Не активирован",
            offline: "Не заходил",
            just_now: "Только что",
            sec: "Секунду назад | {n} секунды | {n} секунд назад",
            min: "Минуту назад | {n} минуты | {n} минут назад",
            h: "Час назад | {n} часа | {n} часов назад",
            day: "День назад | {n} дня назад | {n} дней назад",
            week: "Неделю назад | {n} недели назад | {n} недель назад",
            month: "Месяц назад | {n} месяца назад | {n} месяцев назад",
            year: "Год назад | {n} года назад | {n} лет назад"
        },
        confirmation_message: "{name}  добавил(-а) Вас в проект. Принять проект?"
    }
}