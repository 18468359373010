


















































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { httpGet, httpPost, httpPut } from '@/util/http'
import { apiUrl, apiUrl3 } from '@/domain/constants'
import {Getter, Mutation} from 'vuex-class'
import {GetterNames, MutationNames} from '@/store/types'
import CultureChoose from '@/components/CultureChoose.vue'
import Farm from '@/models/Farm'

interface CulturesArgs {
    parent_culture: string,
    culture: string,
    sort: string,
    reproduction: string,
    field_ids: number[],
    field_names: string[]
}

@Component({
    components: {
        CultureChoose
    }
})
export default class NewProgramModal extends Vue {
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    @Getter(GetterNames.getFarmFromRoute) private getFarmFromRoute!: Farm
    private isShown = false
    private isUpdate = false
    private methodType = 1
    private techmap: any = null
    private name = {
        selectedName: '',
        onerror: ''
    }
    private isRest = false
    private cultures = new Array<CulturesArgs>()
    private fields = []
    private addField = false
    private additionalFields: any = []
    private additionalFieldNames = []
    private templates = {
        origin: new Array(),
        converted: new Array(),
        selected: null as any,
        selectedName: '',
        onerror: '',
        field_ids: new Array(),
        field_names: new Array()
    }

    private changeMethodType(type: number) {
        this.methodType = type
    }

    private changeIsRest(isRest: boolean) {
        this.isRest = isRest
    }

    private show(techmap: any) {
        this.isShown = true
        this.isUpdate = techmap.id ? true : false
        this.methodType = 1
        this.techmap = techmap
        this.fields = techmap.fields
        this.name.selectedName = techmap.name
        this.isRest = techmap.is_rest
        this.fetchTemplates()
        this.parseFields(techmap)
        this.addField = false
        this.additionalFields = []
        this.additionalFieldNames = []
    }

    private parseFields(techmap: any) {
        this.cultures = []
        this.templates.field_ids = techmap.fields.map((x: any) => x.id)
        this.templates.field_names = techmap.fields.map((x: any) => `№${x.name}`)
        techmap.fields.forEach((x: any) => {
            const index = this.cultures.findIndex((y: CulturesArgs) => y.reproduction === x.reproduction || y.sort === x.sort)
            if (index !== -1) {
                this.cultures[index].field_ids.push(x.id)
                this.cultures[index].field_names.push(`№${x.name}`)
            } else {
                this.cultures.push({
                    parent_culture: techmap.parent_culture,
                    culture: techmap.culture,
                    sort: x.sort,
                    reproduction: x.reproduction,
                    field_ids: [x.id],
                    field_names: [`№${x.name}`]
                })
            }
        })
        setTimeout(() => {
        this.cultures.forEach((x: CulturesArgs, index: number) => {
            const el: any = this.$refs[`cultureChoose-${index}`]
            if (el) {
                el[0].setData(x.parent_culture, x.culture, x.sort, x.reproduction)
            }
        })
        }, 100)
    }

    private fetchTemplates() {
        this.setLoadingDataNames('NewProgramModalGetTemplates')
        httpGet({
            url:  `${apiUrl}/techmap/get/my/templates/`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                if (json.ok) {
                    this.templates.origin = json.json
                    this.templates.converted = json.json.map((x: any) => x.name)
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('NewProgramModalGetTemplates')
            }
        })
    }

    private addCulture() {
        this.cultures.push({
            parent_culture: '',
            culture: '',
            sort: '',
            reproduction: '',
            field_ids: [],
            field_names: []
        })
    }

    private deleteCulture(index: number) {
        if (this.cultures[index]) {
            this.cultures.splice(index, 1)
        }
    }

    private checkForErrors() {
        if (this.methodType === 1) {
            this.cultures.forEach((x: CulturesArgs, index: number) => {
                const el: any = this.$refs[`cultureChoose-${index}`]
                if (el) {
                    const result = el[0].getData()
                    this.cultures[index].parent_culture = result.parent_culture || ''
                    this.cultures[index].culture = result.culture || ''
                    this.cultures[index].sort = result.sort || ''
                    this.cultures[index].reproduction = result.reproduction || ''
                }
            })
            const cultures = this.cultures.filter((x: CulturesArgs) => x.field_ids.length > 0)
                .map((x: CulturesArgs) => x.culture)
                .filter((x: string, index: number, arr: string[]) => arr.indexOf(x) === index)
            if (cultures.length > 1 && !this.isRest) {
                this.$alert('Культуры должны быть одинаковыми', 'Предупреждение', 'warning')
                return true
            } else if (cultures[0] === '' && !this.isRest) {
                this.$alert('Выберите культуру', 'Предупреждение', 'warning')
                return true
            } else if ( cultures.length === 0) {
                this.$alert('Выберите поля', 'Предупреждение', 'warning')
                return true
            } else if (this.cultures.find((x: CulturesArgs) => x.culture !== '' && x.field_ids.length === 0)) {
                this.$alert('Выберите клетки', 'Предупреждение', 'warning')
                return true
            } else if (this.name.selectedName === '') {
                this.name.onerror = 'Введите название'
                return true
            } else return false
        } else if (this.methodType === 2) {
            if (this.templates.field_ids.length === 0) {
                this.$alert('Выберите поля', 'Предупреждение', 'warning')
                return true
            } else if (this.templates.selected === null) {
                this.templates.onerror = 'Выберите шаблон'
                return true
            } else return false
        }
    }

    private addTechmap() {
        if (this.checkForErrors()) return
        if (this.methodType === 1) {
            const fields = this.cultures.filter((x: CulturesArgs) => x.field_ids.length > 0)
            .map((x: CulturesArgs) => {
                return {
                    reproduction: this.isRest ? '' : x.reproduction,
                    sort: this.isRest ? '' : x.sort,
                    fields: x.field_ids
                }
            })
            this.setLoadingDataNames('NewProgramModalAddTechmap')
            httpPost({
                url: `${apiUrl3}/techmap/`,
                isSecureRequest: true,
                detailResponse: true,
                body: {
                    name: this.name.selectedName,
                    parent_culture: this.isRest ? '' : this.cultures[0].parent_culture,
                    culture: this.isRest ? '' : this.cultures[0].culture,
                    group_user: this.techmap.group_user,
                    is_rest: this.isRest,
                    fields_by_sort: fields
                },
                onSuccess: json => {
                    if (json.ok) {
                        this.$alert('Технологическая карта успешна создана', 'Создана', 'success').then(() => {
                            this.hide()
                            this.$emit('reload')
                        })
                    } else {
                        this.$alert(`${JSON.stringify(json.json)}`, 'Ошибка', 'error')
                    }
                },
                onError: error => {
                    console.log(error)
                },
                doFinally: () => {
                    this.setLoadingDataNames('NewProgramModalAddTechmap')
                }
            })
        } else {
            this.setLoadingDataNames('NewProgramModalAddNewProgram')
            httpPost({
                url:  `${apiUrl}/techmap/create/from/template/`,
                isSecureRequest: true,
                detailResponse: true,
                body: {
                    template: this.templates.selected.id,
                    group_user: Number(this.$route.params.farmId),
                    fields: this.templates.field_ids,
                    users: []
                },
                onSuccess: json => {
                    if (json.ok) {
                        this.$alert('Технологическая карта успешна создана', 'Создана', 'success').then(() => {
                            this.hide()
                            this.$emit('reload')
                        })
                    } else {
                        this.$alert(`${JSON.stringify(json.json)}`, 'Ошибка', 'error')
                    }
                },
                onError: error => {
                    console.log(error)
                },
                doFinally: () => {
                    this.setLoadingDataNames('NewProgramModalAddNewProgram')
                }
            })
        }
    }

  private updateTechmapFields() {
      const fields = this.cultures.map((x: CulturesArgs) => {
        return {
          reproduction: this.isRest ? '' : x.reproduction,
          sort: this.isRest ? '' : x.sort,
          fields: x.field_ids.concat(this.additionalFields.map((field: any) => field.id))
        }
      })
    // const fields = this.additionalFields.map((field: any) => field.id)
      this.setLoadingDataNames('NewProgramModalUpdateTechmap')
      httpPut({
      url: `${apiUrl3}/techmap/${this.techmap.id}/`,
      isSecureRequest: true,
      detailResponse: true,
      body: {
          name: this.name.selectedName,
          parent_culture: this.isRest ? '' : this.cultures[0].parent_culture,
          culture: this.isRest ? '' : this.cultures[0].culture,
          group_user: this.techmap.group_user,
          is_rest: this.isRest,
          fields_by_sort: fields
      },
      onSuccess: json => {
        if (json.ok) {
          this.$alert('Технологическая карта успешна обновлена', 'Обновлено', 'success')
          this.hide()
          this.$emit('reload')
        } else {
          this.$alert(`${JSON.stringify(json.json)}`, 'Ошибка', 'error')
        }
      },
      onError: error => {
        console.log(error)
      },
      doFinally: () => {
        this.setLoadingDataNames('NewProgramModalUpdateTechmap')
      }
    })
  }

    private updateTechmap() {
        if (this.checkForErrors()) return
        const fields = this.cultures.map((x: CulturesArgs) => {
            return {
                reproduction: this.isRest ? '' : x.reproduction,
                sort: this.isRest ? '' : x.sort,
                fields: x.field_ids
            }
        })
        this.setLoadingDataNames('NewProgramModalUpdateTechmap')
        httpPut({
            url: `${apiUrl3}/techmap/${this.techmap.id}/`,
            isSecureRequest: true,
            detailResponse: true,
            body: {
                name: this.name.selectedName,
                parent_culture: this.isRest ? '' : this.cultures[0].parent_culture,
                culture: this.isRest ? '' : this.cultures[0].culture,
                group_user: this.techmap.group_user,
                is_rest: this.isRest,
                fields_by_sort: fields
            },
            onSuccess: json => {
                if (json.ok) {
                    this.$alert('Технологическая карта успешна обновлена', 'Обновлено', 'success')
                    this.hide()
                    this.$emit('reload')
                } else {
                    this.$alert(`${JSON.stringify(json.json)}`, 'Ошибка', 'error')
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('NewProgramModalUpdateTechmap')
            }
        })
    }

    private selectAllFields(index: number) {
        if (index === -1) {
            if (this.templates.field_ids.length === this.fields.length) {
                this.templates.field_ids = []
                this.templates.field_names = []
            } else {
                this.templates.field_ids = this.fields.map((x: any) => x.id)
                this.templates.field_names = this.fields.map((x: any) => `№${x.name}`)
            }
        } else {
            if (this.cultures[index].field_ids.length === this.fields.length) {
                this.cultures[index].field_ids = []
                this.cultures[index].field_names = []
            } else {
                this.cultures[index].field_ids = this.fields.map((x: any) => x.id)
                this.cultures[index].field_names = this.fields.map((x: any) => `№${x.name}`)
                this.cultures.forEach((x: CulturesArgs, i: number) => {
                    if (i !== index) {
                        this.cultures[i].field_ids = []
                        this.cultures[i].field_names = []
                    }
                })
            }
        }
    }

    private addNewField(field: any) {
      const fieldIndex = this.additionalFields.findIndex((additional: any) => {
        return additional === field
      })
      if (fieldIndex !== -1) {
        this.additionalFields.splice(fieldIndex, 1)
      } else {
        this.additionalFields.push(field)
      }
      this.additionalFieldNames = this.additionalFields.map((addfield: any) => addfield.name)
    }

    private selectField(index: number, field: any) {
        if (index === -1) {
            const idIndex = this.templates.field_ids.findIndex((x: number) => x === field.id)
            const nameIndex = this.templates.field_names.findIndex((x: string) => x === `№${field.name}`)
            if (idIndex !== -1) {
                this.templates.field_ids.splice(idIndex, 1)
            } else this.templates.field_ids.push(field.id)
            if (nameIndex !== -1) {
                this.templates.field_names.splice(nameIndex, 1)
            } else this.templates.field_names.push(`№${field.name}`)
        } else {
            const idIndex = this.cultures[index].field_ids.findIndex((x: number) => x === field.id)
            const nameIndex = this.cultures[index].field_names.findIndex((x: string) => x === `№${field.name}`)
            if (idIndex !== -1) {
                this.cultures[index].field_ids.splice(idIndex, 1)
            } else {
                this.cultures[index].field_ids.push(field.id)
                this.cultures.forEach((x: CulturesArgs, i: number) => {
                    if (i !== index) {
                        const idIndx = x.field_ids.findIndex((y: number) => y = field.id)
                        const nameIndx = x.field_names.findIndex((y: string) => y === `№${field.name}`)
                        if (idIndx !== -1) {
                            this.cultures[i].field_ids.splice(idIndx, 1)
                        }
                        if (nameIndx !== -1) {
                            this.cultures[i].field_names.splice(nameIndx, 1)
                        }
                    }
                })
            }
            if (nameIndex !== -1) {
                this.cultures[index].field_names.splice(nameIndex, 1)
            } else this.cultures[index].field_names.push(`№${field.name}`)
        }
    }

    private onSelectTemplate(item: string) {
        const template = this.templates.origin.find((x: any) => x.name === item)
        if (template) {
            this.templates.selected = template
            this.templates.selectedName = item
            this.templates.onerror = ''
        }
    }

    private hide() {
        this.isShown = false
    }
}
