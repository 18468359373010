



































































































import { Component, Vue, Watch} from 'vue-property-decorator'
import { GetterNames, MutationNames } from '@/store/types'
import { Getter, Mutation } from 'vuex-class'
import * as turf from '@turf/turf'
@Component ({
    components: {
    }
})
export default class AgroChemPoints extends Vue {
    @Getter(GetterNames.getMapData) private mapData !: any
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: any
    @Getter(GetterNames.getAgroChemData) private getAgroChemData!: any
    @Getter(GetterNames.getFarms) private getFarms!: any
    @Getter(GetterNames.getFarmFromRoute) private getFarmFromRoute!: any
    @Mutation(MutationNames.setAgroChemData) private setAgroChemData!: any
    private textScale = 1
    private selectedFeatures: any[] = []
    private drawnPoint: any[] = []
    private modifyPoints: any[] = []
    private selectedPointId = -1

    @Watch('getAgroChemData.pointEdit')
    private modifyFieldPoints() {
        if (!this.getAgroChemData.pointEdit) {
            for (const point of this.modifyPoints) {
                const request = this.getAgroChemData.fieldPoints.find((x: any) => x.divided_cadastre_user === point.properties.id)
                if (request) {
                    const requestIndex = this.getAgroChemData.fieldPoints.indexOf(request)
                    const requestPoint = this.getAgroChemData.fieldPoints[requestIndex].points.find((x: any) => x.id === point.properties.pointid)
                    if (requestPoint) {
                        const requestPointIndex = this.getAgroChemData.fieldPoints[requestIndex].points.indexOf(requestPoint)
                        this.getAgroChemData.fieldPoints[requestIndex].points[requestPointIndex].geom.coordinates = point.geometry.coordinates
                    }
                }
            }
        }
    }
    @Watch('selectedFeatures')
    private featureSelected(newArr: any, oldArr: any) {
        if (newArr.length === 0) return
        else {
            if (this.getAgroChemData.mapSelection) {
                if (newArr[0].properties) {
                    const fieldId = newArr[0].properties.id
                    const tempArr = this.getAgroChemData.selectedFields
                    if (tempArr.includes(fieldId)) {
                        tempArr.splice(tempArr.indexOf(fieldId), 1)
                    } else {
                        tempArr.push(fieldId)
                    }
                    this.setAgroChemData({selectedFields: tempArr})
                }
            } else {
                if (newArr[0].properties) {
                    if (newArr[0].properties.type === 'fieldpoint') {
                        this.selectedPointId = newArr[0].properties.pointid
                        this.selectedFeatures = []
                        return
                    }
                }
            }
        }
        this.selectedPointId = -1
        this.selectedFeatures = []
    }
    @Watch('drawnPoint')
    private addNewPoint() {
        if (this.drawnPoint.length === 0) return
        const point = turf.point(this.drawnPoint[this.drawnPoint.length - 1].geometry.coordinates)
        for (const field of this.getCurrentFarmFields()) {
            const polygon = turf.polygon(field.geom.coordinates[0])
            if (turf.booleanContains(polygon, point)) {
                const request = this.getAgroChemData.fieldPoints.find((x: any) => x.divided_cadastre_user === field.id)
                if (request) {
                    this.getAgroChemData.fieldPoints[this.getAgroChemData.fieldPoints.indexOf(request)].points.push({
                        geom: point.geometry,
                        newpoint: true,
                        id: point.id
                    })
                }
            }
        }
        this.drawnPoint = []
    }
    private removePoint(fieldId: any, pointId: any) {
        const tempArr: any = this.getAgroChemData.fieldPoints
        const point = tempArr.find((x: any) => x.divided_cadastre_user === fieldId)
        .points.find((x: any) => x.id === pointId)
        tempArr.find((x: any) => x.divided_cadastre_user === fieldId)
        .points.splice(tempArr.find((x: any) => x.divided_cadastre_user === fieldId)
        .points.indexOf(point), 1)
        this.setAgroChemData({fieldPoints: tempArr})
        this.selectedPointId = -1
    }
    private getCurrentFarmFields() {
        if (this.getFarmFromRoute) {
            return this.getFarmFromRoute.fields
        }
        return []
    }
    private getFieldbyId(fieldId: any) {
        const farm = this.getFarms.find((x: any) => x.id.toString() === this.currentRoute.params.farmId)
        return farm.fields.find((x: any) => x.id === fieldId)
    }
    private getFieldCenter(field: any) {
        let Xmin = Number.MAX_SAFE_INTEGER
        let Xmax = Number.MIN_SAFE_INTEGER
        let Ymin = Number.MAX_SAFE_INTEGER
        let Ymax = Number.MIN_SAFE_INTEGER
        for (const arr of field.geom.coordinates[0][0]) {
            Xmax = arr[0] > Xmax ? arr[0] : Xmax
            Xmin = arr[0] < Xmin ? arr[0] : Xmin
            Ymax = arr[1] > Ymax ? arr[1] : Ymax
            Ymin = arr[1] < Ymin ? arr[1] : Ymin
        }
        return [(Xmin + Xmax) / 2, (Ymin + Ymax) / 2]
    }
    @Watch('mapData.zoom')
    private getPointRadius() {
        return 15 / this.calculateMetersInPixel()
    }
    private calculateMetersInPixel() {
        const Spixel = ( 2 * Math.PI * 6378137) / Math.pow( 2, this.mapData.zoom + 8 )
        return Spixel
    }
    @Watch('zoom')
    private zoomChanged(currentZoom: number) {
      this.textScale = this.mapZoomToTextScale(currentZoom)
    }

    private mapZoomToTextScale(currentZoom: number) {
      const maxScale = 4
      const minScale = 1
      const zoomRatio = (currentZoom - this.mapData.MIN_ZOOM) / (this.mapData.MAX_ZOOM - this.mapData.MIN_ZOOM)

      return zoomRatio * (maxScale - minScale) + minScale
    }
}
