

















































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import { GetterNames, MutationNames } from '@/store/types'
import { httpGet, httpPost, httpPut } from '@/util/http'
import { apiUrl } from '@/domain/constants'
import { categoryTypes } from '@/data/notificationsData'
import i18n from '@/i18n'
@Component({
    components: {
    }
})
export default class NotificationsView extends Vue {
    @Getter(GetterNames.getSidebarModel) private getSidebarModel!: any
    @Getter(GetterNames.getPermissions) private getPermissions!: any
    @Getter(GetterNames.getLoadingDataNames) private getLoadingDataNames!: any
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: any
    @Getter(GetterNames.IS_SIGNED_IN) private isSignedIn !: boolean
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    @Mutation(MutationNames.setSideboardIsShown) private setSideboardIsShown !: any
    private categoryTypes = categoryTypes
    private notifications: any = []
    private notificationTypes: any = []
    private selectionDropdown = false
    private moreDropdown = false
    private typesOptions = {
        rewind : true,
        autoWidth: true,
        pagination: false,
        gap: '8px',
        perMove: 2
    }
    private pages = {
        count: 1,
        next: null,
        previous: null,
        start_count: 1,
        end_count: 1,
        all_count: 1
    }
    private selectedMessages: any = []
    private sortingString = ''

    get getNotifications() {
        return this.notifications
    }

    get getInfoObject() {
        if (this.currentRoute.params.info) {
            try {
                const info = JSON.parse(this.currentRoute.params.info)
                if (info) {
                    return info
                }
            } catch (err) {
                return null
            }
        } else {
            return null
        }
    }

    private normalizeData(date: any) {
        if (date) {
            return date.substring(8, 10) + '.' + date.substring(5, 7) + '.' + date.substring(0, 4)
        }
        return i18n.t('general.dates.format_date')
    }

    private getCategoryTypeName(value: any) {
        const type = this.categoryTypes.find((x: any) => x.value === value)
        if (type) {
            return type.name
        } else {
            return ''
        }
    }

    private getCategory() {
        if (this.getInfoObject) {
            return this.getInfoObject.category
        } else {
            return 0
        }
    }

    private getType() {
        if (this.getInfoObject) {
            return this.getInfoObject.type
        } else {
            return 0
        }
    }

    private getPage() {
        if (this.getInfoObject) {
            return this.getInfoObject.page
        } else {
            return 1
        }
    }

    private getCategiryColor(category: any) {
        if (category === 3) {
            return '#E0E2D8'
        } else if (category === 2) {
            return '#D51900'
        } else {
            return '#008CDB'
        }
    }

    @Watch('currentRoute')
    private onchangeRoute(newRoute: any, oldRoute: any) {
        let newInfo = null
        let oldInfo = null
        try {
            newInfo = newRoute.params.info
        } catch (err) {
            newInfo = null
        }
        try {
            oldInfo = oldRoute.params.info
        } catch (err) {
            oldInfo = null
        }
        if (newInfo) {
            try {
                const info = JSON.parse(newInfo)
                if (!info.hasOwnProperty('category') || !info.hasOwnProperty('type') || !info.hasOwnProperty('page')) {
                    this.routerReplace(0, 0, 1)
                    return
                }
            } catch (err) {
                this.routerReplace(0, 0, 1)
                return
            }
            if (oldInfo) {
                try {
                    const newInfoJson = JSON.parse(newInfo)
                    const oldInfoJson = JSON.parse(oldInfo)
                    if (!oldInfoJson.hasOwnProperty('category')
                            || !oldInfoJson.hasOwnProperty('type')
                            || !oldInfoJson.hasOwnProperty('page')) {
                        this.getCategoryTypes()
                        return
                    } else {
                        if (oldInfoJson.category !== newInfoJson.category) {
                            this.getCategoryTypes()
                        } else if (oldInfoJson.type !== newInfoJson.type) {
                            this.getNotificationMessages()
                        } else if (oldInfoJson.page !== newInfoJson.page) {
                            this.getNotificationMessages()
                        }
                    }
                } catch (err) {
                    this.getCategoryTypes()
                    return
                }
            } else {
                this.getCategoryTypes()
            }
        } else {
            this.routerReplace(0, 0, 1)
        }
    }

    private routerReplace(categoryId: any, typeId: any, pageId: any) {
        if (this.isSignedIn) {
            this.$router.replace({
                name: 'notifications',
                params: {
                    info: JSON.stringify({
                        category: categoryId,
                        type: typeId,
                        page: pageId
                    })
                }
            })
        }
    }

    private mounted() {
        this.setSideboardIsShown(true)
        this.onchangeRoute(this.currentRoute, null)
    }

    private getCategoryTypes() {
        this.setLoadingDataNames('notificationViewGetCategoryTypes')
        httpGet({
            url:  `${apiUrl}/notifications/types/info/?category=${this.getCategory()}`,
            isSecureRequest: true,
            onSuccess: json => {
                this.notificationTypes = json
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('notificationViewGetCategoryTypes')
                this.getNotificationMessages()
            }
        })
    }

    private getNotificationMessages() {
        this.selectedMessages = []
        this.setLoadingDataNames('notificationViewGetMessages')
        httpPost({
            url:  `${apiUrl}/notifications/?page=${this.getPage()}`,
            isSecureRequest: true,
            body: {
                category: this.getCategory(),
                type: this.getType(),
                filter_string: this.sortingString
            },
            onSuccess: json => {
                if (json.detail) {
                    this.routerReplace(this.getCategory(), this.getType(), 1)
                } else {
                    this.pages = {
                        count: json.count,
                        next: json.next,
                        previous: json.previous,
                        start_count: json.start_count,
                        end_count: json.end_count,
                        all_count: json.all_count
                    }
                    if (json.results) {
                        this.notifications = []
                        this.notifications = json.results
                    }
                }

            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('notificationViewGetMessages')
            }
        })
    }

    private updateMessageStarred(id: any, starred: any) {
        this.setLoadingDataNames('notificationsViewUpdateMessageStar')
        httpPut({
            url: `${apiUrl}/notifications/msg/${id}/`,
            isSecureRequest: true,
            body: {
                is_favorite: starred
            },
            onSuccess: json => {
                this.getCategoryTypes()
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('notificationsViewUpdateMessageStar')
            }
        })
    }

    private changeSelectedMessages(type: any, id: any) {
        if (type === 'single') {
            const index = this.selectedMessages.findIndex((x: any) => x === id)
            if (index !== -1) {
                this.selectedMessages.splice(index, 1)
            } else {
                this.selectedMessages.push(id)
            }
        } else if (type === 'starred') {
            this.selectedMessages = []
            for (const notification of this.getNotifications) {
                if (notification.is_favorite && this.selectedMessages.indexOf(notification.id) === -1) {
                    this.selectedMessages.push(notification.id)
                }
            }
        } else if (type === 'readed') {
            this.selectedMessages = []
            for (const notification of this.getNotifications) {
                if (notification.is_read && this.selectedMessages.indexOf(notification.id) === -1) {
                    this.selectedMessages.push(notification.id)
                }
            }
        } else if (type === 'notreaded') {
            this.selectedMessages = []
            for (const notification of this.getNotifications) {
                if (!notification.is_read && this.selectedMessages.indexOf(notification.id) === -1) {
                    this.selectedMessages.push(notification.id)
                }
            }
        } else if (type === 'notstarred') {
            this.selectedMessages = []
            for (const notification of this.getNotifications) {
                if (!notification.is_favorite && this.selectedMessages.indexOf(notification.id) === -1) {
                    this.selectedMessages.push(notification.id)
                }
            }
        } else if (type === 'all') {
            if (this.getNotifications.length === this.selectedMessages.length) {
                this.selectedMessages = []
            } else {
                for (const notification of this.getNotifications) {
                    if (this.selectedMessages.indexOf(notification.id) === -1) {
                        this.selectedMessages.push(notification.id)
                    }
                }
            }
        }
    }

    private checkIfSelected(type: any, id: any) {
        if (type === 'single') {
            return this.selectedMessages.includes(id)
        } else if (type === 'all') {
            return this.selectedMessages.length === this.notifications.length
        }
        return false
    }

    private MessageActions(action: any, ids: any) {
        if (action === 'DELETE') {
            if (!confirm( i18n.t('messages.questions.delete.title') as string )) return
        }
        this.setLoadingDataNames('notificationViewDeleteMessages')
        httpPost({
            url:  `${apiUrl}/notifications/msg/list/`,
            isSecureRequest: true,
            body: {
                msg_list: ids,
                mode: action
            },
            onSuccess: json => {
                this.getCategoryTypes()
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('notificationViewDeleteMessages')
            }
        })
    }

    private changeCategory(category: any) {
        if (this.getInfoObject) {
            this.$router.replace({
                name: 'notifications',
                params: {
                    info: JSON.stringify({
                        category,
                        type: this.getInfoObject.type,
                        page: this.getInfoObject.page
                    })
                }
            }).catch(error => {/**/})
        }
    }

    private changeType(type: any) {
        if (this.getInfoObject) {
            this.$router.replace({
                name: 'notifications',
                params: {
                    info: JSON.stringify({
                        category: this.getInfoObject.category,
                        type,
                        page: this.getInfoObject.page
                    })
                }
            }).catch(error => {/**/})
        }
    }

    private nextPage() {
        if (this.getInfoObject) {
            if (this.pages.next) {
                this.routerReplace(this.getCategory(), this.getType(), this.pages.next)
            }
        }
    }

    private previousPage() {
        if (this.getInfoObject) {
            if (this.pages.previous) {
                this.routerReplace(this.getCategory(), this.getType(), this.pages.previous)
            }
        }
    }

    private closeSelectionDropdown() {
        this.selectionDropdown = false
    }

    private closeMoreDropdown() {
        this.moreDropdown = false
    }

    private openNotificationManage() {
        this.$router.push({
            name: 'notificationmanage',
            params: {
                info: this.currentRoute.params.info ? this.currentRoute.params.info : null
            }
        })
    }

    private openNotificationMessage(id: any) {
        this.$router.push({
            name: 'notificationmessage',
            params: {
                info: this.currentRoute.params.info ? this.currentRoute.params.info : null,
                messageId: id.toString()
            }
        })
    }

    private close() {
        this.setSideboardIsShown(false)
    }
}
