






import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import { GetterNames, MutationNames } from '@/store/types'

@Component
export default class MapScreen extends Vue {
    @Getter(GetterNames.getMapScreen) private getMapScreen!: any
    @Getter(GetterNames.getPrintScreen) private getPrintScreen!: any
    @Mutation(MutationNames.setMapScreen) private setMapScreen!: any
    @Mutation(MutationNames.setPrintScreen) private setPrintScreen!: any
    @Prop() private map!: any

    @Watch('getMapScreen.start')
    private downloadMap() {
        if (!this.getMapScreen.start) return
        const map = this.map.$map
        if (!map) return
        map.once('rendercomplete', async (event: any) => {
            const canvas = event.context.canvas
            this.setMapScreen({start: false, blobImg: canvas.toDataURL("image/png")})
        })
        map.renderSync()
    }

    @Watch('getPrintScreen')
    private onstartPrintScreen() {
        if (this.getPrintScreen) {
            const map = this.map.$map
            if (!map) {
                this.setPrintScreen(false)
                return
            }
            map.once('rendercomplete', async (event: any) => {
                const canvas = event.context.canvas
                const context = canvas.getContext('2d')
                const link: any = document.getElementById('link')
                link.setAttribute('download', 'map_screen.png')
                const result = this.watermarkedDataURL(canvas, 'EGISTIC')
                link.setAttribute('href', result)
                link.click()
                this.setPrintScreen(false)
            })
            map.renderSync()
            this.setPrintScreen(false)
        }
    }

    private watermarkedDataURL(canvas: any, text: any) {
        const tempCanvas = document.createElement('canvas')
        const tempCtx: any = tempCanvas.getContext('2d')
        const cw = tempCanvas.width = canvas.width
        const ch = tempCanvas.height = canvas.height
        tempCtx.drawImage(canvas, 0, 0)
        tempCtx.font = "36px verdana"
        const textWidth = tempCtx.measureText(text).width
        tempCtx.globalAlpha = .50
        tempCtx.translate(-1 * cw / 2, ch / 2)
        tempCtx.rotate(-Math.PI / 4)
        for (let i = 1; i < Math.round(cw / (textWidth)); i++) {
            for (let j = 1; j < Math.round(cw / 24); j++) {
                tempCtx.fillStyle = 'rgba(0, 0, 0, 0.3)'
                tempCtx.fillText(text, 2 * i * (textWidth + 50), 2 * j * 100)
                tempCtx.fillStyle = 'rgba(255, 255, 255, 0.5)'
                tempCtx.fillText(text, 2 * i * (textWidth + 50) + 2, 2 * j * 100 + 2)

            }
        }
        tempCtx.rotate(45 * Math.PI / 180)
        return tempCanvas.toDataURL("image/png").replace("image/png", "image/octet-stream")
    }
}
