




















































































































import { Component, Vue, Emit } from 'vue-property-decorator'
import { GetterNames, MutationNames } from '@/store/types'
import { Getter, Mutation } from 'vuex-class'
import DateRangePicker from "@/components/DateRangePicker.vue"
import NewTractor from "@/views/MainPage/HomeView/Sideboards/FarmBoard/TelematicsBoard/FarmTelematics/components/NewTractor"
import Folder from "@/views/MainPage/HomeView/Sideboards/FarmBoard/TelematicsBoard/FarmTelematics/components/Folder"
import { egisticLogo, pdfWaterMark } from '@/data/pdfImageData'
import { makePDF, PdfActions } from '@/utils/services'
import {httpGet, httpPost} from "@/util/http"
import {apiUrl} from "@/domain/constants"
import {curry} from "lodash"
import i18n from "@/i18n"

interface IFolder {
  name: string,
  tractors: ITractor[]
}
interface ITractor {
  tractor: {
    name: string,
    uid: string
  }
}
interface IReport {
  start_time: string,
  end_time: string
}
@Component({
  components: {
    DateRangePicker
  }
})
export default class NewAgregateModel extends Vue {
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: any
    @Getter(GetterNames.getLoadingDataNames) private getLoadingDataNames!: any
    @Getter(GetterNames.getTelematicsInfo) private getTelematicsInfo!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    private pdfWaterMark: any = pdfWaterMark
    private egisticLogo: any = egisticLogo
    private agregates: any = []
    private isShown = false
    private totalTractors = 0
    private checkAll = false
    private folders: Folder[] = []
    private choosenTractors: NewTractor[] = []
    private reports: IReport[] = []
    private chooseInputValue = ''
    private reportCount = 0
    private start: any = ''
    private end: any = ''
    private startDate: any = ''
    private endDate: any = ''
    private startTime: any = ''
    private endTime: any = ''
    private startDateError: any = ''
    private endDateError: any = ''
    private chooseTractorError: any = ''

    private getFoldersData() {
      this.folders = []
      this.totalTractors = 0
      this.agregates.forEach((car: any) => {
        const currentFolder = this.folders.find(folder => folder.name === car.category_name)
        if (currentFolder) {
          currentFolder.tractors.push(new NewTractor(car.name, car.id, car.model_name, car.uid, car.category_name))
          currentFolder.quantity++
        } else {
          const tractors: NewTractor[] = []
          tractors.push(new NewTractor(car.name, car.id, car.name, car.uid, car.category_name))
          const newFolder = new Folder(car.category_name, tractors, tractors.length)
          this.folders.push(newFolder)
        }
        this.totalTractors++
      })
    }

    private chooseTractor(folder: number, tractor: number, fromFolder?: boolean) {
        const currentTractor = this.folders[folder].tractors[tractor]
        const currentFolder = this.folders[folder]
        if (currentTractor.checked) {
          currentTractor.checked = false
          fromFolder ? currentFolder.checked = true : currentFolder.checked = false
          this.choosenTractors = this.choosenTractors.filter(item => item.name !== currentTractor.name)
        } else {
          currentTractor.checked = true
          if (!fromFolder) {
            currentFolder.checked = !currentFolder.tractors.find(item => !item.checked)
          }
          if (!this.choosenTractors.find(item => item.name === currentTractor.name)) {
            this.choosenTractors.push(currentTractor)
          }
        }
        if (this.choosenTractors.length > 1) {
          this.chooseInputValue = `${this.choosenTractors[0].name} +${this.choosenTractors.length - 1}`
        } else if (this.choosenTractors.length === 1) {
          this.chooseInputValue = String(this.choosenTractors[0].name)
        } else {
          this.chooseInputValue = ''
        }
    }

    private chooseFolder(index: number) {
        this.folders[index].checked = !this.folders[index].checked
        this.folders[index].tractors.forEach((tractor, idx) => {
          this.folders[index].checked ? tractor.checked = false : tractor.checked = true
          this.chooseTractor(index, idx, this.folders[index].checked)
        })
    }

    private chooseAll() {
      this.checkAll = !this.checkAll
      this.folders.forEach((folder, index) => {
        folder.checked = this.checkAll
        folder.tractors.forEach((tractor, idx) => {
          tractor.checked = !this.checkAll
          this.chooseTractor(index, idx)
        })
      })
    }

    private setNewDataRange(startDate: any, endDate: any) {
      if (startDate) {
        this.start = new Date(startDate)
        this.startDate = this.start.toLocaleDateString("ru")
        this.startTime = this.start.toLocaleTimeString('ru')
      }
      if (endDate) {
        this.end = new Date(endDate)
        this.endTime = this.end.toLocaleTimeString('ru')
        this.endDate = this.end.toLocaleDateString("ru")
      }
    }

    private getHours(report: any) {
      if (report && report.working_minutes) {
        const hours = Math.floor(report.working_minutes / 60)
        const minutes = Math.round(report.working_minutes - 60 * hours)
        return `${hours} часов ${minutes} минуты`
      } else return '0 часов'
    }

    private getDistance(report: any) {
      if (report && report.total_distance) {
        const km = Math.floor(report.total_distance / 1000)
        const meters = Math.round(report.total_distance - 1000 * km)
        return `${km} км ${meters} метров`
      } else return '0 км'
    }

    private getMaxSpeed(report: any) {
      if (report && report.max_speed) {
        return `${report.max_speed} км/ч`
      } else return '0 км/ч'
    }

  private normalizeTime(time: any) {
    if (time) {
      return `${time.substring(8, 10)}.${time.substring(5, 7)}.${time.substring(0, 4)} ${time.substring(11)}`
    } else return ''
  }

  private normalizeDate(date: any) {
    const d = new Date(date)
    return d.toLocaleString('ru', { hour12: false, day: 'numeric', month: 'numeric', year: 'numeric',  hour: "numeric", minute: "numeric", second: 'numeric'})
  }

  private normalizeDuration(duration: any) {
    if (duration > 60) {
      const hours = Math.floor(duration / 60)
      const minutes = Math.round(duration - 60 * hours)
      return `${hours} часов ${minutes} минуты`
    } else {
      return `${Math.round(duration) } минуты`
    }
  }

    private getAgregates() {
      this.setLoadingDataNames('modelGetCars')
      httpGet({
        url:  `${apiUrl}/yields/tractor/`,
        isSecureRequest: true,
        onSuccess: json => {
          this.agregates = json
          this.getFoldersData()
        },
        onError: error => {
          console.log(error)
        },
        doFinally: () => {
          this.setLoadingDataNames('modelGetCars')
        }
      })
    }

    private checkForError() {
        let counter = 0
        if (this.choosenTractors.length === 0) {
          this.chooseTractorError = 'Выберите технику'
          counter++
        } else {
          this.chooseTractorError = ''
        }
        if (!this.startDate) {
          this.startDateError = 'Выберите дату'
          counter++
        } else {
          this.startDateError = ''
        }
        if (!this.endDate) {
        this.endDateError = 'Выберите дату'
        counter++
      } else {
        this.endDateError = ''
      }
        return counter > 0
    }

    private getTractorsReports() {
      if (this.checkForError()) return
      if (this.choosenTractors.length > 0 && this.startDate && this.endDate) {
          this.choosenTractors.forEach(tractor => {
            this.fetchReport(tractor.id, this.choosenTractors.length)
          })
      }
    }

    private fetchReport(carId: number, quantity: number) {
      if (this.start && this.end) {
        this.setLoadingDataNames('modelGetCarData')
        httpPost({
          url:  `${apiUrl}/yields/tractor/report/`,
          isSecureRequest: true,
          detailResponse: true,
          body: {
            tractor: carId,
            start_time: this.start,
            end_time: this.end
          },
          onSuccess: json => {
            this.reportCount++
            if (json.ok) {
              this.reports.push(json.json)
            }
          },
          onError: error => {
            console.log(error)
          },
          doFinally: () => {
            if (this.reportCount === quantity) {
              this.printPdf()
              this.reportCount = 0
              this.reports = []
            }
            this.setLoadingDataNames('modelGetCarData')
          }
        })
      }
    }

  private printPdf() {
    if (!this.reports[0]) return
    const startDate = (new Date(this.reports[0].start_time)).toLocaleString('ru')
    const endDate = (new Date(this.reports[0].end_time)).toLocaleString('ru')
    const contents: object[] = []
    this.reports.forEach((report, index) => {
      const currentReport = [
        {
          image: this.egisticLogo,
          margin: [359, 24, 0, 0],
          width: 91.14,
          height: 30
        },
          {
            text: `Отчёт по работе техники за ${startDate} - ${endDate}`,
            fontSize: 16,
            bold: true,
            margin: [0, 24, 0, 0],
            alignment: 'center'
          },

          {
            layout: 'lightHorizontalLines',
            margin: [24, 24, 24, 0],
            table: {
              body: [
                [{text: this.choosenTractors[index].name, bold: true, fontSize: 16, margin: [24, 0, 0, 0]}, '', '', '', '', ''],
                [{text: i18n.t('headers.workspace.autopark.add_machine.model'), fontSize: 14, margin: [24, 12, 0, 0], color: '#878882'},
                  {text: this.choosenTractors[index].model, fontSize: 14, margin: [24, 12, 12, 0]},
                  {text: i18n.t('headers.workspace.autopark.add_machine.state_num'), fontSize: 14, margin: [24, 12, 0, 0], color: '#878882'},
                  {text: this.choosenTractors[index].uid, fontSize: 14, margin: [24, 12, 12, 0]},
                  {text: i18n.t('headers.workspace.autopark.add_machine.category.title'), fontSize: 14, margin: [24, 12, 0, 0], color: '#878882'},
                  {text: this.choosenTractors[index].category, fontSize: 14, margin: [24, 12, 12, 0]}]
              ]
            }
          },
          {
            layout: 'noBorders',
            margin: [24, 24, 24, 0],
            table: {
              body: [
                [
                  {
                    text: `Рабочие часы: `,
                    bold: true,
                    fontSize: 14,
                    color: '#1c1c1c'
                  },
                  {
                    text: this.getHours(report),
                    fontSize: 14,
                    color: '#1c1c1c'
                  }
                ],
                [
                  {
                    text: `Пройденное расстояние: `,
                    bold: true,
                    fontSize: 14,
                    color: '#1c1c1c'
                  },
                  {
                    text: this.getDistance(report),
                    fontSize: 14,
                    color: '#1c1c1c'
                  }
                ],
                [
                  {
                    text: `MAX скорость: `,
                    bold: true,
                    fontSize: 14,
                    color: '#1c1c1c'
                  },
                  {
                    text: this.getMaxSpeed(report),
                    fontSize: 14,
                    color: '#1c1c1c'
                  }
                ]
              ]
            }
          },
          {
            text: `Остановки техники`,
            fontSize: 16,
            bold: true,
            margin: [0, 24, 0, 0],
            alignment: 'center'
          },
          {
            margin: [24, 12, 24, 0],
            table: {
              headerRows: 1,
              widths: ['*', '*', '*'],
              body: this.getStopsBody(report)
            }
          },
          {
            text: `Потери связи`,
            fontSize: 16,
            bold: true,
            margin: [0, 24, 0, 0],
            alignment: 'center'
          },
          {
            margin: [24, 12, 24, 0],
            table: {
              headerRows: 1,
              widths: ['*', '*', '*'],
              body: this.getNoSignalBody(report)
            }
          },
          {
            text: `---------------------------------------------------`,
            fontSize: 16,
            bold: true,
            margin: [0, 120, 0, 0],
            alignment: 'center'
          }

      ]
      contents.push(currentReport)
    })
    const docDefinition = {
      pageSize: {
        width: 842,
        height: 595
      },
      pageMargins: [16, 16, 16, 16],
      background: [
        {
          image: this.pdfWaterMark,
          margin: [400, 0, 0, 0]
        }
      ],
      content: contents
    }
    makePDF(PdfActions.PRINT, docDefinition)
  }

  private getStopsBody(report: any) {
    const body = []
    body.push([
      {text: 'Начало', fontSize: 12, bold: true, color: '#1c1c1c'},
      {text: 'Конец', fontSize: 12, bold: true, color: '#1c1c1c'},
      {text: 'Длительность', fontSize: 12, bold: true, color: '#1c1c1c'}
    ])
    report.stops.forEach((stop: any) => {
      body.push([
        {text: this.normalizeTime(stop.start_time), fontSize: 12, color: '#1c1c1c'},
        {text: this.normalizeTime(stop.end_time), fontSize: 12, color: '#1c1c1c'},
        {text: this.normalizeDuration(stop.interval_in_minuts), fontSize: 12, color: '#1c1c1c'}
      ])
    })
    // if (report.stops.length === 0) {
    //   body.push([
    //     {text: 'Нет данных', fontSize: 12, color: '#1c1c1c'}
    //   ])
    // }
    return body
  }

  private getNoSignalBody(report: any) {
    const body = []
    body.push([
      {text: 'Начало', fontSize: 12, bold: true, color: '#1c1c1c'},
      {text: 'Конец', fontSize: 12, bold: true, color: '#1c1c1c'},
      {text: 'Длительность', fontSize: 12, bold: true, color: '#1c1c1c'}
    ])
    report.no_signals.forEach((stop: any) => {
      body.push([
        {text: this.normalizeTime(stop.start_time), fontSize: 12, color: '#1c1c1c'},
        {text: this.normalizeTime(stop.end_time), fontSize: 12, color: '#1c1c1c'},
        {text: this.normalizeDuration(stop.interval_in_minuts), fontSize: 12, color: '#1c1c1c'}
      ])
    })
    // if (report.no_signals.length === 0) {
    //   body.push([
    //     {text: 'Нет данных', fontSize: 12, color: '#1c1c1c'}
    //   ])
    // }
    return body
  }

    private show() {
      this.isShown = true
      this.getAgregates()
    }

    private hide() {
      this.isShown = false
    }

}
