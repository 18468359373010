import HandBookView from '@/views/MainPage/HandBookView/Index.vue'
import HandBookCategory from '@/views/MainPage/HandBookView/Category.vue'
import CategoryObject from '@/views/MainPage/HandBookView/CategoryObject.vue'

const routes = [
    {
        path: 'handbook',
        name: 'handbook',
        component: HandBookView
    },
    {
        path: 'handbook/:category',
        name: 'category',
        component: HandBookCategory
    },
    {
        path: 'handbook/:category/:categoryId',
        name: 'categoryobject',
        component: CategoryObject
    }
]

export default routes