






























import { Component, Vue } from 'vue-property-decorator'
import Sideboard from '@/components/Sideboard.vue'
import i18n from '@/i18n'
import SearchByCadastre from './SearchByCadastre/SearchByCadastre.vue'
import SearchOnMap from './SearchOnMap/SearchOnMap.vue'
import { Getter, Mutation } from 'vuex-class'
import { GetterNames, MutationNames } from '@/store/types'
@Component({
    components: {
        Sideboard,
        SearchByCadastre,
        SearchOnMap
    },
    beforeRouteLeave(to: any, from: any, next: any) {
        if (to.params.isDataSaved === 'true') {
            next()
        } else if (confirm(i18n.t('messages.confirm.exit.not_saved') as string)) {
            next()
        } else {
            next(false)
        }
    }
})
export default class RegisterFarm extends Vue {
    @Getter(GetterNames.getRegisterFarms) private getRegisterFarms!: any
    @Mutation(MutationNames.setRegisterFarms) private setRegisterFarms!: any

    private mounted() {
        this.setRegisterFarms({addType: 0})
    }

    private changeAddType(type: any) {
        if (type === 0) {
            this.setRegisterFarms({ addType: 0 })
            this.$ga.event('register farm', 'by_cadastre_number', 'from registercadastre', 1)
        } else if (type === 1) {
            this.setRegisterFarms({ addType: 1 })
            this.$ga.event('register farm', 'on_map', 'from registercadastre', 1)
        }
    }
}
