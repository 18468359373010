






















































































import { Component, Vue, Emit } from 'vue-property-decorator'
import { httpGet } from '@/util/http'
import { apiUrl } from '@/domain/constants'
import { GetterNames, MutationNames } from '@/store/types'
import { Getter, Mutation } from 'vuex-class'
import i18n from '@/i18n'
import {egisticLogo, pdfWaterMark} from '@/data/pdfImageData'
import { makePDF, PdfActions } from '@/utils/services'
@Component({
    components: {
    }
})
export default class NewOperationModel extends Vue {
    @Getter(GetterNames.getLoadingDataNames) private getLoadingDataNames!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    private egisticLogo: any = egisticLogo
    private pdfWaterMark: any = pdfWaterMark
    private isShown = false
    private history: any = {
        title: '',
        oldtitle: {
            name: '',
            date: ''
        },
        array: []
    }
    private excelData: any = []

    private normalizeDate(date: any) {
        if (date === '') return i18n.t('general.dates.format_date')
        return date.substring(8, 10) + '.' + date.substring(5, 7) + '.' + date.substring(2, 4)
    }

    private show(id: any, name: any) {
        this.isShown = true
        this.setLoadingDataNames('productHistoryGetData')
        this.history = {
            title: name,
            oldtitle: {
                name: '',
                date: ''
            },
            array: []
        }
        httpGet({
            url:  `${apiUrl}/storage/product/report/history/${id}`,
            isSecureRequest: true,
            onSuccess: json => {
                const edited = json.edited
                const infos = json.infos
                edited.sort( (a: any, b: any) => ((new Date(b.created_at) as any) - (new Date(a.created_at) as any)))
                for (const edit of edited) {
                    if (edit.new_title !== edit.old_title) {
                        this.history.oldtitle.name = edit.old_title
                        this.history.oldtitle.date = this.normalizeDate(edit.created_at)
                        break
                    }
                }
                for (const edit of edited) {
                    this.history.array.push({
                        date: edit.created_at,
                        type: i18n.t('headers.workspace.storage.logs.edit'),
                        new_title: edit.new_title,
                        old_title: edit.old_title,
                        new_amount: edit.new_amount,
                        old_amount: edit.old_amount,
                        new_unit: edit.new_unit,
                        old_unit: edit.old_unit,
                        new_cost: edit.new_cost,
                        old_cost: edit.old_cost
                    })
                }
                for (const info of infos) {
                    let infotype = ''
                    if (info.income_operation) {
                        infotype = i18n.t('headers.workspace.storage.logs.income') as string
                    }
                    if (info.outcome_operation) {
                        infotype = i18n.t('headers.workspace.storage.logs.expense') as string
                    }
                    if (info.transfer_operation) {
                        infotype = i18n.t('headers.workspace.storage.logs.movement') as string
                    }
                    if (info.return_operation) {
                        infotype = i18n.t('headers.workspace.storage.logs.return') as string
                    }
                    if (info.write_off_operation) {
                        infotype = i18n.t('headers.workspace.storage.logs.writeoff') as string
                    }
                    this.history.array.push({
                        date: info.date,
                        type: infotype,
                        new_title: info.title,
                        old_title: info.title,
                        new_amount: info.amount,
                        old_amount: info.amount,
                        new_unit: info.unit,
                        old_unit: info.unit,
                        new_cost: info.cost,
                        old_cost: info.cost
                    })
                }
                this.history.array.sort((a: any, b: any) => ((new Date(b.date) as any) - (new Date(a.date) as any)))
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('productHistoryGetData')
            }
        })
    }

    @Emit('reload')
    private reload() {
        return {}
    }

    private hide() {
        this.isShown = false
    }

    private getExcelData() {
        this.excelData = []
        if (this.history.array.length === 0) {
            this.excelData.push({
                [i18n.t('headers.workspace.storage.logs.fields.date') as string]: '',
                [i18n.t('headers.workspace.storage.logs.fields.operation_type') as string]: '',
                [i18n.t('headers.workspace.storage.logs.fields.amount') as string]: '',
                [i18n.t('headers.workspace.storage.logs.fields.scaling') as string]: '',
                [i18n.t('headers.workspace.storage.logs.fields.cost') as string]: '',
                [i18n.t('headers.workspace.storage.logs.fields.sum') as string]: ''
            })
        } else {
            this.history.array.forEach((operation: any, index: any) => {
                this.excelData.push({
                    [i18n.t('headers.workspace.storage.logs.fields.date') as string]: this.normalizeDate(operation.date),
                    [i18n.t('headers.workspace.storage.logs.fields.operation_type') as string]: operation.type,
                    [i18n.t('headers.workspace.storage.logs.fields.amount') as string]: operation.new_amount,
                    [i18n.t('headers.workspace.storage.logs.fields.scaling') as string]: operation.new_unit,
                    [i18n.t('headers.workspace.storage.logs.fields.cost') as string]: operation.new_cost,
                    [i18n.t('headers.workspace.storage.logs.fields.sum') as string]: Number(operation.new_amount) * Number(operation.new_cost)
                })
            })
        }
        setTimeout(() => this.downloadExcel(), 1000)
    }

    private downloadExcel() {
        const downloader: any = document.getElementById('downloadExcel')
        if (downloader) {
            downloader.click()
        }
    }

    private printPdf() {
        const date = new Date()
        const today = date.toLocaleString('ru', { hour12: false, day: 'numeric', month: 'numeric', year: 'numeric',  hour: "numeric", minute: "numeric"})
        const header: any = [
            {
                width: 'auto',
                text: i18n.t('headers.workspace.storage.logs.fields.title') as string + ':',
                fontSize: 14,
                bold: true,
                color: '#1c1c1c'
            },
            {
                width: 'auto',
                margin: [24, 0, 0, 0],
                text: this.history.title,
                fontSize: 14,
                color: '#1c1c1c'
            }
        ]
        if (this.history.oldtitle.name !== '') {
            header.push(
                {
                    width: 'auto',
                    margin: [4, 0, 0, 0],
                    text: '(',
                    fontSize: 14,
                    color: '#1c1c1c'
                },
                {
                    width: 'auto',
                    text: this.history.oldtitle.name,
                    fontSize: 14,
                    color: '#878882',
                    decoration: 'lineThrough'
                },
                {
                    width: 'auto',
                    margin: [4, 0, 0, 0],
                    text: ' изм. ',
                    fontSize: 14,
                    color: '#1c1c1c'
                },
                {
                    width: 'auto',
                    margin: [4, 0, 0, 0],
                    text: this.history.oldtitle.date,
                    fontSize: 14,
                    color: '#1c1c1c'
                },
                {
                    width: 'auto',
                    text: ')',
                    fontSize: 14,
                    color: '#1c1c1c'
                }
            )
        }
        const docDefinition = {
            pageSize: {
                width: 842,
                height: 595
            },
            pageMargins: [30, 30, 30, 30],
            background: [
                {
                    image: this.pdfWaterMark,
                    margin: [386, 0, 0, 0]
                }
            ],
            content: [
                {
                    image: this.egisticLogo,
                    margin: [0, 10, 0, 0],
                    width: 91.14,
                    height: 30,
                    alignment: 'center'
                },
                {
                    text: i18n.t('headers.workspace.storage.logs.history'),
                    fontSize: 16,
                    bold: true,
                    margin: [0, 24, 0, 0],
                    alignment: 'center'
                },
                {
                    text: today,
                    fontSize: 16,
                    bold: true,
                    margin: [0, 8, 0, 0],
                    alignment: 'center'
                },
                {
                    margin: [16, 16, 0, 24],
                    columns: header
                },
                {
                    table: {
                        widths: [70, 130, 120, 120, 120, 150],
                        headerRows: 1,
                        body: this.getPdfTableBody()
                    }
                }
            ]
        }
        makePDF(PdfActions.PRINT, docDefinition)
    }

    private getPdfTableBody() {
        const table: any = []
        const row: any = []
        const tableHeaders = [
            i18n.t('headers.workspace.storage.logs.fields.date'),
            i18n.t('headers.workspace.storage.logs.fields.operation_type'),
            i18n.t('headers.workspace.storage.logs.fields.amount'),
            i18n.t('headers.workspace.storage.logs.fields.scaling'),
            i18n.t('headers.workspace.storage.logs.fields.cost'),
            i18n.t('headers.workspace.storage.logs.fields.sum')]
        tableHeaders.forEach((e: any) => {
            row.push({text: e, fontSize: 14})
        })
        table.push(row)
        this.history.array.forEach((operation: any) => {
            table.push([
                {text: this.normalizeDate(operation.date), fortSize: 14},
                {text: operation.type, fortSize: 14},
                {columns: [
                    {width: 'auto', text: operation.old_amount !== operation.new_amount ? operation.old_amount : '',
                        fontSize: 14, color: '#878882', decoration: 'lineThrough'},
                    {width: 'auto', text: operation.new_amount, margin: [4, 0, 0, 0], fontSize: 14}
                ]},
                {columns: [
                    {width: 'auto', text: operation.old_unit !== operation.new_unit ? operation.old_unit : '',
                        fontSize: 14, color: '#878882', decoration: 'lineThrough'},
                    {width: 'auto', text: operation.new_unit, margin: [4, 0, 0, 0], fontSize: 14}
                ]},
                {columns: [
                    {width: 'auto', text: operation.old_cost !== operation.new_cost ? operation.old_cost + i18n.t('general.units.tg') : '',
                        fontSize: 14, color: '#878882', decoration: 'lineThrough'},
                    {width: 'auto', text: operation.new_cost + ' тг', margin: [4, 0, 0, 0], fontSize: 14}
                ]},
                {text: (Number(operation.new_cost) * Number(operation.new_amount)).toString() + i18n.t('general.units.tg'), fontSize: 14}
            ])
        })
        return table
    }

}
