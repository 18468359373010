



































































import {Component, Vue, Emit, Prop} from 'vue-property-decorator'
import { Getter, Action, Mutation } from 'vuex-class'
import { GetterNames, ActionNames, MutationNames } from '@/store/types'
import { httpPut, httpPost } from '@/util/http'
import { apiUrl } from '@/domain/constants'
import i18n from '@/i18n'
@Component
export default class NewSeasonModel extends Vue {
    @Action(ActionNames.fetchFarmsList) private fetchFarmsList !: () => void
    @Getter(GetterNames.getFarmFromRoute) private getFarmFromRoute!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    @Prop({type: Boolean, default: () => true}) private isCurrent: any
    private isShown = false
    private isUpdate = false
    private seasonForUpdate: any = null
    private seasonDropdown = false
    private chosenSeason = ''
    private seasonsArr: any = []
    private fromDate = ''
    private toDate = ''

    get getFromDate() {
        if (this.fromDate === '') return i18n.t('general.dates.format_date')
        return this.fromDate.substring(8, 10) + '.' + this.fromDate.substring(5, 7) + '.' + this.fromDate.substring(2, 4)
    }
    get getToDate() {
        if (this.toDate === '') return i18n.t('general.dates.format_date')
        return this.toDate.substring(8, 10) + '.' + this.toDate.substring(5, 7) + '.' + this.toDate.substring(2, 4)
    }

    private selectSeason(season: any) {
        if  (season.toString().split('').length === 4) {
            this.seasonDropdown = false
            this.chosenSeason = season
            this.fromDate = this.chosenSeason + '-05-01'
            this.toDate = this.chosenSeason + '-10-30'
        }
    }

    private updateSeason() {
        this.setLoadingDataNames('newSeasonUpdateSeason')
        httpPut({
            url: `${apiUrl}/new/seasons/${this.seasonForUpdate.id}/`,
            isSecureRequest: true,
            body: {
                name: this.chosenSeason,
                start_date: this.fromDate,
                end_date: this.toDate,
                group_user: this.getFarmFromRoute.id,
                is_current: this.isCurrent
            },
            onSuccess: json => {
                alert(i18n.t('messages.success.season_updated'))
                this.$emit('reload', this.chosenSeason)
                this.hide()
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('newSeasonUpdateSeason')
            }
        })
    }

    private update(season: any) {
        this.isShown = true
        this.isUpdate = true
        this.fromDate = season.start_date
        this.toDate = season.end_date
        this.chosenSeason = season.name
        this.seasonsArr = []
        this.seasonForUpdate = season
    }

    // @Emit('reload')
    // private reload(newSeason: any) {
    //     return {newSeason}
    // }

    private createSeason() {
        if (this.chosenSeason !== '') {
            this.setLoadingDataNames('newSeasonCreateSeason')
            httpPost({
                url:  `${apiUrl}/new/seasons/`,
                isSecureRequest: true,
                body: {
                    name: this.chosenSeason,
                    start_date: this.fromDate,
                    end_date: this.toDate,
                    group_user: this.getFarmFromRoute.id,
                    is_current: true
                },
                onSuccess: json => {
                    alert(i18n.t('messages.success.season_added'))
                    this.$emit('newSeason', json)
                    this.hide()
                },
                onError: error => {
                    console.log(error)
                },
                doFinally: () => {
                    this.setLoadingDataNames('newSeasonCreateSeason')
                }
            })
        }
    }

    private show(seasons: any) {
        const currentYear = new Date().getFullYear().toString()
        const thisYear = Number(new Date().getFullYear())
        this.seasonsArr = []
        this.seasonsArr = Array.from({length: thisYear - 1990 + 1}, (value: any, index: any) => 1990 + index).reverse()
        if (this.seasonsArr.length > 0) {
            this.chosenSeason = this.seasonsArr[0]
            this.fromDate = this.chosenSeason + '-05-01'
            this.toDate = this.chosenSeason + '-10-30'
        } else {
            this.chosenSeason = ''
        }
        this.isShown = true
        this.isUpdate = false
    }

    private hide() {
        this.isShown = false
    }

    private closeSeasonDropdown() {
        this.seasonDropdown = false
    }
}
