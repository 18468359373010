
































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import { GetterNames, MutationNames } from '@/store/types'
import { Route } from 'vue-router'
import { httpGet, httpDelete } from '@/util/http'
import { baseUrl, apiUrl } from '@/domain/constants'
import Farm from '@/models/Farm'
import i18n from '@/i18n'
import { CultureColors } from '@/data/FieldCulture'
import RegisterHistoryModal from './RegisterHistoryModal.vue'
import {egisticLogo, pdfWaterMark} from '@/data/pdfImageData'
import { downloadExcelData, makePDF, PdfActions } from '@/utils/services'
@Component({
  components: {
      RegisterHistoryModal
  }
})
export default class CropRotationBoard extends Vue {
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: Route
    @Getter(GetterNames.getFarmFromRoute) private getFarmFromRoute!: Farm
    @Getter(GetterNames.getSidebarModel) private getSidebarModel!: any
    @Getter(GetterNames.getLoadingDataNames) private getLoadingDataNames!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    @Mutation(MutationNames.setPlanningData) private setPlanningData!: any
    private egisticLogo: any = egisticLogo
    private pdfWaterMark: any = pdfWaterMark
    private CultureColors = CultureColors
    private searchText = ''
    private cropRotation: any = []
    private cultureData: any = []
    private otherCultures: any = []
    private options: any = {
        status: false,
        index: '-1'
    }
    private sorting = {
        type: 'field',
        alphabetically: true
    }

    private copyInputData(rotation: any) {
      const cultureData = {
        'id': rotation.crop_rotation.id,
        'culture': rotation.crop_rotation.culture,
        'parentCulture': rotation.crop_rotation.parentCulture,
        'sort': rotation.crop_rotation.sort
      }
      this.$router.push({name: 'clonecropdata',
        params: {
          culture: JSON.stringify(cultureData)
        }})
    }


    private getColor(history: any) {
        const result = this.CultureColors.defined.find((x: any) => {
            const crop = x.crop.toLowerCase()
            if (history.parent_culture && history.parent_culture.toLowerCase().includes(crop)) {
                return x
            } else if (history.culture && history.culture.toLowerCase().includes(crop)) {
                return x
            }
        })
        if (result) {
            return result.color
        }
        const result2 = this.otherCultures.find((x: any) => {
            if (x.culture && history.culture) {
                if (x.culture.toLowerCase().includes(history.culture.toLowerCase()) ||
                    history.culture.toLowerCase().includes(x.culture.toLowerCase())) {
                    return x
                }
            } else if (x.culture === history.culture || (x.culture === '' && history.culture === null)
            || (x.culture === null && history.culture === '')) {
                return x
            }
        })
        if (result2) {
            return this.CultureColors.others[result2.index]
        } else {
            if (this.otherCultures.length > 0) {
                const index = this.otherCultures[this.otherCultures.length - 1].index
                this.otherCultures.push({
                    culture: history.culture,
                    index: (index + 1) % 10
                })
                return this.CultureColors.others[index + 1]
            } else {
                this.otherCultures.push({
                    culture: history.culture,
                    index: 0
                })
                return this.CultureColors.others[0]
            }
        }
    }

    private getUrl(url: any) {
        return `${baseUrl}${url}`
    }

    private getCropType(history: any) {
        return (history.sort ? ', ' + history.sort : '') + (history.reproduction ? ', ' + history.reproduction : '')
    }

    private normalizeDate(date: any) {
        if (date === '' || !date) {
            return i18n.t('general.dates.format_date')
        }
        return date.substring(8, 10) + '.' + date.substring(5, 7) + '.' + date.substring(0, 4)
    }

    private getDate(startDate: any, endDate: any) {
        return this.normalizeDate(startDate) + ' - ' + this.normalizeDate(endDate)
    }

    private getProductivity(productivity: any, unit: any) {
        if (productivity) {
            return `${productivity} ${unit === 1 ? i18n.t('general.units.centner_he') : i18n.t('general.units.t_he')}`
        } else {
            return i18n.t('messages.phrases.no_data')
        }
    }

    private getTableHeader() {
        if (this.cropRotation.length > 0) {
            const arr = this.cropRotation[0].crop_rotations.map((x: any) => x.x_axis_name)
            arr.sort((a: any, b: any) => {
                if (a.substring(0, 4) === b.substring(0, 4)) {
                    return a.localeCompare(b)
                } else {
                    return b.substring(0, 4) - a.substring(0, 4)
                }
            })
            return arr
        }
        return []
    }

    private getTableBody() {
        const searchValue = this.searchText.replace('№', '').trim()
        let result = JSON.parse(JSON.stringify(this.cropRotation))
        const searchResult = []
        for (const cropRotations of result) {
          if (cropRotations.name.toLowerCase().includes(searchValue.toLowerCase()) ) {
            searchResult.push(cropRotations)
          }
        }
        result = searchResult
        if (this.sorting.type === 'field') {
            result.sort((a: any, b: any) => a.name - b.name)
        } else {
            result.sort((a: any, b: any) => {
                const cropA = a.crop_rotations.find((x: any) => x.x_axis_name === this.sorting.type)
                const cropB = b.crop_rotations.find((x: any) => x.x_axis_name === this.sorting.type)
                if (cropA && cropB) {
                    if (cropA.crop_rotation && cropB.crop_rotation) {
                        return cropA.crop_rotation.culture - cropB.crop_rotation.culture ? 1 : -1
                    } else if (cropA.crop_rotation && !cropB.crop_rotation) {
                        return -1
                    } else if (!cropA.crop_rotation && cropB.crop_rotation) {
                        return 1
                    } else {
                        return 0
                    }
                }
                return 0
            })
        }
        if (!this.sorting.alphabetically) result.reverse()
        result.map((a: any) => {
            a.crop_rotations = a.crop_rotations.sort((b: any, c: any) => {
                if (c.x_axis_name.substring(0, 4) === b.x_axis_name.substring(0, 4)) {
                    return b.x_axis_name.localeCompare(c.x_axis_name)
                } else return c.x_axis_name.substring(0, 4) - b.x_axis_name.substring(0, 4)
            })
            return a
        })
        return result
    }

    private mounted() {
        this.getCropRotation()
    }

    private getSeasonRange(season: any) {
        const currentSeason = this.getFarmFromRoute.seasons.find((x: any) => x.name.toString() === season.toString())
        if (currentSeason) {
            const startDate = new Date(currentSeason.start_date)
            const endDate = new Date(currentSeason.end_date)
            return startDate.toLocaleDateString('ru', {month: 'short', day: 'numeric'}) + '- ' + endDate.toLocaleDateString('ru', {month: 'short', day: 'numeric'}) + ', ' + season
        }
        return ''
    }
    private getSeasonAreaInfo(season: any) {
        let notUsedArea = 0
        let usedPercentage = 1
        const notUsedData = this.cultureData[season]['Нет данных']
        if (notUsedData) {
            notUsedArea = Math.round(notUsedData.area / 10000)
            usedPercentage = (100 - notUsedData.percentage) / 100
        }
        const totalArea = Math.round(this.getFarmFromRoute.area / 10000)
        return Math.round(totalArea * usedPercentage) + ' га из ' + totalArea + ' га'
    }

    private getSeasonCultures(season: any) {
        const cultures: any = []
        const culturesData: any = Object.entries(this.cultureData).find((x: any) => x[0] === season.toString())
        if (culturesData) {
             Object.entries(culturesData[1]).forEach((x: any) => {
                cultures.push({
                    culture: x[0],
                    percentage: x[1].percentage
                })
            })
        }
        return cultures
    }

    private addNewValue(item: any) {
        const fieldIndex = this.cropRotation.findIndex((x: any) => x.id === item.id)
        if (fieldIndex !== -1) {
            const cropIndex = this.cropRotation[fieldIndex].crop_rotations.findIndex((x: any) => x.x_axis_name === item.val.name)
            if (cropIndex !== -1) {
                this.cropRotation[fieldIndex].crop_rotations[cropIndex].crop_rotation = item.val
                this.cropRotation[fieldIndex].crop_rotations[cropIndex].is_exists = true
            } else {
                this.cropRotation.forEach((x: any, index: any) => {
                    this.cropRotation[index].crop_rotations.push({
                        crop_rotation: index === fieldIndex ? item.val : null,
                        is_exists: index === fieldIndex,
                        x_axis_name: item.val.name
                    })
                })
            }
        }
    }

    private getCropRotation() {
        this.setLoadingDataNames('cropRotationGetTable')
        httpGet({
            url: `${apiUrl}/get/table/crop_rotation/extended/${this.currentRoute.params.farmId}/`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                if (json.ok) {
                    this.cropRotation = json.json.crop_rotaions
                    this.cultureData = json.json.culture_data
                    this.setPlanningData({culturesData: json.culture_data, cropRotations: json.crop_rotaions})
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('cropRotationGetTable')
            }
        })
    }

    private deleteHistory(val: any, id: any) {
        this.$confirm(i18n.t('messages.questions.delete.history') as string, 'Подтверждение', 'info').then(() => {
            this.setLoadingDataNames('CropRotationDeleteHistory')
            httpDelete({
                url: `${apiUrl}/crop_rotation/${val}/`,
                isSecureRequest: true,
                detailResponse: true,
                onSuccess: json => {
                    if (json.ok) {
                        this.$alert(i18n.t('messages.success.deleted') as string, 'Удалено', 'success').then(() => {
                            const fieldIndex = this.cropRotation.findIndex((x: any) => x.id === id)
                            if (fieldIndex !== -1) {
                                const cropIndex = this.cropRotation[fieldIndex].crop_rotations.findIndex((x: any) => {
                                    return x.crop_rotation ? x.crop_rotation.id === val : false
                                })
                                if (cropIndex !== -1) {
                                    this.cropRotation[fieldIndex].crop_rotations[cropIndex].crop_rotation = null
                                    this.cropRotation[fieldIndex].crop_rotations[cropIndex].is_exists = false
                                }
                            }
                        }).catch(e => {/* */})
                    }
                },
                onError: error => {
                    console.log(error)
                },
                doFinally: () => {
                    this.setLoadingDataNames('CropRotationDeleteHistory')
                }
            })
        }).catch(e => {/* */})
    }

    private changeCortingType(val: any) {
        if (this.sorting.type === val) {
            this.sorting.alphabetically = !this.sorting.alphabetically
        } else {
            this.sorting.type = val
            this.sorting.alphabetically = true
        }
    }

    private downloadExcel() {
        downloadExcelData('croprotationtable', 'Crop Rotation')
    }

    private printPdf() {
        const date = new Date()
        const today = date.toLocaleString('ru', { hour12: false, day: 'numeric', month: 'numeric', year: 'numeric',  hour: "numeric", minute: "numeric"})
        const result = JSON.parse(JSON.stringify(this.cropRotation))
        const docDefinition = {
            pageSize: {
                width: 595,
                height: 842
            },
            pageMargins: [30, 30, 30, 30],
            background: [
                {
                    image: this.pdfWaterMark,
                    margin: [308, 0, 0, 0],
                    width: 360
                }
            ],
            content: [
                {
                    image: this.egisticLogo,
                    margin: [0, 10, 0, 0],
                    width: 91.14,
                    height: 30,
                    alignment: 'center'
                },
                {
                    text: i18n.t('headers.workspace.fields.crop_rotation.title'),
                    fontSize: 16,
                    bold: true,
                    margin: [0, 24, 0, 0],
                    alignment: 'center'
                },
                {
                    text: today,
                    fontSize: 16,
                    bold: true,
                    margin: [0, 8, 0, 24],
                    alignment: 'center'
                },
                result.map((element: any) => {
                    return {
                        table: {
                            widths: [120, '*'],
                            headerRows: 1,
                            body: this.getPdfTableBody(element)
                        },
                        margin: [0, 0, 0, 24]
                    }
                })
            ]
        }

        makePDF(PdfActions.PRINT, docDefinition)
    }

    private getPdfTableBody(info: any) {
        const table: any = []
        const row: any = []
        const tableHeaders = [
            {
               text: i18n.t('headers.workspace.fields.field') + ' #' + (info.name ? info.name : info.number),
               fontSize: 10,
               margin: [12, 12, 0, 12]
            },
            {
                text: i18n.t('headers.workspace.fields.crop_rotation.data'),
                fontSize: 10,
                margin: [12, 12, 0, 12]
            }
        ]
        table.push(tableHeaders)
        info.crop_rotations.forEach((cr: any) => {
            if ( cr.is_exists ) {
                table.push(
                    [
                        {text: cr.x_axis_name, fontSize: 10, bold: true, margin: [12, 8, 0, 0]},
                        {text:
                            `${cr.crop_rotation.culture ? cr.crop_rotation.culture : ""} - ${cr.crop_rotation.parent_culture ? cr.crop_rotation.parent_culture : ""} - ${cr.crop_rotation.reproduction ? cr.crop_rotation.reproduction : ""}
                            ${i18n.t('headers.workspace.fields.info.date')} - ${i18n.t('headers.workspace.fields.info.date_cleaning')}: ${cr.crop_rotation.start_date ? cr.crop_rotation.start_date : ""} - ${cr.crop_rotation.end_date ? cr.crop_rotation.end_date : ""}
                            ${i18n.t('headers.workspace.fields.crop_rotation.spring.title')}: ${cr.crop_rotation.spring_treatment ? cr.crop_rotation.spring_treatment : ""}
                            ${i18n.t('headers.workspace.fields.crop_rotation.fall.title')}: ${cr.crop_rotation.autumn_treatment ? cr.crop_rotation.autumn_treatment : ""}
                            ${i18n.t('headers.workspace.fields.yield.title')}: ${cr.crop_rotation.productivity ? cr.crop_rotation.productivity : ""} ${i18n.t('general.units.centner_he')}`
                        , fontSize: 10, bold: false, margin: [12, 8, 0, 8], lineHeight: 1.5}
                    ]
                )
            }
        })
        return table
    }

    private toggleMorebtnDropdown(val: string) {
        if (val === this.options.index) {
            this.options.status = this.options.status === true ? false : true
        } else {
            this.options.index = val
            this.options.status = true
        }
    }

    private closeMorebtnDropdown() {
        this.options.status = false
    }

    private close() {
        this.$router.push(`/cadastres/farm/${this.getFarmFromRoute.id}`)
    }
}
