















































































































































import {Component, Vue} from 'vue-property-decorator'
import Sideboard from '@/components/Sideboard.vue'
import {GetterNames, MutationNames} from '@/store/types'
import {Getter, Mutation} from 'vuex-class'
import {Route} from 'vue-router'
import Farm from '@/models/Farm'
import {httpGet} from '@/util/http'
import {apiUrl3, baseUrl, apiUrl} from '@/domain/constants'
import CultureTaskModal from './components/CultureTaskModal.vue'
import TractorTelematics from '@/models/TractorTelematics'

@Component({
  components: {
    Sideboard,
    CultureTaskModal
  }
})
export default class CultureTask extends Vue {
  @Getter(GetterNames.getCurrentRoute) private currentRoute !: Route
  @Getter(GetterNames.getFarmFromRoute) private getFarmFromRoute!: Farm
  @Getter(GetterNames.getLoadingDataNames) private getLoadingDataNames!: any
  @Getter(GetterNames.getTelematicsInfo) private getTelematicsInfo!: any
  @Getter(GetterNames.getTaskData) private getTaskData!: any

  @Mutation(MutationNames.setTelematicsInfo) private setTelematicsInfo!: any
  @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
  @Mutation(MutationNames.setTaskData) private setTaskData!: any
  private taskData: any = []
  private taskId = -1
  private selectedTractor: any = []
  private currentTrack = 1

  private mounted() {
    this.setTelematicsInfo({tractorsData: []})
    this.selectedTractor = []
    // this.taskData = []
    this.fetchData()
  }

  private updateData() {
      this.fetchData()
  }

  private changeTrack(trackId: any) {
      if (this.currentTrack === trackId) return

      this.currentTrack = trackId
      this.setTaskData({
          taskId: this.taskData.id,
          fields: this.taskData.field_params[this.currentTrack],
          fieldIds: this.taskData.field_params[this.currentTrack].map((field: any) => field.id)
      })
      this.selectedTractor = this.taskData.tractor_params[this.currentTrack][0]
      this.setTelematicData(this.taskData.tractor_params[trackId])
  }

  private fetchData() {
    this.setLoadingDataNames('fetchTaskData')
    httpGet({
      url:  `${apiUrl}/technology/fact/tasks/${this.$route.params.taskId}/`,
      isSecureRequest: true,
      detailResponse: true,
      onSuccess: json => {
        if (json.ok) {
          this.taskData = json.json
          this.taskId = json.json.id
          this.setTaskData({
            taskId: json.json.id,
            fields: json.json.field_params[this.currentTrack],
            fieldIds: json.json.field_params[this.currentTrack].map((field: any) => field.id)
          })
          this.selectedTractor = json.json.tractor_params[this.currentTrack][0]
          this.setTelematicData(json.json.tractor_params[this.currentTrack])
        }
      },
      onError: error => {
        console.log(error)
      },
      doFinally: () => {
        this.setLoadingDataNames('fetchTaskData')
      }
    })
  }

  private setTelematicData(tractors: any) {
      const tractorsTelematics: TractorTelematics[] = []
      tractors.forEach((x: any, i: number) => {
        tractorsTelematics.push(new TractorTelematics({
          index: i,
          folderID: -1,
          complex: x,
          tractorID: x.id,
          tractorIMEI: x.imei,
          tractorUID: x.uid,
          FUEL_DATA: x.fuel_data ? x.fuel_data : [],
          SOCKET_STATUS: 0,
          MAX_CAPACITY: 0,
          MIN_SPEED: 0,
          MAX_SPEED: 999,
          coverage: x.coverage ? x.coverage : 0,
          tractorImage: x.image ? `${baseUrl}${x.image}` : '',
          hasTrailer: x.tractor.has_trailer
        }))
      })
      this.setTelematicsInfo({tractorsData: tractorsTelematics, stopsView: true})

      tractors.forEach((tractor: any, index: number) => {
        if (!tractor.stops || tractor.stops.length < 1) return
        tractor.stops.forEach((stopPoint: any) => {
            this.getTelematicsInfo.tractorsData[0].IS_HISTORY = true
            this.getTelematicsInfo.tractorsData[index].addStopPointData(stopPoint)
        })
      })
  }

  private getTractorFields(imei: number) {
      const tractors: any = Object.values(this.taskData.tractor_params[this.currentTrack])
      return tractors.find((x: any) => x.imei === imei).field_params
  }

  private formatExpences(expense: number) {
    return expense ? expense.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ' тг' : '0 тг'
  }

  private formatExpencesForHe(expense: number, area: number) {
    const expencesForHe = Math.round(expense / area)
    return expense ? expencesForHe.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ' тг' : '0 тг'
  }

  private getFieldArea(field: any, imei: number) {
    const foundImei = field.imeis_with_area.find((imeiData: any) => imeiData.imei === imei)
    if (foundImei) {
      return Math.round(foundImei.area * 100) / 100 + ' га'
    } else {
      return '0 га'
    }
  }

  private zoomToStopPoint(stopPoint: any) {
    this.setTaskData({currentStopPoint: stopPoint})
  }

  private getTotalStopsTime(stops: any) {
    if (!stops) return '0 минуты'
    let totalMinutes = 0
    stops.forEach((stopPoint: any) => {
      totalMinutes += stopPoint.interval_in_minuts
    })

    return `${Math.round(totalMinutes / 60)} часов ${totalMinutes % 60} минуты`
  }

  private onSelectTractor(id: number) {
    const tractorData = this.taskData.tractor_params[this.currentTrack].find((tractor: any) => tractor.id === id)
    if (tractorData) {
      this.selectedTractor = tractorData
    }

    this.getTelematicsInfo.tractorsData.forEach((tractor: any, index: number) => {
      if (tractor.tractorID === tractorData.id) {
        this.getTelematicsInfo.tractorsData[index].IS_HISTORY = true
      } else {
        this.getTelematicsInfo.tractorsData[index].IS_HISTORY = false
      }
    })
  }

  private openTelematics(fieldId: number) {
    this.$router.push({
      name: 'fieldtelematics',
      params: {
        farmId: this.$route.params.farmId,
        currentevent: this.taskId.toString(),
        subfieldId: fieldId.toString()
      }
    })
  }
}
