


















































































































import { Component, Vue, Watch} from 'vue-property-decorator'
import { GetterNames, MutationNames } from '@/store/types'
import { Getter, Mutation } from 'vuex-class'
import { Route } from 'vue-router'
import olStyle from 'ol/style/Style'
import olFill from 'ol/style/Fill'
import olStroke from 'ol/style/Stroke'
import olCircle from 'ol/style/Circle'
import olText from 'ol/style/Text'
import olMultiPoint from 'ol/geom/MultiPoint'
import * as turf from '@turf/turf'
import RegisterFarmModel from '@/models/RegisterFarm'
@Component
export default class RegisterFarm extends Vue {
    @Getter(GetterNames.getMapData) private mapData !: any
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: Route
    @Getter(GetterNames.getRegisterFarms) private getRegisterFarms!: any
    @Mutation(MutationNames.setRegisterFarms) private setRegisterFarms!: any
    private borderColors: any = ['#B2F507', '#06BAF2', '#5AF5D9', '#F37500', '#FC7BE7', '#719D00', '#AA27E8', '#2B4DFF', '#4F0BBD', '#AD3E00']
    private colors: any = ['rgba(178, 245, 7, 0.2)', 'rgba(6, 186, 242, 0.2)', 'rgba(90, 245, 217, 0.2)',
        'rgba(243, 117, 0, 0.2)', 'rgba(252, 123, 231, 0.2)', 'rgba(113, 157, 0, 0.2)', 'rgba(170, 39, 232, 0.2)',
        'rgba(43, 77, 255, 0.2)', 'rgba(79, 11, 189, 0.2)', 'rgba(173, 62, 0, 0.2)']
    private drawnFeatures: any[] = []
    private drawnLines: any[] = []
    private drawnPolygon: any[] = []
    private selectedFeatures: any[] = []
    private editId: any = []
    private selectedFieldId: any = -1
    private selectedErrorIndex: any = -1

    get interactionSelectStatus() {
        if (this.getRegisterFarms.editType === 'edit' && this.getRegisterFarms.editStage === 1) {
            return true
        } else if (this.getRegisterFarms.editType === 'delete' && this.getRegisterFarms.editStage === 1) {
            return true
        } else if (this.getRegisterFarms.editType === 'merge' && this.getRegisterFarms.editStage === 1) {
            return true
        } else if (this.getRegisterFarms.editType === 'vertix' && this.getRegisterFarms.editStage === 1) {
            return true
        } else if (this.getRegisterFarms.editType === 'vertices' && this.getRegisterFarms.editStage === 1) {
            return true
        } else if (this.getRegisterFarms.editType === 'hole' && this.getRegisterFarms.editStage === 1) {
            return true
        } else if (this.getRegisterFarms.editType === 'info' && this.getRegisterFarms.editStage === 1) {
            return true
        } else {
            return false
        }
    }

    get interactionModifyStatus() {
        if (this.getRegisterFarms.editType === 'edit' && this.getRegisterFarms.editStage === 2) {
            return true
        } else if (this.getRegisterFarms.editType === 'draw' && this.getRegisterFarms.editStage === 2) {
            return true
        } else if (this.getRegisterFarms.editType === 'vertix' && this.getRegisterFarms.editStage === 2) {
            return true
        } else {
            return false
        }
    }

    get interactionDrawStatus() {
        if (this.getRegisterFarms.editType === 'draw' && this.getRegisterFarms.editStage === 1) {
            return true
        } else {
            return false
        }
    }

    get interactionDraw2Status() {
        if (this.getRegisterFarms.editType === 'split' && this.getRegisterFarms.editStage === 1) {
            return true
        } else {
            return false
        }
    }

    get interactionDraw3Status() {
        if (this.getRegisterFarms.editType === 'vertices' && this.getRegisterFarms.editStage === 1.5) {
            return true
        } else if (this.getRegisterFarms.editType === 'hole' && this.getRegisterFarms.editStage === 1.5) {
            return true
        } else {
            return false
        }
    }

    private getColorIndex(documentId: any) {
        if (documentId) {
            const cadastre = this.getRegisterFarms.cadastres.find((x: any) => x.documentId === documentId)
            const cadastres = this.getRegisterFarms.cadastres.filter((x: any) => x.cadastre)
            if (cadastre && cadastres) {
                const index = cadastres.indexOf(cadastre)
                if (index !== -1) {
                    return index % 10
                }
            }
        }
        return 0
    }

    private styleFuncFactory() {
        return (feature: any) => {
            const firstconcat = [].concat.apply([], feature.getGeometry().getCoordinates())
            const coordinates = [].concat.apply([], firstconcat)
            const area = turf.area(turf.toWgs84(turf.multiPolygon(feature.getGeometry().getCoordinates())))
            const styles = [
                new olStyle({
                    stroke: new olStroke({
                        color: '#FFC700',
                        width: 2,
                        lineDash: [8, 16]
                    }),
                    fill: new olFill({
                        color: 'rgba(255, 199, 0, 0.1)'
                    }),
                    text: new olText({
                        scale: 1.5,
                        text: `${Math.round(area / 100) / 100} га`,
                        fill: new olFill({
                            color: '#FFFFFF'
                        }),
                        stroke: new olStroke({
                            color: 'rgb(34, 34, 34)'
                        })
                    })
                }),
                new olStyle({
                    image: new olCircle({
                        radius: 5,
                        stroke: new olStroke({
                            color: '#FFFFFF',
                            width: 2
                        }),
                        fill: new olFill({
                            color: '#4B970F'
                        })
                    }),
                    geometry: new olMultiPoint(coordinates)
                })
            ]
            return styles
        }
    }

    private styleFuncFactoryTwo() {
        return (feature: any) => {
            const coordinates = feature.getGeometry().getCoordinates()
            const styles = [
                new olStyle({
                    stroke: new olStroke({
                        color: '#FFC700',
                        width: 2,
                        lineDash: [8, 16]
                    }),
                    fill: new olFill({
                        color: 'rgba(255, 199, 0, 0.2)'
                    })
                }),
                new olStyle({
                    image: new olCircle({
                        radius: 5,
                        stroke: new olStroke({
                            color: '#FFFFFF',
                            width: 2
                        }),
                        fill: new olFill({
                            color: '#4B970F'
                        })
                    }),
                    geometry: new olMultiPoint(coordinates)
                })
            ]
            return styles
        }
    }

    private styleFuncFactoryThree() {
        return (feature: any) => {
            const coordinates = [].concat.apply([], feature.getGeometry().getCoordinates())
            const styles = [
                new olStyle({
                    stroke: new olStroke({
                        color: '#FFC700',
                        width: 2,
                        lineDash: [8, 16]
                    }),
                    fill: new olFill({
                        color: 'rgba(255, 199, 0, 0.2)'
                    })
                }),
                new olStyle({
                    image: new olCircle({
                        radius: 5,
                        stroke: new olStroke({
                            color: '#FFFFFF',
                            width: 2
                        }),
                        fill: new olFill({
                            color: '#4B970F'
                        })
                    }),
                    geometry: new olMultiPoint(coordinates)
                })
            ]
            return styles
        }
    }

    @Watch('selectedFeatures')
    private setChosenField(val: any, old: any) {
        if (val.length === 0) return

        if (val[0].properties.type === 'farmfield') {
            const cadastre = this.getRegisterFarms.cadastres.find((x: any) => x.editing)
            if (cadastre) {
                if (!cadastre.fields.find((x: any) => x.id === val[0].properties.id)) {
                    this.selectedFeatures = []
                    return
                }
            } else {
                this.selectedFeatures = []
                return
            }
        }

        if (this.getRegisterFarms.editType === 'info') {
            if (val[0].properties.type === 'farmfield') {
                this.selectedFieldId = val[0].properties.id
            }
            this.selectedFeatures = []
        }

        if (this.getRegisterFarms.editType === 'edit') {
            if (val[0].properties.type === 'farmfield') {
                this.drawnFeatures = []
                this.editId = []
                this.editId.push(val[0].properties.id)
                this.drawnFeatures.push({
                    type: "Feature",
                    id: val[0].properties.id,
                    properties: {
                        id: val[0].properties.id
                    },
                    geometry: val[0].geometry
                })
                this.setRegisterFarms({editStage: 2})
            }
            this.selectedFeatures = []
        }

        if (this.getRegisterFarms.editType === 'delete') {
            if (val[0].properties.type === 'farmfield') {
                this.editId = []
                this.editId.push(val[0].properties.id)
                this.setRegisterFarms({editStage: 2})
            }
            this.selectedFeatures = []
        }

        if (this.getRegisterFarms.editType === 'merge') {
            if (val[0].properties.type === 'farmfield') {
                if (!this.editId.includes(val[0].properties.id)) {
                    this.editId.push(val[0].properties.id)
                    this.drawnFeatures.push({
                        type: "Feature",
                        id: val[0].properties.id,
                        properties: {
                            id: val[0].properties.id,
                            type: 'farmfield'
                        },
                        geometry: val[0].geometry
                    })
                    if (this.drawnFeatures.length === 2) {
                        this.setRegisterFarms({editStage: 2})
                    }
                } else {
                    const feature = this.drawnFeatures.find((x: any) => x.properties.id === val[0].properties.id)
                    if (feature) {
                        const index = this.drawnFeatures.indexOf(feature)
                        this.drawnFeatures.splice(index, 1)
                        const i = this.editId.indexOf(val[0].properties.id)
                        this.editId.splice(i, 1)
                        if (this.drawnFeatures.length === 0) {
                            this.editId = []
                            this.drawnFeatures = []
                            this.setRegisterFarms({editStage: 1})
                        }
                    }
                }
            }
            this.selectedFeatures = []
        }

        if (this.getRegisterFarms.editType === 'vertix') {
            if (val[0].properties.type === 'farmfield') {
                this.drawnFeatures = []
                this.editId = []
                this.editId.push(val[0].properties.id)
                this.drawnFeatures.push({
                    type: "Feature",
                    id: val[0].properties.id,
                    properties: {
                        id: val[0].properties.id
                    },
                    geometry: val[0].geometry
                })
                this.setRegisterFarms({editStage: 2})
            }
            this.selectedFeatures = []
        }

        if (this.getRegisterFarms.editType === 'vertices') {
            if (val[0].properties.type === 'farmfield') {
                this.drawnFeatures = []
                this.editId = []
                this.editId.push(val[0].properties.id)
                this.drawnFeatures.push({
                    type: "Feature",
                    id: val[0].properties.id,
                    properties: {
                        id: val[0].properties.id
                    },
                    geometry: val[0].geometry
                })
                this.setRegisterFarms({editStage: 1.5})
            }
            this.selectedFeatures = []
        }

        if (this.getRegisterFarms.editType === 'hole') {
            if (val[0].properties.type === 'farmfield') {
                this.drawnFeatures = []
                this.editId = []
                this.editId.push(val[0].properties.id)
                this.drawnFeatures.push({
                    type: "Feature",
                    id: val[0].properties.id,
                    properties: {
                        id: val[0].properties.id
                    },
                    geometry: val[0].geometry
                })
                this.setRegisterFarms({editStage: 1.5})
            }
            this.selectedFeatures = []
        }
    }

    @Watch('drawnFeatures')
    private watchDrawnFeatures(val: any, old: any) {
        if (val.length === 0) return
        if (this.getRegisterFarms.editType === 'draw' && this.getRegisterFarms.editStage === 1) {
            this.getRegisterFarms.editStage = 2
        }
    }

    @Watch('drawnLines')
    private watchDrawnLines(val: any, old: any) {
        if (val.length === 0) return
        if (this.getRegisterFarms.editType === 'split' && this.getRegisterFarms.editStage === 1) {
            this.getRegisterFarms.editStage = 2
        }
    }

    @Watch('drawnPolygon')
    private watchDrawnPolygon(val: any, old: any) {
        if (val.length === 0) return
        if (this.getRegisterFarms.editType === 'vertices' && this.getRegisterFarms.editStage === 1.5) {
            this.getRegisterFarms.editStage = 2
        }
        if (this.getRegisterFarms.editType === 'hole' && this.getRegisterFarms.editStage === 1.5) {
            this.getRegisterFarms.editStage = 2
        }
    }

    @Watch('getRegisterFarms.editType')
    private onchangeEditType(val: any, old: any) {
        if (val !== old) {
                this.editId = []
                this.drawnFeatures = []
                this.drawnLines = []
                this.selectedFeatures = []
                this.drawnPolygon = []
                this.selectedFieldId = -1
        }
    }

    @Watch('getRegisterFarms.editStage')
    private onchangeEditStage(val: any, old: any) {
        if (this.getRegisterFarms.editStage === 1 || this.getRegisterFarms.editStage === 0) {
            this.editId = []
            this.drawnFeatures = []
            this.drawnLines = []
            this.drawnPolygon = []
            this.selectedFeatures = []
            this.selectedFieldId = -1
        }

        if (this.getRegisterFarms.editType === 'edit') {
            if (this.getRegisterFarms.editStage === 3) {
                this.editId = []
                this.drawnFeatures = []
                this.getRegisterFarms.editStage = 1
            }
            if (this.getRegisterFarms.editStage === 4) {
                const coordinates: any = this.drawnFeatures.find((x: any) => this.editId.includes(x.properties.id)).geometry.coordinates
                let fieldArea = 0
                for (const i of coordinates) {
                    fieldArea += turf.area(turf.toWgs84(turf.polygon(i)))
                }
                const cadastre = this.getRegisterFarms.cadastres.find((x: any) => x.fields.find((y: any) => this.editId.includes(y.id)))
                const cadastreIndex = this.getRegisterFarms.cadastres.indexOf(cadastre)
                const field = cadastre.fields.find((x: any) => this.editId.includes(x.id))
                const fieldIndex = cadastre.fields.indexOf(field)
                this.getRegisterFarms.cadastres[cadastreIndex].fields[fieldIndex].geometry.coordinates = coordinates
                this.getRegisterFarms.cadastres[cadastreIndex].fields[fieldIndex].area = fieldArea
                this.editId = []
                this.drawnFeatures = []
                this.getRegisterFarms.editStage = 1
            }
        }

        if (this.getRegisterFarms.editType === 'draw') {
            if (this.getRegisterFarms.editStage === 3) {
                this.editId = []
                this.drawnFeatures = []
                this.getRegisterFarms.editStage = 1
            }
            if (this.getRegisterFarms.editStage === 4) {
                if (this.drawnFeatures.length > 0) {
                    const coordinates = this.drawnFeatures[0].geometry
                    if (this.getRegisterFarms.newField) {
                        const centroid: any = turf.centroid(turf.polygon(coordinates.coordinates[0]))
                        const bbox =  turf.bbox(turf.polygon(coordinates.coordinates[0]))
                        const fieldArea = turf.area(turf.toWgs84(turf.polygon(coordinates.coordinates[0])))
                        const newCadastre = new RegisterFarmModel()
                        let index = 1
                        while (true) {
                            if (this.getRegisterFarms.cadastres.find((x: any) => x.documentId === `поле ${index}`)) {
                                index += 1
                            } else {
                                break
                            }
                        }
                        newCadastre.number = `поле ${index}`
                        const newFields = [{
                            id: `поле ${index}`,
                            geometry: coordinates,
                            area: fieldArea,
                            name: null
                        }]
                        newCadastre.setData({
                            id: `поле ${index}`,
                            documentId: `поле ${index}`,
                            location: '',
                            area: fieldArea,
                            fields: newFields,
                            cadastre: {
                                area: fieldArea,
                                boundary: bbox,
                                center: {
                                    x: centroid.geometry.coordinates[0],
                                    y: centroid.geometry.coordinates[1]
                                },
                                documentId: `поле ${index}`,
                                field: newFields,
                                geomjson: null,
                                id: `поле ${index}`,
                                oblast: '',
                                region: ''
                            },
                            readonly: true
                        })
                        newCadastre.editing = true
                        this.getRegisterFarms.cadastres.push(newCadastre)
                        this.setRegisterFarms({newField: false})
                    } else {
                        const cadastre = this.getRegisterFarms.cadastres.find((x: any) => x.editing)
                        const cadastreIndex = this.getRegisterFarms.cadastres.indexOf(cadastre)
                        let tempId = Math.random() * -1
                        while (true) {
                            if (cadastre.fields.find((x: any) => x.id === tempId)) {
                                tempId = Math.random() * -1
                            } else {
                                break
                            }
                        }
                        let fieldArea = 0
                        for (const i of coordinates.coordinates) {
                            fieldArea += turf.area(turf.toWgs84(turf.polygon(i)))
                        }
                        this.getRegisterFarms.cadastres[cadastreIndex].fields.push({
                            id: tempId,
                            geometry: coordinates,
                            area: fieldArea,
                            name: null
                        })
                    }
                }
                this.editId = []
                this.drawnFeatures = []
                this.getRegisterFarms.editStage = 1
            }
        }

        if (this.getRegisterFarms.editType === 'delete') {
            if (this.getRegisterFarms.editStage === 3) {
                this.editId = []
                this.getRegisterFarms.editStage = 1
            }
            if (this.getRegisterFarms.editStage === 4) {
                if (this.editId.length > 0) {
                    const cadastre = this.getRegisterFarms.cadastres.find((x: any) => x.editing)
                    const cadastreIndex = this.getRegisterFarms.cadastres.indexOf(cadastre)
                    const field = cadastre.fields.find((x: any) => this.editId.includes(x.id))
                    const fieldIndex = cadastre.fields.indexOf(field)
                    this.getRegisterFarms.cadastres[cadastreIndex].fields.splice(fieldIndex, 1)
                }
                this.editId = []
                this.getRegisterFarms.editStage = 1
            }
        }

        if (this.getRegisterFarms.editType === 'merge') {
            if (this.getRegisterFarms.editStage === 2) {
                const features = []
                if (this.drawnFeatures.length > 0) {
                    let union: any = null
                    let fieldId = 45545
                    for (let i = 1; i < this.drawnFeatures.length; i++) {
                        if (fieldId === 45545) {
                            if (this.drawnFeatures[i].id) {
                                fieldId = this.drawnFeatures[i].id
                            }
                        }
                        if (i === 1) {
                            const multiPoly1: any = turf.multiPolygon(this.drawnFeatures[0].geometry.coordinates)
                            const multiPoly2: any = turf.multiPolygon(this.drawnFeatures[1].geometry.coordinates)
                            union = turf.union(multiPoly1, multiPoly2)
                        } else {
                            const multiPoly1: any = turf.multiPolygon(this.drawnFeatures[i].geometry.coordinates)
                            const tempUnion = turf.union(union, multiPoly1)
                            union = tempUnion
                        }
                    }
                    if (union) {
                        this.drawnFeatures = []
                        if (union.geometry.type !== 'MultiPolygon') {
                            this.drawnFeatures.push({
                                type: "Feature",
                                id: fieldId,
                                properties: {
                                    id: fieldId
                                },
                                geometry: {
                                    type: 'MultiPolygon',
                                    coordinates: [union.geometry.coordinates]
                                }
                            })
                        } else {
                            this.editId = []
                            this.drawnFeatures = []
                            this.getRegisterFarms.editStage = 1
                        }
                    }
                }
            }
            if (this.getRegisterFarms.editStage === 3) {
                this.editId = []
                this.drawnFeatures = []
                this.getRegisterFarms.editStage = 1
            }
            if (this.getRegisterFarms.editStage === 4) {
                if (this.drawnFeatures.length === 1 && this.editId.length === 2) {
                    const coordinates = this.drawnFeatures[0].geometry
                    const cadastre = this.getRegisterFarms.cadastres.find((x: any) => x.editing)
                    const cadastreIndex = this.getRegisterFarms.cadastres.indexOf(cadastre)
                    const field = cadastre.fields.find((x: any) => x.id === this.editId[0])
                    const fieldIndex = cadastre.fields.indexOf(field)
                    this.getRegisterFarms.cadastres[cadastreIndex].fields.splice(fieldIndex, 1)
                    const field2 = cadastre.fields.find((x: any) => x.id === this.editId[1])
                    const fieldIndex2 = cadastre.fields.indexOf(field2)
                    this.getRegisterFarms.cadastres[cadastreIndex].fields.splice(fieldIndex2, 1)
                    let fieldArea = 0
                    for (const i of coordinates.coordinates) {
                        fieldArea += turf.area(turf.toWgs84(turf.polygon(i)))
                    }
                    this.getRegisterFarms.cadastres[cadastreIndex].fields.push({
                        id: this.editId[0],
                        geometry: coordinates,
                        area: fieldArea,
                        name: field.name
                    })
                }
                this.editId = []
                this.drawnFeatures = []
                this.getRegisterFarms.editStage = 1
            }
        }

        if (this.getRegisterFarms.editType === 'split') {
            if (this.getRegisterFarms.editStage === 2) {
                this.drawnFeatures = []
                if (this.drawnLines.length > 0) {
                    const line = turf.lineString(this.drawnLines[0].geometry.coordinates)
                    const cadastre = this.getRegisterFarms.cadastres.find((x: any) => x.editing)
                    for (const field of cadastre.fields) {
                        const polygon: any = turf.polygon(field.geometry.coordinates[0])
                        const result: any = this.polygonCut(polygon, line.geometry, cadastre.fields.indexOf(field))
                        if (result) {
                            if (result.type === 'FeatureCollection') {
                                this.editId.push(field.id)
                                for (const feature of result.features) {
                                    let tempId = Math.random() * -1
                                    while (true) {
                                        if (cadastre.fields.find((x: any) => x.id === tempId) ||
                                            this.drawnFeatures.find((x: any) => x.id === tempId)) {
                                            tempId = Math.random() * -1
                                        } else {
                                            break
                                        }
                                    }
                                    this.drawnFeatures.push({
                                        type: "Feature",
                                        id: result.features.indexOf(feature) === 0 ? field.id : tempId,
                                        properties: {
                                            id: result.features.indexOf(feature) === 0 ? field.id : tempId
                                        },
                                        geometry: {
                                            type: 'MultiPolygon',
                                            coordinates: [feature.geometry.coordinates]
                                        }
                                    })
                                }
                            }
                        }
                    }
                }
            }
            if (this.getRegisterFarms.editStage === 3) {
                this.editId = []
                this.drawnFeatures = []
                this.drawnLines = []
                this.getRegisterFarms.editStage = 1
            }
            if (this.getRegisterFarms.editStage === 4) {
                for (const feature of this.drawnFeatures) {
                    const fieldId = feature.id
                    const coordinates = feature.geometry.coordinates
                    const cadastre = this.getRegisterFarms.cadastres.find((x: any) => x.editing)
                    const cadastreIndex = this.getRegisterFarms.cadastres.indexOf(cadastre)
                    const field = cadastre.fields.find((x: any) => x.id === fieldId)
                    let fieldArea = 0
                    for (const i of coordinates) {
                        fieldArea += turf.area(turf.toWgs84(turf.polygon(i)))
                    }
                    if (field) {
                        const fieldIndex = cadastre.fields.indexOf(field)
                        this.getRegisterFarms.cadastres[cadastreIndex].fields[fieldIndex].geometry.coordinates = coordinates
                        this.getRegisterFarms.cadastres[cadastreIndex].fields[fieldIndex].area = fieldArea
                    } else {
                        this.getRegisterFarms.cadastres[cadastreIndex].fields.push({
                            id: fieldId,
                            geometry: feature.geometry,
                            area: fieldArea,
                            name: null
                        })
                    }
                }
                this.editId = []
                this.drawnFeatures = []
                this.drawnLines = []
                this.selectedFeatures = []
                this.getRegisterFarms.editStage = 1
            }
        }

        if (this.getRegisterFarms.editType === 'vertix') {
            if (this.getRegisterFarms.editStage === 3) {
                this.editId = []
                this.drawnFeatures = []
                this.getRegisterFarms.editStage = 1
                this.setRegisterFarms({editStage: 1})
            }
            if (this.getRegisterFarms.editStage === 4) {
                const coordinates = this.drawnFeatures.find((x: any) => this.editId.includes(x.properties.id)).geometry.coordinates
                const cadastre = this.getRegisterFarms.cadastres.find((x: any) => x.fields.find((y: any) => this.editId.includes(y.id)))
                const cadastreIndex = this.getRegisterFarms.cadastres.indexOf(cadastre)
                const field = cadastre.fields.find((x: any) => this.editId.includes(x.id))
                const fieldIndex = cadastre.fields.indexOf(field)
                let fieldArea = 0
                for (const i of coordinates) {
                    fieldArea += turf.area(turf.toWgs84(turf.polygon(i)))
                }
                this.getRegisterFarms.cadastres[cadastreIndex].fields[fieldIndex].geometry.coordinates = coordinates
                this.getRegisterFarms.cadastres[cadastreIndex].fields[fieldIndex].area = fieldArea
                this.editId = []
                this.drawnFeatures = []
                this.getRegisterFarms.editStage = 1
            }
        }

        if (this.getRegisterFarms.editType === 'vertices') {
            if (this.getRegisterFarms.editStage === 2) {
                if (this.drawnPolygon.length === 1 && this.drawnFeatures.length === 1) {
                    const detectPolygon = turf.polygon(this.drawnPolygon[0].geometry.coordinates)
                    const field = JSON.parse(JSON.stringify(this.drawnFeatures[0].geometry.coordinates))
                    const result: any = []
                    for (const [i, container] of field.entries()) {
                        const polygoncontainer = []
                        for (const [j, polygon] of container.entries()) {
                            const points = turf.points(polygon)
                            const ptsWithin: any = turf.pointsWithinPolygon(points, detectPolygon)
                            const copyofpolygon = JSON.parse(JSON.stringify(polygon))
                            for (const point of ptsWithin.features) {
                                const coordinate = point.geometry.coordinates
                                while (true) {
                                    const foundpoint = copyofpolygon.find((x: any) => JSON.stringify(x) === JSON.stringify(coordinate))
                                    if (foundpoint) {
                                        const index = copyofpolygon.indexOf(foundpoint)
                                        copyofpolygon.splice(index, 1)
                                    } else {
                                        break
                                    }
                                }
                            }
                            if (copyofpolygon.length === 0) {
                                continue
                            } else if (JSON.stringify(copyofpolygon[0]) !== JSON.stringify(copyofpolygon[copyofpolygon.length - 1])) {
                                copyofpolygon.push(copyofpolygon[0])
                            }
                            if (copyofpolygon.length > 3) {
                                polygoncontainer.push(copyofpolygon)
                            }
                        }
                        if (polygoncontainer.length === 0) {
                            continue
                        }
                        result.push(polygoncontainer)
                    }
                    this.drawnFeatures = []
                    this.drawnPolygon = []
                    this.drawnFeatures.push({
                        type: "Feature",
                        id: this.editId[0],
                        properties: {
                            id: this.editId[0]
                        },
                        geometry: {
                            type: "MultiPolygon",
                            coordinates: result
                        }
                    })
                } else {
                    this.drawnFeatures = []
                    this.drawnPolygon = []
                    this.setRegisterFarms({editStage: 1})
                }
            }
            if (this.getRegisterFarms.editStage === 3) {
                this.drawnFeatures = []
                this.drawnPolygon = []
                this.drawnLines = []
                this.selectedFeatures = []
                this.editId = []
                this.setRegisterFarms({editStage: 1})
            }
            if (this.getRegisterFarms.editStage === 4) {
                if (this.drawnFeatures.length === 1 && this.editId.length > 0) {
                    if (this.drawnFeatures[0].geometry.coordinates.length === 0) {
                        const cadastre = this.getRegisterFarms.cadastres.find((x: any) => x.editing)
                        const cadastreIndex = this.getRegisterFarms.cadastres.indexOf(cadastre)
                        const field = cadastre.fields.find((x: any) => this.editId.includes(x.id))
                        const fieldIndex = cadastre.fields.indexOf(field)
                        this.getRegisterFarms.cadastres[cadastreIndex].fields.splice(fieldIndex, 1)
                    } else {
                        const coordinates = this.drawnFeatures.find((x: any) => this.editId.includes(x.properties.id)).geometry.coordinates
                        const cadastre = this.getRegisterFarms.cadastres.find((x: any) => x.fields.find((y: any) => this.editId.includes(y.id)))
                        const cadastreIndex = this.getRegisterFarms.cadastres.indexOf(cadastre)
                        const field = cadastre.fields.find((x: any) => this.editId.includes(x.id))
                        const fieldIndex = cadastre.fields.indexOf(field)
                        let fieldArea = 0
                        for (const i of coordinates) {
                            fieldArea += turf.area(turf.toWgs84(turf.polygon(i)))
                        }
                        this.getRegisterFarms.cadastres[cadastreIndex].fields[fieldIndex].geometry.coordinates = coordinates
                        this.getRegisterFarms.cadastres[cadastreIndex].fields[fieldIndex].area = fieldArea
                    }
                }
                this.drawnFeatures = []
                this.drawnPolygon = []
                this.drawnLines = []
                this.selectedFeatures = []
                this.editId = []
                this.setRegisterFarms({editStage: 1})
            }
        }

        if (this.getRegisterFarms.editType === 'hole') {
            if (this.getRegisterFarms.editStage === 2) {
                if (this.drawnPolygon.length === 1 && this.drawnFeatures.length === 1) {
                    const detectPolygon = turf.polygon(this.drawnPolygon[0].geometry.coordinates)
                    const field = JSON.parse(JSON.stringify(this.drawnFeatures[0].geometry.coordinates))
                    const result = []
                    for (const container of field) {
                        const polygon = turf.polygon([container[0]])
                        const copyofcontainer = JSON.parse(JSON.stringify(container))
                        if (turf.booleanContains(polygon, detectPolygon)) {
                            copyofcontainer.push(JSON.parse(JSON.stringify(this.drawnPolygon[0].geometry.coordinates[0])))
                        }
                        result.push(copyofcontainer)
                    }
                    this.drawnFeatures = []
                    this.drawnPolygon = []
                    this.drawnFeatures.push({
                        type: "Feature",
                        id: this.editId[0],
                        properties: {
                            id: this.editId[0]
                        },
                        geometry: {
                            type: "MultiPolygon",
                            coordinates: result
                        }
                    })
                }
            }
            if (this.getRegisterFarms.editStage === 3) {
                this.drawnFeatures = []
                this.drawnPolygon = []
                this.drawnLines = []
                this.selectedFeatures = []
                this.editId = []
                this.setRegisterFarms({editStage: 1})
            }
            if (this.getRegisterFarms.editStage === 4) {
                if (this.drawnFeatures.length === 1 && this.editId.length > 0) {
                    const coordinates = this.drawnFeatures.find((x: any) => this.editId.includes(x.properties.id)).geometry.coordinates
                    const cadastre = this.getRegisterFarms.cadastres.find((x: any) => x.fields.find((y: any) => this.editId.includes(y.id)))
                    const cadastreIndex = this.getRegisterFarms.cadastres.indexOf(cadastre)
                    const field = cadastre.fields.find((x: any) => this.editId.includes(x.id))
                    const fieldIndex = cadastre.fields.indexOf(field)
                    let fieldArea = 0
                    for (const i of coordinates) {
                        fieldArea += turf.area(turf.toWgs84(turf.polygon(i)))
                    }
                    this.getRegisterFarms.cadastres[cadastreIndex].fields[fieldIndex].geometry.coordinates = coordinates
                    this.getRegisterFarms.cadastres[cadastreIndex].fields[fieldIndex].area = fieldArea
                }
                this.drawnFeatures = []
                this.drawnPolygon = []
                this.drawnLines = []
                this.selectedFeatures = []
                this.editId = []
                this.setRegisterFarms({editStage: 1})
            }
        }
    }

    private polygonCut(polygon: any, line: any, idPrefix: any) {
        const THICK_LINE_UNITS = 'kilometers'
        const THICK_LINE_WIDTH = 0.001
        let retVal = null
        const intersectPoints = turf.lineIntersect(polygon, line)
        const offsetLine = []
        const cutFeatures: any = []
        if (intersectPoints.features.length === 0) {
            return retVal
        }
        const lineCoords = turf.getCoords(line)
        if ((turf.booleanWithin(turf.point(lineCoords[0]), polygon) ||
            (turf.booleanWithin(turf.point(lineCoords[lineCoords.length - 1]), polygon)))) {
            return retVal
        }
        offsetLine[0] = turf.lineOffset(line, THICK_LINE_WIDTH, {units: THICK_LINE_UNITS})
        offsetLine[1] = turf.lineOffset(line, -THICK_LINE_WIDTH, {units: THICK_LINE_UNITS})
        for (let i = 0; i <= 1; i++) {
            const forCut = i
            const forSelect = (i + 1) % 2
            const polyCoords = []
            for (const j of line.coordinates) {
                polyCoords.push(j)
            }
            for (let j = (offsetLine[forCut].geometry.coordinates.length - 1); j >= 0; j--) {
                polyCoords.push(offsetLine[forCut].geometry.coordinates[j])
            }
            polyCoords.push(line.coordinates[0])
            const thickLineString = turf.lineString(polyCoords)
            const thickLinePolygon = turf.lineToPolygon(thickLineString)
            const clipped: any = turf.difference(polygon, thickLinePolygon)
            const cutPolyGeoms = []
            if (!clipped) continue
            for (const j of clipped.geometry.coordinates) {
                const polyg: any = turf.polygon(j)
                const intersect = turf.lineIntersect(polyg, offsetLine[forSelect])
                if (intersect.features.length > 0) {
                    cutPolyGeoms.push(polyg.geometry.coordinates)
                }
            }
            cutPolyGeoms.forEach((geometry: any, index: any) => {
                const ids = idPrefix + (i + 1) + '.' +  (index + 1)
                cutFeatures.push(turf.polygon(geometry, {id: ids}))
            })
        }
        if (cutFeatures.length > 0) retVal = turf.featureCollection(cutFeatures)
        return retVal
    }

    private getFieldCenter(coordinates: any) {
        let Xmin = Number.MAX_SAFE_INTEGER
        let Xmax = Number.MIN_SAFE_INTEGER
        let Ymin = Number.MAX_SAFE_INTEGER
        let Ymax = Number.MIN_SAFE_INTEGER
        for (const arr of coordinates[0][0]) {
            Xmax = arr[0] > Xmax ? arr[0] : Xmax
            Xmin = arr[0] < Xmin ? arr[0] : Xmin
            Ymax = arr[1] > Ymax ? arr[1] : Ymax
            Ymin = arr[1] < Ymin ? arr[1] : Ymin
        }
        return [(Xmax + Xmin) / 2, (Ymax + Ymin) / 2]
    }

    private toggleError(index: any) {
        this.selectedErrorIndex = this.selectedErrorIndex === index ? -1 : index
    }

    private closeError(index: any) {
        this.selectedErrorIndex = -1
    }

    private removeError(index: any) {
        this.getRegisterFarms.errorPoints.splice(index, 1)
        this.selectedErrorIndex = -1
    }
}
