






import { Component, Vue } from 'vue-property-decorator'
import {Getter, Mutation} from 'vuex-class'
import {GetterNames, MutationNames} from '@/store/types'
import {httpDelete, httpGet} from '@/util/http'
import Farm from '@/models/Farm'
import i18n from '@/i18n'
import {apiUrl, baseUrl} from '@/domain/constants'
import {Route} from 'vue-router'

@Component
export default class CadastreInfo extends Vue {
  @Getter(GetterNames.getCadastreData) private getCadastreData!: any

  private toStringArea(area: any) {
      return Math.round(area / 100) / 100 + ' га'
  }

  private close() {
    this.$router.back()
  }
}
