











import { Component, Vue, Prop} from 'vue-property-decorator'
@Component
export default class InfoText extends Vue {
    @Prop()
    private title!: string
    @Prop()
    private text!: string
}
