export default {
    phrases: {
        no_data: "No data",
        for: "For",
        new: "new",
        yes: "Yes",
        no: "No",
        from: "From: ",
        answered: "Answered",
        not_answered: "Not answered",
        accept: "Accept",
        decline: "Decline",
        search: "Search",
        error: "Error",
        tasks: {
            done: "Done",
            in_process: "In progress",
            outdated: "Expired"
        },
        ot: "from ",
        do: "to ",
        t: " t,",
        unknown: "Unknown",
        your: "Write your own"
    },
    not_chosen: "Not chosen",
    errors: {
        nothing_found: "Nothing found",
        number: "Enter the phone number",
        data: "Data is not supported by the system",
        no_seasons: "No selected seasons",
        no_corresponding_season: "No corresponding season",
        farm_exists: "A farm with this name already exists!",
        required_field: "This is a required field.",
        password_min_len: "The minimum password length is 6.",
        passwords_dont_match: "Passwords mismatch.",
        incorrect_num: "Incorrect phone number format",
        incorrect_email: "Invalid mail format",
        password_six: "Password must be more than 6 characters",
        field_required_to_fill: "This field is required to be filled",
        no_permission: "You do not have an access to this page",
        no_description: "No description",
        no_info: "No information",
        type_season: "Type the season",
        incorrect_season: "Incorrectly typed season",
        incorrect_yield: "Incorrectly typed yield",
        already_exists_history: "History for this season already exists",
        field_name: "Type field name",
        already_exists_field: "Field with this name already exists",
        vertices_outside: "Vertices cannot be outside of the cell",
        not_enough_data: "Not enough data for  processing",
        no_tasks: "You do not have any tasks",
        no_cadastres: "No cadastres",
        no_warehouse: "PLease choose a warehouse",
        all_problems: "First fill in all required fields for the problem(-s)",
        negative_area: "Area cannot be negative value",
        eleven_digit_cadastre: "Cadastre number should be 11 digit number"
    },
    success: {
        changes_saved: "Changes are saved",
        data_changed: "Data is changed",
        profile_data_changed: "Profile data was changed successfully",
        note_deleted: "Note deleted",
        passport_updated: "Passport successfully renewed | Passport successfully renewed. Select a field to view details",
        deleted: "Deleted",
        history_added: "History added",
        note_added: "Note added",
        reply_added: "Response added",
        note_updated: "Note updated",
        event_deleted: "The event is removed",
        farm_deleted: "Farm deleted",
        device_updated: "The aggregate has been updated",
        device_added: "Aggregate added",
        car_updated: "The machine has been updated",
        car_added: "Machine added",
        account_found: "The account was found",
        farm_added: "The farm was successfully added | The farm was successfully added. Select a farm to view details",
        successfully_added: "Successfully added",
        successfully_updated: "Successfully updated",
        connection: "Connection successfully established",
        staff_added: "Employee was successfully added",
        project_updated: "Project successfully updated"
    },
    questions: {
        delete: {
            title: "Are you sure you want to delete?",
            farm: "Do you really want to delete the farm?",
            note: "Are you sure you want to delete the note?",
            device: "Are you sure you want to delete the device?",
            record: "Are you sure you want to delete the record?",
            history: "Are you sure you want to delete the history?",
            events: "Are you sure you want to delete all chosen events?",
            event: "Are you sure you want to delete this event?",
            staff: "Are you sure you want to delete the employee?",
            storage: "Are you sure you want to delete the storage?"
        },
        found: {
            variety: "Haven't found a variety?"
        }
    },
    instructions: {
        add_title: "Add a title",
        smth: "Write something",
        note_text: "Fill in the note text",
        event_name: "Fill in the event name",
        event_date: "Fill in the event date",
        event_cost: "Fill in the event cost",
        farm_name: "Enter the name of the farm",
        width: "Enter the width of the coverage",
        machine_class: "Fill in the machine class",
        machine_model: "Fill in the machine model",
        machine_state_num: "Fill in the machine state number",
        whatever: "You don't have to fill it out",
        machine_name: "Enter state number",
        machine_imei: "Enter IMEI",
        machine_capacity: "Enter the capacity of the tank",
        machine_consumption: "Enter fuel consumption",
        passport: "Fill in passport",
        number: "Enter the number",
        type_value: "Type the value",
        storage_name: "Enter a storage name",
        customer_fullname: "Enter customer's fullname",
        type_amount: "Please indicate an amount",
        type_cost: "Please indicate a cost",
        type_name: "PLease indicate a type",
        access_from_admins: "To access this page, please send a request to the administrator",
        close_n_try_again: "Close the project and try again",
        finish_editing: "Click 'Finish editing' to save your changes",
        processing: "Your request is being processed",
        fill_form: "Fill out the form",
        max_photo: "You can upload a maximum of 10 photos",
        request_processing: "Your request is being processed",
        select_crop: "To make it easier to work with tasks, you can choose one of the eight cultures listed below or write your own.",
        email: "Enter the email",
        employee_name: "Enter employee's name",
        employee_surname: "Enter employee's surname",
        phone: "Enter phone number",
        upload_file: "Upload a file",
        type_or_choose_event: "Choose an event",
        subscription: {
            title: "Subscribe",
            content: "Order new data to analyze the performance of your field at the moment"
        },
        season: {
            title: "Select season",
            content: "Select a season for your fields, it helps you organize the data over multiple seasons and is important for getting data on your fields"
        },
        farm_divided: {
            title: "Your farm is divided into fields",
            content: "Dividing the fields simplifies the management of your farm, all further possibilities are inside the fields"
        },
        rating: {
            title: "Ranking of your fields",
            content: "Rating so that you know which field shows a good result, or vice versa, or see which field is the best, etc."
        },
        order_agrochem: {
            title: "Order an agrochemical analysis for a farm",
            content: "You can also make an agrochemical analysis for the whole farm from our partners. You can find out more by going to the section"
        },
        cadastre_edit: {
            edit: {
                1: "Choose a cell to edit"
            },
            draw: {
                1: "Draw the vertices of the cell borders to create a new cell",
                2: "Edit and save a new cell"
            },
            delete: {
                1: "Choose a cell to delete",
                2: "Click \"Save\" to complete a deletion"
            },
            merge: {
                1: "Choose two cells to merge",
                2: "Click \"Save\" to complete a merge"
            },
            split: {
                1: "Draw a line to split",
                2: "Click \"Save\" to complete a split"
            },
            vertix: {
                1: "Choose a cell, which contains a vertix you want to delete",
                2: "Press Alt and choose a vertex to delete"
            },
            vertices: {
                "1": "Choose a cell, which contains vertices you want to delete",
                "1_5": "Draw the area, which contains vertices you want to delete",
                "2": "Click \"Save\" to complete vertices deletion"
            },
            hole: {
                "1": "Choose a cell, where you want to make a hole",
                "1_5": "Draw a hole in a cell",
                "2": "Click \"Save\" to save changes"
            },
            info: {
                1: "Hover over an object you want to laern more about"
            }
        }
    },
    selections: {
        all: "Select all",
        farm: "Select the farm",
        field: "Select the field",
        device: "Select the name of device",
        class: "Select the brand",
        model: "Select the model",
        season: "Select the season",
        operation: "Select operation",
        access_type: "Select access type",
        all_fields: "All fields",
        all_farms: "All farms",
        cadastre: "Select the cadastre",
        phenophase: "Select phenophase",
        problem: "Select the note problem",
        date: "Select the date of the detour",
        date_title: "Choose a date",
        machine: "Select a machine",
        device_select: "Select an aggregate",
        category: "Select category",
        ready: "Select from the ready-made ones or write your own",
        season_or_year: "Select a season OR indicate the year",
        income_type: "Select an income type",
        supplier: "Enter the supplier",
        staff: "Select an employee",
        event: "Select an event",
        expense_type: "Select an expense type",
        storage: "Select a storage",
        storage_type: "Select a storage type",
        equipment: "Select an equipment",
        scaling: "Select a scaling unit",
        crop: {
            title: "Choose the crop",
            crops: "Crops",
            options: ["All crops"],
            growing_crop: "Choose the crop to grow",
            or_type: "Choose a culture or type your own"
        },
        place: "Choose a region, city, or village"
    },
    telematics: {
        speed_exceed: "Speed exceeded!"
    },
    confirm: {
        delete: {
            photo: "The photo will be deleted permanently"
        },
        copy: {
            tasks: "Do you really want to copy tasks to another cell?"
        },
        exit: {
            not_saved: "Are you sure you want to exit? Changes will not be saved"
        },
        project: {
            accept: "Are you sure you want to accept the project?",
            decline: "Are you sure you want to decline the project?",
            delete: "Are you sure you want to delete the project?"
        }
    }
}