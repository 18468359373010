


















































































































































































import {Component, Vue, Watch} from 'vue-property-decorator'
import {Getter, Mutation} from 'vuex-class'
import {GetterNames, MutationNames} from '@/store/types'
import DateRangePicker from '@/components/DateRangePicker.vue'
import TelematicsGraph from './TelematicsGraph.vue'
import BaseDropdownContainer from "@/components/BaseDropdownContainer.vue"
import {httpDelete, httpGet} from '@/util/http'
import {apiUrl3, apiUrl} from '@/domain/constants'
import Chart from 'chart.js/auto'
import i18n from '@/i18n'

@Component({
    components: {
        DateRangePicker,
        TelematicsGraph,
        BaseDropdownContainer
    }
})
export default class TelematicsControlPanel extends Vue {

    get extended() {
        return this.getTelematicsInfo.controlPanelExtension
    }
    @Getter(GetterNames.getTelematicsInfo) private getTelematicsInfo!: any
    @Getter(GetterNames.getFarmFromRoute) private getFarmFromRoute!: any
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: any

    @Mutation(MutationNames.setFuelStopTechnic) private setFuelStopTechnic!: any
    @Mutation(MutationNames.setTelematicsInfo) private setTelematicsInfo!: any
    @Mutation(MutationNames.setIsDataLoading) private setIsDataLoading!: any
    @Mutation(MutationNames.setIsShowStopTechnic) private setIsShowStopTechnic!: any
    private autoplay = false
    private playbackSpeed = '300'
    private viewGraph = false
    private currentTractor: any = null
    private currentTractorIndex = -2
    private currentPage = 1
    private currentTractorDropdown = false
    private historyMode = false
    private tractorDetailMode = false
    private timelineStartDate = new Date()
    private timelineEndDate = new Date()
    private differenceInTimeline = 0
    // chart - start
    private fuelChart: any = null
    private speedChart: any = null
    // chart - end
    private maxLinesForTrack: any = []
    private colors: any = ['#2B4DFF', '#AA27E8', '#F37500', '#06BAF2', '#FFC700', '#5AF5D9', '#7D5EF8', '#FC7BE7', '#ADDE30']
    private coverageColors: any = ['rgba(43,77,255, 0.25)',  'rgba(170,39,232, 0.25)', 'rgba(243,117,0, 0.25)', 'rgba(6,186,242, 0.25)',
        'rgba(255,199,0, 0.25)', 'rgba(90,245,217, 0.25)', 'rgba(125,94,248, 0.25)', 'rgba(252,123,231, 0.25)', 'rgba(173,222,48, 0.25)']

    private months: any = ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек']

    private chartFuelData: any = []
    private chartDateDate: any = []

    private chartControl: any = {
        fuel: true,
        speed: true,
        fullData: true,
        index: 0
    }

    private analysisFuelData: any = null

    // change after nurbergen
    private mainCategories = [{title: 'Путь', value: 'road'}, {title: 'Топливо', value: 'fuel'}
    ]

    private selectedCategory = this.mainCategories[0].value

    private chartSpeedControlIndex = 0

    private mounted() {
        this.getTelematicsInfo.fuelStop = false
        this.getTelematicsInfo.playerIntervalID = setTimeout(() => this.timeoutInterval(), 1000 / Number(this.playbackSpeed))
        this.getMainTractorName()
    }

    private check() {
        this.selectedCategory = 'road'
        this.viewGraph = true
    }

    private togglePanelExtension() {
        this.setTelematicsInfo({ controlPanelExtension: !this.extended })
    }

    private changeCurrentTractor(index: any) {
        if (this.getTelematicsInfo.tractorsData[index].IS_VISIBLE) return

        this.toggleTractor(index)
    }

    private getCurrentTractorMaxLines() {
        const currentLines = this.maxLinesForTrack.find((x: any) => x.index === this.currentTractorIndex)
        if (currentLines) {
            return currentLines.lines
        }
    }

    private toggleTractor(index: number) {
        const currentTractor = this.getTelematicsInfo.tractorsData[index]
        currentTractor.IS_VISIBLE = !currentTractor.IS_VISIBLE
        if (!currentTractor.IS_VISIBLE) return this.currentTractorIndex = -1
        this.currentTractorIndex = index
        if (currentTractor.START_DATE) return
        currentTractor.clearHistoryData()
        this.setIsDataLoading(true)
        httpGet({
            url:  `${apiUrl}/technology/fact/tasks/fields/tractors/${currentTractor.tractorID}/telematics/`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                if (json.ok) {
                    this.setTelematicsInfo({ autoTracking: { mainTractorID: currentTractor.tractorID, tracking: false } })
                    if (json.json.statistics) {
                        this.timelineStartDate = new Date(json.json.start_date_time)
                        this.timelineEndDate = new Date(json.json.end_date_time)
                        this.differenceInTimeline = this.timelineEndDate.getTime() - this.timelineStartDate.getTime()
                        let previousDate = this.timelineStartDate

                        json.json.statistics.road_path_with_time_speed.forEach((point: any) => {

                            const newDate = new Date(point.date_time)
                            const differenceInSeconds = (newDate.getTime() - previousDate.getTime()) / 1000
                            if (differenceInSeconds > 1800 ) {
                                this.getTelematicsInfo.tractorsData[index].addNosignalHistory({
                                    tileStartFrom: ((Math.max((previousDate.getTime() - this.timelineStartDate.getTime()), 0) / this.differenceInTimeline) * 100).toFixed(2) + '%',
                                    tileWidth: (((differenceInSeconds * 1000) / this.differenceInTimeline) * 100).toFixed(2) + '%',
                                    date_time: previousDate,
                                    intervalInMinutes: differenceInSeconds / 60
                                })
                            }
                            previousDate = newDate
                            const data = {
                                course: point.course,
                                created_at: '',
                                date_time: point.date_time,
                                fuel_total: point.fuel_total ? point.fuel_total : 0,
                                point: {
                                    coordinates: point.point
                                },
                                protocol: 1,
                                sats: 14,
                                speed: point.speed
                            }
                            this.getTelematicsInfo.tractorsData[index].addLiveData(data)
                            this.getTelematicsInfo.tractorsData[index].currentCoordinates.push(point.point)
                            this.getTelematicsInfo.tracktime = Date.parse(point.date_time)
                            this.getTelematicsInfo.tractorsData[index].processedArea = json.json.statistics.processed_area
                            this.getTelematicsInfo.tractorsData[index].workingHours = json.json.statistics.working_hours
                            this.getTelematicsInfo.tractorsData[index].updateCurrentIndex(Date.parse(point.date_time))
                        })
                    }
                    if (json.json.statistics.stop_points) {
                        json.json.statistics.stop_points.forEach((stopPoint: any) => {
                            this.getTelematicsInfo.tractorsData[index].addStopPointData(stopPoint)
                        })
                    }
                    if (json.json.statistics.max_min_lines) {
                        json.json.statistics.max_min_lines.max_lines.forEach((line: any) => {
                            this.getTelematicsInfo.tractorsData[index].addMaxSpeedData(line)
                        })
                        json.json.statistics.max_min_lines.min_lines.forEach((line: any) => {
                            this.getTelematicsInfo.tractorsData[index].addMinSpeedData(line)
                        })
                        this.getMaxLinesForTrack()
                        // console.log(this.getTelematicsInfo.tractorsData[index].minspeedData, 'min')
                        // console.log(this.getTelematicsInfo.tractorsData[index].maxspeedData, 'max')
                    }
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setIsDataLoading(false)
            }
        })
    }

    private changeLiveBroadcast(id: number) {
        const index = this.getTelematicsInfo.tractorsData.findIndex((x: any) => x.complex.id === id)
        this.getTelematicsInfo.tractorsData.forEach((x: any) => {
            x.IS_LIVE = false
            this.getTelematicsInfo.tractorsData[x.index].clearData()
            this.getTelematicsInfo.tractorsData[x.index].IS_HISTORY = false
        })
        if (this.historyMode) {
            this.getTelematicsInfo.tractorsData[index].clearData()
            this.changeHistoryBroadcast(index)
        } else {
            this.getTelematicsInfo.tractorsData[index].IS_LIVE = true
        }
        this.currentTractor = this.getTelematicsInfo.tractorsData[index].complex.tractor.name
        this.currentTractorDropdown = false
    }

    private changeHistoryBroadcast(index: number) {
        if (index !== -1) {
            if (this.getTelematicsInfo.tractorsData[index].IS_HISTORY) {
                this.getTelematicsInfo.tractorsData[index].clearData()
                this.getTelematicsInfo.tractorsData[index].IS_HISTORY = false
            } else {
                this.getTelematicsInfo.tractorsData.forEach((x: any) => {
                    if (x.IS_LIVE || x.IS_HISTORY) {
                        this.getTelematicsInfo.tractorsData[x.index].clearData()
                        this.getTelematicsInfo.tractorsData[x.index].IS_LIVE = false
                        this.getTelematicsInfo.tractorsData[x.index].IS_HISTORY = false
                    }
                })
                const today = new Date()
                const endDate = this.formatDate(today)
                const yesterday = new Date(today.getTime())
                yesterday.setDate(today.getDate() - 1)
                const startDate = this.formatDate(yesterday)
                this.getTelematicsInfo.tractorsData[index].historyStartDate = startDate
                this.getTelematicsInfo.tractorsData[index].historyEndDate = endDate
                this.getTelematicsInfo.tractorsData[index].IS_HISTORY = true
            }
        }
    }

    private formatDate(date: any) {
        const d = new Date(date)
        let month = '' + (d.getMonth() + 1)
        let day = '' + d.getDate()
        const year = d.getFullYear()
        if (month.length < 2) {
            month = '0' + month
        }
        if (day.length < 2) {
            day = '0' + day
        }
        return [year, month, day].join('-') + ' ' + d.toLocaleTimeString('en-GB')
    }

    private getMainTractorName() {
        if (this.getTelematicsInfo.autoTracking.mainTractorID !== -1) {
            const tractor = this.getTelematicsInfo.tractorsData.find((x: any) =>
                x.tractorID === this.getTelematicsInfo.autoTracking.mainTractorID)
            if (tractor) {
              this.currentTractor = tractor.complex.tractor.name
              return `${tractor.complex.tractor.name} - ${tractor.tractorUID}`
            }
        }
        return this.getTelematicsInfo.autoTracking.mainTractorID
    }

    private getTractorList() {
        let tractors: any
        this.getTelematicsInfo.tractorsData.find((x: any) => x.IS_HISTORY === true) ? this.historyMode = true : this.historyMode = false
        if (this.historyMode) {
            tractors = this.getTelematicsInfo.tractorsData
          } else {
            tractors = this.getTelematicsInfo.tractorsData.filter((x: any) =>
                x.IS_ONLINE === true )
          }
        return tractors
    }

    private getTractorListFiltered() {
        const str = this.currentTractor
        if ( typeof str === 'string' ) {
            const list = this.getTractorList().filter((x: any) => x.complex.tractor.name.toLowerCase().includes(str.toLowerCase()))
            return list.length === 0 ? null : list
        }
        return this.getTractorList()
    }

    private async onchangeTrackTime(val: any) {
        const tracktime = Number(val)
        for (const [index, tractor] of this.getTelematicsInfo.tractorsData.entries()) {
            if (tractor.data.length > 0) {
                this.getTelematicsInfo.tractorsData[index].updateCurrentIndex(tracktime)
            }
        }
    }

    private getStartTime(isTime: boolean) {
        const startTime = this.getMinValue()
        if (startTime === 0) {
            if (isTime) return '00:00:00'
            else return 'ДД.ММ.ГГГГ'
        } else {
            const date = new Date(startTime)
            if (isTime) {
                const hour = date.getHours()
                const minute = date.getMinutes()
                const second = date.getSeconds()
                return `${(hour < 10 ? '0' : '') + hour}:${(minute < 10 ? '0' : '') + minute}:${(second < 10 ? '0' : '') + second}`
            } else {
                const day = date.getDate()
                const month = date.getMonth() + 1
                const year = date.getFullYear()
                return  `${(day < 10 ? '0' : '') + day}.${(month < 10 ? '0' : '') + month}.${year}`
            }
        }
    }

    private getCurrentTime(isTime: boolean) {
        const currentTime = Number(this.getTelematicsInfo.tracktime)
        if (currentTime < 2) {
            if (isTime) return '00:00:00'
            else return 'ДД.ММ.ГГГГ'
        } else {
            const date = new Date(currentTime)
            if (isTime) {
                const hour = date.getHours()
                const minute = date.getMinutes()
                const second = date.getSeconds()
                return `${(hour < 10 ? '0' : '') + hour}:${(minute < 10 ? '0' : '') + minute}:${(second < 10 ? '0' : '') + second}`
            } else {
                const day = date.getDate()
                const month = date.getMonth() + 1
                const year = date.getFullYear()
                return  `${(day < 10 ? '0' : '') + day}.${(month < 10 ? '0' : '') + month}.${year}`
            }
        }
    }

    private getLastTime(isTime: boolean) {
        const lastTime = this.getMaxValue()
        if (lastTime === 1) {
            if (isTime) return '00:00:00'
            else return 'ДД.ММ.ГГГГ'
        } else {
            const date = new Date(lastTime)
            if (isTime) {
                const hour = date.getHours()
                const minute = date.getMinutes()
                const second = date.getSeconds()
                return `${(hour < 10 ? '0' : '') + hour}:${(minute < 10 ? '0' : '') + minute}:${(second < 10 ? '0' : '') + second}`
            } else {
                const day = date.getDate()
                const month = date.getMonth() + 1
                const year = date.getFullYear()
                return  `${(day < 10 ? '0' : '') + day}.${(month < 10 ? '0' : '') + month}.${year}`
            }
        }
    }

    private getMinValue() {
        let array = this.getTelematicsInfo.tractorsData.filter((x: any) => x.IS_HISTORY && x.START_DATE).map((x: any) => {
        return new Date(x.START_DATE).getTime()
        })

        if (array.length === 0) {
            array = this.getTelematicsInfo.tractorsData.filter((x: any) => x.START_DATE).map((x: any) => {
                return new Date(x.START_DATE).getTime()
            })
        }

        if (array.length > 0 && array[0] > 0) {
            const result = Math.min.apply(Math, array)
            return result
        } else {
            this.getTelematicsInfo.tracktime = 0
            return 0
        }
    }

    private getMaxValue() {
        let array = this.getTelematicsInfo.tractorsData.filter((x: any) => x.IS_HISTORY && x.START_DATE).map((x: any) => {
            return new Date(x.LAST_DATE).getTime()
        })

        if (array.length === 0) {
            array = this.getTelematicsInfo.tractorsData.filter((x: any) => x.LAST_DATE).map((x: any) => {
                return new Date(x.LAST_DATE).getTime()
            })
        }

        if (array.length > 0) {
            const result = Math.max.apply(Math, array)
            return result
        } else return 1
    }

    private changeAutoPlay() {
        this.getTelematicsInfo.autoplay = !this.getTelematicsInfo.autoplay
    }

    private changeArrowsView() {
        this.getTelematicsInfo.arrowsView = !this.getTelematicsInfo.arrowsView
    }

    private changeSpeedLimitsView() {
        this.getTelematicsInfo.speedLimitsView = !this.getTelematicsInfo.speedLimitsView
    }

    private changeStopsView() {
        this.getTelematicsInfo.stopsView = !this.getTelematicsInfo.stopsView
    }

    private changeFuelPlace() {
        this.getTelematicsInfo.fuelStop = !this.getTelematicsInfo.fuelStop
    }

    private timeoutInterval() {
        clearTimeout(this.getTelematicsInfo.playerIntervalID)
        if (this.currentRoute.name === 'farmtelematics' || this.currentRoute.name === 'fieldtelematics') {
            if (this.getTelematicsInfo.autoplay) {
                if ((Number(this.getTelematicsInfo.tracktime) + 1000) <= this.getMaxValue() && this.getMaxValue() !== 1) {
                    this.getTelematicsInfo.tracktime = Number(this.getTelematicsInfo.tracktime) + 1000
                    for (const [index, tractor] of this.getTelematicsInfo.tractorsData.entries()) {
                        if (tractor.data.length > 0) {
                            if (tractor.currentIndex + 1 < tractor.data.length) {
                                const next = tractor.data[tractor.currentIndex + 1]
                                const time = new Date(next.date_time)
                                if (Number(this.getTelematicsInfo.tracktime) >= time.getTime()) {
                                    this.getTelematicsInfo.tractorsData[index].setCurrentIndex(tractor.currentIndex + 1, false)
                                }
                            }
                        }
                    }
                }
                this.getTelematicsInfo.playerIntervalID = setTimeout(() => this.timeoutInterval(), 1000 / Number(this.playbackSpeed))
            }
        }
    }

    @Watch('getTelematicsInfo.autoplay')
    private onchangeAutoplay(val: any, old: any) {
        if (val !== old) {
            if (val) {
                this.getTelematicsInfo.playerIntervalID = setTimeout(() => this.timeoutInterval(), 1000 / Number(this.playbackSpeed))
            }
        }
    }

    private setNewDateRange(startDate: any, endDate: any) {
        if (this.getTelematicsInfo.autoTracking.mainTractorID !== -1) {
            const tractor = this.getTelematicsInfo.tractorsData.find((x: any) =>
                x.tractorID === this.getTelematicsInfo.autoTracking.mainTractorID)
            if (tractor) {
                if (tractor.IS_HISTORY) {
                    this.getTelematicsInfo.tractorsData[tractor.index].clearData()
                }
                this.getTelematicsInfo.tracktime = Date.parse(startDate)
                this.getTelematicsInfo.tractorsData[tractor.index].historyStartDate = startDate
                this.getTelematicsInfo.tractorsData[tractor.index].historyEndDate = endDate
                this.getTelematicsInfo.tractorsData[tractor.index].IS_HISTORY = true
            }
        }
    }

    private getMaxLinesForTrack() {
        const data = this.getTelematicsInfo.tractorsData[this.currentTractorIndex].maxspeedData
        const maxLinesForTrack: any = []
        data.forEach((line: any) => {
            const startDate = new Date(line.properties.start_time)
            const endDate = new Date(line.properties.end_time)
            const differenceInMinutes = (endDate.getTime() - startDate.getTime()) / 1000 / 60
            if (differenceInMinutes > 1 ) {
                maxLinesForTrack.push({
                    tileStartFrom: (((startDate.getTime() - this.timelineStartDate.getTime()) / this.differenceInTimeline) * 100).toFixed(2) + '%',
                    tileWidth: (((differenceInMinutes * 1000 * 60) / this.differenceInTimeline) * 100).toFixed(2) + '%',
                    date_time: startDate,
                    intervalInMinutes: differenceInMinutes
                })

            }
        })
        this.maxLinesForTrack.push({index: this.currentTractorIndex, lines: maxLinesForTrack})
    }

    private changePage(value: any) {
        if (Number(value) === 2) {
            this.fetchAnalysisFuelData()
            // this.updateChartData()
        }
    }

    // @Watch('getTelematicsInfo.autoTracking.mainTractorID')
    // private updateData() {
    //     console.log(this.getMainTractorName())
    //     if (this.getTelematicsInfo.autoTracking.mainTractorID !== -1) {
    //         const tractor = this.getTelematicsInfo.tractorsData.find((x: any) => x.tractorID === this.getTelematicsInfo.autoTracking.mainTractorID)
    //         if (tractor) {
    //             let speedData = []
    //             let fuelData = []
    //             if (this.chartControl.fullData) {
    //                 speedData = tractor.speedData
    //                 fuelData = tractor.fuelData
    //             } else {
    //                 if (tractor.speedData.length >= this.chartControl.index * 500) {
    //                     speedData = tractor.speedData.slice(this.chartControl.index * 500, (this.chartControl.index + 1) * 500)
    //                     fuelData = tractor.fuelData.slice(this.chartControl.index * 500, (this.chartControl.index + 1) * 500)
    //                 } else {
    //                     this.chartControl.index = 0
    //                     speedData = tractor.speedData.slice(this.chartControl.index * 500, (this.chartControl.index + 1) * 500)
    //                     fuelData = tractor.fuelData.slice(this.chartControl.index * 500, (this.chartControl.index + 1) * 500)
    //                 }
    //             }
    //             const newData: any = {
    //                 labels: speedData.map((x: any) => x.t),
    //                 datasets: []
    //             }
    //             if (this.chartControl.speed) {
    //                 newData.datasets.push({
    //                     label: 'Скорость (км/ч)',
    //                     data: speedData.map((x: any) => x.y),
    //                     fill: false,
    //                     borderColor: '#FFC700',
    //                     backgroundColor: '#FFC700',
    //                     pointHitRadius: 5,
    //                     yAxisID: 'y1' // 'right'
    //                 })
    //             }
    //             if (this.chartControl.fuel) {
    //                 newData.datasets.push({
    //                     label: 'Уровень топлива (л)',
    //                     data: fuelData.map((x: any) => x.y),
    //                     fill: false,
    //                     borderColor: '#4B970F',
    //                     backgroundColor: '#4B970F',
    //                     pointHitRadius: 5,
    //                     yAxisID: 'y' // 'left'
    //                 })
    //             }
    //             // this.fuelChart.data.datasets = data
    //             console.log(newData)
    //             this.fuelChart.data = newData
    //             this.fuelChart.update()
    //         }
    //     }
    // }

    private deleteTractor(tractor: any) {
        if (confirm(i18n.t('messages.questions.delete.history') as string)) {
            httpDelete({
                url: `${apiUrl}/technology/fact/tasks/fields/${this.getTelematicsInfo.fieldEvent.event.id}/tractors/${tractor.tractorID}/delete/`,
                isSecureRequest: true,
                onSuccess: () => {
                    alert(i18n.t('messages.success.deleted'))
                    const tractors = this.getTelematicsInfo.tractorsData
                    const filtered = tractors.filter((x: any) => x.tractorID !== tractor.tractorID)
                    this.setTelematicsInfo({tractorsData: filtered})
                    console.log(tractors, filtered)
                },
                onError: error => {
                    console.log(error)
                },
                doFinally: () => {
                    console.log('asdf')
                }
            })
        }
    }

    @Watch('selectedCategory')
    private changeCategory(val: any) {
        if (val === 'fuel') {
            this.getTelematicsInfo.fuelStop = true
            this.fetchAnalysisFuelData()
        } else {
            this.getTelematicsInfo.fuelStop = false
        }
    }

    // fuel
    private fetchAnalysisFuelData() {
        this.setIsDataLoading(true)
        const start = this.getTelematicsInfo.historyStart.toLocaleString().split(',')[0].split('.').join('-') + this.getTelematicsInfo.historyStart.toLocaleString().split(',')[1]
        const end = this.getTelematicsInfo.historyEnd.toLocaleString().split(',')[0].split('.').join('-') + this.getTelematicsInfo.historyStart.toLocaleString().split(',')[1]
        httpGet({
            url:  `${apiUrl}/telematics/get/history/fuel/?start=${start}&end=${end}&tractor_id=${this.getTelematicsInfo.autoTracking.mainTractorID}&group_user_id=${this.getFarmFromRoute.id}`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                if (json.ok) {
                    this.analysisFuelData = json.json
                    this.setFuelStopTechnic({
                        technicId: this.getTelematicsInfo.autoTracking.mainTractorID,
                        refuelings: json.json.refuelings
                    })
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.showChart()
                this.setIsDataLoading(false)
            }
        })
    }

    private showChart() {
        if (this.fuelChart) {
            this.fuelChart.destroy()
        }
        const ctx: any = document.getElementById('fuel-chart-main')
        this.fuelChart = new Chart(ctx.getContext('2d'), {
            type: 'line',
            data: {
                datasets: []
            },
            options: {
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        callbacks: {
                            label: (context: any) => {
                                return `${context.raw.toFixed(2)} л`
                            }
                        }
                    }
                },
                scales: {
                    x: {
                        stacked: true,
                        beginAtZero: true,
                        grid: {
                            display: false,
                            drawBorder: false
                        },
                        ticks: {
                            color: '#FFFFFF'
                        }
                    },
                    y: {
                        stacked: true,
                        beginAtZero: true,
                        ticks: {
                            color: '#FFFFFF'
                        },
                        grid: {
                            color: (context: any) => {
                                return "#2E3656"
                            }
                        }
                    }
                },
                elements: {
                    point: {
                        radius: 0
                    }
                }
            }
        })
        this.updateChartData()
    }

    private updateChartData() {
        const date = this.chartFuelData = this.analysisFuelData.fuel_data.map((x: any) =>
            `${new Date(x.date_time).getDate()} ${this.months[new Date(x.date_time).getMonth()]}` + ' ' +
            `${new Date(x.date_time).getHours().toString().length > 1 ? new Date(x.date_time).getHours() : `0${new Date(x.date_time).getHours()}` }:${new Date(x.date_time).getMinutes().toString().length > 1 ? new Date(x.date_time).getMinutes() : `0${new Date(x.date_time).getMinutes()}` }:${new Date(x.date_time).getSeconds().toString().length > 1 ? new Date(x.date_time).getSeconds() : `0${new Date(x.date_time).getSeconds()}` }`
            // `${new Date(x.date_time).getHours()}:${new Date(x.date_time).getMinutes()}:${new Date(x.date_time).getSeconds()}`
        )
        const newData: any = {
            labels: date,
            datasets: []
        }
        newData.datasets.push({
            label: '1',
            data: this.analysisFuelData.fuel_data.map((x: any) => x.fuel_total),
            fill: false,
            borderColor: '#4E85FF',
            backgroundColor: '#4E85FF',
            tension: 0.5
        })
        this.fuelChart.data = newData
        this.fuelChart.update()
    }

    // speed
    private initAnalysisSpeedData() {
        if (this.speedChart) {
            this.speedChart.destroy()
        }
        const ctx: any = document.getElementById('speed-chart-main')
        this.speedChart = new Chart(ctx.getContext('2d'), {
            type: 'line',
            data: {
                datasets: []
            },
            options: {
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        callbacks: {
                            label: (context: any) => {
                                return `${context.raw.toFixed(2)} км/ч`
                            }
                        }
                    }
                },
                scales: {
                    x: {
                        stacked: true,
                        beginAtZero: true,
                        grid: {
                            display: false,
                            drawBorder: false
                        },
                        ticks: {
                            color: '#FFFFFF'
                        }
                    },
                    y: {
                        stacked: true,
                        beginAtZero: true,
                        ticks: {
                            color: '#FFFFFF'
                        },
                        grid: {
                            color: (context: any) => {
                                return "#2E3656"
                            }
                        }
                    }
                },
                elements: {
                    point: {
                        radius: 0
                    }
                }
            }
        })
        this.updateSpeedChartData()
    }

    private updateSpeedChartData() {
        if (this.getTelematicsInfo.autoTracking.mainTractorID !== -1) {
            const tractor = this.getTelematicsInfo.tractorsData.find((x: any) => x.tractorID === this.getTelematicsInfo.autoTracking.mainTractorID)
            if (tractor) {
                let speedData = []
                if (tractor.speedData.length >= this.chartSpeedControlIndex * 500) {
                    speedData = tractor.speedData.slice(this.chartSpeedControlIndex * 500, (this.chartSpeedControlIndex + 1) * 500)
                } else {
                    this.chartSpeedControlIndex = 0
                    speedData = tractor.speedData.slice(this.chartControl.index * 500, (this.chartSpeedControlIndex + 1) * 500)
                }
                const date = speedData.map((x: any) =>
                    `${new Date(x.t).getDate()} ${this.months[new Date(x.t).getMonth()]}` + ' ' +
                    `${new Date(x.t).getHours().toString().length > 1 ? new Date(x.t).getHours() : `0${new Date(x.t).getHours()}` }:${new Date(x.t).getMinutes().toString().length > 1 ? new Date(x.t).getMinutes() : `0${new Date(x.t).getMinutes()}` }:${new Date(x.t).getSeconds().toString().length > 1 ? new Date(x.t).getSeconds() : `0${new Date(x.t).getSeconds()}` }`
                )
                const newData: any = {
                    labels: date,
                    datasets: []
                }
                if (this.chartControl.speed) {
                    newData.datasets.push({
                        label: 'Скорость (км/ч)',
                        data: speedData.map((x: any) => x.y),
                        fill: false,
                        borderColor: '#FFC700',
                        backgroundColor: '#FFC700'
                    })
                }
                console.log(newData)
                this.speedChart.data = newData
                this.speedChart.update()
            }
        }
    }


}
