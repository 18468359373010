

































































































import { Vue, Component } from "vue-property-decorator"
import { Getter, Mutation } from "vuex-class"
import { GetterNames, MutationNames } from "@/store/types"
import { httpPost } from '@/util/http'
import { apiUrl, baseUrl } from '@/domain/constants'
import Chart from "chart.js/auto"
@Component
export default class FieldDashboardTechnic extends Vue {
    @Getter(GetterNames.getSidebarModel) private getSidebarModel!: any
    @Getter(GetterNames.getCurrentSeason) private getCurrentSeason!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any

    private months: any = ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек']
    private chart: any = null
    private technicData: any = null

    private workingHours: any = []

    private mounted() {
        this.fetchTechnicData()
    }

    private fetchTechnicData() {
        this.setLoadingDataNames('TechMapBoardGetCurrentTechmap')
        httpPost({
            url: `${apiUrl}/technology/dashboard/working/technique/detail/`,
            isSecureRequest: true,
            body: {
                tractor: this.$route.params.id,
                group_season: this.getCurrentSeason.id,
                task_name: this.$route.params.task_name
            },
            onSuccess: json => {
                this.technicData = json
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('TechMapBoardGetCurrentTechmap')
                this.initialGraph()
            }
        })
    }

    private initialGraph() {
        const ctx: any = document.getElementById('technic_graph')
        this.chart = new Chart(ctx.getContext('2d'), {
            type: 'bar',
            data: {
                datasets: []
            },
            options: {
                scales: {
                    x: {
                        // beginAtZero: true,
                        grid: {
                            display: false,
                            drawBorder: false
                        }
                    },
                    y: {
                        beginAtZero: true,
                        grid: {
                            drawBorder: false
                        }
                    }
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: (context: any) => {
                                const values = [
                                    `Площадь: ${context.raw[0]} га`,
                                    `Рабочие время: ${this.workingHours[context.raw[1]]} ч`
                                ]
                                return values
                            }
                        }
                    }
                }
            }
        })
        this.updateGraph()
    }

    private updateGraph() {
        const newData: any = {
            labels: this.technicData.chart_data.map((x: any) => this.months[x.date__month]),
            datasets: []
        }
        newData.datasets.push({
            label: 'Отчёт о проделанной работе, га',
            data: this.technicData.chart_data.map((x: any) => [Math.round(x.area), this.getWorkingHours(Math.round(x.working_hours))]),
            backgroundColor: ['#184BFC'],
            barPercentage: 0.5,
            barThickness: 'flex',
            maxBarThickness: 100,
            borderRadius: 10
        })
        this.chart.data = newData
        this.chart.update()
    }

    private getWorkingHours(value: any) {
        if (!this.workingHours.includes(value)) {
            this.workingHours.push(value)
        }
        return this.workingHours.indexOf(value) > 0 ? this.workingHours.indexOf(value) : 0
    }

    private getTractorImageByCategoryId(id: number) {
        switch (id) {
            case 1: return 'tractor'
            case 2: return 'combine'
            case 3: return 'tractor'
            case 4: return 'harvester'
            case 5: return 'tractor'
            case 6: return 'truck'
            case 7: return 'tanker'
            case 8: return 'loader'
            case 9: return 'dumper'
            case 10: return 'tractor'
            case 11: return 'tractor'
            default: return 'combine'
        }
    }

    // private customTooltip: any = {
    //     id: 'customTooltip',
    //     afterDraw(chart: any) {
    //         const {ctx, chartArea: {top, bottom, left, right, width, height}, tooltip, scales: {x, y}} = chart
    //         console.log(tooltip)
    //         if (tooltip._active[0]) {
    //             ctx.beginPath()
    //             ctx.shadowStyle = 'black'
    //             ctx.strokeColor = 'white'
    //             ctx.shadowBlur = 15
    //             ctx.lineWidth = 10
    //             ctx.lineJoin = 'round'
    //             ctx.moveTo(450, 150)
    //             ctx.lineTo(450, 300)
    //             ctx.lineTo(600, 300)
    //             ctx.lineTo(600, 150)
    //             ctx.closePath()
    //             ctx.stroke()
    //             ctx.restore()
    //             ctx.shadowBlur = 0

    //             //
    //             ctx.beginPath()
    //             ctx.fillStyle = 'white'
    //             ctx.moveTo(450, 150)
    //             ctx.lineTo(450, 300)
    //             ctx.lineTo(600, 300)
    //             ctx.lineTo(600, 150)
    //             ctx.closePath()
    //             ctx.fill()
    //             ctx.restore()

    //             // circle
    //             const radius = 7.5
    //             const angle = Math.PI / 180

    //             ctx.font = 'bolder 13px Arial'
    //             ctx.fillStyle = 'grey'
    //             ctx.textBaseline = 'middle'
    //             ctx.fillText(chart.data.labels[tooltip._active[0].index], 462.5, 172)
    //             tooltip._active.forEach((dataset: any, index: any) => {
    //                 const dataPoint = dataset.index
    //                 if (index === 0) {
    //                     const diff = chart.data.datasets[0].data[dataPoint] - chart.data.datasets[1].data[dataPoint]
    //                     ctx.font = 'border 15px Arial'
    //                     ctx.fillStyle = 'black'
    //                     ctx.textAlign = 'left'

    //                     let compareText: any = []
    //                     if (diff < 0) {
    //                         compareText = ['a', 'b']
    //                     }

    //                     ctx.fillText(compareText[0], 462.5, 195)
    //                     ctx.fillText(compareText[1], 462.5, 195)

    //                     ctx.beginPath()
    //                     ctx.fillStyle = 'black'
    //                     ctx.arc(radius + 462.5, 245 + (25 * index), radius, angle * 0, angle* 360, false)
    //                     ctx.fill()

    //                     ctx.font = 'border 15px Arial'
    //                     ctx.fillStyle = 'black'
    //                     ctx.fillText('???', 470 + 15, 462.5, 245 + (25 * index))
    //                 }
    //             })
    //         }
    //     }
    // }

    private imgUrl(photo: any) {
        return `${baseUrl}${photo}`
    }

}
