import i18n from '@/i18n'
export const categories = [
    {
        name: 'pH',
        header: i18n.t('headers.workspace.farm.agrochem_analys.acidity.title'),
        indicators: [
            {
                color: '#D51900',
                lower: null,
                upper: '2.0',
                value: i18n.t('headers.workspace.farm.agrochem_analys.acidity.indicators[0]')
            },
            {
                color: '#E45925',
                lower: '2.1',
                upper: '3.5',
                value: i18n.t('headers.workspace.farm.agrochem_analys.acidity.indicators[1]')
            },
            {
                color: '#F2994A',
                lower: '3.6',
                upper: '5.5',
                value: i18n.t('headers.workspace.farm.agrochem_analys.acidity.indicators[2]')
            },
            {
                color: '#FFC700',
                lower: '5.6',
                upper: '6.9',
                value: i18n.t('headers.workspace.farm.agrochem_analys.acidity.indicators[3]')
            },
            {
                color: '#4B970F',
                lower: '6.9',
                upper: '7.1',
                value: i18n.t('headers.workspace.farm.agrochem_analys.acidity.indicators[4]')
            },
            {
                color: '#3C9A75',
                lower: '7.1',
                upper: '8.0',
                value: i18n.t('headers.workspace.farm.agrochem_analys.acidity.indicators[5]')
            },
            {
                color: '#2D9CDB',
                lower: '8.1',
                upper: '9.5',
                value: i18n.t('headers.workspace.farm.agrochem_analys.acidity.indicators[6]')
            },
            {
                color: '#175DBC',
                lower: '9.6',
                upper: '10.5',
                value: i18n.t('headers.workspace.farm.agrochem_analys.acidity.indicators[7]')
            },
            {
                color: '#0000FF',
                lower: '10.6',
                upper: null,
                value: i18n.t('headers.workspace.farm.agrochem_analys.acidity.indicators[8]')
            }
        ]
    },
    {
        name: i18n.t('headers.workspace.farm.agrochem_analys.phosphorus.name'),
        header: i18n.t('headers.workspace.farm.agrochem_analys.phosphorus.title'),
        indicators: [
            {
                color: '#D51900',
                lower: null,
                upper: '10',
                value: i18n.t('headers.workspace.farm.agrochem_analys.phosphorus.indicators[0]')
            },
            {
                color: '#F2994A',
                lower: '10.01',
                upper: '15',
                value: i18n.t('headers.workspace.farm.agrochem_analys.phosphorus.indicators[1]')
            },
            {
                color: '#FFC700',
                lower: '15.01',
                upper: '30',
                value: i18n.t('headers.workspace.farm.agrochem_analys.phosphorus.indicators[2]')
            },
            {
                color: '#4B970F',
                lower: '30.01',
                upper: '45',
                value: i18n.t('headers.workspace.farm.agrochem_analys.phosphorus.indicators[3]')
            },
            {
                color: '#2D9CDB',
                lower: '45.01',
                upper: '60',
                value: i18n.t('headers.workspace.farm.agrochem_analys.phosphorus.indicators[4]')
            },
            {
                color: '#175DBC',
                lower: '60',
                upper: null,
                value: i18n.t('headers.workspace.farm.agrochem_analys.phosphorus.indicators[5]')
            }
        ]
    },
    {
        name: i18n.t('headers.workspace.farm.agrochem_analys.potassium.name'),
        header: i18n.t('headers.workspace.farm.agrochem_analys.potassium.title'),
        indicators: [
            {
                color: '#D51900',
                lower: null,
                upper: '100',
                value: i18n.t('headers.workspace.farm.agrochem_analys.potassium.indicators[0]')
            },
            {
                color: '#F2994A',
                lower: '100.01',
                upper: '200',
                value: i18n.t('headers.workspace.farm.agrochem_analys.potassium.indicators[1]')
            },
            {
                color: '#FFC700',
                lower: '200.01',
                upper: '300',
                value: i18n.t('headers.workspace.farm.agrochem_analys.potassium.indicators[2]')
            },
            {
                color: '#4B970F',
                lower: '300.01',
                upper: '400',
                value: i18n.t('headers.workspace.farm.agrochem_analys.potassium.indicators[3]')
            },
            {
                color: '#2D9CDB',
                lower: '400.01',
                upper: '600',
                value: i18n.t('headers.workspace.farm.agrochem_analys.potassium.indicators[4]')
            },
            {
                color: '#175DBC',
                lower: '600',
                upper: null,
                value: i18n.t('headers.workspace.farm.agrochem_analys.potassium.indicators[5]')
            }
        ]
    },
    {
        name: i18n.t('headers.workspace.farm.agrochem_analys.nitrogen.name'),
        header: i18n.t('headers.workspace.farm.agrochem_analys.nitrogen.title'),
        indicators: [
            {
                color: '#D51900',
                lower: null,
                upper: '30',
                value: i18n.t('headers.workspace.farm.agrochem_analys.nitrogen.indicators[0]')
            },
            {
                color: '#F2994A',
                lower: '30.01',
                upper: '40',
                value: i18n.t('headers.workspace.farm.agrochem_analys.nitrogen.indicators[1]')
            },
            {
                color: '#FFC700',
                lower: '40.01',
                upper: '50',
                value: i18n.t('headers.workspace.farm.agrochem_analys.nitrogen.indicators[2]')
            },
            {
                color: '#4B970F',
                lower: '50.01',
                upper: '70',
                value: i18n.t('headers.workspace.farm.agrochem_analys.nitrogen.indicators[3]')
            },
            {
                color: '#2D9CDB',
                lower: '70.01',
                upper: '100',
                value: i18n.t('headers.workspace.farm.agrochem_analys.nitrogen.indicators[4]')
            },
            {
                color: '#175DBC',
                lower: '100',
                upper: null,
                value: i18n.t('headers.workspace.farm.agrochem_analys.nitrogen.indicators[5]')
            }
        ]
    },
    {
        name: i18n.t('headers.workspace.farm.agrochem_analys.sulfur.name'),
        header: i18n.t('headers.workspace.farm.agrochem_analys.sulfur.title'),
        indicators: [
            {
                color: '#D51900',
                lower: null,
                upper: '6.0',
                value: i18n.t('headers.workspace.farm.agrochem_analys.sulfur.indicators[1]')
            },
            {
                color: '#F2994A',
                lower: '6.0',
                upper: '12.0',
                value: i18n.t('headers.workspace.farm.agrochem_analys.sulfur.indicators[2]')
            },
            {
                color: '#FFC700',
                lower: '12.0',
                upper: null,
                value: i18n.t('headers.workspace.farm.agrochem_analys.sulfur.indicators[2]')
            }
        ]
    },
    {
        name: i18n.t('headers.workspace.farm.agrochem_analys.humus.name'),
        header: i18n.t('headers.workspace.farm.agrochem_analys.humus.title'),
        indicators: [
            {
                color: '#D51900',
                lower: '0',
                upper: '2.0',
                value: i18n.t('headers.workspace.farm.agrochem_analys.humus.indicators[0]')
            },
            {
                color: '#F2994A',
                lower: '2.01',
                upper: '4.0',
                value: i18n.t('headers.workspace.farm.agrochem_analys.humus.indicators[1]')
            },
            {
                color: '#FFC700',
                lower: '4.01',
                upper: '6.0',
                value: i18n.t('headers.workspace.farm.agrochem_analys.humus.indicators[2]')
            },
            {
                color: '#4B970F',
                lower: '6.01',
                upper: '8.0',
                value: i18n.t('headers.workspace.farm.agrochem_analys.humus.indicators[3]')
            },
            {
                color: '#2D9CDB',
                lower: '8.01',
                upper: '10.0',
                value: i18n.t('headers.workspace.farm.agrochem_analys.humus.indicators[4]')
            },
            {
                color: '#175DBC',
                lower: '10.0',
                upper: null,
                value: i18n.t('headers.workspace.farm.agrochem_analys.humus.indicators[5]')
            }
        ]
    }
]