import { render, staticRenderFns } from "./SubfieldNotes.vue?vue&type=template&id=2a56be9b&scoped=true&"
import script from "./SubfieldNotes.vue?vue&type=script&lang=ts&"
export * from "./SubfieldNotes.vue?vue&type=script&lang=ts&"
import style0 from "./SubfieldNotes.vue?vue&type=style&index=0&id=2a56be9b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a56be9b",
  null
  
)

export default component.exports

/* vuetify-loader */
import installDirectives from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import ClickOutside from 'vuetify/lib/directives/click-outside'
installDirectives(component, {ClickOutside})
