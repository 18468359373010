




































































import { Component, Vue } from 'vue-property-decorator'
import { GetterNames } from '@/store/types'
import { Getter } from 'vuex-class'
import { imageEnvironment } from '@/domain/constants'
import { ndviGistogram } from '@/data/gistogramData'

@Component
export default class NotificationMessages extends Vue {
    @Getter(GetterNames.getMapData) private mapData !: any
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: any
    @Getter(GetterNames.getNotificationData) private getNotificationData!: any
    private ndviCategories = ndviGistogram
    private textScale = 1
    private boundaries: any = []

    get note() {
        if ( this.getNotificationData.message.perm_name === 'notice_mention' ||
            this.getNotificationData.message.perm_name === 'notice_reply') {
            return this.getNotificationData.message.act
        }
        return false
    }

    get checkIfNeeded() {
        if (this.currentRoute.params.messageId) {
            if (this.getNotificationData.message) {
                if (this.currentRoute.params.messageId === this.getNotificationData.message.id.toString()) {
                    if (this.getNotificationData.message.group_user) {
                        return true
                    }
                }
            }
        }
        return false
    }

    get responses() {
        if (this.getNotificationData.message.perm_name === 'response_new_image') {
            return this.getNotificationData.message.responses
        }
        return false
    }

    private getImageUrl(imageUrl: any) {
        return `${imageEnvironment}${imageUrl.substring(16)}`
    }
}
