export default {
    title: "Autopark",
    machines: "Machines",
    devices: "Devices",
    add_machine: {
        title: " machine",
        class: "Brand",
        model: "Model",
        state_num: "State number",
        IMEI: "IMEI",
        capacity: "Capacity (l)",
        capacity_short: "Capacity",
        consumption: "Fuel consumption (l/he)",
        photo: "Photo",
        category: {
            title: "Categories",
            categories: ["Tractors", "Combines", "Self-propelled mower", "Forage harvesters", "Self-propelled spreaders"]
        }
    },
    add_record: {
        title: " ",
        type_placeholder: "Select a type",
        type: "Maintenance type",
        repair_stage: "Renovation stage",
        repair_stage_placeholder: "Select the renovation stage",
        consumables: "Consumables and spare parts",
        status: "Status",
        status_placeholder: "Enter the status",
        date_range: "Duration",
        mileage: "Mileage",
        mileage_placeholder: "Enter a mileage",
        hours: "Machine hours",
        hours_placeholder: "Enter machine hours",
        sum: "Total sum",
        sum_placeholder: "Enter a total sum",
        note: "Note",
        errors: {
            type: "Select the maintenance type",
            repair_stage: "Select the renovation stage",
            sum: "Enter a total sum",
            date_range: "Enter the duration",
            no_records: "You have no up-to-date records"
        }
    },
    edit_machine: "Edit the machine",
    add_device: {
        title: " device",
        name: "Name",
        width: "Coverage (m)",
        photo: "Photo"
    },
    edit_device: "Edit the device"
}