import FarmBoard from '@/views/MainPage/HomeView/Sideboards/FarmBoard/Index.vue'
import FarmSubfields from '@/views/MainPage/HomeView/Sideboards/FarmBoard/Subfields.vue'
import SubfieldsRating from '@/views/MainPage/HomeView/Sideboards/FarmBoard/RatingBoard/SubfieldsRating.vue'
import EditFarm from '@/views/MainPage/HomeView/Sideboards/FarmBoard/EditFarm.vue'
import AgroChem from '@/views/MainPage/HomeView/Sideboards/FarmBoard/AgroChem/Index.vue'
import orderAgroChem from '@/views/MainPage/HomeView/Sideboards/FarmBoard/AgroChem/orderAgroChem.vue'
import uploadAgroChem from '@/views/MainPage/HomeView/Sideboards/FarmBoard/AgroChem/uploadAgroChem.vue'
import FarmTelematics from '@/views/MainPage/HomeView/Sideboards/FarmBoard/TelematicsBoard/FarmTelematics/FarmTelematics.vue'
import CropRotation from '@/views/MainPage/HomeView/Sideboards/FarmBoard/CropRotationBoard/Index.vue'
import TechMap from '@/views/MainPage/HomeView/Sideboards/FarmBoard/TechMapBoard/Index.vue'
import MeteoStations from '@/views/MainPage/HomeView/Sideboards/FarmBoard/MeteoStationsBoard/Index.vue'
import CloneCropData from '@/views/MainPage/HomeView/Sideboards/FarmBoard/CropRotationBoard/CloneCropData.vue'
import AllNotes from '@/views/MainPage/HomeView/Sideboards/FarmBoard/NotesBoard/AllNotes.vue'
import FieldCadastreInfo from '@/views/MainPage/HomeView/Sideboards/FarmBoard/CadastreInfo/FieldCadastreInfo.vue'
import CultureTask from '@/views/MainPage/HomeView/Sideboards/FarmBoard/TechMapBoard/CultureTask.vue'
import PlanningDashbroad from '@/views/MainPage/HomeView/Sideboards/FarmBoard/TechMapBoard/components/PlanningDashbroad.vue'
import PlanningAdd from '@/views/MainPage/HomeView/Sideboards/FarmBoard/TechMapBoard/components/PlanningAdd.vue'
import FieldDashboard from '@/views/MainPage/HomeView/Sideboards/FarmBoard/FieldDashboard/FieldDashboard.vue'
import FieldDashboardTechnic from '@/views/MainPage/HomeView/Sideboards/FarmBoard/FieldDashboard/FieldDashboardTechnic.vue'

const routes = [
    {
        path: 'cadastres/farm/:farmId/',
        name: 'farm',
        component: FarmBoard,
        children: [
            {
                path: 'subfields',
                name: 'subfields',
                component: FarmSubfields
            },
            {
                path: 'subfieldsrating',
                name: 'subfieldsrating',
                component: SubfieldsRating
            },
            {
                path: 'croprotation',
                name: 'croprotation',
                component: CropRotation
            },
            {
                path: 'allnotes',
                name: 'allnotes',
                component: AllNotes
            },
            {
                path: 'clonecropdata/:culture',
                name: 'clonecropdata',
                component: CloneCropData
            },
            {
                path: 'techmap',
                name: 'techmap',
                component: TechMap
            },
            {
                path: 'meteostations',
                name: 'meteostations',
                component: MeteoStations
            },
            {
                path: 'dashboard',
                name: 'dashboard',
                component: FieldDashboard
            },
            {
                path: 'dashboard/technic/:id/:culture',
                name: 'dashboard-technic',
                component: FieldDashboardTechnic
            }
        ]
    },
    {
        path: 'editfarm/:farmId',
        name: 'editfarm',
        component: EditFarm
    },
    {
        path: 'argochem/:farmId',
        name: 'agrochem',
        component: AgroChem
    },
    {
        path: 'orderagrochem/:farmId',
        name: 'orderagrochem',
        component: orderAgroChem
    },
    {
        path: 'cadastres/farm/:farmId/telematics',
        name: 'farmtelematics',
        component: FarmTelematics
    },
    {
        path: 'uploadagrochemresult/:farmId',
        name: 'uploadagrochemresult',
        component: uploadAgroChem
    },
    {
        path: 'cadastres/farm/:farmId/culturetask/:taskId',
        name: 'culturetask',
        component: CultureTask
    },
    {
        path: 'cadastres/farm/:farmId/fieldcadastre/:fieldId',
        name: 'fieldcadastre',
        component: FieldCadastreInfo
    },
    {
        path: 'cadastres/farm/:farmId/planning/:id',
        name: 'planning-technology',
        component: PlanningDashbroad
    },
    {
        path: 'cadastres/farm/:farmId/planning',
        name: 'planning',
        component: PlanningAdd
    }
]

export default routes