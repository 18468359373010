export default {
    title: "Қойма операцияларының журналы",
    general: "Операциялар журналы",
    list: "Қоймалар тізімі",
    list_by_farms: "Шаруашылықтар бойынша тізім",
    logs: {
        period: "Кезең",
        income: "Кіріс",
        expense: "Шығыс",
        movement: "Ауысу",
        storage: "Қойма",
        farm: "Шаруашылығы",
        return: "Қайтару",
        writeoff: "Шығынға жазу",
        operation: "Әрекет",
        edit: "Редакциялау",
        equipment: "Техника",
        history: "Тарих",
        name: "Атауы",
        fields: {
            type: "Тип",
            desc: "Сипаттау",
            date: "Күн",
            title: "Атауы",
            title_add: "Атауын қосу",
            title_change: "Атауынды өзгерту",
            income_type: "Кірістің түрі",
            expense_type: "Шығынның түрі",
            operation_type: "Операция түрі",
            amount: "Сан",
            scaling: "Өлшеу бірлігі",
            scaling_full: "Өлшеу бірлігі",
            cost: "Құн",
            cost_for_one: "Бірлік құны",
            sum: "Сомасы",
            sender: "жіберуші",
            receiver: "алушы",
            supplier: "Жеткізуші",
            new: "өзгеріс"
        },
        income_type: {
            supplier: "Жеткізуші",
            employee: "Қызметкер",
            return: "Сатып алушыны қайтару",
            event: "Іс-шарадан қалған қалдықтар"
        },
        expense_type: {
            employee: "Қызметкер",
            customer: "Сатып алушы",
            refill: "Құю",
            maintenance: "Техникаға қызмет көрсету"
        },
        total: "Жиыны"
    }
}