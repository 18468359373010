

























































































































































import { Component, Vue } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import { GetterNames, MutationNames } from '@/store/types'
import { httpPost } from '@/util/http'
import { apiUrl3 } from '@/domain/constants'
import DateRangePicker from '@/components/DateRangePicker.vue'
import Chart from 'chart.js'
@Component({
    components: {
        DateRangePicker
    }
})
export default class MeteoStationsBoard extends Vue {
    @Getter(GetterNames.getSidebarModel) private getSidebarModel!: any
    @Getter(GetterNames.getLoadingDataNames) private getLoadingDataNames!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    private stations: any = {
        origin: [],
        converted: []
    }
    private station: any = {
        selected: null,
        selectedName: ''
    }
    private filter: any = {
        dataType: 1,
        iteration: {
            origin: [
                {
                    id: 1,
                    name: 'Ежечастно',
                    key: 'hourly'
                },
                {
                    id: 2,
                    name: 'Ежедневно',
                    key: 'daily'
                }
            ],
            converted: ['Ежечастно', 'Ежедневно'],
            selected: {
                id: 1,
                name: 'Ежечастно',
                key: 'hourly'
            },
            selectedName: 'Ежечастно'
        },
        range: {
            origin: [
                {
                    key: 1,
                    name: '24 часов'
                },
                {
                    key: 2,
                    name: '2 дня'
                },
                {
                    key: 7,
                    name: '7 дней'
                },
                {
                    key: 10,
                    name: '10 дней'
                },
                {
                    key: 14,
                    name: '14 дней'
                },
                {
                    key: 30,
                    name: '30 дней'
                },
                {
                    key: 90,
                    name: '90 дней'
                }
            ],
            converted: ['24 часов', '2 дня', '7 дней', '10 дней', '14 дней'],
            selected: {
                key: 1,
                name: '24 часов'
            },
            selectedName: '24 часов'
        },
        startDate: '',
        endDate: '',
        period: {
            origin: [
                {
                    key: 3,
                    name: '3 дня'
                },
                {
                    key: 7,
                    name: '7 дней'
                }
            ],
            converted: ['3 дня', '7 дней'],
            selected: {
                key: 3,
                name: '3 дня'
            },
            selectedName: '3 дня'
        }
    }
    private colorset = [
        {
            line: '#4B970F',
            background: 'rgba(75, 151, 15, 0.1)'
        },
        {
            line: '#008CDB',
            background: 'rgba(0, 140, 219, 0.1)'
        },
        {
            line: '#FFC700',
            background: 'rgba(255, 199, 0, 0.1)'
        },
        {
            line: '#D51900',
            background: 'rgba(213, 25, 0, 0.1)'
        }
    ]
    private data: any = [1]

    private changeDataType(type: number) {
        if (this.filter.dataType !== type) {
            this.filter.dataType = type
            if (type === 1) {
                this.getSensorData()
            } else if (type === 2) {
                this.getForecastData()
            }
        }
    }

    private onSelectIteration(item: any) {
        const iteration = this.filter.iteration.origin.find((x: any) => x.name === item)
        if (iteration && this.filter.iteration.selectedName !== iteration.name) {
            this.filter.iteration.selected = iteration
            this.filter.iteration.selectedName = item
            if (iteration.id === 1) {
                this.filter.range.converted = ['24 часов', '2 дня', '7 дней', '10 дней', '14 дней']
                this.filter.range.selected = {
                    key: 1,
                    name: '24 часов'
                }
                this.filter.range.selectedName = '24 часов'
            } else if (iteration.id === 2) {
                this.filter.range.converted = ['7 дней', '10 дней', '14 дней', '30 дней', '90 дней']
                this.filter.range.selected = {
                    key: 7,
                    name: '7 дней'
                }
                this.filter.range.selectedName = '7 дней'
            }
            this.getSensorData()
        }
    }

    private onSelectRange(item: any) {
        const range = this.filter.range.origin.find((x: any) => x.name === item)
        if (range) {
            this.filter.range.selected = range
            this.filter.range.selectedName = item
            this.filter.startDate = ''
            this.filter.endDate = ''
            this.getSensorData()
        }
    }

    private onSelectPeriod(item: any) {
        const period = this.filter.period.origin.find((x: any) => x.name === item)
        if (period) {
            this.filter.period.selected = period
            this.filter.period.selectedName = period.name
            this.getForecastData()
        }
    }

    private onSelectStation(item: any) {
        const station = this.stations.origin.find((x: any) => `${x.name_custom} (${x.name_original})` === item)
        if (station) {
            this.station.selected = station
            this.station.selectedName = item
            if (this.filter.dataType === 1) {
                this.getSensorData()
            } else if (this.filter.dataType === 2) {
                this.getForecastData()
            }
        }
    }

    private showDateRangePicker() {
        const datePicker: any = this.$refs.daterangepicker as any
        if (datePicker) {
            datePicker.show()
        }
    }

    private updateSelectedDate(startDate: string, endDate: string) {
        if (startDate && endDate) {
            this.filter.startDate = startDate
            this.filter.endDate = endDate
            this.filter.range.selected = null
            this.filter.range.selectedName = ''
            this.getSensorData()
        }
    }

    private mounted() {
        this.setLoadingDataNames('MeteoStationsBoardGetStations')
        httpPost({
            url: `${apiUrl3}/meteos/get-stations/`,
            isSecureRequest: true,
            detailResponse: true,
            body: {
                farm: Number(this.$route.params.farmId)
            },
            onSuccess: json => {
                if (json.ok) {
                    this.stations.origin = json.json
                    this.stations.converted = json.json.map((x: any) => `${x.name_custom} (${x.name_original})`)
                    if (json.json.length > 0) {
                        this.station.selected = this.stations.origin[0]
                        this.station.selectedName = this.stations.converted[0]
                        this.getSensorData()
                    }
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('MeteoStationsBoardGetStations')
            }
        })
    }

    private getSensorData() {
        if (this.station.selected) {
            this.data = []
            let startDate = this.filter.startDate
            let endDate = this.filter.endDate
            if (this.filter.range.selected) {
                startDate = new Date()
                endDate = new Date()
                startDate.setDate(startDate.getDate() - this.filter.range.selected.key)
                startDate = `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()} ${startDate.getHours()}:${startDate.getMinutes()}:00`
                endDate = `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()} ${endDate.getHours()}:${endDate.getMinutes()}:00`
            }
            this.setLoadingDataNames('MeteoStationsGetSensorData')
            httpPost({
                url: `${apiUrl3}/meteos/get-sensor-data/`,
                isSecureRequest: true,
                detailResponse: true,
                body: {
                    station: this.station.selected.name_original,
                    date_from: startDate,
                    date_until: endDate,
                    time_iteration: this.filter.iteration.selected.key
                },
                onSuccess: json => {
                    if (json.ok) {
                        this.parseJson(json)
                    }
                },
                onError: error => {
                    console.log(error)
                },
                doFinally: () => {
                    this.setLoadingDataNames('MeteoStationsGetSensorData')
                }
            })
        }
    }

    private getForecastData() {
        if (this.station.selected) {
            this.data = []
            let startDate: any = new Date()
            let endDate: any = new Date()
            endDate.setDate(endDate.getDate() + this.filter.period.selected.key)
            startDate = `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()} ${startDate.getHours()}:${startDate.getMinutes()}:00`
            endDate = `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()} ${endDate.getHours()}:${endDate.getMinutes()}:00`
            this.setLoadingDataNames('MeteoStationsGetForecastData')
            httpPost({
                url: `${apiUrl3}/meteos/get-forecast-data/`,
                isSecureRequest: true,
                detailResponse: true,
                body: {
                    station: this.station.selected.name_original,
                    date_from: startDate,
                    date_until: endDate,
                    time_iteration: 'hourly'
                },
                onSuccess: json => {
                    if (json.ok) {
                        this.parseJson(json)
                    }
                },
                onError: error => {
                    console.log(error)
                },
                doFinally: () => {
                    this.setLoadingDataNames('MeteoStationsGetForecastData')
                }
            })
        }
    }

    private parseJson(json: any) {
        this.data = Object.entries(json.json).map(([k, v]) => (v)).map((x: any, i: number) => {
            let data = []
            if (x[0].data.hasOwnProperty('avg')) {
                data = x[0].data.avg
            } else if (x[0].data.hasOwnProperty('min')) {
                data = x[0].data.min
            } else if (x[0].data.hasOwnProperty('max')) {
                data = x[0].data.max
            }
            const tableData = []
            for (const date of data) {
                const row = []
                row.push(date.t.substring(0, 16))
                for (const item of x) {
                    if (item.data.hasOwnProperty('min')) {
                        const min = item.data.min.find((y: any) => y.t === date.t)
                        row.push(min ? min.y : '')
                    }
                    if (item.data.hasOwnProperty('avg')) {
                        const avg = item.data.avg.find((y: any) => y.t === date.t)
                        row.push(avg ? avg.y : '')
                    }
                    if (item.data.hasOwnProperty('max')) {
                        const max = item.data.max.find((y: any) => y.t === date.t)
                        row.push(max ? max.y : '')
                    }
                }
                tableData.push(row)
            }
            return {
                view: 1,
                chart: null,
                chart_data: x.map((y: any, j: number) => {
                    const chartData = []
                    if (y.data.hasOwnProperty('min')) {
                        chartData.push({
                            label: `${y.title} минимум|${y.unit}|минимум`,
                            data: y.data.min,
                            borderWidth: 0,
                            pointHitRadius: 5,
                            pointRadius: 0,
                            pointHoverBackgroundColor: y.color,
                            fill: false,
                            line: false,
                            yAxisID: `chart-${i}-axis-${j}`
                        })
                    }
                    if (y.data.hasOwnProperty('avg')) {
                        chartData.push({
                            yAxisID: `chart-${i}-axis-${j}`,
                            label: `${y.title} ср.знач|${y.unit}|ср.знач`,
                            data: y.data.avg,
                            backgroundColor: `${y.color}1A`,
                            borderColor: y.color,
                            fill: y.data.hasOwnProperty('min') ? '-1' : false
                        })
                    }
                    if (y.data.hasOwnProperty('max')) {
                        chartData.push({
                            yAxisID: `chart-${i}-axis-${j}`,
                            label: `${y.title} максимум|${y.unit}|максимум`,
                            data: y.data.max,
                            backgroundColor: `${y.color}1A`,
                            pointHitRadius: 5,
                            pointRadius: 0,
                            pointHoverBackgroundColor: y.color,
                            fill: '-1',
                            line: false
                        })
                    }
                    return {
                        id: `chart-${i}-axis-${j}`,
                        position: j === 0 ? 'left' : 'right',
                        show: true,
                        title: y.title,
                        unit: y.unit,
                        color: y.color,
                        data: chartData
                    }
                }),
                table_data: tableData
            }
        })
        setTimeout(() => this.createCharts(), 500)
    }

    private createCharts() {
        Chart.defaults.line.showLines = true
        Chart.defaults.line.spanGaps = true
        for (const [index, chart] of this.data.entries()) {
            const ctx: any = document.getElementById(`meteodata-chart-${index}`)
            ctx.getContext('2d').clearRect(0, 0, ctx.width, ctx.height)
            this.data[index].chart = new Chart(ctx.getContext('2d'), {
                type: 'line',
                data: {
                    datasets: chart.chart_data.reduce((r: any, v: any) => {
                        r.push(...v.data)
                        return r
                    }, [])
                },
                options: {
                    points: true,
                    legend: false,
                    hover: {
                        mode: 'index',
                        intersect: false
                    },
                    scales: {
                        yAxes: chart.chart_data.map((x: any) => {
                            return {
                                scaleLabel: {
                                    display: true,
                                    labelString: x.title
                                },
                                id: x.id,
                                position: x.position,
                                stacked: false,
                                ticks: {
                                    beginAtZero: true,
                                    callback(value: any) {
                                        return `${value}${x.unit}`
                                    }
                                }
                            }
                        }),
                        xAxes: [
                            {
                                type: 'time',
                                time: {
                                    unit: 'minute',
                                    tooltipFormat: 'DD.MM.YY HH:mm',
                                    displayFormats: {
                                        'minute': 'DD.MM.YY HH:mm'
                                    }
                                },
                                ticks: {
                                    fontColor: '#000000',
                                    maxTicksLimit: 4,
                                    beginAtZero: true,
                                    maxRotation: 0,
                                    minRotation: 0,
                                    callback(label: any) {
                                        const EnRuArr = [{en: 'Jan', ru: 'Янв'}, {en: 'Feb', ru: 'Фев'},
                                                    {en: 'Mar', ru: 'Мар'}, {en: 'Apr', ru: 'Апр'},
                                                    {en: 'May', ru: 'Май'}, {en: 'Jun', ru: 'Июн'},
                                                    {en: 'Jul', ru: 'Июл'}, {en: 'Aug', ru: 'Авг'},
                                                    {en: 'Sep', ru: 'Сен'}, {en: 'Oct', ru: 'Окт'},
                                                    {en: 'Nov', ru: 'Ноя'}, {en: 'Dec', ru: 'Дек'}]
                                        if (typeof(label) === 'string') {
                                            const arr1 = label.split(' ')
                                            const arr2 = arr1[0].split('.')
                                            const month = EnRuArr[Number(arr2[1]) - 1]
                                            if (month) {
                                                return `${arr2[0]} ${month.ru} ${arr1[1]}`
                                            }
                                        }
                                        return label
                                    }
                                }
                            }
                        ]
                    },
                    tooltips: {
                        mode: 'index',
                        callbacks: {
                            title: (title: any) => {
                                return title[0].label
                            },
                            label(tooltipItem: any, chartItem: any) {
                                const label = chartItem.datasets[tooltipItem.datasetIndex].label.split('|')
                                return `${label[0]}: ${tooltipItem.value}${label[1]}`
                            }
                        }
                    }
                }
            })
        }
    }

    private updateChart(chartIndex: number, dataIndex: number) {
        this.data[chartIndex].chart_data[dataIndex].show = !this.data[chartIndex].chart_data[dataIndex].show
        this.data[chartIndex].chart.data.datasets = this.data[chartIndex].chart_data
            .reduce((r: any, v: any, i: any) => {
                if (v.show) {
                    r.push(...v.data)
                }
                return r
            }, [])
        this.data[chartIndex].chart.update()
    }

    private close() {
        this.$router.push(`/cadastres/farm/${this.$route.params.farmId}`).catch(e => { /* */ })
    }
}

