import { Module, GetterTree, ActionTree, MutationTree } from 'vuex'
import { GetterNames, ActionNames, MutationNames } from '../../types'
import { saveAuthHeaderToCookie, clearCookies } from '@/domain/constants'
import { isLoggedIn } from '@/domain/constants'

const states = {
    isSignedIn: isLoggedIn()
}

export type IState = typeof states

const mutations: MutationTree<IState> = {
    [MutationNames.SET_SIGNED_IN](state, value: boolean) {
        state.isSignedIn = value
    }
}

const actions: ActionTree<IState, any> = {
    [ActionNames.ON_SIGN]({ commit }, { token, expiresIn }) {
        saveAuthHeaderToCookie(token, expiresIn)
        commit(MutationNames.SET_SIGNED_IN, true)
    },

    [ActionNames.LOG_OUT]({ commit }) {
        commit(MutationNames.SET_SIGNED_IN, false)
        commit('setFarms', [])
        clearCookies()
    }
}

const getters: GetterTree<IState, any> = {
    [GetterNames.IS_SIGNED_IN](state): boolean {
        return state.isSignedIn
    }
}

const auth: Module<IState, any> = {
    state: states,
    actions,
    mutations,
    getters
}

export default auth
