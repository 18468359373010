import { getAuthToken } from '@/domain/constants'
import { errorHandler } from './errorHandler'

interface HttpGetArgs {
    url: string,
    isSecureRequest?: boolean,
    detailResponse?: boolean,
    onSuccess: (json: any) => void,
    onError?: (error: any) => void,
    doFinally?: () => void
}

interface HttpPostArgs {
    url: string,
    isSecureRequest?: boolean,
    detailResponse?: boolean,
    body: any,
    onSuccess: (json: any) => void,
    onError?: (error: any) => void,
    doFinally?: () => void
}

interface HttpDeleteArgs { url: string, isSecureRequest?: boolean, detailResponse?: boolean, body?: any, onSuccess: (json?: any) => void, onError?: (error: any) => void, doFinally?: () => void }

export const httpGet = ({ url, onSuccess, onError, doFinally, isSecureRequest = false, detailResponse = false }: HttpGetArgs) => {
    const headers: any = { 'Accept': 'application/json', 'Content-Type': 'application/json' }
    // tslint:disable-next-line:no-string-literal
    if (isSecureRequest) headers['Authorization'] = getAuthToken()
    fetch(url, { method: 'GET', headers })
        .then(response => {
            errorHandler(response)
            if (detailResponse) {
                return response.json().then(result => {
                    return {
                        json: result,
                        ok: response.ok,
                        status: response.status
                    }
                })
            } else {
                return response.json()
            }
        })
        .then(json => {
            onSuccess(json)
        })
        .catch(e => {
            if (onError) onError(e)
        })
        .finally(() => {
            if (doFinally) doFinally()
        })
}

export const httpPost = ({
    url,
    body,
    onSuccess,
    onError,
    doFinally,
    isSecureRequest = false,
    detailResponse = false
}: HttpPostArgs) => {
    const headers: any = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    // tslint:disable-next-line:no-string-literal
    if (isSecureRequest) headers['Authorization'] = getAuthToken()
    const stringifiedJson = JSON.stringify(body)
    fetch(url, {
        method: 'post',
        headers,
        body: stringifiedJson
    })
        .then(response => {
            errorHandler(response)
            if (detailResponse) {
                return response.json().then(result => {
                    return {
                        json: result,
                        ok: response.ok,
                        status: response.status
                    }
                })
            } else {
                return response.json()
            }
        }).then(json => {
            onSuccess(json)
        }).catch(e => {
            if (onError) onError(e)
        }).finally(() => {
            if (doFinally) doFinally()
        })
}

export const httpPostFile = ({
    url,
    body,
    onSuccess,
    onError,
    doFinally,
    isSecureRequest = false,
    detailResponse = false
}: HttpPostArgs) => {
    const headers: any = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    // tslint:disable-next-line:no-string-literal
    if (isSecureRequest) headers['Authorization'] = getAuthToken()
    const stringifiedJson = JSON.stringify(body)
    fetch(url, {
        method: 'post',
        headers,
        body: stringifiedJson
    })
        .then(response => response.blob())
        .then(blob => {
            errorHandler(blob)
            if (detailResponse) {
                return blob
            } else {
                return 'error'
            }
        }).then(json => {
            onSuccess(json)
        }).catch(e => {
            if (onError) onError(e)
        }).finally(() => {
            if (doFinally) doFinally()
        })
}

export const httpPostZip = ({
    url,
    body,
    onSuccess,
    onError,
    doFinally,
    isSecureRequest = false,
    detailResponse = false
}: HttpPostArgs) => {
    const headers: any = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    // tslint:disable-next-line:no-string-literal
    if (isSecureRequest) headers['Authorization'] = getAuthToken()
    const stringifiedJson = JSON.stringify(body)
    fetch(url, {
        method: 'post',
        headers,
        body: stringifiedJson
    }).then(response => {
        errorHandler(response)
        if (detailResponse) {
            return response.json().then(result => {
                return {
                    json: result,
                    ok: response.ok,
                    status: response.status
                }
            })
        } else {
            return response
        }
    }).then(response => {
        onSuccess(response)
    }).catch(e => {
        if (onError) onError(e)
    }).finally(() => {
        if (doFinally) doFinally()
    })
}

export const httpPostFormData = ({
    url,
    body,
    onSuccess,
    onError,
    doFinally,
    isSecureRequest = false,
    detailResponse = false
}: HttpPostArgs) => {
    const headers: any = {
    }
    // tslint:disable-next-line:no-string-literal
    if (isSecureRequest) headers['Authorization'] = getAuthToken()
    fetch(url, {
        method: 'post',
        headers,
        body
    }).then(response => {
        errorHandler(response)
        if (detailResponse) {
            return response.json().then(result => {
                return {
                    json: result,
                    ok: response.ok,
                    status: response.status
                }
            })
        } else {
            return response.json()
        }
    }).then(json => {
        onSuccess(json)
    }).catch(e => {
        if (onError) onError(e)
    }).finally(() => {
        if (doFinally) doFinally()
    })
}

export const httpPutFormData = ({
    url,
    body,
    onSuccess,
    onError,
    doFinally,
    isSecureRequest = false,
    detailResponse = false
}: HttpPostArgs) => {
    const headers: any = {
    }
    if (isSecureRequest) headers.Authorization = getAuthToken()
    fetch(url, {
        method: 'put',
        headers,
        body
    }).then(response => {
        errorHandler(response)
        if (detailResponse) {
            return response.json().then(result => {
                return {
                    json: result,
                    ok: response.ok,
                    status: response.status
                }
            })
        } else {
            return response.json()
        }
    }).then(json => {
        onSuccess(json)
    }).catch(e => {
        if (onError) onError(e)
    }).finally(() => {
        if (doFinally) doFinally()
    })
}

export const httpDelete = ({ url, onSuccess, onError, doFinally, isSecureRequest = false, detailResponse = false }: HttpDeleteArgs) => {
    const headers: any = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    // tslint:disable-next-line:no-string-literal
    if (isSecureRequest) headers['Authorization'] = getAuthToken()
    return fetch(url, { method: 'DELETE', headers }).then(response => {
        errorHandler(response)
        if (detailResponse) {
            return response.json().then(result => {
                return {
                    json: result,
                    ok: response.ok,
                    status: response.status
                }
            })
        } else {
            return null
        }
    }).then(json => {
        if (onSuccess) detailResponse ? onSuccess(json) : onSuccess()
    }).catch(e => {
        if (onError) onError(e)
    }).finally(() => {
        if (doFinally) doFinally()
    })
}

export const httpDeleteWithBody = ({
    url,
    body,
    onSuccess,
    onError,
    doFinally,
    isSecureRequest = false,
    detailResponse = false
}: HttpDeleteArgs) => {
    const headers: any = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    // tslint:disable-next-line:no-string-literal
    if (isSecureRequest) headers['Authorization'] = getAuthToken()
    const stringifiedJson = JSON.stringify(body)
    return fetch(url, {
        method: 'DELETE',
        headers,
        body: stringifiedJson
    }).then(response => {
        errorHandler(response)
        if (detailResponse) {
            return {
                ok: response.ok,
                status: response.status
            }
        } else {
            return null
        }
    }).then(json => {
        if (onSuccess) detailResponse ? onSuccess(json) : onSuccess()
    }).catch(e => {
        if (onError) onError(e)
    }).finally(() => {
        if (doFinally) doFinally()
    })
}

export const httpPut = ({
    url,
    body,
    onSuccess,
    onError,
    doFinally,
    isSecureRequest = false,
    detailResponse = false
}: HttpPostArgs) => {
    const headers: any = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    if (isSecureRequest) headers.Authorization = getAuthToken()
    const stringifiedJson = JSON.stringify(body)
    fetch(url, {
        method: 'put',
        headers,
        body: stringifiedJson
    }).then(response => {
        errorHandler(response)
        if (detailResponse) {
            return response.json().then(result => {
                return {
                    json: result,
                    ok: response.ok,
                    status: response.status
                }
            })
        } else {
            return response.json()
        }
    }).then(json => {
        onSuccess(json)
    }).catch(e => {
        if (onError) onError(e)
    }).finally(() => {
        if (doFinally) doFinally()
    })
}

export const httpPatch = ({
    url,
    body,
    onSuccess,
    onError,
    doFinally
}: HttpPostArgs) => {
    const headers: any = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    headers.Authorization = getAuthToken()

    const stringifiedJson = JSON.stringify(body)
    fetch(url, {
        method: 'PATCH',
        headers,
        body: stringifiedJson
    })
        .then(response => response.json())
        .then(json => {
            onSuccess(json)
        })
        .catch(e => {
            if (onError) onError(e)
        })
        .finally(() => {
            if (doFinally) doFinally()
        })
}