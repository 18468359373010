



























import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class HeaderWithPagination extends Vue {
    @Prop() private text!: string

    private goBack() {
        this.$emit('go-back')
    }

    private nextPage() {
        this.$emit('next-page')
    }

    private prevPage() {
        this.$emit('prev-page')
    }
}
