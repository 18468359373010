





































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import { GetterNames, ActionNames } from '@/store/types'
import { Route } from 'vue-router'
import { httpPost } from '@/util/http'
import { apiUrl } from '@/domain/constants'
import i18n from '@/i18n'
import {egisticLogo, pdfWaterMark} from '@/data/pdfImageData'
import DateRangePicker from '@/components/DateRangePicker.vue'
import {operationData} from '@/data/newOperationData'
import { makePDF, PdfActions } from '@/utils/services'
@Component({
    components: {
        DateRangePicker
    }
})
export default class StorageRemainsView extends Vue {
    @Action(ActionNames.fetchFarmsList) private fetchFarmsList!: any
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: Route
    @Getter(GetterNames.getSidebarModel) private getSidebarModel!: any
    @Getter(GetterNames.getFarms) private getFarms!: any
    @Getter(GetterNames.getPermissions) private getPermissions!: any
    private egisticLogo: any = egisticLogo
    private pdfWaterMark: any = pdfWaterMark
    private dataloading = false
    private startDate = ''
    private endDate = ''
    private newOperationData: any = operationData
    private storage: any = {
        group_users_name: '',
        title: ''
    }
    private storageLogs: any = {
        edit: [],
        income: [],
        outcome: [],
        return: [],
        transfer: [],
        write_off: []
    }
    private excelData: any = []
    private excelName: any = 'logs.xls'

    private normalizeDate(date: any) {
        if (date === '') return i18n.t('general.dates.format_date')
        return date.substring(8, 10) + '.' + date.substring(5, 7) + '.' + date.substring(2, 4)
    }

    private normalizeTypes(types: any) {
        let str = ''
        for (const type of types) {
            str += type + '; '
        }
        return str
    }

    private getDuration() {
        const datePicker: any = this.$refs.daterangepicker as any
        if (datePicker) {
            const startDate = datePicker.getStartDate()
            const endDate = datePicker.getEndDate()
            if (startDate && endDate && !startDate.includes('NaN-NaN-NaN') && !endDate.includes('NaN-NaN-NaN')) {
                this.startDate = startDate.split(' ')[0]
                this.endDate = endDate.split(' ')[0]
                const startDay = startDate.split(' ')[0].split('-')
                const startTime = startDate.split(' ')[1].split(':')
                const endDay = endDate.split(' ')[0].split('-')
                const endTime = endDate.split(' ')[1].split(':')
                return i18n.t('general.dates.starting_from') + startDay[2] + '.' + startDay[1] + '.' + startDay[0].substring(2) +
                    ' - ' + i18n.t('general.dates.up_to') + endDay[2] + '.' + endDay[1] + '.' + endDay[0].substring(2)
            } else {
                const startDay = this.startDate.split('-')
                const endDay = this.endDate.split('-')
                return i18n.t('general.dates.starting_from') + startDay[2] + '.' + startDay[1] + '.' + startDay[0].substring(2) +
                    ' - ' + i18n.t('general.dates.up_to') + endDay[2] + '.' + endDay[1] + '.' + endDay[0].substring(2)
            }
        }
        return i18n.t('general.dates.starting_from') as string + i18n.t('general.dates.format_date') + ' - ' + i18n.t('general.dates.up_to') + i18n.t('general.dates.format_date')
    }

    private getTotalAmount(type: any) {
        let amount = 0
        for (const operation of type) {
            for (const product of operation.product_infos) {
                amount += Number(product.amount) * Number(product.cost)
            }
        }
        return amount
    }

    private getIncomeType(income: any) {
        if (Number(income.type) === 1) return i18n.t('headers.workspace.storage.logs.income_type.supplier') + ' - ' + income.name
        if (Number(income.type) === 2) return i18n.t('headers.workspace.storage.logs.income_type.employee') + ' - ' + income.employee.first_name + ' ' + income.employee.last_name
        if (Number(income.type) === 3) return i18n.t('headers.workspace.storage.logs.income_type.return') + ' - ' + income.name
        if (Number(income.type) === 4) return i18n.t('headers.workspace.storage.logs.income_type.event') + ' - ' + income.event
    }

    private getOutcomeType(outcome: any) {
        if (Number(outcome.type) === 1) return i18n.t('headers.workspace.storage.logs.expense_type.employee') + ' - ' + outcome.employee.first_name + ' ' + outcome.employee.last_name
        if (Number(outcome.type) === 2) return i18n.t('headers.workspace.storage.logs.expense_type.customer') + ' - ' + outcome.name
        if (Number(outcome.type) === 3) return i18n.t('headers.workspace.storage.logs.expense_type.refill') + ' - ' + outcome.tractor.name
        if (Number(outcome.type) === 4) return i18n.t('headers.workspace.storage.logs.expense_type.maintenance') + ' - ' + outcome.tractor.name
    }

    private mounted() {
        if (!this.getFarms) {
            this.fetchFarmsList()
        }
        let fromDate: any = new Date()
        fromDate.setDate(fromDate.getDate() - 14)
        fromDate = fromDate.toJSON().slice(0, 10)
        const finishDate = new Date().toJSON().slice(0, 10)
        this.startDate = fromDate
        this.endDate = finishDate
        this.fetchStorageLogs()
    }

    private fetchStorageLogs() {
        this.dataloading = true
        httpPost({
            url: `${apiUrl}/storage/get/operations/by/storage/`,
            isSecureRequest: true,
            detailResponse: true,
            body: {
                storage: Number(this.currentRoute.params.storageId),
                start_date: this.startDate,
                end_date: this.endDate
            },
            onSuccess: json => {
                if (json.ok) {
                    this.storage = json.json.storage
                    this.storageLogs = {
                        edit: json.json.edit,
                        income: json.json.income,
                        outcome: json.json.outcome,
                        return: json.json.return,
                        transfer: json.json.transfer,
                        write_off: json.json.write_off
                    }
                }
            },
            doFinally: () => {
                this.dataloading = false
            }
        })
    }

    private getExcelData(type: any) {
        this.excelData = []
        if (type === 'income') {
            this.excelName = 'Income.xls'
            if (this.storageLogs.income.length === 0) {
                this.excelData.push({
                    [i18n.t('headers.workspace.storage.logs.fields.date') as string]: '',
                    [i18n.t('headers.workspace.storage.logs.fields.title') as string]: '',
                    [i18n.t('headers.workspace.storage.logs.fields.income_type') as string]: '',
                    [i18n.t('headers.workspace.storage.logs.fields.amount') as string]: '',
                    [i18n.t('headers.workspace.storage.logs.fields.scaling') as string]: '',
                    [i18n.t('headers.workspace.storage.logs.fields.cost') as string]: '',
                    [i18n.t('headers.workspace.storage.logs.fields.sum') as string]: ''
                })
            } else {
                this.storageLogs.income.forEach((income: any) => {
                    income.product_infos.forEach((product: any) => {
                        this.excelData.push({
                            [i18n.t('headers.workspace.storage.logs.fields.date') as string]: this.normalizeDate(income.date),
                            [i18n.t('headers.workspace.storage.logs.fields.title') as string]: product.title,
                            [i18n.t('headers.workspace.storage.logs.fields.income_type') as string]: this.getIncomeType(income),
                            [i18n.t('headers.workspace.storage.logs.fields.amount') as string]: product.amount,
                            [i18n.t('headers.workspace.storage.logs.fields.scaling') as string]: product.unit,
                            [i18n.t('headers.workspace.storage.logs.fields.cost') as string]: product.cost + ' ' + i18n.t('general.units.tg'),
                            [i18n.t('headers.workspace.storage.logs.fields.sum') as string]: (Number(product.amount) * Number(product.cost)) + ' ' + i18n.t('general.units.tg')
                        })
                    })
                })
            }
        }
        if (type === 'outcome') {
            this.excelName = 'Outcome.xls'
            if (this.storageLogs.outcome.length === 0) {
                this.excelData.push({
                    [i18n.t('headers.workspace.storage.logs.fields.date') as string]: '',
                    [i18n.t('headers.workspace.storage.logs.fields.title') as string]: '',
                    [i18n.t('headers.workspace.storage.logs.fields.expense_type') as string]: '',
                    [i18n.t('headers.workspace.storage.logs.fields.amount') as string]: '',
                    [i18n.t('headers.workspace.storage.logs.fields.scaling') as string]: '',
                    [i18n.t('headers.workspace.storage.logs.fields.cost') as string]: '',
                    [i18n.t('headers.workspace.storage.logs.fields.sum') as string]: ''
                })
            } else {
                this.storageLogs.outcome.forEach((outcome: any) => {
                    outcome.product_infos.forEach((product: any) => {
                        this.excelData.push({
                            [i18n.t('headers.workspace.storage.logs.fields.date') as string]: this.normalizeDate(outcome.date),
                            [i18n.t('headers.workspace.storage.logs.fields.title') as string]: product.title,
                            [i18n.t('headers.workspace.storage.logs.fields.income_type') as string]: this.getOutcomeType(outcome),
                            [i18n.t('headers.workspace.storage.logs.fields.amount') as string]: product.amount,
                            [i18n.t('headers.workspace.storage.logs.fields.scaling') as string]: product.unit,
                            [i18n.t('headers.workspace.storage.logs.fields.cost') as string]: product.cost + i18n.t('general.units.tg'),
                            [i18n.t('headers.workspace.storage.logs.fields.sum') as string]: (Number(product.amount) * Number(product.cost)) + ' ' + i18n.t('general.units.tg')
                        })
                    })
                })
            }
        }
        if (type === 'transfer') {
            this.excelName = 'Transfer.xls'
            if (this.storageLogs.transfer.length === 0) {
                this.excelData.push({
                    [i18n.t('headers.workspace.storage.logs.fields.date') as string]: '',
                    [i18n.t('headers.workspace.storage.logs.fields.title') as string]: '',
                    [i18n.t('headers.workspace.storage.logs.storage') as string + ' - ' + i18n.t('headers.workspace.storage.logs.fields.sender')]: '',
                    [i18n.t('headers.workspace.storage.logs.storage') as string + ' - ' + i18n.t('headers.workspace.storage.logs.fields.receiver')]: '',
                    [i18n.t('headers.workspace.storage.logs.fields.amount') as string]: '',
                    [i18n.t('headers.workspace.storage.logs.fields.scaling') as string]: '',
                    [i18n.t('headers.workspace.storage.logs.fields.cost') as string]: '',
                    [i18n.t('headers.workspace.storage.logs.fields.sum') as string]: ''
                })
            } else {
                this.storageLogs.transfer.forEach((transfer: any) => {
                    transfer.product_infos.forEach((product: any) => {
                        this.excelData.push({
                            [i18n.t('headers.workspace.storage.logs.fields.date') as string]: this.normalizeDate(transfer.date),
                            [i18n.t('headers.workspace.storage.logs.fields.title') as string]: product.title,
                            [i18n.t('headers.workspace.storage.logs.storage') as string + ' - ' + i18n.t('headers.workspace.storage.logs.fields.sender')]: transfer.storage.title,
                            [i18n.t('headers.workspace.storage.logs.storage') as string + ' - ' + i18n.t('headers.workspace.storage.logs.fields.receiver')]: transfer.destination.title,
                            [i18n.t('headers.workspace.storage.logs.fields.amount') as string]: product.amount,
                            [i18n.t('headers.workspace.storage.logs.fields.scaling') as string]: product.unit,
                            [i18n.t('headers.workspace.storage.logs.fields.cost') as string]: product.cost + i18n.t('general.units.tg'),
                            [i18n.t('headers.workspace.storage.logs.fields.sum') as string]: (Number(product.amount) * Number(product.cost)) + ' ' + i18n.t('general.units.tg')
                        })
                    })
                })
            }
        }
        if (type === 'return') {
            this.excelName = 'Return.xls'
            if (this.storageLogs.return.length === 0) {
                this.excelData.push({
                    [i18n.t('headers.workspace.storage.logs.fields.date') as string]: '',
                    [i18n.t('headers.workspace.storage.logs.fields.title') as string]: '',
                    [i18n.t('headers.workspace.storage.logs.fields.supplier') as string]: '',
                    [i18n.t('headers.workspace.storage.logs.fields.amount') as string]: '',
                    [i18n.t('headers.workspace.storage.logs.fields.scaling') as string]: '',
                    [i18n.t('headers.workspace.storage.logs.fields.cost') as string]: '',
                    [i18n.t('headers.workspace.storage.logs.fields.sum') as string]: ''
                })
            } else {
                this.storageLogs.return.forEach((returns: any) => {
                    returns.product_infos.forEach((product: any) => {
                        this.excelData.push({
                            [i18n.t('headers.workspace.storage.logs.fields.date') as string]: this.normalizeDate(returns.date),
                            [i18n.t('headers.workspace.storage.logs.fields.title') as string]: product.title,
                            [i18n.t('headers.workspace.storage.logs.fields.supplier') as string]: returns.supplier,
                            [i18n.t('headers.workspace.storage.logs.fields.amount') as string]: product.amount,
                            [i18n.t('headers.workspace.storage.logs.fields.scaling') as string]: product.unit,
                            [i18n.t('headers.workspace.storage.logs.fields.cost') as string]: product.cost + i18n.t('general.units.tg'),
                            [i18n.t('headers.workspace.storage.logs.fields.sum') as string]: (Number(product.amount) * Number(product.cost)) + ' ' + i18n.t('general.units.tg')
                        })
                    })
                })
            }
        }
        if (type === 'writeoff') {
            this.excelName = 'Write_off.xls'
            if (this.storageLogs.write_off.length === 0) {
                this.excelData.push({
                    [i18n.t('headers.workspace.storage.logs.fields.date') as string]: '',
                    [i18n.t('headers.workspace.storage.logs.fields.title') as string]: '',
                    [i18n.t('headers.workspace.storage.logs.fields.amount') as string]: '',
                    [i18n.t('headers.workspace.storage.logs.fields.scaling') as string]: '',
                    [i18n.t('headers.workspace.storage.logs.fields.cost') as string]: '',
                    [i18n.t('headers.workspace.storage.logs.fields.sum') as string]: ''
                })
            } else {
                this.storageLogs.write_off.forEach((writeoff: any) => {
                    writeoff.product_infos.forEach((product: any) => {
                        this.excelData.push({
                            [i18n.t('headers.workspace.storage.logs.fields.date') as string]: this.normalizeDate(writeoff.date),
                            [i18n.t('headers.workspace.storage.logs.fields.title') as string]: product.title,
                            [i18n.t('headers.workspace.storage.logs.fields.amount') as string]: product.amount,
                            [i18n.t('headers.workspace.storage.logs.fields.scaling') as string]: product.unit,
                            [i18n.t('headers.workspace.storage.logs.fields.cost') as string]: product.cost + i18n.t('general.units.tg'),
                            [i18n.t('headers.workspace.storage.logs.fields.sum') as string]: (Number(product.amount) * Number(product.cost)) + ' ' + i18n.t('general.units.tg')
                        })
                    })
                })
            }
        }
        if (type === 'edit') {
            this.excelName = 'Edited.xls'
            if (this.storageLogs.edit.length === 0) {
                this.excelData.push({
                    [i18n.t('headers.workspace.storage.logs.fields.date') as string]: '',
                    [i18n.t('headers.workspace.storage.logs.fields.title') as string]: '',
                    [i18n.t('headers.workspace.storage.logs.fields.title') as string +  '(' + i18n.t('headers.workspace.storage.logs.fields.new') + ')']: '',
                    [i18n.t('headers.workspace.storage.logs.fields.amount') as string]: '',
                    [i18n.t('headers.workspace.storage.logs.fields.amount') as string +  '(' + i18n.t('headers.workspace.storage.logs.fields.new') + ')']: '',
                    [i18n.t('headers.workspace.storage.logs.fields.scaling') as string]: '',
                    [i18n.t('headers.workspace.storage.logs.fields.scaling') as string +  '(' + i18n.t('headers.workspace.storage.logs.fields.new') + ')']: '',
                    [i18n.t('headers.workspace.storage.logs.fields.cost') as string]: '',
                    [i18n.t('headers.workspace.storage.logs.fields.cost') as string +  '(' + i18n.t('headers.workspace.storage.logs.fields.new') + ')']: '',
                    [i18n.t('headers.workspace.storage.logs.fields.sum') as string]: ''
                })
            } else {
                this.storageLogs.edit.forEach((product: any) => {
                    this.excelData.push({
                        [i18n.t('headers.workspace.storage.logs.fields.date') as string]: this.normalizeDate(product.created_at),
                        [i18n.t('headers.workspace.storage.logs.fields.title') as string]: product.old_title,
                        [i18n.t('headers.workspace.storage.logs.fields.title') as string +  '(' + i18n.t('headers.workspace.storage.logs.fields.new') + ')']: product.new_title,
                        [i18n.t('headers.workspace.storage.logs.fields.amount') as string]: product.old_amount,
                        [i18n.t('headers.workspace.storage.logs.fields.amount') as string +  '(' + i18n.t('headers.workspace.storage.logs.fields.new') + ')']: product.new_amount,
                        [i18n.t('headers.workspace.storage.logs.fields.scaling') as string]: product.old_unit,
                        [i18n.t('headers.workspace.storage.logs.fields.scaling') as string +  '(' + i18n.t('headers.workspace.storage.logs.fields.new') + ')']: product.new_unit,
                        [i18n.t('headers.workspace.storage.logs.fields.cost') as string]: product.old_cost + i18n.t('general.units.tg'),
                        [i18n.t('headers.workspace.storage.logs.fields.cost') as string +  '(' + i18n.t('headers.workspace.storage.logs.fields.new') + ')']: product.new_cost + i18n.t('general.units.tg'),
                        [i18n.t('headers.workspace.storage.logs.fields.sum') as string]: (Number(product.new_cost) * Number(product.new_amount)) + ' ' + i18n.t('general.units.tg')
                    })
                })
            }
        }
        setTimeout(() => this.downloadExcel(), 1000)
    }

    private downloadExcel() {
        const downloader: any = document.getElementById('downloadLogsExcel')
        if (downloader) {
            downloader.click()
        }
    }

    private printPdf(types: any) {
        const docDefinition = {
            pageSize: {
                width: 842,
                height: 595
            },
            pageMargins: [30, 30, 30, 30],
            background: [
                {
                    image: this.pdfWaterMark,
                    margin: [386, 0, 0, 0]
                }
            ],
            content: this.getPdfTableBody(types)
        }
        makePDF(PdfActions.PRINT, docDefinition)
    }

    private getPdfTableBody(types: any) {
        const date = new Date()
        const today = date.toLocaleString('ru', { hour12: false, day: 'numeric', month: 'numeric', year: 'numeric',  hour: "numeric", minute: "numeric"})
        const content: any = [
            {
                image: this.egisticLogo,
                margin: [0, 10, 0, 0],
                width: 91.14,
                height: 30,
                alignment: 'center'
            },
            {
                text: i18n.t('headers.workspace.storage.general'),
                fontSize: 16,
                bold: true,
                margin: [0, 24, 0, 0],
                alignment: 'center'
            },
            {
                text: today,
                fontSize: 16,
                bold: true,
                margin: [0, 8, 0, 0],
                alignment: 'center'
            },
            {
                text: this.storage.group_users_name + ' - ' + this.storage.title,
                fontSize: 16,
                bold: true,
                margin: [16, 16, 0, 0]
            },
            {
                margin: [16, 8, 0, 0],
                columns: [
                    {
                        width: 'auto',
                        text: i18n.t('headers.workspace.logs.fields.type') + ':',
                        fontSize: 12,
                        bold: true
                    },
                    {
                        width: 'auto',
                        text: this.normalizeTypes(this.storage.types_name),
                        fontSize: 12,
                        margin: [12, 0, 0, 0]
                    }
                ]
            },
            {
                margin: [16, 8, 0, 0],
                columns: [
                    {
                        width: 'auto',
                        text: i18n.t('headers.workspace.logs.fields.desc') + ':',
                        fontSize: 12,
                        bold: true
                    },
                    {
                        width: 'auto',
                        text: this.storage.description === '' ? i18n.t('messages.errors.no_description') : this.storage.description,
                        fontSize: 12,
                        margin: [12, 0, 0, 0]
                    }
                ]
            },
            {
                margin: [16, 8, 0, 0],
                columns: [
                    {
                        width: 'auto',
                        text: i18n.t('headers.workspace.logs.period') + ':',
                        fontSize: 12,
                        bold: true
                    },
                    {
                        width: 'auto',
                        text: this.getDuration(),
                        fontSize: 12,
                        margin: [12, 0, 0, 0]
                    }
                ]
            }
        ]
        for (const type of types) {
            let header = i18n.t('headers.workspace.logs.income')
            if (type === 'outcome') header = i18n.t('headers.workspace.logs.expense')
            if (type === 'transfer') header = i18n.t('headers.workspace.logs.movement')
            if (type === 'return') header = i18n.t('headers.workspace.logs.return')
            if (type === 'writeoff') header = i18n.t('headers.workspace.logs.writeoff')
            if (type === 'edit') header = i18n.t('headers.workspace.logs.edit')
            content.push({
                margin: [0, 24, 0, 0],
                text: header,
                bold: true,
                fontSize: 14
            })
            const table: any = []
            const row: any = []
            let tableHeaders: any = []
            if (type === 'income') { tableHeaders = [
                i18n.t('headers.workspace.storage.logs.fields.date'),
                i18n.t('headers.workspace.storage.logs.fields.title'),
                i18n.t('headers.workspace.storage.logs.fields.income_type'),
                i18n.t('headers.workspace.storage.logs.fields.amount'),
                i18n.t('headers.workspace.storage.logs.fields.scaling'),
                i18n.t('headers.workspace.storage.logs.fields.cost'),
                i18n.t('headers.workspace.storage.logs.fields.sum')
            ]
            }
            if (type === 'outcome') { tableHeaders = [
                i18n.t('headers.workspace.storage.logs.fields.date'),
                i18n.t('headers.workspace.storage.logs.fields.title'),
                i18n.t('headers.workspace.storage.logs.fields.expense_type'),
                i18n.t('headers.workspace.storage.logs.fields.amount'),
                i18n.t('headers.workspace.storage.logs.fields.scaling'),
                i18n.t('headers.workspace.storage.logs.fields.cost'),
                i18n.t('headers.workspace.storage.logs.fields.sum')
            ]
            }
            if (type === 'transfer') { tableHeaders = [
                i18n.t('headers.workspace.storage.logs.fields.date'),
                i18n.t('headers.workspace.storage.logs.fields.title'),
                i18n.t('headers.workspace.storage.logs.field.storage') + ' - ' + i18n.t('headers.workspace.storage.logs.fields.sender'),
                i18n.t('headers.workspace.storage.logs.fields.storage') + ' - ' + i18n.t('headers.workspace.storage.logs.fields.receiver'),
                i18n.t('headers.workspace.storage.logs.fields.amount'),
                i18n.t('headers.workspace.storage.logs.fields.scaling'),
                i18n.t('headers.workspace.storage.logs.fields.cost'),
                i18n.t('headers.workspace.storage.logs.fields.sum')
            ]
            }
            if (type === 'return') { tableHeaders = [
                i18n.t('headers.workspace.storage.logs.fields.date'),
                i18n.t('headers.workspace.storage.logs.fields.title'),
                i18n.t('headers.workspace.storage.logs.fields.supplier'),
                i18n.t('headers.workspace.storage.logs.fields.amount'),
                i18n.t('headers.workspace.storage.logs.fields.scaling'),
                i18n.t('headers.workspace.storage.logs.fields.cost'),
                i18n.t('headers.workspace.storage.logs.fields.sum')
            ]
            }
            if (type === 'writeoff') { tableHeaders = [
                i18n.t('headers.workspace.storage.logs.fields.date'),
                i18n.t('headers.workspace.storage.logs.fields.title'),
                i18n.t('headers.workspace.storage.logs.fields.amount'),
                i18n.t('headers.workspace.storage.logs.fields.scaling'),
                i18n.t('headers.workspace.storage.logs.fields.cost'),
                i18n.t('headers.workspace.storage.logs.fields.sum')
            ]
            }
            if (type === 'edit') { tableHeaders = [
                i18n.t('headers.workspace.storage.logs.fields.date'),
                i18n.t('headers.workspace.storage.logs.fields.title'),
                i18n.t('headers.workspace.storage.logs.fields.title') as string +  '(' + i18n.t('headers.workspace.storage.logs.fields.new') + ')',
                i18n.t('headers.workspace.storage.logs.fields.amount'),
                i18n.t('headers.workspace.storage.logs.fields.amount') as string + '(' + i18n.t('headers.workspace.storage.logs.fields.new') + ')',
                i18n.t('headers.workspace.storage.logs.fields.scaling'),
                i18n.t('headers.workspace.storage.logs.fields.scaling') as string + '(' + i18n.t('headers.workspace.storage.logs.fields.new') + ')',
                i18n.t('headers.workspace.storage.logs.fields.cost'),
                i18n.t('headers.workspace.storage.logs.fields.cost') as string + '(' + i18n.t('headers.workspace.storage.logs.fields.new') + ')'
            ]
            }
            tableHeaders.forEach((e: any) => {
                row.push({text: e, fontSize: 14})
            })
            table.push(row)
            if (type === 'income') {
                this.storageLogs.income.forEach((income: any) => {
                    income.product_infos.forEach((product: any) => {
                        table.push([
                            {text: this.normalizeDate(income.date), fortSize: 14},
                            {text: product.title, fortSize: 14},
                            {text: this.getIncomeType(income), fortSize: 14},
                            {text: product.amount, fortSize: 14},
                            {text: product.unit, fortSize: 14},
                            {text: product.cost + i18n.t('general.units.tg'), fortSize: 14},
                            {text: (Number(product.amount) * Number(product.cost)) + '' + i18n.t('general.units.tg') as string, fortSize: 14}
                        ])
                    })
                })
                table.push([
                    {text: ''},
                    {text: ''},
                    {text: ''},
                    {text: ''},
                    {text: ''},
                    {text: i18n.t('general.bill.total'), fontSize: 14},
                    {text: this.getTotalAmount(this.storageLogs.income) + '' + i18n.t('general.units.tg'), fontSize: 14}
                ])
                content.push(
                    {
                        margin: [0, 24, 0, 0],
                        table: {
                            widths: [55, 150, 160, 80, 60, 120, 100],
                            headerRows: 1,
                            body: table
                        }
                    }
                )
            }
            if (type === 'outcome') {
                this.storageLogs.outcome.forEach((outcome: any) => {
                    outcome.product_infos.forEach((product: any) => {
                        table.push([
                            {text: this.normalizeDate(outcome.date), fortSize: 14},
                            {text: product.title, fortSize: 14},
                            {text: this.getOutcomeType(outcome), fortSize: 14},
                            {text: product.amount, fortSize: 14},
                            {text: product.unit, fortSize: 14},
                            {text: product.cost + i18n.t('general.units.tg'), fortSize: 14},
                            {text: (Number(product.amount) * Number(product.cost)) + '' + i18n.t('general.units.tg'), fortSize: 14}
                        ])
                    })
                })
                table.push([
                    {text: ''},
                    {text: ''},
                    {text: ''},
                    {text: ''},
                    {text: ''},
                    {text:  i18n.t('general.bill.total'), fontSize: 14},
                    {text: this.getTotalAmount(this.storageLogs.outcome) + '' +  i18n.t('general.units.tg'), fontSize: 14}
                ])
                content.push(
                    {
                        margin: [0, 24, 0, 0],
                        table: {
                            widths: [55, 150, 160, 80, 60, 120, 100],
                            headerRows: 1,
                            body: table
                        }
                    }
                )
            }
            if (type === 'transfer') {
                this.storageLogs.transfer.forEach((transfer: any) => {
                    transfer.product_infos.forEach((product: any) => {
                        table.push([
                            {text: this.normalizeDate(transfer.date), fortSize: 14},
                            {text: product.title, fortSize: 14},
                            {text: transfer.storage.title, fortSize: 14},
                            {text: transfer.destination.title, fortSize: 14},
                            {text: product.amount, fortSize: 14},
                            {text: product.unit, fortSize: 14},
                            {text: product.cost +  i18n.t('general.units.tg'), fortSize: 14},
                            {text: (Number(product.amount) * Number(product.cost)) + '' +  i18n.t('general.units.tg'), fortSize: 14}
                        ])
                    })
                })
                table.push([
                    {text: ''},
                    {text: ''},
                    {text: ''},
                    {text: ''},
                    {text: ''},
                    {text: ''},
                    {text:  i18n.t('general.bill.total'), fontSize: 14},
                    {text: this.getTotalAmount(this.storageLogs.transfer) + '' + i18n.t('general.units.tg'), fontSize: 14}
                ])
                content.push(
                    {
                        margin: [0, 24, 0, 0],
                        table: {
                            widths: [55, 120, 95, 95, 80, 60, 120, 100],
                            headerRows: 1,
                            body: table
                        }
                    }
                )
            }
            if (type === 'return') {
                this.storageLogs.return.forEach((returns: any) => {
                    returns.product_infos.forEach((product: any) => {
                        table.push([
                            {text: this.normalizeDate(returns.date), fortSize: 14},
                            {text: product.title, fortSize: 14},
                            {text: returns.supplier, fortSize: 14},
                            {text: product.amount, fortSize: 14},
                            {text: product.unit, fortSize: 14},
                            {text: product.cost + i18n.t('general.units.tg'), fortSize: 14},
                            {text: (Number(product.amount) * Number(product.cost)) + '' + i18n.t('general.units.tg'), fortSize: 14}
                        ])
                    })
                })
                table.push([
                    {text: ''},
                    {text: ''},
                    {text: ''},
                    {text: ''},
                    {text: ''},
                    {text: i18n.t('general.bill.total'), fontSize: 14},
                    {text: this.getTotalAmount(this.storageLogs.return) + '' + i18n.t('general.units.tg'), fontSize: 14}
                ])
                content.push(
                    {
                        margin: [0, 24, 0, 0],
                        table: {
                            widths: [55, 150, 160, 80, 60, 120, 100],
                            headerRows: 1,
                            body: table
                        }
                    }
                )
            }
            if (type === 'writeoff') {
                this.storageLogs.write_off.forEach((writeoff: any) => {
                    writeoff.product_infos.forEach((product: any) => {
                        table.push([
                            {text: this.normalizeDate(writeoff.date), fortSize: 14},
                            {text: product.title, fortSize: 14},
                            {text: product.amount, fortSize: 14},
                            {text: product.unit, fortSize: 14},
                            {text: product.cost + i18n.t('general.units.tg'), fortSize: 14},
                            {text: (Number(product.amount) * Number(product.cost)) + '' + i18n.t('general.units.tg'), fortSize: 14}
                        ])
                    })
                })
                table.push([
                    {text: ''},
                    {text: ''},
                    {text: ''},
                    {text: ''},
                    {text: i18n.t('general.bill.total'), fontSize: 14},
                    {text: this.getTotalAmount(this.storageLogs.write_off) + '' + i18n.t('general.units.tg'), fontSize: 14}
                ])
                content.push(
                    {
                        margin: [0, 24, 0, 0],
                        table: {
                            widths: [55, 250, 90, 70, 130, 110],
                            headerRows: 1,
                            body: table
                        }
                    }
                )
            }
            if (type === 'edit') {
                this.storageLogs.edit.forEach((product: any) => {
                    table.push([
                        {text: this.normalizeDate(product.created_at), fortSize: 14},
                        {text: product.old_title, fortSize: 14},
                        {text: product.new_title, fortSize: 14},
                        {text: product.old_amount, fortSize: 14},
                        {text: product.new_amount, fortSize: 14},
                        {text: product.old_unit, fortSize: 14},
                        {text: product.new_unit, fortSize: 14},
                        {text: product.old_cost + i18n.t('general.units.tg'), fortSize: 14},
                        {text: product.new_cost + i18n.t('general.units.tg'), fortSize: 14}
                    ])
                })
                content.push(
                    {
                        margin: [0, 24, 0, 0],
                        table: {
                            widths: [55, 100, 100, 80, 80, 60, 60, 85, 85],
                            headerRows: 1,
                            body: table
                        }
                    }
                )
            }
        }
        return content
    }

    private close() {
        this.$router.push(
            {
                name: 'storageremains',
                params: {
                    storages: JSON.parse(this.$route.params.storages)
                }
            }
        )
    }
}
