export default {
    title: "Журнал операций склада",
    general: "Журнал операций",
    list: "Список складов",
    list_by_farms: "Список по хозяйствам",
    logs: {
        period: "Период",
        income: "Приход",
        expense: "Расход",
        movement: "Перемещение",
        storage: "Склад",
        farm: "Хозяйство",
        return: "Возврат",
        writeoff: "Списание",
        operation: "Операция",
        edit: "Редактирование",
        equipment: "Техника",
        history: "История",
        name: "Название",
        fields: {
            type: "Тип",
            desc: "Описание",
            date: "Дата",
            title: "Наименование",
            title_add: "Добавить наименование",
            title_change: "Изменить наименование",
            income_type: "Тип прихода",
            expense_type: "Тип расхода",
            operation_type: "Тип операции",
            amount: "Количество",
            scaling: "Ед. изм.",
            scaling_full: "Единица измерения",
            cost: "Стоимость",
            cost_for_one: "Cтоимость за ед.",
            sum: "Сумма",
            sender: "отправитель",
            receiver: "получатель",
            supplier: "Поставщик",
            new: "изм."
        },
        income_type: {
            supplier: "Поставщик",
            employee: "Сотрудник",
            return: "Возврат покупателя",
            event: "Остатки с мероприятия"
        },
        expense_type: {
            employee: "Сотрудник",
            customer: "Покупатель",
            refill: "Заправка",
            maintenance: "Обслуживание техники"
        },
        total: "Итого"
    }
}