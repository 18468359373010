import StorageView from '@/views/MainPage/HomeView/Sideboards/StorageBoard/StorageView.vue'
import StorageRemainsView from '@/views/MainPage/HomeView/Sideboards/StorageBoard/StorageRemains/StorageRemainsView.vue'
import StorageLogsView from '@/views/MainPage/HomeView/Sideboards/StorageBoard/StorageLogs/StorageLogsView.vue'

const routes = [
    {
        path: 'storage',
        name: 'storage',
        component: StorageView
    },
    {
        path: 'storage/remains/:storages',
        name: 'storageremains',
        component: StorageRemainsView
    },
    {
        path: 'storage/logs/:storageId/:storages',
        name: 'storagelogs',
        component: StorageLogsView
    }
]

export default routes