export default {
    weather: "Ауа-райын қарау",
    first_order: "Бірінші тапсырыс жасаңыз",
    culture: {
        search: "Сорт бойынша іздеу",
        title: "Дала дақыл",
        type: "Түр",
        variety: {
            title: "Сорт",
            name: "Сорттың атауы"
        }
    }
}