export const serviceTypes = [
    {
        name: "Мойка",
        types: [
            "Кузова",
            "Двигателя",
            "Другое"
        ]
    },
    {
        name: "Обслуживание",
        types: [
            "Замена масла в двигателе",
            "Замена воздушного фильтра",
            "Замена жидкостей в узлах",
            "Дефектовка",
            "ТО",
            "Другое"
        ]
    },
    {
        name: "Ремонт",
        types: [
            "Ремонт узлов",
            "Смазочно-заправочные работы",
            "Электротехнические работы",
            "Гидравлика",
            "Ходовая",
            "Топливная система",
            "Шиномонтажные работы",
            "Прицепы и другое оборудование",
            "Другое"
        ]
    }
]

export const repairStages = [
    "Диагностика",
    "Ожидание дилера/сервиса",
    "Ожидание запчастей",
    "Ремонтные работы",
    "Проверка"
]
export const recordStatuses = [
    "Сделано",
    "В процессе",
    "Запланировано",
    "Отменено"
]