























































































































































































import { Component, Vue, Emit, Prop } from 'vue-property-decorator'
import NewEventModel from '../../../FarmBoard/TechMapBoard/components/NewEventModal.vue'
import { httpGet, httpDelete } from '@/util/http'
import { apiUrl } from '@/domain/constants'
import i18n from '@/i18n'
import { Mutation, Getter } from 'vuex-class'
import { MutationNames, GetterNames } from '@/store/types'
@Component({
    components: {
        NewEventModel
    }
})
export default class EventviewModel extends Vue {
    @Prop({default: null}) private event!: any
    @Prop({default: null}) private techmapId!: any
    @Prop({default: false}) private template!: any
    @Prop({default: false}) private status!: any
    @Getter(GetterNames.getPermissions) private getPermissions!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    private moreStatus = false

    private getStatus(color: boolean) {
        if (this.event.is_finished) {
            return color ? '#4B970F' : 'Выполнено'
        } else if (this.event.end_date) {
            const today = new Date()
            const endDate = new Date(this.event.end_date)
            if (today > endDate) {
                return color ? '#D51900' : 'Просрочено'
            }
        }
        return color ? '#FFC700' : 'В процессе'
    }

    private normalizeDate(date: any) {
        if (date) {
            return date.substring(8, 10) + '.' + date.substring(5, 7) + '.' + date.substring(0, 4)
        } else {
            return i18n.t('general.dates.format_date')
        }
    }

    private updateEvent() {
        const eventModel = this.$refs.newEventModel as any
        if (eventModel) {
            eventModel.update(this.event, this.status, this.techmapId, this.template)
        }
    }

    private deleteEvent() {
        this.$confirm('Вы действительно хотите удалить мероприятие?', 'Подтверждение', 'warning').then(() => {
            let deleteUrl = `${apiUrl}/techmap/task/${this.event.id}/`
            if (this.status) {
                deleteUrl = `${apiUrl}/techmap/task/field/${this.event.id}/`
            }
            this.setLoadingDataNames('eventViewModelDeleteEvent')
            httpDelete({
                url: deleteUrl,
                isSecureRequest: true,
                onSuccess: () => {
                    this.$alert('Мероприятие успешно удалено', 'Удалено', 'success')
                    this.reload()
                },
                onError: error => {
                    console.log(error)
                },
                doFinally: () => {
                    this.setLoadingDataNames('eventViewModelDeleteEvent')
                }
            })
        }).catch(e => {/* */})
    }

    private changeStatus() {
        this.$confirm(`Вы дествительно хотите изменить статус мероприятие на "${!this.event.is_finished ? 'Сделано' : 'Не сделано'}"?`, 'Предупреждение', 'info').then(() => {
            this.setLoadingDataNames('EventViewModelChangeStatus')
            httpGet({
                url: `${apiUrl}/techmap/status/update/taskfield/${this.event.id}/`,
                isSecureRequest: true,
                onSuccess: json => {
                    this.$alert('Статус мероприятие успешно обновлено', 'Обновлено', 'success')
                    this.reload()
                },
                onError: error => {
                    console.log(error)
                },
                doFinally: () => {
                    this.setLoadingDataNames('EventViewModelChangeStatus')
                }
            })
        }).catch(e => {/* */})
    }

    private toggleMoreButton() {
        this.moreStatus = !this.moreStatus
    }

    @Emit('reload')
    private reload() {
        return {}
    }

}
