



























































































































































































import {Component, Vue, Watch} from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import { GetterNames, MutationNames } from '@/store/types'
import { Route } from 'vue-router'
import { httpGet, httpDelete } from '@/util/http'
import { apiUrl, baseUrl } from '@/domain/constants'
import NewCarModel from './components/NewCarModel.vue'
import NewRecordModel from './components/NewRecordModel.vue'
import i18n from '@/i18n'
import { egisticLogo, pdfWaterMark } from '@/data/pdfImageData'
import { makePDF, PdfActions } from '@/utils/services'
@Component({
    components: {
        NewCarModel,
        NewRecordModel
    }
})
export default class ServiceBookView extends Vue {
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: Route
    @Getter(GetterNames.getSidebarModel) private getSidebarModel!: any
    @Getter(GetterNames.getPermissions) private getPermissions!: any
    @Getter(GetterNames.getLoadingDataNames) private getLoadingDataNames!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    private egisticLogo: any = egisticLogo
    private pdfWaterMark: any = pdfWaterMark
    private tractor: any = null
    private serviceBook: any = []
    private searchText = ''
    private sortingType: any = {
        property: '',
        alphabetically: true
    }

    private getRecords() {
        const searchResult = []
        for (const record of this.serviceBook) {
            if (record.service_type.toLowerCase().includes(this.searchText.toLowerCase()) ||
                record.mileage.toString().toLowerCase().includes(this.searchText.toLowerCase()) ||
                record.engine_hour.toString().toLowerCase().includes(this.searchText.toLowerCase()) ||
                record.consumables.toLowerCase().includes(this.searchText.toLowerCase()) ||
                record.cost.toString().toLowerCase().includes(this.searchText.toLowerCase()) ||
                this.getStatus(record.status, record.start_time, record.end_time, false).toLowerCase().includes(this.searchText.toLowerCase()) ||
                record.comments.toLowerCase().includes(this.searchText.toLowerCase())) {
                    searchResult.push(record)
                }
        }
        if (this.sortingType.property !== '') {
            searchResult.sort((a: any, b: any) => a[this.sortingType.property] > b[this.sortingType.property] ? 1 : -1)
            if (!this.sortingType.alphabetically) searchResult.reverse()
        }
        return searchResult
    }

    private changeSortingType(type: any) {
        if (this.sortingType.property === type) {
            this.sortingType.alphabetically = !this.sortingType.alphabetically
        } else {
            this.sortingType.property = type
            this.sortingType.alphabetically = true
        }
    }

    private getImageUrl(imageUrl: any) {
        return `${baseUrl}${imageUrl}`
    }

    private getStatus(status: any, startDate: any, endDate: any, ifcolor: boolean) {
        const today = new Date()
        const startTime = new Date(startDate)
        const endTime = new Date(endDate)
        if (status === 'done') return ifcolor ? '#4B970F' : (i18n.t('general.process.done') as string)
        if (status === 'cancelled') return ifcolor ? '#D51900' : (i18n.t('general.process.cancelled') as string)
        if (today.getTime() < startTime.getTime()) {
            return ifcolor ? '#00B2FF' : (i18n.t('general.process.planned') as string)
        } else if (today.getTime() < endTime.getTime()) {
            return ifcolor ? '#FFC700' : (i18n.t('general.process.in_process') as string)
        } else {
            return ifcolor ? '#D51900' : (i18n.t('general.process.outdated') as string)
        }
    }

    private getDuration(startDate: any, endDate: any) {
        if (startDate && endDate && !startDate.includes('NaN-NaN-NaN') && !endDate.includes('NaN-NaN-NaN')) {
            const startDay = startDate.split(' ')[0].split('-')
            const startTime = startDate.split(' ')[1].split(':')
            const endDay = endDate.split(' ')[0].split('-')
            const endTime = endDate.split(' ')[1].split(':')
            return + startDay[2] + '.' + startDay[1] + '.' + startDay[0] + ' ' + startTime[0] + ':' + startTime[1] +
                ' - \n ' + endDay[2] + '.' + endDay[1] + '.' + endDay[0] + ' ' + endTime[0] + ':' + endTime[1]
        }
        return (i18n.t('general.dates.from') as string) + ( i18n.t('general.dates.format_date') as string) + ' ' + (i18n.t('general.dates.format_time') as string) + ' - ' + (i18n.t('general.dates.to') as string) + ( i18n.t('general.dates.format_date') as string) + ' ' + (i18n.t('general.dates.format_time') as string)
    }

    private deleteRecord(id: any) {
        if (confirm(i18n.t('messages.questions.delete.record') as string)) {
            this.setLoadingDataNames('serviceBookDeleteRecord')
            httpDelete({
                url: `${apiUrl}/yields/service/book/${id}`,
                isSecureRequest: true,
                onSuccess: () => {
                    alert(i18n.t('messages.success.deleted'))
                },
                onError: error => {
                    console.log(error)
                },
                doFinally: () => {
                    this.setLoadingDataNames('serviceBookDeleteRecord')
                    this.getAgregate()
                }
            })
        }
    }

    private mounted() {
        this.getAgregate()
    }

    private getAgregate() {
        this.setLoadingDataNames('serviceBookGetAgregate')
        httpGet({
            url:  `${apiUrl}/yields/get/service_book/by/tractor/${this.currentRoute.params.carId}`,
            isSecureRequest: true,
            onSuccess: json => {
                if (json.tractor) {
                    this.tractor = json.tractor
                    this.serviceBook = json.service_books
                }
            },
            onError: error => {
            console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('serviceBookGetAgregate')
            }
        })
    }

    private getExcelData() {
        const excelData: any = []
        if (this.serviceBook.length === 0) {
            excelData.push({
                [i18n.t('headers.workspace.autopark.add_record.type') as string]: '',
                [i18n.t('headers.workspace.autopark.add_record.status') as string]: '',
                [i18n.t('headers.workspace.autopark.add_record.status') as string + ', ' + i18n.t('general.units.km')]: '',
                [i18n.t('headers.workspace.autopark.add_record.hours') as string + ', ' + i18n.t('general.units.h') ]: '',
                [i18n.t('headers.workspace.autopark.add_record.date_range') as string]: '',
                [i18n.t('headers.workspace.autopark.add_record.consumables') as string]: '',
                [i18n.t('headers.workspace.storage.logs.fields.cost') as string]: '',
                [i18n.t('headers.workspace.autopark.add_record.note') as string]: ''
            })
        } else {
            this.serviceBook.forEach((record: any) => {
                excelData.push({
                    [i18n.t('headers.workspace.autopark.add_record.type') as string]: record.service_type,
                    [i18n.t('headers.workspace.autopark.add_record.status') as string]: this.getStatus(record.status, record.start_time, record.end_time, false) + (record.repair_stage !== '' ? ' (' + record.repair_stage + ')' : ''),
                    [i18n.t('headers.workspace.autopark.add_record.status') as string + ', ' + i18n.t('general.units.km')]: record.mileage,
                    [i18n.t('headers.workspace.autopark.add_record.hours') as string + ', ' + i18n.t('general.units.h') ]: record.engine_hour,
                    [i18n.t('headers.workspace.autopark.add_record.date_range') as string]: this.getDuration(record.start_time, record.end_time),
                    [i18n.t('headers.workspace.autopark.add_record.consumables') as string]: record.consumables,
                    [i18n.t('headers.workspace.storage.logs.fields.cost') as string]: record.cost,
                    [i18n.t('headers.workspace.autopark.add_record.note') as string]: record.comments === '' ? i18n.t('messages.errors.no_description') : record.comments
                })
            })
        }
        return excelData
    }

    private downloadExcel() {
        const downloader: any = document.getElementById('downloadExcel')
        if (downloader) {
            downloader.click()
        }
    }

    private printPdf() {
        const date = new Date()
        const today = date.toLocaleString('ru', { hour12: false, day: 'numeric', month: 'numeric', year: 'numeric',  hour: "numeric", minute: "numeric"})
        const docDefinition = {
            pageSize: {
                width: 842,
                height: 595
            },
            pageMargins: [16, 16, 16, 16],
            background: [
                {
                    image: this.pdfWaterMark,
                    margin: [400, 0, 0, 0]
                }
            ],
            content: [
                {
                    image: this.egisticLogo,
                    margin: [359, 24, 0, 0],
                    width: 91.14,
                    height: 30
                },
                {
                    text: i18n.t('headers.workspace.profile.service_book.title'),
                    fontSize: 16,
                    bold: true,
                    margin: [0, 24, 0, 0],
                    alignment: 'center'
                },
                {
                    text: today,
                    fontSize: 16,
                    bold: true,
                    margin: [0, 8, 0, 0],
                    alignment: 'center'
                },
                {
                    layout: 'lightHorizontalLines',
                    margin: [24, 24, 24, 24],
                    table: {
                        body: [
                            [{text: this.tractor.class_name, bold: true, fontSize: 16, margin: [24, 0, 0, 0]}, '', '', '', '', ''],
                            [{text: i18n.t('headers.workspace.autopark.add_machine.model'), fontSize: 14, margin: [24, 12, 0, 0], color: '#878882'},
                            {text: this.tractor.model_name, fontSize: 14, margin: [24, 12, 12, 0]},
                            {text: i18n.t('headers.workspace.autopark.add_machine.state_num'), fontSize: 14, margin: [24, 12, 0, 0], color: '#878882'},
                            {text: this.tractor.uid, fontSize: 14, margin: [24, 12, 12, 0]},
                            {text: i18n.t('headers.workspace.autopark.add_machine.category.title'), fontSize: 14, margin: [24, 12, 0, 0], color: '#878882'},
                            {text: this.tractor.category_name, fontSize: 14, margin: [24, 12, 12, 0]}]
                        ]
                    }
                },
                {
                    table: {
                        margin: [0, 24, 0, 0],
                        widths: [118, 100, 65, 70, 126, 99, 79, 'auto'],
                        headerRows: 1,
                        body: this.getPdfTableBody()
                    }
                }
            ]
        }
        makePDF(PdfActions.PRINT, docDefinition)
    }

    private getPdfTableBody() {
        const table: any = []
        const row: any = []
        const tableHeaders = [
            i18n.t('headers.workspace.autopark.add_record.type'),
            i18n.t('headers.workspace.autopark.add_record.status'),
            i18n.t('headers.workspace.autopark.add_record.mileage') + ' ' + i18n.t('general.units.km'),
            i18n.t('headers.workspace.autopark.add_record.hours') + ' ' + i18n.t('general.units.h'),
            i18n.t('headers.workspace.autopark.add_record.date_range'),
            i18n.t('headers.workspace.autopark.add_record.consumables'),
            i18n.t('headers.workspace.autopark.add_record.sum'),
            i18n.t('headers.workspace.autopark.add_record.note')]
        tableHeaders.forEach((e: any) => {
            row.push({text: e, fontSize: 10})
        })
        table.push(row)
        this.serviceBook.forEach((record: any) => {
            table.push([
                {text: record.service_type, fontSize: 10},
                [{text: this.getStatus(record.status, record.start_time, record.end_time, false), fontSize: 10, color: this.getStatus(record.status, record.start_time, record.end_time, true)},
                {text: record.repair_stage !== '' ? ' (' + record.repair_stage + ')' : '', fontSize: 10}],
                {text: record.mileage, fontSize: 10},
                {text: record.engine_hour, fontSize: 10},
                {text: this.getDuration(record.start_time, record.end_time), fontSize: 10},
                {text: record.consumables, fontSize: 10},
                {text: record.cost, fontSize: 10},
                {text: record.comments === '' ? i18n.t('messages.errors.no_description') : record.comments, fontSize: 10}
            ])
        })
        return table
    }

    private close() {
        this.$router.push(`/profile`)
    }
}
