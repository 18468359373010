


































































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator'
import { Getter, Action, Mutation } from 'vuex-class'
import { GetterNames, ActionNames, MutationNames } from '@/store/types'
import { Route } from 'vue-router'
import { httpGet, httpPost, httpDelete, httpPut } from '@/util/http'
import { apiUrl2, apiUrl } from '@/domain/constants'
@Component({
  components: {
  }
})
export default class OrdersView extends Vue {
    @Getter(GetterNames.getSidebarModel) private getSidebarModel!: any
    @Getter(GetterNames.getLoadingDataNames) private getLoadingDataNames!: any
    @Getter(GetterNames.getPermissions) private getPermissions!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    private orders: any = []
    private moreButton: any = {
        id: 0,
        status: false
    }

    private normalizeDate(date: any) {
        return date.substring(8, 10) + '.' + date.substring(5, 7) + '.' + date.substring(0, 4)
    }

    private mounted() {
        this.getOrders()
    }

    private getOrders() {
        this.setLoadingDataNames('ordersViewOrders')
        httpGet({
            url:  `${apiUrl}/get_results/group_user`,
            isSecureRequest: true,
            onSuccess: json => {
                this.orders = json
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('ordersViewOrders')
            }
        })
    }

    private toggleMorebtnDropdown(val: number) {
        if (val === this.moreButton.id) {
            this.moreButton.status = this.moreButton.status === true ? false : true
        } else {
            this.moreButton.id = val
            this.moreButton.status = true
        }
    }

    private closeMorebtnDropdown() {
        this.moreButton.status = false
    }

    private close() {
        this.$router.push('/cadastres').catch(err => {/* */})
    }
}
