














import { Component, Vue, Prop} from 'vue-property-decorator'
@Component
export default class CheckBox extends Vue {
    @Prop({default: '#4B970F'})
    private fillColor !: string

    @Prop({default: false})
    private checked!: boolean
}
