export default {
    welcome: "Қош келдіңіздер",
    whoarewe1: "EGISTIC - сіздің шаруашылығыңызды басқару жүйесі",
    whoarewe2: "Ауыл шаруашылығы саласындағы консультанттар мен өндірушілері үшін шаруашылықты басқару жүйесі құрылған",
    address: 'Нұр-Сұлтан қаласы, Қабанбай батыр даңғылы, 53, технопарк, кеңсе 13.3',
    mission: {
        1: "“Біздің миссиямыз",
        2: "- ферманы басқаруды жеңілдетіміз”",
        content: {
            1: "EGISTIC фермасын басқару жүйесі қашықтықтан зондтау технологияларын, жоғары дәлдіктегі спутниктік навигацияны, геоақпараттық жүйелерді және машиналық оқыту технологияларын қолдана отырып, егін алқаптарын бақылау мен басқарудың интеграцияланған шешімін жүзеге асыруға негізделген.",
            2: "\"EGISTIC\" тарихы 2018 жылы Жандос Керімқұлов (бас директор және тең құрылтайшы) ашық бастапқы коды бар спутниктік суреттер арқылы Қазақстандағы фермерлер үшін фермаларды басқару жүйесін жеңілдету технологиясын көрген кезде басталады. Қазақстандық нарықта шетелдік, қымбат және пайдалану қиын болып табылатын бірнеше бағдарламалық сервистер бар. Сондықтан мұндай қызметтерді тек холдингтер ғана пайдалана алады. Бір жыл ішінде Egistic тобы фермерлерге спутниктік кескіндерді қолдануды бастау үшін қарапайым операцияларды жүргізуге көмектесетін қарапайым бағдарламалық жасақтама жасады.",
            3: "Енді тестілеу мен дамытудың екі жылында EGISTIC қызметі кеңейтілді және жүйеде ферманы іске қосуға арналған қызметтердің толық тізімі бар деп айта аламыз.",
            3.5: "Бізде 1000-нан астам қолданушы бар және біздің фермерлеріміз 2016 жылы спутниктік суреттер мен ауа-райы мәліметтеріне бір рет басу арқылы қол жеткізе алады."
        }
    },
    application: "Egistic мобильді қосымшасы қолжетімді. Сіз орнатуды қалайсыз ба?",
    guide_start: "танысуды бастаңыз",
    personal_info: {
        name: "Аты",
        surname: "Тегі",
        email: "E-mail",
        number: "Телефон нөмірі",
        position: "Лауазым",
        password: "Құпиясөз ",
        permission: "Қол жеткізу түрі"
    },
    units: {
        he: " га",
        t: " т",
        tenge: " теңге",
        tenge_he: " теңге/га",
        kg_he: " кг/га",
        tg_he: " тг/га",
        centner_he: "ц/га",
        t_he: "т/га",
        km_h: " км/сағ",
        m_s: " м/с",
        mm: " мм",
        l: " л",
        tg: " тг",
        km: " км",
        m: " м",
        h: " сағ"
    },
    language: "Қазақ тілі",
    dates: {
        date: "Күн",
        today: "Бүгін",
        from: "Бастап",
        starting_from: "бастап",
        to: "дейін ",
        up_to: "дейін",
        format_date: "КК.АА.ЖЖ",
        format_time: "СС:ММ",
        months: [ "қаңтар", "ақпан", "наурыз", "сәуір", "мамыр", "маусым", "шілде", "тамыз", "қыркүйек", "қазан", "ақпан", "желтоқсан"],
        week: ["Дү", "Сй", "Ср", "Бе", "Жұ", "Сн", "Же"],
        month: ["қан", "ақп", "нау", "сәу", "мам", "мау", "шіл", "там", "қыр", "қаз", "ақп", "жел"]
    },
    process: {
        done: "Жасаған",
        cancelled: "Болдырмаған",
        planned: "Жспарландырған",
        in_process: "Процесінде",
        outdated: "Мерзімін өткізіп алған",
        loading: "Жүктеу жүріп жатыр",
        broadcasting: "Трансляция жүріп жатыр",
        connection_failed: "Қосылым үзілді"
    },
    bill: {
        check: "Төлем шоты",
        number: "Нөмер:",
        from_date: "Күннен",
        status: "Мәртебесі:",
        paid: "Төленді",
        not_paid: "Төленбеген",
        total: "Жиынтығы:"
    },
    download_file: "Файлды жүктеу"
}