
































































import { Component, Vue } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import { GetterNames, MutationNames } from '@/store/types'
import { httpGet, httpDelete } from '@/util/http'
import { apiUrl } from '@/domain/constants'
import NewTemplateModal from './components/NewTemplateModal.vue'
@Component({
    components: {
        NewTemplateModal
    }
})
export default class TemplatesView extends Vue {
    @Getter(GetterNames.getSidebarModel) private getSidebarModel!: any
    @Getter(GetterNames.getLoadingDataNames) private getLoadingDataNames!: any
    @Getter(GetterNames.getPermissions) private getPermissions!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    private templates: any = []
    private moreButton: any = {
        status: false,
        id: -1
    }

    private mounted() {
        this.fetchTemplates()
    }

    private fetchTemplates() {
        this.setLoadingDataNames('TemplatesViewGetTemplates')
        httpGet({
            url:  `${apiUrl}/techmap/get/my/templates/`,
            isSecureRequest: true,
            onSuccess: json => {
                this.templates = json
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('TemplatesViewGetTemplates')
            }
        })
    }

    private deleteTechmap(id: any, name: any) {
        this.$confirm(`Вы действительно хотите удалить шаблон: "${name}"?`, 'Подтверждение', 'warning').then(() => {
            this.setLoadingDataNames('TechMapBoardDeleteTechMap')
            httpDelete({
                url: `${apiUrl}/techmap/${id}/`,
                isSecureRequest: true,
                onSuccess: () => {
                    this.$alert('Шаблон успешно удален', 'Удалено', 'success')
                    this.fetchTemplates()
                },
                onError: error => {
                    console.log(error)
                },
                doFinally: () => {
                    this.setLoadingDataNames('TechMapBoardDeleteTechMap')
                }
            })
        }).catch(e => {/* */})
    }

    private toggleMorebtnDropdown(val: number) {
        if (val === this.moreButton.id) {
            this.moreButton.status = this.moreButton.status === true ? false : true
        } else {
            this.moreButton.id = val
            this.moreButton.status = true
        }
    }

    private openTemplate(id: any) {
        this.$router.push({
            name: 'templateevents',
            params: {
                templateId: id.toString()
            }
        })
    }

    private closeMorebtnDropdown() {
        this.moreButton.status = false
    }

    private close() {
        this.$router.push('/cadastres').catch(err => {/* */})
    }
}
