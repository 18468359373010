var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vl-layer-tile',{attrs:{"cross-origin":"anonymous"}},[_vm._l((_vm.getCurrentFarmFields()),function(field,i){return _c('vl-feature',{key:'field'+i,attrs:{"properties":{ id: field.id, type: 'field'}}},[_c('vl-geom-multi-polygon',{attrs:{"coordinates":field.geom.coordinates}}),_c('vl-style-box',[_c('vl-style-fill',{attrs:{"color":"rgba(255,255,255,0)"}}),(!_vm.getAgroChemResult.selectedFields.includes(field.id) ||
                !_vm.getAgroChemResult.mapSelection)?_c('vl-style-text',{attrs:{"text":("№" + (field.name) + "\n" + (Math.round(field.area / 100) / 100) + " " + (_vm.$t('general.units.he'))),"scale":_vm.textScale,"overflow":true,"color":"rgb(255,255,255)"}},[_c('vl-style-fill',{attrs:{"color":'#FFFFFF'}}),_c('vl-style-stroke',{attrs:{"color":'rgb(34, 34, 34)'}})],1):_vm._e(),_c('vl-style-stroke',{attrs:{"color":"rgb(255,255,255)","width":1}})],1)],1)}),_vm._l((_vm.getAgroChemResult.selectedFields),function(fieldId,index){return [(_vm.getFieldbyId(fieldId))?_c('vl-feature',{key:index,attrs:{"properties":{
                id: fieldId,
                type: 'chosenfield'
            }}},[_c('vl-geom-multi-polygon',{attrs:{"coordinates":_vm.getFieldbyId(fieldId).geom.coordinates}}),_c('vl-style-box',[_c('vl-style-fill',{attrs:{"color":_vm.getFillColor(fieldId)}}),_c('vl-style-stroke',{attrs:{"color":"rgb(255,255,0)","width":2}})],1),(_vm.getAgroChemResult.mapSelection)?_c('vl-feature',{attrs:{"properties":{
                id:fieldId,
                type: 'numeration'
            }}},[_c('vl-geom-point',{attrs:{"coordinates":_vm.getFieldCenter(_vm.getFieldbyId(fieldId))}}),_c('vl-style-box',[_c('vl-style-text',{attrs:{"scale":2,"text":("" + (_vm.getAgroChemResult.selectedFields.findIndex(function (x) { return x === fieldId; })+1))}},[_c('vl-style-fill',{attrs:{"color":"#FFFFFF"}})],1),_c('vl-style-circle',{attrs:{"radius":20,"coordinates":_vm.getFieldCenter(_vm.getFieldbyId(fieldId))}},[_c('vl-style-fill',{attrs:{"color":"green"}}),_c('vl-style-stroke',{attrs:{"color":"white","width":2}})],1)],1)],1):_vm._e()],1):_vm._e()]}),_c('vl-interaction-select',{attrs:{"features":_vm.selectedFeatures},on:{"update:features":function($event){_vm.selectedFeatures=$event}}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }