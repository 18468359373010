










































import { Component, Vue, Watch, Emit, Prop } from 'vue-property-decorator'

@Component
export default class Modal extends Vue {
    private isShown = false
    @Prop({default: false})
    private loading!: boolean
    @Prop()
    private confirmText!: string

    @Emit('cancel')
    private cancel() {
        return {}
    }

    @Emit('confirm')
    private confirm() {
        return {}
    }

    private show() {
        this.isShown = true
    }

    private hide() {
        this.isShown = false
    }
}
