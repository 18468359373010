

















































































































































import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { GetterNames, MutationNames } from '@/store/types'
import { Getter, Mutation } from 'vuex-class'
import * as turf from '@turf/turf'
import UploadAgroChem from '@/models/UploadAgroChem'
@Component ({
    components: {
    }
})
export default class AgroChemFileUpload extends Vue {
    @Getter(GetterNames.getMapData) private mapData !: any
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: any
    @Getter(GetterNames.getUploadAgroChemResult) private getUploadAgroChemResult!: any
    @Getter(GetterNames.getFarmFromRoute) private getFarmFromRoute!: any
    @Mutation(MutationNames.setUploadAgroChemResult) private setUploadAgroChemResult!: any
    @Prop() private map!: any
    @Prop() private view!: any
    private textScale = 1
    private drawnPoint: any[] = []
    private selectedFeatures: any[] = []

    get fieldIndex() {
        return this.getUploadAgroChemResult.selectedPoint.fieldIndex
    }

    get pointIndex() {
        return this.getUploadAgroChemResult.selectedPoint.pointIndex
    }

    @Watch('drawnPoint')
    private onDrawPoint() {
        if (this.drawnPoint.length === 0) return
        else {
            const point = turf.point(this.drawnPoint[0].geometry.coordinates)
            this.getFarmFromRoute.fields.every((x: any) => {
                const polygon = turf.polygon(x.geom.coordinates[0] as any)
                if (turf.booleanContains(polygon, point)) {
                    const index = this.getUploadAgroChemResult.data.findIndex((y: any) => y.field.id === x.id)
                    if (index !== -1) {
                        this.getUploadAgroChemResult.data[index].addPoint(this.drawnPoint[0].geometry.coordinates)
                        this.setUploadAgroChemResult({selectedPoint: {
                            fieldId: x.id,
                            fieldIndex: index,
                            pointIndex: this.getUploadAgroChemResult.data[index].points.length - 1
                        }})
                    } else {
                        const field = new UploadAgroChem()
                        field.setSelectedField(
                            x.id,
                            `№${x.name}`,
                            this.getFarmFromRoute.getCenter(x.id.toString()),
                            this.getFarmFromRoute.getZoom(x.id.toString()),
                            x.geom.coordinates
                        )
                        field.removePoint(0)
                        field.addPoint(this.drawnPoint[0].geometry.coordinates)
                        this.getUploadAgroChemResult.data.push(field)
                        this.setUploadAgroChemResult({
                            selectedPoint: {
                                fieldId: x.id,
                                fieldIndex: this.getUploadAgroChemResult.data.length - 1,
                                pointIndex: 0
                            }
                        })
                    }
                    return false
                } else return true
            })
            this.drawnPoint = []
        }
    }

    @Watch('selectedFeatures')
    private onSelectFeature() {
        if (this.selectedFeatures.length === 0) return
        else {
            if (this.selectedFeatures[0].properties.type === 'agrochem-point') {
                this.setUploadAgroChemResult({selectedPoint: {
                    fieldId: this.selectedFeatures[0].properties.id,
                    fieldIndex: this.selectedFeatures[0].properties.fieldIndex,
                    pointIndex: this.selectedFeatures[0].properties.index
                }})
            } else {
                this.setUploadAgroChemResult({
                    selectedPoint: {
                        fieldId: null,
                        fieldIndex: null,
                        pointIndex: null
                    }
                })
            }
            this.selectedFeatures = []
        }
    }

    @Watch('getUploadAgroChemResult.selectedField')
    private onchangeSelectedField(val: any, old: any) {
        if (val) {
            const field = this.getUploadAgroChemResult.data.find((x: any) => x.field.id === val)
            if (field && this.view.$view) {
                this.view.$view.animate({
                    center: field.field.center,
                    zoom: field.field.zoom,
                    duration: 500
                })
            }
        }
    }

    private closeSelectedPoint() {
        this.setUploadAgroChemResult({
            selectedPoint: {
                fieldId: null,
                fieldIndex: null,
                pointIndex: null
            }
        })
    }

    private getPointRadius() {
        return  100 / this.calculateMetersInPixel()
    }

    private calculateMetersInPixel() {
        const Spixel = ( 2 * Math.PI * 6378137) / Math.pow( 2, this.mapData.zoom + 8 )
        return Spixel
    }

    @Watch('zoom')
    private zoomChanged(currentZoom: number) {
      this.textScale = this.mapZoomToTextScale(currentZoom)
    }

    private mapZoomToTextScale(currentZoom: number) {
      const maxScale = 4
      const minScale = 1
      const zoomRatio = (currentZoom - this.mapData.MIN_ZOOM) / (this.mapData.MAX_ZOOM - this.mapData.MIN_ZOOM)
      return zoomRatio * (maxScale - minScale) + minScale
    }
}
