




























import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import {ActionNames, GetterNames} from '@/store/types'
import {Action, Getter} from 'vuex-class'
import FieldSlider from './FieldSlider.vue'

@Component({
    components: {
        FieldSlider
    }
})
export default class TaskRequests extends Vue {
    @Action(ActionNames.updateMapDataInRegion) private updateMapDataInRegion!: any
    @Getter(GetterNames.getMapData) private mapData !: any
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: any
    @Getter(GetterNames.getFarmFromRoute) private getFarmFromRoute!: any
    @Getter(GetterNames.getFarmMapControllers) private getFarmMapControllers!: any
    @Getter(GetterNames.getTaskData) private getTaskData!: any
    @Prop() private fields!: any
    @Prop() private vlView!: any
    private selectedFeatures: any[] = []
    private textScale = 1
    private boundaries: any = [0, 0, 0, 0]

    @Watch('selectedFeatures')
    private setChosenCadastreField(features: any, oldFeatures: any) {
      if (features.length === 0) return
      if (features[0].properties === null) return
      if (features[0].properties.type === 'fieldtask') {
        this.$router.push({

          name: 'fieldtelematics',
          params: {
            farmId: this.$route.params.farmId,
            currentevent: this.getTaskData.taskId.toString(),
            subfieldId: features[0].properties.id.toString()
          }
        })
      }
    }

    private getFieldProgress(fieldId: number) {
      const field = this.getTaskData.fields.find((fieldData: any) => fieldData.id === fieldId)
      if (field && field.progress) {
        return Math.round(field.progress * 100) / 100 + '%'
      }
      return '0%'
    }

    private getFieldColor(fieldId: number) {
      const field = this.getTaskData.fields.find((fieldData: any) => fieldData.id === fieldId)
      if (!field || !field.progress) return
      if (field.progress <= 30) {
        return '#FF0000'
      } else if (field.progress > 30 && field.progress <= 70) {
        return '#FFC700'
      } else {
        return '#4B970F'
      }
    }

    @Watch('getTaskData.currentStopPoint')
    private zoomToStopPoint(newStop: any, oldStop: any) {
      if (!newStop) return
      if (newStop !== oldStop) {
        this.vlView.animate({
          center: newStop.point.coordinates,
          zoom: 15,
          duration: 800
        })
      }
    }

    @Watch('getTaskData.taskId')
    private getBoundaries(newTask: any, oldTask: any) {
      if (!this.getTaskData) return
      let Xmin = Number.MAX_SAFE_INTEGER
      let Xmax = Number.MIN_SAFE_INTEGER
      let Ymin = Number.MAX_SAFE_INTEGER
      let Ymax = Number.MIN_SAFE_INTEGER

      this.getTaskData.fields.forEach((fieldData: any) => {
        const field = this.getFarmFromRoute.fields.find((x: any) => x.id.toString() === fieldData.id.toString())
        if ( field && field.geom) {
          for (const arr of field.geom.coordinates[0][0]) {
            Xmax = arr[0] > Xmax ? arr[0] : Xmax
            Xmin = arr[0] < Xmin ? arr[0] : Xmin
            Ymax = arr[1] > Ymax ? arr[1] : Ymax
            Ymin = arr[1] < Ymin ? arr[1] : Ymin
          }
        }
      })
      this.boundaries = [Xmin, Ymin, Xmax, Ymax]
      if (this.getTaskData.fields.length > 0) {
        this.updateMapDataInRegion({ boundaries: this.boundaries })
      }
    }

    @Watch('mapData.zoom')
    private zoomChanged(currentZoom: number) {
      this.textScale = this.mapZoomToTextScale(currentZoom)
    }

    private mapZoomToTextScale(currentZoom: number) {
      let maxScale = 3
      if (this.currentRoute.name === 'farm') {
        maxScale = this.getFarmMapControllers.textMaxScale
      }
      const minScale = 1
      const zoomRatio = (currentZoom - this.mapData.MIN_ZOOM - 2) / (this.mapData.MAX_ZOOM - this.mapData.MIN_ZOOM)
      return zoomRatio * (maxScale - minScale) + minScale
    }

}
