import { permissionTypes } from '@/data/rolesData'
import { apiUrl, apiUrl3 } from '@/domain/constants'
import { httpGet } from '@/util/http'
import { triangleGrid } from '@turf/turf'
import { exceptionFn } from 'vue-analytics'
import { NamedChunksPlugin, SplitChunksPlugin } from 'webpack'
interface DefaultArgs {
    selected: any,
    selectedName: string,
    onerror: string
}
interface DriverArgs {
    id: any,
    converted: string[],
    selected: any,
    selectedName: string,
    onerror: string,
    start_time: {
        selectedName: string,
        onerror: string
    },
    end_time: {
        selectedName: string,
        onerror: string
    }
}
interface ComplexArgs {
    id: any,
    name: string,
    uid: string,
    tractor_category: DefaultArgs,
    trailer_category: DefaultArgs,
    tractor: {
        converted: string[],
        selected: any,
        selectedName: string,
        onerror: string
    },
    trailer: {
        converted: string[],
        selected: any,
        selectedName: string,
        onerror: string
    },
    min_speed: {
        selectedName: string,
        onerror: string
    },
    max_speed: {
        selectedName: string,
        onerror: string
    },
    treatment_depth: {
        selectedName: string,
        onerror: string
    },
    coverage: {
        selectedName: string,
        onerror: string
    },
    drivers: DriverArgs[],
    visible: boolean
}
interface PreparationArgs {
    id: any,
    front_id: string,
    name: string,
    type: DefaultArgs,
    preparation: {
        converted: string[],
        selected: any,
        selectedName: string,
        onerror: string
    },
    price: {
        selectedName: string,
        onerror: string
    },
    quantity: {
        is_area: boolean,
        title: string,
        selectedName: string,
        onerror: string
    }
    consumption_rate: {
        selectedName: string,
        onerror: string
    },
    consumption_unit: {
        converted: string[],
        selected: any,
        selectedName: string,
        onerror: string
    },
    consumption_amount: {
        title: string,
        selectedName: string,
        onerror: string
    },
    solution_rate: {
        title: string,
        selectedName: string,
        onerror: string
    },
    solution_amount: {
        selectedName: string,
        onerror: string
    }
    visible: boolean
}

interface StorageArgs {
    id: any,
    front_id: string,
    name: string,
    item: {
        selected: any,
        selectedName: string,
        onerror: string
    },
    amount: {
        selectedName: string,
        onerror: string
    },
    price: {
        selectedName: string,
        onerror: string
    },
    visible: boolean
}

interface ExpensesArgs {
    id: any,
    front_id: string,
    name: {
        selectedName: string,
        onerror: string
    },
    cost: {
        selectedName: string,
        onerror: string
    }
}

export default class NewEventScript {
    public IS_TEMPLATE = false
    public TECHMAP_ID = -1
    public TECHMAP: any = null
    public IS_FIELD = false
    public FIELD_ID = -1
    public FARM_ID = -1
    public IS_UPDATE = false
    public UPDATE_EVENT: any = null

    public downloaded = {
        techmap: false,
        events: false,
        fields: false,
        categories: false,
        members: false,
        tractors: false,
        trailers: false,
        preparations: false,
        seeds: false,
        storage: false,
        parsed: false,
        all: false
    }

    public visibility = {
        complex: false,
        preparations: false,
        seeds: false,
        yield: false,
        is_quantity_as_area: true
    }

    public dropdowns = {
        members: false,
        complex: false,
        preparations: false,
        seeds: false,
        storage: false,
        expenses: false
    }

    public DATA = {
        events: {
            origin: new Array(),
            converted: new Array()
        },
        members: {
            origin: new Array(),
            converted: new Array()
        },
        tractor_categories: {
            origin: new Array(),
            converted: new Array()
        },
        trailer_categories: {
            origin: new Array(),
            converted: new Array()
        },
        tractors: {
            origin: new Array(),
            converted: new Array()
        },
        trailers: {
            origin: new Array(),
            converted: new Array()
        },
        preparations: {
            origin: new Array(),
            converted: new Array()
        },
        consumption_units: {
            origin: new Array(),
            converted: new Array()
        },
        sowing_units: {
            origin: new Array(),
            converted: new Array()
        },
        storage: {
            origin: new Array(),
            converted: new Array()
        }
    }

    public event: DefaultArgs = {
        selected: null,
        selectedName: '',
        onerror: ''
    }

    public fields = {
        origin: new Array(),
        total_area: 0,
        selected: new Array(),
        selectedNames: new Array(),
        onerror: ''
    }

    public START_DATE = ''
    public END_DATE = ''

    public members = new Array<DefaultArgs>()

    public complexes = new Array<ComplexArgs>()

    public preparations = new Array<PreparationArgs>()

    public seeds = {
        id: null as any,
        front_id: '',
        name: '',
        spacing: {
            selectedName: '',
            onerror: ''
        },
        distance: {
            selectedName: '',
            onerror: ''
        },
        depth: {
            selectedName: '',
            onerror: ''
        },
        density: {
            selectedName: '',
            onerror: ''
        },
        density_unit: {
            selected: null as any,
            selectedName: '',
            onerror: ''
        },
        cost: {
            title: '',
            selectedName: '',
            onerror: ''
        },
        area: {
            selectedName: '',
            onerror: ''
        }
    }

    public storage = new Array<StorageArgs>()

    public expenses = new Array<ExpensesArgs>()

    public NOTATION = ''

    public checkForReady() {
        if (this.downloaded.techmap &&
            this.downloaded.events &&
            this.downloaded.fields &&
            this.downloaded.categories &&
            this.downloaded.tractors &&
            this.downloaded.trailers &&
            this.downloaded.preparations &&
            this.downloaded.seeds &&
            this.downloaded.storage) {
            if (this.downloaded.parsed) {
                this.downloaded.all = true
            } else this.parseData()
        }
    }

    public fetchData(techmapId: number, isTemplate: boolean, isField: boolean, farmId: number, fieldId: number, isUpdate: boolean, event: any) {
        this.TECHMAP_ID = techmapId
        this.IS_TEMPLATE = isTemplate
        this.IS_FIELD = isField
        this.FARM_ID = farmId
        this.FIELD_ID = fieldId
        this.IS_UPDATE = isUpdate
        this.UPDATE_EVENT = event
        this.fetchTechmap()
        this.fetchEvents()
        this.fetchAutoCategories()
        if (!isTemplate) {
            this.fetchFields()
            this.fetchMembers()
            this.fetchTractors()
            this.fetchTrailers()
            this.fetchStorage()
        } else {
            this.downloaded.members = true
            this.downloaded.fields = true
            this.downloaded.tractors = true
            this.downloaded.trailers = true
            this.downloaded.storage = true
            this.checkForReady()
        }
        this.fetchPreparations()
        this.fetchSeeds()
    }

    public fetchTechmap() {
        let techmapUrl = `${apiUrl}/techmap/tasks/by/techmap/${this.TECHMAP_ID}/`
        if (this.IS_FIELD) techmapUrl = `${apiUrl}/techmap/by/field/${this.FIELD_ID}/`
        httpGet({
            url: techmapUrl,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                if (json.ok) {
                    this.TECHMAP = json.json
                    this.seeds.name = `${json.json.culture ? `${json.json.culture}` : ''}` +
                    `${json.json.sort ? `, ${json.json.sort}` : ''}` +
                    `${json.json.reproduction ? `, ${json.json.reproduction}` : ''}`
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.downloaded.techmap = true
                this.checkForReady()
            }
        })
    }

    public fetchEvents() {
        httpGet({
            url:  `${apiUrl}/techmap/static/`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                if (json.ok) {
                    this.DATA.events.origin = json.json
                    this.DATA.events.converted = json.json.map((x: any) => x.name)
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.downloaded.events = true
                this.checkForReady()
            }
        })
    }

    public fetchFields() {
        httpGet({
            url: `${apiUrl3}/techmap/fields/by/techmap/${this.TECHMAP_ID}/`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                if (json.ok) {
                    this.fields.origin = json.json
                    this.fields.onerror = ''
                    if (this.IS_FIELD) {
                        const field = json.json.find((x: any) => x.id === this.FIELD_ID)
                        if (field) {
                            this.fields.selected = [field.id]
                            this.fields.selectedNames = [`№${field.name}`]
                        }
                    } else {
                        this.fields.selected = json.json.map((x: any) => x.id)
                        this.fields.selectedNames = json.json.map((x: any) => `№${x.name}`)
                    }
                    this.calculateTotalArea()
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.downloaded.fields = true
                this.checkForReady()
            }
        })
    }

    public fetchAutoCategories() {
        httpGet({
            url:  `${apiUrl}/techmap/get/tractors/`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                if (json.ok) {
                    this.DATA.tractor_categories.origin = json.json.category
                    this.DATA.tractor_categories.converted = json.json.category.map((x: any) => x.name)
                    this.DATA.trailer_categories.origin = json.json.trailer_category
                    this.DATA.trailer_categories.converted = json.json.trailer_category.map((x: any) => x.name)
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.downloaded.categories = true
                this.checkForReady()
            }
        })
    }

    public fetchMembers() {
        httpGet({
            url:  `${apiUrl}/workspace/get/employee/by/farm/${this.FARM_ID}/`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                if (json.ok) {
                    this.DATA.members.origin = json.json
                    this.DATA.members.converted = json.json.map((x: any) => {
                        const roles = this.getPermissionTypes(x.roles)
                        return `${x.user_fullname} (${roles})`
                    })
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.downloaded.members = true
                this.checkForReady()
            }
        })
    }

    public fetchTractors() {
        httpGet({
            url:  `${apiUrl}/yields/tractor/`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                if (json.ok) {
                    this.DATA.tractors.origin = json.json
                    this.DATA.tractors.converted = json.json.map((x: any) => `${x.name} (${x.uid})`)
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.downloaded.tractors = true
                this.checkForReady()
            }
        })
    }

    public fetchTrailers() {
        httpGet({
            url:  `${apiUrl}/yields/trailer/`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                if (json.ok) {
                    this.DATA.trailers.origin = json.json
                    this.DATA.trailers.converted = json.json.map((x: any) => `${x.name} (${x.coverage})`)
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.downloaded.trailers = true
                this.checkForReady()
            }
        })
    }

    public fetchPreparations() {
        httpGet({
            url:  `${apiUrl}/techmap/get/preparations/${this.TECHMAP_ID}/`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                if (json.ok) {
                    this.DATA.preparations.origin = json.json.preparations_list.map((x: any) => {
                        return {
                            type: x.type,
                            name: x.type_name,
                            origin: x.preparations,
                            converted: x.preparations.map((y: any) => `${y.name}${y.target ? ` (${y.target})` : ''}`)
                        }
                    })
                    this.DATA.preparations.converted = json.json.preparations_list.map((x: any) => x.type_name)
                    this.DATA.consumption_units.origin = json.json.measures
                    this.DATA.consumption_units.converted = json.json.measures.map((x: any) => x.name)
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.downloaded.preparations = true
                this.checkForReady()
            }
        })
    }

    public fetchSeeds() {
        httpGet({
            url:  `${apiUrl}/techmap/get/seeds/${this.TECHMAP_ID}/`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                if (json.ok) {
                    this.DATA.sowing_units.origin = json.json.measures
                    this.DATA.sowing_units.converted = json.json.measures.map((x: any) => x.name)
                    if (json.json.measures.length > 0) {
                        this.seeds.density_unit.selected = json.json.measures[0]
                        this.seeds.density_unit.selectedName = json.json.measures[0].name
                        this.seeds.cost.title = `Стоимость семян за ${json.json.measures[0].name.split('/')[0]}`
                    }
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.downloaded.seeds = true
                this.checkForReady()
            }
        })
    }

    public fetchStorage() {
        httpGet({
            url: `${apiUrl3}/techmap/storage/items/${this.TECHMAP_ID}/`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                if (json.ok) {
                    const arr = json.json.reduce((r: any, v: any) => {
                        const result = v.current_products.map((x: any) => {
                            return {
                                storage: v.title,
                                storage_id: v.id,
                                ...x
                            }
                        })
                        r.push(...result)
                        return r
                    }, [])
                    this.DATA.storage.origin = arr
                    this.DATA.storage.converted = arr.map((x: any) => {
                        return `${x.title}`
                    })
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.downloaded.storage = true
                this.checkForReady()
            }
        })
    }

    public parseData() {
        if (this.IS_UPDATE) {
            this.event.selected = this.UPDATE_EVENT
            this.event.selectedName = this.UPDATE_EVENT.name
            this.visibility.complex = this.UPDATE_EVENT.has_tractors
            this.visibility.preparations = this.UPDATE_EVENT.has_preparations
            this.visibility.seeds = this.UPDATE_EVENT.has_seeds
            this.visibility.is_quantity_as_area = this.UPDATE_EVENT.is_quantity_as_area
            if (this.IS_FIELD) {
                const field = this.fields.origin.find((x: any) => x.id === this.FIELD_ID)
                if (field) {
                    this.fields.selected = [field.id]
                    this.fields.selectedNames = [`№${field.name}`]
                }
            } else {
                this.fields.selected = this.UPDATE_EVENT.applied_fields_read.map((x: any) => x.id)
                this.fields.selectedNames = this.UPDATE_EVENT.applied_fields_read.map((x: any) => `№${x.name}`)
            }
            this.calculateTotalArea()
            this.START_DATE = this.UPDATE_EVENT.start_date || ''
            this.END_DATE = this.UPDATE_EVENT.end_date || ''
            this.UPDATE_EVENT.users_read.forEach((x: any) => {
                const roles = this.getPermissionTypes(x.roles)
                this.members.push({
                    selected: x,
                    selectedName: `${x.user_fullname} (${roles})`,
                    onerror: ''
                })
            })
            this.UPDATE_EVENT.tractors_read.forEach((x: any) => {
                this.complexes.push({
                    id: x.id,
                    name: x.tractor ? `${x.tractor.name} (${x.tractor.uid})` : '',
                    uid: x.tractor ? x.tractor.uid : '',
                    tractor_category: {
                        selected: x.tractor_category,
                        selectedName: x.tractor_category.name,
                        onerror: ''
                    },
                    tractor: {
                        converted: this.DATA.tractors.origin.filter((y: any) => {
                            if (x.tractor_category) {
                                return y.category === x.tractor_category.id
                            } else return true
                        }).map((y: any) => `${y.name} (${y.uid})`),
                        selected: x.tractor,
                        selectedName: x.tractor ? `${x.tractor.name} (${x.tractor.uid})` : '',
                        onerror: ''
                    },
                    trailer_category: {
                        selected: x.trailer_category || null,
                        selectedName: x.trailer_category ? x.trailer_category.name : '',
                        onerror: ''
                    },
                    trailer: {
                        converted: this.DATA.trailers.origin.filter((y: any) => {
                            if (y.trailer_category) {
                                return x.trailer_category.id === y.category
                            } else return true
                        }).map((y: any) => `${y.name} (${y.coverage})`),
                        selected: x.trailer || null,
                        selectedName: x.trailer ? `${x.trailer.name} (${x.trailer.coverage})` : '',
                        onerror: ''
                    },
                    min_speed: {
                        selectedName: x.min_speed ? `${x.min_speed}` : '',
                        onerror: ''
                    },
                    max_speed: {
                        selectedName: x.max_speed ? `${x.max_speed}` : '',
                        onerror: ''
                    },
                    treatment_depth: {
                        selectedName: x.depth ? `${x.depth}` : '',
                        onerror: ''
                    },
                    coverage: {
                        selectedName: x.width ? `${x.width}` : '',
                        onerror: ''
                    },
                    drivers: x.drivers.map((y: any) => {
                        return {
                            id: y.id,
                            converted: this.DATA.members.origin.filter((z: any) => z.roles.includes(6))
                            .map((z: any) => `${z.user_fullname}`),
                            selected: {
                                user_fullname: y.user.fullname,
                                user_id: y.user.id
                            },
                            selectedName: y.user.fullname,
                            onerror: '',
                            start_time: {
                                selectedName: y.start_time,
                                onerror: ''
                            },
                            end_time: {
                                selectedName: y.end_time,
                                onerror: ''
                            }
                        }
                    }),
                    visible: false
                })
            })
            this.UPDATE_EVENT.preparations_read.forEach((x: any) => {
                let units = []
                const unit = x.consumption_rate_measurement
                if (this.DATA.consumption_units.origin.length > 0) {
                    units = this.DATA.consumption_units.origin.filter((y: any) => y.preparation_as_area === this.visibility.is_quantity_as_area)
                }
                this.preparations.push({
                    id: x.id,
                    front_id: x.front_id,
                    name:  x.type.type === 5 ? x.preparation_from_storage.title :
                    `${x.preparation.name}${x.preparation.target ? ` (${x.preparation.target})` : ''}`,
                    type: {
                        selected: x.type,
                        selectedName: x.type.type_name,
                        onerror: ''
                    },
                    preparation: {
                        converted: x.type.type === 5 ? this.DATA.storage.converted :
                        this.DATA.preparations.origin.find((y: any) => y.type === x.type.type).converted,
                        selected: x.type.type === 5 ? x.preparation_from_storage : x.preparation,
                        selectedName: x.type.type === 5 ? x.preparation_from_storage.title :
                            `${x.preparation.name}${x.preparation.target ? ` (${x.preparation.target})` : ''}`,
                        onerror: ''
                    },
                    price: {
                        selectedName: x.price ? `${x.price}` : '',
                        onerror: ''
                    },
                    quantity: {
                        is_area: this.visibility.is_quantity_as_area,
                        title: unit.preparation_as_area ? 'Общая площадь (га)' : `Количество семян (${unit.name.split('/')[1]})`,
                        selectedName: x.quantity ? `${x.quantity}` : '',
                        onerror: ''
                    },
                    consumption_rate: {
                        selectedName: x.consumption_rate ? `${x.consumption_rate}` : '',
                        onerror: ''
                    },
                    consumption_unit: {
                        converted: units.map((y: any) => y.name),
                        selected: unit,
                        selectedName: unit ? unit.name : '',
                        onerror: ''
                    },
                    consumption_amount: {
                        title: `Необходимый объем препарата (${unit ? unit.name.split('/')[0] : ''})`,
                        selectedName: x.volume ? `${x.volume}` : '',
                        onerror: ''
                    },
                    solution_rate: {
                        title: unit.preparation_as_area ? 'Расход рабочего раствора (л/га)' : `Расход рабочего раствора (л/${unit.name.split('/')[1]})`,
                        selectedName: x.working_consumption ? `${x.working_consumption}` : '',
                        onerror: ''
                    },
                    solution_amount: {
                        selectedName: x.water_volume ? `${x.water_volume}` : '',
                        onerror: ''
                    },
                    visible: false
                })
            })
            this.UPDATE_EVENT.seeds_read.forEach((x: any) => {
                const unit = x.sowing_density_measurement
                this.seeds = {
                    id: x.id,
                    front_id: x.front_id,
                    name: x.name ? x.name : this.seeds.name,
                    spacing: {
                        selectedName: x.row_spacing ? `${x.row_spacing}` : '',
                        onerror: ''
                    },
                    distance: {
                        selectedName: x.plant_distance ? `${x.plant_distance}` : '',
                        onerror: ''
                    },
                    depth: {
                        selectedName: x.sowing_depth ? `${x.sowing_depth}` : '',
                        onerror: ''
                    },
                    density: {
                        selectedName: x.sowing_density ? `${x.sowing_density}` : '',
                        onerror: ''
                    },
                    density_unit: {
                        selected: x.sowing_density_measurement,
                        selectedName: unit ? unit.name : '',
                        onerror: ''
                    },
                    cost: {
                        title: `Стоимость семян за ${unit.name.split('/')[0]}`,
                        selectedName: x.price ? `${x.price}` : '',
                        onerror: ''
                    },
                    area: {
                        selectedName: x.quantity ? `${x.quantity}` : '',
                        onerror: ''
                    }
                }

            })
            this.UPDATE_EVENT.storage_products_read.forEach((x: any) => {
                this.storage.push({
                    id: x.id,
                    front_id: x.front_id,
                    name: x.product_report.title,
                    item: {
                        selected: x.product_report,
                        selectedName: x.product_report.title,
                        onerror: ''
                    },
                    amount: {
                        selectedName: x.amount ? `${x.amount}` : '',
                        onerror: ''
                    },
                    price: {
                        selectedName: x.cost ? `${x.cost}` : '',
                        onerror: ''
                    },
                    visible: false
                })
            })
            this.UPDATE_EVENT.addition_costs_read.forEach((x: any) => {
                this.expenses.push({
                    id: x.id,
                    front_id: x.front_id_map,
                    name: {
                        selectedName: x.name,
                        onerror: ''
                    },
                    cost: {
                        selectedName: `${x.price}`,
                        onerror: ''
                    }
                })
            })
            this.NOTATION = this.UPDATE_EVENT.comment
            this.downloaded.parsed = true
            this.checkForReady()
        } else {
            this.addMember()
            this.addComplex()
            this.addPreparation()
            this.addStorage()
            this.addExpenses()
            this.downloaded.parsed = true
            this.checkForReady()
        }
    }

    public onSelectEvent(item: string) {
        const event = this.DATA.events.origin.find((x: any) => x.name === item)
        if (event) {
            this.event.selected = event
            this.event.selectedName = event.name
            this.event.onerror = ''
            this.visibility.complex = event.has_tractors
            this.visibility.preparations = event.has_preparations
            this.visibility.seeds = event.has_seeds
            this.visibility.yield = event.parameters.includes("Урожайность")
            this.visibility.is_quantity_as_area = event.is_quantity_as_area
        }
    }

    public selectAllFields() {
        if (this.fields.origin.length === this.fields.selected.length) {
            this.fields.selected = []
            this.fields.selectedNames = []
        } else {
            this.fields.selected = this.fields.origin.map((x: any) => x.id)
            this.fields.selectedNames = this.fields.origin.map((x: any) => `№${x.name}`)
        }
        this.calculateTotalArea()
    }

    public selectField(field: any) {
        const idIndex = this.fields.selected.findIndex((x: number) => x === field.id)
        const nameIndex = this.fields.selectedNames.findIndex((x: string) => x === `№${field.name}`)
        if (idIndex !== -1) {
            this.fields.selected.splice(idIndex, 1)
        } else this.fields.selected.push(field.id)
        if (nameIndex !== -1) {
            this.fields.selectedNames.splice(nameIndex, 1)
        } else this.fields.selectedNames.push(`№${field.name}`)
        this.calculateTotalArea()
    }

    public calculateTotalArea() {
        const oldArea = this.fields.total_area
        this.fields.total_area = this.fields.origin.reduce((r: number, v: any) => {
            if (this.fields.selected.includes(v.id)) {
                r += v.area
            }
            return r
        }, 0)
        if (oldArea !== this.fields.total_area) {
            for (const [index, preparation] of this.preparations.entries()) {
                if (preparation.quantity.is_area) {
                    this.preparations[index].quantity.selectedName = `${Math.round(this.fields.total_area / 100) / 100}`
                    this.preparations[index].quantity.onerror = ''
                }
            }
            this.seeds.area.selectedName = `${Math.round(this.fields.total_area / 100) / 100}`
        }
    }

    public toggleMembersDropdown() {
        this.dropdowns.members = !this.dropdowns.members
    }

    public getPermissionTypes(roles: any) {
        const arr = []
        for (const role of permissionTypes) {
            if (roles.includes(role.num)) {
                arr.push(role.name)
            }
        }
        return arr.join(', ')
    }

    public onSelectMember(item: any, index: any) {
        if (this.members[index]) {
            const member = this.DATA.members.origin.find((x: any) => {
                const roles = this.getPermissionTypes(x.roles)
                return `${x.user_fullname} (${roles})` === item
            })
            if (member) {
                this.members[index].selected = member
                this.members[index].selectedName = item
                this.members[index].onerror = ''
            }
        }
    }

    public addMember() {
        this.members.push({
            selected: null,
            selectedName: '',
            onerror: ''
        })
    }

    public deleteMember(index: number) {
        if (this.members[index]) {
            this.members.splice(index, 1)
        }
    }

    public getMembersCount() {
        return this.members.filter((x: any) => x.selected).length
    }

    public toggleComplexDropdown() {
        this.dropdowns.complex = !this.dropdowns.complex
    }

    public toggleComplex(index: number) {
        this.complexes[index].visible = !this.complexes[index].visible
    }

    public addComplex() {
        this.complexes.push({
            id: null,
            name: '',
            uid: '',
            tractor_category: {
                selected: null,
                selectedName: '',
                onerror: ''
            },
            trailer_category: {
                selected: null,
                selectedName: '',
                onerror: ''
            },
            tractor: {
                converted: this.DATA.tractors.converted,
                selected: null,
                selectedName: '',
                onerror: ''
            },
            trailer: {
                converted: this.DATA.trailers.converted,
                selected: null,
                selectedName: '',
                onerror: ''
            },
            min_speed: {
                selectedName: '',
                onerror: ''
            },
            max_speed: {
                selectedName: '',
                onerror: ''
            },
            treatment_depth: {
                selectedName: '',
                onerror: ''
            },
            coverage: {
                selectedName: '',
                onerror: ''
            },
            drivers: [],
            visible: false
        })
    }

    public copyComplex(index: number) {
        if (this.complexes[index]) {
            const complex = this.complexes[index]
            this.complexes.push({
                id: null,
                name: '',
                uid: '',
                tractor_category: {
                    selected: complex.tractor_category.selected,
                    selectedName: complex.tractor_category.selectedName,
                    onerror: complex.tractor_category.onerror
                },
                trailer_category: {
                    selected: complex.trailer_category.selected,
                    selectedName: complex.trailer_category.selectedName,
                    onerror: complex.trailer_category.onerror
                },
                tractor: {
                    converted: complex.tractor.converted,
                    selected: null,
                    selectedName: '',
                    onerror: ''
                },
                trailer: {
                    converted: complex.trailer.converted,
                    selected: complex.trailer.selected,
                    selectedName: complex.trailer.selectedName,
                    onerror: complex.trailer.onerror
                },
                min_speed: {
                    selectedName: complex.min_speed.selectedName,
                    onerror: complex.min_speed.onerror
                },
                max_speed: {
                    selectedName: complex.max_speed.selectedName,
                    onerror: complex.max_speed.onerror
                },
                treatment_depth: {
                    selectedName: complex.treatment_depth.selectedName,
                    onerror: complex.treatment_depth.onerror
                },
                coverage: {
                    selectedName: complex.coverage.selectedName,
                    onerror: complex.coverage.onerror
                },
                drivers: [],
                visible: false
            })
        }
    }

    public deleteComplex(index: number) {
        if (this.complexes[index]) {
            this.complexes.splice(index, 1)
        }
    }

    public onSelectTractorCategory(item: string, index: number) {
        if (this.complexes[index]) {
            const category = this.DATA.tractor_categories.origin.find((x: any) => x.name === item)
            if (category) {
                this.complexes[index].tractor_category.selected = category
                this.complexes[index].tractor_category.selectedName = category.name
                this.complexes[index].tractor_category.onerror = ''
                if (this.complexes[index].tractor.selected) {
                    if (this.complexes[index].tractor.selected.category !== category.id) {
                        this.complexes[index].tractor.selected = null
                        this.complexes[index].tractor.selectedName = ''
                        this.complexes[index].tractor.onerror = ''
                    }
                }
                this.complexes[index].tractor.converted = this.DATA.tractors.origin.filter((x: any) => {
                    return x.category === category.id
                }).map((x: any) => `${x.name} (${x.uid})`)
            }
        }
    }

    public onSelectTrailerCategory(item: string, index: number) {
        if (this.complexes[index]) {
            const category = this.DATA.trailer_categories.origin.find((x: any) => x.name === item)
            if (category) {
                this.complexes[index].trailer_category.selected = category
                this.complexes[index].trailer_category.selectedName = category.name
                this.complexes[index].trailer_category.onerror = ''
                if (this.complexes[index].trailer.selected) {
                    if (this.complexes[index].trailer.selected.category !== category.id) {
                        this.complexes[index].trailer.selected = null
                        this.complexes[index].trailer.selectedName = ''
                        this.complexes[index].trailer.onerror = ''
                    }
                }
                this.complexes[index].trailer.converted = this.DATA.trailers.origin.filter((x: any) => {
                    return x.category === category.id
                }).map((x: any) => `${x.name} (${x.coverage})`)
            }
        }
    }

    public onSelectTractor(item: string, index: number) {
        if (this.complexes[index]) {
            const tractor = this.DATA.tractors.origin.find((x: any) => `${x.name} (${x.uid})` === item)
            if (tractor) {
                this.complexes[index].tractor.selected = tractor
                this.complexes[index].tractor.selectedName = item
                this.complexes[index].tractor.onerror = ''
                this.complexes[index].name = tractor.name
                this.complexes[index].uid = tractor.uid
                const category = this.DATA.tractor_categories.origin.find((x: any) => x.id === tractor.category)
                if (category) {
                    this.complexes[index].tractor_category.selected = category
                    this.complexes[index].tractor_category.selectedName = category.name
                    this.complexes[index].tractor_category.onerror = ''
                }
                if (tractor.coverage) {
                    this.complexes[index].coverage.selectedName = `${tractor.coverage}`
                    this.complexes[index].coverage.onerror = ''
                }
            }
        }
    }

    public onSelectTrailer(item: string, index: number) {
        if (this.complexes[index]) {
            const trailer = this.DATA.trailers.origin.find((x: any) => `${x.name} (${x.coverage})` === item)
            if (trailer) {
                this.complexes[index].trailer.selected = trailer
                this.complexes[index].trailer.selectedName = item
                this.complexes[index].trailer.onerror = ''
                const category = this.DATA.trailer_categories.origin.find((x: any) => x.id === trailer.category)
                if (category) {
                    this.complexes[index].trailer_category.selected = category
                    this.complexes[index].trailer_category.selectedName = category.name
                    this.complexes[index].tractor_category.onerror = ''
                }
                if (trailer.coverage) {
                    this.complexes[index].coverage.selectedName = `${trailer.coverage}`
                    this.complexes[index].coverage.onerror = ''
                }
            }
        }
    }

    public addDriver(index: number) {
        if (this.complexes[index]) {

            this.complexes[index].drivers.push({
                id: null,
                converted: this.DATA.members.origin.filter((x: any) => x.roles.includes(6))
                    .map((x: any) => `${x.user_fullname}`),
                selected: null,
                selectedName: '',
                onerror: '',
                start_time: {
                    selectedName: '00:00',
                    onerror: ''
                },
                end_time: {
                    selectedName: '23:59',
                    onerror: ''
                }
            })
        }
    }

    public deleteDriver(complexIndex: number, index: number) {
        if (this.complexes[complexIndex]) {
            if (this.complexes[complexIndex].drivers[index]) {
                this.complexes[complexIndex].drivers.splice(index, 1)
            }
        }
    }

    public onSelectDriver(item: string, complexIndex: number, index: number) {
        if (this.complexes[complexIndex]) {
            if (this.complexes[complexIndex].drivers[index]) {
                const driver = this.DATA.members.origin.find((x: any) => x.user_fullname === item)
                if (driver) {
                    this.complexes[complexIndex].drivers[index].selected = driver
                    this.complexes[complexIndex].drivers[index].selectedName = item
                    this.complexes[complexIndex].drivers[index].onerror = ''
                }
            }
        }
    }

    public getComplexesCount() {
        return this.complexes.filter((x: ComplexArgs) => x.name !== '').length
    }

    public togglePreparationsDropdown() {
        this.dropdowns.preparations = !this.dropdowns.preparations
    }

    public togglePreparation(index: number) {
        if (this.preparations[index]) {
            this.preparations[index].visible = !this.preparations[index].visible
        }
    }

    public addPreparation() {
        let units = []
        let unit = null
        if (this.DATA.consumption_units.origin.length > 0) {
            units = this.DATA.consumption_units.origin.filter((x: any) => x.preparation_as_area === this.visibility.is_quantity_as_area)
            unit = units.length > 0 ? units[0] : null
        }
        this.preparations.push({
            id: null,
            front_id: '',
            name: '',
            type: {
                selected: null,
                selectedName: '',
                onerror: ''
            },
            preparation: {
                converted: [],
                selected: null,
                selectedName: '',
                onerror: ''
            },
            price: {
                selectedName: '',
                onerror: ''
            },
            quantity: {
                is_area: true,
                title: unit.preparation_as_area ? 'Общая площадь (га)' : `Количество семян (${unit.name.split('/')[1]})`,
                selectedName: `${Math.round(this.fields.total_area / 100) / 100}`,
                onerror: ''
            },
            consumption_rate: {
                selectedName: '',
                onerror: ''
            },
            consumption_unit: {
                converted: units.map((x: any) => x.name),
                selected: unit,
                selectedName: unit ? unit.name : '',
                onerror: ''
            },
            consumption_amount: {
                title: `Необходимый объем препарата (${unit ? unit.name.split('/')[0] : ''})`,
                selectedName: '',
                onerror: ''
            },
            solution_rate: {
                title: unit.preparation_as_area ? 'Расход рабочего раствора (л/га)' : `Расход рабочего раствора (л/${unit.name.split('/')[1]})`,
                selectedName: '',
                onerror: ''
            },
            solution_amount: {
                selectedName: '',
                onerror: ''
            },
            visible: false
        })
    }

    public deletePreparation(index: number) {
        if (this.preparations[index]) {
            if (this.preparations[index].front_id) {
                const i = this.expenses.findIndex((x: ExpensesArgs) => x.front_id === this.preparations[index].front_id)
                if (i !== -1) {
                    this.expenses.splice(i, 1)
                }
            }
            this.preparations.splice(index, 1)
        }
    }

    public getPreparationsCount() {
        return this.preparations.filter((x: PreparationArgs) => x.name !== '').length
    }

    public onSelectPreparationType(item: string, index: number) {
        if (this.preparations[index]) {
            const type = this.DATA.preparations.origin.find((x: any) => x.name === item)
            if (type) {
                const oldType = this.preparations[index].type.selected ? this.preparations[index].type.selected.type : 0
                this.preparations[index].type.selected = type
                this.preparations[index].type.selectedName = item
                this.preparations[index].type.onerror = ''
                if (oldType !== type.type) {
                    this.preparations[index].preparation.converted = type.type === 5 ? this.DATA.storage.converted : type.converted
                    this.preparations[index].preparation.selected = null
                    this.preparations[index].preparation.selectedName = ''
                    this.preparations[index].preparation.onerror = ''
                    this.preparations[index].name = ''
                }
            }
        }
    }

    public onSelectPreparation(item: string, index: number) {
        if (this.preparations[index]) {
            let preparation = null
            if (this.preparations[index].type.selected.type === 5) {
                preparation = this.DATA.storage.origin.find((x: any) => item === `${x.title}`)
            } else {
                const type = this.DATA.preparations.origin.find((x: any) => x.type === this.preparations[index].type.selected.type)
                if (type) {
                    preparation = type.origin.find((x: any) => item === `${x.name}${x.target ? ` (${x.target})` : ''}`)
                }
            }
            if (preparation) {
                if (this.preparations[index].type.selected.type === 5) {
                    this.preparations[index].price.selectedName = `${preparation.cost}`
                    this.preparations[index].price.onerror = ''
                }
                this.preparations[index].preparation.selected = preparation
                this.preparations[index].preparation.selectedName = item
                this.preparations[index].preparation.onerror = ''
                this.preparations[index].name = item
                this.calculatePreparationExpenses(index)
            }
        }
    }

    public calculatePreparationExpenses(index: number) {
        if (this.preparations[index].name === '') return
        this.preparations[index].price.selectedName = this.preparations[index].price.selectedName.replaceAll(',', '.')
        this.preparations[index].quantity.selectedName = this.preparations[index].quantity.selectedName.replaceAll(',', '.')
        this.preparations[index].consumption_rate.selectedName = this.preparations[index].consumption_rate.selectedName.replaceAll(',', '.')
        this.preparations[index].solution_rate.selectedName = this.preparations[index].solution_rate.selectedName.replaceAll(',', '.')
        const price = this.normalizeNumber(this.preparations[index].price.selectedName)
        const quantity = this.normalizeNumber(this.preparations[index].quantity.selectedName)
        const rate = this.normalizeNumber(this.preparations[index].consumption_rate.selectedName)
        const solution = this.normalizeNumber(this.preparations[index].solution_rate.selectedName)
        if (this.preparations[index].front_id) {
            const i = this.expenses.findIndex((x: ExpensesArgs) => x.front_id === this.preparations[index].front_id)
            if (i) {
                this.expenses[i].name.selectedName = this.preparations[index].name
                this.expenses[i].name.onerror = ''
                this.expenses[i].cost.selectedName = `${Math.round(price * quantity * rate)}`
                this.expenses[i].cost.onerror = ''
            }
        } else {
            const newId = `${Math.random().toString().substring(10)}`
            this.preparations[index].front_id = newId
            this.expenses.push({
                id: null,
                front_id: newId,
                name: {
                    selectedName: this.preparations[index].name,
                    onerror: ''
                },
                cost: {
                    selectedName: `${Math.round(price * quantity * rate)}`,
                    onerror: ''
                }
            })
        }
        this.preparations[index].consumption_amount.selectedName = `${rate * quantity}`
        this.preparations[index].solution_amount.selectedName = `${solution * quantity}`
    }

    public getPreprationExpenses(index: number) {
        if (this.preparations[index].front_id) {
            const i = this.expenses.findIndex((x: ExpensesArgs) => x.front_id === this.preparations[index].front_id)
            if (i !== -1) {
                return this.expenses[i].cost.selectedName
            } else return '0'
        } else {
            return '0'
        }
    }

    public normalizeNumber(num: string) {
        return this.validateIsNaN(num) ? 0 : Number(num)
    }

    public validateIsNaN(num: any) {
        return Number.isNaN(Number(num))
    }

    public onSelectConsumptionUnit(item: string, index: number) {
        if (this.preparations[index]) {
            const unit = this.DATA.consumption_units.origin.find((x: any) => x.name === item)
            if (unit) {
                this.preparations[index].quantity.is_area = unit.preparation_as_area
                this.preparations[index].quantity.title = unit.preparation_as_area ?
                    'Общая площадь (га)' : `Количество семян (${item.split('/')[1]})`
                this.preparations[index].quantity.selectedName = unit.preparation_as_area ?
                    `${Math.round(this.fields.total_area / 100) / 100}` : ''
                this.preparations[index].solution_rate.title = unit.preparation_as_area ?
                    'Расход рабочего раствора (л/га)' : `Расход рабочего раствора (л/${item.split('/')[1]})`
                this.preparations[index].quantity.onerror = ''
                this.preparations[index].consumption_amount.title = `Необходимый объем препарата (${unit ? unit.name.split('/')[0] : ''})`
                this.preparations[index].consumption_unit.selected = unit
                this.preparations[index].consumption_unit.selectedName = item
                this.preparations[index].consumption_unit.onerror = ''
            }
        }
    }

    public toggleSeedsDropdown() {
        this.dropdowns.seeds = !this.dropdowns.seeds
    }

    public onSelectSowingUnit(item: string) {
        const unit = this.DATA.sowing_units.origin.find((x: any) => x.name === item)
        if (unit) {
            this.seeds.density_unit.selected = unit
            this.seeds.density_unit.selectedName = unit.name
            this.seeds.density_unit.onerror = ''
            this.seeds.cost.title = `Стоимость семян за ${unit.name.split('/')[0]}`
        }
    }

    public calculateSeedsExpenses() {
        if (this.visibility.seeds) {
            this.seeds.cost.selectedName = this.seeds.cost.selectedName.replaceAll(',', '.')
            this.seeds.area.selectedName = this.seeds.area.selectedName.replaceAll(',', '.')
            this.seeds.density.selectedName = this.seeds.density.selectedName.replaceAll(',', '.')
            const price = this.normalizeNumber(this.seeds.cost.selectedName)
            const quantity = this.normalizeNumber(this.seeds.area.selectedName)
            const rate = this.normalizeNumber(this.seeds.density.selectedName)
            if (this.seeds.front_id) {
                const i = this.expenses.findIndex((x: ExpensesArgs) => x.front_id === this.seeds.front_id)
                if (i !== -1) {
                    this.expenses[i].cost.selectedName = `${Math.round(price * quantity * rate)}`
                }
            } else {
                const newId = `${Math.random().toString().substring(10)}`
                this.seeds.front_id = newId
                this.expenses.push({
                    id: null,
                    front_id: newId,
                    name: {
                        selectedName: 'Семена',
                        onerror: ''
                    },
                    cost: {
                        selectedName: `${Math.round(price * quantity * rate)}`,
                        onerror: ''
                    }
                })
            }
        }
    }

    public getSeedsExpenses() {
        if (this.seeds.front_id) {
            const i = this.expenses.findIndex((x: ExpensesArgs) => x.front_id === this.seeds.front_id)
            if (i !== -1) {
                return this.expenses[i].cost.selectedName
            } else return '0'
        } else return '0'
    }

    public toggleStorageDropdown() {
        this.dropdowns.storage = !this.dropdowns.storage
    }

    public toggleStorage(index: number) {
        if (this.storage[index]) {
            this.storage[index].visible = !this.storage[index].visible
        }
    }

    public addStorage() {
        this.storage.push({
            id: null,
            front_id: '',
            name: '',
            item: {
                selected: null,
                selectedName: '',
                onerror: ''
            },
            amount: {
                selectedName: '',
                onerror: ''
            },
            price: {
                selectedName: '',
                onerror: ''
            },
            visible: false
        })
    }

    public deleteStorage(index: number) {
        if (this.storage[index]) {
            if (this.storage[index].id) {
                const i = this.expenses.findIndex((x: ExpensesArgs) => x.front_id === this.storage[index].front_id)
                if (i !== -1) {
                    this.expenses.splice(i, 1)
                }
            }
            this.storage.splice(index, 1)
        }
    }

    public onSelectStorageItem(item: string, index: number) {
        if (this.storage[index]) {
            const thing = this.DATA.storage.origin.find((x: any) => `${x.title}` === item)
            if (thing) {
                this.storage[index].item.selected = thing
                this.storage[index].item.selectedName = item
                this.storage[index].item.onerror = ''
                this.storage[index].amount.selectedName = `${thing.amount}`
                this.storage[index].price.selectedName = `${thing.cost}`
                this.storage[index].name = item
                this.calculateStorageExpenses(index)
            }
        }
    }

    public calculateStorageExpenses(index: number) {
        if (this.storage[index].name === '') return
        this.storage[index].price.selectedName = this.storage[index].price.selectedName.replaceAll(',', '.')
        this.storage[index].amount.selectedName = this.storage[index].amount.selectedName.replaceAll(',', '.')
        const price = this.normalizeNumber(this.storage[index].price.selectedName)
        const amount = this.normalizeNumber(this.storage[index].amount.selectedName)
        if (this.storage[index].front_id) {
            const i = this.expenses.findIndex((x: ExpensesArgs) => x.front_id === this.storage[index].front_id)
            if (i !== -1) {
                this.expenses[i].cost.selectedName = `${Math.round(price * amount)}`
            }
        } else {
            const newId = `${Math.random().toString().substring(10)}`
            this.storage[index].front_id = newId
            this.expenses.push({
                id: null,
                front_id: newId,
                name: {
                    selectedName: this.storage[index].name,
                    onerror: ''
                },
                cost: {
                    selectedName: `${Math.round(price * amount)}`,
                    onerror: ''
                }
            })
        }
    }

    public getStorageExpenses(index: number) {
        if (this.storage[index].front_id) {
            const i = this.expenses.findIndex((x: ExpensesArgs) => x.front_id === this.storage[index].front_id)
            if (i !== -1) {
                return this.expenses[i].cost.selectedName
            } else return '0'
        } else return '0'
    }

    public getStorageCount() {
        return this.storage.filter((x: StorageArgs) => x.name !== '').length
    }

    public toggleExpensesDropdown() {
        this.dropdowns.expenses = !this.dropdowns.expenses
    }

    public addExpenses() {
        this.expenses.push({
            id: null,
            front_id: '',
            name: {
                selectedName: '',
                onerror: ''
            },
            cost: {
                selectedName: '',
                onerror: ''
            }
        })
    }

    public deleteExpenses(index: number) {
        if (this.expenses[index].front_id === '') {
            this.expenses.splice(index, 1)
        }
    }

    public getExpensesCount() {
        return this.expenses.filter((x: ExpensesArgs) => x.name.selectedName !== '').length
    }

    public checkForErrors() {
        let counter = 0
        if (this.event.selected === null || this.event.selectedName === '') {
            this.event.onerror = 'Выберите мероприятие'
            counter++
        } else if (this.event.selected) {
            if (this.event.selected.name !== this.event.selectedName) {
                this.event.onerror = 'Выберите мероприятие'
                counter++
            }
        }
        if (this.fields.selected.length === 0) {
            this.fields.onerror = 'Выберите поля'
            counter++
        }
        if (this.visibility.complex) {
            this.complexes.forEach((complex: ComplexArgs, index: number) => {
                let checker = false
                if (complex.tractor_category.selected) {
                    if (complex.max_speed.selectedName) {
                        this.complexes[index].max_speed.selectedName = this.complexes[index].max_speed.selectedName.replaceAll(',', '.')
                        if (this.validateIsNaN(this.complexes[index].max_speed.selectedName)) {
                            this.complexes[index].max_speed.onerror = 'Введите число'
                            checker = true
                            counter++
                        }
                    }
                    if (complex.min_speed.selectedName) {
                        this.complexes[index].min_speed.selectedName = this.complexes[index].min_speed.selectedName.replaceAll(',', '.')
                        if (this.validateIsNaN(this.complexes[index].min_speed.selectedName)) {
                            this.complexes[index].min_speed.onerror = 'Введите число'
                            checker = true
                            counter++
                        }
                    }
                    if (complex.treatment_depth.selectedName) {
                        this.complexes[index].treatment_depth.selectedName = this.complexes[index].treatment_depth.selectedName.replaceAll(',', '.')
                        if (this.validateIsNaN(this.complexes[index].treatment_depth.selectedName)) {
                            this.complexes[index].treatment_depth.onerror = 'Введите число'
                            checker = true
                            counter++
                        }
                    }
                    if (complex.coverage.selectedName) {
                        this.complexes[index].coverage.selectedName = this.complexes[index].coverage.selectedName.replaceAll(',', '.')
                        if (this.validateIsNaN(this.complexes[index].coverage.selectedName)) {
                            this.complexes[index].coverage.onerror = 'Введите число'
                            checker = true
                            counter++
                        }
                    }
                    complex.drivers.filter((x: DriverArgs) => x.selected).forEach((x: DriverArgs, j: number) => {
                        const regExp = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/
                        if (!x.start_time.selectedName.match(regExp)) {
                            this.complexes[index].drivers[j].start_time.onerror = 'Введите время'
                            checker = true
                            counter++
                        }
                        if (!x.end_time.selectedName.match(regExp)) {
                            this.complexes[index].drivers[j].end_time.onerror = 'Введите время'
                            checker = true
                            counter++
                        }
                    })
                }
                if (checker) this.dropdowns.complex = true
                this.complexes[index].visible = checker
            })
        }
        if (this.visibility.preparations) {
            this.preparations.forEach((preparation: PreparationArgs, index: number) => {
                let checker = false
                if (preparation.preparation.selected) {
                    if (preparation.price.selectedName) {
                        this.preparations[index].price.selectedName = this.preparations[index].price.selectedName.replaceAll(',', '.')
                        if (this.validateIsNaN(this.preparations[index].price.selectedName)) {
                            this.preparations[index].price.onerror = 'Введите число'
                            checker = true
                            counter++
                        }
                    }
                    if (preparation.quantity.selectedName) {
                        this.preparations[index].quantity.selectedName = this.preparations[index].quantity.selectedName.replaceAll(',', '.')
                        if (this.validateIsNaN(this.preparations[index].quantity.selectedName)) {
                            this.preparations[index].quantity.onerror = 'Введите число'
                            checker = true
                            counter++
                        }
                    }
                    if (preparation.consumption_rate.selectedName) {
                        this.preparations[index].consumption_rate.selectedName = this.preparations[index].consumption_rate.selectedName.replaceAll(',', '.')
                        if (this.validateIsNaN(this.preparations[index].consumption_rate.selectedName)) {
                            this.preparations[index].consumption_rate.onerror = 'Введите число'
                            checker = true
                            counter++
                        }
                    }
                    if (preparation.solution_rate.selectedName) {
                        this.preparations[index].solution_rate.selectedName = this.preparations[index].solution_rate.selectedName.replaceAll(',', '.')
                        if (this.validateIsNaN(this.preparations[index].solution_rate.selectedName)) {
                            this.preparations[index].solution_rate.onerror = 'Введите число'
                            checker = true
                            counter++
                        }
                    }
                }
                if (checker) this.dropdowns.preparations = true
                this.preparations[index].visible = checker
            })
        }
        if (this.visibility.seeds) {
            let checker = false
            if (this.seeds.spacing.selectedName) {
                this.seeds.spacing.selectedName = this.seeds.spacing.selectedName.replaceAll(',', '.')
                if (this.validateIsNaN(this.seeds.spacing.selectedName)) {
                    this.seeds.spacing.onerror = 'Введите число'
                    checker = true
                    counter++
                }
            }
            if (this.seeds.distance.selectedName) {
                this.seeds.distance.selectedName = this.seeds.distance.selectedName.replaceAll(',', '.')
                if (this.validateIsNaN(this.seeds.distance.selectedName)) {
                    this.seeds.distance.onerror = 'Введите число'
                    checker = true
                    counter++
                }
            }
            if (this.seeds.depth.selectedName) {
                this.seeds.depth.selectedName = this.seeds.depth.selectedName.replaceAll(',', '.')
                if (this.validateIsNaN(this.seeds.depth.selectedName)) {
                    this.seeds.depth.onerror = 'Введите число'
                    checker = true
                    counter++
                }
            }
            if (this.seeds.density.selectedName) {
                this.seeds.density.selectedName = this.seeds.density.selectedName.replaceAll(',', '.')
                if (this.validateIsNaN(this.seeds.density.selectedName)) {
                    this.seeds.density.onerror = 'Введите число'
                    checker = true
                    counter++
                }
            }
            if (this.seeds.cost.selectedName) {
                this.seeds.cost.selectedName = this.seeds.cost.selectedName.replaceAll(',', '.')
                if (this.validateIsNaN(this.seeds.cost.selectedName)) {
                    this.seeds.cost.onerror = 'Введите число'
                    checker = true
                    counter++
                }
            }
            if (this.seeds.area.selectedName) {
                this.seeds.area.selectedName = this.seeds.area.selectedName.replaceAll(',', '.')
                if (this.validateIsNaN(this.seeds.area.selectedName)) {
                    this.seeds.area.onerror = 'Введите число'
                    checker = true
                    counter++
                }
            }
            if (checker) this.dropdowns.seeds = true
        }
        this.storage.forEach((x: StorageArgs, index: number) => {
            let checker = false
            if (x.item.selected) {
                this.storage[index].amount.selectedName = this.storage[index].amount.selectedName.replaceAll(',', '.')
                if (this.validateIsNaN(this.storage[index].amount.selectedName)) {
                    checker = true
                    counter++
                }
                this.storage[index].price.selectedName = this.storage[index].price.selectedName.replaceAll(',', '.')
                if (this.validateIsNaN(this.storage[index].price.selectedName)) {
                    checker = true
                    counter++
                }
            }
            if (checker) this.dropdowns.storage = true
            this.storage[index].visible = checker
        })
        this.expenses.forEach((expense: ExpensesArgs, index: number) => {
            let checker = false
            if (expense.name.selectedName) {
                this.expenses[index].cost.selectedName = this.expenses[index].cost.selectedName.replaceAll(',', '.')
                if (this.validateIsNaN(this.expenses[index].cost.selectedName)) {
                    this.expenses[index].cost.onerror = 'Введите число'
                    checker = true
                    counter++
                }
            }
            if (checker) this.dropdowns.expenses = true
        })
        return counter > 0
    }

    public GetPostBody() {
        const members = this.members.filter((x: DefaultArgs) => x.selected).map((x: DefaultArgs) => x.selected.user_id)
        let selectedComplexes = new Array()
        let selectedPreparations = new Array()
        let selectedSeeds = new Array()
        let expenses = new Array()
        let storage = new Array()
        if (this.visibility.complex) {
            selectedComplexes = this.complexes.filter((x: ComplexArgs) => x.tractor.selected).map((x: ComplexArgs) => {
                return {
                    id: x.id || null,
                    tractor_category: x.tractor_category.selected ? x.tractor_category.selected.id : null,
                    tractor: x.tractor.selected ? x.tractor.selected.id : null,
                    trailer_category: x.trailer_category.selected ? x.trailer_category.selected.id : null,
                    trailer: x.trailer.selected ? x.trailer.selected.id : null,
                    min_speed: x.min_speed.selectedName ? Number(x.min_speed.selectedName) : null,
                    max_speed: x.max_speed.selectedName ? Number(x.max_speed.selectedName) : null,
                    depth: x.treatment_depth.selectedName ? Number(x.treatment_depth.selectedName) : null,
                    width: x.coverage.selectedName ? Number(x.coverage.selectedName) : null,
                    drivers: x.drivers.filter((y: DriverArgs) => y.selected).map((y: DriverArgs) => {
                        return {
                            id: x.id,
                            user: y.selected.user_id,
                            start_time: y.start_time.selectedName,
                            end_time: y.end_time.selectedName
                        }
                    })
                }
            })
        }
        if (this.visibility.preparations) {
            selectedPreparations = this.preparations.filter((x: PreparationArgs) => x.preparation.selected).map((x: PreparationArgs) => {
                return {
                    id: x.id || null,
                    front_id: x.front_id,
                    type: x.type.selected.type,
                    preparation: x.type.selected.type !== 5 ? x.preparation.selected.id : null,
                    preparation_from_storage: x.type.selected.type === 5 ? x.preparation.selected.id : null,
                    price: x.price.selectedName ? Number(x.price.selectedName) : null,
                    quantity: x.quantity.selectedName ? Number(x.quantity.selectedName) : null,
                    consumption_rate: x.consumption_rate.selectedName ? Number(x.consumption_rate.selectedName) : null,
                    consumption_rate_measurement: x.consumption_unit.selected ? x.consumption_unit.selected.id : null,
                    volume: x.consumption_amount.selectedName ? Number(x.consumption_amount.selectedName) : null,
                    working_consumption: x.solution_rate.selectedName ? Number(x.solution_rate.selectedName) : null,
                    water_volume: x.solution_amount.selectedName ? Number(x.solution_amount.selectedName) : null
                }
            })
        }
        if (this.visibility.seeds) {
            selectedSeeds = [{
                id: this.seeds.id || null,
                name: this.seeds.name,
                front_id: this.seeds.front_id,
                row_spacing: this.seeds.spacing.selectedName ? Number(this.seeds.spacing.selectedName) : null,
                plant_distance: this.seeds.distance.selectedName ? Number(this.seeds.distance.selectedName) : null,
                sowing_depth: this.seeds.depth.selectedName ? Number(this.seeds.depth.selectedName) : null,
                sowing_density: this.seeds.density.selectedName ? Number(this.seeds.density.selectedName) : null,
                sowing_density_measurement: this.seeds.density_unit.selected ? this.seeds.density_unit.selected.id : null,
                price: this.seeds.cost.selectedName ? Number(this.seeds.cost.selectedName) : null,
                quantity: this.seeds.area.selectedName ? Number(this.seeds.area.selectedName) : null
            }]
        }
        storage = this.storage.filter((x: StorageArgs) => x.item.selected).map((x: StorageArgs) => {
            return {
                front_id: x.front_id,
                product_report: x.item.selected.id,
                amount: Number(x.amount.selectedName),
                cost: Number(x.price.selectedName)
            }
        })
        expenses = this.expenses.filter((x: ExpensesArgs) => x.name.selectedName).map((x: ExpensesArgs) => {
            return {
                front_id_map: x.front_id,
                name: x.name.selectedName,
                price: Number(x.cost.selectedName)
            }
        })
        const body = {
            tech_map: this.TECHMAP_ID,
            name: this.event.selected.name,
            start_date: this.START_DATE || null,
            end_date: this.END_DATE || null,
            has_tractors: this.visibility.complex,
            has_preparations: this.visibility.preparations,
            has_seeds: this.visibility.seeds,
            is_quantity_as_area: this.visibility.is_quantity_as_area,
            users: members,
            roles: [],
            tractors: selectedComplexes,
            preparations: selectedPreparations,
            seeds: selectedSeeds,
            params: [],
            addition_costs: expenses,
            applied_fields: this.fields.selected,
            storage_products: storage,
            comment: this.NOTATION
        }
        return body
    }
}