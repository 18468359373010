export default {
    phrases: {
        no_data: "Деректер жоқ",
        for: "Үшін",
        new: "Жаңалық",
        yes: "Иә",
        no: "Жоқ",
        answered: "Жауап берді",
        not_answered: "Жауап жоқ",
        accept: "Қабылдау",
        decline: "Бас тарту",
        search: "Іздеу",
        t: " t,",
        unknown: "Белгісіз",
        your: "Өзіңіздің жазыңыз",
        error: "Қате"
    },
    not_chosen: "Таңдалмаған",
    errors: {
        nothing_found: "Ештеңе табылған жоқ",
        number: "Телефон нөмірін енгізіңіз",
        data: "Жүйе деректерді қолдамайды",
        no_seasons: "Таңдалған мезгілдері жоқ",
        no_corresponding_season: "Қолайлы маусым жоқ",
        farm_exists: "Мұндай атаумен шаруашылық бар!",
        required_field: "Бұл міндетті өріс.",
        password_min_len: "Құпиясөздің минималды ұзындығы - 6.",
        passwords_dont_match: "Құпия сөздер сәйкес келмейді.",
        incorrect_num: "Телефон нөмірінің форматы жарамсыз",
        incorrect_email: "Пошта форматы жарамсыз",
        password_six: "Құпия сөз 6 таңбадан артық болуы керек",
        field_required_to_fill: "Өрісті толтыру міндетті",
        no_permission: "Сізде кіруге рұқсат жоқ",
        no_description: "Сипаттамасы жоқ",
        no_info: "Ақпарат жоқ",
        type_season: "Маусымды енгізіңіз",
        incorrect_season: "Маусым дұрыс енгізілмеген",
        incorrect_yield: "Өнімділігі енгізу кезіндегі қате",
        already_exists_history: "Осы маусымның тарихы бар",
        field_name: "Өрістің атауын енгізіңіз",
        already_exists_field: "Осындай атаумен ұяшық бар",
        vertices_outside: "Координаттар ұяшықта емес",
        not_enough_data: "Өңдеу үшін деректер жеткіліксіз",
        no_tasks: "Сізде техника бойынша тапсырмалар жоқ",
        no_cadastres: "Ұяшықтар жоқ",
        no_warehouse: "Қойма таңдалмады",
        all_problems: "Барлық мәселелерге қатысты жолдарды толтырыңыз",
        negative_area: "Аудан теріс мән бола алмайды",
        eleven_digit_cadastre: "Кадастр нөмірі 11 цифралық сан болу қажет"
    },
    success: {
        changes_saved: "Өзгерістер сақталды",
        data_changed: "Деректер өзгертілді",
        profile_data_changed: "Профиль деректері сәтті өзгертілді",
        note_deleted: "Естелік жойылды",
        passport_updated: "Паспорт сәтті жаңартылды | Паспорт сәтті жаңартылды. Бөлшектерді көру үшін ұяшықты таңдаңыз",
        deleted: "Жойылған",
        history_added: "Тарих қосылды",
        note_added: " Ескертпе қосылды",
        reply_added: "Жауап қосылды",
        note_updated: "Ескертпе жаңартылды",
        event_deleted: "Іс-шара жойылдған",
        farm_deleted: "Шаруашылық жойылды",
        season_updated: "Маусым сәтті жаңартылды",
        season_added: "Маусым қосылды",
        device_updated: "Агрегат жаңартылды",
        device_added: "Агрегат қосылды",
        car_updated: "Техника жаңартылды",
        car_added: "Техника қосылды",
        account_found: "Аккаунт табылған",
        farm_added: "Шаруашылық сәтті қосылды | Совхоз шаруашылығы сәтті қосылды. Мәліметтерді көру үшін шаруашылықты таңдаңыз",
        successfully_added: " Сәтті қосылған",
        successfully_updated: "Сәтті жаңартылған",
        connection: "Байланыс орнатылған",
        staff_added: "Қызметкер қосылған",
        project_updated: "Жоба сәтті жаңартылған"
    },
    questions: {
        delete: {
            title: "Жоюға сенімдісіз бе?",
            farm: "Сіз шынымен шаруашылығын алып тастайсыз ба?",
            note: "Сіз ескертпені алып тастағыңыз сенімдісіз келе ме?",
            device: "Сіз агрегатты жойғыңыз келетініне сенімдісіз бе?",
            record: "Сіз жазуды алып тастағыңыз сенімдісіз келе ме?",
            history: "Сіз тарихты алып тастағыңыз сенімдісіз келе ме?",
            events: "Барлық таңдалған іс-шараны жойғыңыз келе ме?",
            event: "Бұл іс-шараны жойғыңыз келе ме?",
            staff: "Сіз қызметкерді алып тастағыңыз келетініне сенімдісіз бе?",
            storage: "Сіз қойманы жойғыңыз келетініне сенімдісіз бе?"

        },
        found: {
            variety: "ортты таппадыңыз ба?"
        }
    },
    instructions: {
        smth: "Бір нәрсе жазыңыз",
        note_text: "Ескертпе мәтінін толтырыңыз",
        event_name: "Іс-шараның атауын толтырыңыз",
        event_date: "Іс-шара күнін толтырыңыз",
        event_cost: "Іс-шараның құнын толтырыңыз",
        farm_name: "Шаруашылықтың атауын енгізіңіз",
        width: "Қамту енін енгізіңіз",
        storage_name: "Қойма атауын енгізіңіз",
        machine_class: "Техника классты толтырыңыз",
        machine_model: "Техника моделін толтырыңыз",
        machine_state_num: "Техниканың мемлекеттік нөмірін толтырыңыз",
        whatever: "Толтыру қажет емес",
        machine_name: "Мемлекеттік нөмірді жазыңыз",
        machine_imei: "IMEI жазыңыз",
        machine_capacity: "Бактың сыйымдылығын жазыңыз",
        machine_consumption: "Отын шығыстарынын жазыңыз",
        passport: "Паспортты толтыру",
        number: "Санды енгізіңіз",
        add_title: "Атауын қосыңыз",
        type_amount: "Санын көрсетіңіз",
        type_cost: "Құнын көрсетіңіз",
        type_name: "Атын енгізіңіз",
        type_value: "Мәнін еңгізіңіз",
        finish_editing: "Өзгерістерді сақтау үшін 'Өңдеуді аяқтау' басыңыз",
        processing: "Сіздің сұранысыңыз өңделуде",
        fill_form: "Енгізу нысанын толтырыңыз",
        access_from_admins: "Кіру үшін әкімшіге сұрау жіберіңіз",
        close_n_try_again: "Жобаны жауып, қайталап көріңіз.",
        max_photo: "Ең көбі 10 фотосуретті жүктеуге болады",
        request_processing: "Сіздің сұранысыңыз өңделуде",
        select_crop: "Тапсырмаларды жеңілдету үшін сіз төмендегі сегіз дақылдың біреуін таңдай аласыз немесе өзіңіз жаза аласыз.",
        email: "Email енгізіңіз",
        employee_name: "Қызметкердің атын енгізіңіз",
        employee_surname: "Қызметкердің тегінді енгізіңіз",
        phone: "Телефон нөмірін енгізіңіз",
        upload_file: "Файл жүктеңіз",
        type_or_choose_event: "Іс шараны таңдаңыз",
        subscription: {
            title: "Жазылысты рәсімдеңіз",
            content: "Сіздің жердің көрсеткіштерін талдау үшін жаңа деректерге тапсырыс беріңіз"
        },
        season: {
            title: "Маусымды таңдаңыз",
            content: "Ұяшықтарыңыз үшін маусымды таңдаңыз. Бұл сізге бірнеше маусымда деректерді ұйымдастыруға көмектеседі және жерден деректерді алу үшін маңызды"
        },
        farm_divided: {
            title: "Сіздің шаруашылығыңыз ұяшықтарға бөлінген",
            content: "Ұяшықтарды бөлу сіздің шаруашылығыңызды басқаруды жеңілдетеді, барлық мүмкіндіктер жасуша ішінде болады"
        },
        rating: {
            title: "Сіздің ұяшықтарыңыздың рейтингі",
            content: "Рейтинг қай ұяшықтың жақсы нәтиже көрсететінін білу үшін немесе керісінше немесе қай ұяшықтың ең жақсы екенін және т. б."
        },
        order_agrochem: {
            title: "Шаруашылыққа агрохимиялық талдауға тапсырыс беріңіз",
            content: "Сіз сондай-ақ барлық шаруашылыққа біздің серіктестерімізден агрохимиялық талдау жасай аласыз. Толығырақ бөлімге өту арқылы білуге ​​болады"
        },
        cadastre_edit: {
            edit: {
                1: "Редакциялау үшін ұяшықты таңдаңыз"
            },
            draw: {
                1: "Жаңа ұяшық жасау үшін ұяшық шекараларының шыңдарын белгілеңіз",
                2: "Жаңа ұяшықты өңдеңіз және сақтаңыз"
            },
            delete: {
                1: "Жою үшін ұяшықты таңдаңыз",
                2: "Жоюды аяқтау үшін \"Сақтау\" түймесін басыңыз"
            },
            merge: {
                1: "Біріктіру үшін екі ұяшықты таңдаңыз",
                2: "Біріктіруді аяқтау үшін \"Сақтау\" түймесін басыңыз"
            },
            split: {
                1: "Бөлу үшін сызық сызыңыз",
                2: "Бөлуді аяқтау үшін \"Сақтау\" түймесін басыңыз"
            },
            vertix: {
                1: "Нүктені алып тастау керек ұяшықты таңдаңыз",
                2: "Пернетақтадағы Alt түймесін басып, жою үшін шыңды таңдаңыз"
            },
            vertices: {
                "1": "Нүктелерді жою қажет ұяшықты таңдаңыз",
                "1_5": "Жою нүктелері бар аймақты сызыңыз",
                "2": "Нүктелерді жоюды аяқтау үшін \"Сақтау\" түймесін басыңыз"
            },
            hole: {
                "1": "Тесік жасағыңыз келетін ұяшықты таңдаңыз",
                "1_5": "Ұяшыққа тесік салыңыз",
                "2": "Өзгерісті аяқтау үшін \"Сақтау\" түймесін басыңыз"
            },
            info: {
                1: "Ақпаратты алғыңыз келетін нысанға тінтуір меңзерін апарыңыз."
            }
        }
    },
    selections: {
        all: "Барлығын таңдау",
        title: "Атауын таңдаңыз",
        farm: "Шаруашылықты таңдаңыз",
        field: "Ұяшықтарды таңдаңыз",
        device: "Агрегаттың атауын таңдаңыз",
        class: "Марканы таңдаңыз",
        model: "Үлгіні таңдаңыз",
        season: "Маусымды таңдаңыз",
        season_or_year: "Маусымды таңдаңыз немесе жылды жазыңыз",
        income_type: "Келу түрін таңдаңыз",
        access_type: "Кіру түрін таңдаңыз",
        supplier: "Жеткізушіні енгізіңіз",
        staff: "Қызметкерді таңдаңыз",
        event: "Іс-шараны таңдаңыз",
        expense_type: "Шығыс түрін таңдаңыз",
        storage: "Қойманы таңдаңыз",
        storage_type: "Қойма түрін таңдаңыз",
        equipment: "Техниканы таңдаңыз",
        customer_fullname: "Сатып алушының аты-жөнін енгізіңіз",
        scaling: "Өлшем бірлігін таңдаңыз",
        operation: "Әрекетті таңдаңыз",
        all_fields: "Барлық ұяшықтар",
        all_farms: "Барлық шаруашылық",
        cadastre: "Кадастрды таңдаңыз",
        phenophase: "Фенофазды таңдаңыз",
        problem: "Ескерту мәселесін таңдаңыз",
        date: "Айналып өту күнін таңдаңыз",
        date_title: "Күнді таңдаңыз",
        machine: "Техниканы таңдаңыз",
        device_select: "Агрегатты таңдаңыз",
        category: "Санатты таңдаңыз",
        ready: "Дайын нұсқадан таңдаңыз немесе өзіңіз жазыңыз",
        crop: {
            title: "Дақыл таңдаңыз",
            crops: "Дақылдар",
            options: ["Барлық дақылдар"],
            growing_crop: "Өсірілетін дақылды таңдаңыз",
            or_type: "Дақылды таңдаңыз немесе өзіңіз жазыңыз"
        },
        place: "Өңірді, қаланы, ауылды таңдау"
    },
    telematics: {
        speed_exceed: "Жылдамдық асып кетті!"
    },
    confirm: {
        delete: {
            photo: "Фото біржола жойылады"
        },
        copy: {
            tasks: "Тапсырмаларды басқа ұяшыққа көшіргіңіз келетініне сенімдісіз бе?"
        },
        exit: {
            not_saved: "Сіз шыққыңыз келетініне сенімдісіз бе? Деректер сақталмайды"
        },
        project: {
            accept: "Сіз жобаны қабылдағыңыз келетініне сенімдісіз бе?",
            decline: "Сіз жобадан бас тартқыңыз келетініне сенімдісіз бе?",
            delete: "Сіз жобаны жойғыңыз келетініне сенімдісіз бе?"
        }
    }
}