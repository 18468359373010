



























import { Component, Vue } from 'vue-property-decorator'
import { httpPost } from '@/util/http'
import { apiUrl } from '@/domain/constants'
import { Mutation } from 'vuex-class'
import { MutationNames } from '@/store/types'

@Component
export default class RegisterModal extends Vue {
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    private isShown = false
    private userId = -1
    private email = {
        seletedName: '',
        onerror: ''
    }

    private show(userId: number) {
        this.isShown = true
        this.userId = userId
    }

    private finishRegister() {
        const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/
        if (!this.email.seletedName.match(mailformat)) {
            this.email.onerror = `${this.$i18n.t('messages.instructions.email')}`
            return
        }
        this.setLoadingDataNames('registerModalFinishRegister')
        httpPost({
            url: `${apiUrl}/workspace/activate/fake/user/`,
            isSecureRequest: true,
            detailResponse: true,
            body: {
                role_workspace: this.userId,
                email: this.email.seletedName
            },
            onSuccess: json => {
                if (json.ok) {
                    this.$alert('Регистрация успешно завершена', 'Успешно', 'success').then(() => {
                        this.$emit('reload')
                        this.hide()
                    })
                } else {
                    this.$alert(JSON.stringify(json.json), 'Ошибка', 'error')
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('registerModalFinishRegister')
            }
        })
    }

    private hide() {
        this.isShown = false
    }
}
