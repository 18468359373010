<template>
    <div class="chart">
        <canvas style="width: 200px; height: 200px;" :id="id"/>
    </div>
</template>

<script>
import Chart from "chart.js/auto"

export default {
    props: ['backgroundColor', 'borderWidth', 'borderColor', 'data', 'name', 'fill', 'height', 'id', 'labels', 'title', 'type', 'width', 'extras', 'dataMin', 'dataMax'],
    mounted() {
        const ctx = document.getElementById(this.id).getContext('2d')
        const dataSets = []
        if (this.data && this.dataMin && this.dataMax) {
            dataSets.push({
                label: "Min.",
                data: this.dataMin.map(x => Math.round(x)),
                fill: this.fill,
                backgroundColor: this.backgroundColor ? this.backgroundColor : '#FFC700',
                borderColor: this.borderColor ? this.borderColor : '#FFC700',
                borderWidth: this.borderWidth ? this.borderWidth : 0,
                borderRadius: 0,
                hoverOffset: 5
            }, {
                label: this.title,
                data: this.data.map(x => Math.round(x)),
                fill: this.fill,
                backgroundColor: this.backgroundColor ? this.backgroundColor : '#4B970F',
                borderColor: this.borderColor ? this.borderColor : '#4B970F',
                borderWidth: this.borderWidth ? this.borderWidth : 0,
                borderRadius: 6,
                hoverOffset: 5
            }, {
                label: "Max.",
                data: this.dataMax.map(x => Math.round(x)),
                fill: this.fill,
                backgroundColor: this.backgroundColor ? this.backgroundColor : '#008CDB',
                borderColor: this.borderColor ? this.borderColor : '#008CDB',
                borderWidth: this.borderWidth ? this.borderWidth : 0,
                borderRadius: 0,
                hoverOffset: 5
            })
        } else if (this.data) {
            dataSets.push({
                label: this.title,
                data: this.data.map(x => Math.round(x)),
                fill: this.fill,
                backgroundColor: this.backgroundColor ? this.backgroundColor : '#4B970F',
                borderColor: this.borderColor ? this.borderColor : '#4B970F',
                borderWidth: this.borderWidth ? this.borderWidth : 0,
                borderRadius: 0,
                hoverOffset: 5
            })
        }
        if (this.extras) {
            dataSets.push({
                label: this.title,
                data: this.extras.map(x => Math.round(x)),
                fill: this.fill,
                backgroundColor: this.backgroundColor ? this.backgroundColor : '#D41900',
                borderColor: this.borderColor ? this.borderColor : '#D41900',
                borderWidth: this.borderWidth ? this.borderWidth : 1,
                borderRadius: 0,
                hoverOffset: 20
            })
        }
        const chart = new Chart(ctx, {
            type: this.type ? this.type : 'bar',
            data: {
                labels: this.labels,
                datasets: dataSets
            },
            options: {
                onClick: (e, activeEls) => {
                    const datasetIndex = activeEls[0].datasetIndex
                    const dataIndex = activeEls[0].index
                    const datasetLabel = e.chart.data.datasets[datasetIndex].label
                    const value = e.chart.data.datasets[datasetIndex].data[dataIndex]
                    this.$emit('eventsValue', value)
                }
            }
        })
    }
}
</script>

<style></style>