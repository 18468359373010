














































import { Component, Vue } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import { GetterNames, MutationNames } from '@/store/types'
import { httpGet } from '@/util/http'
import { apiUrl } from '@/domain/constants'
import i18n from '@/i18n'
import RegisterFarm from '@/models/RegisterFarm'
@Component
export default class SearchOnMap extends Vue {
    @Getter(GetterNames.getMapData) private mapData !: any
    @Getter(GetterNames.getRegisterFarms) private getRegisterFarms!: any
    @Mutation(MutationNames.setRegisterFarms) private setRegisterFarms!: any
    @Mutation(MutationNames.setSideboardIsShown) private setSideboardIsShown!: any
    private treeselectDropdown = false
    private selectedRegionName = i18n.t('messages.selections.place')
    private selectedRegion: any = null
    private regions: any = []
    private isDataLoading = false
    private selectedOblast = -1

    public mounted() {
        this.isDataLoading = true
        httpGet({
            url:  `${apiUrl}/oblast/data/`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                if (json.ok) {
                    this.regions = json.json
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.isDataLoading = false
            }
        })
    }

    private searchCadastre() {
        if (this.selectedRegionName === i18n.t('messages.selections.place') || this.selectedRegion === null) return
        this.$ga.event('register farm', 'search region', 'from searchonmap', 1)
        this.$router.push({
            name: 'searchonmap',
            params: {
                regionId: this.selectedRegion.id,
                regionIndex: this.selectedRegion.index_str,
                isDataSaved: 'true'
            }
        })
    }

    private drawField() {
        this.setRegisterFarms({newField: true})
        this.setRegisterFarms({editType: 'draw', editStage: 1})
        this.setSideboardIsShown(false)
    }

    private selectRegion(region: any) {
        this.selectedRegionName = `${region.name}(${region.index_str})`
        this.selectedRegion = region
        this.closeTreeSelectDropdown()
    }

    private toggleOblast(id: any) {
        this.selectedOblast = this.selectedOblast === id ? -1 : id
    }

    private closeTreeSelectDropdown() {
        this.treeselectDropdown = false
        this.selectedOblast = -1
    }
}
