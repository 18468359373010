









import { Component, Vue } from 'vue-property-decorator'
import ContactModel from './ContactModel/Index.vue'
import LoadingModel from './LoadingModel/Index.vue'
import NotificationModel from './NotificationModel/Index.vue'
import PhotosModel from './PhotosModel/Index.vue'
import ErrorHandlerModel from './ErrorHandlerModel/Index.vue'
@Component({
    components: {
        ContactModel,
        LoadingModel,
        NotificationModel,
        PhotosModel,
        ErrorHandlerModel
    }
})
export default class FrontComponents extends Vue {
}
