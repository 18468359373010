

















































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator"
import { Getter, Mutation } from "vuex-class"
import { GetterNames, MutationNames } from "@/store/types"
import { httpDelete, httpGet, httpPost, httpPut } from "@/util/http"
import { apiUrl, apiUrl3 } from "@/domain/constants"
import Chart from '@/components/ChartComponent.vue'
import PlanningDetailTechnic from "@/views/MainPage/HomeView/Sideboards/FarmBoard/TechMapBoard/components/PlanningDashbroadList/PlanningDetailTechnic.vue"
import PlanningAddPreparation from "@/views/MainPage/HomeView/Sideboards/FarmBoard/TechMapBoard/components/PlanningDashbroadList/PlanningAddPreparation.vue"
import PlanningAddTechnic from "@/views/MainPage/HomeView/Sideboards/FarmBoard/TechMapBoard/components/PlanningDashbroadList/PlanningAddTechnic.vue"
import Farm from '@/models/Farm'
import DateRangePicker from "@/components/DateRangePicker.vue"
import TimePicker from '@/components/TimePicker.vue'
import CultureChoose from '@/components/CultureChoose.vue'
import NewTractor from '@/views/MainPage/HomeView/Sideboards/FarmBoard/TelematicsBoard/FarmTelematics/components/NewTractor'
import Folder from '@/views/MainPage/HomeView/Sideboards/FarmBoard/TelematicsBoard/FarmTelematics/components/Folder'
import _ from 'lodash'
import { CultureReproduction } from '@/data/FieldCulture'
import NewSeasonModel from '@/components/cadastreBoard/NewSeasonModel.vue'

@Component({
    components: {
        Chart,
        PlanningDetailTechnic,
        PlanningAddPreparation,
        PlanningAddTechnic,
        CultureChoose,
        TimePicker,
        DateRangePicker,
        NewSeasonModel
    }
})
export default class PlanningAdd extends Vue {
    @Getter(GetterNames.getSidebarModel) private getSidebarModel!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    @Getter(GetterNames.getCurrentSeason) private getCurrentSeason!: any

    @Getter(GetterNames.getPlanningData) private getPlanningData!: any
    @Mutation(MutationNames.setPlanningData) private setPlanningData!: any

    @Getter(GetterNames.getFarmFromRoute) private getFarmFromRoute!: Farm
    @Mutation(MutationNames.setTechnologyEvents) private setTechnologyEvents!: any

    @Getter(GetterNames.getTechnologyEvents) private getTechnologyEvents!: any
    @Mutation(MutationNames.setFarmMapControllers) private setFarmMapControllers!: any
    private CultureReproduction: any = CultureReproduction
    private colors: any = ['#2B4DFF', '#AA27E8', '#F37500', '#06BAF2', '#FFC700', '#5AF5D9', '#7D5EF8', '#FC7BE7', '#ADDE30']
    private page = 1
    private technology = {
        name: '',
        description: null,
        culture: '',
        parent_culture: '',
        sort: '',
        reproduction: '',
        processingType: '1',
        availabilityOfIrrigation: '',
        productivity: '',
        predecessor: '',
        totalArea: 0,
        seeding_rate: '',
        season: {
            id: -1,
            name: ''
        },
        fields: {
            origin: [],
            converted: new Array(),
            selected: new Array(),
            selectedNames: new Array()
        },
        fieldsOfNull: {
            origin: [],
            converted: new Array(),
            selected: new Array(),
            selectedNames: new Array()
        },
        areas: {
            origin: [],
            converted: [{ name: '', area: '', percentage: '' }]
        },

        cultures: {
            origin: [] as any,
            converted: []
        },

        events: {
            origin: new Array(),
            workEvents: new Array(),
            converted: new Array()
        },

        sorts: {
            converted: []
        },

        predecessors: {
            selected: new Array(),
            converted: new Array(),
            string: ''
        }
    }

    private detailsData = {
        id: 0,
        fields: {
            origin: new Array(),
            converted: new Array(),
            selected: new Array(),
            selectedNames: new Array()
        },

        preparations: {
            origin: new Array(),
            converted: new Array(),
            selectedTypeId: -1,
            typePreparations: new Array(),
            typePreparationsConverted: new Array()
        },

        seeds: {
            origin: new Array(),
            converted: new Array(),
            selected: ''
        },

        storages: {
            origin: new Array(),
            converted: new Array(),
            selected: ''
        },

        preparation_units: {
            origin: new Array(),
            main: new Array(),
            converted: new Array()
        },

        consumption_units: {
            origin: new Array(),
            converted: new Array()
        },

        seed_units: {
            origin: new Array(),
            converted: new Array()
        },

        tractor: {
            id: '',
            tractor: '',
            tractorError: '',
            trailer: '',
            trailerName: '',
            trailerError: '',
            trailerCategory: '',
            trailerCategoryError: '',
            performance: '',
            performance_measure: '',
            fuel_consumption_rate_for_ha: '',
            fuel_consumption_rate_for_ha_error: '',
            required_volume: '',
            cost: '',
            average_speed: '',
            average_speed_error: '',
            required_hours: '',
            saved: false,
            workload: '',
            working_hours: '',

            drivers: [{
                driver: {
                    user_id: '',
                    user_fullname: ''
                },
                start_time: '',
                start_time_error: '',
                end_time: '',
                end_time_error: '',
                shift: 1,
                salary_rate_for_ha: '',
                salary_rate_for_ha_error: '',
                cost: ''
            },
            {
                driver: {
                    user_id: '',
                    user_fullname: ''
                },
                start_time: '',
                start_time_error: '',
                end_time: '',
                end_time_error: '',
                shift: 2,
                salary_rate_for_ha: '',
                salary_rate_for_ha_error: '',
                cost: ''
            }]
        },
        preparation: {
            id: 0,
            cultureId: '',
            plan_task: 2,
            type: 1,
            preparation: '',
            preparationError: '',
            preparation_from_storage: '',
            preparation_from_storage_error: '',
            preparation_type: '',
            preparation_type_error: '',
            consumption_rate: '',
            consumption_rate_error: '',
            consumption_rate_measurement: '',
            preparation_unit: '',
            price: '',
            priceError: '',
            quantity: '',
            quantityError: '',
            working_consumption: '',
            working_consumption_error: '',
            volume: '',
            volumeError: '',
            water_volume: '',
            water_volume_error: '',
            cost: '',
            saved: false
        },
        seed: {
            id: 0,
            plan_task: 2,
            type: 1,
            name: '',
            nameError: '',
            selected: '',
            selectedError: '',
            raw_spacing: '',
            raw_spacing_error: '',
            plant_distance: '',
            plant_distance_error: '',
            sowing_depth: '',
            sowing_depth_error: '',
            sowing_density: '',
            sowing_density_error: '',
            sowing_density_measure: '',
            price: '',
            priceError: '',
            cost: 0,
            saved: false,
            cultureId: -1,
            culture: [] as any,
            isSend: false
        },
        spending: {
            id: 0,
            name: '',
            nameError: '',
            price: '',
            priceError: '',
            description: '',
            saved: false
        }
    }

    private technologyDetails = {
        id: 0,
        event_name: '',
        culture_name: '',
        work_type: '',
        start_date: '',
        end_date: '',
        fields: {
            origin: new Array(),
            converted: new Array(),
            selected: new Array(),
            selectedNames: new Array(),
            selectedArea: ''
        },

        tractors: new Array(),
        preparations: new Array(),
        seeds: new Array(),
        spendings: new Array(),
        seedsOnCulture: new Array()
    }
    private currentEventId = 1
    private currentTractorIndex = 0
    private currentPreparationIndex = 0
    private currentSeedIndex = 0
    private currentSpendingIndex = 0
    private endDate = ''
    private currentEventTab = 1
    private twoDrivers = false
    private paramsActive = false
    private showAddTractorModel = false
    private tractorSearch = ''
    private addEventModal = false
    private savedTractorData = false
    private modalInfo = {
        event_name: '',
        event_name_error: '',
        work_type: '',
        start_date: '',
        start_date_error: ''
    }
    private farmSeasons: any = []
    private croprotationCultures: any = {
        current: [],
        origin: [],
        selected: [],
        selectedOrigin: []
    }
    private croprotationNew: any = {
        culture: '',
        parent_culture: '',
        sort: '',
        reproduction: '',
        fields: {
            selected: [],
            selectedNames: []
        },
        area: 0,
        selectMode: false,
        addInfoCulture: false,
        selectedArea: 0
    }

    private agregates: any = []
    private trailers = {
        selected: new Array(),
        origin: new Array(),
        converted: new Array(),
        categories: new Array(),
        categoryTrailers: new Array()
    }
    private drivers = {
        selected: new Array(),
        origin: new Array(),
        converted: new Array()
    }
    private totalTractors = 0
    private checkAll = false
    private folders: any = []
    private choosenTractors: NewTractor[] = []
    private chooseInputValue = ''
    private currentTractorTrailer = ''

    /// change after Nurbergen
    private fetchTechnologyDataJson: any = null

    private newCroprotation: any = {
        culture: new Array() as any,
        fields: new Array() as any,
        productivity: ''
    }

    // after Nurbergen
    private cultureToSend: any = []

    private mounted() {
        this.setFarmMapControllers({ fill: true })
        this.technology.season = this.getCurrentSeason
        this.setPlanningData({ selectedFields: [], season: Number(this.getCurrentSeason.name), page: 1 })
        this.setTechnologyEvents('clear')
        this.fetchSeasons()
        if (this.getCurrentSeason.id !== undefined) {
            this.fetchSeasonFields()
        } // this.fetchSeasonFields()
        // this.setTechnologyEvents(_.cloneDeep(this.technologyDetails))
        // this.fetchPreparations()
    }

    // @Watch('technology.season')
    // private getSeason(val: any) {
    //     if (this.getCurrentSeason.id !== undefined) {
    //         this.fetchSeasonFields()
    //     }
    // }

    private fetchTechnologyData(id: any) {
        this.setLoadingDataNames('fetchTechnology')
        httpGet({
            url: `${apiUrl}/technology/${id}/`,
            isSecureRequest: true,
            onSuccess: json => {
                this.fetchTechnologyDataJson = json
                const newSeason = this.farmSeasons.find((x: any) => x.id === json.technology.group_season)
                if (newSeason) {
                    this.technology.season = newSeason
                }
                this.detailsData.id = json.technology.id
                this.technology.name = json.technology.name
                this.technology.culture = json.technology.culture_name // this.technology.parent_culture = this.technology.culture.split(' ')[0]
                this.technology.description = json.technology.description // this.technology.productivity = (json.technology.productivity).toString()

                json.technology.cultures.forEach((culture: any) => {
                    const currentCulture = this.croprotationCultures.origin.cultures.find((x: any) => `${x.culture} ${x.sort} ${x.reproduction}` === `${culture.culture} ${culture.sort} ${culture.reproduction}`)
                    if (culture.fields.length === currentCulture.fields.length) {
                        const currentCultureIndex = this.croprotationCultures.origin.cultures.findIndex((x: any) => `${x.culture} ${x.sort} ${x.reproduction}` === `${culture.culture} ${x.sort} ${x.reproduction}`)
                        this.croprotationCultures.selected.push(currentCultureIndex)
                    }
                })

                this.croprotationCultures.selectedOrigin = json.technology.cultures.map((x: any) => {
                    return {
                        id: x.id,
                        culture: x.culture,
                        fields: x.fields,
                        productivity: x.productivity,
                        reproduction: x.reproduction,
                        sort: x.sort
                    }
                })

                this.setPlanningData({ selectedFields: json.technology.fields})

                const el: any = this.$refs.cultureChoose
                if (el) {
                    el.setData(this.technology.culture.split(' ')[0], json.technology.culture_name, json.technology.sort, json.technology.reproduction)
                }
                // json.technology.fields.forEach((x: any) => {
                //     const newField = this.technology.fields.origin.find((y: any) => y.id === x)
                //     if (newField) {
                //         this.selectField(newField, false)
                //     }
                // })
                json.plan_tasks.forEach((x: any) => {
                    this.technologyDetails.id = x.id
                    this.technologyDetails.event_name = x.task_name
                    this.technologyDetails.culture_name = x.culture_name
                    this.technologyDetails.work_type = x.work_type
                    const newStartDate = new Date(x.start_date)
                    this.technologyDetails.start_date = newStartDate.toLocaleDateString('ru')
                    const newEndDate = x.end_date ? new Date(x.end_date) : null
                    this.technologyDetails.end_date = newEndDate ? newEndDate.toLocaleDateString('ru') : ''
                    this.paramsActive = true
                    this.setTechnologyEvents(_.cloneDeep(this.technologyDetails))
                })
                // this.fetchPreparations()
                this.countTotalArea()
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('fetchTechnology')
            }
        })
    }

    private fetchEventData(eventId: any) {
        this.getAgregates(eventId)
        this.setLoadingDataNames('fetchTechnology')
        httpGet({
            url: `${apiUrl}/technology/task/${eventId}/`,
            isSecureRequest: true,
            onSuccess: json => {
                if (json.tractors.length > 0 && this.technologyDetails.tractors.length > 0) return

                json.tractors.forEach((x: any, index: any) => {
                    const newTractor = _.cloneDeep(this.detailsData.tractor)
                    newTractor.id = x.id
                    newTractor.average_speed = x.average_speed.toString()
                    newTractor.fuel_consumption_rate_for_ha = x.fuel_consumption_rate_for_ha.toString()
                    newTractor.cost = x.fuel_price ? x.fuel_price.toString() : '0'
                    newTractor.required_volume = x.required_volume.toString()
                    newTractor.working_hours = x.working_hours.toString()
                    newTractor.workload = (Math.round(x.workload / 100) / 100).toString()
                    const trailer = this.trailers.origin.find((y: any) => y.name === x.trailer_name)
                    if (trailer) {
                        newTractor.trailer = trailer
                        newTractor.trailerName = trailer.name
                        newTractor.trailerCategory = trailer.category_name
                        if (index === 0) {
                            this.currentTractorTrailer = trailer.name
                        }
                        // this.trailers.categoryTrailers = trailers.map((x: any) => `${x.name} (${x.coverage})`)
                    }
                    let tractor = this.agregates.find((y: any) => y.tractor === x.tractor)
                    if (!tractor) {
                        tractor = this.agregates.find((y: any) => y.id === x.tractor)
                    }
                    if (tractor) {
                        newTractor.tractor = tractor
                    }
                    x.drivers.forEach((driver: any, driverIndex: any) => {

                        const driverInfo = this.drivers.origin.find((z: any) => z.user_id === driver.driver)
                        if (driverInfo) {
                             newTractor.drivers[driverIndex].driver = driverInfo
                        }

                        newTractor.drivers[driverIndex].start_time = driver.start_time
                        newTractor.drivers[driverIndex].end_time = driver.end_time
                        newTractor.drivers[driverIndex].salary_rate_for_ha = driver.salary_rate_for_ha.toString()
                        newTractor.drivers[driverIndex].start_time = driver.start_time
                    })
                    newTractor.saved = true
                    this.technologyDetails.tractors.push(newTractor)
                    this.savedTractorData = true
                })
                json.preparations.forEach((x: any) => {
                    const newPreparation = _.cloneDeep(this.detailsData.preparation)
                    newPreparation.id = x.id
                    newPreparation.consumption_rate = x.consumption_rate.toString()
                    const consumptionRate = this.detailsData.consumption_units.origin.find((y: any) => y.id === x.consumption_rate)
                    if (consumptionRate) {
                        newPreparation.consumption_rate_measurement = consumptionRate
                    }
                    const preparations = this.detailsData.preparations.origin.find((y: any) => y.type === x.preparation_type)
                    if (preparations) {
                        newPreparation.preparation_type = preparations
                        let preparation = null
                        if (x.preparation_type === 5) {
                            preparation = this.detailsData.storages.origin.find((z: any) => z.id === x.preparation_from_storage)
                            preparation.name = preparation.title
                        } else {
                            preparation = preparations.origin.find((z: any) => z.id === x.preparation)
                        }
                        if (preparation) {
                            newPreparation.preparation = preparation
                            const preparationUnit = this.detailsData.preparation_units.origin.find((z: any) => z.id === x.preparation_measurement)
                            newPreparation.preparation_unit = preparationUnit
                            const consumptionUnit = this.detailsData.consumption_units.origin.find((z: any) => z.id === x.consumption_rate_measurement)
                            newPreparation.consumption_rate_measurement = consumptionUnit
                        }
                    }
                    newPreparation.cost = x.cost.toString()
                    newPreparation.plan_task = x.plan_task
                    newPreparation.preparation_from_storage = x.preparation_from_storage
                    newPreparation.price = x.price.toString()
                    newPreparation.type = x.type
                    newPreparation.volume = x.volume.toString()
                    newPreparation.water_volume = x.water_volume.toString()
                    newPreparation.working_consumption = x.working_consumption.toString()
                    newPreparation.saved = true
                    this.technologyDetails.preparations.push(newPreparation)
                })
                // let seedArr: any = []
                json.seeds.forEach((x: any) => {
                    const newSeed = _.cloneDeep(this.detailsData.seed)
                    newSeed.id = x.id
                    newSeed.cultureId = x.technology_culture
                    newSeed.culture = x.culture
                    newSeed.name = x.name
                    newSeed.plant_distance = x.plant_distance.toString()
                    newSeed.sowing_depth = x.sowing_depth.toString()
                    newSeed.sowing_density = x.sowing_density.toString()
                    const currentMeasure = this.detailsData.seed_units.origin.find((y: any) => y.id === x.sowing_density_measure)

                    if (currentMeasure) {
                       newSeed.sowing_density_measure = currentMeasure
                    }
                    newSeed.price = x.price.toString()
                    newSeed.cost = x.cost,
                    newSeed.saved = true

                    this.technologyDetails.seeds.push(newSeed)
                    // if (this.technologyDetails.seeds.length === this.technology.cultures.origin.length) {
                    //     seedArr = this.technologyDetails.seeds
                    //     const currentCulture = this.technologyDetails.seeds.find((y: any) => y.culture.culture === x.culture.culture)

                    //     if (seedArr.includes(currentCulture)) {
                    //         seedArr.splice(seedArr.indexOf(currentCulture), 1, newSeed)
                    //     } else {
                    //         seedArr.push(currentCulture)
                    //     }
                    //     this.technologyDetails.seeds = seedArr
                    //     return
                    // } else {
                    //     this.technologyDetails.seeds.push(newSeed)
                    //     return
                    // }
                })
                json.spendings.forEach((x: any) => {
                    const newSpending = _.cloneDeep(this.detailsData.spending)
                    newSpending.id = x.id,
                    newSpending.name = x.name,
                    newSpending.price = x.price.toString(),
                    newSpending.description = x.description,
                    newSpending.saved = true
                    this.technologyDetails.spendings.push(newSpending)
                })
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('fetchTechnology')
                // this.technologyDetails.seeds[this.currentSeedIndex].cultureId = this.technology.cultures.origin[this.currentSeedIndex].id
            }
        })
    }

    private seasonNames() {
        return this.farmSeasons.map((x: any) => x.name)
    }

    private updateSeasons(newSeason: any) {
        this.fetchSeasons()
    }

    private fetchSeasons() {
        httpGet({
            url: `${apiUrl}/all/seasons/${this.$route.params.farmId}/`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                if (json.ok) {
                    this.farmSeasons = json.json.reverse()
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.fetchPreparations()
                this.getTractors()
                this.fetchCroprotation()
            }
        })
    }

    private fetchCroprotation() {
        let seasonId = this.farmSeasons[0].id
        const season = this.getPlanningData.season
        if (season) {
            const seasonData = this.farmSeasons.find((x: any) => x.name === season.toString())
            if (seasonData) {
                seasonId = seasonData.id
            }
        }
        httpGet({
            url: `${apiUrl}/technology/get/info/culture/${seasonId}/`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                if (json.ok) {
                    this.croprotationCultures.origin = json.json
                    this.croprotationCultures.origin.cultures = this.croprotationCultures.origin.cultures.sort(this.alphabetically())
                }
            },
            onError: error => {
                console.log(error)
            }
        })
    }

    private alphabetically() {
        return (a: any, b: any) => {
            if (a.culture === b.culture) {
                return 0
            }

            if (a.culture === null) {
                return 1
            }
            if (b.culture === null) {
                return -1
            }

            return a.culture < b.culture ? 1 : -1
        }
    }

    private toggleCroprotationCulture(cultureIndex: any, culture: any) {
        this.croprotationCultures.current = cultureIndex
        //// const selected = this.croprotationCultures.selected
        //// const currentCultureIndex = selected.findIndex((x: any) => x === cultureIndex)
        //// if (currentCultureIndex !== -1) {
        ////     selected.splice(currentCultureIndex, 1)
        //// } else {
        ////     selected.push(cultureIndex)
        //// }

        // for select
        const currentCultureIndex = this.croprotationCultures.selected.findIndex((x: any) => x === cultureIndex)
        if (currentCultureIndex !== -1) {
            this.croprotationCultures.selected.splice(currentCultureIndex, 1)
        } else {
            this.croprotationCultures.selected.push(cultureIndex)
        }

        const indexSelectedOrigin = this.croprotationCultures.selectedOrigin.findIndex((x: any) => `${x.culture} ${x.reproduction} ${x.sort}` === `${culture.culture} ${culture.reproduction} ${culture.sort}`) // reproduction //sort
        if (indexSelectedOrigin !== -1) {
            this.croprotationCultures.selectedOrigin.splice(indexSelectedOrigin, 1)
        } else {
            this.croprotationCultures.selectedOrigin.push(culture)
        }

        const fields = this.croprotationCultures.selectedOrigin.map((x: any) => x.fields)
        const res = fields.flatMap((y: any) => y)

        this.setPlanningData({selectedFields: res, fieldOnMap: false})
    }

    // @Watch('croprotationCultures.selectedOrigin')
    // private changeCultureSelected(val: any) {
    //     const fields = this.croprotationCultures.selectedOrigin.map((x: any) => x.fields)
    //     const res = fields.flatMap((y: any) => y)
    //     this.setPlanningData({selectedFields: res, fieldOnMap: false})
    //     const tempArr = this.getPlanningData.selectedFields
    //     res.forEach((x:any) => {
    //         if (tempArr.includes(x)) {
    //             tempArr.splice(tempArr.indexOf(x), 1)
    //         } else {
    //             tempArr.push(x)
    //         }
    //     })
    //     this.setPlanningData({selectedFields: tempArr, fieldOnMap: false})
    //     this.countTotalArea()
    // }

    private countTotalArea() {
        let currentCultureArea = 0
        if (this.getPlanningData.fields.length > 0) {
            this.getPlanningData.selectedFields.forEach((field: any) => {
                const currentField: any = this.technology.fields.origin.find((x: any) => x.id === field)
                currentCultureArea += Number((currentField.area / 10000).toFixed(2))
            })
        }
        this.technology.totalArea = currentCultureArea
    }

    private onSelectSeason(currentSeason: any) {
        if (this.technology.season === currentSeason) return
        const season = this.farmSeasons.find((x: any) => x.name === currentSeason)
        if (season) {
            this.technology.season = season
            this.setPlanningData({ season: Number(season.name), selectedFields: [] })
            this.fetchCroprotation()

        }
    }

    private changePage(page: any) {
        if (this.page === 1 && page === 1) return
        this.setPlanningData({ page })
        if (this.page === 1) {
            if (this.$route.query.id) {
                this.fetchSeeds(this.croprotationCultures.selectedOrigin[this.currentSeedIndex])
                this.updateTechnologyNew(this.$route.query.id) // this.updateTechnology(this.$route.query.id)
                this.fetchEventData(this.technologyDetails.id)
                return
            }
            if (this.detailsData.id > 0) return this.page = page
            this.createTechnologyNew() // this.createTechnology()
        } else {
            this.page = page
        }
    }

    private changeCurrentEvent(eventId: number) {
        if (eventId === this.technologyDetails.id) return

        this.currentTractorIndex = 0
        this.currentPreparationIndex = 0
        this.currentSeedIndex = 0
        this.currentSpendingIndex = 0

        this.setTechnologyEvents(_.cloneDeep(this.technologyDetails))
        const event = this.getTechnologyEvents.find((x: any) => x.id === eventId)
        if (event) {
            this.paramsActive = true
            this.technologyDetails = _.cloneDeep(event)
            if (event.tractors.length > 0) {
                this.currentTractorTrailer = event.tractors[0].trailer.name
                this.savedTractorData = true
            }
        }

        if (this.$route.query.id) {
            if (event.tractors.length === 0 && event.preparations.length === 0 && event.seeds.length === 0 && event.spendings.length === 0) {
                this.fetchEventData(eventId)
                // this.technologyDetails.seeds[this.currentSeedIndex].cultureId = this.technology.cultures.origin[this.currentSeedIndex].id
            }
        }
        this.fetchSeeds(this.technology.cultures.origin[this.currentSeedIndex])
    }

    private changeCurrentEventTab(tab: any) {
        this.currentEventTab = tab
        if (tab === 3 && this.technologyDetails.seeds.length === 0) {
            for (const culture of this.technology.cultures.origin) {
                this.technologyDetails.seeds.push(_.cloneDeep(this.detailsData.seed))
            }
            this.technologyDetails.seeds[this.currentSeedIndex].cultureId = this.technology.cultures.origin[this.currentSeedIndex].id
            this.fetchSeeds(this.technology.cultures.origin[this.currentSeedIndex])
        }
    }

    @Watch('tractorSearch')
    private searchTractor(search: any) {
        this.folders.forEach((x: any, index: number) => {
            const tractors = x.tractors.filter((y: any) => y.class_name.toLowerCase().includes(search.toLowerCase())
             || y.uid.toLowerCase().includes(search.toLowerCase())
             || y.model_name.toLowerCase().includes(search.toLowerCase()))
            this.folders[index].searchedTractors = tractors
            this.folders[index].collapsed = tractors.length === 0
            this.folders[index].quantity = tractors.length

            if (search === '') {
                this.folders[index].quantity = this.folders[index].tractors.length
                this.folders[index].collapsed = true
            }
        })
    }

    private onChangeInputData(type: string) {
        if (type === 'tractor') {
            this.savedTractorData = false
            this.technologyDetails.tractors[this.currentTractorIndex].saved = false
        } else if (type === 'preparation') {
            this.technologyDetails.preparations[this.currentPreparationIndex].saved = false
        } else if (type === 'seed') {
            this.technologyDetails.seeds[this.currentSeedIndex].saved = false
        } else if (type === 'spending') {
            this.technologyDetails.spendings[this.currentSpendingIndex].saved = false
        }
    }

    // Map Selection

    // @Watch('getPlanningData.selectedFields')
    private onChangeSelectedFields(newVal: any) {
        if (newVal.length === 0) return this.selectField(this.technology.fields.selected[0], true)
        if (newVal.length === this.technology.fields.selected.length) return
        let newId: any = null


        if (this.technology.fields.selected.length > 0) {
            const currentFieldIds = this.technology.fields.selected.map((y: any) => y.id)
            newId = newVal
                .filter((x: any) => !currentFieldIds.includes(x))
                .concat(currentFieldIds.filter(x => !newVal.includes(x)))[0]

            // console.log(newId)
            // console.log(currentFieldIds)
        } else {
            newId = newVal[0]
        }

        if (newId) {
            const newField: any = this.technology.fields.origin.find((x: any) => x.id === newId)
            if (newField) {
                this.selectField(newField, true)
            }
        }
    }

    @Watch('getPlanningData.selectedFields')
    private onChangeSelectedFieldsNew(val: any) {
        this.countTotalArea()
    }

    private setMapSelection() {
        this.setPlanningData({ mapSelection: !this.getPlanningData.mapSelection })
        if (this.getPlanningData.mapSelection) {
            this.setPlanningData({ pointEdit: false })
        }
    }

    // Fetch Fields
    private fetchSeasonFields() {
        httpPost({
            url: `${apiUrl}/crop_rotation/percentages/`,
            isSecureRequest: true,
            detailResponse: true,
            body: {
                group_user: Number(this.$route.params.farmId),
                group_season: this.technology.season.id === undefined ? Number(this.getCurrentSeason.id) : Number(this.technology.season.id)
            },
            onSuccess: json => {
                if (json.ok) {
                    this.technology.areas.converted = []
                    this.technology.fields.origin = json.json.fields
                    this.setPlanningData({fields: json.json.fields})
                    this.technology.fields.converted = json.json.fields.map((x: any) => x.name)

                    this.technology.fieldsOfNull.origin = json.json.fields.filter((x: any) => x.culture === null)
                    this.technology.fieldsOfNull.converted = json.json.fields.filter((x: any) => x.culture === null).map((x: any) => x.name)

                    this.technology.areas.origin = json.json.areas
                    Object.entries(json.json.areas).map((x: any) => {
                        const area: any = {
                            name: x[0],
                            area: Math.round(x[1] / 100) / 100,
                            percentage: Math.round((x[1] / json.json.total_area) * 10000) / 100
                        }
                        this.technology.areas.converted.push(area)
                    })
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                if (this.$route.query.id) {
                    this.fetchTechnologyData(this.$route.query.id)
                }
            }
        })
    }
    private selectAllFields() {
        if (this.technology.fields.origin.length === this.technology.fields.selected.length) {
            this.technology.fields.selected = []
            this.technology.fields.selectedNames = []
            // this.setPlanningData({ selectedFields: [] })
        } else {
            this.technology.fields.selected = _.cloneDeep(this.technology.fields.origin)
            this.technology.fields.selectedNames = this.technology.fields.origin.map((x: any) => `№${x.name}`)
            // this.setPlanningData({ selectedFields: this.technology.fields.selected.map((x: any) => x.id) })
        }
    }

    // private selectAllNullFields() {
    //     if (this.technology.fieldsOfNull.origin.length === this.technology.fields.selected.length) {

    //     }
    // }

    private selectField(field: any, fromMap = false) {
        if (!field) return
        const idIndex = this.technology.fields.selected.findIndex((x: any) => x.id === field.id)
        const nameIndex = this.technology.fields.selectedNames.findIndex((x: string) => x === `№${field.name}`)

        if (idIndex !== -1) {
            this.technology.fields.selected.splice(idIndex, 1)
            this.technology.predecessors.selected.splice(idIndex, 1)
        } else {
            this.technology.fields.selected.push(field)
            this.technology.predecessors.selected.push(field.prev_culture)
        }

        if (nameIndex !== -1) {
            this.technology.fields.selectedNames.splice(nameIndex, 1)
        } else this.technology.fields.selectedNames.push(`№${field.name}`)


        this.technology.predecessor = ([...new Set(this.technology.predecessors.selected)]).join(', ')
        // this.technology.totalArea = (Math.round(this.technology.fields.selected.map((x: any) => x.area).reduce((a, b) => a + b, 0) / 100) / 100).toString()
        if (!fromMap) {
            // this.setPlanningData({ selectedFields: this.technology.fields.selected.map((x: any) => x.id) })
        }
    }

    private fetchSelectedFields() {
        this.detailsData.fields.converted = this.technology.fields.selected.map((x: any) => x.name)
        this.detailsData.fields.origin = this.technology.fields.selected
    }

    // On Select

    private fetchEvents() {
        httpGet({
            url: `${apiUrl}/techmap/static/work_type/`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                if (json.ok) {
                    this.technology.events.origin = json.json
                }
            },
            onError: error => {
                console.log(error)
            }
        })
    }

    private onSelectEvent(event: any) {
        const currentEvent: any = this.technology.events.workEvents.find((x: any) => x.name === event)

        if (currentEvent) {
            this.modalInfo.work_type = currentEvent.work_type
            this.modalInfo.event_name = currentEvent.name
            // this.setTechnologyEvents(_.cloneDeep( this.technologyDetails))
        }
    }

    private onSelectReproduction(item: string) {
        this.technology.reproduction = item
    }
    private onSelectConsumptionMeasure(item: string) {
        const currentMeasure = this.detailsData.consumption_units.origin.find((x: any) => x.name === item)
        if (currentMeasure) {
            this.technologyDetails.preparations[this.currentPreparationIndex].consumption_rate_measurement = currentMeasure
        }
    }
    private onSelectPreparationUnit(item: string) {
        const currentMeasure = this.detailsData.preparation_units.origin.find((x: any) => x.name === item)
        if (currentMeasure) {
            this.technologyDetails.preparations[this.currentPreparationIndex].preparation_unit = currentMeasure
        }
    }
    private onSelectSeedMeasure(item: string) {
        const currentMeasure = this.detailsData.seed_units.origin.find((x: any) => x.name === item)
        if (currentMeasure) {
            this.technologyDetails.seeds[this.currentSeedIndex].sowing_density_measure = currentMeasure
        }
    }


    // Add new Instances

    private addTractor() {
        this.choosenTractors = []
        this.folders.forEach((f: any) => {
            f.tractors.forEach((t: any) => {
                this.technologyDetails.tractors.forEach((tractor: any) => {
                    if (t.id === tractor.tractor.id) {
                        t.checked = true
                        this.choosenTractors.push(tractor.tractor)
                        f.collapsed = false
                    }
                })
                if (!this.technologyDetails.tractors.map((x: any) => x.tractor.id).includes(t.id)) {
                    t.checked = false
                }
            })
        })
        this.showAddTractorModel = true
    }

    private addPreparation() {
        if (this.technologyDetails.preparations.length > 0) {

            this.changeCurrentPreparation(this.currentPreparationIndex + 1)
        }
        this.technologyDetails.preparations.push(_.cloneDeep(this.detailsData.preparation))
    }

    private addSeed() {
        if (this.technologyDetails.seeds.length > 0) {
            this.changeCurrentSeed(this.currentSeedIndex + 1)
        }
        this.technologyDetails.seeds.push(_.cloneDeep(this.detailsData.seed))
    }

    private addSpending() {
        if (this.technologyDetails.spendings.length > 0) {
            this.changeCurrentSpending(this.currentSpendingIndex + 1)
        }
        this.technologyDetails.spendings.push(_.cloneDeep(this.detailsData.spending))
    }


    // Change Current

    private changeCurrentTractor(index: number) {
        if (index === this.currentTractorIndex) return
        // if (this.checkForError()) return
        this.currentTractorIndex = index

        const tractor = this.technologyDetails.tractors[this.currentTractorIndex]
        this.savedTractorData = tractor.saved
        this.currentTractorTrailer = tractor.trailer.name
        this.twoDrivers = tractor.drivers[1].salary_rate_for_ha !== ''

        return

        if (tractor.id) return this.updateEventTractor(this.currentTractorIndex, index)
        this.createEventTractor(this.currentTractorIndex, index)
    }

    private dublicateToAllTractors(currentTractor: any) {
        if (this.checkForError()) return
        this.technologyDetails.tractors.forEach((x: any, index: number) => {
            if (x.id) return

            const tractorInfo = x.tractor
            x = _.cloneDeep(currentTractor)
            x.tractor = tractorInfo
            x.id = ''

            this.technologyDetails.tractors[index] = x
            this.createEventTractor(index, index++)
        })
    }

    private changeCurrentPreparation(index: number) {
        if (index === this.currentPreparationIndex) return
        if (this.checkForErrorPreparations()) return

        const preparation = this.technologyDetails.preparations[this.currentPreparationIndex]
        // console.log(preparation)
        if (preparation.id) return this.updateEventPreparation(this.currentPreparationIndex, index)
        this.createEventPreparation(this.currentPreparationIndex, index)
    }

    private changeCurrentSeed(index: number) {
        if (index === this.currentSeedIndex) return
        if (this.checkForErrorSeed()) return

        const seed = this.technologyDetails.seeds[this.currentSpendingIndex]
        if (seed.id) return this.updateEventSeed(this.currentSpendingIndex, index)
        this.createEventSeed(this.currentSeedIndex, index)
    }

    private changeCurrentSpending(index: number) {
        if (index === this.currentSpendingIndex) return
        if (this.checkForErrorSpending()) return

        const spending = this.technologyDetails.spendings[this.currentSpendingIndex]
        if (spending.id) return this.updateEventSpending(this.currentSpendingIndex, index)
        this.createEventSpending(this.currentSpendingIndex, index)
    }

    private addTractorsToList() {
        this.showAddTractorModel = false
        if (this.choosenTractors.length === 0) return this.technologyDetails.tractors = []
        // console.log(this.choosenTractors)
        this.choosenTractors.forEach((x: any) => {
            if (!this.technologyDetails.tractors.find((y: any) => y.tractor.id === x.id)) {
                const newTractorData = _.cloneDeep(this.detailsData.tractor)
                newTractorData.tractor = x
                this.technologyDetails.tractors.push(newTractorData)
            }
        })
    }


    // Fetch Tractors

    private getTractors() {
        this.setLoadingDataNames('modelGetCars')
        httpGet({
            url: `${apiUrl}/yields/tractor/`,
            isSecureRequest: true,
            onSuccess: json => {
                this.agregates = json
                this.getFoldersData()
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('modelGetCars')
                this.getTrailers()
            }
        })
    }

    private getAgregates(eventId: any) {
        this.setLoadingDataNames('modelGetCars')
        httpGet({
            url: `${apiUrl}/technology/tractor/recommendations/${eventId}/`,
            isSecureRequest: true,
            onSuccess: json => {
                this.agregates = json
                this.getFoldersData()
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('modelGetCars')
                this.getTrailers()
            }
        })
    }
    private getTrailers() {
        httpGet({
            url: `${apiUrl}/yields/trailer/`,
            isSecureRequest: true,
            onSuccess: json => {
                this.trailers.origin = json
                this.trailers.converted = json.map((x: any) => `${x.name} (${x.coverage})`)
                this.trailers.categoryTrailers = json.map((x: any) => `${x.name} (${x.coverage})`)
                json.forEach((x: any) => {
                    if (x.category_name !== null && x.category !== null) {
                        this.trailers.categories.push(x.category_name)
                    }
                })
                // this.trailers.categories = json.map((x: any) => x.category_name)
                // this.trailers.categories = [...new Set(json.map((x: any) => x.category_name))] // Nurbergens
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
               this.getDrivers()
            }
        })
    }

    private getDrivers() {
        httpGet({
            url: `${apiUrl}/workspace/get/employee/by/workspace/`,
            isSecureRequest: true,
            onSuccess: json => {
                this.drivers.origin = json
                this.drivers.converted = json.map((x: any) => x.user_fullname)
            },
            onError: error => {
                console.log(error)
            }
        })
    }

    private getFoldersData() {
        this.folders = []
        this.totalTractors = 0
        this.agregates.forEach((car: any) => {
            const currentFolder = this.folders.find((folder: any) => folder.name === car.category)
            if (currentFolder) {
                currentFolder.tractors.push({id: car.tractor, class_name: car.class_name, category: car.category, uid: car.uid, model_name: car.model_name, plan_tasks: car.plan_tasks, checked: false, coverage: car.coverage})
                currentFolder.quantity++
            } else {
                const tractors: any = []
                tractors.push({id: car.tractor, class_name: car.class_name, category: car.category, uid: car.uid, model_name: car.model_name, plan_tasks: car.plan_tasks, checked: false, coverage: car.coverage})
                const newFolder = new Folder(car.category, tractors, tractors.length)
                this.folders.push(newFolder)
            }
            this.totalTractors++
        })

    }
    private chooseTractor(folder: number, tractor: number, fromFolder?: boolean) {
        const currentTractor = this.folders[folder].tractors[tractor]
        const currentFolder = this.folders[folder]
        if (currentTractor.checked) {
            currentTractor.checked = false
            fromFolder ? currentFolder.checked = true : currentFolder.checked = false
            this.choosenTractors = this.choosenTractors.filter(item => item.uid !== currentTractor.uid)
        } else {
            currentTractor.checked = true
            if (!fromFolder) {
                currentFolder.checked = !currentFolder.tractors.find((item: any) => !item.checked)
            }
            if (!this.choosenTractors.find(item => item.uid === currentTractor.uid)) {
                this.choosenTractors.push(currentTractor)
            }
        }
        if (this.choosenTractors.length > 1) {
            this.chooseInputValue = `${this.choosenTractors[0].name} +${this.choosenTractors.length - 1}`
        } else if (this.choosenTractors.length === 1) {
            this.chooseInputValue = String(this.choosenTractors[0].name)
        } else {
            this.chooseInputValue = ''
        }
        // console.log(currentTractor)
        // console.log(this.folders)
    }
    private chooseFolder(index: number) {
        this.folders[index].checked = !this.folders[index].checked
        this.folders[index].tractors.forEach((tractor: any, idx: any) => {
            this.folders[index].checked ? tractor.checked = false : tractor.checked = true
            this.chooseTractor(index, idx, this.folders[index].checked)
        })
    }
    private chooseAll() {
        this.checkAll = !this.checkAll
        this.folders.forEach((folder: any, index: any) => {
            folder.checked = this.checkAll
            folder.tractors.forEach((tractor: any, idx: any) => {
                tractor.checked = !this.checkAll
                this.chooseTractor(index, idx)
            })
        })
    }

    private onselectTrailer(item: any) {
        const trailer = this.trailers.origin.find((x: any) => `${x.name} (${x.coverage})` === item)
        if (trailer) {
            this.technologyDetails.tractors[this.currentTractorIndex].trailer = trailer
            this.currentTractorTrailer = trailer.name
            // this.trailers.selected = trailer
            this.onChangeInputData('tractor')
        }
    }
    private onselectTrailerCategory(item: any) {
        this.technologyDetails.tractors[this.currentTractorIndex].trailer = ''
        this.technologyDetails.tractors[this.currentTractorIndex].trailerName = ''
        this.currentTractorTrailer = ''
        this.trailers.selected = []
        const trailers = this.trailers.origin.filter((x: any) => x.category_name === item)
        if (trailers) {
            this.technologyDetails.tractors[this.currentTractorIndex].trailerCategory = item
            this.trailers.categoryTrailers = trailers.map((x: any) => `${x.name} (${x.coverage})`)
            this.onChangeInputData('tractor')
            // this.technologyDetails.tractors[this.currentTractorIndex].trailer = trailer
            // this.trailers.selected = trailer
        }
    }

    private onSelectDriver(driverName: any, shift: number) {
        const driver = this.drivers.origin.find((x: any) => x.user_fullname === driverName)
        if (driver) {
            // console.log(driver)
            this.technologyDetails.tractors[this.currentTractorIndex].drivers[shift].driver = driver
        }
        this.onChangeInputData('tractor')
    }

    private getRequiredFuelVolume() {
        const tractor = this.technologyDetails.tractors[this.currentTractorIndex]

        let cost = 0
        cost = Math.floor(Number(this.technology.totalArea) * Number(tractor.fuel_consumption_rate_for_ha))
        tractor.required_volume = cost || 0
        return cost.toString()
    }

    private getPerformanceCost() {
        const tractor = this.technologyDetails.tractors[this.currentTractorIndex]
        let cost = 0
        this.technologyDetails.tractors[this.currentTractorIndex].required_volume = Math.floor(Number(this.technology.totalArea) * Number(tractor.fuel_consumption_rate_for_ha))
        cost = tractor.required_volume * Number(tractor.cost) // cost = tractor.fuel_consumption_rate_for_ha * tractor.workload * tractor.cost
        tractor.performance_measure = cost
        return this.formatExpences(cost)
    }

    private changeDriverCount(value: boolean) {
        this.twoDrivers = value
    }


    // Preparations
    private fetchPreparations() {
        httpGet({
            url: `${apiUrl}/techmap/get/preparations/by/culture/?culture=${this.technology.culture}`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                if (json.ok) {
                    this.detailsData.preparations.origin = json.json.preparations_list.map((x: any) => {
                        return {
                            type: x.type,
                            name: x.type_name,
                            origin: x.preparations,
                            converted: x.preparations.map((y: any) => y.name)
                        }
                    })
                    this.detailsData.preparations.converted = json.json.preparations_list.map((x: any) => x.type_name)
                    this.detailsData.consumption_units.origin = json.json.measures
                    this.detailsData.consumption_units.converted = json.json.measures.map((x: any) => x.name)
                    this.detailsData.preparation_units.origin = json.json.preperation_measurement.flatMap((x: any) => x.types)
                    this.detailsData.preparation_units.main = json.json.preperation_measurement.main
                    this.detailsData.preparation_units.converted = json.json.preperation_measurement.flatMap((x: any) => x.types.map((y: any) => y.name))
                    this.detailsData.preparation.consumption_rate_measurement = json.json.measures[1]
                    this.detailsData.preparation.preparation_unit = json.json.preperation_measurement[0].types[0]
                    // console.log(this.detailsData)
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.fetchStorage()
            }
        })
    }

    private fetchStorage() {
        httpGet({
            url: `${apiUrl3}/techmap/storage/items/by/group_user/?group_user=${this.$route.params.farmId}`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                if (json.ok) {
                    this.detailsData.storages.origin = json.json.flatMap((x: any) => x.current_products)
                    this.detailsData.storages.converted = json.json.flatMap((x: any) => x.current_products.map((y: any) => y.title))
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                // this.fetchSeeds()
            }
        })
    }

    private onSelectPreparationType(item: string) {
        const preparation = this.detailsData.preparations.origin.find((x: any) => x.name === item)
        if (preparation) {
            this.detailsData.preparations.selectedTypeId = preparation.type
            this.technologyDetails.preparations[this.currentPreparationIndex].preparation_type = preparation
            this.technologyDetails.preparations[this.currentPreparationIndex].preparation = ''

            if (preparation.type === 5) {
                this.detailsData.preparations.typePreparations = this.detailsData.storages.origin
                this.detailsData.preparations.typePreparationsConverted = this.detailsData.storages.converted
            } else {
                this.detailsData.preparations.typePreparations = preparation.origin
                this.detailsData.preparations.typePreparationsConverted = preparation.converted
            }

        }
    }

    private onSelectPreparation(item: string) {
        let preparation: any = {}

        if (this.technologyDetails.preparations[this.currentPreparationIndex].preparation_type.type === 5) {
            const storage = this.detailsData.storages.origin.find((x: any) => item === `${x.title}`)
            this.technologyDetails.preparations[this.currentPreparationIndex].quantity = storage.amount.toString()
            this.technologyDetails.preparations[this.currentPreparationIndex].price = storage.cost.toString()
            preparation.name = storage.title
            preparation.id = storage.id

            const newUnit = this.detailsData.preparation_units.origin.find((x: any) => x.name === storage.unit.split('.')[0])
            // console.log(newUnit)
            if (newUnit) {
                this.technologyDetails.preparations[this.currentPreparationIndex].preparation_unit = newUnit
            }

        } else {
            preparation = this.detailsData.preparations.typePreparations.find((x: any) => x.name === item)
            this.technologyDetails.preparations[this.currentPreparationIndex].quantity = '0'
            this.technologyDetails.preparations[this.currentPreparationIndex].price = '0'
        }

        if (preparation) {
            this.technologyDetails.preparations[this.currentPreparationIndex].preparation = preparation
        }
    }

    private getVolume(preparation: any) {
        let volume = 0
        volume = Math.floor(Number(this.technology.totalArea) * Number(preparation.consumption_rate))
        this.technologyDetails.preparations[this.currentPreparationIndex].volume = volume
        return this.formatExpences(volume)
    }

    private getVolumeMeasure() {
        if (!this.technologyDetails.preparations[this.currentPreparationIndex].consumption_rate_measurement) return
        return this.technologyDetails.preparations[this.currentPreparationIndex].consumption_rate_measurement.name.split('/')[0]
    }

    private getWaterVolume(preparation: any) {
        let volume = 0
        volume = Math.floor(Number(this.technology.totalArea) * Number(preparation.working_consumption))
        this.technologyDetails.preparations[this.currentPreparationIndex].water_volume = volume
        return this.formatExpences(volume)
    }

    private getPreparationTotal() {
        const preparation = this.technologyDetails.preparations[this.currentPreparationIndex]
        if (preparation) {
            preparation.cost = (preparation.volume || 0) * (preparation.price || 0) //  (1000 / preparation.preparation_unit.amount)
            // if (preparation.preparation_unit.name.split('').includes('л')) {

            // } else {
            //     preparation.cost = (preparation.volume || 0) * (preparation.price || 0) / (1000 / preparation.preparation_unit.amount)
            // }
            return this.formatExpences(preparation.cost)
        }
        return 0
    }


    // Seeds
    private fetchSeeds(culture: any) {
        httpGet({
            url: `${apiUrl}/techmap/get/seeds/by/culture/?culture=${culture.culture}`, // this.technology.parent_culture
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                if (json.ok) {
                    this.detailsData.seed_units.origin = json.json.measures
                    this.detailsData.seed_units.converted = json.json.measures.map((x: any) => x.name)
                    this.detailsData.seed.sowing_density_measure = json.json.measures[0]
                }
            },
            onError: error => {
                console.log(error)
            }
        })
    }
    private getReproduction() {
        return this.CultureReproduction.map((x: any) => x.name)
    }

    private getSeedCost() {
        if (this.technologyDetails.seeds.length === 0) return 0
        const seed = this.technologyDetails.seeds[this.currentSeedIndex]
        const total = (Number(seed.sowing_density) * Number(seed.price) * Number(this.technology.totalArea) / 1000).toFixed(0)
        this.technologyDetails.seeds[this.currentSeedIndex].total = total

        return this.formatExpences(Number(total))
    }

    private openNewEventModal(workTypeIndex: number) {
        this.setTechnologyEvents(_.cloneDeep(this.technologyDetails))
        this.modalInfo = {
            event_name: '',
            event_name_error: '',
            work_type: '',
            start_date: '',
            start_date_error: ''
        }
        const datePicker: any = this.$refs.daterangepicker as any
        if (datePicker) {
            datePicker.discard()
        }
        this.technology.events.workEvents = this.technology.events.origin[workTypeIndex].tasks
        this.technology.events.converted = this.technology.events.origin[workTypeIndex].tasks.map((x: any) => x.name)
        this.addEventModal = true
    }

    private setNewDataRange(startDate: any) {
        if (startDate) {
            const start = new Date(startDate)
            this.technologyDetails.start_date = start.toLocaleDateString("ru")
            this.modalInfo.start_date = start.toLocaleDateString("ru")
        }
    }

    private addNewEvent() {
        if (this.checkForErrorEvent()) return
        this.technologyDetails = _.cloneDeep(this.getTechnologyEvents[0])
        this.technologyDetails.id = this.currentEventId
        this.technologyDetails.event_name = this.modalInfo.event_name
        this.technologyDetails.work_type = this.modalInfo.work_type
        this.technologyDetails.start_date = this.modalInfo.start_date
        this.createTechnologyEvent()
        this.addEventModal = false
        for (const culture of this.technology.cultures.origin) {
            const newSeed = _.cloneDeep(this.detailsData.seed)
            newSeed.cultureId = culture.id
            newSeed.culture = culture
            this.technologyDetails.seeds.push(newSeed)
        }
        // // this.technologyDetails.seeds[this.currentSeedIndex].cultureId = this.technology.cultures.origin[this.currentSeedIndex].id
        this.fetchSeeds(this.technology.cultures.origin[this.currentSeedIndex])
    }

    private switchEvent(id: number) {
        this.setTechnologyEvents(_.cloneDeep(this.technologyDetails))
        this.technologyDetails = _.cloneDeep(this.getTechnologyEvents.find((x: any) => x.id === id))
        this.detailsData.fields.selected = this.technologyDetails.fields.selected
        this.detailsData.fields.selectedNames = this.technologyDetails.fields.selectedNames
        this.currentEventId = id
    }


    // Check For Errors

    private formatExpences(expense: number) {
        return expense ? expense.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + '' : '0'
    }

    private checkFields() {
        if (this.technology.name === '') return false
        // if (this.technology.fields.selected.length === 0) return false
        const el: any = this.$refs.cultureChoose
        if (el) {
            const result = el.getData()
            this.croprotationNew.parent_culture = result.parent_culture || ''
            this.croprotationNew.culture = result.culture || ''
            this.croprotationNew.sort = result.sort || ''
            this.croprotationNew.reproduction = result.reproduction || ''
            // this.setPlanningData({ culture: result.culture })
        }
        // if (this.$route.query.id) {
        //     console.log(this.fetchTechnologyDataJson, 'vds')
        //     console.log(this.getPlanningData.selectedFields)
        //     if (this.fetchTechnologyDataJson.technology.cultures.length === this.croprotationCultures.selectedOrigin.length) return false
        //     if (this.fetchTechnologyDataJson.technology.fields.length === this.getPlanningData.selectedFields.length) return false
        // }
        return true
    }

    private checkForErrorEvent() {
        this.modalInfo.event_name_error = ''
        this.modalInfo.start_date_error = ''
        let counter = 0
        if (!this.modalInfo.event_name) { this.modalInfo.event_name_error = 'Введите значение'; counter++ }
        if (!this.modalInfo.start_date) { this.modalInfo.start_date_error = 'Введите значение'; counter++ }

        return counter > 0
    }

    private checkForError() {
        let counter = 0

        const tractorIndex = this.currentTractorIndex

        if (!this.technologyDetails.tractors[tractorIndex]) return true
        if (!this.technologyDetails.tractors[tractorIndex].tractor.coverage) {
            if (!this.technologyDetails.tractors[tractorIndex].trailerCategory) {
                this.technologyDetails.tractors[tractorIndex].trailerCategoryError = 'Выберите категорию'
                counter++
            } else {
                this.technologyDetails.tractors[tractorIndex].trailerCategoryError = ''
            }

            if (!this.currentTractorTrailer) {
                this.technologyDetails.tractors[tractorIndex].trailerError = 'Выберите агрегат'
                counter++
            } else {
                this.technologyDetails.tractors[tractorIndex].trailerError = ''
            }
        }
        if (this.technologyDetails.tractors[tractorIndex].average_speed === '') {
            this.technologyDetails.tractors[tractorIndex].average_speed_error = 'Введите значение'
            counter++
        } else {
            this.technologyDetails.tractors[tractorIndex].average_speed_error = ''
        }

        // if (this.technologyDetails.tractors[tractorIndex].drivers[0].salary_rate_for_ha === '') {
        //     this.technologyDetails.tractors[tractorIndex].drivers[0].salary_rate_for_ha_error = 'Введите значение'
        //     counter++
        // } else {
        //     this.technologyDetails.tractors[tractorIndex].drivers[0].salary_rate_for_ha_error = ''
        // }


        this.technologyDetails.tractors[tractorIndex].drivers.forEach((driver: any, index: number) => {
            if (index === 1 && !this.twoDrivers) return

            const regExp = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/
            if (!driver.start_time.match(regExp)) {
                this.technologyDetails.tractors[tractorIndex].drivers[index].start_time_error = 'Введите время'
                counter++
            } else {
                this.technologyDetails.tractors[tractorIndex].drivers[index].start_time_error = ''
            }

            if (!driver.end_time.match(regExp)) {
                this.technologyDetails.tractors[tractorIndex].drivers[index].end_time_error = 'Введите время'
                counter++
            } else {
                this.technologyDetails.tractors[tractorIndex].drivers[index].end_time_error = ''
            }
        })
        return counter > 0
    }

    private checkForErrorPreparations() {
        const preparation = this.technologyDetails.preparations[this.currentPreparationIndex]
        preparation.preparationError = ''
        preparation.preparation_type_error = ''
        preparation.consumption_rate_error = ''
        preparation.priceError = ''
        preparation.quantityError = ''
        preparation.working_consumption_error = ''
        preparation.volumeError = ''
        preparation.water_volume_error = ''
        let counter = 0

        if (!preparation.preparation) { preparation.preparationError = 'Введите значение'; counter++ }
        if (!preparation.preparation_type) { preparation.preparation_type_error = 'Введите значение'; counter++ }

        return counter > 0
    }

    private checkForErrorSeed() {
        const seed = this.technologyDetails.seeds[this.currentSeedIndex]
        seed.sowing_density_error = ''
        seed.sowing_depth_error = ''
        seed.plant_distance_error = ''
        seed.priceError = ''
        let counter = 0

        if (!seed.sowing_density) { seed.sowing_density_error = 'Введите значение'; counter++ }
        if (!seed.sowing_depth) { seed.sowing_depth_error = 'Введите значение'; counter++ }
        if (!seed.plant_distance) { seed.plant_distance_error = 'Введите значение'; counter++ }
        if (!seed.price) { seed.priceError = 'Введите значение'; counter++ }

        return counter > 0
    }

    private checkForErrorSpending() {
        const spending = this.technologyDetails.spendings[this.currentSpendingIndex]
        spending.nameError = ''
        spending.priceError = ''
        let counter = 0

        if (!spending.name) { spending.nameError = 'Введите значение'; counter++ }
        if (!spending.price) { spending.priceError = 'Введите значение'; counter++ }

        return counter > 0
    }


    // API Requests - Create

    private createTechnology() {
        httpPost({
            url: `${apiUrl}/technology/create/`,
            isSecureRequest: true,
            detailResponse: true,
            body: {
                name: this.technology.name,
                description: this.technology.description,
                culture_name: this.technology.culture,
                sort: this.technology.sort || null,
                productivity: this.technology.productivity || 0,
                predecessor: this.technology.predecessor,
                fields: this.technology.fields.selected.map((x: any) => x.id),
                group_user: this.$route.params.farmId,
                group_season: this.technology.season.id
            },
            onSuccess: json => {
                if (json.ok) {
                    this.detailsData.id = json.json.id
                    this.page = 2
                    this.fetchSelectedFields()
                    this.fetchEvents()
                } else {
                    this.$alert('Поле ' + json.json.non_field_errors + '  уже добавлено', 'Ошибка', 'warning')
                }
            },
            onError: error => {
                console.log(error)
            }
        })
    }

    private createTechnologyEvent() {
        this.fetchPreparations()
        // console.log(this.technology)
        httpPost({
            url: `${apiUrl}/technology/task/create/`,
            isSecureRequest: true,
            detailResponse: true,
            body: {
                task_name: this.technologyDetails.event_name,
                technology: this.detailsData.id,
                group_user: this.$route.params.farmId,
                group_season: this.getCurrentSeason.id,
                start_date: this.technologyDetails.start_date,
                work_type: this.technologyDetails.work_type
            }, // culture_name: this.technology.culture, // fields: this.technology.fields.selected.map((x: any) => x.id),
            onSuccess: json => {
                if (json.ok) {
                    this.technologyDetails.id = json.json.id
                    // console.log(this.technologyDetails, 'this.technologyDetails')
                    this.setTechnologyEvents(_.cloneDeep(this.technologyDetails))
                    this.paramsActive = true
                    const datePicker: any = this.$refs.daterangepicker as any
                    if (datePicker) {
                        datePicker.discard()
                    }
                    this.getAgregates(json.json.id)
                } else {
                    this.$alert('Ошибка сервера', 'Ошибка', 'error')
                }
            },
            onError: error => {
                console.log(error)
            }
        })
    }

    private createEventTractor(index: any, newIndex: number) {
        // console.log(this.technology, 'eee')
        if (index === newIndex) {
            if (this.checkForError()) return
        }
        const tractor = this.technologyDetails.tractors[index]
        if (tractor.id) return this.updateEventTractor(index, newIndex)

        const drivers = [{
            driver: tractor.drivers[0].driver.user_id,
            start_time: tractor.drivers[0].start_time,
            end_time: tractor.drivers[0].end_time,
            shift: 1,
            salary_rate_for_ha: tractor.drivers[0].salary_rate_for_ha || 0,
            cost: 0
        }]
        if (this.twoDrivers) {
            drivers.push({
                driver: tractor.drivers[1].driver.user_id,
                start_time: tractor.drivers[1].start_time,
                end_time: tractor.drivers[1].end_time,
                shift: 2,
                salary_rate_for_ha: tractor.drivers[1].salary_rate_for_ha || 0,
                cost: 0
            })
        }

        httpPost({
            url: `${apiUrl}/technology/task/tractor/create/`,
            isSecureRequest: true,
            detailResponse: true,
            body: {
                tractor: {
                    plan_task: this.technologyDetails.id,
                    tractors: [tractor.tractor.id],
                    trailer: tractor.trailer ? tractor.trailer.id : null,
                    average_speed: Number(tractor.average_speed),
                    fuel_consumption_rate_for_ha: tractor.fuel_consumption_rate_for_ha || 0,
                    required_volume: tractor.required_volume || 0,
                    cost: tractor.performance_measure || 0,
                    fuel_price: tractor.cost || 0
                },
                drivers
            },
            onSuccess: json => {
                if (json.ok) {
                    this.savedTractorData = true
                    tractor.saved = true
                    tractor.id = json.json.tractor[0].id
                    tractor.workload = Math.round(json.json.tractor.workload / 100) / 100
                    // tractor.

                    this.currentTractorIndex = newIndex
                    const newDate = json.json.calculate.end_date
                    if (newDate) {
                        const date = new Date(newDate)
                        this.technologyDetails.end_date = date.toLocaleDateString('ru')
                    }
                    Object.entries(json.json.calculate.tractor_stat).forEach((x: any) => {
                        const tractorData = this.technologyDetails.tractors.find((y: any) => y.id === Number(x[0]))
                        if (tractorData) {
                            tractorData.workload = Math.round(x[1].workload / 100) / 100
                            tractorData.working_hours = x[1].working_hours
                        }
                    })

                } else {
                    this.$alert('Ошибка сервера', 'Ошибка', 'error')
                }
            },
            onError: error => {
                console.log(error)
            }
        })
    }

    private createEventPreparation(index: any, newIndex: number) {
        if (this.technologyDetails.tractors.length > 0 && this.technologyDetails.tractors[0].workload === "") {
            this.$alert('Сперва создайте технику', 'Сперва создайте технику')
            return
        }
        if (index === newIndex) {
            if (this.checkForErrorPreparations()) return
        }

        const preparation = this.technologyDetails.preparations[index]
        if (preparation.id) return this.updateEventPreparation(index, newIndex)

        httpPost({
            url: `${apiUrl}/technology/task/preparation/create/`,
            isSecureRequest: true,
            detailResponse: true,
            body: {
                plan_task: this.technologyDetails.id,
                type: 1,
                preparation: preparation.preparation_type.type !== 5 ? preparation.preparation.id : null,
                preparation_from_storage: preparation.preparation_type.type === 5 ? preparation.preparation.id : null,
                preparation_type: preparation.preparation_type.type,
                consumption_rate: Number(preparation.consumption_rate),
                consumption_rate_measurement: preparation.consumption_rate_measurement.id,
                preparation_measurement: null, // preparation.preparation_unit.id,
                price: Number(preparation.price),

                working_consumption: Number(preparation.working_consumption),
                quantity: Number(preparation.quantity),
                volume: preparation.volume,
                water_volume: preparation.water_volume,
                cost: Math.round(preparation.cost)
            },
            onSuccess: json => {
                if (json.ok) {
                    preparation.saved = true
                    preparation.id = json.json.id
                    this.currentPreparationIndex = newIndex
                } else {
                    this.$alert('Ошибка сервера', 'Ошибка', 'error')
                }
            },
            onError: error => {
                console.log(error)
            }
        })
    }

    private createEventSeed(index: any, newIndex: number) {
        this.setLoadingDataNames('fetchTechnology')
        if (index === newIndex) {
            if (this.checkForErrorSeed()) return
        }

        const seed = this.technologyDetails.seeds[index]
        if (seed.id) return this.updateEventSeed(index, newIndex)

        console.log(seed, 'seed-create')
        httpPost({
            url: `${apiUrl}/technology/task/seed/create/`,
            isSecureRequest: true,
            detailResponse: true,

            body: { // technology_culture: id
                technology_culture: seed.cultureId,
                plan_task: this.technologyDetails.id,
                type: 1,
                name: this.technology.sort,
                plant_distance: seed.plant_distance,
                sowing_depth: seed.sowing_depth,
                sowing_density: seed.sowing_density,
                sowing_density_measure: seed.sowing_density_measure.id,
                price: seed.price,
                cost: Number(seed.total).toFixed(0)
            },
            onSuccess: json => {
                if (json.ok) {
                    seed.saved = true
                    seed.id = json.json.id
                    this.currentSeedIndex = newIndex
                    this.technologyDetails.seeds[index].isSend = true
                } else {
                    this.$alert('Ошибка сервера', 'Ошибка', 'error')
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('fetchTechnology')
            }
        })
    }

    private createEventSpending(index: any, newIndex: number) {
        if (index === newIndex) {
            if (this.checkForErrorSpending()) return
        }

        const spending = this.technologyDetails.spendings[index]
        if (spending.id) return this.updateEventSpending(index, newIndex)

        httpPost({
            url: `${apiUrl}/technology/task/spending/create/`,
            isSecureRequest: true,
            detailResponse: true,
            body: {
                plan_task: this.technologyDetails.id,
                type: 1,
                name: spending.name,
                price: spending.price,
                description: spending.description
            },
            onSuccess: json => {
                if (json.ok) {
                    spending.saved = true
                    spending.id = json.json.id
                    this.currentSpendingIndex = newIndex
                } else {
                    this.$alert('Ошибка сервера', 'Ошибка', 'error')
                }
            },
            onError: error => {
                console.log(error)
            }
        })
    }


    // API Requests - Update

    private updateTechnology(id: any) {
        httpPut({
            url: `${apiUrl}/technology/${id}/`,
            isSecureRequest: true,
            detailResponse: true,
            body: {
                name: this.technology.name,
                description: this.technology.description,
                culture_name: this.technology.culture,
                sort: this.technology.sort || null,
                productivity: this.technology.productivity || 0,
                predecessor: this.technology.predecessor,
                fields: this.technology.fields.selected.map((x: any) => x.id),
                group_user: this.$route.params.farmId,
                group_season: this.technology.season.id
            },
            onSuccess: json => {
                if (json.ok) {
                    this.detailsData.id = json.json.id
                    this.page = 2
                    this.fetchSelectedFields()
                    this.fetchEvents()
                } else {
                    this.$alert('Поле ' + json.json.non_field_errors + '  уже добавлено', 'Ошибка', 'warning')
                }
            },
            onError: error => {
                console.log(error)
            }
        })
    }

    private updateEventTractor(index: any, newIndex: number) {
        if (index === newIndex) {
            if (this.checkForError()) return
        }

        const tractor = this.technologyDetails.tractors[index]
        const drivers = [{
            driver: tractor.drivers[0].driver.user_id,
            start_time: tractor.drivers[0].start_time,
            end_time: tractor.drivers[0].end_time,
            shift: 1,
            salary_rate_for_ha: tractor.drivers[0].salary_rate_for_ha || 0,
            cost: 0
        }]
        if (this.twoDrivers) {
            drivers.push({
                driver: tractor.drivers[1].driver.user_id,
                start_time: tractor.drivers[1].start_time,
                end_time: tractor.drivers[1].end_time,
                shift: 2,
                salary_rate_for_ha: tractor.drivers[1].salary_rate_for_ha || 0,
                cost: 0
            })
        }

        httpPut({
            url: `${apiUrl}/technology/task/tractor/${tractor.id}/`,
            isSecureRequest: true,
            detailResponse: true,
            body: {
                tractor: {
                    plan_task: this.technologyDetails.id,
                    tractor: tractor.tractor.id,
                    trailer: tractor.trailer.id,
                    average_speed: Number(tractor.average_speed),
                    fuel_consumption_rate_for_ha: tractor.fuel_consumption_rate_for_ha || 0,
                    required_volume: tractor.required_volume || 0,
                    cost: tractor.performance_measure || 0,
                    fuel_price: tractor.cost || 0
                },
                drivers
            },
            onSuccess: json => {
                if (json.ok) {
                    this.savedTractorData = true
                    tractor.saved = true
                    tractor.id = json.json.tractor.id
                    this.currentTractorIndex = newIndex
                    const newDate = json.json.calculate.end_date
                    if (newDate) {
                        const date = new Date(newDate)
                        this.technologyDetails.end_date = date.toLocaleDateString('ru')
                        Object.entries(json.json.calculate.tractor_stat).forEach((x: any) => {
                            const tractorData = this.technologyDetails.tractors.find((y: any) => y.id === Number(x[0]))
                            if (tractorData) {
                                tractorData.workload = Math.round(x[1].workload / 100) / 100
                                tractorData.working_hours = x[1].working_hours
                            }
                        })
                    }

                } else {
                    this.$alert('Ошибка сервера', 'Ошибка', 'error')
                }
            },
            onError: error => {
                console.log(error)
            }
        })
    }

    private updateEventPreparation(index: any, newIndex: number) {

        const preparation = this.technologyDetails.preparations[index]

        httpPut({
            url: `${apiUrl}/technology/task/preparation/${preparation.id}/`,
            isSecureRequest: true,
            detailResponse: true,
            body: {
                plan_task: this.technologyDetails.id,
                type: 1,
                preparation: preparation.preparation.id,
                preparation_from_storage: null,
                preparation_type: preparation.preparation_type.type,

                consumption_rate: Number(preparation.consumption_rate),
                consumption_rate_measurement: preparation.consumption_rate_measurement.id,
                preparation_measurement: null, // preparation.preparation_unit.id,
                price: Number(preparation.price),

                working_consumption: Number(preparation.working_consumption),
                quantity: Number(preparation.quantity),
                volume: preparation.volume,
                water_volume: preparation.water_volume,
                cost: Math.round(preparation.cost)
            },
            onSuccess: json => {
                if (json.ok) {
                    preparation.saved = true
                    preparation.id = json.json.id
                    this.currentPreparationIndex = newIndex
                } else {
                    this.$alert('Ошибка сервера', 'Ошибка', 'error')
                }
            },
            onError: error => {
                console.log(error)
            }
        })
    }

    private updateEventSeed(index: any, newIndex: number) {
        this.setLoadingDataNames('fetchTechnology')
        const seed = this.technologyDetails.seeds[index]

        httpPut({
            url: `${apiUrl}/technology/task/seed/${seed.id}/`,
            isSecureRequest: true,
            detailResponse: true,
            body: {
                technology_culture: seed.cultureId,
                plan_task: this.technologyDetails.id,
                type: 1,
                name: this.technology.sort,
                raw_spacing: seed.raw_spacing,
                plant_distance: seed.plant_distance,
                sowing_depth: seed.sowing_depth,
                sowing_density: seed.sowing_density,
                sowing_density_measure: seed.sowing_density_measure.id,
                price: seed.price,
                cost: Number(seed.total).toFixed(0)
            },
            onSuccess: json => {
                if (json.ok) {
                    seed.saved = true
                    seed.id = json.json.id
                    this.currentSeedIndex = newIndex
                } else {
                    this.$alert('Ошибка сервера', 'Ошибка', 'error')
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('fetchTechnology')
            }
        })
    }

    private updateEventSpending(index: any, newIndex: number) {

        const spending = this.technologyDetails.spendings[index]

        httpPut({
            url: `${apiUrl}/technology/task/spending/${spending.id}/`,
            isSecureRequest: true,
            detailResponse: true,
            body: {
                plan_task: this.technologyDetails.id,
                type: 1,
                name: spending.name,
                price: spending.price,
                description: spending.description
            },
            onSuccess: json => {
                if (json.ok) {
                    spending.saved = true
                    spending.id = json.json.id
                    this.currentSpendingIndex = newIndex
                } else {
                    this.$alert('Ошибка сервера', 'Ошибка', 'error')
                }
            },
            onError: error => {
                console.log(error)
            }
        })
    }


    // API Requests - Delete

    private deleteEvent(event: any) {
        console.log(event)
        this.$confirm(`Вы действительно хотите удалить: "${event.event_name}"?`, 'Подтверждение', 'warning').then(() => {
            httpDelete({
                url: `${apiUrl}/technology/task/${event.id}/`,
                isSecureRequest: true,
                onSuccess: json => {
                    this.setTechnologyEvents(event.id)
                    let prevEvent = _.cloneDeep(this.getTechnologyEvents.find((x: any) => x.id !== 0))
                    if (!prevEvent) {
                        prevEvent = _.cloneDeep(this.getTechnologyEvents.find((x: any) => x.id === 0))
                        this.paramsActive = false
                    }
                    this.technologyDetails = prevEvent
                },
                onError: error => {
                    console.log(error)
                }
            }).catch(e => {/* */ })
        })
    }

    private deleteAllTractors() {
        httpDelete({
            url: `${apiUrl}/technology/task/${this.technologyDetails.id}/tractors/delete/`,
            isSecureRequest: true,
            onSuccess: json => {
                this.technologyDetails.tractors = []
            },
            onError: error => {
                console.log(error)
            }
        })
    }

    private deleteTractor(tractor: any, index: any) {
        this.currentTractorIndex = index === 0 ? index : index - 1
        this.technologyDetails.tractors.splice(index, 1)
        if (!tractor.id) return
        httpDelete({
            url: `${apiUrl}/technology/task/tractor/${tractor.id}/`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                const newDate = json.json.calculate.end_date
                if (newDate) {
                    const date = new Date(newDate)
                    this.technologyDetails.end_date = date.toLocaleDateString('ru')
                    Object.entries(json.json.calculate.tractor_stat).forEach((x: any) => {
                        const tractorData = this.technologyDetails.tractors.find((y: any) => y.id === Number(x[0]))
                        if (tractorData) {
                            tractorData.workload = Math.round(x[1].workload / 100) / 100
                            tractorData.working_hours = x[1].working_hours
                        }
                    })
                }
            },
            onError: error => {
                console.log(error)
            }
        })
    }

    private deletePreparation(preparation: any, index: any) {
        this.currentPreparationIndex = index === 0 ? index : index - 1
        this.technologyDetails.preparations.splice(index, 1)
        if (!preparation.id) return
        httpDelete({
            url: `${apiUrl}/technology/task/preparation/${preparation.id}/`,
            isSecureRequest: true,
            onSuccess: json => {
                //
            },
            onError: error => {
                console.log(error)
            }
        })
    }

    private deleteSeed(seed: any, index: any) {
        this.currentSeedIndex = index === 0 ? index : index - 1
        this.technologyDetails.seeds.splice(index, 1)
        if (!seed.id) return
        httpDelete({
            url: `${apiUrl}/technology/task/seeds/${seed.id}/`,
            isSecureRequest: true,
            onSuccess: json => {
                console.log(json)
            },
            onError: error => {
                console.log(error)
            }
        })
    }

    private deleteSpending(spending: any, index: any) {
        this.currentSpendingIndex = index === 0 ? index : index - 1
        this.technologyDetails.spendings.splice(index, 1)

        if (!spending.id) return
        httpDelete({
            url: `${apiUrl}/technology/task/spending/${spending.id}/`,
            isSecureRequest: true,
            onSuccess: json => {
                console.log(json)
            },
            onError: error => {
                console.log(error)
            }
        })
    }

    private getCultureChoose(culture: any) {
        this.newCroprotation.culture = culture
    }

    // create crop
    private createCroprotationNew() {
        this.setLoadingDataNames('fetchTechnology')
        httpPost({
            url: `${apiUrl}/crop_rotation/set/fields/`,
            isSecureRequest: true,
            detailResponse: true,
            body: {
                fields: this.technology.fields.selected.map((x: any) => x.id),
                group_season: this.technology.season.id,
                culture: this.newCroprotation.culture.culture,
                parent_culture: this.newCroprotation.culture.parent_culture,
                sort: this.newCroprotation.culture.sort,
                reproduction: this.newCroprotation.culture.reproduction,
                partial: this.newCroprotation.culture.is_new,
                name: this.technology.season.name
            },
            onSuccess: json => {
                if (json.ok) {
                    this.fetchCroprotation()
                    this.newCroprotation = {
                        culture: new Array() as any,
                        fields: new Array() as any,
                        productivity: ''
                    }
                    this.technology.fields.selected = []
                    this.technology.fields.selectedNames = []
                    this.croprotationNew.selectMode = false
                } else {
                    this.$alert('Поле ' + json.json.non_field_errors + '  уже добавлено', 'Ошибка', 'warning')
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('fetchTechnology')
            }
        })
    }

    private filterTechnologyFields(fields: any) {
        if (this.croprotationNew.addInfoCulture) {
            return fields.filter((x: any) => x.culture === null)
        }
        return fields
    }

    private checkForAllCultures() {
        if (this.croprotationCultures.origin.cultures !== undefined) {
            return this.croprotationCultures.origin.cultures.length === this.croprotationCultures.selectedOrigin.length && this.croprotationCultures.origin.cultures.length === this.croprotationCultures.selected.length
        }
    }

    private selectedAllCultures() {
        if (this.croprotationCultures.origin.cultures !== undefined) {
            if (this.croprotationCultures.origin.cultures.length === this.croprotationCultures.selectedOrigin.length && this.croprotationCultures.origin.cultures.length === this.croprotationCultures.selected.length) {
                this.croprotationCultures.selectedOrigin = []
                this.croprotationCultures.selected = []
                this.technology.totalArea = 0
            } else {
                this.croprotationCultures.selectedOrigin = _.cloneDeep(this.croprotationCultures.origin.cultures)
                this.croprotationCultures.selected = this.croprotationCultures.origin.cultures.map((x: any, index: any) => index)
                this.technology.totalArea = this.technology.areas.converted.map((x: any) => x.area).reduce((acc: any, item: any) => acc + item, 0)
            }
        }
        const fields = this.croprotationCultures.selectedOrigin.map((x: any) => x.fields)
        this.setPlanningData({selectedFields: fields.flatMap((y: any) => y), fieldOnMap: false})
        this.countTotalArea()
    }

    private checkFieldForCulture() {
        let arr: any = []
        // console.log(this.getPlanningData.selectedFields, this.croprotationCultures.selectedOrigin)
        this.getPlanningData.selectedFields.forEach((field: any) => {
            const origin = this.croprotationCultures.selectedOrigin.map((x: any) => x.fields)
            const originFields = origin.flatMap((x: any) => x)
            if (!originFields.includes(field)) {
                const currentFieldCulture: any = this.technology.fields.origin.find((x: any) => x.id === field)
                const currentCulture = currentFieldCulture.culture === 'Пары' ? this.croprotationCultures.origin.cultures.find((x: any) => x.culture === currentFieldCulture.culture) : this.croprotationCultures.origin.cultures.find((x: any) => `${x.culture} ${x.sort} ${x.reproduction}` === `${currentFieldCulture.culture} ${currentFieldCulture.sort} ${currentFieldCulture.reproduction}`) // x.culture === currentFieldCulture.culture)
                const existObj = arr.findIndex((x: any) => x.culture === currentCulture.culture)
                if (existObj === -1) {
                    const obj = {
                        culture: currentCulture.culture,
                        fields: currentCulture.fields.filter((z: any) => z === field), // currentCultureFields,
                        productivity: currentCulture.productivity,
                        reproduction: currentCulture.reproduction,
                        sort: currentCulture.sort
                    }
                    // console.log(obj, 'obj')
                    arr = arr.filter((item: any, index: any) => {
                        return arr.indexOf(item) === index
                    })
                    arr.push(obj)
                } else {
                    arr[existObj].fields.push(field)
                }
            } else {
                const currentFieldCulture: any = this.technology.fields.origin.find((x: any) => x.id === field)
                const isExis = arr.findIndex((y: any) => y.culture === currentFieldCulture.culture)
                // console.log(currentFieldCulture, isExis)
                const addedElem = this.croprotationCultures.selectedOrigin.find((y: any) => y.culture === currentFieldCulture.culture)
                if (isExis === -1) {
                    arr.push(addedElem)
                }
            }
        })
        this.cultureToSend = arr
    }

    // create technology
    private createTechnologyNew() {
        this.checkFieldForCulture()
        // return
        httpPost({
            url: `${apiUrl}/technology/create/new/`,
            isSecureRequest: true,
            detailResponse: true,
            body: { // fields: this.getPlanningData.selectedFields,
                name: this.technology.name,
                description: this.technology.description,
                group_user: this.$route.params.farmId,
                group_season: this.technology.season.id,
                // cultures: this.croprotationCultures.selectedOrigin
                cultures: this.cultureToSend
            },
            onSuccess: json => {
                if (json.ok) {
                    this.detailsData.id = json.json.id
                    this.page = 2
                    this.technology.cultures.origin = json.json.cultures
                        // for (const culture of json.json.cultures) {
                        //     const newSeed = _.cloneDeep(this.detailsData.seed)
                        //     newSeed.cultureId = culture.id
                        //     newSeed.culture = culture
                        //     this.technologyDetails.seeds.push(newSeed)
                        // }
                        // this.fetchSeeds(json.json.cultures[this.currentSeedIndex])
                        // this.technologyDetails.seeds[this.currentSeedIndex].cultureId = this.technology.cultures.origin[this.currentSeedIndex].id
                    this.fetchSeeds(this.technology.cultures.origin[this.currentSeedIndex])
                    this.fetchSelectedFields()
                    this.fetchEvents()
                } else {
                    this.$alert('Поле ' + json.json.non_field_errors + '  уже добавлено', 'Ошибка', 'warning')
                }
            },
            onError: error => {
                console.log(error)
            }
        })
    }

    private updateTechnologyNew(id: any) {
        this.checkFieldForCulture()
        // return
        httpPut({
            url: `${apiUrl}/technology/new/${id}/`,
            isSecureRequest: true,
            detailResponse: true,
            body: { // fields: this.getPlanningData.selectedFields,
                name: this.technology.name,
                description: this.technology.description,
                group_user: this.$route.params.farmId,
                group_season: this.technology.season.id,
                cultures: this.cultureToSend
                // cultures: this.croprotationCultures.selectedOrigin
            },
            onSuccess: json => {
                if (json.ok) {
                    this.detailsData.id = json.json.id
                    this.page = 2
                    this.technology.cultures.origin = json.json.cultures
                    this.fetchSeeds(this.technology.cultures.origin[this.currentSeedIndex])
                    /////////////
                    // for (const culture of json.json.cultures) {
                    //     const newSeed = _.cloneDeep(this.detailsData.seed)
                    //     newSeed.cultureId = culture.id
                    //     newSeed.culture = culture

                    //     this.technologyDetails.seeds.push(newSeed)
                    // }

                    this.fetchSelectedFields()
                    this.fetchEvents()
                    this.fetchSeeds(json.json.cultures[this.currentSeedIndex])
                } else {
                    this.$alert('Поле ' + json.json.non_field_errors + '  уже добавлено', 'Ошибка', 'warning')
                }

            },
            onError: error => {
                console.log(error)
            }
        })
    }



    // seeds

    private someFunction(culture: any, indexCulture: any) {

        this.fetchSeeds(culture)
        this.currentSeedIndex = indexCulture
        if (indexCulture + 1 > this.technologyDetails.seeds.length) {
            const newSeed = _.cloneDeep(this.detailsData.seed)
            this.technologyDetails.seeds.push(newSeed)
        }

        // for while
        // console.log(culture)
        // console.log(this.technologyDetails.seeds[this.currentSeedIndex], 'list')
        if (this.technologyDetails.seeds[this.currentSeedIndex].cultureId === 0) {
            this.technologyDetails.seeds[this.currentSeedIndex].cultureId = culture.id
        }
        // console.log(this.technologyDetails.seeds)
    }

    private addCulture() {
        this.technology.cultures.origin.forEach((culture: any) => {
            const notExist = this.technologyDetails.seeds.find((seed: any) => seed.culture.culture === culture.culture)
            if (notExist === undefined) {
                const newSeed = _.cloneDeep(this.detailsData.seed)
                newSeed.cultureId = culture.id
                newSeed.culture = culture
                this.technologyDetails.seeds.push(newSeed)
            }
        })
    }

    @Watch('currentRoute.name')
    private onChangeSubfield(newVal: any) {
        this.setPlanningData({selectedFields: []})
    }

    private getNormalizeTotalArea(area: any) {
        return area.toFixed(2)
    }

}
