export default {
    main: "Главная",
    home: "Домашняя страница",
    about_us: "О нас",
    services: "Услуги",
    news: "Новости",
    support: "Поддержка",
    farm: "Хозяйство",
    contacts: "Контакты",
    workspace: {
        main: "Главная",
        workspace: {
            title: "Рабочая область",
            option: "Показать все"
        },
        employees: "Сотрудники",
        farm: "Хозяйство",
        autopark: "Автопарк",
        storage: "Склад",
        guide: "Справочник",
        dispatcher: "Диспетчерская",
        profile: "Профиль"
    }
}