






















































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import HeaderWithPagination from '@/components/HeaderWithPagination.vue'
import Sideboard from '@/components/Sideboard.vue'
import BaseMenuItem from '@/components/BaseMenuItem.vue'
import InfoText from '@/components/cadastreBoard/InfoText.vue'
import { Getter, Action, Mutation } from 'vuex-class'
import { GetterNames, ActionNames, MutationNames } from '@/store/types'
import Farm from '@/models/Farm'
import { httpDelete } from '@/util/http'
import { apiUrl } from '@/domain/constants'
import SubscriptionInfo from '@/components/SubscriptionInfo.vue'
import WelcomeModel from '@/components/tour/WelcomeModel.vue'
import DefaultModel from '@/components/tour/DefaultModel.vue'
import FinalModel from '@/components/tour/FinalModel.vue'
import {farmContent} from '@/data/tourContent'
import SeasonsModel from '@/components/cadastreBoard/SeasonsModel.vue'
import i18n from '@/i18n'
@Component({
    components: {
        HeaderWithPagination,
        Sideboard,
        BaseMenuItem,
        InfoText,
        SubscriptionInfo,
        WelcomeModel,
        DefaultModel,
        FinalModel,
        SeasonsModel
    }
})
export default class FarmBoard extends Vue {
    @Action(ActionNames.fetchFarmsList) private fetchFarmsList !: any

    @Getter(GetterNames.getFarmFromRoute) private getFarmFromRoute!: Farm
    @Getter(GetterNames.getFarms) private getFarms!: any
    @Getter(GetterNames.getGuideTourActivators) private getGuideTourActivators!: any
    @Getter(GetterNames.getPermissions) private getPermissions!: any
    @Getter(GetterNames.getLoadingDataNames) private getLoadingDataNames!: any

    @Mutation(MutationNames.setActiveIndicator) private setActiveIndicator!: any
    @Mutation(MutationNames.setSideboardIsShown) private setSideboardIsShown !: any
    @Mutation(MutationNames.setNewFarmRequest) private setNewFarmRequest!: any
    @Mutation(MutationNames.setGuideTourActivators) private setGuideTourActivators!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any

    private normalizeDate(date: string) {
        if (date) {
            return `${date.substring(8, 10)}.${date.substring(5, 7)}.${date.substring(0, 4)}`
        } else return i18n.t('messages.phrases.no_data')
    }

    @Watch('getGuideTourActivators.farmTour')
    private onchangeFarmTour() {
        if (this.getGuideTourActivators.farmTour) {
            (this.$refs.welcomeModel as any).show()
        }
    }

    private endFarmTour() {
        this.setGuideTourActivators({farmTour: false})
    }

    private startFarmTour(index: any) {
        if (index === 'end') {
            (this.$refs.finalModel as any).show()
            const model = this.$refs.defaultModel as any
            model.hide()
            this.endFarmTour()
        } else {
            const content: any = farmContent[index]
            const model = this.$refs.defaultModel as any
            model.show(farmContent[index], {
                modelTop:  content.modelBottom ? (window.innerHeight - Number(content.modelBottom.substring(0, content.modelBottom.length - 2)) - 208) + 'px' : content.modelTop,
                modelBottom: content.modelBottom,
                modelLeft: content.modelLeft,
                modelRight: content.modelRight,
                arrowTop: content.arrowTop,
                arrowLeft: content.arrowLeft
            })
        }
    }

    @Watch('$route.params.farmId')
    private onchangeFarm(val: any, old: any) {
        if (val !== old) {
            this.fetchFarmsList()
        }
    }

    private mounted() {
        this.fetchFarmsList()
        this.setActiveIndicator('ndvi')
    }

    private FarmOrderRequest() {
        this.setNewFarmRequest({isNewRequest: true, farmId: this.getFarmFromRoute.id, selectedFarms: []})
        this.$router.push({
            name: 'farmorderdata',
            params: {
                status: 'loading'
            }
        }).catch(e => { /* */ })
    }

    private removeFarm() {
        this.setLoadingDataNames('farmBoardDeleteFarm')
        httpDelete({
            url: `${apiUrl}/group/user/list/${this.getFarmFromRoute.id}`,
            isSecureRequest: true,
            onSuccess: () => {
                alert(i18n.t('messages.success.farm_deleted'))
                this.fetchFarmsList({reload: true})
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('farmBoardDeleteFarm')
                this.$router.push('/cadastres').catch(e => {/**/})
            }
        })
    }

    private openFarmRoute(routeName: any, id?: any) {
        if (this.getFarmFromRoute) {
            this.$router.push({
                name: routeName,
                params: {
                    farmId: id ? id.toString() : this.getFarmFromRoute.id.toString()
                }
            }).catch(e => { /* */ })
        }
    }

    private openPrevFarm() {
        if (this.getFarmFromRoute && this.getFarms.length > 0) {
            const index = this.getFarms.findIndex((x: any) => x.id === this.getFarmFromRoute.id)
            if (index !== -1) {
                const previous = index - 1 < 0 ? this.getFarms.length - 1 : index - 1
                this.openFarmRoute('farm', this.getFarms[previous].id)
            }
        }
    }

    private openNextFarm() {
        if (this.getFarmFromRoute && this.getFarms.length > 0) {
            const index = this.getFarms.findIndex((x: any) => x.id === this.getFarmFromRoute.id)
            if (index !== -1) {
                const next = index + 1 > this.getFarms.length - 1 ? 0 : index + 1
                this.openFarmRoute('farm', this.getFarms[next].id)
            }
        }
    }

    private openCadastresBoard() {
        this.$router.push('/cadastres').catch(e => { /* */ })
    }
}
