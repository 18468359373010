export const problemTypes = [
    {
        type: 'type1',
        icon: 'medical_plus_icon.svg',
        title: 'headers.workspace.fields.notes.note_addition.problem.type1',
        images: [] as any,
        isActive: false
    },
    {
        type: 'type2',
        icon: 'pest_icon.svg',
        title: 'headers.workspace.fields.notes.note_addition.problem.type2',
        images: [] as any,
        isActive: false
    },
    {
        type: 'type3',
        icon: 'weed_icon.svg',
        title: 'headers.workspace.fields.notes.note_addition.problem.type3',
        images: [] as any,
        isActive: false
    },
    {
        type: 'type4',
        icon: 'watering_icon.svg',
        title: 'headers.workspace.fields.notes.note_addition.problem.type4',
        images: [] as any,
        isActive: false
    }
]

export const notesRegistrationDetails = {
    type1: {
        details: [
            {
                type: "dropdown",
                placeholder: 'headers.workspace.fields.notes.note_addition.problem_details.type1.input1.placeholder'
            },
            {
                type: "input",
                header: 'headers.workspace.fields.notes.note_addition.problem_details.type1.input2.header',
                placeholder: 'headers.workspace.fields.notes.note_addition.problem_details.type1.input2.placeholder'
            }
        ],
        add: 'headers.workspace.fields.notes.note_addition.problem_details.type1.add',
        advice: {
            type: "dropdown",
            header: 'headers.workspace.fields.notes.note_addition.problem_details.type1.advice.header',
            placeholder: 'headers.workspace.fields.notes.note_addition.problem_details.type1.advice.placeholder'
        },
        add_preparation: 'headers.workspace.fields.notes.note_addition.problem_details.type1.add_prep'
    },
    type2: {
        details: [
            {
                type: "dropdown",
                placeholder: 'headers.workspace.fields.notes.note_addition.problem_details.type2.input1.placeholder'
            },
            {
                type: "input",
                header: 'headers.workspace.fields.notes.note_addition.problem_details.type2.input2.header',
                sup: 2,
                placeholder: 'headers.workspace.fields.notes.note_addition.problem_details.type2.input2.placeholder'
            }
        ],
        add: 'headers.workspace.fields.notes.note_addition.problem_details.type2.add',
        advice: {
            type: "dropdown",
            header: 'headers.workspace.fields.notes.note_addition.problem_details.type2.advice.header',
            placeholder: 'headers.workspace.fields.notes.note_addition.problem_details.type2.advice.placeholder'
        },
        add_preparation: 'headers.workspace.fields.notes.note_addition.problem_details.type2.add_prep'
    },
    type3: {
        details: [
            {
                type: "dropdown",
                placeholder: 'headers.workspace.fields.notes.note_addition.problem_details.type3.input1.placeholder'
            },
            {
                type: "dropdown",
                header: 'headers.workspace.fields.notes.note_addition.problem_details.type3.input2.header',
                placeholder: 'headers.workspace.fields.notes.note_addition.problem_details.type3.input2.placeholder'
            },
            {
                type: "input",
                header: 'headers.workspace.fields.notes.note_addition.problem_details.type3.input3.header',
                sup: 2,
                placeholder: 'headers.workspace.fields.notes.note_addition.problem_details.type3.input3.placeholder'
            }
        ],
        add: 'headers.workspace.fields.notes.note_addition.problem_details.type3.add',
        advice: {
            type: "dropdown",
            header: 'headers.workspace.fields.notes.note_addition.problem_details.type3.advice.header',
            placeholder: 'headers.workspace.fields.notes.note_addition.problem_details.type3.advice.placeholder'
        },
        add_preparation: 'headers.workspace.fields.notes.note_addition.problem_details.type3.add_prep'
    }
}

export const notesDetailTable = [
    {
        title: 'headers.workspace.fields.notes.note_addition.date',
        type: 'round_date'
    }
]

export const notesDetailTableByType = {
    type1: [
        {
            title: 'headers.workspace.fields.notes.note_addition.problem_details.type1.title',
            type: 'name'
        },
        {
            title: 'headers.workspace.fields.notes.note_addition.problem_details.type1.input2.header',
            type: 'value'
        }
    ],
    type2: [
        {
            title: 'headers.workspace.fields.notes.note_addition.problem_details.type2.title',
            type: 'name'
        },
        {
            title: 'headers.workspace.fields.notes.note_addition.problem_details.type2.input2.header',
            type: 'value'
        }
    ],
    type3: [
        {
            title: 'headers.workspace.fields.notes.note_addition.problem_details.type3.title',
            type: 'name'
        },
        {
            title: 'headers.workspace.fields.notes.note_addition.problem_details.type3.input2.header',
            type: 'value'
        }
    ]
}