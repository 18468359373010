



























import { Component, Vue } from 'vue-property-decorator'
import {httpPost, httpPut} from '@/util/http'
import { apiUrl } from '@/domain/constants'
import { Mutation } from 'vuex-class'
import { MutationNames } from '@/store/types'

@Component
export default class AddTelegramModel extends Vue {
  @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
  private isShown = false
  private userId = -1
  private telegram = {
    seletedName: '',
    onerror: ''
  }

  private show(user: any) {
     this.telegram = {
        seletedName: '',
        onerror: ''
     }
     this.isShown = true
     this.userId = user.id
     this.telegram.seletedName = user.telegram ? user.telegram.telegram_username : ''
  }

  private finishRegister() {
    this.telegram.seletedName = this.telegram.seletedName.replace('@', '').trim()
    // if (!this.telegram.seletedName) {
    //   this.telegram.onerror = `${this.$i18n.t('messages.errors.required_field')}`
    //   return
    // }
    this.setLoadingDataNames('registerModalFinishRegister')
    httpPut({
      url: `${apiUrl}/workspace/change/permissions/in/workspace/${this.userId}/`,
      isSecureRequest: true,
      detailResponse: true,
      body: {
        telegram_username: this.telegram.seletedName
      },
      onSuccess: json => {
        if (json.ok) {
          this.$alert('Аккаунт телеграм успешно добавлен', 'Успешно', 'success').then(() => {
            this.$emit('reload')
            this.hide()
          })
        } else {
          this.$alert(JSON.stringify(json.json), 'Ошибка', 'error')
        }
      },
      onError: error => {
        console.log(error)
      },
      doFinally: () => {
        this.setLoadingDataNames('registerModalFinishRegister')
      }
    })
  }

  private hide() {
    this.isShown = false
  }
}
