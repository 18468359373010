




























import { Component, Vue, Watch, Emit, Prop } from 'vue-property-decorator'

@Component
export default class WelcomeModel extends Vue {
    private isShown = false
    private catalogList = [1, 0, 0, 0, 0]

    private show() {
        this.isShown = true
    }

    private hide() {
        this.isShown = false
    }
    @Emit('end')
    private end() {
        return {}
    }
    @Emit('confirm')
    private confirm() {
        return {}
    }
}
