































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import { GetterNames, MutationNames } from '@/store/types'
import Farm from '@/models/Farm'
import { httpPost } from '@/util/http'
import { apiUrl } from '@/domain/constants'
import { Route } from 'vue-router'
import { ndviGistogram, ndmiGistogram, clgreenGistogram } from '@/data/gistogramData'
import i18n from '@/i18n'

@Component
export default class SubfieldsRating extends Vue {
    @Getter(GetterNames.getFarmFromRoute) private getFarmFromRoute!: Farm
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: Route
    @Getter(GetterNames.getFieldsRating) private getFieldsRating!: any

    @Mutation(MutationNames.setActiveIndicator) private setActiveIndicator!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    @Mutation(MutationNames.setFieldsRating) private setFieldsRating!: any

    private indicators: any = {
        origin: [
            {
                index: 0,
                name: i18n.t('headers.workspace.fields.indicators.indicators.vegetation.title'),
                color: '#4B970F',
                tag: 'ndvi'
            },
            {
                index: 1,
                name: i18n.t('headers.workspace.fields.indicators.indicators.nitrogen.title'),
                color: '#008CDB',
                tag: 'gndvi'
            },
            {
                index: 2,
                name: i18n.t('headers.workspace.fields.indicators.indicators.humidity.title'),
                color: '#FFC700',
                tag: 'ndmi'
            },
            {
                index: 3,
                name: i18n.t('headers.workspace.fields.indicators.indicators.chlorophyll.title'),
                color: '#D51900',
                tag: 'clgreen'
            }
        ],
        selected: {
            index: 0,
            name: i18n.t('headers.workspace.fields.indicators.indicators.vegetation.title'),
            color: '#4B970F',
            tag: 'ndvi'
        },
        dropdown: false
    }

    private dates: any = {
        origin: [],
        selected: '',
        dropdown: false
    }

    private cultures: any = {
        origin: [],
        selected: {
            index: -1,
            culture: ''
        },
        dropdown: false
    }

    private getSelectedDate() {
        if (this.dates.selected) {
            return this.normalizeDate(this.dates.selected)
        } else return i18n.t('general.dates.format_date')
    }

    private normalizeDate(date: any) {
        if (date) {
           return `${date.substring(8, 10)}.${date.substring(5, 7)}.${date.substring(2, 4)}`
        } else return ''
    }

    private getRatinColor(value: any) {
        if (this.indicators.selected.index === 0 || this.indicators.selected.index === 1) {
            for (const i of ndviGistogram) {
                if (i.from <= value && value <= i.to) return i.color
            }
        } else if (this.indicators.selected.index === 2) {
            for (const i of ndmiGistogram) {
                if (i.from <= value && value <= i.to) return i.color
            }
        } else if (this.indicators.selected.index === 3) {
            for (const i of clgreenGistogram) {
                if (i.from <= value && value <= i.to) return i.color
            }
        }
    }

    private mounted() {
        this.setFieldsRating({result: [], indicator: 'ndvi', fieldId: 0})
        this.setLoadingDataNames('SubfieldRatingGetStatic')
        httpPost({
            url:  `${apiUrl}/rating/get/static/`,
            isSecureRequest: true,
            detailResponse: true,
            body: {
                group_user: this.currentRoute.params.farmId
            },
            onSuccess: json => {
                if (json.ok) {
                    this.dates.origin = json.json.dates.reverse()
                    this.dates.selected = this.dates.origin.length > 0 ? this.dates.origin[0] : ''
                    this.cultures.origin = [
                        {
                            index: 0,
                            culture: i18n.t('messages.selections.crop.options[0]')
                        }
                    ]
                    this.cultures.origin = this.cultures.origin.concat(json.json.cultures
                        .map((x: any, i: any) => {
                            return {
                                index: i + 1,
                                culture: x.culture
                            }
                    }))
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('SubfieldRatingGetStatic')
            }
        })
    }

    private getData() {
        if (this.cultures.selected.index === -1) return
        const postBody: any = {
            group_user: this.getFarmFromRoute.id,
            index: this.indicators.selected.tag
        }
        if (this.dates.selected) postBody.date = this.dates.selected
        if (this.cultures.selected.index !== 0) postBody.type = this.cultures.selected.culture

        this.setLoadingDataNames('SubfieldsRatingGetRating')
        this.setActiveIndicator('')
        this.setFieldsRating({result: []})
        httpPost({
            url:  `${apiUrl}/rating/get/`,
            isSecureRequest: true,
            detailResponse: true,
            body: postBody,
            onSuccess: json => {
                if (json.ok) {
                    this.setFieldsRating({result: json.json.crops.field, indicator: this.indicators.selected.tag})
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('SubfieldsRatingGetRating')
            }
        })
    }

    private closeIndicatorsDropdown() {
        this.indicators.dropdown = false
    }

    private closeDatesDropdown() {
        this.dates.dropdown = false
    }

    private closeCulturesDropdown() {
        this.cultures.dropdown = false
    }

    private close() {
        this.$router.push(`/cadastres/farm/${this.getFarmFromRoute.id}`)
    }
}
