
































































































































































































































































































































import { Vue, Component, Watch } from "vue-property-decorator"
import Chart from "chart.js/auto"

import {Getter, Mutation} from "vuex-class"
import {GetterNames, MutationNames} from "@/store/types"
import { httpGet, httpPost } from '@/util/http'
import { apiUrl } from '@/domain/constants'
import { CultureColors } from '@/data/FieldCulture'
import DateRangePicker from "@/components/DateRangePicker.vue"
import { AnyObject } from "chart.js/types/basic"
@Component({
    components: {
        DateRangePicker
    }
})
export default class FieldDashboard extends Vue {
    @Getter(GetterNames.getSidebarModel) private getSidebarModel!: any
    @Getter(GetterNames.getFarmFromRoute) private getFarmFromRoute!: any
    @Getter(GetterNames.getCurrentSeason) private getCurrentSeason!: any
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: any
    @Getter(GetterNames.getFarmMapControllers) private getFarmMapControllers!: any
    @Getter(GetterNames.getCurrentFarmIndicators) private getCurrentFarmIndicators!: any

    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any

    private CultureColors = CultureColors
    // graphs
    private colors: any = ['#4F0BBD', '#23628F', '#4F8F23', '#FFC700', '#F37500', '#FC7BE7', '#AA27E8', '#719D00', '#FFD400']
    private months: any = ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек']
    private dates: any = [{value: '3 года', dataName: 'data_by_year'}, {value: '12 мес.', dataName: 'data_by_month'}, {value: '7 дней', dataName: 'data_by_week'}, {value: '30дней', dataName: 'data_by_month_days'}]

    private otherCultures: any = []
    // html-staff
    private currentDatesIndex = 0
    private dropdownAnalytics = false
    private technicSelectList: boolean = true

    // charts
    private cultureChart: any = null
    private analyticsChart: any = null
    private currentAnalyticsChart: any = null
    private technicRatingChart: any = null
    private currentWorkChart: any = null
    private calendarWorkChart: any = null

    // json for charts
    private technicListData: any = []
    private technicRatingData: any = null
    private currentAnalyticsData: any = null
    private technologyTaskData: any = null
    private calendarWorkData: any = null

    // filter params technic
    private queryCategory: string = ''
    private queryTractor: string = ''
    private queryTaskName: string = ''

    private queryDays: number = 200
    private queryOrdering: string = 'performance'

    // search
    private searchText: string = ''

    // filter list - technicData
    // private filterDaysList: any = {
    //     origin: [
    //         {title: 'Последние 1 день', value: 1},
    //         {title: 'Последние 7 дней', value: 7},
    //         {title: 'Последние 30 дней', value: 30}
    //     ],
    //     converted: ['Последние 1 день', 'Последние 7 дней', 'Последние 30 дней'],
    //     selected: ''
    // }
    private filterCategoryTechnic: any = {
        origin: [],
        converted: [],
        selected: '',
        checked: []
    }

    private filterTechnicName: any = {
        origin: [],
        converted: [],
        selected: '',
        checked: []
    }

    private filterPlanTask: any = {
        origin: [],
        checked: []
    }

    private selectedDataCulture: any = []

    // filter list - technicDataRaiting
    private filterCategoryRatingTechnic: any = {
        converted: [],
        checked: ['Тракторы']
    }

    // filter-search
    private filterTechnicBox: any = false
    private searchTechnicBox: any = false

    // chart about buy culture
    private dataPurchaseCulture: any = {
        origin: [] as any,
        selected: [] as any
    }

    private chartPurchaseCulture: any = {
        cultureName: "Пшеница" , // "Пшеница"
        categoryName: '', // "2 класс"
        dataName: '', // "data_by_year"
        date: [], // Array(3),
        general: {
            class: '',
            culture: ''
        }
    }

    private financeAnalyticsMonths: any = []

    private cultureNameChart: any = []

    private dayRangeOfFinancyAnalitics: any = []
    // dropwdown
    private isDropdownVisisble: boolean = false

    private onlyTechnicDataArr: any = []

    private eventArrOfName: any = []

    private arrayOfRatingTask: any = []

    private selectCurrentWorkDates = [
        {id: 1, title: 'Неделя'},
        {id: 2, title: 'Месяц'},
        {id: 3, title: 'Год'}
    ]

    private alwaysShowTooltip: any = {
        id: 'alwaysShowTooltip',
        afterDraw(chart: any, args: any, options: any) {
            const { ctx } = chart
            ctx.save()

            chart.data.datasets.forEach((dataset: any, i: any) => {
                chart.getDatasetMeta(i).data.forEach((datapoint: any, index: any) => {
                    const { x, y } = datapoint.tooltipPosition()

                    const text = chart.data.labels[index] + '\n' + chart.data.datasets[i].data[index] + 'га'

                    // triangle
                    ctx.beginPath()
                    ctx.fill()
                    ctx.restore()

                    // text
                    ctx.font = 'bold 12px sans-serif'
                    ctx.fillStyle = 'white'
                    ctx.height = '15px'
                    ctx.fillText(text, x - 50, y)
                    ctx.restore()
                })
            })
        }
    }

    private currentWorkMonth = new Date(Date.now()).getMonth() + 1
    private currentWorkWeek = 1
    private currentWorkDay: any = 0

    // calendar technic
    private dateFromCalendarTechnic = ''

    private mounted() {
        this.setLoadingDataNames('TechMapBoardGetCurrentTechmap')
        this.fetchCultureCost()
        this.fetchAnalytics()
        this.fetchTechnologyTask()
        this.fetchListTechnic()
        this.fetchRatingTechnic()
        this.fetchCurrentWork()
        this.setLoadingDataNames('TechMapBoardGetCurrentTechmap')
        console.log()
    }

    get getDays() {
        return `${new Date(this.calendarWorkData.start_date).getDate()} ${this.months[new Date(this.calendarWorkData.start_date).getMonth()]} - ${new Date(this.calendarWorkData.end_date).getDate()} ${this.months[new Date(this.calendarWorkData.end_date).getMonth()]}`
    }

    private setNewDataRange(value: any) {
        const date = new Date(value)
        const dateNow = new Date(Date.now())
        this.dateFromCalendarTechnic = date.toLocaleDateString("ru")
        const daysAgo = dateNow.getTime() - date.getTime()
        this.queryDays = Math.ceil(daysAgo / (1000 * 3600 * 24))
    }

    private fetchCurrentWork() {
        httpPost({
            url: `${apiUrl}/technology/dashboard/task/chart/`,
            isSecureRequest: true,
            body: {
                group_user: this.getFarmFromRoute.id,
                group_season: this.getCurrentSeason.id,
                year: Number(this.getCurrentSeason.name),
                month: this.currentWorkMonth, // currentWorkMonth === -1 ? -1 : currentWorkMonth + 1,
                week: this.currentWorkWeek,
                day: this.currentWorkDay
            },
            onSuccess: json => {
                this.calendarWorkData = json.chart_data
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                if (this.calendarWorkChart !== null) {
                    this.calendarWorkChart.destroy()
                }
                this.calendarChartInit()
            }
        })
    }

    private changeDateCurrentWork(args: any) {
        switch (args.target.value) {
            case '1': // week
                this.currentWorkMonth = new Date(Date.now()).getMonth() + 1
                this.currentWorkWeek = 1
                this.currentWorkDay = 0
                this.fetchCurrentWork()
                break
            case '2': // month
                this.currentWorkMonth = new Date(Date.now()).getMonth() + 1
                this.currentWorkWeek = 0
                this.currentWorkDay = 0
                this.fetchCurrentWork()
                break
            case '3': // year
                this.currentWorkMonth = 0
                this.currentWorkWeek = 0
                this.currentWorkDay = 0
                this.fetchCurrentWork()
                break
        }
    }

    private changeCurrentDate(type: any, value: any) {
        switch (value) {
            case true:
                if (type === 'currentWorkWeek') {
                    if (this.currentWorkWeek === -1) {
                        this.currentWorkWeek = 1
                    } else {
                        this.currentWorkWeek++
                    }
                } else {
                    this.currentWorkMonth++
                }
                this.fetchCurrentWork()
                break
            case false:
                if (type === 'currentWorkWeek') {
                    if (this.currentWorkWeek === 1) {
                        this.currentWorkWeek = -1
                    } else {
                        this.currentWorkWeek--
                    }
                } else {
                    this.currentWorkMonth--
                }
                this.fetchCurrentWork()
                break
        }
    }

    private checkForFeatureDay() {
        const startDate = [new Date(this.calendarWorkData.start_date).getFullYear(), new Date(this.calendarWorkData.start_date).getMonth() + 1, new Date(this.calendarWorkData.start_date).getDate()].join('-')
        const currentDate = [new Date(Date.now()).getFullYear(), new Date(Date.now()).getMonth() + 1, new Date(Date.now()).getDate()].join('-')

        return startDate !== currentDate
    }

    private calendarChartInit() {
        const ctx: any = document.getElementById('calendar_chart')
        this.calendarWorkChart = new Chart(ctx.getContext('2d'), {
            type: 'bar',
            data: {
                datasets: []
            },
            options: {
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        callbacks: {
                            label: (context: any) => {
                                if (!Array.isArray(context.raw)) {
                                    const value = `План. гектарности: ${context.raw} га`
                                    return value
                                } else {
                                    const values = [
                                        `Мероприятие: ${this.eventArrOfName[context.raw[1]]}`,
                                        `Факт. Обработано: ${context.raw[0]} га`
                                    ]
                                    return values
                                }
                            }
                        }
                    }
                },
                responsive: true,
                scales: {
                    x: {
                        min: 0,
                        suggestedMin: 0,
                        stacked: true,
                        beginAtZero: true,
                        grid: {
                            display: false,
                            drawBorder: false
                        }
                    },
                    y: {
                        min: 0,
                        suggestedMin: 0,
                        stacked: true,
                        beginAtZero: true,
                        grid: {
                            drawBorder: false
                        }
                    }
                },
                onClick: (e: any, activeEls: any) => {
                    if (this.currentWorkMonth !== 0 && this.currentWorkWeek === 0) {
                        console.log(activeEls)
                        const datasetIndex = activeEls[0].datasetIndex
                        const dataIndex = activeEls[0].index
                        const datasetLabel = e.chart.data.datasets[datasetIndex].label
                        const value = e.chart.data.datasets[datasetIndex].data[dataIndex]
                        const label = e.chart.data.labels[dataIndex]
                        const res = label.split('.').reverse()
                        const date = res.join('-')
                        this.currentWorkDay = date
                        this.currentWorkWeek = 1
                        this.fetchCurrentWork()
                    }
                    // this.calendarMonthName = e.chart.data.labels[dataIndex]
                    // this.fetchCurrentWork()
                }
            }
        })
        this.updateCalendarWorkChart()
    }

    private updateCalendarWorkChart() {
        const newData: any = {
            labels: this.calendarWorkData.data.map((x: any) => x.date || this.months[x.month - 1]),
            datasets: []
        }

        let max: any = -Infinity
        this.calendarWorkData.data.forEach((x: any) => {
            if (x.tasks.length > max) {
                max = x.tasks.length
            }
        })

        const stackData: any = []
        this.calendarWorkData.data.forEach((x: any, index: any) => {
            if (x.tasks.length !== max) {
                let counter = x.tasks.length
                while (counter < max) {
                    x.tasks.push({area: 0, task_name: ''})
                    counter++
                }
            }
            x.tasks.forEach((y: any, indexY: any) => {
                if (stackData[indexY] === undefined) {
                    stackData[indexY] = []
                }
                stackData[indexY].push({area: y.area || 0, date: x.date, task: y.task_name})
            })
        })

        newData.datasets.push({
            label: 'План',
            data: this.calendarWorkData.data.map((x: any) => x.plan.toFixed(2)),
            stack: 'Stack 0',
            backgroundColor: ['#C9E0B7']
        })

        stackData.forEach((x: any, index: any) => {
            newData.datasets.push({
                label: 'Факт',
                data: x.map((y: any) => [Math.round(y.area), this.getTextOfEvent(y.task)]),
                stack: 'Stack 1',
                backgroundColor: (context: any) => {
                    return context.raw[0] > 0 ? this.colors[context.raw[1] % 9] : '#FFFFFF'
                }
                // borderRadius: {
                //     topLeft: index === stackData.length - 1 ? 12 : 0,
                //     topRight: index === stackData.length - 1 ? 12 : 0
                // }
            })
        })
        this.calendarWorkChart.data = newData
        this.calendarWorkChart.update()
    }

    private countDayOfYear(start: any, end: any) {
        const currentYearStart = new Date(start)
        const currentYearEnd = new Date(end)

        let count = currentYearEnd.getTime() - currentYearStart.getTime()
        while (count) {
            const item = this.dayRangeOfFinancyAnalitics[this.dayRangeOfFinancyAnalitics.length - 1] || currentYearStart
            const time = new Date(item).getTime() + 86400000
            const timeDate = new Date(time).toLocaleDateString("sv-SE")
            this.dayRangeOfFinancyAnalitics.push(timeDate)
            count = count - 86400000
        }

        this.dayRangeOfFinancyAnalitics.forEach((x: any) => {
            const date = new Date(x)
            if (!this.financeAnalyticsMonths.includes(`${this.months[date.getMonth()]} ${date.getFullYear()}`)) {
                this.financeAnalyticsMonths.push(`${this.months[date.getMonth()]} ${date.getFullYear()}`)
            }
        })
    }

    private fetchCultureCost() {
        httpGet({
            url: `${apiUrl}/technology/dashboard/culture_cost/chart/`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                if (json.ok) {
                    this.dataPurchaseCulture.origin = json.json
                    this.dataPurchaseCulture.origin.forEach((x: any) => {
                        x.culture_classes.forEach((y: any) => {
                            this.selectedDataCulture.push({culture: x.culture_name, class: y.class_name})
                        })
                    })
                    // console.log(this.chartPurchaseCulture)
                    this.chartPurchaseCulture.general.class = this.dataPurchaseCulture.origin[0].culture_classes[0].class_name
                    this.chartPurchaseCulture.general.culture = this.dataPurchaseCulture.origin[0].culture_name

                    this.dataPurchaseCulture.selected = json.json.find((x: any) => x.culture_name === this.chartPurchaseCulture.cultureName)

                    this.chartPurchaseCulture.categoryName = this.dataPurchaseCulture.selected.culture_classes[0].class_name
                    this.chartPurchaseCulture.dataName = this.dataPurchaseCulture.selected.culture_classes[0].class_data[1].data_name
                    this.chartPurchaseCulture.date = this.dataPurchaseCulture.selected.culture_classes[0].class_data[1].data
                    this.analyticsChartInitialization()
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                //
            }
        })
    }

    private fetchAnalytics() {
        httpPost({
            url: `${apiUrl}/technology/dashboard/finance/`,
            isSecureRequest: true,
            body: {
                group_user: this.getFarmFromRoute.id,
                group_season: this.getCurrentSeason.id
            },
            onSuccess: json => {
                this.currentAnalyticsData = json

                if (this.currentAnalyticsData.chart_start_date !== null && this.currentAnalyticsData.chart_end_date !== null) {
                    this.countDayOfYear(this.currentAnalyticsData.chart_start_date, this.currentAnalyticsData.chart_end_date)
                }
                this.currentAnalyticsChartInitialization()
            },
            onError: error => {
                console.log(error)
            }
        })
    }

    private fetchTechnologyTask() {
        httpPost({
            url: `${apiUrl}/technology/dashboard/task/`,
            isSecureRequest: true,
            body: {
                group_user: this.getFarmFromRoute.id,
                group_season: this.getCurrentSeason.id
            },
            onSuccess: json => {
                this.technologyTaskData = json

                this.technologyTaskData.culture_data.culture_percentage.forEach((x: any, index: any) => {
                    this.cultureNameChart.push({id: index, area: (x.area / 10000).toFixed(2), culture: x.culture})
                })
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.cultureChartInitialization()
            }
        })
    }

    private orderingFetchListTechnic() {
        this.queryOrdering = this.queryOrdering === 'performance' ? '-performance' : 'performance'
        this.reverseTechnicRatingValue()
        this.fetchListTechnic()
    }

    private fetchListTechnic() {
        this.setLoadingDataNames('TechMapBoardGetCurrentTechmap')
        this.filterTechnicBox = false
        httpPost({
            url: `${apiUrl}/technology/dashboard/working/technique/?ordering=${this.queryOrdering}&task_name=${this.queryTaskName}&days=${this.queryDays}&category=${this.queryCategory}&tractor=${this.queryTractor}`,
            isSecureRequest: true,
            detailResponse: true,
            body: {
                group_season: this.getCurrentSeason.id
            },
            onSuccess: json => {
                if (json.ok) {
                    this.technicListData = json.json
                    for (const technic of this.technicListData) {
                        if (!this.filterCategoryTechnic.converted.includes(technic.category_name)) {
                            this.filterCategoryTechnic.origin.push({id: technic.category, title: technic.category_name})
                            this.filterCategoryTechnic.converted.push(technic.category_name)
                        }
                        if (!this.filterTechnicName.converted.includes(`${technic.class_name} ${technic.model_name}`)) {
                            this.filterTechnicName.origin.push({id: technic.id, title: `${technic.class_name} ${technic.model_name}`})
                            this.filterTechnicName.converted.push(`${technic.class_name} ${technic.model_name}`)
                        }
                        if (!this.filterPlanTask.origin.includes(technic.task_name)) {
                            this.filterPlanTask.origin.push(technic.task_name)
                        }
                    }
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('TechMapBoardGetCurrentTechmap')
                // this.technicChartInitialization()
            }
        })
    }

    private fetchRatingTechnic() {
        httpPost({
            url: `${apiUrl}/technology/dashboard/technique/`,
            isSecureRequest: true,
            body: {
                group_season: this.getCurrentSeason.id
            },
            onSuccess: json => {
                this.technicRatingData = json
                for (const category of this.technicRatingData.tractors_rating) {
                    if (!this.filterCategoryRatingTechnic.converted.includes(category.category_name)) {
                        this.filterCategoryRatingTechnic.converted.push(category.category_name)
                    }
                }
                this.checkboxReverseTechnicRating()
                this.technicRatingChartInitialization()
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                //
            }
        })
    }

    private checkboxReverseTechnicRating() {
        this.onlyTechnicDataArr = []
        this.technicRatingData.tractors_rating.forEach((x: any) => {
            if (x.category_name === this.filterCategoryRatingTechnic.checked[0]) {
                this.onlyTechnicDataArr.push({workload: Math.round(x.workload), name: `${x.class_name} ${x.model_name} ${x.uid}`, category: x.category_name, tasks: x.tasks})
            }
        })
    }

    private cultureChartInitialization() {
        const ctx: any = document.getElementById('culture_chart')
        this.cultureChart = new Chart(ctx.getContext('2d'), {
            type: 'pie',
            data: {
                datasets: []
            },
            options: {
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: (context: any) => {
                                const value = `${context.label} ${context.raw} га`
                                return value
                            }
                        },
                        enabled: true // false
                    },
                    legend: {
                        display: false
                    }
                }
            }
            // plugins: [this.alwaysShowTooltip]
        })
        this.updateCultureChart()
    }

    private chartEvent(value: any) {
        const currentCulture = this.technologyTaskData.culture_data.culture_percentage.find((x: any) => (x.area / 10000).toFixed(2) === value)
        this.chartPurchaseCulture.cultureName = currentCulture.culture
        this.updateAnalyticsChart()
    }

    private analyticsChartInitialization() {
        const ctx: any = document.getElementById('analytics_chart')
        this.analyticsChart = new Chart(ctx.getContext('2d'), {
            type: 'line',
            data: {
                datasets: []
            },
            options: {
                plugins: {
                    legend: {
                        display: false
                    }
                },
                maintainAspectRatio: false,
                scales: {
                    x: {
                        grid: {
                            display: false,
                            drawBorder: false
                        }
                    },
                    y: {
                        beginAtZero: true,
                        grid: {
                            color: () => {
                                return '#E6EDFF'
                            },
                            drawBorder: false
                        }
                    }
                },
                animations: {
                    tension: {
                        duration: 1000,
                        easing: 'linear',
                        from: 0,
                        to: 0.5
                    }
                }
            }
        })
        this.updateAnalyticsChart()
    }

    private getTextOfEvent(event: any) {
        if (!this.eventArrOfName.includes(event) && event !== '') {
            this.eventArrOfName.push(event)
        }
        return this.eventArrOfName.indexOf(event) > 0 ? this.eventArrOfName.indexOf(event) : 0
    }

    private currentAnalyticsChartInitialization() {
        const ctx: any = document.getElementById('current_analytics_chart')
        this.currentAnalyticsChart = new Chart(ctx.getContext('2d'), {
            type: 'line',
            data: {
                datasets: []
            },
            options: {
                plugins: {
                    legend: {
                        display: false
                    }
                },
                maintainAspectRatio: false,
                scales: {
                    x: {
                        grid: {
                            display: false,
                            drawBorder: false
                        },
                        ticks: {
                            display: false
                        }
                    },
                    y: {
                        beginAtZero: true,
                        grid: {
                            color: () => {
                                return '#E6EDFF'
                            },
                            drawBorder: false
                        }
                    }
                },
                elements: {
                    point: {
                        radius: 0
                    }
                }
            }
        })
        this.updateCurrentAnalyticsChart()
    }

    private technicRatingChartInitialization() {
        const ctx: any = document.getElementById('technic_rating') || document.querySelector('.technic_rating')
        this.technicRatingChart = new Chart(ctx.getContext('2d'), {
            type: 'bar',
            data: {
                datasets: []
            },
            options: {
                indexAxis: 'y',
                elements: {
                    bar: {
                        borderWidth: 2
                    }
                },
                responsive: true,
                scales: {
                    y: {
                        stacked: true,
                        beginAtZero: true,
                        grid: {
                            display: false,
                            drawBorder: false
                        }
                    }
                },
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        callbacks: {
                            label: (context: any) => {
                                const values = [
                                    `Мероприятие: ${this.arrayOfRatingTask[context.raw[1]]}`,
                                    `Обработано: ${context.raw[0]} га`
                                ]
                                return values
                            }
                        }
                    }
                }
            }
        })
        this.updateTechnicRatingChart()
    }

    private updateTechnicRatingChart() {
        const technicTitle = this.onlyTechnicDataArr.map((x: any) => x.name) // this.technicCategoryByRating().map((x: any) => `${x.class_name} ${x.model_name} ${x.uid}`)
        const newData: any = {
            labels: technicTitle,
            datasets: []
        }

        let max: any = -Infinity
        this.onlyTechnicDataArr.forEach((x: any) => {
            if (x.tasks.length > max) {
                max = x.tasks.length
            }
        })

        const stackData: any = []
        this.onlyTechnicDataArr.forEach((x: any, index: any) => {
            if (x.tasks.length !== max) {
                let counter = x.tasks.length
                while (counter < max) {
                    x.tasks.push({area: 0, task_name: ''})
                    counter++
                }
            }
            x.tasks.forEach((y: any, indexY: any) => {
                if (stackData[indexY] === undefined) {
                    stackData[indexY] = []
                }
                stackData[indexY].push({area: y.area || 0, date: x.date, task: y.task_name})
            })
        })

        stackData.forEach((x: any) => {
            newData.datasets.push({
                label: 'Рейтинг техник',
                data: x.map((y: any) => [Math.round(y.area), this.getRatingTask(y.task)]),
                backgroundColor: (context: any) => {
                    return context.raw[0] > 0 ? this.colors[context.raw[1] % 9] : '#FFFFFF'
                },
                // barPercentage: 0.5,
                stack: 'Stack 1'
                // minBarLength: 20
            })
        })
        this.technicRatingChart.data = newData
        this.technicRatingChart.update()
    }

    private getRatingTask(task: any) {
        if (!this.arrayOfRatingTask.includes(task)) {
            this.arrayOfRatingTask.push(task)
        }
        return this.arrayOfRatingTask.indexOf(task)
    }

    private reverseTechnicRatingValue() {
        this.technicRatingChart.data.labels.reverse()
        this.technicRatingChart.data.datasets[0].data.reverse()
        this.technicRatingChart.update()
    }

    private updateCultureChart() {
        const newData: any = {
            labels: this.technologyTaskData.culture_data.culture_percentage.map((x: any) => x.culture),
            datasets: []
        }

        newData.datasets.push({
            label: 'Population',
            backgroundColor: this.technologyTaskData.culture_data.culture_percentage.map((x: any) => this.getColor(x.culture)),
            data: this.technologyTaskData.culture_data.culture_percentage.map((x: any) => (x.area / 10000).toFixed(2)),
            hoverOffset: 10
        })
        this.cultureChart.data = newData
        // console.log(newData)
        this.cultureChart.update()
    }

    private updateAnalyticsChart() {
        const newData: any = {
            labels: '',
            datasets: []
        }
        if (this.chartPurchaseCulture.dataName === 'data_by_year') {
            newData.labels = this.chartPurchaseCulture.date.map((x: any) => x.year)
            newData.datasets.push({
                label: 'Изменение: за последние 3 года',
                data: this.chartPurchaseCulture.date.map((x: any) => Math.round(x.value)),
                borderColor: '#638FFF',
                tension: 1
            })
        } else if (this.chartPurchaseCulture.dataName === 'data_by_month') {
            newData.labels = this.chartPurchaseCulture.date.map((x: any) => this.months[x.month - 1])
            newData.datasets.push({
                label: 'Изменение: за последние 12 месяцов',
                data: this.chartPurchaseCulture.date.map((x: any) => Math.round(x.value)),
                borderColor: '#638FFF',
                tension: 1
            })
        } else if (this.chartPurchaseCulture.dataName === 'data_by_month_days') {
            newData.labels = this.chartPurchaseCulture.date.map((x: any) => x.title)
            newData.datasets.push({
                label: 'Изменение: за последние 30 дней',
                data: this.chartPurchaseCulture.date.map((x: any) => Math.round(x.value)),
                borderColor: '#638FFF',
                tension: 1
            })
        } else if (this.chartPurchaseCulture.dataName === 'data_by_week') {
            newData.labels = this.chartPurchaseCulture.date.map((x: any) => `${x.day}.${x.month}`)
            newData.datasets.push({
                label: 'Изменение: за последние 7 дней',
                data: this.chartPurchaseCulture.date.map((x: any) => Math.round(x.value)),
                borderColor: '#638FFF',
                tension: 1
            })
        }
        this.analyticsChart.data = newData
        this.analyticsChart.update()
    }

    private updateCurrentAnalyticsChart() {
        const index = this.dayRangeOfFinancyAnalitics // this.currentAnalyticsData.fact_task_chart.map((x: any, i: any) => i)
        const newData: any = {
            labels: index || [],
            datasets: []
        }
        newData.datasets.push({
            label: 'Плановый расход',
            data: this.currentAnalyticsData.plan_task_chart || [],
            borderColor: '#638FFF',
            tension: 0.1
        })
        newData.datasets.push({
            label: 'Расход',
            data: this.currentAnalyticsData.fact_task_chart || [],
            borderColor: '#D41900',
            tension: 0.1
        })
        this.currentAnalyticsChart.data = newData
        this.currentAnalyticsChart.update()
    }

    private technicCategoryByRating() {
        const ratingTechnic: any = []
        for (const category of this.technicRatingData.tractors_rating) {
            if (category.category_name === this.filterCategoryRatingTechnic.checked[0]) {
                ratingTechnic.push(category)
            }
        }
        return ratingTechnic
    }

    // filter modal
    // private onSelectDay(item: any) {
    //     const index = this.filterDaysList.origin.find((x: any) => x.title.toLowerCase() === item.toLowerCase())
    //     if (index) {
    //         this.queryDays = index.value
    //         this.filterDaysList.selected = index.title
    //     }
    // }

    // check category
    private checkedCategoryTechnic(item: any) {
        const index = this.filterCategoryTechnic.checked.findIndex((x: any) => x === item.id)
        if (index === -1) {
            this.filterCategoryTechnic.checked.push(item.id)
        } else {
            this.filterCategoryTechnic.checked.splice(index, 1)
        }
    }

    private checkAllCategoryTechnic() {
        if (this.filterCategoryTechnic.checked.length === this.filterCategoryTechnic.origin.length) {
            this.filterCategoryTechnic.checked.splice(0, this.filterCategoryTechnic.checked.length)
        } else {
            this.filterCategoryTechnic.origin.forEach((x: any) => {
                if (!this.filterCategoryTechnic.checked.includes(x.id) ) {
                    this.filterCategoryTechnic.checked.push(x.id)
                }
            })
        }
    }

    // check technic
    private checkedTechnicName(item: any) {
        const index = this.filterTechnicName.checked.findIndex((x: any) => x === item.id)
        if (index === -1) {
            this.filterTechnicName.checked.push(item.id)
        } else {
            this.filterTechnicName.checked.splice(index, 1)
        }
    }

    private checkAllTechnicName() {
        if (this.filterTechnicName.checked.length === this.filterTechnicName.origin.length) {
            this.filterTechnicName.checked.splice(0, this.filterTechnicName.checked.length)
        } else {
            this.filterTechnicName.origin.forEach((x: any) => {
                if (!this.filterTechnicName.checked.includes(x.id)) {
                    this.filterTechnicName.checked.push(x.id)
                }
            })
        }
    }

    // check plan task
    private checkTechnicPlanTask(item: any) {
        const index = this.filterPlanTask.checked.findIndex((x: any) => x === item)
        if (index === -1) {
            this.filterPlanTask.checked.push(item)
        } else {
            this.filterPlanTask.checked.splice(index, 1)
        }
    }

    private checkAllTechnicPlanTask() {
        if (this.filterPlanTask.checked.length === this.filterPlanTask.origin.length) {
            this.filterPlanTask.checked.splice(0, this.filterPlanTask.checked.length)
        } else {
            this.filterPlanTask.origin.forEach((x: any) => {
                if (!this.filterPlanTask.checked.includes(x)) {
                    this.filterPlanTask.checked.push(x)
                }
            })
        }
    }

    private applyListTechnic() {
        if (this.filterCategoryTechnic.checked.length > 0) {
            for (const category of this.filterCategoryTechnic.checked) {
                this.queryCategory += `${category},`
            }
            this.queryCategory = this.queryCategory.substring(0, this.queryCategory.length - 1)
        }
        if (this.filterTechnicName.checked.length > 0) {
            for (const technic of this.filterTechnicName.checked) {
                this.queryTractor += `${technic},`
            }
            this.queryTractor = this.queryTractor.substring(0, this.queryTractor.length - 1)
        }
        if (this.filterPlanTask.checked.length > 0) {
            for (const task of this.filterPlanTask.checked) {
                this.queryTaskName += `${task},`
            }
            this.queryTaskName = this.queryTaskName.substring(0, this.queryTaskName.length - 1)
        }
        this.fetchListTechnic()
    }

    private technicSearch() {
        const searchResult = []
        for (const technic of this.technicListData) {
            if (technic.class_name.toLowerCase().includes(this.searchText.toLowerCase()) || technic.uid.toLowerCase().includes(this.searchText.toLowerCase())) {
                searchResult.push(technic)
            }
        }
        return searchResult
    }

    // rating technic
    private checkedCategoryRatingTechnic(item: any) {
        const index = this.filterCategoryRatingTechnic.checked.findIndex((x: any) => x === item)
        if (index === -1) {
            this.filterCategoryRatingTechnic.checked = []
            this.filterCategoryRatingTechnic.checked.push(item)
        } else {
            this.filterCategoryRatingTechnic.checked.splice(index, 1)
        }

        this.checkboxReverseTechnicRating()
    }

    private resetFilter() {
        this.queryCategory = ''
        this.queryTractor = ''
        this.queryTaskName = ''
        this.queryOrdering = 'performance'
        this.queryDays = 200
        this.dateFromCalendarTechnic = ''

        this.filterCategoryTechnic.checked = []
        this.filterTechnicName.checked = []
        this.filterPlanTask.checked = []

        this.fetchListTechnic()
    }

    // calc gradint dought chart
    private conicGradient(technic: any) {
        if (technic.area < 0.9) {
            return 0
        }
        if (technic.plan_area === 0) {
            return 360
        } else {
            return (technic.area / (technic.plan_area / 10000)) * 360
        }
    }

    // format number
    private formatExpences(expense: number) {
        return expense ? expense.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + '' : '0'
    }

    // select
    private selectCategoryCultureName(val: any) {
        this.chartPurchaseCulture.categoryName = this.chartPurchaseCulture.general.class
        this.chartPurchaseCulture.cultureName = this.chartPurchaseCulture.general.culture
        this.dataPurchaseCulture.selected = this.dataPurchaseCulture.origin.find((x: any) => x.culture_name === this.chartPurchaseCulture.general.culture)

        const category = this.dataPurchaseCulture.selected.culture_classes.find((x: any) => x.class_name === this.chartPurchaseCulture.general.class)
        if (category) {
            if (this.currentDatesIndex === 2) {
                this.chartPurchaseCulture.date = category.class_data[3].data
                this.chartPurchaseCulture.dataName = category.class_data[3].data_name
                return this.updateAnalyticsChart()
            } else if (this.currentDatesIndex === 3) {
                this.chartPurchaseCulture.date = category.class_data[2].data
                this.chartPurchaseCulture.dataName = category.class_data[2].data_name
                return this.updateAnalyticsChart()
            }
            this.chartPurchaseCulture.date = category.class_data[this.currentDatesIndex].data
            this.chartPurchaseCulture.dataName = category.class_data[this.currentDatesIndex].data_name
        }
        this.updateAnalyticsChart()
    }

    private getTractorImageByCategoryId(id: number) {
        switch (id) {
            case 1: return 'tractor'
            case 2: return 'combine'
            case 3: return 'tractor'
            case 4: return 'harvester'
            case 5: return 'tractor'
            case 6: return 'truck'
            case 7: return 'tanker'
            case 8: return 'loader'
            case 9: return 'dumper'
            case 10: return 'tractor'
            case 11: return 'tractor'
            default: return 'combine'
        }
    }

    private getColor(item: any) {
        const result = this.CultureColors.defined.find((x: any) => {
            if (item && item.toLowerCase().includes(x.crop.toLowerCase())) return x
        })
        if (result) return result.color

        const result2 = this.otherCultures.find((x: any) => {
            if (x.culture && item) {
                if (x.culture.toLowerCase().includes(item.toLowerCase()) ||
                    item.toLowerCase().includes(x.culture.toLowerCase())) {
                    return x
                }
            } else if (x.culture === item || (x.culture === '' && item === null) || (x.culture === null && item === '')) {
                return x
            }
        })
        if (result2) {
            return this.CultureColors.others[result2.index]
        } else {
            if (this.otherCultures.length > 0) {
                const index = this.otherCultures[this.otherCultures.length - 1].index
                this.otherCultures.push({
                    culture: item,
                    index: (index + 1) % 10
                })
                return this.CultureColors.others[index + 1]
            } else {
                this.otherCultures.push({
                    culture: item,
                    index: 0
                })
                return this.CultureColors.others[0]
            }
        }
    }

    private closeFilterTechnicBox() {
        this.filterTechnicBox = false
    }

    // change date Culture Name ( year - month - week )
    private changeDateCultureName(index: any) {
        this.currentDatesIndex = index.target.value
        this.selectCategoryCultureName(this.chartPurchaseCulture.categoryName)
    }

    // change - close
    private changeTechnicContentList(val: boolean) {
        this.technicSelectList = val ? true : false
        this.filterTechnicBox = false
        this.searchTechnicBox = false
    }

    private toggleDropdown() {
        this.isDropdownVisisble = !this.isDropdownVisisble
    }

    private close() {
        this.$router.push({
            name: 'farm',
            params: {
                farmId: this.$route.params.farmId
            }
        })
    }
}
