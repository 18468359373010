








































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component
export default class TimePicker extends Vue {
    @Prop({default: ''}) private header!: string
    @Prop({default: '157px'}) private width!: string
    @Prop({default: ''}) private value!: string
    @Prop({default: ''}) private onerror!: string
    private hour = '00'
    private minute = '00'

    private mounted() {
        this.onchangeTime(this.value, '')
    }

    @Watch('value')
    private onchangeTime(val: any, old: any) {
        if (val && val !== old) {
            if (val !== `${this.hour}:${this.minute}`) {
                const result = val.split(':')
                this.hour = result[0] ? `${result[0]}` : ''
                this.minute = result[1] ? `${result[1]}` : ''
                this.checkHour()
                this.checkMinute()
            }
        }
    }

    private checkHour() {
        const hour = Number(this.hour)
        this.hour = hour > 23 ? '23' : hour <= 0 ? '00' :
            hour.toString().length === 1 ? `0${hour}` : `${hour}`
        this.updateValue()
    }

    private checkMinute() {
        const minute = Number(this.minute)
        this.minute = minute > 59 ? '59' : minute <= 0 ? '00' :
            minute.toString().length === 1 ? `0${minute}` : `${minute}`
        this.updateValue()
    }

    private updateValue() {
        this.$emit('input', `${this.hour}:${this.minute}`)
    }
}
