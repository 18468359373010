





























































import { Component, Vue } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import { GetterNames, MutationNames } from '@/store/types'

@Component
export default class LayersGroup extends Vue {
    @Getter(GetterNames.getLayerType) private getLayerType!: any
    @Getter(GetterNames.getActiveIndicator) private getActiveIndicator!: any
    @Getter(GetterNames.getSuperResolution) private getSuperResolution!: any
    @Mutation(MutationNames.changeLayerType) private changeLayerType!: any
    @Mutation(MutationNames.setActiveIndicator) private setActiveIndicator!: any
    @Mutation(MutationNames.setSuperResolution) private setSuperResolution!: any
    private layersDropdown = false
    private indicators = [
        {
            name: 'rgb',
            title: 'map.snapshot'
        },
        {
            name: 'ndvi',
            title: 'headers.workspace.fields.indicators.indicators.vegetation.title'
        },
        {
            name: 'contrasted_ndvi',
            title: 'headers.workspace.fields.indicators.indicators.vegetation_contrast.title'
        },
        {
            name: 'ndmi',
            title: 'headers.workspace.fields.indicators.indicators.humidity.title'
        },
        {
            name: 'gndvi',
            title: 'headers.workspace.fields.indicators.indicators.nitrogen.title'
        },
        {
            name: 'clgreen',
            title: 'headers.workspace.fields.indicators.indicators.chlorophyll.title'
        }
    ]
    private layers = [
        {
            name: 'google',
            title: 'map.background.satellite'
        },
        {
            name: 'osm',
            title: 'map.background.scheme'
        }
    ]

    private getTitle(title: string) {
        return this.$i18n.t(title)
    }

    private ChangeIndicatorType(val: string) {
        this.setActiveIndicator(this.getActiveIndicator === val ? '' : val)
    }

    private toggleLayersDropdown() {
        this.layersDropdown = !this.layersDropdown
    }

    private closeLayersDropdown() {
        this.layersDropdown = false
    }
}
