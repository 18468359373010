






















































































import { Component, Vue, Prop } from 'vue-property-decorator'
import i18n from '@/i18n'
import DatePicker from '@/components/DatePicker.vue'
import {httpPost, httpPut} from '@/util/http'
import {apiUrl} from '@/domain/constants'
@Component({
    components: {
        DatePicker
    }
})
export default class ViewPastures extends Vue {
    @Prop({default: null}) private pastures!: any
    private isEditing = false

    private saveChanges() {
      if (!this.pastures) return
      httpPut({
        url:  `${apiUrl}/get/pasture/info/`,
        isSecureRequest: true,
        detailResponse: true,
        body: {
          kpc: this.pastures.kpc.value ? Number(this.pastures.kpc.value) : null,
          mpc: this.pastures.mpc.value ? Number(this.pastures.mpc.value) : null,
          horses: this.pastures.horses.value ? Number(this.pastures.horses.value) : null,
          improvement: this.pastures.improvements.value ? Number(this.pastures.improvements.value) : null,
          note: this.pastures.note.value ? this.pastures.note.value : ''
        },
        onSuccess: json => {
          if (json.ok) {
            this.$alert('Данные успешно обнавлены', 'Сохранено', 'success')
            this.$emit('saveChanges', 'newPastures')
            this.isEditing = false
          } else {
            this.$alert('Ошибка сервера', 'Ошибка', 'error')
          }
        },
        onError: error => {
          console.log(error)
        }
      })
    }

    private cancel() {
        this.isEditing = false
    }

}
