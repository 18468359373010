


























































import { Component, Vue, Watch } from 'vue-property-decorator'
import ChangeAgregateModel from './ChangeAgregateModel.vue'
import {httpGet, httpPost, httpPostFile} from '@/util/http'
import {apiUrl, apiUrl3} from '@/domain/constants'
import {Getter, Mutation} from 'vuex-class'
import {GetterNames, MutationNames} from '@/store/types'
import { CultureColors } from '@/data/FieldCulture'
import Farm from '@/models/Farm'

@Component({
  components: {
    ChangeAgregateModel
  }
})

export default class ByCultureView extends Vue {
    @Getter(GetterNames.getFarmFromRoute) private getFarmFromRoute!: Farm
    @Getter(GetterNames.getCurrentSeason) private getCurrentSeason!: any

    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    private CultureColors = CultureColors
    private otherCultures = new Array()
    private searchText = ''
    private days: any = []
    private dailyData: any = []
    private startDate = ''
    private daysCount = 0

    @Watch('getFarmFromRoute')
    private change(val: any) {
        this.fetchData()
    }

    private mounted() {
        const startDay = new Date().getFullYear() === this.getCurrentSeason.name ? new Date() : new Date(`${this.getCurrentSeason.name}-12-31`)
        startDay.setDate(startDay.getDate())
        this.startDate = startDay.toLocaleDateString("sv-SE")
        let currentMonth = {
            name: '',
            days: ['']
        }
        const yearStart = new Date(startDay.getFullYear(), 0, 0)
        const daysCount = Math.round((startDay.getTime() - yearStart.getTime()) / ( 1000 * 60 * 60 * 24))
        this.daysCount = daysCount
        for (let i = 0; i < daysCount; i++) {

            const day = new Date(this.startDate)
            day.setDate(startDay.getDate() - i)
            const month = day.toLocaleString('ru-RU', { month: 'long'})
            if (month !== currentMonth.name) {
                if (currentMonth.name !== '') {
                    this.days.push(currentMonth)
                }
                currentMonth = {
                    name: month,
                    days: []
                }
                currentMonth.days.push(day.toLocaleString('ru-RU', { day: 'numeric'}))
            } else {
                currentMonth.days.push(day.toLocaleString('ru-RU', { day: 'numeric'}))
            }
        }
        this.fetchData()
    }

    private getDateString(event: any) {
        const start = new Date(event.start_date)
        const end = event.end_date ? new Date(event.end_date) : new Date()
        return start.toLocaleString('ru-RU', { day: 'numeric', month: 'long'}) + ' - ' + end.toLocaleString('ru-RU', { day: 'numeric', month: 'long'})
    }

    private getCulturesList() {
        const searchValue = this.searchText.trim()
        const searchResult = []
        if (this.dailyData) {
            for (const culture of this.dailyData) {
                if (culture.culture.toLowerCase().includes(searchValue.toLowerCase()) ) {
                    searchResult.push(culture)
                }
            }
        }
        return searchResult
    }

    private openFarmRoute(routeName: any, id: any) {
        if (this.getFarmFromRoute) {
            this.$router.push({
                name: routeName,
                params: {
                    farmId: this.getFarmFromRoute.id.toString(),
                    taskId: id.toString()
                }
            }).catch(e => { /* */ })
        }
    }

    private dateDiffInDays(start: any, end: any) {
        const _MS_PER_DAY = 1000 * 60 * 60 * 24
        // console.log(start,end,this.startDate)
        const a = new Date(start ? start : this.startDate)
        const b = new Date(end ? end : this.startDate)
        // Discard the time and time-zone information.
        const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate())
        const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate())
        // console.log((utc1 - utc2) / _MS_PER_DAY + 1)
        return (utc1 - utc2) / _MS_PER_DAY + 1
    }

    private getStarterPosition(tasks: any) {
        const dates = tasks.filter((task: any) => task.end_date).map((task: any) => Number(this.dateDiffInDays(this.startDate, task.end_date)))
        if (dates) {
            return Math.min.apply(null, dates) - 1
        }
        return 0
    }

    private getShiftPosition(tasks: any, endDate: any) {
        const dates = tasks.map((task: any) => Number(this.dateDiffInDays(this.startDate, task.end_date)))
        const minDifference = Math.min.apply(null, dates)
        return this.dateDiffInDays(this.startDate, endDate) - minDifference
    }

    private fetchData() {
        this.setLoadingDataNames('fetchDailyData')
        httpGet({
            url:  `${apiUrl}/technology/fact/tasks/by/culture/?group_user=${this.$route.params.farmId}`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                if (json.ok) {
                    this.dailyData = json.json
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('fetchDailyData')
            }
        })
    }

    private getColor(item: any) {
        const result = this.CultureColors.defined.find((x: any) => {
            if (item && item.toLowerCase().includes(x.crop.toLowerCase())) return x
        })
        if (result) return result.color

        const result2 = this.otherCultures.find((x: any) => {
            if (x.culture && item) {
                if (x.culture.toLowerCase().includes(item.toLowerCase()) ||
                    item.toLowerCase().includes(x.culture.toLowerCase())) {
                    return x
                }
            } else if (x.culture === item || (x.culture === '' && item === null) || (x.culture === null && item === '')) {
                return x
            }
        })
        if (result2) {
            return this.CultureColors.others[result2.index]
        } else {
            if (this.otherCultures.length > 0) {
                const index = this.otherCultures[this.otherCultures.length - 1].index
                this.otherCultures.push({
                    culture: item,
                    index: (index + 1) % 10
                })
                return this.CultureColors.others[index + 1]
            } else {
                this.otherCultures.push({
                    culture: item,
                    index: 0
                })
                return this.CultureColors.others[0]
            }
        }
    }

    private downloadCultureData() {
        this.setLoadingDataNames('downloadCultureData')
        httpGet({
            url: `${apiUrl3}/techmap/by/culture/group_user/${this.$route.params.farmId}/excel/`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                if (json) {
                    if (json.json.file) {
                        const a = document.createElement('a')
                        a.href = json.json.file
                        // a.download = "egistic-kz.xlsx"
                        // document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
                        a.click()
                        a.remove()
                    }
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('downloadCultureData')
            }
        })
  }

}
