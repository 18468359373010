













































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import { GetterNames, MutationNames } from '@/store/types'
import i18n from '@/i18n'
import olLineString from 'ol/geom/LineString'
import olPolygon from 'ol/geom/Polygon'
import olStyle from 'ol/style/Style'
import olFill from 'ol/style/Fill'
import olStroke from 'ol/style/Stroke'
import olCircle from 'ol/style/Circle'
import olMultiPoint from 'ol/geom/MultiPoint'
import * as turf from '@turf/turf'

@Component
export default class Ruler extends Vue {
    @Getter(GetterNames.getRulerMode) private getRulerMode!: any
    @Mutation(MutationNames.setRulerMode) private setRulerMode!: any

    private drawnFeatures: any[] = []
    private helpToolTip: any = {
        text: i18n.t('buttons.basic.press_start'),
        measure: '',
        tipCoordinates: [0, 0],
        valueCoordinates: [0, 0],
        sketch: null,
        listener: null
    }

    private updateTipCoordinate(coordinate: any) {
        this.helpToolTip.tipCoordinates = coordinate
    }

    private styleFuncFactory() {
        return (feature: any) => {
            const type = feature.getGeometry().getType()
            let coordinates = []
            if (type === 'Point') coordinates = [feature.getGeometry().getCoordinates()]
            else if (type === 'LineString') coordinates = feature.getGeometry().getCoordinates()
            else if (type === 'Polygon') coordinates = [].concat.apply([], feature.getGeometry().getCoordinates())
            const styles = [
                new olStyle({
                    stroke: new olStroke({
                        color: '#FFC700',
                        width: 2,
                        lineDash: [8, 16]
                    }),
                    fill: new olFill({
                        color: 'rgba(255, 199, 0, 0.2)'
                    })
                }),
                new olStyle({
                    image: new olCircle({
                        radius: 5,
                        stroke: new olStroke({
                            color: '#FFFFFF',
                            width: 2
                        }),
                        fill: new olFill({
                            color: '#4B970F'
                        })
                    }),
                    geometry: new olMultiPoint(coordinates)
                })
            ]
            return styles
        }
    }

    @Watch('getRulerMode.active')
    private onchangeRulerMode() {
        this.drawnFeatures = []
    }

    private getLength(feature: any) {
        if (feature.geometry.type === "LineString") {
            return this.formatLength(feature.geometry.coordinates)
        } else if (feature.geometry.type === "Polygon") {
            return this.formatArea(feature.geometry.coordinates)
        } else if (feature.geometry.type === "Point") {
            const pt = turf.point(feature.geometry.coordinates)
            const converted: any = turf.toWgs84(pt)
            if (converted) {
                return `Lon: ${converted.geometry.coordinates[0].toFixed(6)}\n Lat: ${converted.geometry.coordinates[1].toFixed(6)}`
            }
            return '0'
        } else {
            return '5'
        }
    }

    private formatLength(geom: any) {
        const length = turf.length(turf.toWgs84(turf.lineString(geom))) * 1000
        let output = ''
        if (length > 100) {
            output = (Math.round(length / 1000 * 100) / 100) + ' ' + 'km'
        } else {
            output = (Math.round(length * 100) / 100) + ' ' + 'm'
        }
        return output
    }

    private formatArea(coordinates: any) {
        if (coordinates[0].length < 4) {
            return '0 m2'
        } else {
            const area = turf.area(turf.toWgs84(turf.polygon(coordinates)))
            let output = ''
            if (area > 10000) {
                output = Math.round((area / 10000) * 100) / 100 + ' ' + 'га'
            } else {
                output = Math.round(area * 100) / 100 + ' ' + 'm2'
            }
            return output
        }
    }

    private measureStart(evt: any) {
        this.helpToolTip.sketch = evt.feature
        this.helpToolTip.listener = this.helpToolTip.sketch.getGeometry().on('change', (evn: any) => {
            const geom = evn.target
            if (geom.getType() === 'Polygon') {
                this.helpToolTip.measure = this.formatArea(geom.getCoordinates())
                this.helpToolTip.valueCoordinates = geom.getInteriorPoint().getCoordinates().splice(0, 2)
                if (geom.getCoordinates()[0].length > 3) this.helpToolTip.text = 'Нажмите 2 раза чтобы завершить'
                else this.helpToolTip.text = i18n.t('buttons.basic.press_continue')
            } else if (geom.getType() === 'LineString') {
                this.helpToolTip.measure = this.formatLength(geom.getCoordinates())
                this.helpToolTip.valueCoordinates = geom.getLastCoordinate()
                if (geom.getCoordinates().length > 2) this.helpToolTip.text = 'Нажмите 2 раза чтобы завершить'
                else this.helpToolTip.text = i18n.t('buttons.basic.press_continue')
            }
        })
    }

    private measureEnd(evt: any) {
        this.helpToolTip.sketch = null
        this.helpToolTip.text = i18n.t('buttons.basic.press_start')
    }

    private getOverlayPosition(feature: any) {
        if (feature.geometry.type === "Polygon") {
            const polygon = new olPolygon(feature.geometry.coordinates)
            return polygon.getInteriorPoint().getCoordinates().splice(0, 2)
        } else if (feature.geometry.type === "LineString") {
            const linestring = new olLineString(feature.geometry.coordinates)
            return linestring.getLastCoordinate()
        } else if (feature.geometry.type === "Point") {
            const coordinates = feature.geometry.coordinates
            return coordinates
        }
        return [0, 0]
    }
}
