import { render, staticRenderFns } from "./OrderFarmData.vue?vue&type=template&id=402d1c95&scoped=true&"
import script from "./OrderFarmData.vue?vue&type=script&lang=ts&"
export * from "./OrderFarmData.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "402d1c95",
  null
  
)

export default component.exports