






















import { Component, Vue, Watch, Emit, Prop } from 'vue-property-decorator'

@Component
export default class AlertModal extends Vue {
    private isShown = false

    private show() {
        this.isShown = true
    }

    private hide() {
        this.isShown = false
    }
    @Emit('confirm')
    private confirm() {
        return {}
    }
}
