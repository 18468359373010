














































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import { GetterNames, MutationNames } from '@/store/types'
import BaseMenuItem from '@/components/BaseMenuItem.vue'
import Gistogram from '@/views/MainPage/HomeView/Sideboards/FarmBoard/SubfieldBoard/IndicatorsBoard/Gistogram.vue'
import {clgreenGistogram, ndviGistogram, ndmiGistogram, ndviContrastedGistogram} from '@/data/gistogramData'
import {indicatorsContent} from '@/data/tourContent'
import { httpPost } from '@/util/http'
import { apiUrl2, baseUrl } from '@/domain/constants'
import i18n from '@/i18n'
@Component({
    components: {
        BaseMenuItem,
        Gistogram
    }
})
export default class FieldPanel extends Vue {
    @Getter(GetterNames.getIsProblemZonesMode) private getIsProblemZonesMode!: any
    @Getter(GetterNames.getWeedinessImage) private getWeedinessImage!: any
    @Getter(GetterNames.getActiveIndicator) private getActiveIndicator!: any
    @Getter(GetterNames.getSubfieldIndicatorData) private getSubfieldIndicatorData!: any
    @Getter(GetterNames.getFieldRequestComparisonMode) private getFieldRequestComparisonMode !: boolean
    @Getter(GetterNames.getGuideTourActivators) private getGuideTourActivators!: any
    @Getter(GetterNames.getLastNewNotePoint) private getLastNewNotePoint!: any
    @Getter(GetterNames.getFertilizerResult) private getFertilizerResult!: any

    @Mutation(MutationNames.setFieldRequestComparisonMode) private setFieldRequestComparisonMode!: any
    @Mutation(MutationNames.setIsProblemZonesMode) private setIsProblemZonesMode!: any
    @Mutation(MutationNames.setWeedinessImage) private setWeedinessImage!: any
    @Mutation(MutationNames.setActiveIndicator) private setActiveIndicator!: any
    @Mutation(MutationNames.setSubfieldIndicatorData) private setSubfieldIndicatorData!: any
    @Mutation(MutationNames.setGuideTourActivators) private setGuideTourActivators!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    @Mutation(MutationNames.setFertilizerResult) private setFertilizerResult!: any

    private gistogramData = { 'clgreen': clgreenGistogram, 'ndvi': ndviGistogram, 'contrasted_ndvi': ndviContrastedGistogram, 'gndvi': ndviGistogram, 'ndmi': ndmiGistogram }
    private fertilizerRequestId = -1
    private weedinessRequestId = -1
    private fertilizerNorms: any = ['', '', '']
    private fullText = false
    private textShow = false

    private ChangeIndicatorType(val: string) {
        this.setActiveIndicator(this.getActiveIndicator === val ? '' : val)
    }

    private getRequestById(requestId: number) {
        return this.getSubfieldIndicatorData.data.find((x: any) => requestId === x.id)
    }

    private getIndicatorValue(type: string) {
        if (this.getSubfieldIndicatorData.data.length > 0) {
            const request = this.getRequestById(this.getSubfieldIndicatorData.currentRequestId)
            if (!request.is_present) return false
            if (type === 'ndvi') return request.ndvi ? request.ndvi.mean.toFixed(2) : ''
            if (type === 'contrastred_ndvi') return request.ndvi ? request.ndvi.mean.toFixed(2) : ''
            if (type === 'gndvi') return request.gndvi ? request.gndvi.mean.toFixed(2) : ''
            if (type === 'clgreen') return request.clgreen ? request.clgreen.mean.toFixed(2) : ''
            if (type === 'ndmi') return request.ndmi ? request.ndmi.mean.toFixed(2) : ''
        }
        return false
    }

    private getIndicatorColor(type: any, ranges: any[] ) {
        const value = this.getIndicatorValue(type === 'contrasted_ndvi' ? 'ndvi' : type)
        if (value) {
            const range = ranges.find(x => x.from <= value && x.to >= value)
            if (range) return range.color
        }
        return false
    }

    @Watch('getGuideTourActivators.indicatorsTour')
    private onchangeIndicatorsTour() {
        if (this.getGuideTourActivators.indicatorsTour) {
            (this.$refs.indicatorsModel as any).show()
            this.setFieldRequestComparisonMode(false)
            this.setFieldRequestComparisonMode(true)
        }
    }

    private endIndicatorsTour() {
        this.setGuideTourActivators({indicatorsTour: false})
    }

    private startIndicatorsTour(index: any) {
        if (index === 'end') {
            (this.$refs.finalModel as any).show()
            const model = this.$refs.defaultModel as any
            model.hide()
            this.endIndicatorsTour()
        } else {
            const content: any = indicatorsContent[index]
            const model = this.$refs.defaultModel as any
            model.show(indicatorsContent[index], {
                modelTop:  content.modelTop,
                modelBottom: content.modelBottom,
                modelLeft: content.modelLeft ? content.modelLeft : (content.nextIndex === 2 ? (window.innerWidth / 2 + 65 - 237.5) + 'px' : (window.innerWidth - 612) + 'px'),
                modelRight: content.modelRight,
                arrowTop: content.arrowTop,
                arrowLeft: content.arrowLeft
            })
        }
    }

    @Watch('getLastNewNotePoint')
    private setNoteModalActive() {
        if (this.getLastNewNotePoint.length !== 0) {
            (this.$refs.newNoteAlert as any).show()
        }
    }

    @Watch('getSubfieldIndicatorData.alertProblemZones')
    private showProblemZonesAlert() {
        if (!this.getSubfieldIndicatorData.alertProblemZones) return
        (this.$refs.alertModal as any).show()
        this.setSubfieldIndicatorData({alertProblemZones: false})
    }

    @Watch('getSubfieldIndicatorData.currentRequestId')
    private updateFertilizerOnChange() {
        this.updateFertilizerResult()
        this.getWeedinessData()
    }

    private getWeedinessData() {
        if (!this.getWeedinessImage.visibility) return
        if (this.getWeedinessImage.requestId === this.getSubfieldIndicatorData.currentRequestId) return
        if (this.weedinessRequestId === this.getSubfieldIndicatorData.currentRequestId) return
        this.setWeedinessImage({
            image: null,
            requestId: null,
            download: false
        })
        const request = this.getRequestById(this.getSubfieldIndicatorData.currentRequestId)
        if (!request.is_present) return
        this.weedinessRequestId = this.getSubfieldIndicatorData.currentRequestId
        this.setLoadingDataNames('indicatorsBoardGetWeediness')
        httpPost({
            url: `${apiUrl2}/get/classify/crop/`,
            isSecureRequest: true,
            body: {
                request: this.getSubfieldIndicatorData.currentRequestId
            },
            onSuccess: json => {
                if (json === 'error') {
                    alert(i18n.t('messages.errors.data'))
                    return
                }
                this.setWeedinessImage({image: baseUrl + json.raster_path, requestId: this.getSubfieldIndicatorData.currentRequestId})
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('indicatorsBoardGetWeediness')
            }
        })
    }

    @Watch('getFertilizerResult.visibility')
    private updateFertilizerResult() {
        if (!this.getFertilizerResult.visibility) return
        if (this.getFertilizerResult.requestId === this.getSubfieldIndicatorData.currentRequestId) return
        if (this.fertilizerRequestId === this.getSubfieldIndicatorData.currentRequestId) return
        this.setFertilizerResult({
            norm: [],
            requestId: -1,
            imgUrl: null,
            data: [],
            download: false
        })
        const request = this.getRequestById(this.getSubfieldIndicatorData.currentRequestId)
        if (!request.is_present) return
        this.fertilizerRequestId = this.getSubfieldIndicatorData.currentRequestId
        this.setLoadingDataNames('indicatorsBoardGetClasters')
        httpPost({
            url: `${apiUrl2}/get/cluster/`,
            isSecureRequest: true,
            body: {
                request: this.getSubfieldIndicatorData.currentRequestId,
                n_clusters: 3,
                ndvi: true,
                gndvi: true,
                clgreen: true
            },
            onSuccess: json => {
            if (json.detail) {
                alert(i18n.t('messages.instructions.processing'))
            } else {
                this.setFertilizerResult({imgUrl: baseUrl + json.raster_path, data: json.geometry.features, requestId: this.getSubfieldIndicatorData.currentRequestId})
                this.fertilizerNorms = ['', '', '']
            }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('indicatorsBoardGetClasters')
            }
        })
    }

    private getTextsOfType(type: string) {
        if (type === 'clgreen') {
            return i18n.t('headers.workspace.fields.indicators.indicators.chlorophyll.text')
        }
        if (type === 'ndvi') {
            return i18n.t('headers.workspace.fields.indicators.indicators.vegetation.text')
        }
        if (type === 'contrasted_ndvi') {
            return i18n.t('headers.workspace.fields.indicators.indicators.vegetation.text')
        }
        if (type === 'ndmi') {
            return i18n.t('headers.workspace.fields.indicators.indicators.humidity.text')
        }
        if (type === 'gndvi') {
            return i18n.t('headers.workspace.fields.indicators.indicators.nitrogen.text')
        }
    }

}
