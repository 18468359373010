










































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import { GetterNames, MutationNames } from '@/store/types'
import { Route } from 'vue-router'
import { httpPostFormData } from '@/util/http'
import { apiUrl } from '@/domain/constants'
import i18n from '@/i18n'
import zipcelx from 'zipcelx'
import UploadAgroChem from '@/models/UploadAgroChem'
@Component({
  components: {
  }
})
export default class OrderAgroChem extends Vue {
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: Route
    @Getter(GetterNames.getFarmFromRoute) private getFarmFromRoute!: any
    @Getter(GetterNames.getUploadAgroChemResult) private getUploadAgroChemResult!: any
    @Mutation(MutationNames.setUploadAgroChemResult) private setUploadAgroChemResult!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    private fields: any = {
        origin: [],
        converted: []
    }
    private dropdown = true
    private fileOpen = {
        index: -1,
        type: ''
    }
    private generalConclusionFile: any = null

    private toggleField(field: any, index: any) {
        if (this.getUploadAgroChemResult.data[index]) {
            this.getUploadAgroChemResult.data[index].toggleActive()
            if (this.getUploadAgroChemResult.data[index].active && field.field.id) {
                this.setUploadAgroChemResult({selectedField: field.field.id})
            } else {
                this.setUploadAgroChemResult({selectedField: undefined})
            }
        }
    }

    private onSelectField(index: number, item: any) {
        const field = this.fields.origin.find((x: any) => `№${x.name}` === item)
        if (field) {
            if (this.getUploadAgroChemResult.data.find((x: any) => x.field.id === field.id)) return
            if (this.getUploadAgroChemResult.data[index]) {
                this.getUploadAgroChemResult.data[index].setSelectedField(
                    field.id,
                    `№${field.name}`,
                    this.getFarmFromRoute.getCenter(field.id.toString()),
                    this.getFarmFromRoute.getZoom(field.id.toString()),
                    field.geom.coordinates
                )
                this.setUploadAgroChemResult({selectedField: field.id})
            }
        }
    }

    @Watch('getFarmFromRoute')
    private getCurrentFarmFields() {
        if (this.getFarmFromRoute) {
            this.fields.origin = this.getFarmFromRoute.fields
            this.fields.converted = this.getFarmFromRoute.fields.map((x: any) => {
                return `№${x.name}`
            })
        }
    }

    private addField() {
        this.getUploadAgroChemResult.data.push(new UploadAgroChem())
    }

    private removeField(index: any) {
        if (this.getUploadAgroChemResult.data[index]) {
            this.getUploadAgroChemResult.data.splice(index, 1)
        }
    }

    private mounted() {
        this.setUploadAgroChemResult({
            data: [new UploadAgroChem()],
            selectedField: undefined,
            draw: false,
            selectedPoint: {
                fieldId: null,
                fieldIndex: null,
                pointIndex: null
            }
        })
        this.getCurrentFarmFields()
    }

    private openFileUpload(index: any, type: any) {
        const upload = this.$refs.uploadfile as any
        if (upload) {
            this.fileOpen.index = index
            this.fileOpen.type = type
            upload.click()
        }
    }

    private filesUploaded(files: any) {
        if (files.length === 0) return
        if (this.fileOpen.index === -1) {
            this.generalConclusionFile = files[0]
        } else {
            if (this.getUploadAgroChemResult.data[this.fileOpen.index]) {
                if (this.fileOpen.type === 'conclusion') {
                    this.getUploadAgroChemResult.data[this.fileOpen.index].addConclusion(files[0])
                } else if (this.fileOpen.type === 'result') {
                    // TODO
                    this.getUploadAgroChemResult.data[this.fileOpen.index].addResult(files[0])
                }
            }
        }
        const upload = this.$refs.uploadfile as any
        if (upload) {
            upload.value = ''
        }
    }

    private uploadNewAgroChemResult() {
        const formData = new FormData()
        const data = []
        for (const field of this.getUploadAgroChemResult.data) {
            const result = field.getPostBody()
            if (result) {
                data.push(result)
                if (field.conclusion) {
                    formData.append(`divided_${field.field.id}`, field.conclusion)
                }
            }
        }
        if (data.length === 0) return
        formData.append('agro_analysis', JSON.stringify(data))
        if (this.generalConclusionFile) {
            formData.append('result_file', this.generalConclusionFile)
        }
        this.setLoadingDataNames('uploadAgroChemPostNewResult')
        httpPostFormData({
            url: `${apiUrl}/agro_analysis/create/by/front/`,
            isSecureRequest: true,
            detailResponse: true,
            body: formData,
            onSuccess: json => {
                if (json.ok) {
                    this.$alert(i18n.t('messages.success.successfully_updated') as string, 'Добавлено', 'success').then(() => {
                        this.setLoadingDataNames('uploadAgroChemPostNewResult')
                    })
                } else {
                    this.$alert(JSON.stringify(json.json), 'Ошибка', 'error')
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.close()
            }
        })
    }

    private downloadExcel(field: any, name: any) {
        const sheetData = [
            [
                {
                    value: i18n.t('headers.workspace.farm.agrochem_analys.latitude'),
                    type: 'string'
                },
                {
                    value: i18n.t('headers.workspace.farm.agrochem_analys.longitude'),
                    type: 'string'
                },
                {
                    value: 'pH',
                    type: 'string'
                },
                {
                    value: i18n.t('headers.workspace.farm.agrochem_analys.phosphorus.name'),
                    type: 'string'
                },
                {
                    value: i18n.t('headers.workspace.farm.agrochem_analys.potassium.name'),
                    type: 'string'
                },
                {
                    value: i18n.t('headers.workspace.farm.agrochem_analys.nitrogen.name'),
                    type: 'string'
                },
                {
                    value: i18n.t('headers.workspace.farm.agrochem_analys.sulfur.name'),
                    type: 'string'
                },
                {
                    value: i18n.t('headers.workspace.farm.agrochem_analys.humus.name'),
                    type: 'string'
                }
            ]
        ]

        if (field) {
            for (const point of field.points) {
                sheetData.push([
                    {
                        value: point.lat.text,
                        type: 'string'
                    },
                    {
                        value: point.lon.text,
                        type: 'string'
                    },
                    {
                        value: point.ph.text,
                        type: 'string'
                    },
                    {
                        value: point.phosphorus.text,
                        type: 'string'
                    },
                    {
                        value: point.potassium.text,
                        type: 'string'
                    },
                    {
                        value: point.nitrogen.text,
                        type: 'string'
                    },
                    {
                        value: point.sulfur.text,
                        type: 'string'
                    },
                    {
                        value: point.humus.text,
                        type: 'string'
                    }
                ])
            }
        }

        const config = {
            filename: name,
            sheet: {
                data: sheetData
            }
        }
        zipcelx(config)
    }

    private close() {
        this.$router.push(`/argochem/${this.currentRoute.params.farmId}`)
    }

}
