











































































































































import { Component, Vue } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import { GetterNames, MutationNames } from '@/store/types'
import { httpPost } from '@/util/http'
import { apiUrl } from '@/domain/constants'
import i18n from '@/i18n'
import {egisticLogo, pdfWaterMark} from '@/data/pdfImageData'
import NewOperationModel from './NewOperationModel.vue'
import NewProductModel from './NewProductModel.vue'
import ProductHistory from './ProductHistory.vue'
import { makePDF, PdfActions } from '@/utils/services'
@Component({
    components: {
        NewOperationModel,
        NewProductModel,
        ProductHistory
    }
})
export default class StorageRemainsView extends Vue {
    @Getter(GetterNames.getSidebarModel) private getSidebarModel!: any
    @Getter(GetterNames.getPermissions) private getPermissions!: any
    @Getter(GetterNames.getLoadingDataNames) private getLoadingDataNames!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    private egisticLogo: any = egisticLogo
    private pdfWaterMark: any = pdfWaterMark
    private storages: any = []
    private today: any = ''
    private excelData: any = []

    private normalizeTypes(types: any) {
        let str = ''
        for (const type of types) {
            str += type + '; '
        }
        return str
    }

    private getTotalAmount(storage: any) {
        let amount = 0
        for (const product of storage.current_products) {
            amount += product.amount * product.cost
        }
        return amount
    }

    private mounted() {
        this.fetchStorages()
        const date = new Date()
        this.today = date.toLocaleString('ru', { hour12: false, day: 'numeric', month: 'numeric', year: 'numeric',  hour: "numeric", minute: "numeric"})
    }

    private fetchStorages() {
        this.setLoadingDataNames('storageRemainsFetchStorages')
        httpPost({
            url: `${apiUrl}/storage/get/products/`,
            isSecureRequest: true,
            body: {
                storages: JSON.parse(this.$route.params.storages) ? JSON.parse(this.$route.params.storages) : []
            },
            onSuccess: json => {
                this.storages = json
            },
            doFinally: () => {
                this.setLoadingDataNames('storageRemainsFetchStorages')
            }
        })
    }

    private getExcelData(products: any) {
        this.excelData = []
        if (products.length === 0) {
            this.excelData.push({
                '№': '',
                [i18n.t('headers.workspace.storage.logs.fields.title') as string]: '',
                [i18n.t('headers.workspace.storage.logs.fields.amount') as string]: '',
                [i18n.t('headers.workspace.storage.logs.fields.scaling') as string]: '',
                [i18n.t('headers.workspace.storage.logs.fields.cost') as string]: '',
                [i18n.t('headers.workspace.storage.logs.fields.sum') as string]: ''
            })
        } else {
            products.forEach((product: any, index: any) => {
                this.excelData.push({
                    '№': index + 1,
                    [i18n.t('headers.workspace.storage.logs.fields.title') as string]: product.title,
                    [i18n.t('headers.workspace.storage.logs.fields.amount') as string]: product.amount,
                    [i18n.t('headers.workspace.storage.logs.fields.scaling') as string]: product.unit,
                    [i18n.t('headers.workspace.storage.logs.fields.cost') as string]: product.cost,
                    [i18n.t('headers.workspace.storage.logs.fields.sum') as string]: Number(product.amount) * Number(product.cost)
                })
            })
        }
        setTimeout(() => this.downloadExcel(), 1000)
    }

    private downloadExcel() {
        const downloader: any = document.getElementById('downloadStorageExcel')
        if (downloader) {
            downloader.click()
        }
    }

    private printPdf(storages: any) {
        const docDefinition = {
            pageSize: {
                width: 842,
                height: 595
            },
            pageMargins: [30, 30, 30, 30],
            background: [
                {
                    image: this.pdfWaterMark,
                    margin: [386, 0, 0, 0]
                }
            ],
            content: this.getPdfTableBody(storages)
        }
        makePDF(PdfActions.PRINT, docDefinition)
    }

    private getPdfTableBody(storages: any) {
        const date = new Date()
        const today = date.toLocaleString('ru', { hour12: false, day: 'numeric', month: 'numeric', year: 'numeric',  hour: "numeric", minute: "numeric"})
        const content: any = [
            {
                image: this.egisticLogo,
                margin: [0, 10, 0, 0],
                width: 91.14,
                height: 30,
                alignment: 'center'
            },
            {
                text: i18n.t('headers.workspace.profile.storage.storage_remains'),
                fontSize: 16,
                bold: true,
                margin: [0, 24, 0, 0],
                alignment: 'center'
            },
            {
                text: today,
                fontSize: 16,
                bold: true,
                margin: [0, 8, 0, 0],
                alignment: 'center'
            }
        ]
        for (const storage of storages) {
            content.push({
                text: storage.group_users_name + ' - ' + storage.title,
                fontSize: 16,
                bold: true,
                margin: [16, 16, 0, 0]
            })
            content.push({
                margin: [16, 8, 0, 0],
                columns: [
                    {
                        width: 'auto',
                        text: i18n.t('headers.workspace.storage.logs.fields.type') + ':',
                        fontSize: 12,
                        bold: true
                    },
                    {
                        width: 'auto',
                        text: this.normalizeTypes(storage.types_name),
                        fontSize: 12,
                        margin: [12, 0, 0, 0]
                    }
                ]
            })
            content.push({
                margin: [16, 8, 0, 0],
                columns: [
                    {
                        width: 'auto',
                        text: i18n.t('headers.workspace.storage.logs.fields.desc') + ':',
                        fontSize: 12,
                        bold: true
                    },
                    {
                        width: 'auto',
                        text: storage.description === '' ? i18n.t('messages.errors.no_description') : storage.description,
                        fontSize: 12,
                        margin: [12, 0, 0, 0]
                    }
                ]
            })
            const table: any = []
            const row: any = []
            const tableHeaders = [
                '№',
                i18n.t('headers.workspace.storage.logs.fields.title'),
                i18n.t('headers.workspace.storage.logs.fields.amount'),
                i18n.t('headers.workspace.storage.logs.fields.scaling'),
                i18n.t('headers.workspace.storage.logs.fields.cost'),
                i18n.t('headers.workspace.storage.logs.fields.sum')]
            tableHeaders.forEach((e: any) => {
                row.push({text: e, fontSize: 14})
            })
            table.push(row)
            storage.current_products.forEach((product: any, index: any) => {
                table.push([
                    {text: index + 1, fortSize: 14},
                    {text: product.title, fontSize: 14},
                    {text: product.amount, fontSize: 14},
                    {text: product.unit, fontSize: 14},
                    {text: product.cost + i18n.t('general.units.tg'), fontSize: 14},
                    {text: (Number(product.cost) * Number(product.amount)) + '' + i18n.t('general.units.tg'), fontSize: 14}
                ])
            })
            table.push([
                {text: ''},
                {text: ''},
                {text: ''},
                {text: ''},
                {text: 'Итого:', fontSize: 14},
                {text: this.getTotalAmount(storage) + '' + i18n.t('general.units.tg'), fontSize: 14}
            ])
            content.push(
                {
                    margin: [0, 24, 0, 30],
                    table: {
                        widths: [40, 250, 90, 60, 130, 130],
                        headerRows: 1,
                        body: table
                    }
                }
            )
        }
        return content
    }

    private openStorageLogs(id: any) {
        this.$router.push(
            {
                name: 'storagelogs',
                params: {
                    storages: JSON.stringify(this.$route.params.storages),
                    storageId: id
                }
            }
        )
    }

    private close() {
        this.$router.push(`/storage`)
    }
}
