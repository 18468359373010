


































































































































































































































































































































































































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import { GetterNames, MutationNames } from '@/store/types'
import { permissionTypes } from '@/data/rolesData'
import NewEventModal from './NewEventModal.vue'
import { apiUrl3 } from '@/domain/constants'
import { httpDelete, httpPut } from '@/util/http'
import i18n from '@/i18n'
import EventsPrintModalView from '@/views/MainPage/HomeView/Sideboards/FarmBoard/TechMapBoard/components/EventsPrintModalView.vue'

@Component({
    components: {
      EventsPrintModalView,
        NewEventModal
    }
})
export default class EventsView extends Vue {
    @Prop({type: Array, default: () => []}) private events!: any
    @Prop({type: String, default: ''}) private day!: string
    @Prop({type: Boolean, default: false}) private isField!: boolean
    @Getter(GetterNames.getPermissions) private getPermissions!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    private splideOptions = {
        rewind : false,
        autoWidth: true,
        pagination: false,
        gap: '8px',
        padding: {
            left: 40,
            right: 40
        }
    }
    private selected = {
        event: -1,
        data: 0,
        user: 0,
        complex: 0,
        preparation: 0,
        storage: 0,
        expenses: 0
    }

    get getUsers() {
        const event = this.events.find((x: any) => x.id === this.selected.event)
        if (event) {
            if (event.users_read[this.selected.user]) {
                return event.users_read[this.selected.user]
            } else return null
        } else return null
    }

    get getComplex() {
        const event = this.events.find((x: any) => x.id === this.selected.event)
        if (event) {
            if (event.tractor_params[this.selected.complex]) {
                return event.tractor_params[this.selected.complex]
            } else return null
        } else return null
    }

    get getPreparation() {
        const event = this.events.find((x: any) => x.id === this.selected.event)
        if (event) {
            if (event.preparations_read[this.selected.preparation]) {
                return event.preparations_read[this.selected.preparation]
            } else return null
        } else return null
    }

    get getSeed() {
        const event = this.events.find((x: any) => x.id === this.selected.event)
        if (event) {
            if (event.seeds_read[0]) {
                return event.seeds_read[0]
            } else return null
        } else return null
    }

    get getStorage() {
        const event = this.events.find((x: any) => x.id === this.selected.event)
        if (event) {
            if (event.storage_products_read[this.selected.storage]) {
                return event.storage_products_read[this.selected.storage]
            } else return null
        } else return null
    }

    get getExpense() {
        const event = this.events.find((x: any) => x.id === this.selected.event)
        if (event) {
            if (event.addition_costs_read[this.selected.expenses]) {
                return event.addition_costs_read[this.selected.expenses]
            } else return null
        } else return null
    }

    private getStatus(event: any, color: boolean) {
        if (event.percentage >= 100) {
            return color ? '#4B970F' : 'Выполнено'
        } else if (event.end_date) {
            const today = new Date()
            const endDate = new Date(event.end_date)
            if (today > endDate) {
                return color ? '#D51900' : 'Просрочено'
            }
        }
        return color ? '#FFC700' : 'В процессе'
    }

    private getFiledsNames(fields: any) {
        return fields.map((x: any) => `№${x.name}`)
    }

    private getPermissionTypes(roles: any) {
        const arr = []
        for (const role of permissionTypes) {
            if (roles.includes(role.num)) {
                arr.push(role.name)
            }
        }
        return arr.join(', ')
    }

    private selectEvent(id: number) {
        if (this.selected.event === id) {
            this.selected.event = -1
        } else {
            this.selected.event = id
            this.selected.data = 0
            this.selected.complex = 0
            this.selected.preparation = 0
            this.selected.storage = 0
            this.selected.expenses = 0
        }
    }

    private normalizeDate(date: string) {
        if (date) {
            return date.substring(8, 10) + '.' + date.substring(5, 7) + '.' + date.substring(0, 4)
        } else {
            return this.$i18n.t('general.dates.format_date')
        }
    }

    private changeStatus(id: number, status: boolean) {
        this.$confirm(`Вы дествительно хотите изменить статус мероприятие на "${!status ? 'Сделано' : 'Не сделано'}"?`, 'Предупреждение', 'info').then(() => {
            this.setLoadingDataNames('EventsModalChangeStatus')
            httpPut({
                url: `${apiUrl3}/techmap/task/field/${id}/`,
                isSecureRequest: true,
                detailResponse: true,
                body: {
                    is_finished: !status
                },
                onSuccess: json => {
                    if (json.ok) {
                        this.$alert('Статус мероприятие успешно обновлено', 'Обновлено', 'success')
                        this.$emit('reload')
                    } else {
                        this.$alert(`${JSON.stringify(json.json)}`, 'Ошибка', 'error')
                    }
                },
                onError: error => {
                    console.log(error)
                },
                doFinally: () => {
                    this.setLoadingDataNames('EventsModalChangeStatus')
                }
            })
        }).catch(e => {/* */})
    }

    private deleteEvent(id: number) {
        let deleteUrl = `${apiUrl3}/techmap/task/${id}/`
        if (this.isField) {
            deleteUrl = `${apiUrl3}/techmap/task/field/${id}/`
        }
        this.$confirm('Вы уверены что хотите удалить мероприятие?', 'Подтверждение', 'warning').then(() => {
            this.setLoadingDataNames('EventsViewDeleteEvent')
            httpDelete({
                url: deleteUrl,
                isSecureRequest: true,
                detailResponse: true,
                onSuccess: json => {
                    if (json.ok) {
                        this.$alert('Мероприятие успешно удалено', 'Удалено', 'success')
                        this.$emit('reload')
                    } else {
                        this.$alert(`${JSON.stringify(json.json)}`, 'Ошибка', 'error')
                    }
                },
                onError: error => {
                    console.log(error)
                },
                doFinally: () => {
                    this.setLoadingDataNames('EventsViewDeleteEvent')
                }
            })
        }).catch(e => { /* */ })
    }
}
