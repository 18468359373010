var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((_vm.getCurrentFarmFields()),function(field,i){return _c('vl-feature',{key:'field'+i,attrs:{"properties":{
            id: field.id,
            type: 'field'    
        }}},[_c('vl-geom-multi-polygon',{attrs:{"coordinates":field.geom.coordinates}}),_c('vl-style-box',[_c('vl-style-fill',{attrs:{"color":"rgba(255,255,255,0)"}}),(!_vm.getAgroChemData.selectedFields.includes(field.id) ||
                !_vm.getAgroChemData.mapSelection)?_c('vl-style-text',{attrs:{"text":("№" + (field.name)),"scale":_vm.textScale,"overflow":true,"color":"rgb(255,255,255)"}},[_c('vl-style-fill',{attrs:{"color":'#FFFFFF'}}),_c('vl-style-stroke',{attrs:{"color":'rgb(34, 34, 34)'}})],1):_vm._e(),_c('vl-style-stroke',{attrs:{"color":"rgb(255,255,255)","width":1}})],1)],1)}),_vm._l((_vm.getAgroChemData.selectedFields),function(fieldId,index){return _c('vl-feature',{key:index,attrs:{"properties":{
            id: fieldId,
            type: 'chosenfield'
        }}},[_c('vl-geom-multi-polygon',{attrs:{"coordinates":_vm.getFieldbyId(fieldId).geom.coordinates}}),_c('vl-style-box',[_c('vl-style-fill',{attrs:{"color":'rgba(255,255,255, 0.12)'}}),_c('vl-style-stroke',{attrs:{"color":"rgb(255,255,255)","width":2}})],1),(_vm.getAgroChemData.mapSelection)?[_c('vl-geom-point',{attrs:{"coordinates":_vm.getFieldCenter(_vm.getFieldbyId(fieldId))}}),_c('vl-style-box',[_c('vl-style-text',{attrs:{"scale":2,"text":("" + (_vm.getAgroChemData.selectedFields.findIndex(function (x) { return x === fieldId; })+1))}},[_c('vl-style-fill',{attrs:{"color":"#FFFFFF"}})],1),_c('vl-style-circle',{attrs:{"radius":20,"coordinates":_vm.getFieldCenter(_vm.getFieldbyId(fieldId))}},[_c('vl-style-fill',{attrs:{"color":"green"}}),_c('vl-style-stroke',{attrs:{"color":"white","width":2}})],1)],1)]:_vm._e()],2)}),_c('vl-layer-vector',{attrs:{"overlay":true}},[_c('vl-source-vector',{attrs:{"ident":"modify-target","features":_vm.modifyPoints},on:{"update:features":function($event){_vm.modifyPoints=$event}}},[_vm._l((_vm.getAgroChemData.fieldPoints),function(field){return _vm._l((field.points),function(point,index){return _c('vl-feature',{key:'point.'+point.id+'.'+index,attrs:{"properties":{
                    id: field.divided_cadastre_user,
                    pointid: point.id,
                    type: 'fieldpoint'
                }}},[_c('vl-geom-point',{attrs:{"coordinates":point.geom.coordinates}}),_c('vl-style-box',[_c('vl-style-circle',{attrs:{"radius":_vm.getPointRadius()}},[_c('vl-style-fill',{attrs:{"color":"white"}}),_c('vl-style-stroke',{attrs:{"color":"white","width":1}})],1)],1),(_vm.selectedPointId === point.id)?_c('vl-overlay',{attrs:{"position":point.geom.coordinates}},[_c('div',{staticClass:"point"},[_c('div',{staticClass:"delete_btn",on:{"click":function($event){return _vm.removePoint(field.divided_cadastre_user, point.id)}}},[_vm._v(_vm._s(_vm.$t('buttons.basic.actions.delete')))])])]):_vm._e()],1)})})],2)],1),_c('vl-layer-vector',[_c('vl-source-vector',{attrs:{"ident":"draw-point","features":_vm.drawnPoint},on:{"update:features":function($event){_vm.drawnPoint=$event}}})],1),(_vm.getAgroChemData.pointEdit && _vm.getAgroChemData.editType === 'add_icon')?_c('vl-interaction-draw',{attrs:{"source":"draw-point","type":"Point"}}):_vm._e(),((_vm.getAgroChemData.pointEdit && _vm.getAgroChemData.editType === 'delete_icon') || _vm.getAgroChemData.mapSelection)?_c('vl-interaction-select',{attrs:{"features":_vm.selectedFeatures},on:{"update:features":function($event){_vm.selectedFeatures=$event}}}):_vm._e(),(_vm.getAgroChemData.pointEdit && _vm.getAgroChemData.editType === 'edit_icon')?_c('vl-interaction-modify',{attrs:{"source":"modify-target"}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }