import {IState} from './'

export type State = IState

export const Action = {
    fetchDividedLegends: 'fetchDividedLegends',
    fetchFieldIndicatorData: 'fetchFieldIndicatorData',
    fetchFieldsNoteImage: 'fetchFieldsNoteImage'
}

export const Mutation = {
    setLegendCoordinatesArray: 'setLegendCoordinatesArray',
    setLastNewNotePoint: 'setLastNewNotePoint',
    setFieldsRating: 'setFieldsRating',
    setSubfieldIndicatorData: 'setSubfieldIndicatorData',
    setSubfieldNoteData: 'setSubfieldNoteData',
    setCadastreData: 'setCadastreData',
    setTaskData: 'setTaskData',
    setCurrentPlaceNotesCoordination: 'setCurrentPlaceNotesCoordination',
    setNormalDateRound: 'setNormalDateRound'
}

export const Getter = {
    getLegendCoordinatesArray: 'getLegendCoordinatesArray',
    getActivatePassportEdit: 'getActivatePassportEdit',
    getLastNewNotePoint: 'getLastNewNotePoint',
    getFieldsRating: 'getFieldsRating',
    getSubfieldIndicatorData: 'getSubfieldIndicatorData',
    getSubfieldNoteData: 'getSubfieldNoteData',
    getFieldFromRoute: 'getFieldFromRoute',
    getCadastreData: 'getCadastreData',
    getTaskData: 'getTaskData',
    getCurrentPlaceNotesCoordination: 'getCurrentPlaceNotesCoordination',
    getNormalDateRound: 'getNormalDateRound'
}
