


















































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter, Mutation } from 'vuex-class'
import { ActionNames, GetterNames, MutationNames } from '@/store/types'
import { httpGet } from '@/util/http'
import { apiUrl } from '@/domain/constants'
import { Route } from 'vue-router'
import i18n from '@/i18n'
import FarmMapControllers from './components/FarmMapControllers.vue'
import FieldIndicatorsSlider from './components/FieldIndicatorsSlider.vue'
import FarmIndicatorsSlider from './components/FarmIndicatorsSlider.vue'
import AgroChemControllers from './components/AgroChemControllers.vue'
import RulerButton from './components/RulerButton.vue'
import LayersGroup from './components/LayersGroup.vue'
@Component({
    components: {
        FarmMapControllers,
        FieldIndicatorsSlider,
        FarmIndicatorsSlider,
        RulerButton,
        AgroChemControllers,
        LayersGroup
    }
})
export default class MapControllers extends Vue {
    @Action(ActionNames.updateMapDataInRegion) private updateMapDataInRegion!: any

    @Getter(GetterNames.getMapData) private mapData !: any
    @Getter(GetterNames.getLoadingDataNames) private getLoadingDataNames!: any
    @Getter(GetterNames.getLoadingTractorInfo) private getLoadingTractorInfo!: any
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: Route
    @Getter(GetterNames.getIsNotesModeActive) private getIsNotesModeActive!: any
    @Getter(GetterNames.getFarmFromRoute) private getFarmFromRoute!: any
    @Getter(GetterNames.getFieldFromRoute) private getFieldFromRoute!: any
    @Getter(GetterNames.getSideboardIsShown) private sideboardIsShown !: any
    @Getter(GetterNames.getSubfieldIndicatorData) private getSubfieldIndicatorData!: any
    @Getter(GetterNames.getWeedinessImage) private getWeedinessImage!: any
    @Getter(GetterNames.getGuideTourActivators) private getGuideTourActivators!: any
    @Getter(GetterNames.getTelematicsInfo) private getTelematicsInfo!: any
    @Getter(GetterNames.getIsDataLoading) private getIsDataLoading!: any
    @Getter(GetterNames.getFieldRequestComparisonMode) private getFieldRequestComparisonMode !: boolean

    @Mutation(MutationNames.incZoom) private incZoom !: any
    @Mutation(MutationNames.decZoom) private decZoom !: any
    @Mutation(MutationNames.setGeolocVisibility) private setGeolocVisibility !: any
    @Mutation(MutationNames.setSideboardIsShown) private setSideboardIsShown !: any
    @Mutation(MutationNames.setIsNotesModeActive) private setIsNotesModeActive!: any
    @Mutation(MutationNames.setWeedinessImage) private setWeedinessImage!: any
    @Mutation(MutationNames.setGuideTourActivators) private setGuideTourActivators!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    @Mutation(MutationNames.setPrintScreen) private setPrintScreen!: any
    @Mutation(MutationNames.setTelematicsInfo) private setTelematicsInfo!: any
    @Mutation(MutationNames.setFieldRequestComparisonMode) private setFieldRequestComparisonMode!: any

    private infoBtnStatus = false
    private userData = {
        email: '',
        first_name: '',
        last_name: '',
        username: ''
    }

    private return2Farm() {
        if (this.getFarmFromRoute) {
            this.updateMapDataInRegion({
                animateBbox: this.getFarmFromRoute.boundaries
            })
        }
    }

    private mounted() {
        this.loadUserData()
    }

    @Watch('currentRoute')
    private routeChanged(newRoute: Route, oldRoute: Route) {
        const name = newRoute.name
        if (name !== 'fieldtelematics') {
            this.setTelematicsInfo({
                autoTracking: {
                    mainTractorID: -1,
                    tracking: false
                }
            })
        }
    }

    @Watch('getFieldFromRoute.id')
    private onchangeField(val: any, old: any) {
        if (val !== old) {
            this.setIsNotesModeActive(false)
        }
    }

    private activateNotesMode() {
        this.setSideboardIsShown(this.getIsNotesModeActive)
        this.setIsNotesModeActive(!this.getIsNotesModeActive)
        if (this.$route.name !== 'subfieldindicators') {
            if (this.getFarmFromRoute) {
                this.$router.push({
                    name: 'subfieldnotes',
                    params: {
                        farmId: this.$route.params.farmId,
                        subfieldId: this.$route.params.subfieldId
                    }
                }).catch(e => {/* */})
            }
        }
    }

    private openFarmRoute(routeName: any, id?: any) {
      if (this.getFarmFromRoute) {
        this.$router.push({
          name: routeName,
          params: {
            farmId: id ? id.toString() : this.getFarmFromRoute.id.toString()
          }
        }).catch(e => { /* */ })
      }
    }

    private loadUserData() {
        this.setLoadingDataNames('mapControllersUserInfo')
        httpGet({
            url: apiUrl + '/user_info/',
            isSecureRequest: true,
            onSuccess: json => {
                const user = json.user
                if (user) {
                    this.userData = user
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('mapControllersUserInfo')
            }
        })
    }

    private showGeoloc(val: boolean) {
        console.log(val)
        if (this.currentRoute.name === 'fieldtelematics' || this.currentRoute.name === 'farmtelematics') {
            if (this.getTelematicsInfo.autoTracking.mainTractorID !== -1) {
                this.setTelematicsInfo({
                    autoTracking: {
                        tracking: !this.getTelematicsInfo.autoTracking.tracking
                    }
                })
            } else {
                const result = this.getTelematicsInfo.tractorsData.filter((x: any) => x.data.length > 0)
                if (result.length > 0) {
                    this.setTelematicsInfo({
                        autoTracking: {
                            mainTractorID: result[0].tractorID,
                            tracking: true
                        }
                    })
                }
            }
        } else {
            for ( let i = 0; i < 5; i++ ) {
                this.decZoom()
            }
            this.setGeolocVisibility(val)
        }
    }

    private closeInfoButtonActivator() {
        this.infoBtnStatus = false
    }
}
