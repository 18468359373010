interface TractorTelematicsArgs {
    index: number               // tractor index in tractors data
    folderID: number            // folder ID
    complex: any                // complex object
    tractorID: number           // tractor ID
    tractorIMEI: string         // tractor IMEI
    tractorUID: string          // tractor UID
    FUEL_DATA: any              // array of coefficient and param name of fuel data {param_name: string, {a: number, b: number, c: number}} ax*x+bx+c
    SOCKET_STATUS: number       // socket status 'broadcast'=0 or 'loading'=1 or 'broadcasting'=2 or 'connection failed'=3
    MAX_CAPACITY: number        // tractor fuel capacity
    MIN_SPEED: number           // tractor min speed default should be 0
    MAX_SPEED: number           // tractor max speed default should be 999
    coverage: number            // tractor agregate coverage
    tractorImage: string        // url of tractor image
    hasTrailer: boolean
    historyStartDate?: string
    historyEndDate?: string
    IS_HISTORY?: boolean
    IS_LIVE?: boolean
    taskEvent?: any
}

export default class TractorTelematics {
    public index: number
    public folderID: number
    public complex: any
    public tractorID: number
    public tractorIMEI: string
    public tractorUID: string
    public IS_VISIBLE: boolean          // tractor visibility
    public IS_ONLINE: boolean           // tractor online status
    public IS_LIVE: boolean             // true if live stream, false if history or off
    public IS_HISTORY: boolean
    public FUEL_DATA: any
    public SOCKET_STATUS: number
    public MAX_CAPACITY: number
    public MIN_SPEED: number
    public MAX_SPEED: number
    public coverage: number
    public tractorImage: string
    public data: any[]                  // main array of all stream data
    public currentIndex: number         // current data index
    public START_DATE: string           // start date of data
    public LAST_DATE: string            // end date of data
    public coordinates: any[]           // whole coordinates array for geom-line-string
    public currentCoordinates: any[]    // current view coordinates array for geom-line-string
    public arrows: any[]                // direction array in format of GeoJSON
    public minspeedData: any[]          // array of min speed line string
    public maxspeedData: any[]          // array of max speed line string
    public roadColor: string            // hex color of road
    public coverageColor: string        // rgba color of coverage
    public hasTrailer: boolean
    public historyStartDate: string     // history request start date
    public historyEndDate: string       // history request end date
    public historyIdentifier: string    // history identifier
    public historyTotalCount: number    // history data messages count
    public historyCurrentCount: number  // history data current index
    public speedData: any[]             // array of data for graph
    public fuelData: any[]              // array of data for graph
    public stopData: any[]              // array of stop points
    public nosignalData: any[]          // array of no signal range
    public nosignalHistory: any[]
    public lastFuel = 0
    public processedArea = 0
    public workingHours = 0
    public taskEvent: any

    constructor({ index, folderID, complex, tractorID, tractorIMEI, tractorUID, FUEL_DATA, hasTrailer,
        SOCKET_STATUS, MAX_CAPACITY, MIN_SPEED, MAX_SPEED, coverage, tractorImage, historyStartDate, historyEndDate, IS_HISTORY, taskEvent }: TractorTelematicsArgs) {
        this.index = index
        this.folderID = folderID
        this.complex = complex
        this.tractorID = tractorID
        this.tractorIMEI = tractorIMEI
        this.tractorUID = tractorUID
        this.IS_VISIBLE = true
        this.IS_ONLINE = false
        this.IS_LIVE = false
        this.IS_HISTORY = IS_HISTORY ? IS_HISTORY : false
        this.FUEL_DATA = FUEL_DATA
        this.SOCKET_STATUS = SOCKET_STATUS
        this.MAX_CAPACITY = MAX_CAPACITY
        this.MIN_SPEED = MIN_SPEED
        this.MAX_SPEED = MAX_SPEED
        this.coverage = coverage
        this.tractorImage = tractorImage
        this.data = []
        this.currentIndex = 0
        this.START_DATE = ''
        this.LAST_DATE = ''
        this.coordinates = []
        this.currentCoordinates = []
        this.arrows = []
        this.minspeedData = []
        this.maxspeedData = []
        this.roadColor = '#E0E2D8'
        this.coverageColor = '#E0E2D8'
        this.hasTrailer = hasTrailer ? hasTrailer : false
        this.historyStartDate = historyStartDate ? historyStartDate : ''
        this.historyEndDate = historyEndDate ? historyEndDate : ''
        this.historyIdentifier = ''
        this.historyTotalCount = 0
        this.historyCurrentCount = 0
        this.speedData = []
        this.fuelData = []
        this.stopData = []
        this.nosignalData = []
        this.nosignalHistory = []
        this.taskEvent = taskEvent
    }

    public addLiveData(data: any) {
        if (data.fuel_total) {
            this.lastFuel = data.fuel_total
        }
        this.data.push(data)
        this.coordinates.push(data.point.coordinates)
        this.speedData.push({
            t: data.date_time,
            y: data.speed
        })
        let amount = 0
        let checker = false
        for (const fuel of this.FUEL_DATA) {
            if (data.fuel.hasOwnProperty(fuel.param_name)) {
                const x = data.fuel[fuel.param_name]
                const a = fuel.params.a
                const b = fuel.params.b
                const c = fuel.params.c
                amount += a * x * x + b * x + c
                checker = true
            }
        }
        this.fuelData.push({
            t: data.date_time,
            y: data.fuel_total ? data.fuel_total : this.lastFuel
        })
        if (this.data.length === 1) {
            this.START_DATE = this.data[0].date_time
        }
        if (this.LAST_DATE) {
            if (new Date(this.LAST_DATE).getTime() < new Date(data.date_time).getTime()) {
                this.LAST_DATE = data.date_time
            }
        } else this.LAST_DATE = data.date_time
        const lastpoint = data.point.coordinates
        let needarrow = false
        if (this.arrows.length !== 0) {
            const prevarrow = this.arrows[this.arrows.length - 1].properties.coordinates
            const length = Math.sqrt(Math.pow(lastpoint[0] - prevarrow[0], 2) + Math.pow(lastpoint[1] - prevarrow[1], 2))
            const angle = Math.abs(data.course * Math.PI / 180 - this.arrows[this.arrows.length - 1].properties.rotation)
            if (length > 1000) needarrow = true
            else if (angle > 1) needarrow = true
        } else needarrow = true
        if (needarrow) {
            const arrowAngle = data.course * Math.PI / 180
            this.arrows.push({
                type: 'Feature',
                properties: {
                    coordinates: lastpoint,
                    index: this.data.length - 1,
                    rotation: arrowAngle,
                    tractorID: this.tractorID,
                    arrIndex: this.arrows.length,
                    tractorIndex: this.index
                },
                geometry: {
                    type: 'Point',
                    coordinates: lastpoint
                }
            })
        }
    }

    public setCurrentIndex(index: any, needtime: boolean) {
        if (this.coordinates.length > index) {
            this.currentIndex = index
            this.currentCoordinates = this.coordinates.slice(0, index + 1)
            if (needtime) {
                return new Date(this.data[this.currentIndex].date_time).getTime()
            }
        }
    }

    public updateCurrentIndex(timestamp: any) {
        const length = this.data.length
        let start = 0
        let end = this.data.length
        let middle = 0
        if (timestamp <= new Date(this.data[0].date_time).getTime()) {
            this.setCurrentIndex(0, false)
        } else if (timestamp >= new Date(this.data[end]).getTime()) {
            this.setCurrentIndex(end, false)
        }
        while (start < end) {
            middle = Math.floor((start + end) / 2)
            if (timestamp === new Date(this.data[middle].date_time).getTime()) {
                this.setCurrentIndex(middle, false)
                return
            } else if (timestamp < new Date(this.data[middle].date_time).getTime()) {
                if (middle > 0 && timestamp > new Date(this.data[middle - 1].date_time).getTime()) {
                    this.setCurrentIndex(middle - 1, false)
                    return
                }
                end = middle
            } else {
                if (middle < length - 1 && timestamp < new Date(this.data[middle + 1].date_time).getTime()) {
                    this.setCurrentIndex(middle, false)
                    return
                }
                start = middle + 1
            }
        }
        this.setCurrentIndex(middle, false)
    }

    public checkIfVisibleObject(time: any) {
        if (this.data.length > 0) {
            const lastTime = this.data[this.currentIndex].date_time
            return new Date(time).getTime() <= new Date(lastTime).getTime()
        } else {
            return false
        }
    }

    public addMinSpeedData(data: any) {
        this.minspeedData.push({
            type: 'Feature',
            properties: {
                average_speed: data.average_speed,
                start_time: data.start_time,
                end_time: data.end_time,
                tractorID: this.tractorID,
                index: this.minspeedData.length
            },
            geometry: data.line_string
        })
    }

    public addMaxSpeedData(data: any) {
        this.maxspeedData.push({
            type: 'Feature',
            properties: {
                average_speed: data.average_speed,
                start_time: data.start_time,
                end_time: data.end_time,
                tractorID: this.tractorID,
                index: this.maxspeedData.length
            },
            geometry: data.line_string
        })
    }

    public addStopPointData(data: any) {
        this.stopData.push({
            id: data.id,
            imei: data.imei,
            interval: data.interval_in_minuts,
            point: data.point,
            start_time: data.start_time,
            end_time: data.end_time,
            comment: data.cause_comment
        })
    }

    public addNosignalData(data: any) {
        this.nosignalData.push({
            id: data.id,
            imei: data.imei,
            interval: data.inverval_in_minuts,
            start_date: data.start_date,
            end_date: data.end_date
        })
    }

    public addNosignalHistory(data: any) {
        this.nosignalHistory.push({
            tileStartFrom: data.tileStartFrom,
            tileWidth: data.tileWidth,
            interval: data.intervalInMinutes,
            date_time: data.date_time
        })
    }

    public clearData() {
        this.data = []
        this.currentIndex = 0
        this.SOCKET_STATUS = 0
        this.START_DATE = ''
        this.LAST_DATE = ''
        this.coordinates = []
        this.currentCoordinates = []
        this.arrows = []
        this.minspeedData = []
        this.maxspeedData = []
        this.roadColor = '#E0E2D8'
        this.coverageColor = '#E0E2D8'
        this.speedData = []
        this.fuelData = []
        this.stopData = []
    }

    public clearHistoryData() {
        this.data = []
        this.START_DATE = ''
        this.LAST_DATE = ''
        this.coordinates = []
        this.currentCoordinates = []
        this.minspeedData = []
        this.maxspeedData = []
        this.speedData = []
        this.fuelData = []
        this.stopData = []
        this.nosignalHistory = []
    }
}