export const farmContent = [
    {
        id: 'farm-guide-tour-step-0',
        header: 'Оформите подписку',
        text: 'Закажите новые данные чтобы анализировать показатели вашего поля на данный момент',
        catalogList: [0, 1, 0, 0, 0, 0],
        nextIndex: 1,
        modelTop: '228px',
        modelLeft: '478px',
        modelBottom: null,
        modelRight: null,
        arrowTop: '89px',
        arrowLeft: '-13px'
    },
    {
        id: 'farm-guide-tour-step-1',
        header: 'Выберите сезон',
        text: 'Выберите сезон для ваших полей, это помогает Вам организовать данные за несколько сезонов и Важно для получения данных по Вашим полям',
        catalogList: [0, 0, 1, 0, 0, 0],
        nextIndex: 2,
        modelTop: '180px',
        modelLeft: '478px',
        modelBottom: null,
        modelRight: null,
        arrowTop: '89px',
        arrowLeft: '-13px'
    },
    {
        id: 'farm-guide-tour-step-2',
        header: 'Ваше хозяйство разделено на поля',
        text: 'Разделение полей упрощает управление вашим хозяйством, все дальнейшие возможности находятся внутри полей',
        catalogList: [0, 0, 0, 1, 0, 0],
        nextIndex: 3,
        modelTop: '308px',
        modelLeft: '478px',
        modelBottom: null,
        modelRight: null,
        arrowTop: '89px',
        arrowLeft: '-13px'
    },
    {
        id: 'farm-guide-tour-step-3',
        header: 'Рейтинг ваших полей',
        text: 'Рейтинг для того чтобы вы знали какое поле показывает хороший результат либо наоборот',
        catalogList: [0, 0, 0, 0, 1, 0],
        nextIndex: 4,
        modelTop: '388px',
        modelLeft: '478px',
        modelBottom: null,
        modelRight: null,
        arrowTop: '89px',
        arrowLeft: '-13px'
    },
    {
        id: 'farm-guide-tour-step-4',
        header: 'Закажите агрохим анализ на хозяйство',
        text: 'Вы также можете сделать на все хозяйство агрохим анлализ у наших партнеров. Подробнее вы можете узнать перейдя по разделу',
        catalogList: [0, 0, 0, 0, 0, 1],
        nextIndex: 'end',
        modelBottom: '24px',
        modelLeft: '478px',
        modelTop: null,
        modelRight: null,
        arrowTop: '141px',
        arrowLeft: '-13px'
    }
]

export const fieldContent = [
    {
        id: 'field-guide-tour-step-0',
        header: 'История поля',
        text: 'После заполнения данного раздела вся история поля по культурам, урожайности и способам обработки будут всегда под рукой',
        catalogList: [0, 1, 0, 0, 0, 0, 0, 0],
        nextIndex: 1,
        modelTop: null,
        modelBottom: null,
        modelLeft: '478px',
        modelRight: null,
        arrowTop: '89px',
        arrowLeft: '-13px'
    },
    {
        id: 'field-guide-tour-step-1',
        header: 'Показатели',
        text: 'Все последние данные по анализу космических снимков в этом разделе. Весь визуальный анализ делается именно тут.',
        catalogList: [0, 0, 1, 0, 0, 0, 0, 0],
        nextIndex: 2,
        modelTop: null,
        modelBottom: null,
        modelLeft: '478px',
        modelRight: null,
        arrowTop: '89px',
        arrowLeft: '-13px'
    },
    {
        id: 'field-guide-tour-step-2',
        header: 'Хроника',
        text: 'Включив данную функцию вы можете сравнить информацию за разные даты и увидеть как развиваются ваши показатели',
        catalogList: [0, 0, 0, 1, 0, 0, 0, 0],
        nextIndex: 3,
        modelTop: null,
        modelBottom: null,
        modelLeft: '478px',
        modelRight: null,
        arrowTop: '89px',
        arrowLeft: '-13px'
    },
    {
        id: 'field-guide-tour-step-3',
        header: 'Заметки',
        text: 'Создавайте заметки прямо на поле, закрепляйте ответственного сотрудника и оффлайн навигацию по мобильному приложению',
        catalogList: [0, 0, 0, 0, 1, 0, 0, 0],
        nextIndex: 4,
        modelTop: null,
        modelBottom: null,
        modelLeft: '478px',
        modelRight: null,
        arrowTop: '89px',
        arrowLeft: '-13px'
    },
    {
        id: 'field-guide-tour-step-4',
        header: 'Задачи',
        text: 'Создавайте технологические карты прямо в приложении, проверяйте его выполнение и отправляйте на модуль телематики',
        catalogList: [0, 0, 0, 0, 0, 1, 0, 0],
        nextIndex: 5,
        modelTop: null,
        modelBottom: null,
        modelLeft: '478px',
        modelRight: null,
        arrowTop: '89px',
        arrowLeft: '-13px'
    },
    {
        id: 'field-guide-tour-step-5',
        header: 'Аналитика',
        text: 'Графики развития поля, информация по погоде и урожайность культур находится именно тут',
        catalogList: [0, 0, 0, 0, 0, 0, 1, 0],
        nextIndex: 6,
        modelTop: null,
        modelBottom: null,
        modelLeft: '478px',
        modelRight: null,
        arrowTop: '89px',
        arrowLeft: '-13px'
    },
    {
        id: 'field-guide-tour-step-6',
        header: 'Агрохим анализ',
        text: 'Если вы заказывали агрохим анализ у нас на платформе, то результат будет отображаться тут',
        catalogList: [0, 0, 0, 0, 0, 0, 0, 1],
        nextIndex: 'end',
        modelTop: null,
        modelBottom: null,
        modelLeft: '478px',
        modelRight: null,
        arrowTop: '141px',
        arrowLeft: '-13px'
    }
]

export const indicatorsContent = [
    {
        id: 'indicators-guide-tour-step-0',
        header: 'Хроника сравнения данных',
        text: 'Включив данную функцию, вы можете сравнить информацию за разные даты и увидеть, как развиваются ваши показатели',
        catalogList: [0, 1, 0, 0, 0],
        nextIndex: 1,
        modelTop: '112px',
        modelBottom: null,
        modelLeft: '478px',
        modelRight: null,
        arrowTop: '89px',
        arrowLeft: '-13px'
    },
    {
        id: 'indicators-guide-tour-step-1',
        header: 'Хроника сравнения данных',
        text: 'Выберите даты и двигайте слайдер для сравнения',
        catalogList: [0, 0, 1, 0, 0],
        nextIndex: 2,
        modelTop: '112px',
        modelBottom: null,
        modelLeft: null,
        modelRight: null,
        arrowTop: '193px',
        arrowLeft: '222.5px'
    },
    {
        id: 'indicators-guide-tour-step-2',
        header: 'Проблемные зоны',
        text: 'Программа EGISTIC имеет возможность показывать вам проблемные зоны кажого поля, включив кнопку "проблемные зоны", вы увидите выделенными красными линиями проблемные места',
        catalogList: [0, 0, 0, 1, 0],
        nextIndex: 3,
        modelTop: '152px',
        modelBottom: null,
        modelLeft: '478px',
        modelRight: null,
        arrowTop: '89px',
        arrowLeft: '-13px'
    },
    {
        id: 'indicators-guide-tour-step-3',
        header: 'Добавьте заметку',
        text: 'Если хотите что-то подчеркнуть для себя, добавьте замекту для дальнейших действий',
        catalogList: [0, 0, 0, 0, 1],
        nextIndex: 'end',
        modelTop: '72px',
        modelBottom: null,
        modelLeft: null,
        modelRight: null,
        arrowTop: '6px',
        arrowLeft: '457px'
    }
]