































import { Component, Vue, Prop, Watch} from 'vue-property-decorator'
import { GetterNames } from '@/store/types'
import { Getter } from 'vuex-class'
import i18n from '@/i18n'
import { imageEnvironment } from '@/domain/constants'
import * as turf from '@turf/turf'
@Component ({
    components: {
    }
})
export default class YieldLayer extends Vue {
    @Prop({default: null}) public map!: any
    @Getter(GetterNames.getMapData) private mapData !: any
    @Getter(GetterNames.getYieldLayer) private getYieldLayer!: any
    @Getter(GetterNames.getFarmFromRoute) private getFarmFromRoute!: any
    @Getter(GetterNames.getCurrentRoute) private getCurrentRoute!: any
    @Getter(GetterNames.getFieldFromRoute) private getFieldFromRoute!: any

    private yieldTip: any = {
        active: false,
        value: '',
        coordinates: [0, 0]
    }

    private getImageUrl(imageUrl: any) {
        return `${imageEnvironment}${imageUrl.substring(16)}`
    }

    private clickEvent(evt: any) {
        if (this.$route.name === 'subfieldyield') {
            if (this.getYieldLayer.layer) {
                const map = this.map.$map
                const layerId = this.getYieldLayer.layer === 'real' ? 700006 : false
                if (layerId) {
                    const wmsLayer = map.forEachLayerAtPixel(evt.pixel, (layer: any) => {
                        if (layer.values_.id === layerId) {
                            return layer
                        }
                    })
                    if (wmsLayer) {
                        const url = wmsLayer.getSource().getGetFeatureInfoUrl(
                            evt.coordinate,
                            map.getView().getResolution(),
                            map.getView().getProjection(),
                            {
                                'INFO_FORMAT': 'application/json',
                                'EXCEPTIONS': 'application/vnd.ogc.se_xml'
                            }
                        )
                        if (url) {
                            fetch(url).then((response: any) => {
                                return response.text()
                            }).then((featureCollections: any) => {
                                const json = JSON.parse(featureCollections)
                                if (json) {
                                    const value = json.features[0].properties.yield
                                    this.yieldTip.value = `${value.toFixed(2)} ${i18n.t('general.units.centner_he')}`
                                    this.yieldTip.active = true
                                    this.yieldTip.coordinates = evt.coordinate
                                }
                            })
                        }
                    }
                }
            }
        }
    }

    private onPointerMove(evt: any) {
        if (this.getYieldLayer.selected && this.getYieldLayer.layer === 'predicted') {
            const point = turf.point(evt.coordinate)
            const polygon = turf.polygon(this.getFieldFromRoute.geom.coordinates[0])
            if (turf.booleanContains(polygon, point)) {
                const map = this.map.$map
                if (map) {
                    map.once('rendercomplete', async (event: any) => {
                        const canvas = event.context.canvas
                        const magnitude = canvas.width / canvas.getBoundingClientRect().width
                        const x = evt.pixel[0] * magnitude
                        const y = evt.pixel[1] * magnitude
                        const c = canvas.getContext('2d')
                        const p = c.getImageData(x, y, 1, 1).data
                        if (p[0] === p[1] && p[0] === [2] && (p[0] === 0 || p[0] === 255)) {
                            this.clearTip()
                        } else {
                            const max = this.getYieldLayer.selected.yield_range.max
                            const min = this.getYieldLayer.selected.yield_range.min
                            let value = 0
                            if (p[0] - p[1] > 0) {
                                value = p[1] / 255 * 0.5 * (max + min) / 2
                            } else {
                                value = (1 - p[0] / 255) * (max - min)
                            }
                            this.yieldTip.active = true
                            this.yieldTip.value = `${value.toFixed(2)} ${i18n.t('general.units.centner_he')}`
                            this.yieldTip.coordinates = evt.coordinate
                        }

                    })
                    map.renderSync()
                } else {
                    this.clearTip()
                }
            }
        }
    }

    @Watch('getCurrentRoute.params.subfieldId')
    private onchangeSubfield() {
        this.clearTip()
    }

    @Watch('getYieldLayer.layer')
    private clearTip() {
        this.yieldTip.active = false
        this.yieldTip.value = ''
        this.yieldTip.coordinates = [0, 0]
    }

    private getCurrentField() {
        if (this.getFarmFromRoute) {
            const field = this.getFarmFromRoute.fields.find((x: any) => x.id.toString() === this.getCurrentRoute.params.subfieldId)
            if (field) {
                return field
            }
        }
        return false
    }

    private getBoundaries(field: any) {
        if (!field) return [0, 0, 0, 0]
        let Xmin = Number.MAX_SAFE_INTEGER
        let Xmax = Number.MIN_SAFE_INTEGER
        let Ymin = Number.MAX_SAFE_INTEGER
        let Ymax = Number.MIN_SAFE_INTEGER
        for (const arr of field.geom.coordinates[0][0]) {
            Xmax = arr[0] > Xmax ? arr[0] : Xmax
            Xmin = arr[0] < Xmin ? arr[0] : Xmin
            Ymax = arr[1] > Ymax ? arr[1] : Ymax
            Ymin = arr[1] < Ymin ? arr[1] : Ymin
        }
        return [Xmin, Ymin, Xmax, Ymax]
    }

}
