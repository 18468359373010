import { Module, GetterTree, ActionTree, MutationTree } from 'vuex'
import { GetterNames, ActionNames, MutationNames } from '../../types'
import TractorTelematics from '@/models/TractorTelematics'

const states = {
    isLegendDividingMode: false,
    isNotesModeActive: false,
    notificationData: {
        count: 0,
        message: null
    },
    ChroniclePanel: {
        status: false,
        indicator: 'precipitation',
        selected: 0,
        layer: 0
    },
    TelematicsInfo: {
        socket: null,
        by_agregate: true,
        farmId: -1,
        geozone: {
            data: null,
            view: false,
            edit: 0,
            type: 'edit'
        },
        folders: [],
        tractorsHistoryPreview: null,
        tractorsData: new Array<TractorTelematics>(),
        autoplay: false,
        tracktime: 0,
        playerIntervalID: 0,
        autoTracking: {
            mainTractorID: -1,
            tracking: false
        },
        arrowsView: false,
        speedLimitsView: false,
        stopsView: false,
        fuelStop: false,
        fieldEvent: {
            reportType: 1,
            event: null,
            tractor: null,
            flaws: false,
            overlaps: false
        },
        controlPanelExtension: false,
        historyStart: '',
        historyEnd: ''
    },
    isShowStopTechnic: false,
    FuelStopTechnic: {
        technicId: -1,
        refuelings: null as any
    }
    // technicEventTelematics: []
}

export type IState = typeof states

const mutations: MutationTree<IState> = {
    [MutationNames.changeLegendDividingMode](state, newVal: boolean) {
        state.isLegendDividingMode = newVal
    },

    [MutationNames.setIsNotesModeActive](state, val: boolean) {
        state.isNotesModeActive = val
    },

    [MutationNames.setNotificationData](state, val: any) {
        if (val.hasOwnProperty('count')) state.notificationData.count = val.count
        if (val.hasOwnProperty('message')) state.notificationData.message = val.message
    },

    [MutationNames.setChroniclePanel](state, val: any) {
        if (val.hasOwnProperty('status')) state.ChroniclePanel.status = val.status
        if (val.hasOwnProperty('indicator')) state.ChroniclePanel.indicator = val.indicator
        if (val.hasOwnProperty('selected')) state.ChroniclePanel.selected = val.selected
        if (val.hasOwnProperty('layer')) state.ChroniclePanel.layer = val.layer
    },

    [MutationNames.setTelematicsInfo](state, val: any) {
        if (val.hasOwnProperty('socket')) state.TelematicsInfo.socket = val.socket
        if (val.hasOwnProperty('by_agregate')) state.TelematicsInfo.by_agregate = val.by_agregate
        if (val.hasOwnProperty('farmId')) state.TelematicsInfo.farmId = val.farmId
        if (val.hasOwnProperty('geozone')) {
            if (val.geozone.hasOwnProperty('data')) state.TelematicsInfo.geozone.data = val.geozone.data
            if (val.geozone.hasOwnProperty('view')) state.TelematicsInfo.geozone.view = val.geozone.view
            if (val.geozone.hasOwnProperty('edit')) state.TelematicsInfo.geozone.edit = val.geozone.edit
            if (val.geozone.hasOwnProperty('type')) state.TelematicsInfo.geozone.type = val.geozone.type
        }
        if (val.hasOwnProperty('folders')) state.TelematicsInfo.folders = val.folders
        if (val.hasOwnProperty('tractorsHistoryPreview')) state.TelematicsInfo.tractorsHistoryPreview = val.tractorsHistoryPreview
        if (val.hasOwnProperty('tractorsData')) state.TelematicsInfo.tractorsData = val.tractorsData
        if (val.hasOwnProperty('autoplay')) state.TelematicsInfo.autoplay = val.autoplay
        if (val.hasOwnProperty('tracktime')) state.TelematicsInfo.tracktime = val.tracktime
        if (val.hasOwnProperty('playerIntervalID')) state.TelematicsInfo.playerIntervalID = val.playerIntervalID
        if (val.hasOwnProperty('autoTracking')) {
            if (val.autoTracking.hasOwnProperty('mainTractorID')) state.TelematicsInfo.autoTracking.mainTractorID = val.autoTracking.mainTractorID
            if (val.autoTracking.hasOwnProperty('tracking')) state.TelematicsInfo.autoTracking.tracking = val.autoTracking.tracking
        }
        if (val.hasOwnProperty('arrowsView')) state.TelematicsInfo.arrowsView = val.arrowsView
        if (val.hasOwnProperty('speedLimitsView')) state.TelematicsInfo.speedLimitsView = val.speedLimitsView
        if (val.hasOwnProperty('stopsView')) state.TelematicsInfo.stopsView = val.stopsView
        if (val.hasOwnProperty('fuelStop')) state.TelematicsInfo.fuelStop = val.fuelStop
        if (val.hasOwnProperty('fieldEvent')) {
            if (val.fieldEvent.hasOwnProperty('reportType')) state.TelematicsInfo.fieldEvent.reportType = val.fieldEvent.reportType
            if (val.fieldEvent.hasOwnProperty('event')) state.TelematicsInfo.fieldEvent.event = val.fieldEvent.event
            if (val.fieldEvent.hasOwnProperty('tractor')) state.TelematicsInfo.fieldEvent.tractor = val.fieldEvent.tractor
            if (val.fieldEvent.hasOwnProperty('flaws')) state.TelematicsInfo.fieldEvent.flaws = val.fieldEvent.flaws
            if (val.fieldEvent.hasOwnProperty('overlaps')) state.TelematicsInfo.fieldEvent.overlaps = val.fieldEvent.overlaps
        }
        if (val.hasOwnProperty('controlPanelExtension')) state.TelematicsInfo.controlPanelExtension = val.controlPanelExtension
        if (val.hasOwnProperty('historyStart')) state.TelematicsInfo.historyStart = val.historyStart
        if (val.hasOwnProperty('historyEnd')) state.TelematicsInfo.historyEnd = val.historyEnd
    },

    [MutationNames.setFuelStopTechnic](state, val: any) {
        if (val.hasOwnProperty('technicId')) state.FuelStopTechnic.technicId = val.technicId
        if (val.hasOwnProperty('refuelings')) state.FuelStopTechnic.refuelings = val.refuelings
    },

    [MutationNames.setIsShowStopTechnic](state, val: any) {
        state.isShowStopTechnic = val
    }

    // [MutationNames.setTechnicEventTelematics](state, val: any) {
    //     console.log(val, 'val')
    //     const index = state.technicEventTelematics.find((x: any) => x.id === val.id)
    //     if (index) {
    //         state.technicEventTelematics.splice(index, 1, { id: val.id, event: val.event } as any)
    //     } else {
    //         state.technicEventTelematics.push(val)
    //     }
    // }
}

const actions: ActionTree<IState, any> = {

}

const getters: GetterTree<IState, any> = {
    [GetterNames.getIsNotesModeActive]: state => {
        return state.isNotesModeActive
    },
    [GetterNames.panelsState]: state => state,

    [GetterNames.getNotificationData]: state => {
        return state.notificationData
    },

    [GetterNames.getChroniclePanel]: state => {
        return state.ChroniclePanel
    },

    [GetterNames.getTelematicsInfo]: state => {
        return state.TelematicsInfo
    },

    [GetterNames.getFuelStopTechnic]: state => {
        return state.FuelStopTechnic
    },

    [GetterNames.getIsShowStopTechnic]: state => {
        return state.isShowStopTechnic
    }

    // [GetterNames.getTechnicEventTelematics]: state => {
    //     return state.technicEventTelematics
    // }
}

const auth: Module<IState, any> = {
    state: states,
    actions,
    mutations,
    getters
}

export default auth
