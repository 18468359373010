



























































import { Component, Vue, Watch } from 'vue-property-decorator'
import { httpPost, httpPut } from '@/util/http'
import { apiUrl } from '@/domain/constants'
import { GetterNames, MutationNames } from '@/store/types'
import { Getter, Mutation } from 'vuex-class'
import { CultureReproduction } from '@/data/FieldCulture'
import CultureChoose from '@/components/CultureChoose.vue'
@Component({
    components: {
        CultureChoose
    }
})
export default class NewTemplateModel extends Vue {
    @Getter(GetterNames.getLoadingDataNames) private getLoadingDataNames!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    private CultureReproduction = CultureReproduction
    private isShown = false
    private isUpdate = false
    private techmap: any = null
    private template = {
        name: '',
        onerror: ''
    }
    private culture: any = {
        is_rest: false,
        parent_culture: '',
        culture: '',
        sort: '',
        reproduction: '',
        onerror: ''
    }

    private show() {
        this.isShown = true
        this.isUpdate = false
        this.template = {
            name: '',
            onerror: ''
        }
        this.culture.is_rest = false
        this.culture.parent_culture = ''
        this.culture.culture = ''
        this.culture.sort = ''
        this.culture.reproduction = ''
        this.culture.onerror = ''
    }

    private addNewTemplate() {
        if (this.checkForErrors()) return
        this.setLoadingDataNames('newTemplateModelAddNewTemplate')
        httpPost({
            url: `${apiUrl}/techmap/`,
            isSecureRequest: true,
            detailResponse: true,
            body: {
                name: this.template.name,
                is_rest: this.culture.is_rest,
                culture: this.culture.is_rest ? '' : this.culture.culture,
                parent_culture: this.culture.is_rest ? '' : this.culture.parent_culture,
                reproduction: this.culture.is_rest ? '' : this.culture.reproduction,
                sort: this.culture.is_rest ? '' : this.culture.sort,
                is_template: true
            },
            onSuccess: json => {
                if (json.ok) {
                    this.$alert('Шаблон успешно создан', 'Успешно', 'success').then(() => {
                        this.$emit('reload')
                        this.hide()
                    })
                } else {
                    this.$alert(JSON.stringify(json.json), 'Ошибка', 'error')
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('newTemplateModelAddNewTemplate')
            }
        })
    }

    private update(techmap: any) {
        this.isShown = true
        this.isUpdate = true
        this.techmap = techmap
        this.template.name = techmap.name
        this.template.onerror = ''
        this.culture.is_rest = techmap.is_rest
        this.culture.parent_culture = techmap.parent_culture || ''
        this.culture.culture = techmap.culture || ''
        this.culture.sort = techmap.sort || ''
        this.culture.reproduction = techmap.reproduction || ''
        this.culture.onerror = ''
        const cultureChoose: any = this.$refs.cultureChoose
        if (cultureChoose) {
            cultureChoose.setData(this.culture.parent_culture, this.culture.culture, this.culture.sort, this.culture.reproduction)
        }
    }

    private updateTemplate() {
        if (this.checkForErrors()) return
        if (this.techmap) {
            this.setLoadingDataNames('newTemplateModelUpdateTemplate')
            httpPut({
                url: `${apiUrl}/techmap/${this.techmap.id}/`,
                isSecureRequest: true,
                detailResponse: true,
                body: {
                    name: this.template.name,
                    is_rest: this.culture.is_rest,
                    culture: this.culture.is_rest ? '' : this.culture.culture,
                    parent_culture: this.culture.is_rest ? '' : this.culture.parent_culture,
                    reproduction: this.culture.is_rest ? '' : this.culture.reproduction,
                    sort: this.culture.is_rest ? '' : this.culture.sort
                },
                onSuccess: json => {
                    if (json.ok) {
                        this.$alert('Шаблон успешно сохранен', 'Обновлено', 'success').then(() => {
                            this.$emit('reload')
                            this.hide()
                        })
                    } else {
                        this.$alert(JSON.stringify(json.json), 'Ошибка', 'error')
                    }
                },
                onError: error => {
                    console.log(error)
                },
                doFinally: () => {
                    this.setLoadingDataNames('newTemplateModelUpdateTemplate')
                }
            })
        }
    }

    private checkForErrors() {
        const el: any = this.$refs.cultureChoose
        if (el) {
            const result = el.getData()
            this.culture.parent_culture = result.parent_culture || ''
            this.culture.culture = result.culture || ''
            this.culture.sort = result.sort || ''
            this.culture.reproduction = result.reproduction || ''
        }
        let counter = 0
        if (this.template.name === '') {
            this.template.onerror = `${this.$i18n.t('headers.workspace.profile.templates.name_input')}`
            counter++
        }
        if (this.culture.culture === '' && !this.culture.is_rest) {
            this.culture.onerror = `${this.$i18n.t('messages.selections.crop.title')}`
            counter++
        }
        return counter > 0
    }

    private setIsRest(val: any) {
        this.culture.is_rest = val
    }

    private hide() {
        this.isShown = false
    }
}
