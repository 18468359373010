









































































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator'
import { Getter, Action, Mutation } from 'vuex-class'
import { GetterNames, ActionNames, MutationNames } from '@/store/types'
import RegisterHistoryModal from '@/views/MainPage/HomeView/Sideboards/FarmBoard/CropRotationBoard/RegisterHistoryModal.vue'
import { Route } from 'vue-router'
import { httpGet, httpPost, httpDelete } from '@/util/http'
import { apiUrl2, apiUrl, baseUrl } from '@/domain/constants'
import Farm from '@/models/Farm'
import i18n from '@/i18n'
@Component({
  components: {
      RegisterHistoryModal
  }
})
export default class FieldHistory extends Vue {
    @Action(ActionNames.fetchFarmsList) private fetchFarmsList !: () => void
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: Route
    @Getter(GetterNames.getFarmFromRoute) private getFarmFromRoute!: Farm
    @Getter(GetterNames.getIsDataLoading) private getIsDataLoading!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    private isDataLoading = false
    private optionStatus = false
    private optionid = 0
    private fieldHistory = []

    private getUrl(url: any) {
        return `${baseUrl}${url}`
    }

    private getCropType(history: any) {
        return history.culture + ', ' + (history.sort !== '' ? history.sort + ', ' : '') + history.reproduction
    }

    private normalizeDate(date: any) {
        if (date === '' || !date) {
            return i18n.t('general.dates.format_date') as string
        }
        return date.substring(8, 10) + '.' + date.substring(5, 7) + '.' + date.substring(0, 4)
    }

    private getDate(startDate: any, endDate: any) {
        return this.normalizeDate(startDate) + ' - ' + this.normalizeDate(endDate)
    }

    private getProductivity(productivity: any, unit: any) {
        if (productivity) {
            return `${productivity} ${unit === 1 ? i18n.t('general.units.centner_he') : i18n.t('general.units.t_he')}`
        } else {
            return i18n.t('messages.phrases.no_data')
        }
    }

    private mounted() {
        this.getHistoryData()
    }

    private getHistoryData() {
        this.setLoadingDataNames('fieldHistoryGetHistoryData')
        httpGet({
            url: `${apiUrl}/get/history/by/divided/${this.currentRoute.params.subfieldId}/`,
            isSecureRequest: true,
            onSuccess: json => {
                this.fieldHistory = json
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('fieldHistoryGetHistoryData')
            }
        })
    }

    private deleteHistory(val: any) {
        if (confirm(i18n.t('messages.questions.delete.history') as string)) {
            httpDelete({
                url: `${apiUrl}/crop_rotation/${val}/`,
                isSecureRequest: true,
                onSuccess: () => {
                    alert(i18n.t('messages.success.deleted'))
                },
                onError: error => {
                    console.log(error)
                },
                doFinally: () => {
                    this.getHistoryData()
                }
            })
        }
    }

    private toggleMorebtnDropdown(val: number) {
        if (val === this.optionid) {
            this.optionStatus = this.optionStatus === true ? false : true
        } else {
            this.optionid = val
            this.optionStatus = true
        }
    }

    private closeMorebtnDropdown() {
        this.optionStatus = false
    }

    private close() {
        this.$router.push(`/cadastres/farm/${this.getFarmFromRoute.id}/subfields/${this.currentRoute.params.subfieldId}`)
    }

    private convertDate(val: string) {
        if (val === null) return i18n.t('general.dates.format_date')
        return val.substring(8, 10) + '.' + val.substring(5, 7) + '.' + val.substring(0, 4)
    }
}
