































































































































































































































































































































































































































































































































































































































































































































































































































































































import {Component, Vue, Watch} from 'vue-property-decorator'
import {httpGet, httpPost, httpDelete, httpPut} from '@/util/http'
import {apiUrl, apiUrl3} from '@/domain/constants'
import {Getter, Mutation} from 'vuex-class'
import {GetterNames, MutationNames} from '@/store/types'
import DateRangePicker from "@/components/DateRangePicker.vue"
import Farm from '@/models/Farm'
import FarmFields from '@/views/MainPage/HomeView/MapContainer/MapView/components/FarmFields.vue'
import AgroChemPoints from '@/views/MainPage/HomeView/MapContainer/MapView/components/AgroChemPoints.vue'
import { CultureColors } from '@/data/FieldCulture'
import TimePicker from '@/components/TimePicker.vue'
import _ from 'lodash'
import Folder from '@/views/MainPage/HomeView/Sideboards/FarmBoard/TelematicsBoard/FarmTelematics/components/Folder'
import NewTractor from '@/views/MainPage/HomeView/Sideboards/FarmBoard/TelematicsBoard/FarmTelematics/components/NewTractor'
import { CultureReproduction } from '@/data/FieldCulture'

@Component({
  components: {
      FarmFields,
      AgroChemPoints,
      DateRangePicker,
      TimePicker
  }
})

export default class PlanningModalView extends Vue {
    @Getter(GetterNames.getMapData) private getMapData!: any
    @Getter(GetterNames.getFarmFromRoute) private getFarmFromRoute!: Farm
    @Getter(GetterNames.getFarmMapControllers) private getFarmMapControllers!: any
    @Getter(GetterNames.getLoadingDataNames) private getLoadingDataNames!: any
    @Getter(GetterNames.getCurrentSeason) private getCurrentSeason!: any
    @Getter(GetterNames.getAgroChemData) private getAgroChemData!: any
    @Getter(GetterNames.getTechnologyEvents) private getTechnologyEvents!: any
    @Mutation(MutationNames.setAgroChemData) private setAgroChemData!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    @Mutation(MutationNames.setTechnologyEvents) private setTechnologyEvents!: any
    private CultureColors = CultureColors
    private CultureReproduction: any = CultureReproduction
    private otherCultures: any = []
    private isShown = false
    private page = 2
    private currentEventId = 1
    private currentTractorIndex = 0
    private currentPreparationIndex = 0
    private currentSeedIndex = 0
    private currentSpendingIndex = 0
    private endDate = ''
    private currentEventTab = 1
    private twoDrivers = false
    private paramsActive = false
    private showAddTractorModel = false
    private tractorSearch = ''
    private openedFolders: any = []

    private technology = {
        name: '',
        description: null,
        cultureName: '',
        sort: '',
        reproduction: '',
        processingType: '1',
        availabilityOfIrrigation: '',
        productivity: '',
        predecessor: '',
        totalArea: '',
        seeding_rate: '',
        fields: {
            origin: [],
            converted: new Array(),
            selected: new Array(),
            selectedNames: new Array()
        },

        areas: {
            origin: [],
            converted: [{name: '', area: '', percentage: ''}]
        },

        cultures: {
            origin: [],
            converted: []
        },

        events: {
            origin: [],
            converted: []
        },

        sorts: {
            converted: []
        },

        predecessors: {
            selected: new Array(),
            converted: new Array(),
            string: ''
        }
    }
    private detailsData = {
        id: 0,
        fields: {
            origin: new Array(),
            converted: new Array(),
            selected: new Array(),
            selectedNames: new Array()
        },

        preparations: {
            origin: new Array(),
            converted: new Array(),
            typePreparations: new Array(),
            typePreparationsConverted: new Array()
        },

        seeds: {
            origin: new Array(),
            converted: new Array(),
            selected: ''
        },

        storages: {
            origin: new Array(),
            converted: new Array(),
            selected: ''
        },

        consumption_units: {
            origin: new Array(),
            converted: new Array()
        },

        seed_units: {
            origin: new Array(),
            converted: new Array()
        },

        tractor: {
            id: '',
            tractor: '',
            tractorError: '',
            trailer: '',
            trailerError: '',
            trailerCategory: '',
            trailerCategoryError: '',
            performance: '',
            performance_measure: '',
            fuel_consumption_rate_for_ha: '',
            fuel_consumption_rate_for_ha_error: '',
            required_volume: '',
            cost: '',
            average_speed: '',
            average_speed_error: '',
            required_hours: '',

            drivers: [
                {
                    driver: null,
                    start_time: '',
                    start_time_error: '',
                    end_time: '',
                    end_time_error: '',
                    shift: 1,
                    salary_rate_for_ha: '',
                    salary_rate_for_ha_error: '',
                    cost: ''
                },
                {
                    driver: '',
                    start_time: '',
                    end_time: '',
                    shift: 2,
                    salary_rate_for_ha: '',
                    cost: ''
                }
            ]
        },
        preparation: {
            plan_task: 2,
            type: 1,
            preparation: '',
            preparationError: '',
            preparation_from_storage: '',
            preparation_from_storage_error: '',
            preparation_type: '',
            preparation_type_error: '',
            consumption_rate: '',
            consumption_rate_error: '',
            consumption_rate_measurement: '',
            price: '',
            priceError: '',
            quantity: '',
            quantityError: '',
            working_consumption: '',
            working_consumption_error: '',
            volume: '',
            volumeError: '',
            water_volume: '',
            water_volume_error: '',
            cost: ''
        },
        seed: {
            plan_task: 2,
            type: 1,
            name: '',
            nameError: '',
            selected: '',
            selectedError: '',
            raw_spacing: '',
            raw_spacing_error: '',
            plant_distance: '',
            plant_distance_error: '',
            sowing_depth: '',
            sowing_depth_error: '',
            sowing_density: '',
            sowing_density_error: '',
            sowing_density_measure: '',
            price: '',
            priceError: '',
            cost: 0
        },
        spending: {
            name: '',
            nameError: '',
            price: '',
            priceError: '',
            description: ''
        }
    }
    private technologyDetails = {
        id: 0,
        event_name: '',
        culture_name: '',
        start_date: '',
        end_date: '',
        fields: {
            origin: new Array(),
            converted: new Array(),
            selected: new Array(),
            selectedNames: new Array(),
            selectedArea: ''
        },

        tractors: new Array(),
        preparations: new Array(),
        seeds: new Array(),
        spendings: new Array()
    }

    private agregates: any = []
    private trailers = {
        selected: new Array(),
        origin: new Array(),
        converted: new Array(),
        categories: new Array(),
        categoryTrailers: new Array()
    }
    private totalTractors = 0
    private checkAll = false
    private folders: any = []
    private choosenTractors: NewTractor[] = []
    private chooseInputValue = ''
    private chooseTractorError: any = ''

    private mounted() {
        this.paramsActive = false
        this.page = 1
        this.setLoadingDataNames('clean')
        this.getFarmMapControllers.fill = true
        this.setAgroChemData({mapSelection: true, selectedFields: []})
        this.setTechnologyEvents('clear')

        this.fetchSeasonFields()
        this.fetchCultures()
        this.fetchEvents()
        this.setTechnologyEvents(_.cloneDeep( this.technologyDetails))
    }

    @Watch('getAgroChemData.selectedFields')
    private onSelectFields(val: any) {
        const fields = this.technology.fields.selected.map((x: any) => x.id)
        const newFields = val.filter((x: any) => !fields.includes(x))
        const oldFields = fields.filter((x: any) => !val.includes(x))

        let fieldData = null
        if (newFields.length > 0) {
            fieldData = this.technology.fields.origin.find((x: any) => x.id === newFields[0])

        } else {
            fieldData = this.technology.fields.origin.find((x: any) => x.id === oldFields[0])
        }
        if (fieldData) {
            this.selectField(fieldData, true)
        }
    }

    @Watch('tractorSearch')
    private searchTractor(search: any) {
        this.folders.forEach((x: any, index: number) => {
            const tractors = x.tractors.filter((y: any) => y.name.toLowerCase().includes(search.toLowerCase()))
            this.folders[index].searchedTractors = tractors
            this.folders[index].collapsed = tractors.length === 0
            this.folders[index].quantity = tractors.length

            if (search === '') {
                this.folders[index].quantity = this.folders[index].tractors.length
                this.folders[index].collapsed = true
            }
        })
    }

    private show() {
        this.isShown = true
    }

    private createNewEvent() {
        const oldEvent = _.cloneDeep( this.getTechnologyEvents.find((x: any) => x.id === 0))

        this.setTechnologyEvents(_.cloneDeep( this.technologyDetails))
        this.currentEventId++

        oldEvent.id = this.currentEventId
        this.technologyDetails = oldEvent
        this.setTechnologyEvents(_.cloneDeep( this.technologyDetails))
        this.technologyDetails.id = this.currentEventId
    }

    private switchEvent(id: number) {
        console.log(id)

        this.setTechnologyEvents(_.cloneDeep( this.technologyDetails))
        this.technologyDetails = _.cloneDeep(this.getTechnologyEvents.find((x: any) => x.id === id))
        this.detailsData.fields.selected = this.technologyDetails.fields.selected
        this.detailsData.fields.selectedNames = this.technologyDetails.fields.selectedNames
        this.currentEventId = id
    }

    private changeCurrentEventTab(tab: any) {
       this.currentEventTab = tab
    }

    private hide() {
        this.isShown = false
        this.$emit('reload')
    }

    private changePage() {
        if (this.page === 1) {
            this.createTechnology()
            this.fetchSelectedFields()
            this.technologyDetails.id = 1
            this.setTechnologyEvents(_.cloneDeep( this.technologyDetails))
            this.getAgregates()
        }
    }

    private fetchSeasonFields() {
        httpPost({
            url:  `${apiUrl}/crop_rotation/percentages/`,
            isSecureRequest: true,
            detailResponse: true,
            body: {
                group_user: Number(this.$route.params.farmId),
                group_season: this.getCurrentSeason.id
            },
            onSuccess: json => {
                if (json.ok) {
                    this.technology.areas.converted = []
                    this.technology.fields.origin = json.json.fields
                    this.technology.fields.converted = json.json.fields.map((x: any) => x.name)
                    this.technology.areas.origin = json.json.areas
                    Object.entries(json.json.areas).map((x: any) => {
                        const area: any = {
                            name: x[0],
                            area: Math.round(x[1] / 100) / 100,
                            percentage: Math.round( (x[1] / json.json.total_area) * 10000) / 100
                        }
                        this.technology.areas.converted.push(area)
                    })
                }
            },
            onError: error => {
                console.log(error)
            }
        })
    }

    private fetchCultures() {
        httpGet({
            url:  `${apiUrl}/get/culture/static/`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                if (json.ok) {
                    this.technology.cultures.origin = json.json
                    this.technology.cultures.converted = json.json.flatMap((x: any) => x.cultures.map((y: any) => y.name))
                }
            },
            onError: error => {
                console.log(error)
            }
        })
    }

    private fetchEvents() {
        httpGet({
            url:  `${apiUrl}/techmap/static/`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                if (json.ok) {
                    this.technology.events.origin = json.json
                    this.technology.events.converted = json.json.map((x: any) => x.name)
                }
            },
            onError: error => {
                console.log(error)
            }
        })
    }

    private onSelectCulture(item: string) {
        console.log(item)
        let currentCulture: any = {}
        this.technology.cultures.origin.forEach((x: any) => {
            if (x.cultures.find((y: any) => y.name === item)) {
                currentCulture = x.cultures.find((y: any) => y.name === item)
                console.log(currentCulture)
            }
        })
        if (currentCulture) {
            this.technology.cultureName = currentCulture.name
            this.technology.sorts.converted = currentCulture.sorts
            this.technology.sort = ''
        }
    }

    private onSelectEvent(event: any) {
        const currentEvent: any = this.technology.events.origin.find((x: any) => x.name === event)

        if (currentEvent) {
            this.technologyDetails.event_name = currentEvent.name
            this.setTechnologyEvents(_.cloneDeep( this.technologyDetails))
        }
    }

    private onSelectSort(item: string) {
        this.technology.sort = item
    }
    private onSelectReproduction(item: string) {
        this.technology.reproduction = item
    }
    private onSelectConsumptionMeasure(item: string) {
        const currentMeasure = this.detailsData.consumption_units.origin.find((x: any) => x.name === item)
        if (currentMeasure) {
            this.technologyDetails.preparations[this.currentPreparationIndex].consumption_rate_measurement = currentMeasure
        }
    }
    private onSelectSeedMeasure(item: string) {
        const currentMeasure = this.detailsData.seed_units.origin.find((x: any) => x.name === item)
        if (currentMeasure) {
            this.technologyDetails.seeds[this.currentSeedIndex].sowing_density_measure = currentMeasure
        }
    }

    private selectAllFields() {
        if (this.technology.fields.origin.length === this.technology.fields.selected.length) {
            this.technology.fields.selected = []
            this.technology.fields.selectedNames = []
        } else {
            this.technology.fields.selected = this.technology.fields.origin.map((x: any) => x.id)
            this.technology.fields.selectedNames = this.technology.fields.origin.map((x: any) => `№${x.name}`)
            // this.technology.totalArea =
        }
        // this.calculateTotalArea()
    }

    private selectField(field: any, fromMap = false) {
        const idIndex = this.technology.fields.selected.findIndex((x: any) => x.id === field.id)
        const nameIndex = this.technology.fields.selectedNames.findIndex((x: string) => x === `№${field.name}`)

        if (idIndex !== -1) {
            this.technology.fields.selected.splice(idIndex, 1)
            this.technology.predecessors.selected.splice(idIndex, 1)
        } else {
            this.technology.fields.selected.push(field)
            this.technology.predecessors.selected.push(field.prev_culture)
        }

        if (nameIndex !== -1) {
            this.technology.fields.selectedNames.splice(nameIndex, 1)
        } else this.technology.fields.selectedNames.push(`№${field.name}`)

        this.technology.predecessor = ([...new Set(this.technology.predecessors.selected)]).join(', ')
        this.technology.totalArea = (Math.round(this.technology.fields.selected.map((x: any) => x.area).reduce((a, b) => a + b, 0) / 100) / 100).toString()
        if (!fromMap) {
            this.setAgroChemData({selectedFields: this.technology.fields.selected.map((x: any) => x.id)})
        }
    }

    private fetchSelectedFields() {
        this.detailsData.fields.converted = this.technology.fields.selected.map((x: any) => x.name)
        this.detailsData.fields.origin = this.technology.fields.selected
    }

    private selectEventFields(field: any) {
        const idIndex = this.technologyDetails.fields.selected.findIndex((x: any) => x.id === field.id)

        if (idIndex !== -1) {
            this.technologyDetails.fields.selected.splice(idIndex, 1)
            this.technologyDetails.fields.selectedNames.splice(idIndex, 1)
        } else {
            this.technologyDetails.fields.selected.push(field)
            this.technologyDetails.fields.selectedNames.push(field.name)
        }

        this.technologyDetails.fields.selectedArea = (Math.round(this.technologyDetails.fields.selected.map((x: any) => x.area).reduce((a, b) => a + b, 0) / 100) / 100).toString()
    }

    private getColor(culture: any) {
        const result = this.CultureColors.defined.find((x: any) => {
            const crop = x.crop.toLowerCase()
            if (culture.name && culture.name.toLowerCase().includes(crop)) {
                return x
            } else if (culture.name && culture.name.toLowerCase().includes(crop)) {
                return x
            }
        })
        if (result) {
            return result.color
        }
        const result2 = this.otherCultures.find((x: any) => {
            if (x.culture && culture.name) {
                if (x.culture.toLowerCase().includes(culture.name.toLowerCase()) ||
                    culture.name.toLowerCase().includes(x.culture.toLowerCase())) {
                    return x
                }
            } else if (x.culture === culture.name || (x.culture === '' && culture.name === null)
                || (x.culture === null && culture.name === '')) {
                return x
            }
        })
        if (result2) {
            return this.CultureColors.others[result2.index]
        } else {
            if (this.otherCultures.length > 0) {
                const index = this.otherCultures[this.otherCultures.length - 1].index
                this.otherCultures.push({
                    culture: culture.name,
                    index: (index + 1) % 10
                })
                return this.CultureColors.others[index + 1]
            } else {
                this.otherCultures.push({
                    culture: culture.name,
                    index: 0
                })
                return this.CultureColors.others[0]
            }
        }
    }

    private setNewDataRange(startDate: any) {
        if (startDate) {
            const start = new Date(startDate)
            this.technologyDetails.start_date = start.toLocaleDateString("ru")
            this.setTechnologyEvents(_.cloneDeep( this.technologyDetails))
        }
    }

    private changeDriverCount(value: boolean) {
        this.twoDrivers = value
    }





    // Add new Instance


    private addTractor() {
        if ( this.technologyDetails.tractors.length === 0) {
            this.technologyDetails.tractors.push(_.cloneDeep(this.detailsData.tractor))
        }
        this.showAddTractorModel = true
    }

    private addPreparation() {
        if ( this.technologyDetails.preparations.length > 0) {

            this.changeCurrentPreparation(this.currentPreparationIndex + 1)
        }
        this.technologyDetails.preparations.push(_.cloneDeep(this.detailsData.preparation))
    }

    private addSeed() {
        if ( this.technologyDetails.seeds.length > 0) {
            this.changeCurrentSeed(this.currentSeedIndex + 1)
        }
        this.technologyDetails.seeds.push(_.cloneDeep(this.detailsData.seed))
    }

    private addSpending() {
        if ( this.technologyDetails.spendings.length > 0) {
            this.changeCurrentSpending(this.currentSpendingIndex + 1)
        }
        this.technologyDetails.spendings.push(_.cloneDeep(this.detailsData.spending))
    }


    // Change Current

    private changeCurrentTractor(index: number) {
        if (index === this.currentTractorIndex) return
        if (this.checkForError()) return

        const tractor = this.technologyDetails.tractors[this.currentTractorIndex]
        if (tractor.id) return this.updateEventTractor(this.currentTractorIndex, index)
        this.createEventTractor(this.currentTractorIndex, index)
    }

    private changeCurrentPreparation(index: number) {
        if (index === this.currentPreparationIndex) return
        if (this.checkForErrorPreparations()) return

        const preparation = this.technologyDetails.preparations[this.currentPreparationIndex]
        if (preparation.id) return this.updateEventPreparation(this.currentPreparationIndex, index)
        this.createEventPreparation(this.currentPreparationIndex, index)
    }

    private changeCurrentSeed(index: number) {
        if (index === this.currentSeedIndex) return
        if (this.checkForErrorSeed()) return

        const seed = this.technologyDetails.seeds[this.currentSpendingIndex]
        if (seed.id) return this.updateEventSeed(this.currentSpendingIndex, index)
        this.createEventSeed(this.currentSeedIndex, index)
    }

    private changeCurrentSpending(index: number) {
        if (index === this.currentSpendingIndex) return
        if (this.checkForErrorSpending()) return

        const spending = this.technologyDetails.spendings[this.currentSpendingIndex]
        if (spending.id) return this.updateEventSpending(this.currentSpendingIndex, index)
        this.createEventSpending(this.currentSpendingIndex, index)
    }

    private addTractorsToList() {
        this.showAddTractorModel = false
        this.technologyDetails.tractors = []
        this.choosenTractors.forEach((x: any) => {
            const newTractorData = _.cloneDeep(this.detailsData.tractor)
            newTractorData.tractor = x
            this.technologyDetails.tractors.push(newTractorData)
            this.detailsData.id++
        })
        this.detailsData.tractor = this.technologyDetails.tractors[0]

    }


    // Tractor Choose

    private getFoldersData() {
        this.folders = []
        this.totalTractors = 0
        this.agregates.forEach((car: any) => {
            const currentFolder = this.folders.find((folder: any) => folder.name === car.category_name)
            if (currentFolder) {
                currentFolder.tractors.push(new NewTractor(car.name, car.id, car.model_name, car.uid, car.category_name))
                currentFolder.quantity++
            } else {
                const tractors: NewTractor[] = []
                tractors.push(new NewTractor(car.name, car.id, car.name, car.uid, car.category_name))
                const newFolder = new Folder(car.category_name, tractors, tractors.length)
                this.folders.push(newFolder)
            }
            this.totalTractors++
        })
    }
    private chooseTractor(folder: number, tractor: number, fromFolder?: boolean) {
        const currentTractor = this.folders[folder].tractors[tractor]
        const currentFolder = this.folders[folder]
        if (currentTractor.checked) {
            currentTractor.checked = false
            fromFolder ? currentFolder.checked = true : currentFolder.checked = false
            this.choosenTractors = this.choosenTractors.filter(item => item.name !== currentTractor.name)
        } else {
            currentTractor.checked = true
            if (!fromFolder) {
                currentFolder.checked = !currentFolder.tractors.find((item: any) => !item.checked)
            }
            if (!this.choosenTractors.find(item => item.name === currentTractor.name)) {
                this.choosenTractors.push(currentTractor)
            }
        }
        if (this.choosenTractors.length > 1) {
            this.chooseInputValue = `${this.choosenTractors[0].name} +${this.choosenTractors.length - 1}`
        } else if (this.choosenTractors.length === 1) {
            this.chooseInputValue = String(this.choosenTractors[0].name)
        } else {
            this.chooseInputValue = ''
        }
    }
    private chooseFolder(index: number) {
        this.folders[index].checked = !this.folders[index].checked
        this.folders[index].tractors.forEach((tractor: any, idx: any) => {
            this.folders[index].checked ? tractor.checked = false : tractor.checked = true
            this.chooseTractor(index, idx, this.folders[index].checked)
        })
    }
    private chooseAll() {
        this.checkAll = !this.checkAll
        this.folders.forEach((folder: any, index: any) => {
            folder.checked = this.checkAll
            folder.tractors.forEach((tractor: any, idx: any) => {
                tractor.checked = !this.checkAll
                this.chooseTractor(index, idx)
            })
        })
    }

    private onselectTrailer(item: any) {
        const trailer = this.trailers.origin.find((x: any) => `${x.name} (${x.coverage})` === item)
        if (trailer) {
            this.technologyDetails.tractors[this.currentTractorIndex].trailer = trailer
            this.trailers.selected = trailer
        }
    }
    private onselectTrailerCategory(item: any) {
        const trailers = this.trailers.origin.filter((x: any) => x.category_name === item)
        if (trailers) {
            this.technologyDetails.tractors[this.currentTractorIndex].trailerCategory = item
            this.trailers.categoryTrailers = trailers.map((x: any) => `${x.name} (${x.coverage})`)
            // this.technologyDetails.tractors[this.currentTractorIndex].trailer = trailer
            // this.trailers.selected = trailer
        }
    }

    private getAgregates() {
        this.setLoadingDataNames('modelGetCars')
        httpGet({
            url:  `${apiUrl}/yields/tractor/`,
            isSecureRequest: true,
            onSuccess: json => {
                this.agregates = json
                this.getFoldersData()
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('modelGetCars')
                this.getTrailers()
            }
        })
    }
    private getTrailers() {
        httpGet({
            url:  `${apiUrl}/yields/trailer/`,
            isSecureRequest: true,
            onSuccess: json => {
                this.trailers.origin = json
                this.trailers.converted = json.map((x: any) => `${x.name} (${x.coverage})`)
                this.trailers.categoryTrailers = json.map((x: any) => `${x.name} (${x.coverage})`)
                this.trailers.categories = [...new Set(json.map((x: any) => x.category_name))]

            },
            onError: error => {
                console.log(error)
            }, doFinally: () => {
                this.fetchPreparations()
            }
        })
    }

    private getRequiredFuelVolume() {
        const tractor = this.technologyDetails.tractors[this.currentTractorIndex]

        let cost = 0
        cost = Math.floor(Number(this.technology.totalArea) * Number(tractor.fuel_consumption_rate_for_ha))
        tractor.required_volume = cost || 0
        return cost.toString()
    }

    private getPerformanceCost() {
        const tractor = this.technologyDetails.tractors[this.currentTractorIndex]

        let cost = 0
        cost = tractor.required_volume * Number(tractor.cost)
        tractor.performance_measure = cost
        return cost.toString()
    }



    // Preparations

    private fetchPreparations() {
        console.log(this.technology.cultureName)
        httpGet({
            url:  `${apiUrl}/techmap/get/preparations/by/culture/?culture=${this.technology.cultureName}`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                if (json.ok) {
                    this.detailsData.preparations.origin = json.json.preparations_list.map((x: any) => {
                        return {
                            type: x.type,
                            name: x.type_name,
                            origin: x.preparations,
                            converted: x.preparations.map((y: any) => y.name)
                        }
                    })
                    this.detailsData.preparations.converted = json.json.preparations_list.map((x: any) => x.type_name)
                    this.detailsData.consumption_units.origin = json.json.measures
                    this.detailsData.consumption_units.converted = json.json.measures.map((x: any) => x.name)
                    this.detailsData.preparation.consumption_rate_measurement = json.json.measures[0]
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.fetchStorage()
            }
        })
    }

    private fetchStorage() {
        httpGet({
            url:  `${apiUrl3}/techmap/storage/items/by/group_user/?group_user=${this.$route.params.farmId}`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                if (json.ok) {
                    console.log(json.json)
                    this.detailsData.storages.origin = json.json.flatMap((x: any) => x.current_products)
                    this.detailsData.storages.converted = json.json.flatMap((x: any) => x.current_products.map((y: any) => y.title))
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.fetchSeeds()
            }
        })
    }

    private onSelectPreparationType(item: string) {
        const preparation = this.detailsData.preparations.origin.find((x: any) => x.name === item)
        if (preparation) {
            console.log(preparation)
            this.technologyDetails.preparations[this.currentPreparationIndex].preparation_type = preparation
            this.technologyDetails.preparations[this.currentPreparationIndex].preparation = ''

            if (preparation.type === 5) {
                this.detailsData.preparations.typePreparations = this.detailsData.storages.origin
                this.detailsData.preparations.typePreparationsConverted = this.detailsData.storages.converted
            } else {
                this.detailsData.preparations.typePreparations = preparation.origin
                this.detailsData.preparations.typePreparationsConverted = preparation.converted
            }

        }
    }

    private onSelectPreparation(item: string) {
        let preparation: any = {}

        if (this.technologyDetails.preparations[this.currentPreparationIndex].preparation_type.type === 5) {
            const storage = this.detailsData.storages.origin.find((x: any) => item === `${x.title}`)
            console.log(storage)
            this.technologyDetails.preparations[this.currentPreparationIndex].quantity = storage.amount.toString()
            this.technologyDetails.preparations[this.currentPreparationIndex].price = storage.cost.toString()
            preparation.name = storage.title
        } else {
            preparation = this.detailsData.preparations.typePreparations.find((x: any) => x.name === item)
            this.technologyDetails.preparations[this.currentPreparationIndex].quantity = '0'
            this.technologyDetails.preparations[this.currentPreparationIndex].price = '0'
        }

        if (preparation) {
            this.technologyDetails.preparations[this.currentPreparationIndex].preparation = preparation
        }
        console.log(preparation)
    }

    private getVolume(preparation: any) {
        let volume = 0
        volume = Math.floor(Number(this.technology.totalArea) * Number(preparation.consumption_rate))
        this.technologyDetails.preparations[this.currentPreparationIndex].volume = volume
        return volume.toString()
    }

    private getWaterVolume(preparation: any) {
        let volume = 0
        volume = Math.floor(Number(this.technology.totalArea) * Number(preparation.working_consumption))
        this.technologyDetails.preparations[this.currentPreparationIndex].water_volume = volume
        return volume.toString()
    }

    private getPreparationTotal() {
        const preparation = this.technologyDetails.preparations[this.currentPreparationIndex]
        if (preparation) {
            preparation.cost = (preparation.volume || 0) * (preparation.price || 0)
            return Math.floor(preparation.cost)
        }
        return 0
    }



    // Seeds

    private fetchSeeds() {
        httpGet({
            url:  `${apiUrl}/techmap/get/seeds/by/culture/?culture=${this.technology.cultureName}`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                if (json.ok) {
                    this.detailsData.seed_units.origin = json.json.measures
                    this.detailsData.seed_units.converted = json.json.measures.map((x: any) => x.name)
                    this.detailsData.seed.sowing_density_measure = json.json.measures[0]
                }
            },
            onError: error => {
                console.log(error)
            }
        })
    }
    private getReproduction() {
        return this.CultureReproduction.map((x: any) => x.name)
    }

    private getSeedCost() {

        if (this.technologyDetails.seeds.length  === 0) return 0
        const seed = this.technologyDetails.seeds[this.currentSeedIndex]
        const total = Number(seed.sowing_density) * Number(seed.price) * Number(this.technology.totalArea)
        this.technologyDetails.seeds[this.currentSeedIndex].total = total

        return total.toFixed(0)
    }




    // Check Errors

    private checkForError() {
        let counter = 0

        const tractorIndex = this.currentTractorIndex

        if (!this.technologyDetails.tractors[tractorIndex].trailerCategory) {
            this.technologyDetails.tractors[tractorIndex].trailerCategoryError = 'Выберите категорию'
            counter++
        } else {
            this.technologyDetails.tractors[tractorIndex].trailerCategoryError = ''
        }

        if (!this.technologyDetails.tractors[tractorIndex].trailer) {
            this.technologyDetails.tractors[tractorIndex].trailerError = 'Выберите агрегат'
            counter++
        } else {
            this.technologyDetails.tractors[tractorIndex].trailerError = ''
        }

        if (this.technologyDetails.tractors[tractorIndex].average_speed === '') {
            this.technologyDetails.tractors[tractorIndex].average_speed_error = 'Введите значение'
            counter++
        } else {
            this.technologyDetails.tractors[tractorIndex].average_speed_error = ''
        }

        if (this.technologyDetails.tractors[tractorIndex].drivers[0].salary_rate_for_ha === '') {
            this.technologyDetails.tractors[tractorIndex].drivers[0].salary_rate_for_ha_error = 'Введите значение'
            counter++
        } else {
            this.technologyDetails.tractors[tractorIndex].drivers[0].salary_rate_for_ha_error = ''
        }


        this.technologyDetails.tractors[tractorIndex].drivers.forEach((driver: any, index: number) => {
            if (index === 1 && !this.twoDrivers) return

            const regExp = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/
            if (!driver.start_time.match(regExp)) {
                this.technologyDetails.tractors[tractorIndex].drivers[index].start_time_error = 'Введите время'
                counter++
            } else {
                this.technologyDetails.tractors[tractorIndex].drivers[index].start_time_error = ''
            }

            if (!driver.end_time.match(regExp)) {
                this.technologyDetails.tractors[tractorIndex].drivers[index].end_time_error = 'Введите время'
                counter++
            } else {
                this.technologyDetails.tractors[tractorIndex].drivers[index].end_time_error = ''
            }
        })
        return counter > 0
    }

    private checkForErrorPreparations() {
        const preparation = this.technologyDetails.preparations[this.currentPreparationIndex]
        preparation.preparationError = ''
        preparation.preparation_type_error = ''
        preparation.consumption_rate_error = ''
        preparation.priceError = ''
        preparation.quantityError = ''
        preparation.working_consumption_error = ''
        preparation.volumeError = ''
        preparation.water_volume_error = ''
        let counter = 0

        if (!preparation.preparation) { preparation.preparationError = 'Введите значение'; counter++}
        if (!preparation.preparation_type) { preparation.preparation_type_error = 'Введите значение'; counter++}

        return counter > 0
    }

    private checkForErrorSeed() {
        const seed = this.technologyDetails.seeds[this.currentSeedIndex]
        seed.raw_spacing_error = ''
        seed.sowing_density_error = ''
        seed.sowing_depth_error = ''
        seed.plant_distance_error = ''
        seed.priceError = ''
        let counter = 0

        if (!seed.raw_spacing) { seed.raw_spacing_error = 'Введите значение'; counter++}
        if (!seed.sowing_density) { seed.sowing_density_error = 'Введите значение'; counter++}
        if (!seed.sowing_depth) { seed.sowing_depth_error = 'Введите значение'; counter++}
        if (!seed.plant_distance) { seed.plant_distance_error = 'Введите значение'; counter++}
        if (!seed.price) { seed.priceError = 'Введите значение'; counter++}

        return counter > 0
    }

    private checkForErrorSpending() {
        const spending = this.technologyDetails.spendings[this.currentSpendingIndex]
        spending.nameError = ''
        spending.priceError = ''
        let counter = 0

        if (!spending.name) { spending.nameError = 'Введите значение'; counter++}
        if (!spending.price) { spending.priceError = 'Введите значение'; counter++}

        return counter > 0
    }











    // API Requests - Create

    private createTechnology() {
        httpPost({
            url:  `${apiUrl}/technology/create/`,
            isSecureRequest: true,
            detailResponse: true,
            body: {
                name: this.technology.name,
                description: this.technology.description,
                culture_name: this.technology.cultureName,
                sort: this.technology.sort || null,
                productivity: this.technology.productivity || 0,
                predecessor: this.technology.predecessor,
                fields: this.technology.fields.selected.map((x: any) => x.id),
                group_user: this.$route.params.farmId,
                group_season: this.getCurrentSeason.id
            },
            onSuccess: json => {
                if (json.ok) {
                    this.detailsData.id = json.json.id
                    this.page = 2
                } else {
                    this.$alert('Ошибка сервера', 'Ошибка', 'error')
                }
            },
            onError: error => {
                console.log(error)
            }
        })
    }

    private createTechnologyEvent() {
        httpPost({
            url:  `${apiUrl}/technology/task/create/`,
            isSecureRequest: true,
            detailResponse: true,
            body: {
                task_name: this.technologyDetails.event_name,
                technology: this.detailsData.id,
                fields: this.technology.fields.selected.map((x: any) => x.id),
                group_user: this.$route.params.farmId,
                group_season: this.getCurrentSeason.id,
                start_date: this.technologyDetails.start_date,
                culture_name: this.technology.cultureName
            },
            onSuccess: json => {
                if (json.ok) {
                    this.technologyDetails.id = json.json.id
                    console.log(json.json)
                    this.paramsActive = true
                } else {
                    this.$alert('Ошибка сервера', 'Ошибка', 'error')
                }
            },
            onError: error => {
                console.log(error)
            }
        })
    }

    private createEventTractor(index: any, newIndex: number) {

        const tractor = this.technologyDetails.tractors[index]

        const drivers = [{
                driver: '',
                start_time: tractor.drivers[0].start_time,
                end_time: tractor.drivers[0].end_time,
                shift: 1,
                salary_rate_for_ha: tractor.drivers[0].salary_rate_for_ha,
                cost: 0
            }]

        if (this.twoDrivers) {
            drivers.push({
                driver: '',
                start_time: tractor.drivers[1].start_time,
                end_time: tractor.drivers[1].end_time,
                shift: 2,
                salary_rate_for_ha: tractor.drivers[1].salary_rate_for_ha,
                cost: 0
            })
        }

        console.log(tractor)
        httpPost({
            url:  `${apiUrl}/technology/task/tractor/create/`,
            isSecureRequest: true,
            detailResponse: true,
            body: {
                tractor: {
                    plan_task: this.technologyDetails.id,
                    tractor: tractor.tractor.id,
                    trailer: tractor.trailer.id,
                    average_speed: Number(tractor.average_speed),
                    fuel_consumption_rate_for_ha: tractor.fuel_consumption_rate_for_ha || 0,
                    required_volume: tractor.required_volume || 0,
                    cost: tractor.performance_measure || 0,
                    fuel_price: tractor.cost || 0
                },
                drivers
            },
            onSuccess: json => {
                if (json.ok) {
                    tractor.id = json.json.tractor.id
                    this.currentTractorIndex = newIndex
                    const newDate = json.json.calculate.end_date
                    if (newDate) {
                        const date = new Date(newDate)
                        this.technologyDetails.end_date = date.toLocaleDateString('ru')
                    }

                } else {
                    this.$alert('Ошибка сервера', 'Ошибка', 'error')
                }
            },
            onError: error => {
                console.log(error)
            }
        })
    }

    private createEventPreparation(index: any, newIndex: number) {

        const preparation = this.technologyDetails.preparations[index]

        console.log(preparation)
        httpPost({
            url:  `${apiUrl}/technology/task/preparation/create/`,
            isSecureRequest: true,
            detailResponse: true,
            body: {
                plan_task: this.technologyDetails.id,
                type: 1,
                preparation: preparation.preparation.id,
                preparation_from_storage: null,
                preparation_type: preparation.preparation_type.type,

                consumption_rate: Number(preparation.consumption_rate),
                consumption_rate_measurement: preparation.consumption_rate_measurement.id,
                price: Number(preparation.price),

                working_consumption: Number(preparation.working_consumption),
                quantity: Number(preparation.quantity),
                volume: preparation.volume,
                water_volume: preparation.water_volume,
                cost: preparation.cost
            },
            onSuccess: json => {
                if (json.ok) {
                    console.log(json.json)
                    preparation.id = json.json.id
                    this.currentPreparationIndex = newIndex
                } else {
                    this.$alert('Ошибка сервера', 'Ошибка', 'error')
                }
            },
            onError: error => {
                console.log(error)
            }
        })
    }

    private createEventSeed(index: any, newIndex: number) {

        const seed = this.technologyDetails.seeds[index]
        console.log(this.technology.sort)
        console.log(seed)
        httpPost({
            url:  `${apiUrl}/technology/task/seed/create/`,
            isSecureRequest: true,
            detailResponse: true,
            body: {
                plan_task: this.technologyDetails.id,
                type: 1,
                name: this.technology.sort,
                raw_spacing: seed.raw_spacing,
                plant_distance: seed.plant_distance,
                sowing_depth: seed.sowing_depth,
                sowing_density: seed.sowing_density,
                sowing_density_measure: seed.sowing_density_measure.id,
                price: seed.price,
                cost: seed.total.toFixed(0)
            },
            onSuccess: json => {
                if (json.ok) {
                    seed.id = json.json.id
                    this.currentSeedIndex = newIndex
                } else {
                    this.$alert('Ошибка сервера', 'Ошибка', 'error')
                }
            },
            onError: error => {
                console.log(error)
            }
        })
    }

    private createEventSpending(index: any, newIndex: number) {

        const spending = this.technologyDetails.spendings[index]

        console.log(spending)
        httpPost({
            url:  `${apiUrl}/technology/task/spending/create/`,
            isSecureRequest: true,
            detailResponse: true,
            body: {
                plan_task: this.technologyDetails.id,
                type: 1,
                name: spending.name,
                price: spending.price,
                description: spending.description
            },
            onSuccess: json => {
                if (json.ok) {
                    spending.id = json.json.id
                    this.currentSpendingIndex = newIndex
                } else {
                    this.$alert('Ошибка сервера', 'Ошибка', 'error')
                }
            },
            onError: error => {
                console.log(error)
            }
        })
    }




    // API Requests - Update

    private updateEventTractor(index: any, newIndex: number) {

        const tractor = this.technologyDetails.tractors[index]

        const drivers = [{
            driver: '',
            start_time: tractor.drivers[0].start_time,
            end_time: tractor.drivers[0].end_time,
            shift: 1,
            salary_rate_for_ha: tractor.drivers[0].salary_rate_for_ha,
            cost: 0
        }]

        if (this.twoDrivers) {
            drivers.push({
                driver: '',
                start_time: tractor.drivers[1].start_time,
                end_time: tractor.drivers[1].end_time,
                shift: 2,
                salary_rate_for_ha: tractor.drivers[1].salary_rate_for_ha,
                cost: 0
            })
        }

        console.log(tractor)
        httpPut({
            url:  `${apiUrl}/technology/task/tractor/${tractor.id}/`,
            isSecureRequest: true,
            detailResponse: true,
            body: {
                tractor: {
                    plan_task: this.technologyDetails.id,
                    tractor: tractor.tractor.id,
                    trailer: tractor.trailer.id,
                    average_speed: Number(tractor.average_speed),
                    fuel_consumption_rate_for_ha: tractor.fuel_consumption_rate_for_ha || 0,
                    required_volume: tractor.required_volume || 0,
                    cost: tractor.performance_measure || 0,
                    fuel_price: tractor.cost || 0
                },
                drivers
            },
            onSuccess: json => {
                if (json.ok) {
                    tractor.id = json.json.tractor.id
                    this.currentTractorIndex = newIndex
                    const newDate = json.json.calculate.end_date
                    if (newDate) {
                        const date = new Date(newDate)
                        this.technologyDetails.end_date = date.toLocaleDateString('ru')
                    }

                } else {
                    this.$alert('Ошибка сервера', 'Ошибка', 'error')
                }
            },
            onError: error => {
                console.log(error)
            }
        })
    }

    private updateEventPreparation(index: any, newIndex: number) {

        const preparation = this.technologyDetails.preparations[index]

        console.log(preparation)
        httpPut({
            url:  `${apiUrl}/technology/task/preparation/${preparation.id}/`,
            isSecureRequest: true,
            detailResponse: true,
            body: {
                plan_task: this.technologyDetails.id,
                type: 1,
                preparation: preparation.preparation.id,
                preparation_from_storage: null,
                preparation_type: preparation.preparation_type.type,

                consumption_rate: Number(preparation.consumption_rate),
                consumption_rate_measurement: preparation.consumption_rate_measurement.id,
                price: Number(preparation.price),

                working_consumption: Number(preparation.working_consumption),
                quantity: Number(preparation.quantity),
                volume: preparation.volume,
                water_volume: preparation.water_volume,
                cost: preparation.cost
            },
            onSuccess: json => {
                if (json.ok) {
                    console.log(json.json)
                    preparation.id = json.json.id
                    this.currentPreparationIndex = newIndex
                } else {
                    this.$alert('Ошибка сервера', 'Ошибка', 'error')
                }
            },
            onError: error => {
                console.log(error)
            }
        })
    }

    private updateEventSeed(index: any, newIndex: number) {
        const seed = this.technologyDetails.seeds[index]

        console.log(seed)
        httpPut({
            url:  `${apiUrl}/technology/task/seed/${seed.id}/`,
            isSecureRequest: true,
            detailResponse: true,

            body: {
                plan_task: this.technologyDetails.id,
                type: 1,
                name: this.technology.sort,
                raw_spacing: seed.raw_spacing,
                plant_distance: seed.plant_distance,
                sowing_depth: seed.sowing_depth,
                sowing_density: seed.sowing_density,
                sowing_density_measure: seed.sowing_density_measure.id,
                price: seed.price,
                cost: seed.total.toFixed(0)
            },
            onSuccess: json => {
                if (json.ok) {
                    seed.id = json.json.id
                    this.currentSeedIndex = newIndex
                } else {
                    this.$alert('Ошибка сервера', 'Ошибка', 'error')
                }
            },
            onError: error => {
                console.log(error)
            }
        })
    }

    private updateEventSpending(index: any, newIndex: number) {

        const spending = this.technologyDetails.spendings[index]

        console.log(spending)
        httpPut({
            url:  `${apiUrl}/technology/task/spending/${spending.id}/`,
            isSecureRequest: true,
            detailResponse: true,
            body: {
                plan_task: this.technologyDetails.id,
                type: 1,
                name: spending.name,
                price: spending.price,
                description: spending.description
            },
            onSuccess: json => {
                if (json.ok) {
                    spending.id = json.json.id
                    this.currentSpendingIndex = newIndex
                } else {
                    this.$alert('Ошибка сервера', 'Ошибка', 'error')
                }
            },
            onError: error => {
                console.log(error)
            }
        })
    }



    // API Requests - Delete


    private deleteAllTractors() {
        httpDelete({
            url:  `${apiUrl}/technology/task/${this.technologyDetails.id}/tractors/delete/`,
            isSecureRequest: true,
            onSuccess: json => {
                this.technologyDetails.tractors = []
                console.log(json)
            },
            onError: error => {
                console.log(error)
            }
        })
    }

    private deleteTractor(tractor: any, index: any) {

        this.currentTractorIndex = index === 0 ? index : index - 1
        this.technologyDetails.tractors.splice(index, 1)
        if (!tractor.id) return
        httpDelete({
            url:  `${apiUrl}/technology/task/tractor/${tractor.id}/`,
            isSecureRequest: true,
            onSuccess: json => {
                console.log(json)
                const newDate = json.json.calculate.end_date
                if (newDate) {
                    const date = new Date(newDate)
                    this.technologyDetails.end_date = date.toLocaleDateString('ru')
                }
            },
            onError: error => {
                console.log(error)
            }
        })
    }

    private deletePreparation(preparation: any, index: any) {

        this.currentPreparationIndex = index === 0 ? index : index - 1
        this.technologyDetails.preparations.splice(index, 1)
        if (!preparation.id) return
        httpDelete({
            url:  `${apiUrl}/technology/task/preparation/${preparation.id}/`,
            isSecureRequest: true,
            onSuccess: json => {
                console.log(json)
            },
            onError: error => {
                console.log(error)
            }
        })
    }

    private deleteSeed(seed: any, index: any) {

        this.currentSeedIndex = index === 0 ? index : index - 1
        this.technologyDetails.seeds.splice(index, 1)
        if (!seed.id) return
        httpDelete({
            url:  `${apiUrl}/technology/task/seeds/${seed.id}/`,
            isSecureRequest: true,
            onSuccess: json => {
                console.log(json)
            },
            onError: error => {
                console.log(error)
            }
        })
    }

    private deleteSpending(spending: any, index: any) {

        this.currentSpendingIndex = index === 0 ? index : index - 1
        this.technologyDetails.spendings.splice(index, 1)

        if (!spending.id) return
        httpDelete({
            url:  `${apiUrl}/technology/task/spending/${spending.id}/`,
            isSecureRequest: true,
            onSuccess: json => {
                console.log(json)
            },
            onError: error => {
                console.log(error)
            }
        })
    }

}
