








































import { Component, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { GetterNames } from '@/store/types'

@Component
export default class LayerGroup extends Vue {
    @Getter(GetterNames.getMapData) private mapData !: any
    @Getter(GetterNames.getLayerType) private getLayerType!: any
    @Getter(GetterNames.getSuperResolution) private getSuperResolution!: any
}
