









import { Component, Vue, Watch } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import {GetterNames, MutationNames } from '@/store/types'
import { httpPost } from '@/util/http'
import { apiUrl } from '@/domain/constants'
import GeozoneEdit from './components/GeozoneEdit.vue'
import TelematicsControlPanel from './components/TelematicsControlPanel.vue'
@Component({
    components: {
        GeozoneEdit,
        TelematicsControlPanel
    }
})
export default class TelematicsPanel extends Vue {
    @Getter(GetterNames.getTelematicsInfo) private getTelematicsInfo!: any
    @Mutation(MutationNames.setTelematicsInfo) private setTelematicsInfo!: any

    get isTelematicsControlPanelVisible() {
        const result = this.getTelematicsInfo.tractorsData.find((x: any) => x.IS_LIVE || x.IS_HISTORY)
        return result ? true : false
    }

    @Watch('isTelematicsControlPanelVisible')
    private onchangeTelematicsControlPanel(val: any, old: any) {
        if (val !== old) {
            this.setTelematicsInfo({
                autoplay: val
            })
        }
    }
}
