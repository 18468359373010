import EditProfileView from '@/views/MainPage/HomeView/Sideboards/ProfileBoard/EditProfileView.vue'
import OrdersView from '@/views/MainPage/HomeView/Sideboards/ProfileBoard/OrdersView/OrdersView.vue'
import TemplatesView from '@/views/MainPage/HomeView/Sideboards/ProfileBoard/TemplatesView/TemplatesView.vue'
import TemplateEventsView from '@/views/MainPage/HomeView/Sideboards/ProfileBoard/TemplatesView/TemplateEvents/TemplateEventsView.vue'
import FarmsInfoView from '@/views/MainPage/HomeView/Sideboards/ProfileBoard/FarmsInfoView/FarmsInfoView.vue'

const routes = [
    {
        path: 'profile/orders',
        name: 'profileorders',
        component: OrdersView
    },
    {
        path: 'profile/templates',
        name: 'templates',
        component: TemplatesView
    },
    {
        path: 'profile/templates/:templateId',
        name: 'templateevents',
        component: TemplateEventsView
    },
    {
        path: 'profile/farmsinfo',
        name: 'farmsinfo',
        component: FarmsInfoView
    },
    {
        path: 'profile/edit',
        name: 'editprofile',
        component: EditProfileView
    }
]

export default routes