





































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import { GetterNames, MutationNames } from '@/store/types'
import { CultureColors } from '@/data/FieldCulture'
@Component
export default class CulturesPanel extends Vue {
    @Getter(GetterNames.getPlanningData) private getPlanningData!: any
    @Getter(GetterNames.getCurrentSeason) private getCurrentSeason!: any
    @Mutation(MutationNames.setPlanningData) private setPlanningData!: any
    private currentSeason = 0
    private CultureColors: any = CultureColors
    private otherCultures: any = []
    private showAll = true
    private years: any = []
    private activeTab = 1

    private mounted() {
        if (this.getCurrentSeason) {
            this.currentSeason = Number(this.getPlanningData.season)
        }
        if (this.getPlanningData.culturesData) {
            this.years = Object.keys(this.getPlanningData.culturesData).reverse()
        }
    }

    @Watch('getPlanningData.selectedFields')
    private changeActiveTab(newVal: any) {
        if (newVal.length === 1) {
            this.activeTab = 2
        } else if (newVal.length === 0) {
            this.activeTab = 1
        }
        this.getFieldsRotation()
    }

    @Watch('getPlanningData.culturesData')
    private updateYears(newVal: any) {
        this.years = Object.keys(newVal).reverse()
    }

    private changeSeason(season: any) {
        this.currentSeason = season.target.value
        this.setPlanningData({ season: season.target.value })
    }

    private getSeasonCultures() {
        if (!this.years.includes(this.currentSeason)) this.currentSeason = this.getCurrentSeason.name
        if (this.getPlanningData.culturesData) {
            const seasonData: any = Object.entries(this.getPlanningData.culturesData).find((x: any) => x[0] === this.currentSeason)
            const culturesConverted: any = []
            Object.entries(seasonData[1]).forEach((key: any) => {
                culturesConverted.push({
                    name: key[0] === 'null' ? 'Нет данных' : key[0],
                    area: Math.round(key[1].area / 100) / 100,
                    percentage: key[1].percentage
                })
            })
            return culturesConverted
        }
    }

    // private getFieldsRotation() {
    //     const fieldsData: any = []
    //     this.getPlanningData.selectedFields.forEach((fieldId: any) => {
    //         if (this.getPlanningData.cropRotations) {
    //             const data = this.getPlanningData.cropRotations.find((x: any) => x.id === fieldId)
    //             if (data) {
    //                 fieldsData.push(data)
    //             }
    //         }
    //     })
    //     return fieldsData
    // }

    private getFieldsRotation() {
        const fieldsData: any = []
        const current = this.getPlanningData.selectedFields[this.getPlanningData.selectedFields.length - 1]
        if (this.getPlanningData.cropRotations) {
            const data = this.getPlanningData.cropRotations.find((x: any) => x.id === current)
            if (data) {
                fieldsData.push(data)
            }
        }
        return fieldsData
    }

    private getFieldCultureColor(yearData: any) {
        if (yearData.crop_rotation) {
            console.log(yearData.crop_rotation)

            if (yearData.crop_rotation.culture) {
                console.log(yearData.crop_rotation.culture)
                return this.getColor(yearData.crop_rotation.culture)
            }
        }
        return 'transparent'
    }

    private getColor(parentCulture: any) {
        const result = this.CultureColors.defined.find((x: any) => {
            const crop = x.crop.toLowerCase()
            if (parentCulture && parentCulture.toLowerCase().includes(crop)) {
                return x
            }
        })
        if (result) {
            return result.color
        }
        const result2 = this.otherCultures.find((x: any) => {
            if (x.culture && parentCulture) {
                if (x.culture.toLowerCase().includes(parentCulture.toLowerCase()) ||
                    parentCulture.toLowerCase().includes(x.culture.toLowerCase())) {
                    return x
                }
            } else if (x.culture === parentCulture || (x.culture === '' && parentCulture === null)
                || (x.culture === null && parentCulture === '')) {
                return x
            }
        })
        if (result2) {
            return this.CultureColors.others[result2.index]
        } else {
            if (this.otherCultures.length > 0) {
                const index = this.otherCultures[this.otherCultures.length - 1].index
                this.otherCultures.push({
                    culture: parentCulture,
                    index: (index + 1) % 10
                })
                return this.CultureColors.others[index + 1]
            } else {
                this.otherCultures.push({
                    culture: parentCulture,
                    index: 0
                })
                return this.CultureColors.others[0]
            }
        }
    }
}
