import { render, staticRenderFns } from "./AgroChemControllers.vue?vue&type=template&id=7f066e88&scoped=true&"
import script from "./AgroChemControllers.vue?vue&type=script&lang=ts&"
export * from "./AgroChemControllers.vue?vue&type=script&lang=ts&"
import style0 from "./AgroChemControllers.vue?vue&type=style&index=0&id=7f066e88&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f066e88",
  null
  
)

export default component.exports