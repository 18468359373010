import { render, staticRenderFns } from "./EditFarm.vue?vue&type=template&id=0b4eefd8&scoped=true&"
import script from "./EditFarm.vue?vue&type=script&lang=ts&"
export * from "./EditFarm.vue?vue&type=script&lang=ts&"
import style0 from "./EditFarm.vue?vue&type=style&index=0&id=0b4eefd8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b4eefd8",
  null
  
)

export default component.exports