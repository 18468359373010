








import { Component, Vue, Watch } from 'vue-property-decorator'
import { GetterNames, MutationNames } from '@/store/types'
import { Getter, Mutation } from 'vuex-class'
import { httpGet, httpDelete } from '@/util/http'
import { baseUrl, apiUrl, apiUrl2 } from '@/domain/constants'
import { Route } from 'vue-router'
@Component
export default class SubNotes extends Vue {
    @Getter(GetterNames.getMapData) private mapData !: any
    @Getter(GetterNames.getSubfieldNoteData) private getSubfieldNoteData!: any
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: Route
    @Getter(GetterNames.getNoteSupport) private getNoteSupport!: any
    @Getter(GetterNames.getPermissions) private getPermissions!: any

    @Mutation(MutationNames.setSubfieldNoteData) private setSubfieldNoteData!: any
    @Mutation(MutationNames.setSideboardIsShown) private setSideboardIsShown !: any
    @Mutation(MutationNames.setIsDataLoading) private setIsDataLoading!: any
    @Mutation(MutationNames.setNoteSupport) private setNoteSupport!: any
    @Mutation(MutationNames.setMainMapCenter) private setMainMapCenter!: any

    private selectedFeatures: any[] = []

    private selectedNoteCoordinates: any = null
    private selectedNoteId: any = null

    private listNotes: any = []

    private getTypeProblem(problem: any) {
        switch (problem) {
            case 'type1':
                return 'Болезнь'
            case 'type2':
                return 'Вредители'
            case 'type3':
                return 'Сорняки'
            case 'type4':
                return 'Другое'
            default:
                return 'Заметка без категории'
        }
    }

    @Watch('selectedFeatures')
    private noteSelected() {
        if (this.selectedFeatures.length === 0 || !this.selectedFeatures[0].properties) return
        this.selectedNoteCoordinates = null
        if (this.selectedFeatures[0].properties.type === 'note') {
            this.selectedNoteCoordinates = this.selectedFeatures[0].geometry.coordinates
            this.selectedNoteId = this.selectedFeatures[0].properties.id

            // this.currentFieldsNote = this.newNotesList.find((x: any) => x.id === this.selectedNoteId && ( x.coordinates[0] === this.selectedNote[0] && x.coordinates[1] === this.selectedNote[1] ) )
            // this.currentFieldsNote.idNotes = this.selectedFeatures[0].id
            // this.currentNoteId = this.selectedFeatures[0].id

            this.setSubfieldNoteData({
                fieldId: null,
                currentNoteId: this.selectedFeatures[0].properties.id,
                data: null
            })
        }
        this.selectedFeatures = []
    }

    private mounted() {
        if (this.$route.name === 'allnotes') {
            this.getAllNotesList()
        } else {
            this.getNotesList()
        }
    }

    // get all notes list
    private getAllNotesList() {
        httpGet({
            url:  `${apiUrl2}/notes/by_group_user/${this.currentRoute.params.farmId}/`,
            isSecureRequest: true,
            onSuccess: json => {
                if (json) {
                    this.setSubfieldNoteData({
                        fieldId: 0,
                        currentNoteId: null,
                        data: json.reverse()
                    })
                    console.log(json, 'getNotesList')
                    const arr: any = []

                    json.forEach((note: any) => {
                        note.images.forEach((image: any) => {
                            arr.push({
                                coordinates: image.point.coordinates,
                                avatar: note.avatar,
                                created_at: note.created_at,
                                divided_cadastre_user: note.divided_cadastre_user,
                                employee_count: note.employee_count,
                                employees_name: note.employees_name,
                                fields: note.fields,
                                i_can_reply: note.i_can_reply,
                                id: note.id,
                                image,
                                is_my: note.is_my,
                                is_replied: note.is_replied,
                                name: note.name,
                                owner_full_name: note.owner_full_name,
                                point: note.point,
                                problem: note.problem,
                                round_date: note.round_date,
                                text: note.text
                            })
                        })
                    })
                    this.unique(arr)
                }
            },
            onError: error => {
                console.log(error)
            }
        })
    }

    private unique(args: any) {
        const res2 = args.reduce((acc: any, item: any) => {
            return acc.map((cur: any) => cur.coordinates[0]).includes(item.coordinates[0]) || acc.push(item), acc
        }, [])

        console.log(res2, 're2')
    }

    // get fields notes list
    private getNotesList() {
        if (this.getSubfieldNoteData.data.length > 0) {
            this.setSideboardIsShown(true)
            this.setIsDataLoading(true)
            httpGet({
                url: `${apiUrl}/notes/by_field/${this.currentRoute.params.subfieldId}/`, // ${this.getSubfieldNoteData.data[0].divided_cadastre_user.id}/`,
                isSecureRequest: true,
                onSuccess: json => {
                    this.setSubfieldNoteData({
                        fieldId: this.currentRoute.params.subfieldId,
                        currentNoteId: null,
                        data: json
                    })
                    console.log(json, this.getSubfieldNoteData.data[0].divided_cadastre_user.id, 'getNotes')
                },
                onError: error => {
                    console.log(error)
                },
                doFinally: () => {
                    this.setIsDataLoading(false)
                }
            })
        }
    }
}
