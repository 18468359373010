





















import { Component, Vue, Prop, Watch} from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import { GetterNames, MutationNames } from '@/store/types'
@Component
export default class NewNote extends Vue {
    @Getter(GetterNames.getMapData) private mapData !: any
    @Getter(GetterNames.getIsNotesModeActive) private getIsNotesModeActive!: any
    @Getter(GetterNames.getLastNewNotePoint) private getLastNewNotePoint!: any
    @Getter(GetterNames.getFarmFromRoute) private getFarmFromRoute!: any
    @Getter(GetterNames.getFieldByAllnotes) private getFieldByAllnotes!: any
    @Getter(GetterNames.getAddAllNote) private getAddAllNote!: any

    @Mutation(MutationNames.setLastNewNotePoint) private setLastNewNotePoint!: any
    @Mutation(MutationNames.setSideboardIsShown) private setSideboardIsShown !: any
    @Mutation(MutationNames.setFieldByAllnotes) private setFieldByAllnotes !: any
    @Mutation(MutationNames.setFieldRequestComparisonMode) private setFieldRequestComparisonMode!: any

    @Prop({default: ''}) private entOnMap!: any

    private drawnPoints: any[] = []
    private selectedFeatures: any[] = []

    @Watch('drawnPoints')
    private checkIfNewNoteSelected() {
        if (this.drawnPoints.length === 0) return
        while (this.drawnPoints.length > 1) {
            this.drawnPoints.shift()
        }
        this.setLastNewNotePoint(this.drawnPoints[0].geometry.coordinates)
        this.setSideboardIsShown(false)
    }

    @Watch('selectedFeatures')
    private selectedFeaturesWatch(val: any) {
        if (this.selectedFeatures.length === 0) return
        this.setFieldByAllnotes(this.getFarmFromRoute.fields.find((field: any) => field.id === val[0].properties.id))
        this.setLastNewNotePoint(this.entOnMap.coordinate)
    }

    @Watch('getIsNotesModeActive')
    private changeLastNotePoint() {
        this.drawnPoints = []
        this.setLastNewNotePoint([])
    }
}
