export default {
    auth: {
        login: "войти",
        login_google: "войти через Google",
        sign_up: "Зарегистрироваться",
        sign_up_free: "Зарегистрироваться бесплатно",
        log_out: "Выйти"
    },
    save: {
        general: "Сохранить",
        machine: "Сохранить технику",
        farm: "Сохранить хозяйство"
    },
    actions: {
        title: "Действия",
        access: "Доступ",
        change: "Изменить",
        delete: "Удалить",
        download: "Скачать PDF",
        download_report: "Скачать отчёт",
        print: "Распечатать",
        add: "Добавить",
        end: "Завершить",
        edit: "Редактировать",
        upload: "Загрузить",
        copy: "Дублировать"
    },
    download_guide: "Скачать Руководство в PDF",
    update: "Обновить",
    create: "Создать",
    continue: "Продолжить",
    apply: "Применить",
    learn_more: "Подробнее",
    watch_video: "Посмотреть видео",
    go_back: "Назад",
    goto_channel: "Перейти на канал",
    go_on: "Далее",
    skip: "Пропустить",
    steps: {
        prev: "Предыдущая",
        next: "Следующая"
    },
    new_order: "новый заказ",
    cancel: "Отмена",
    cancel_division: "отменить разделение",
    recover: "Восстановить",
    ok: "Хорошо",
    exit: "Выйти",
    add: {
        general: "Добавить",
        staff: "Добавить сотрудника",
        machine: "Добавить технику",
        device: "Добавить агрегат",
        farm: "Добавить хозяйство",
        new_farm: "Добавить хозяйство",
        event: "Добавить мероприятие",
        category: "Добавить категорию",
        title: "Добавить наименование"
    },
    show: {
        itself: "Показать",
        points: "Показать точки на карте",
        fields: "Показать поля",
        starred: "Показать избранные",
        all: "Показать полностью"
    },
    close: "Закрыть",
    understand: "Мне все понятно",
    season: ["Выбор сезон", "Текущий сезон: "],
    subscription: ["Оформить подписку", "Подписка оформлена до "],
    subscribe: "Подписаться",
    get: {
        general: "Заказать",
        agrochemical_analysis: "Заказать агрохим анализ почвы"
    },
    change: {
        general: "Изменить",
        password: "Изменить пароль",
        staff: "Изменить сотрудника"
    },
    go_main: "Перейти на главную",
    download: {
        map: "Скачать карту"
    },
    select: "Выбрать",
    reply: "Ответить",
    try: "Попробовать",
    write: {
        whatsapp: "Написать WhatsApp",
        operator: "Написать оператору"
    },
    press_start: "Нажмите чтобы начать",
    press_continue: "Нажмите чтобы продолжить",
    mark_all_read: "Отметить все как прочитанные",
    mark: "Выделить"
}