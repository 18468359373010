













import { Component, Vue } from 'vue-property-decorator'
import { Mutation, Getter, Action } from 'vuex-class'
import Sidebar from './Sidebar.vue'
import Menubar from './Menubar.vue'
import {GetterNames, MutationNames, ActionNames} from '@/store/types'
import AdvertisementModel from '@/components/AdvertisementModel.vue'
import { httpGet } from '@/util/http'
import { apiUrl } from '@/domain/constants'
import i18n from '@/i18n'
import { getAdvertisementModel, setAdvertisementModel } from '@/domain/constants'
@Component({
    components: {
        Sidebar,
        Menubar,
        AdvertisementModel
    }
})
export default class Home extends Vue {
    @Action(ActionNames.fetchPermissions) private fetchPermissions!: () => void
    @Getter(GetterNames.IS_SIGNED_IN) private isSignedIn !: boolean
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any

    private mounted() {
        if (this.$device.ios) {
            if (confirm(i18n.t('general.application') as string)) {
                window.location.href = 'https://apps.apple.com/kz/app/egistic/id1538465959'
            }
        }
        if (this.$device.android) {
            if (confirm(i18n.t('general.application') as string)) {
                window.location.href = 'https://play.google.com/store/apps/details?id=com.egistic.development.egistic_mobile'
            }
        }
        if (!this.isSignedIn) {
            this.$router.push('/signin').catch(err => {/* */})
        } else {
            this.fetchPermissions()
        }
        this.getAdvertisement()
    }

    private togleAdvertisementModel(val: any) {
        setAdvertisementModel(val)
    }

    private getAdvertisement() {
        this.setLoadingDataNames('mainpageGetAdvertisement')
        httpGet({
            url: `${apiUrl}/get/current/discount`,
            isSecureRequest: true,
            onSuccess: json => {
                const endDate = new Date(json.end_time)
                const currentDate = new Date()
                const t = endDate.getTime() - currentDate.getTime()
                if ( t > 0 && this.isSignedIn) {
                    if (getAdvertisementModel() !== 'false') {
                        const model: any = this.$refs.advertisementModel as any
                        model.show()
                    }
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('mainpageGetAdvertisement')
            }
        })
    }
}
