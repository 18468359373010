import download from "downloadjs"

export enum PdfActions {
  PRINT = 'PRINT',
  DOWNLOAD = 'DOWNLOAD'
}

export const sleep = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

const base64ToBlob = (data: any, mime: any) => {
  const base64 = window.btoa(window.unescape(encodeURIComponent(data)))
  const bstr = atob(base64)
  let n = bstr.length
  const u8arr = new Uint8ClampedArray(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new Blob([u8arr], { type: mime })
}

export const downloadExcelData = (tableId: string, filename: string, sheetName: string = 'Sheet1') => {
  // const dataType = 'application/vnd.ms-excel'
  const dataType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  const tableSelect = document.getElementById(tableId)
  console.log(dataType)
  if ( tableSelect ) {
    const tableHTML = tableSelect.outerHTML.replace(/.data-v-[a-z0-9]+=""/g, '')
    const xlsTemp =
    `<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta name=ProgId content=Excel.Sheet> <meta name=Generator content="Microsoft Excel 11"><meta http-equiv="Content-Type" content="text/html; charset=UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>${sheetName}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><style>br {mso-data-placement: same-cell;} table {font-size: 14px; border-style: solid; border-color: #000000; float: left; padding: 6px; border-collapse: collapse;}</style></head><body>${tableHTML}</body></html>`
    const blob = base64ToBlob(xlsTemp, dataType)
    download(blob, filename, dataType)
  }
}

export const makePDF = (action: PdfActions, document: any) => {
  const pdfMake = require('pdfmake/build/pdfmake.min.js')
  const pdfFonts = require('pdfmake/build/vfs_fonts.js')
  pdfMake.vfs = pdfFonts.pdfMake.vfs

  switch (action) {
    case(PdfActions.PRINT): {
      pdfMake.createPdf(document).print()
      break
    }
    case(PdfActions.DOWNLOAD): {
      pdfMake.createPdf(document).download()
      break
    }
  }
}

export const getBase64ImageFromHTML = (img: any) => {
  const canvas = document.createElement("canvas")
  canvas.width = img.width
  canvas.height = img.height
  const ctx = canvas.getContext("2d")
  if ( ctx ) {
    ctx.drawImage(img, 0, 0)
    const dataURL = canvas.toDataURL("image/png")
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "")
  }
}

export const getBase64ImageFromURL = (url: string) => fetch(url)
.then(response => response.blob())
.then(blob => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.onloadend = () => resolve(reader.result)
  reader.onerror = reject
  reader.readAsDataURL(blob)
}))

export const geojson2wkt = (geometry: any) => {
  if (geometry.type === "MultiPolygon") {
    let wkt = "MULTIPOLYGON ("
    for (const x of geometry.coordinates) {
      wkt += "("
      for (const y of x) {
        wkt += "("
        for (const z of y) {
          wkt += z[0] + " " + z[1]
          if (z !== y[y.length - 1]) {
            wkt += ", "
          }
        }
        wkt += y !== x[x.length - 1] ? "), " : ")"
      }
      wkt += x !== geometry.coordinates[geometry.coordinates.length - 1] ? "), " : ")"
    }
    wkt += ")"
    return wkt
  } else return null
}