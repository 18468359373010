/* tslint:disable:max-classes-per-file */
import * as turf from '@turf/turf'
import { httpPostFormData } from '@/util/http'
import { apiUrl } from '@/domain/constants'

class Field {
    public id: number
    public name: string
    public center: number[]
    public zoom: number
    public coordinates: number[]
    public onerror: string
    public dropdown: boolean

    constructor() {
        this.id = 0
        this.name = ''
        this.center = []
        this.zoom = 10
        this.coordinates = []
        this.onerror = ''
        this.dropdown = false
    }
}

class Model {
    public text: string
    public onerror: string

    constructor(text?: any) {
        this.text = text ? text.toString() : ''
        this.onerror = ''
    }
}

class Point {
    public lon: Model
    public lat: Model
    public coordinates: number[]
    public ph: Model
    public phosphorus: Model
    public potassium: Model
    public nitrogen: Model
    public sulfur: Model
    public humus: Model
    public dropdown: boolean
    public onerror: string

    constructor(coordinates: number[]) {
        this.lon = new Model(coordinates.length > 0 ? turf.toWgs84(coordinates)[0].toFixed(6) : '')
        this.lat = new Model(coordinates.length > 0 ? turf.toWgs84(coordinates)[1].toFixed(6) : '')
        this.coordinates = coordinates ? coordinates : []
        this.ph = new Model()
        this.phosphorus = new Model()
        this.potassium = new Model()
        this.nitrogen = new Model()
        this.sulfur = new Model()
        this.humus = new Model()
        this.dropdown = false
        this.onerror = ''
    }

    public updateCoordinates(coordinates: any) {
        if (coordinates.length > 0) {
            const polygon = turf.polygon(coordinates[0] as any)
            if (!isNaN(this.lon.text as any) && !isNaN(this.lat.text as any)) {
                const pt = turf.point([Number(this.lon.text), Number(this.lat.text)])
                const point: any = turf.toMercator(pt)
                if (turf.booleanContains(polygon, point)) {
                    this.coordinates = turf.toMercator([Number(this.lon.text), Number(this.lat.text)])
                } else {
                    this.coordinates = []
                    this.onerror = 'Координаты не в периметре поля'
                }
            } else this.coordinates = []
        } else {
            this.coordinates = []
        }
    }

    public getFilledCount() {
        let count = 0
        if (this.coordinates.length > 0) {
            count += 2
        }
        if (this.ph.text && !isNaN(this.ph.text as any)) count++
        if (this.phosphorus.text && !isNaN(this.phosphorus.text as any)) count++
        if (this.potassium.text && !isNaN(this.potassium.text as any)) count++
        if (this.nitrogen.text && !isNaN(this.nitrogen.text as any)) count++
        if (this.sulfur.text && !isNaN(this.sulfur.text as any)) count++
        if (this.humus.text && !isNaN(this.humus.text as any)) count++
        return count
    }
}

export default class UploadAgroChem {
    public field: Field
    public file: any
    public points: Point[]
    public errors: boolean
    public conclusion: any
    public active: boolean

    constructor() {
        this.field = new Field()
        this.file = null
        this.points = [new Point([])]
        this.errors = false
        this.conclusion = null
        this.active = false
    }

    public toggleActive() {
        this.active = !this.active
    }

    public getFieldName() {
        if (this.field.name) {
            return this.field.name
        } else {
            return 'Поле не выбрано'
        }
    }

    public setSelectedField(id: any, name: string, center: number[], zoom: number, coordinates: number[]) {
        this.field.id = id
        this.field.name = name
        this.field.center = center
        this.field.zoom = zoom
        this.field.coordinates = coordinates
        this.field.onerror = ''
    }

    public addPoint(coordinates?: number[]) {
        let index = this.points.length - 1
        while (index >= 0) {
            if (this.points[index].getFilledCount() === 0) {
                this.points.splice(index, 1)
            }
            index--
        }
        this.points.push(new Point(coordinates ? coordinates : []))
    }

    public removePoint(index: number) {
        if (this.points[index]) {
            this.points.splice(index, 1)
        }
    }

    public updatePointCoordinates(index: number) {
        if (this.points[index]) {
            this.points[index].updateCoordinates(this.field.coordinates)
        }
    }

    public verifyData() {
        // TODO
    }

    public addConclusion(file: any) {
        this.conclusion = file
    }

    public removeConclusion() {
        this.conclusion = null
    }

    public removeFile() {
        this.file = null
    }

    public addResult(file: any) {
        if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            const formData = new FormData()
            formData.append('file', file)
            httpPostFormData({
                url: `${apiUrl}/agro_analysis/json/from/xlsx/`,
                isSecureRequest: true,
                detailResponse: true,
                body: formData,
                onSuccess: json => {
                    if (json.ok) {
                        this.VerificationUploadedFile(json.json)
                        this.file = file
                    }
                },
                onError: error => {
                    console.log(error)
                },
                doFinally: () => {
                    //
                }
            })
        }
    }

    public VerificationUploadedFile(converted: any) {
        /* tslint:disable:no-string-literal */
        if (converted.length > 0 && this.field.id) {
            const polygon = turf.polygon(this.field.coordinates[0] as any)
            for (const point of converted[0]) {
                if (point.hasOwnProperty('Широта')
                    && point.hasOwnProperty('Долгота')
                    && point.hasOwnProperty('pH')
                    && point.hasOwnProperty('Фосфор')
                    && point.hasOwnProperty('Калий')
                    && point.hasOwnProperty('Азот')
                    && point.hasOwnProperty('Сера')
                    && point.hasOwnProperty('Гумус')) {
                    if (!isNaN(point['Широта']) && !isNaN(point['Долгота'])) {
                        const lon = Number(point['Долгота'].toString().replace(',', '.'))
                        const lat = Number(point['Широта'].toString().replace(',', '.'))
                        let pt = turf.point([lon, lat])
                        if (lon >= 180 || lon <= -180) {
                            pt = turf.toWgs84(pt)
                            point['Долгота'] = pt.geometry.coordinates[0].toString()
                            point['Широта'] = pt.geometry.coordinates[1].toString()
                        }
                        const pt2 = turf.toMercator(pt)
                        if (turf.booleanContains(polygon, pt2)) {
                            const index = this.points.findIndex((x: any) => x.lon.text === point['Долгота'].toString()
                            && x.lat.text === point['Широта'].toString())
                            if (index !== -1) {
                                this.points[index].ph.text = this.validate(point['pH']) ? point['pH'].toString() : ''
                                this.points[index].phosphorus.text = this.validate(point['Фосфор']) ? point['Фосфор'].toString() : ''
                                this.points[index].potassium.text = this.validate(point['Калий']) ? point['Калий'].toString() : ''
                                this.points[index].nitrogen.text = this.validate(point['Азот']) ? point['Азот'].toString() : ''
                                this.points[index].sulfur.text = this.validate(point['Сера']) ? point['Сера'].toString() : ''
                                this.points[index].humus.text = this.validate(point['Гумус']) ? point['Гумус'].toString() : ''
                            } else {
                                const tempPoint = new Point([])
                                tempPoint.lon.text = point['Долгота'].toString()
                                tempPoint.lat.text = point['Широта'].toString()
                                tempPoint.ph.text = this.validate(point['pH']) ? point['pH'].toString() : ''
                                tempPoint.phosphorus.text = this.validate(point['Фосфор']) ? point['Фосфор'].toString() : ''
                                tempPoint.potassium.text = this.validate(point['Калий']) ? point['Калий'].toString() : ''
                                tempPoint.nitrogen.text = this.validate(point['Азот']) ? point['Азот'].toString() : ''
                                tempPoint.sulfur.text = this.validate(point['Сера']) ? point['Сера'].toString() : ''
                                tempPoint.humus.text = this.validate(point['Гумус']) ? point['Гумус'].toString() : ''
                                tempPoint.updateCoordinates(this.field.coordinates)
                                this.points.push(tempPoint)
                            }
                        }
                    }
                }
            }
        }
    }

    public getPostBody() {
        if (this.field.id) {
            const data = []
            for (const point of this.points) {
                if (point.coordinates.length > 0) {
                    if (!isNaN(point.ph.text as any) && point.ph.text
                        && !isNaN(point.phosphorus.text as any) && point.phosphorus.text
                        && !isNaN(point.potassium.text as any) && point.potassium.text
                        && !isNaN(point.nitrogen.text as any) && point.nitrogen.text
                        && !isNaN(point.sulfur.text as any) && point.sulfur.text
                        && !isNaN(point.humus.text as any) && point.humus.text) {
                        data.push({
                            geom: {
                                type: 'Point',
                                coordinates: point.coordinates
                            },
                            ph: point.ph.text,
                            phosphorus: point.phosphorus.text,
                            potassium: point.potassium.text,
                            nitrogen: point.nitrogen.text,
                            sulfur: point.sulfur.text,
                            humus: point.humus.text
                        })
                    }
                }
            }
            if (data.length > 0) {
                return {
                    divided_cadastre_user: this.field.id,
                    points: data
                }
            } else return false
        } else return false
    }

    private validate(text: any) {
        return !isNaN(text) && text
    }
}