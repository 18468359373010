































































import { Component, Vue } from 'vue-property-decorator'
import i18n from '@/i18n'
import { makePDF, PdfActions } from '@/utils/services'

@Component
export default class FarmBills extends Vue {

    get orderData(): any {
        return this.$route.params.request
    }

    private companyAddress = 'ТОО “Egistic” БИН: 180940005226 010000, Республика Казахстан, \
    г. Нур-Султан, пр. Кабанбай батыра, каб. 13.4'
    private bankCredentials = 'KZ548562203105458567 AO “Банк ЦентрКредит” БИК KCJBKZKX'

    public printPdf() {
        const docDefinition = {
            content: [
                {
                    text: 'Счет на оплату # ' + 0,
                    style: 'header'
                },
                {
                    style: 'tableExample',
                    table: {
                        widths: [47, 175, 17, 240, 0],
                        body: [
                            [
                                {text: 'Номер', color: 'gray', border: [false, false, false, false]},
                                {text: this.orderData[0].id, border: [false, false, false, false]},
                                {text: 'От', color: 'gray', border: [false, false, false, false]},
                                {rowSpan: 2, text: 'ТОО “Egistic” БИН: 180940005226 010000,\nРеспублика Казахстан, г. Нур-Султан, \nпр. Кабанбай батыра, каб. 13.4', border: [false, false, false, false]},
                                ''
                            ],
                            [
                                {text: 'От', color: 'gray', border: [false, false, false, false]},
                                {text: this.getFormattedDate(this.orderData[0].created_at), border: [false, false, false, false]},
                                {text: '', border: [false, false, false, false]},
                                '',
                                ''
                            ],
                            [
                                {text: '', border: [false, false, false, false]},
                                {text: '', border: [false, false, false, false]},
                                {text: '', border: [false, false, false, false]},
                                {text: '', border: [false, false, false, false]},
                                ''
                            ],
                            [
                                {text: '', border: [false, false, false, false]},
                                {text: '', border: [false, false, false, false]},
                                {text: '', border: [false, false, false, false]},
                                {text: 'KZ548562203105458567 AO “Банк ЦентрКредит”\nБИК KCJBKZKX',
                                border: [false, false, false, false]},
                                ''
                            ]
                        ]
                    },
                    layout: {
                        defaultBorder: false
                    }
                },
                {
                    style: 'tableExample',
                    table: {
                        body: this.generateTableBody()
                    },
                    layout: {
                        defaultBorder: false
                    }
                },
                {
                    text:  i18n.t('general.bill.total') + ' ' + 0 + i18n.t('general.units.tg'),
                    alignment: 'right',
                    style: 'totalPrice'
                }
            ],
            styles: {
                header: {
                    fontSize: 18,
                    bold: true,
                    margin: [0, 0, 0, 10]
                },
                subheader: {
                    fontSize: 16,
                    bold: true,
                    margin: [0, 10, 0, 5]
                },
                tableExample: {
                    margin: [0, 5, 0, 15]
                },
                tableHeader: {
                    bold: true,
                    fontSize: 13,
                    color: 'black'
                },
                totalPrice: {
                    bold: true,
                    fontSize: 18,
                    color: 'black'
                }
            },
            defaultStyle: {
                // alignment: 'justify'
            }
        }
        makePDF(PdfActions.PRINT, docDefinition)
    }

  public downloadPdf() {
    const docDefinition = {
        content: [
            {
                text: i18n.t('general.bill.check') + '# ' + this.orderData[0].id,
                style: 'header'
            },
            {
                style: 'tableExample',
                table: {
                    widths: [47, 175, 17, 240, 0],
                    body: [
                        [
                            {text: i18n.t('general.bill.number'), color: 'gray', border: [false, false, false, false]},
                            {text: this.orderData[0].id, border: [false, false, false, false]},
                            {text: i18n.t('messages.phrases.from'), color: 'gray', border: [false, false, false, false]},
                            {rowSpan: 2, text: 'ТОО “Egistic” БИН: 180940005226 010000,\nРеспублика Казахстан, г. Нур-Султан,\n пр. Кабанбай батыра, каб. 13.4, 010000', border: [false, false, false, false]},
                            ''
                        ],
                        [
                            {text: i18n.t('messages.phrases.from'), color: 'gray', border: [false, false, false, false]},
                            {text: this.getFormattedDate(this.orderData[0].created_at), border: [false, false, false, false]},
                            {text: '', border: [false, false, false, false]},
                            '',
                            ''
                        ],
                        [
                            {text: '', border: [false, false, false, false]},
                            {text: '', border: [false, false, false, false]},
                            {text: '', border: [false, false, false, false]},
                            {text: '', border: [false, false, false, false]},
                            ''
                        ],
                        [
                            {text: '', border: [false, false, false, false]},
                            {text: '', border: [false, false, false, false]},
                            {text: '', border: [false, false, false, false]},
                            {text: 'KZ548562203105458567 AO “Банк ЦентрКредит”\nБИК KCJBKZKX',
                                border: [false, false, false, false]},
                            ''
                        ]
                    ]
                },
                layout: {
                    defaultBorder: false
                }
            },
            {
                style: 'tableExample',
                table: {
                    body: this.generateTableBody()
                },
                layout: {
                    defaultBorder: false
                }
            },
            {
                text: i18n.t('general.bill.total') + ' ' + this.getTotalAmount() + i18n.t('general.units.tg'),
                alignment: 'right',
                style: 'totalPrice'
            }
        ],
        styles: {
            header: {
                fontSize: 18,
                bold: true,
                margin: [0, 0, 0, 10]
            },
            subheader: {
                fontSize: 16,
                bold: true,
                margin: [0, 10, 0, 5]
            },
            tableExample: {
                margin: [0, 5, 0, 15]
            },
            tableHeader: {
                bold: true,
                fontSize: 13,
                color: 'black'
            },
            totalPrice: {
                bold: true,
                fontSize: 18,
                color: 'black'
            }
        },
        defaultStyle: {
          // alignment: 'justify'
        }
    }
    makePDF(PdfActions.DOWNLOAD, docDefinition)
  }

    private mounted() {
      //   this.fetchBill()
    }

    private getTotalAmount() {
        let sum = 0
        this.orderData.forEach((order: any) => {
            sum += order.amount
        })
        return sum
    }

    private getFormattedDate(stringDate: string) {
        const date = new Date(stringDate)
        return `${date.getUTCDate()}.${date.getUTCMonth() + 1}.${date.getUTCFullYear()}`
    }

    private close() {
        this.$router.push(`/cadastres/`)
    }

    private generateTableBody() {
        const table: any[] = [
            [
                {text: '#', color: 'gray', border: [false, false, false, true]},
                {text: 'Наименование', color: 'gray', border: [false, false, false, true]},
                {text: 'Кол-во', color: 'gray', border: [false, false, false, true]},
                {text: 'Ед.', color: 'gray', border: [false, false, false, true]},
                {text: 'Цена', color: 'gray', border: [false, false, false, true]},
                {text: 'Сумма', color: 'gray', border: [false, false, false, true]}
            ]
        ]
        this.orderData.forEach((order: any) => {
            table.push(
                [
                    {text: '1', border: [false, false, false, true]},
                    {text: 'Услуга по предоставлению информации по спектральному анализу о состоянии поля для кадастра №'
                            + order.group_user, border: [false, false, false, true]},
                    {text: '1', border: [false, false, false, true]},
                    {text: 'шт', border: [false, false, false, true]},
                    {text: order.amount, border: [false, false, false, true]},
                    {text: order.amount, border: [false, false, false, true]}
                ]
            )
        })
        return table
    }
}
