

























































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import {ActionNames, GetterNames, MutationNames} from '@/store/types'
import {Action, Getter, Mutation} from 'vuex-class'
import FieldSlider from './FieldSlider.vue'
import {httpGet, httpPost} from '@/util/http'
import {apiUrl3, apiUrl} from '@/domain/constants'

@Component({
  components: {
    FieldSlider
  }
})
export default class FieldCadastreRequests extends Vue {
  @Action(ActionNames.updateMapDataInRegion) private updateMapDataInRegion!: any
  @Getter(GetterNames.getMapData) private mapData !: any
  @Getter(GetterNames.getCurrentRoute) private currentRoute !: any
  @Getter(GetterNames.getFarmFromRoute) private getFarmFromRoute!: any
  @Getter(GetterNames.getFarmMapControllers) private getFarmMapControllers!: any
  @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
  private colors: any = ['#2B4DFF', '#AA27E8', '#F37500', '#06BAF2', '#FFC700', '#5AF5D9', '#7D5EF8', '#FC7BE7', '#ADDE30']
  @Prop() private vlView!: any
  private textScale = 1
  private boundaries: any = [0, 0, 0, 0]
  private fieldBoundaries: any = [0, 0, 0, 0]
  private fieldCenter = [0, 0]
  private cadastres: any = []
  private field: any = {}

  private mounted() {
    this.getFieldCadastres()
  }

  private getCenter() {
    // this.fieldCenter = [ (this.fieldBoundaries[0] + this.fieldBoundaries[2]) / 2, (this.fieldBoundaries[1] + this.fieldBoundaries[3]) / 2 ]
    this.fieldCenter = [ this.fieldBoundaries[2], this.fieldBoundaries[1] ]
  }

  private getBoundaries() {
    if (!this.cadastres) return
    let Xmin = Number.MAX_SAFE_INTEGER
    let Xmax = Number.MIN_SAFE_INTEGER
    let Ymin = Number.MAX_SAFE_INTEGER
    let Ymax = Number.MIN_SAFE_INTEGER

    this.cadastres.forEach((fieldData: any) => {
      if ( fieldData && fieldData.geom) {
        for (const arr of fieldData.geom.coordinates[0][0]) {
          Xmax = arr[0] > Xmax ? arr[0] : Xmax
          Xmin = arr[0] < Xmin ? arr[0] : Xmin
          Ymax = arr[1] > Ymax ? arr[1] : Ymax
          Ymin = arr[1] < Ymin ? arr[1] : Ymin
        }
      }
    })
    this.boundaries = [Xmin, Ymin, Xmax, Ymax]
    if (this.cadastres.length > 0) {
      this.updateMapDataInRegion({ boundaries: this.boundaries })
    }
  }

  private getFieldBoundaries() {
    if (!this.field) return

    let Xmin = Number.MAX_SAFE_INTEGER
    let Xmax = Number.MIN_SAFE_INTEGER
    let Ymin = Number.MAX_SAFE_INTEGER
    let Ymax = Number.MIN_SAFE_INTEGER

    if (this.field && this.field.geom) {
      for (const arr of this.field.geom.coordinates[0][0]) {
        Xmax = arr[0] > Xmax ? arr[0] : Xmax
        Xmin = arr[0] < Xmin ? arr[0] : Xmin
        Ymax = arr[1] > Ymax ? arr[1] : Ymax
        Ymin = arr[1] < Ymin ? arr[1] : Ymin
      }
    }
    this.fieldBoundaries = [Xmin, Ymin, Xmax, Ymax]
    this.getCenter()
  }

  @Watch('mapData.zoom')
  private zoomChanged(currentZoom: number) {
    this.textScale = this.mapZoomToTextScale(currentZoom)
  }

  private mapZoomToTextScale(currentZoom: number) {
    let maxScale = 3
    if (this.currentRoute.name === 'farm') {
      maxScale = this.getFarmMapControllers.textMaxScale
    }
    const minScale = 1
    const zoomRatio = (currentZoom - this.mapData.MIN_ZOOM - 2) / (this.mapData.MAX_ZOOM - this.mapData.MIN_ZOOM)
    return zoomRatio * (maxScale - minScale) + minScale
  }

  private getFieldCadastres() {
    this.setLoadingDataNames('getFieldCadastres')
    httpGet({
      url:  `${apiUrl}/get/intersect/cadastres/${this.$route.params.fieldId}/`,
      isSecureRequest: true,
      detailResponse: true,
      onSuccess: json => {
        if (json.ok) {
          this.field = json.json.field
          this.cadastres = json.json.cadastres
          this.getBoundaries()
          this.getFieldBoundaries()
        }
      },
      onError: error => {
        console.log(error)
      },
      doFinally: () => {
        this.setLoadingDataNames('getFieldCadastres')
      }
    })
  }

  private changeFieldCadastre(cadastreId: number) {
    httpPost({
      url:  `${apiUrl}/cadastre/update/field/`,
      isSecureRequest: true,
      detailResponse: true,
      body: {
        farm: Number(this.$route.params.farmId),
        field: this.field.id,
        cadastre: cadastreId
      },
      onSuccess: json => {
        if (json.ok) {
          this.$alert('Кадастр успешно изменен', 'Сохранено', 'success')
          this.field.kad_nomer = json.json.cadastre
        } else {
          this.$alert('Ошибка сервера', 'Ошибка', 'error')
        }
      },
      onError: error => {
        console.log(error)
      }
    })
  }

}
