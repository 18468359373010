














import { Component, Vue, Watch } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import {GetterNames, MutationNames} from '@/store/types'

@Component
export default class FarmMapControllers extends Vue {
    @Getter(GetterNames.getSideboardIsShown) private sideboardIsShown !: any
    @Getter(GetterNames.getUploadAgroChemResult) private getUploadAgroChemResult!: any
    @Mutation(MutationNames.setUploadAgroChemResult) private setUploadAgroChemResult!: any

}
