
































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { GetterNames, MutationNames } from '@/store/types'
import { Getter, Mutation } from 'vuex-class'
import { httpGet, httpDelete } from '@/util/http'
import { baseUrl, apiUrl, apiUrl2 } from '@/domain/constants'
import { Route } from 'vue-router'
import i18n from '@/i18n'
@Component
export default class SubfieldNotes extends Vue {
    @Getter(GetterNames.getMapData) private mapData !: any
    @Getter(GetterNames.getSubfieldNoteData) private getSubfieldNoteData!: any
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: Route
    @Getter(GetterNames.getNoteSupport) private getNoteSupport!: any
    @Getter(GetterNames.getPermissions) private getPermissions!: any

    @Mutation(MutationNames.setSubfieldNoteData) private setSubfieldNoteData!: any
    @Mutation(MutationNames.setSideboardIsShown) private setSideboardIsShown !: any
    @Mutation(MutationNames.setIsDataLoading) private setIsDataLoading!: any
    @Mutation(MutationNames.setNoteSupport) private setNoteSupport!: any
    @Mutation(MutationNames.setMainMapCenter) private setMainMapCenter!: any
    @Mutation(MutationNames.setPhotosData) private setPhotosData!: any
    private selectedFeatures: any[] = []
    private selectedNote: any = null
    private moreBtnStatus = false
    private usersDropdown = false
    private reply = {
        dropdown: false,
        id: -1
    }

    private samePhotoArr: any = []
    private samePhoto: boolean = false
    private selectedNoteId: any = -1
    private currentNoteId: any = -1
    private currentFieldsNote: any = -1

    private newNotesList: any = []

    private getTypeProblem(problem: any) {
        switch (problem) {
            case 'type1':
                return 'Болезнь'
            case 'type2':
                return 'Вредители'
            case 'type3':
                return 'Сорняки'
            case 'type4':
                return 'Другое'
            default:
                return 'Заметка без категории'
        }
    }

    @Watch('currentRoute')
    private watchOnRoute(val: any) {
        this.listGetSubfieldNoteData()
        if (this.currentRoute.name === 'subfieldnotedetails') {
            return this.selectedNote = -1
        }
    }

    private mounted() {
        if (this.$route.name === 'allnotes') {
            this.getNotesList()
        } else {
            this.getNotes()
        }
    }

    private checkForOpeningOverlay(point: any) {
        if (this.currentRoute.name === 'subfieldnotedetails' && this.currentRoute.params.noteId.toString() === point.id.toString()) {
            return true
        }
        if (this.currentRoute.name === 'subfieldnotes' && this.selectedNote[0] === point.coordinates[0] && this.selectedNote[1] === point.coordinates[1] && point.idNotes === this.currentNoteId) {

            return true
        }
        if (this.selectedNote[0] === point.coordinates[0] && this.selectedNote[1] === point.coordinates[1] && point.idNotes === this.currentNoteId && this.currentRoute.name !== 'subfieldnotes') {
            return true
        }
    }

    private listGetSubfieldNoteData() {
        let imageObj: any = []
        let yValue: any = null
        return this.getSubfieldNoteData.data.forEach((x: any) => {
            x.points.forEach((y: any) => {
                yValue = y
                if (x.images.length > 0) {
                    imageObj = x.images[0].point !== null ? x.images.find((image: any) => image.point.coordinates[0] === y.coordinates[0] && image.point.coordinates[1] === y.coordinates[1]) : ''
                }
            })
            this.newNotesList.push({
                coordinates: yValue.coordinates,
                problem: x.problem,
                image: imageObj.image_squeezed ? imageObj.image_squeezed : imageObj.image,
                images: x.images,
                created_at: x.created_at,
                divided_cadastre_user: x.divided_cadastre_user,
                employee_count: x.employee_count,
                employees_name: x.employees_name,
                fields: x.fields,
                id: x.id,
                is_my: x.is_my,
                is_replied: x.is_replied,
                name: x.name,
                owner_full_name: x.owner_full_name,
                round_date: x.round_date,
                text: x.text,
                idNotes: ''
            })
        })
    }

    @Watch('getNoteSupport.noteId')
    private checkNoteSupport() {
        if (this.getNoteSupport.noteId === null) return
        this.selectedNote = this.getNoteSupport.noteId
        this.setNoteSupport({ noteId: null })
        const note = this.getSubfieldNoteData.data.find((x: any) => x.id === this.selectedNote)
        if (note) {
            this.setMainMapCenter(note.point.coordinates)
        }
    }

    private normalizeData(date: any) {
        if (date) {
            return date.substring(8, 10) + '.' + date.substring(5, 7) + '.' + date.substring(0, 4)
        }
        return i18n.t('general.dates.format_date')
    }

    private getDatetoString(date: string) {
        const months = [i18n.t('general.dates.months[0]') as string, i18n.t('general.dates.months[1]') as string, i18n.t('general.dates.months[2]') as string, i18n.t('general.dates.months[3]') as string, i18n.t('general.dates.months[4]') as string, i18n.t('general.dates.months[5]') as string, i18n.t('general.dates.months[6]') as string, i18n.t('general.dates.months[7]') as string, i18n.t('general.dates.months[8]') as string, i18n.t('general.dates.months[9]') as string, i18n.t('general.dates.months[10]') as string, i18n.t('general.dates.months[11]') as string]
        return date.substring(8, 10) + ' ' + months[Number(date.substring(5, 7)) - 1] + ' ' + date.substring(0, 4)
    }

    private getImageUrl(imageUrl: any) {
        const photo = imageUrl.image_squeezed || imageUrl.image
        return `${baseUrl}${photo}`
    }

    @Watch('selectedFeatures')
    private noteSelected() {
        if (this.selectedFeatures.length === 0 || !this.selectedFeatures[0].properties) return
        this.selectedNote = null
        if (this.selectedFeatures[0].properties.type === 'note') {
            this.selectedNote = this.selectedFeatures[0].geometry.coordinates
            this.selectedNoteId = this.selectedFeatures[0].properties.id

            this.currentFieldsNote = this.newNotesList.find((x: any) => x.id === this.selectedNoteId && ( x.coordinates[0] === this.selectedNote[0] && x.coordinates[1] === this.selectedNote[1] ) )
            this.currentFieldsNote.idNotes = this.selectedFeatures[0].id
            this.currentNoteId = this.selectedFeatures[0].id

            this.setSubfieldNoteData({
                fieldId: null,
                currentNoteId: this.selectedFeatures[0].properties.id,
                data: null
            })
        }
        this.selectedFeatures = []
        this.showSameImage(this.selectedNoteId)
    }

    private showSameImage(id: any) {
        console.log(id)
        this.samePhotoArr = []
        this.newNotesList.forEach((x: any) => {
            if (x.id === id && (this.selectedNote[0] !== x.coordinates[0] && this.selectedNote[1] !== x.coordinates[1] )) {
                this.samePhotoArr.push({ id: x.id, image: x.image, point: x.coordinates, problem: x.problem })
            }
        })
        this.samePhoto = true
    }

    private getNotes() {
        if (this.getSubfieldNoteData.data.length > 0) {
            this.setSideboardIsShown(true)
            this.setIsDataLoading(true)
            httpGet({
                url: `${apiUrl2}/notes/by_field/${this.currentRoute.params.subfieldId}/`, // ${this.getSubfieldNoteData.data[0].divided_cadastre_user.id}/`,
                isSecureRequest: true,
                onSuccess: json => {
                    this.setSubfieldNoteData({
                        fieldId: this.currentRoute.params.subfieldId,
                        currentNoteId: null,
                        data: json
                    })
                    console.log(json, this.getSubfieldNoteData.data[0].divided_cadastre_user.id, 'getNotes')
                    this.listGetSubfieldNoteData()
                },
                onError: error => {
                    console.log(error)
                },
                doFinally: () => {
                    this.setIsDataLoading(false)
                }
            })
        }
    }

    private getNotesList() {
        httpGet({
            url:  `${apiUrl2}/notes/by_group_user/${this.currentRoute.params.farmId}/`,
            isSecureRequest: true,
            onSuccess: json => {
                if (json) {
                    this.setSubfieldNoteData({
                        fieldId: 0,
                        currentNoteId: null,
                        data: json.reverse()
                    })
                    console.log(json, 'getNotesList')
                    this.listGetSubfieldNoteData()
                }
            },
            onError: error => {
                console.log(error)
            }
        })
    }

    private deleteNote(id: any) {
        Vue.alert('', `${this.$t('messages.questions.delete.note')}`, 'warning').then(() => {
            httpDelete({
                url: `${apiUrl2}/notes/${id}`,
                isSecureRequest: true,
                onSuccess: () => {
                    Vue.alert('', `${this.$t('messages.success.note_deleted')}`, 'success')
                    this.selectedNote = null
                    this.samePhoto = false
                    this.samePhotoArr = []
                    this.getNotes()
                },
                onError: error => {
                    console.log(error)
                }
            })
        })
    }

    private changeReplyDropdown(id: any) {
        if (this.reply.id === id) {
            this.reply.dropdown = this.reply.dropdown === true ? false : true
        } else {
            this.reply.id = id
            this.reply.dropdown = true
        }
    }

    private openNoteUpdate(note: any) {
        if (this.$route.name !== 'noteupdate') {
            this.$router.push(`/cadastres/farm/${this.currentRoute.params.farmId}/subfields/${note.divided_cadastre_user.id}/noteupdate/${note.id}`)
        }
    }

    private openNoteReply(id: any) {
        if (this.$route.name !== 'notereply') {
            this.$router.push(`/cadastres/farm/${this.currentRoute.params.farmId}/subfields/${this.currentRoute.params.subfieldId}/notereply/${id}`)
        }
    }

    private closeMorebtnDropdown() {
        this.moreBtnStatus = false
    }

    private closeUsersDropdown() {
        this.usersDropdown = false
    }

    private closeReplyDropdown() {
        this.reply.dropdown = false
    }

    private closeNoteWindow() {
        if (this.currentRoute.name === 'subfieldnotedetails') {
            this.$router.back()
        }
        this.selectedNote = null
        this.samePhoto = false
    }
}
