




















































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import {ActionNames, GetterNames, MutationNames} from '@/store/types'
import {Action, Getter, Mutation} from 'vuex-class'
import FieldSlider from './FieldSlider.vue'
import {httpGet, httpPost} from '@/util/http'
import {apiUrl, apiUrl3} from '@/domain/constants'
@Component({
    components: {
        FieldSlider
    }
})
export default class CadastreRequests extends Vue {
    @Action(ActionNames.updateMapDataInRegion) private updateMapDataInRegion!: any
    @Action(ActionNames.fetchCurrentFarmCadastres) private fetchCurrentFarmCadastres !: any
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: any
    @Getter(GetterNames.getFarmFromRoute) private getFarmFromRoute!: any
    @Getter(GetterNames.getFarmMapControllers) private getFarmMapControllers!: any
    @Getter(GetterNames.getMapData) private mapData !: any
    @Getter(GetterNames.getSubfieldIndicatorData) private getSubfieldIndicatorData!: any
    @Getter(GetterNames.getFieldRequestComparisonMode) private getFieldRequestComparisonMode !: boolean
    @Getter(GetterNames.getSwiperPositionX) private swiperPositionX!: number
    @Getter(GetterNames.getCadastreData) private getCadastreData!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames !: any
    @Mutation(MutationNames.setLegendCoordinatesArray) private setLegendCoordinatesArray!: any
    @Mutation(MutationNames.setCadastreData) private setCadastreData!: any
    @Mutation(MutationNames.setSelectedCadastresFarm) private setSelectedCadastresFarm!: any
    @Prop() private vlView!: any
    private selectedFeatures: any[] = []
    private textScale = 1
    private boundaries: any = [0, 0, 0, 0]
    private currentCadastre: any = {}

    private getCadastreBoundaries(cadastre: any) {
      let Xmin = Number.MAX_SAFE_INTEGER
      let Xmax = Number.MIN_SAFE_INTEGER
      let Ymin = Number.MAX_SAFE_INTEGER
      let Ymax = Number.MIN_SAFE_INTEGER
      for (const arr of cadastre.cadastre.geom.coordinates[0][0]) {
        Xmax = arr[0] > Xmax ? arr[0] : Xmax
        Xmin = arr[0] < Xmin ? arr[0] : Xmin
        Ymax = arr[1] > Ymax ? arr[1] : Ymax
        Ymin = arr[1] < Ymin ? arr[1] : Ymin
      }
      return [Xmax, Xmin, Ymax, Ymin]
    }

    private getFieldById(id: string) {
      if (this.getFarmFromRoute.cadastres) {
        const cad = this.getFarmFromRoute.cadastres.find((x: any) => {
          if (x.cadastre) {
            return x.cadastre.cad_nomer === id
          }
        })
        this.currentCadastre = cad
        return cad
      }
      return false
    }

    private getCenter(fieldId: any) {
      const field = this.getFieldById(fieldId)
      this.setCurrentCadastreData(field)
      const boundaries = this.getCadastreBoundaries(field)
      return [ (boundaries[0] + boundaries[1]) / 2, (boundaries[2] + boundaries[3]) / 2 ]
    }

    private getZoom(fieldId: any) {
      const field = this.getFieldById(fieldId)
      const boundaries = this.getCadastreBoundaries(field)
      const dividend1 = 2 * Math.PI * 6378137 * 0.9 * window.innerHeight
      const dividend2 = 2 * Math.PI * 6378137 * 0.8 * window.innerWidth
      const divisor1 = boundaries[2] - boundaries[3]
      const divisor2 = boundaries[0] - boundaries[1]
      return Math.log(Math.min((dividend1 / divisor1), (dividend2 / divisor2))) / Math.log(2) - 8
    }

    @Watch('selectedFeatures')
    private setChosenCadastreField(features: any, oldFeatures: any) {
      if (features.length === 0) return
      if (this.$route.name !== 'registercadastre') {
        this.handleFieldsClick(features)
      }
    }

  private handleFieldsClick(features: any[]) {
    if (features === null) return
    if (this.currentRoute.name !== 'cadastre' && this.currentRoute.name !== 'subfield'
        && this.currentRoute.name !== 'subfields' && !this.currentRoute.params.subfieldId
        && this.currentRoute.name !== 'farm' && this.currentRoute.name !== 'subfieldsrating'
        && this.currentRoute.name !== 'fieldagrochem' && this.currentRoute.name !== 'cadastreinfo') return
    if (features.length > 0) {
      if (features[0].properties === null) return
      const currentFeatureProps = features.pop().properties
      if (currentFeatureProps && currentFeatureProps.type === 'cadastre') {
        this.vlView.animate({
          center: this.getCenter(currentFeatureProps.id.toString()),
          zoom: Math.floor(this.getZoom(currentFeatureProps.id.toString())),
          duration: 800
        })
      }
    }
  }

  private getFormattedCadastre(cadastre: any) {
    return cadastre ? [...cadastre].map((d, i) => i % 3 === cadastre.length % 3 ? '-' + d : d).join('') : ''
  }

    private mounted() {
      this.currentCadastre = []
        // this.setSelectedCadastresFarm(this.$route.params.farmId)
      this.fetchCurrentFarmCadastres()
      this.currentCadastre = this.getFarmFromRoute.cadastres.find((cadastr: any) => {
          if (cadastr && cadastr.cadastre) {
            return cadastr.cadastre.cad_nomer === this.$route.params.cadastreId
          }
        })
      if (this.currentCadastre) {
            this.setCurrentCadastreData(this.currentCadastre)
            this.getBoundaries(this.currentCadastre)
            this.updateMapDataInRegion({ boundaries: this.boundaries })
        }
    }

    private setCurrentCadastreData(cadastre: any) {
      this.setCadastreData({
        cadastreId: cadastre.cadastre.cad_nomer,
        area: cadastre.area,
        araeble_by_doc: cadastre.araeble_by_doc,
        pasture: cadastre.pasture,
        arable: cadastre.arable,
        fields: cadastre.fields,
        capture: cadastre.capture,
        notUsed: cadastre.not_used,
        documentDeadline: cadastre.document_deadline
      })
    }

    private getBoundaries(cad: any) {
        this.setLegendCoordinatesArray([])
        let Xmin = Number.MAX_SAFE_INTEGER
        let Xmax = Number.MIN_SAFE_INTEGER
        let Ymin = Number.MAX_SAFE_INTEGER
        let Ymax = Number.MIN_SAFE_INTEGER
        if (!cad) return
        for (const arr of cad.cadastre.geom.coordinates[0][0]) {
          Xmax = arr[0] > Xmax ? arr[0] : Xmax
          Xmin = arr[0] < Xmin ? arr[0] : Xmin
          Ymax = arr[1] > Ymax ? arr[1] : Ymax
          Ymin = arr[1] < Ymin ? arr[1] : Ymin
        }
        this.boundaries = [Xmin, Ymin, Xmax, Ymax]
    }

    @Watch('mapData.zoom')
    private zoomChanged(currentZoom: number) {
      this.textScale = this.mapZoomToTextScale(currentZoom)
    }

    private mapZoomToTextScale(currentZoom: number) {
      let maxScale = 3
      if (this.currentRoute.name === 'farm') {
        maxScale = this.getFarmMapControllers.textMaxScale
      }
      const minScale = 1
      const zoomRatio = (currentZoom - this.mapData.MIN_ZOOM - 2) / (this.mapData.MAX_ZOOM - this.mapData.MIN_ZOOM)
      return zoomRatio * (maxScale - minScale) + minScale
    }

}
