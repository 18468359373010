






































































import { Component, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { GetterNames } from '@/store/types'
import Farm from '@/models/Farm'
import i18n from '@/i18n'
import SubfieldRenameModel from './SubfieldRenameModel.vue'

@Component({
  components: {
    SubfieldRenameModel
  }
})
export default class Subfields extends Vue {
    @Getter(GetterNames.getFarmFromRoute) private getFarmFromRoute!: Farm
    private searchText = ''

   private getFieldsList() {
     const searchValue = this.searchText.replace('№', '').trim()
     const searchResult = []
     if (this.getFarmFromRoute.fields) {
       for (const cropRotations of this.getFarmFromRoute.fields) {
         if (cropRotations.name.toLowerCase().includes(searchValue.toLowerCase()) ) {
           searchResult.push(cropRotations)
         }
       }
     }

     return searchResult
   }

    private setDefaultDate(date: any) {
        if (date) {
            return `${date.substring(8, 10)}.${date.substring(5, 7)}.${date.substring(0, 4)}`
        } else return i18n.t('messages.phrases.no_data')
    }

    private close() {
        this.$router.push(`/cadastres/farm/${this.getFarmFromRoute.id}`)
    }
}
