







import { Component, Vue, Prop } from 'vue-property-decorator'
import { GetterNames } from '@/store/types'
import { Getter } from 'vuex-class'
import GeozoneEdit from './components/GeozoneEdit.vue'
import TelematicsMap from './components/TelematicsMap.vue'
@Component({
    components: {
        GeozoneEdit,
        TelematicsMap
    }
})
export default class TelematicsContainer extends Vue {
    @Prop({default: null}) private map!: any
    @Getter(GetterNames.getTelematicsInfo) private getTelematicsInfo!: any

    get isTelematicsControlPanelVisible() {
        const result = this.getTelematicsInfo.tractorsData.find((x: any) => x.IS_LIVE || x.IS_HISTORY)
        return result ? true : false
    }
}
