



























































import { Component, Vue, Watch} from 'vue-property-decorator'
import { GetterNames, MutationNames } from '@/store/types'
import { Getter, Mutation } from 'vuex-class'
@Component
export default class OrderFarmData extends Vue {
    @Getter(GetterNames.getMapData) private mapData !: any
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: any
    @Getter(GetterNames.getFarms) private getFarms!: any
    @Getter(GetterNames.getNewFarmRequest) private getNewFarmRequest!: any
    @Mutation(MutationNames.setCurrentCadastreIndex) private setCurrentCadastreIndex!: any
    @Mutation(MutationNames.setNewFarmRequest) private setNewFarmRequest!: any
    private textScale = 1
    private selectedFeatures: any[] = []

    get checkIfMapselection() {
        if (this.getNewFarmRequest.selectedFarms.find((x: any) => x.mapSelection)) return true
        else return false
    }
    @Watch('selectedFeatures')
    private featureSelected(newArr: any, oldArr: any) {
        if (newArr.length === 0) return
        else {
            if (newArr[0].properties.id) {
                const fieldId = newArr[0].properties.id
                const farmrequest = this.getNewFarmRequest
                const farm = farmrequest.selectedFarms.find((x: any) => x.mapSelection)
                if (farm) {
                    if (farm.farm.fields.find((x: any) => x.id === fieldId)) {
                        if (farmrequest.selectedFarms[farmrequest.selectedFarms.indexOf(farm)].fields.includes(fieldId)) {
                            farmrequest.selectedFarms[farmrequest.selectedFarms.indexOf(farm)].fields.
                            splice(farmrequest.selectedFarms[farmrequest.selectedFarms.indexOf(farm)].fields.indexOf(fieldId), 1)
                        } else {
                            farmrequest.selectedFarms[farmrequest.selectedFarms.indexOf(farm)].fields.push(fieldId)
                        }
                        this.setNewFarmRequest({selectedFarms: farmrequest.selectedFarms})
                    }
                }
            }
        }
        this.selectedFeatures = []
    }
    private getFieldCenter(field: any) {
        let Xmin = Number.MAX_SAFE_INTEGER
        let Xmax = Number.MIN_SAFE_INTEGER
        let Ymin = Number.MAX_SAFE_INTEGER
        let Ymax = Number.MIN_SAFE_INTEGER
        for (const arr of field.geom.coordinates[0][0]) {
            Xmax = arr[0] > Xmax ? arr[0] : Xmax
            Xmin = arr[0] < Xmin ? arr[0] : Xmin
            Ymax = arr[1] > Ymax ? arr[1] : Ymax
            Ymin = arr[1] < Ymin ? arr[1] : Ymin
        }
        return [(Xmin + Xmax) / 2, (Ymin + Ymax) / 2]
    }

    @Watch('zoom')
    private zoomChanged(currentZoom: number) {
      this.textScale = this.mapZoomToTextScale(currentZoom)
    }

    private mapZoomToTextScale(currentZoom: number) {
      const maxScale = 4
      const minScale = 1
      const zoomRatio = (currentZoom - this.mapData.MIN_ZOOM) / (this.mapData.MAX_ZOOM - this.mapData.MIN_ZOOM)
      return zoomRatio * (maxScale - minScale) + minScale
    }
}
