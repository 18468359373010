









































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator'
import { Getter, Mutation, Action } from 'vuex-class'
import { GetterNames, MutationNames, ActionNames } from '@/store/types'
import { Route } from 'vue-router'
import { httpGet, httpPost, httpDelete } from '@/util/http'
import { baseUrl, apiUrl } from '@/domain/constants'
import i18n from '@/i18n'
@Component({
  components: {

  }
})
export default class HandBookView extends Vue {
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: Route
    private searchText = ''
    private category = [
        {
            url: 'diseases',
            img: 'diseases.png',
            text: i18n.t('headers.workspace.fields.notes.note_addition.problem.type1'),
            id: 'DI'
        },
        {
            url: 'pests',
            img: 'pests.png',
            text: i18n.t('headers.workspace.fields.notes.note_addition.problem.type2'),
            id: 'PE'
        },
        {
            url: 'weedplants',
            img: 'weedplants.png',
            text: i18n.t('headers.workspace.fields.notes.note_addition.problem.type3'),
            id: 'WE'
        }
    ]
    private crops = [i18n.t('headers.workspace.guide.category[0]') as string, i18n.t('headers.workspace.guide.category[1]') as string, i18n.t('headers.workspace.guide.category[2]') as string, i18n.t('headers.workspace.guide.category[3]') as string, i18n.t('headers.workspace.guide.category[4]') as string, i18n.t('headers.workspace.guide.category[5]') as string,
    i18n.t('headers.workspace.guide.category[6]') as string, i18n.t('headers.workspace.guide.category[7]') as string, i18n.t('headers.workspace.guide.category[8]') as string, i18n.t('headers.workspace.guide.category[9]') as string, i18n.t('headers.workspace.guide.category[10]') as string, i18n.t('headers.workspace.guide.category[11]') as string, i18n.t('headers.workspace.guide.category[12]') as string,
    i18n.t('headers.workspace.guide.category[13]') as string, i18n.t('headers.workspace.guide.category[14]') as string, i18n.t('headers.workspace.guide.category[15]') as string, i18n.t('headers.workspace.guide.category[16]') as string, i18n.t('headers.workspace.guide.category[17]') as string, i18n.t('headers.workspace.guide.category[18]') as string]
    private chosenCropIndex = 0
    private chosenSortIndex = 0
    private categoryData = []

    @Watch('chosenCropIndex')
    private watchchosenCropIndex(val: any) {
        console.log(val)
    }

    get currentCategory() {
         return this.category.find(x => x.url === this.currentRoute.params.category)
    }
    get getResult() {
        if (this.chosenCropIndex === 0) {
            return this.categoryData.filter((x: any) => x.ru_name.toUpperCase().includes(this.searchText.toUpperCase()))
        } else {
            return []
        }
    }
    private getImageUrl(imageUrl: any) {
        if (!imageUrl) {
            return 'https://cabinet.egistic.kz/media/front/no-image.png'
        }
        return `${baseUrl}${imageUrl}`
    }
    private mounted() {
        this.getData()
    }
    private getData() {
        if (this.currentCategory) {
            httpGet({
                url: `${apiUrl}/handbook/infopage/${this.currentCategory.id}`,
                isSecureRequest: true,
                onSuccess: json => {
                    this.categoryData = json
                },
                onError: error => {
                    console.log(error)
                },
                doFinally: () => {
                    //
                }
            })
        }
    }
    private close() {
        this.$router.push(`/handbook`)
    }
}
