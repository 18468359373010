import { Module, GetterTree, ActionTree, MutationTree } from 'vuex'
import { GetterNames, ActionNames, MutationNames } from '../../types'
import router from '@/router'
import { Route } from 'vue-router'
const states = {
    sidebarModel: true,
    sideboardIsShown: true,
    loadingDataNames: new Array<string>(),
    loadingTractorInfo: new Array<string>(),
    technologyEvents: new Array<string>(),
    fullBlindLoading: false,
    isDataLoading: false,
    signViewData: {
        needToConfirmMail: false,
        signError: {
            needToShow: false,
            details: ''
        }
    },
    priceRanges: new Array<any>(),
    currentRoute: router.currentRoute,
    newFarmRequest: {
        isNewRequest: false,
        farmId: '',
        selectedFarms: []
    },
    noteSupport: {
        noteId: null
    },
    mapScreen: {
        start: false,
        blobImg: null
    },
    guideTourActivators: {
        farmTour: false,
        fieldTour: false,
        indicatorsTour: false
    },
    photosData: {
        currentIndex: 0,
        data: []
    },
    errorHandler: new Array<any>(),
    fieldByAllnotes: [] as any,
    addAllNote: true
}

export type IState = typeof states

const mutations: MutationTree<IState> = {
    [MutationNames.setSidebarModel](state, val: any) {
        state.sidebarModel = val
    },

    [MutationNames.changeNeedToConfirmMail](state, val: boolean) {
        state.signViewData.needToConfirmMail = val
    },

    [MutationNames.setSignError](state, { needToShow, details }) {
        state.signViewData.signError.needToShow = needToShow
        state.signViewData.signError.details = details
    },

    [MutationNames.setIsDataLoading](state, val: boolean) {
        state.isDataLoading = val
    },

    [MutationNames.setLoadingDataNames](state, val: string) {
        if (val === 'clean') {
            state.loadingDataNames = new Array<string>()
        } else if (state.loadingDataNames.includes(val)) {
            const index = state.loadingDataNames.indexOf(val)
            state.loadingDataNames.splice(index, 1)
        } else {
            state.loadingDataNames.push(val)
        }
    },

    [MutationNames.setLoadingTractorInfo](state, val: string) {
        if (val === 'clean') {
            state.loadingTractorInfo = new Array<string>()
        } else if (state.loadingTractorInfo.includes(val)) {
            const index = state.loadingTractorInfo.indexOf(val)
            state.loadingTractorInfo.splice(index, 1)
        } else {
            state.loadingTractorInfo.push(val)
        }
    },

    [MutationNames.setTechnologyEvents](state, val: any) {

        if (val === 'clear') {
            state.technologyEvents = []
        } else if (typeof val === "number") {
            const eventIndex = state.technologyEvents.findIndex((x: any) => x.id === val)
            if (eventIndex !== -1) {
                state.technologyEvents.splice(eventIndex, 1)
            }
        } else {
            const eventIndex = state.technologyEvents.findIndex((x: any) => x.id === val.id)
            if (eventIndex !== -1) {
                state.technologyEvents[eventIndex] = val
            } else {
                state.technologyEvents.push(val)
            }
        }
        // const newEventIndex = state.technologyEvents.findIndex((x: any) => x.id === val.id)
        // if (newEventIndex === -1) {
        //     state.technologyEvents.push(val)
        // }
    },

    [MutationNames.setFullBlindLoading](state, val: boolean) {
        state.fullBlindLoading = val
    },

    [MutationNames.setCurrentRoute](state, route: Route) {
        state.currentRoute = route
    },

    [MutationNames.setSideboardIsShown](state, val: boolean) {
        state.sideboardIsShown = val
    },

    [MutationNames.setNewFarmRequest](state, val: any) {
        if (val.hasOwnProperty('isNewRequest')) state.newFarmRequest.isNewRequest = val.isNewRequest
        if (val.hasOwnProperty('farmId')) state.newFarmRequest.farmId = val.farmId
        if (val.hasOwnProperty('selectedFarms')) state.newFarmRequest.selectedFarms = val.selectedFarms
    },

    [MutationNames.setNoteSupport](state, val: any) {
        if (val.hasOwnProperty('noteId')) state.noteSupport.noteId = val.noteId
    },

    [MutationNames.setMapScreen](state, val: any) {
        if (val.hasOwnProperty('start')) state.mapScreen.start = val.start
        if (val.hasOwnProperty('blobImg')) state.mapScreen.blobImg = val.blobImg
    },

    [MutationNames.setGuideTourActivators](state, val: any) {
        if (val.hasOwnProperty('farmTour')) state.guideTourActivators.farmTour = val.farmTour
        if (val.hasOwnProperty('fieldTour')) state.guideTourActivators.fieldTour = val.fieldTour
        if (val.hasOwnProperty('indicatorsTour')) state.guideTourActivators.indicatorsTour = val.indicatorsTour
    },

    [MutationNames.setPhotosData](state, val: any) {
        if (val.hasOwnProperty('currentIndex')) state.photosData.currentIndex = val.currentIndex
        if (val.hasOwnProperty('data')) state.photosData.data = val.data
    },

    [MutationNames.setErrorHandler](state, val: any) {
        const index = state.errorHandler.findIndex((x: any) => x.id === val.id)
        if (index !== -1) {
            state.errorHandler.splice(index, 1)
        } else {
            state.errorHandler.push(val)
        }
    },

    [MutationNames.setFieldByAllnotes](state, val: any) {
        state.fieldByAllnotes = val
    },

    [MutationNames.setAddAllNote](state, val: any) {
        state.addAllNote = val
    }
}

const actions: ActionTree<IState, any> = {
}

const getters: GetterTree<IState, any> = {
    [GetterNames.getSidebarModel]: state => state.sidebarModel,

    [GetterNames.getCurrentRoute]: state => {
        return state.currentRoute
    },
    [GetterNames.getSignViewData]: state => {
        return state.signViewData
    },
    [GetterNames.getSideboardIsShown]: state => {
        return state.sideboardIsShown
    },
    [GetterNames.getIsDataLoading]: state => {
        return state.isDataLoading
    },

    [GetterNames.getLoadingDataNames]: state => (val: string) => {
        if (val === 'isDataLoading') {
            return state.loadingDataNames.length > 0
        } else if (val === 'all') {
            return state.loadingDataNames
        } else {
            return state.loadingDataNames.includes(val)
        }
    },

    [GetterNames.getLoadingTractorInfo]: state => (val: string) => {
        if (val === 'isDataLoading') {
            return state.loadingTractorInfo.length > 0
        } else if (val === 'all') {
            return state.loadingTractorInfo
        } else {
            return state.loadingTractorInfo.includes(val)
        }
    },

    [GetterNames.getFullBlindLoading]: state => state.fullBlindLoading,

    [GetterNames.getNewFarmRequest]: state => {
        return state.newFarmRequest
    },
    [GetterNames.getNoteSupport]: state => state.noteSupport,

    [GetterNames.getTechnologyEvents]: state => state.technologyEvents,

    [GetterNames.getMapScreen]: state => state.mapScreen,

    [GetterNames.getGuideTourActivators]: state => state.guideTourActivators,

    [GetterNames.getPhotosData]: state => states.photosData,

    [GetterNames.getErrorHandler]: state => states.errorHandler,

    [GetterNames.getFieldByAllnotes]: state => state.fieldByAllnotes,

    [GetterNames.getAddAllNote]: state => state.addAllNote
}

const auth: Module<IState, any> = {
    state: states,
    actions,
    mutations,
    getters
}

export default auth
