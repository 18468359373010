







































































































































































































































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import { GetterNames, MutationNames } from '@/store/types'
import { httpGet, httpDelete } from '@/util/http'
import { apiUrl, baseUrl } from '@/domain/constants'
import NewCarModel from './components/NewCarModel.vue'
// import * as timeago from 'timeago.js'
import NewAgregateModel from './components/NewAgregateModel.vue'
import NewComplexModel from '../FarmBoard/TelematicsBoard/FarmTelematics/components/NewComplexModel.vue'
import claasTechnicDetail from '@/views/MainPage/HomeView/Sideboards/CarparkBoard/claas/claasTechnicDetail.vue'
import i18n from '@/i18n'
@Component({
  components: {
      NewCarModel,
      NewAgregateModel,
      NewComplexModel,
      claasTechnicDetail

  }
})
export default class CarParkView extends Vue {
    @Getter(GetterNames.getSidebarModel) private getSidebarModel!: any
    @Getter(GetterNames.getPermissions) private getPermissions!: any
    @Getter(GetterNames.getLoadingDataNames) private getLoadingDataNames!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    @Mutation(MutationNames.setSideboardIsShown) private setSideboardIsShown !: any
    private activeScreen = 'machines'
    private searchText = ''
    private agregates: any = []
    private trailers: any = []
    private classTechnic: any = []
    private recordIds: any = []
    private viewType = 1
    private car: any = {
        model: {
            origin: [],
            converted: [],
            selected: '',
            onerror: ''
        },
        category: {
            origin: [],
            converted: [],
            selected: '',
            onerror: ''
        }
    }

    private claas: any = {
        category: {
            origin: [],
            converted: [],
            selected: '',
            onerror: ''
        }
    }

    private dropdownAllTechnic: boolean = true
    private dropdownClaasTechnic: boolean = true

    private filteringMarka: boolean = false
    private filteringCategory: boolean = false

    private filteringClaasMarka: boolean = false
    private filteringClaasCategory: boolean = false

    private choosenMarka: any = []
    private choosenCategory: any = []
    private choosenClaasCategory: any = []

    private filters = {
        category: [],
        class: [],
        status: {},
        total_count: 0,
        is_class_count: 0,
        status_count: []
    }

    private trailerFilters = {
        category: [],
        name: [],
        coverage: [],
        total_count: 0
    }

    private selected = {
        categories: [],
        classes: [],
        isClass: [],
        status: [],
        trailerCategories: [],
        trailerNames: [],
        trailerCoverages: []
    }

    private showOnOffTechnic() {
        if (this.dropdownAllTechnic) return require('!html-loader!@/assets/icons/arrow_up.svg')
        return require('!html-loader!@/assets/icons/arrow_icon.svg')
    }

    private showOnOffClaas() {
        if (this.dropdownClaasTechnic) return require('!html-loader!@/assets/icons/arrow_up.svg')
        return require('!html-loader!@/assets/icons/arrow_icon.svg')
    }

    private getImageUrl(imageUrl: any) {
        return `${baseUrl}${imageUrl}`
    }
    private showRecords(id: any) {
        if (this.recordIds.includes(id)) {
            this.recordIds.splice(this.recordIds.indexOf(id), 1)
        } else {
            this.recordIds.push(id)
        }
    }

    private agregatesResults() {
        if (this.searchText !== '') {
            return this.agregatesResult()
        }
        if (this.choosenMarka.length > 0) {
            return this.orderingListTechnic(this.agregates, this.choosenMarka, 'class_name')
        }
        if (this.choosenCategory.length > 0) {
            return this.orderingListTechnic(this.agregates, this.choosenCategory, 'category_name')
        }
        return this.agregates
    }

    private agregatesResult() {
        const searchResult = []
        for (const agregate of this.agregates) {
            if (agregate.category_name.toLowerCase().includes(this.searchText.toLowerCase()) ||
                agregate.class_name.toLowerCase().includes(this.searchText.toLowerCase()) ||
                agregate.uid.toString().toLowerCase().includes(this.searchText.toLowerCase())) {
                searchResult.push(agregate)
            }
        }
        return searchResult
    }

    private orderingListTechnic(list: any, arr: any, key: any) {
        const searchResult = []
        for (const item of arr) {
            const index = list.filter((x: any) => x[`${key}`] === item)
            searchResult.push(...index)
        }
        return searchResult
    }

    private getClassTechnics() {
        if (this.searchText !== '') {
            return this.getClassTechnic()
        }
        if (this.choosenClaasCategory.length > 0) {
            return this.orderingListTechnic(this.classTechnic, this.choosenClaasCategory, 'product_family')
        }
        return this.classTechnic
    }

    private getClassTechnic() {
        const searchResult = []
        for (const claas of this.classTechnic) {
            if (claas.name.toLowerCase().includes(this.searchText.toLowerCase())) {
                searchResult.push(claas)
            }
        }
        return searchResult
    }

    private trailersResult() {
        const searchResult = []
        for (const trailer of this.trailers) {
            if (trailer.name.toLowerCase().includes(this.searchText.toLowerCase())) {
                searchResult.push(trailer)
            }
        }
        return searchResult
    }

    private getStatus(status: any, startDate: any, endDate: any, ifcolor: boolean) {
        const today = new Date()
        const startTime = new Date(startDate)
        const endTime = new Date(endDate)
        if (status === 'done') return ifcolor ? '#4B970F' :  (i18n.t('general.process.done') as string)
        if (status === 'cancelled') return ifcolor ? '#D51900' :  (i18n.t('general.process.cancelled') as string)
        if (today.getTime() < startTime.getTime()) {
            return ifcolor ? '#00B2FF' :  (i18n.t('general.process.planned') as string)
        } else if (today.getTime() < endTime.getTime()) {
            return ifcolor ? '#FFC700' :  (i18n.t('general.process.in_process') as string)
        } else {
            return ifcolor ? '#D51900' :  (i18n.t('general.process.outdated') as string)
        }
    }
    private getDuration(startDate: any, endDate: any) {
        if (startDate && endDate && !startDate.includes('NaN-NaN-NaN') && !endDate.includes('NaN-NaN-NaN')) {
            const startDay = startDate.split(' ')[0].split('-')
            const startTime = startDate.split(' ')[1].split(':')
            const endDay = endDate.split(' ')[0].split('-')
            const endTime = endDate.split(' ')[1].split(':')
            return + startDay[2] + '.' + startDay[1] + '.' + startDay[0] + ' ' + startTime[0] + ':' + startTime[1] +
                ' - \n ' + endDay[2] + '.' + endDay[1] + '.' + endDay[0] + ' ' + endTime[0] + ':' + endTime[1]
        }
        return (i18n.t('general.dates.from') as string) + ( i18n.t('general.dates.format_date') as string) + ' ' + (i18n.t('general.dates.format_time') as string) + ' - ' + (i18n.t('general.dates.to') as string) + ( i18n.t('general.dates.format_date') as string) + ' ' + (i18n.t('general.dates.format_time') as string)
    }

    private checkboxItem(item: any, arr: any) {
        const index = arr.findIndex((id: number) => id === item)
        if (index !== -1) {
            arr.splice(index, 1)
        } else {
            arr.push(item)
        }
    }

    private mounted() {
        this.getAgregates()
        this.getTrailers()
        this.getClaasTechnic()
        this.setSideboardIsShown(true)
    }

    private getAgregates() {
        this.setLoadingDataNames('carparkGetAgregates')
        httpGet({
            url: `${apiUrl}/yields/tractor/list/?category=${this.selected.categories.toString()}&class_name=${this.selected.classes.toString()}&status=${this.selected.status.toString()}&is_claas=${this.selected.isClass.length > 0 ? this.selected.isClass : 'False'}`,
            isSecureRequest: true,
            onSuccess: json => {
                this.filters = {
                    category: json.category,
                    class: json.class,
                    status: json.status,
                    total_count: json.total_count,
                    is_class_count: json.is_class_count,
                    status_count: json.status_count
                }

                this.agregates = json.filtered_data
                this.car.model.converted = json.filtered_data.map((x: any) => x.class_name)
                this.car.model.converted = this.car.model.converted.filter((x: any, y: any) => this.car.model.converted.indexOf(x) === y)

                this.car.category.converted = json.filtered_data.map((x: any) => x.category_name)
                this.car.category.converted = this.car.category.converted.filter((x: any, y: any) => this.car.category.converted.indexOf(x) === y)
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('carparkGetAgregates')
            }
        })
    }

    private toggleFilterItem(filterType: string, id: any) {
        let filterCategory: any[] = []
        // console.log(filterType, id, 'args')
        // console.log(this.selected, 'selected')

        if (filterType === 'categories') {
            filterCategory = this.selected.categories
        } else if (filterType === 'classes') {
            filterCategory = this.selected.classes
        } else if (filterType === 'status') {
            filterCategory = this.selected.status
        } else if (filterType === 'isClass') {
            filterCategory = this.selected.isClass
        } else if (filterType === 'trailerCategories') {
            filterCategory = this.selected.trailerCategories
        } else if (filterType === 'trailerNames') {
            filterCategory = this.selected.trailerNames
        } else if (filterType === 'trailerCoverages') {
            filterCategory = this.selected.trailerCoverages
        }
        // console.log(filterCategory, 'filterCategory')
        const currentFilterItemIndex = filterCategory.findIndex((x: any) => x === id)
        // console.log(currentFilterItemIndex, 'currentFilterItemIndex')
        if (currentFilterItemIndex === -1) {
            if (filterType === 'status' && id !== 'all') {
                filterCategory.splice(0, 1, id)
            } else if (id === 'all') {
                filterCategory.length = 0
            } else {
                filterCategory.push(id)
            }
        } else {
            filterCategory.splice(currentFilterItemIndex, 1)
        }

        if (filterType.includes('trailer')) {
            this.getTrailers()
        } else {
            this.getAgregates()
        }
        // console.log(filterCategory)
        // console.log('------')
    }

    private getTrailers() { // agregat
        this.setLoadingDataNames('carparkGetTrailers')
        httpGet({
            url:  `${apiUrl}/yields/trailer/list/?category=${this.selected.trailerCategories.toString()}&name=${this.selected.trailerNames.toString()}&coverage=${this.selected.trailerCoverages.toString()}`,
            isSecureRequest: true,
            onSuccess: json => {
                this.trailerFilters = {
                    category: json.category,
                    name: json.name,
                    coverage: json.coverage,
                    total_count: json.total_count
                }
                this.trailers = json.filtered_data
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('carparkGetTrailers')
            }
        })
    }

    private getClaasTechnic() {
        this.setLoadingDataNames('carparkGetAgregates')
        httpGet({
            url: `${apiUrl}/yields/claas/get/machines/`,
            isSecureRequest: true,
            onSuccess: json => {
                this.classTechnic = json
                this.claas.category.converted = json.map((x: any) => x.product_family)
                this.claas.category.converted = this.claas.category.converted.filter((x: any, y: any) => this.claas.category.converted.indexOf(x) === y)
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('carparkGetAgregates')
            }
        })
    }

    private deleteAgregate(val: any) {
        if (confirm(i18n.t('messages.questions.delete.device') as string)) {
            this.setLoadingDataNames('carparkDeleteAgregate')
            httpDelete({
                url: `${apiUrl}/yields/tractor/${val}`,
                isSecureRequest: true,
                onSuccess: () => {
                    alert(i18n.t('messages.success.deleted'))
                },
                onError: error => {
                    console.log(error)
                },
                doFinally: () => {
                    this.setLoadingDataNames('carparkDeleteAgregate')
                    this.getAgregates()
                }
            })
        }
    }

    private deleteTrailer(val: any) {
        if (confirm(i18n.t('messages.questions.delete.device') as string)) {
            this.setLoadingDataNames('carparkDeleteTrailer')
            httpDelete({
                url: `${apiUrl}/yields/trailer/${val}`,
                isSecureRequest: true,
                onSuccess: () => {
                    alert(i18n.t('messages.success.deleted'))
                },
                onError: error => {
                    console.log(error)
                },
                doFinally: () => {
                    this.setLoadingDataNames('carparkDeleteTrailer')
                    this.getTrailers()
                }
            })
        }
    }

    private getTractorImageByCategoryId(id: number) {
        switch (id) {
            case 1: return 'tractor'
            case 2: return 'combine'
            case 3: return 'tractor'
            case 4: return 'harvester'
            case 5: return 'tractor'
            case 6: return 'truck'
            case 7: return 'tanker'
            case 8: return 'loader'
            case 9: return 'dumper'
            case 10: return 'tractor'
            case 11: return 'tractor'
            default: return 'combine'
        }
    }

    private close() {
        this.$router.push(`/cadastres`).catch(e => {/**/})
    }

}
