// export const environment = 'cabinet.openlayers.kz'
export const environment = 'cabinet.egistic.kz'

// const domain = 'localhost'
// const domain = 'openlayers'
// const domain = 'demoegistic'
const domain = 'egistic'

export const mainDomain = () => {
    if (domain.includes('localhost')) return '.localhost.kz'
    else if (domain.includes('openlayers')) return '.openlayers.kz'
    else if (domain.includes('demoegistic')) return '.demoegistic.kz'
    else if (domain.includes('egistic')) return '.egistic.kz'
    else return '.egistic.kz'
}

export const landingUrl = () => {
    if (domain.includes('localhost')) return 'http://localhost.kz:8080'
    else if (domain.includes('openlayers')) return 'https://openlayers.kz'
    else if (domain.includes('demoegistic')) return 'https://demoegistic.kz'
    else if (domain.includes('egistic')) return 'https://egistic.kz'
    else return 'https://egistic.kz'
}

export const loginUrl = () => {
    if (domain.includes('localhost')) return 'http://login.localhost.kz:8080'
    else if (domain.includes('openlayers')) return 'https://login.openlayers.kz'
    else if (domain.includes('demoegistic')) return 'https://login.demoegistic.kz'
    else if (domain.includes('egistic')) return 'https://login.egistic.kz'
    else return 'https://login.egistic.kz'
}

export const farmUrl = () => {
    if (domain.includes('localhost')) return 'http://farm.localhost.kz:8080'
    else if (domain.includes('openlayers')) return 'https://farm.openlayers.kz'
    else if (domain.includes('demoegistic')) return 'https://farm.demoegistic.kz'
    else if (domain.includes('egistic')) return 'https://farm.egistic.kz'
    else return 'https://farm.egistic.kz'
}

export const consultingUrl = () => {
    if (domain.includes('localhost')) return 'http://consulting.localhost.kz:8080'
    else if (domain.includes('openlayers')) return 'https://consulting.openlayers.kz'
    else if (domain.includes('demoegistic')) return 'https://consulting.demoegistic.kz'
    else if (domain.includes('egistic')) return 'https://consulting.egistic.kz'
    else return 'https://consulting.egistic.kz'
}

export const marketUrl = () => {
    if (domain.includes('localhost')) return 'http://market.localhost.kz:8080'
    else if (domain.includes('openlayers')) return 'https://market.openlayers.kz'
    else if (domain.includes('demoegistic')) return 'https://market.demoegistic.kz'
    else if (domain.includes('egistic')) return 'https://market.egistic.kz'
    else return 'https://market.egistic.kz'
}

export const rabotaUrl = () => {
    if (domain.includes('localhost')) return 'http://rabota.localhost.kz:8080'
    else if (domain.includes('openlayers')) return 'https://rabota.openlayers.kz'
    else if (domain.includes('demoegistic')) return 'https://rabota.demoegistic.kz'
    else if (domain.includes('egistic')) return 'https://rabota.egistic.kz'
    else return 'https://rabota.egistic.kz'
}