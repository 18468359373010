


































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Getter, Action, Mutation } from 'vuex-class'
import {GetterNames, MutationNames, ActionNames} from '@/store/types'
import { httpGet } from '@/util/http'
import { apiUrl } from '@/domain/constants'
import i18n from '@/i18n'
import { openCrossOriginWebSite } from '@/utils/crossOrigin'
@Component
export default class Sidebar extends Vue {
    @Action(ActionNames.setPermissionList) private setPermissionList!: any
    @Getter(GetterNames.getSidebarModel) private getSidebarModel!: any
    @Getter(GetterNames.getPermissions) private getPermissions!: any
    @Getter(GetterNames.getNotificationData) private getNotificationData!: any
    @Mutation(MutationNames.setSidebarModel) private setSidebarModel!: any
    @Mutation(MutationNames.setSideboardIsShown) private setSideboardIsShown !: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    private workspaces = []
    private workspaceDropdown = false

    private getCurrentWorkspace() {
        if (this.workspaces.length > 0) {
            const workspace: any = this.workspaces.find((x: any) => x.is_current)
            if (workspace) {
                return workspace.name
            }
        }
        return i18n.t('headers.workspace.projects.plural')
    }

    private mounted() {
        this.fetchWorkspaceData()
    }

    @Watch('getPermissions')
    private fetchWorkspaceData() {
        this.setLoadingDataNames('sidebarShortWorkspace')
        httpGet({
            url:  `${apiUrl}/workspace/short/`,
            isSecureRequest: true,
            onSuccess: json => {
                this.workspaces = json
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('sidebarShortWorkspace')
            }
        })
    }

    private openWorkspace(id: any) {
        this.setLoadingDataNames('sidebarOpenWorkspace')
        httpGet({
            url:  `${apiUrl}/workspace/${id}/`,
            isSecureRequest: true,
            onSuccess: json => {
                if (json.permission_list) {
                    this.setPermissionList({
                        permissions: json.permission_list,
                        workspaceToken: json.token
                    })
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('sidebarOpenWorkspace')
            }
        })
    }

    private closeSidebarModel() {
        this.setSidebarModel(false)
    }

    private closeWorkspaceDropdown() {
        this.workspaceDropdown = false
    }

    private isCurrentRoute(name: string) {
        const currentRoute = this.$route.name
        return currentRoute === name
    }

    private onClickItem(domain: string, path: string) {
        const eventName = domain === 'farm' ? `${path}` : `${domain}-${path}`
        this.$gtag.event( `farm: ${eventName}`)
        openCrossOriginWebSite(domain, path)
    }

}
