







































































































import { Vue, Component } from "vue-property-decorator"
import {httpGet, httpPost} from "@/util/http"
import {apiUrl} from "@/domain/constants"
import {Mutation} from "vuex-class"
import {MutationNames} from "@/store/types"
@Component
export default class PlanningAddPreparation extends Vue {
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    private isShown = false
    private preparationId: any = -1
    private detailsData: any = {
        preparations: {
            origin: [],
            converted: [],
            typePreparations: [],
            typePreparationsConverted: []
        },
        consumption_units: {
            origin: [],
            converted: []
        },
        storages: {
            origin: [],
            converted: []
        }
    }
    private totalArea: any = -1
    private taskId: any = -1

    private preparation: any = {
        preparation: {
            id: -1,
            name: ''
        },
        preparation_type: {} as any,
        id: -1 as any,
        type: '' as any,
        typeId: -1 as any,
        consumption_rate: 0 as any,
        consumption_rate_measurement: {
            id: 0 as any,
            name: '' as any
        } as any,
        price: 0 as any,
        working_consumption: 0 as any,
        quantity: 0 as any,
        volume: 0 as any,
        water_volume: 0 as any,
        cost: 0 as any
    }

    private onSelectPreparationType(item: string) {
        const preparation = this.detailsData.preparations.origin.find((x: any) => x.name === item)
        if (preparation) {
            this.preparation.type = preparation.name
            this.preparation.typeId = preparation.type
            this.preparation.preparation_type = preparation
            if (preparation.type === 5) {
                this.detailsData.preparations.typePreparations = this.detailsData.storages.origin
                this.detailsData.preparations.typePreparationsConverted = this.detailsData.storages.converted
            } else {
                this.detailsData.preparations.typePreparations = preparation.origin
                this.detailsData.preparations.typePreparationsConverted = preparation.converted
            }
        }
    }

    private onSelectPreparation(item: string) {
        let preparation: any = {}

        if (this.preparation.typeId === 5) {
            const storage = this.detailsData.storages.origin.find((x: any) => item === `${x.title}`)
            this.preparation.price = storage.cost
            this.preparation.quantity = storage.amount
            preparation.name = storage.name
        } else {
            preparation = this.detailsData.preparations.typePreparations.find((x: any) => x.name === item)
            this.preparation.quantity = 0
            this.preparation.price = 0
        }

        if (preparation) {
            this.preparation.preparation = preparation
        }
    }

    private onSelectConsumptionMeasure(item: any) {
        const currentMeasure = this.detailsData.consumption_units.origin.find((x: any) => x.name === item)
        if (currentMeasure) {
            this.preparation.consumption_rate_measurement = currentMeasure
        }
    }

    private getVolume(preparation: any) {
        let volume = 0
        volume = Math.floor(Number(this.totalArea) * Number(preparation.consumption_rate))
        this.preparation.volume = volume
        return volume.toString()
    }

    private getWaterVolume(preparation: any) {
        let volume = 0
        volume = Math.floor(Number(this.totalArea) * Number(preparation.working_consumption))
        this.preparation.water_volume = volume
        return volume.toString()
    }

    private getPreparationTotal() {
        if (this.preparation) {
            this.preparation.cost = (this.preparation.volume || 0) * (this.preparation.price || 0)
            return Math.floor(this.preparation.cost).toFixed(2)
        }
        return 0
    }

    private getPreparations(cultureName: any) {
        httpGet({
            url:  `${apiUrl}/techmap/get/preparations/by/culture/?culture=${cultureName}`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                if (json.ok) {
                    this.detailsData.preparations.origin = json.json.preparations_list.map((x: any) => {
                        return {
                            type: x.type,
                            name: x.type_name,
                            origin: x.preparations,
                            converted: x.preparations.map((y: any) => y.name)
                        }
                    })
                    this.detailsData.preparations.converted = json.json.preparations_list.map((x: any) => x.type_name)
                    this.detailsData.consumption_units.origin = json.json.measures
                    this.detailsData.consumption_units.converted = json.json.measures.map((x: any) => x.name)
                    this.preparation.consumption_rate_measurement = json.json.measures[0]
                }
            },
            onError: error => {
                console.log(error)
            }
        })
    }

    private checkForError() {
        let counter = 0
        if (this.preparation.preparation.name === '') {
            Vue.alert('', 'Выберите тип препарата', 'warning')
            counter++
        }
        if (this.preparation.price === 0) {
            Vue.alert('', 'Введите цену', 'warning')
            counter++
        }
        if (this.preparation.working_consumption === 0) {
            Vue.alert('', 'Введите расход рабочего раствора', 'warning')
            counter++
        }
        if (this.preparation.quantity === 0) {
            Vue.alert('', 'Введите количество семян', 'warning')
            counter++
        }
        if (this.preparation.consumption_rate === 0) {
            Vue.alert('', 'Введите норма расхода препарата', 'warning')
            counter++
        }

        return counter === 0
    }

    private createPreparation() {
        if (!this.checkForError()) return
        this.setLoadingDataNames('modelGetCars')
        httpPost({
            url:  `${apiUrl}/technology/task/preparation/create/`,
            isSecureRequest: true,
            detailResponse: true,
            body: {
                plan_task: this.taskId,
                type: 1,
                preparation: this.preparation.preparation.id,
                preparation_from_storage: null,
                preparation_type: this.preparation.preparation_type.type,
                consumption_rate: Number(this.preparation.consumption_rate),
                consumption_rate_measurement: this.preparation.consumption_rate_measurement.id,
                price: Number(this.preparation.price),
                working_consumption: Number(this.preparation.working_consumption),
                quantity: Number(this.preparation.quantity),
                volume: this.preparation.volume,
                water_volume: this.preparation.water_volume,
                cost: this.preparation.cost
            },
            onSuccess: json => {
                if (json.ok) {
                    console.log(json.json)
                    Vue.alert('', 'Вы успешно создали препарат', 'success').then(() => {
                        this.hide()
                    })
                } else {
                    this.$alert('Ошибка сервера',  'Ошибка',  'error')
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('modelGetCars')
            }
        })
    }

    private show(id: any, cultureName: any, area: any, taskId: any) {
        this.getPreparations(cultureName)
        this.preparationId = id
        this.totalArea = area
        this.taskId = taskId
        this.isShown = true
    }

    private hide() {
        this.isShown = false
        this.preparation = null
    }
}
