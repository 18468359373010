








































































































































import { Component, Vue } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import { GetterNames, MutationNames } from '@/store/types'
import { httpGet, httpDelete } from '@/util/http'
import { apiUrl } from '@/domain/constants'
import { permissionTypes } from '@/data/rolesData'
import MembersTable from './components/MembersTable.vue'
import NewMemberModal from './components/NewMemberModal.vue'
import NewFolderModal from './components/NewFolderModal.vue'

@Component({
    components: {
        MembersTable,
        NewFolderModal,
        NewMemberModal
    }
})
export default class MembersView extends Vue {
    @Getter(GetterNames.getSidebarModel) private getSidebarModel!: any
    @Getter(GetterNames.getPermissions) private getPermissions!: any
    @Getter(GetterNames.getLoadingDataNames) private getLoadingDataNames!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    @Mutation(MutationNames.setSideboardIsShown) private setSideboardIsShown !: any
    private permissionTypes = permissionTypes
    private isDataLoading = false
    private viewType = 2
    private folders = []
    private selectedFolder = 0
    private searchText = ''

    private getPermissionTypes(roles: any) {
        let str = ''
        for (const role of this.permissionTypes) {
            if (roles.includes(role.num)) {
                str += role.name + ', '
            }
        }
        if (str !== '') {
            return str.substring(0, str.length - 2)
        }
        return str
    }

    private search() {
        if (this.viewType === 1) {
            this.viewType = 2
        } else {
            const el: any = this.$refs.membersList
            if (el) {
                el.getData(1)
            }
        }
    }

    private mounted() {
        this.setSideboardIsShown(true)
    }

    private changeViewType(type: number) {
        this.viewType = type
        if (this.viewType === 1) {
            this.fetchFolders()
        } else if (this.viewType === 2) {
            const el: any = this.$refs.membersList
            if (el) {
                el.getData(1)
            }
        }
    }

    private fetchFolders() {
        this.isDataLoading = true
        this.selectedFolder = 0
        httpGet({
            url: `${apiUrl}/workspace/get/folders/`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                if (json.ok) {
                    this.folders = json.json
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.isDataLoading = false
            }
        })
    }

    private deleteFolder(folder: any) {
        this.$confirm(`Вы уверены что хотите удалить папку "${folder.name}"?`, 'Подтверждение', 'info').then(() => {
            this.setLoadingDataNames('MembersViewDeleteFolder')
            httpDelete({
                url: `${apiUrl}/workspace/folders/${folder.id}/`,
                isSecureRequest: true,
                detailResponse: true,
                onSuccess: json => {
                    if (json.ok) {
                        this.$alert('Папка успешно удалена', 'Успешно', 'success').then(() => {
                            this.changeViewType(1)
                        })
                    } else {
                        this.$alert(JSON.stringify(json.json), 'Ошибка', 'error')
                    }
                },
                onError: error => {
                    console.log(error)
                },
                doFinally: () => {
                    this.setLoadingDataNames('MembersViewDeleteFolder')
                }
            })
        })
    }

    private toggleFolder(id: number) {
        this.selectedFolder = this.selectedFolder === id ? 0 : id
    }

    private close() {
        this.setSideboardIsShown(false)
    }
}
