























































import { Component, Vue, Emit } from 'vue-property-decorator'
import { httpPostFormData, httpPutFormData } from '@/util/http'
import { apiUrl } from '@/domain/constants'
import { GetterNames, MutationNames } from '@/store/types'
import { Getter, Mutation } from 'vuex-class'
import i18n from '@/i18n'
@Component
export default class NewWorkspaceModel extends Vue {
    @Getter(GetterNames.getLoadingDataNames) private getLoadingDataNames!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    private isShown = false
    private imageView = false
    private workspaceName = ''
    private uploadedFile: any = null
    private imageUrl: any = null
    private isUpdate = false
    private workspaceForUpdate: any = null

    private setImageUrl(files: any) {
        for (const file of files) {
            this.uploadedFile = file
            this.imageUrl = URL.createObjectURL(this.uploadedFile)
            break
        }
    }

    private deleteImage() {
        this.uploadedFile = null
        this.imageUrl = null
        this.imageView = false
    }

    private show() {
        this.isShown = true
        this.uploadedFile = null
        this.imageUrl = null
        this.workspaceName = ''
    }

    private update(workspace: any) {
        this.isShown = true
        this.isUpdate = true
        this.uploadedFile = null
        this.workspaceName = workspace.owner_workspace.name
        this.imageUrl = workspace.image ? workspace.image : null
        this.workspaceForUpdate = workspace
    }

    private addNewWorkspace() {
        if (this.getLoadingDataNames('newWorkspaceAddWorkspace')) return
        if (this.workspaceName === '') {
            alert(i18n.t('messages.instructions.type_name'))
            return
        }
        const formData = new FormData()
        formData.append('name', this.workspaceName)
        if (this.uploadedFile !== null) {
            formData.append('image', this.uploadedFile)
        }
        this.setLoadingDataNames('newWorkspaceAddWorkspace')
        httpPostFormData({
            url:  `${apiUrl}/workspace/`,
            isSecureRequest: true,
            body: formData,
            onSuccess: json => {
                alert(i18n.t('messages.success.successfully_added'))
                this.reload()
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('newWorkspaceAddWorkspace')
                this.hide()
            }
        })
    }

    private updateWorkspace() {
        if (this.getLoadingDataNames('newWorkspaceUpdateWorkspace')) return
        if (this.workspaceName === '') {
            alert(i18n.t('messages.instructions.type_name'))
            return
        }
        if (!this.workspaceForUpdate) {
            return
        }
        const formData = new FormData()
        if (this.workspaceName !== this.workspaceForUpdate.owner_workspace.name) {
            formData.append('name', this.workspaceName)
        }
        if (this.uploadedFile !== null) {
            formData.append('image', this.uploadedFile)
        }
        if (this.imageUrl === null && this.workspaceForUpdate.image) {
            formData.append('is_image_deleted', 'true')
        }
        this.setLoadingDataNames('newWorkspaceUpdateWorkspace')
        httpPutFormData({
            url: `${apiUrl}/workspace/${this.workspaceForUpdate.id}/`,
            isSecureRequest: true,
            body: formData,
            onSuccess: json => {
                alert(i18n.t('messages.success.project_updated'))
                this.reload()
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('newWorkspaceUpdateWorkspace')
                this.hide()
            }
        })
    }

    @Emit('reload')
    private reload() {
        return {}
    }

    private hide() {
        this.isShown = false
        this.isUpdate = false
    }
}
