












































import { Component, Vue, Prop } from 'vue-property-decorator'
import { GetterNames, MutationNames } from '@/store/types'
import { Getter, Mutation } from 'vuex-class'

@Component
export default class RegionCadastres extends Vue {
    @Prop({default: null}) public map!: any
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: any
    @Getter(GetterNames.getRegisterFarms) private getRegisterFarms!: any
    @Mutation(MutationNames.setRegisterFarms) private setRegisterFarms!: any

    get getFilterString() {
        if (this.currentRoute.params.regionIndex) {
            const str = "kad_nomer ILIKE '" + this.currentRoute.params.regionIndex + "%'"
            return str
        }
        return '%'
    }

    private clickEvent(evt: any) {
        if (this.currentRoute.name === 'searchonmap') {
            const wmsLayer = this.map.forEachLayerAtPixel(evt.pixel, (layer: any) => {
                if (layer.values_.id === 671544) {
                    return layer
                }
            })
            if (wmsLayer) {
                const url = wmsLayer.getSource().getGetFeatureInfoUrl(
                    evt.coordinate,
                    this.map.getView().getResolution(),
                    this.map.getView().getProjection(),
                    {
                        'INFO_FORMAT': 'application/json',
                        'propertyName': 'kad_nomer,area,geom'
                    }
                )
                if (url) {
                    fetch(url).then((response: any) => {
                        return response.text()
                    }).then((featureCollections: any) => {
                        const json = JSON.parse(featureCollections)
                        if (json) {
                            const tempArray = this.getRegisterFarms.selectedCadastres
                            const index = tempArray.findIndex((x: any) => x.id === json.features[0].properties.kad_nomer)
                            if (index === -1) {
                                tempArray.push({
                                    id: json.features[0].properties.kad_nomer,
                                    area: json.features[0].properties.area,
                                    geometry: json.features[0].geometry,
                                    documentId: json.features[0].properties.kad_nomer
                                })
                            } else {
                                tempArray.splice(index, 1)
                            }
                            this.setRegisterFarms({selectedCadastres: tempArray})
                        }
                    })
                }
            }
        }
    }

    private getCenter(coordinates: any) {
        let Xmin = Number.MAX_SAFE_INTEGER
        let Xmax = Number.MIN_SAFE_INTEGER
        let Ymin = Number.MAX_SAFE_INTEGER
        let Ymax = Number.MIN_SAFE_INTEGER
        for ( const i of coordinates[0][0]) {
            Xmax = i[0] > Xmax ? i[0] : Xmax
            Xmin = i[0] < Xmin ? i[0] : Xmin
            Ymax = i[1] > Ymax ? i[1] : Ymax
            Ymin = i[1] < Ymin ? i[1] : Ymin
        }
        return [ (Xmax + Xmin) / 2, (Ymax + Ymin) / 2 ]
    }
}
