


























































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import { GetterNames, MutationNames } from '@/store/types'
import { Route } from 'vue-router'
import {httpGet, httpPost, httpPostFile, httpPut, httpPutFormData} from '@/util/http'
import { apiUrl } from '@/domain/constants'
import Farm from '@/models/Farm'
import i18n from '@/i18n'

@Component
export default class CloneCropData extends Vue {
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: Route
    @Getter(GetterNames.getFarmFromRoute) private getFarmFromRoute!: Farm
    @Getter(GetterNames.getFarms) private getFarms!: any
    // @Getter(GetterNames.getAgroChemData) private getAgroChemData!: any
    // @Mutation(MutationNames.setAgroChemData) private setAgroChemData!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    @Getter(GetterNames.getPlanningData) private getPlanningData!: any
    @Mutation(MutationNames.setPlanningData) private setPlanningData!: any
    @Mutation(MutationNames.setFarmMapControllers) private setFarmMapControllers!: any
    private selectedFarm = ''
    private fieldDropdown = false
    private cultureData = {
      id: -1,
      culture: '',
      parentCulture: '',
      sort: ''
    }

    private getCurrentFarmFields() {
        if (this.getFarmFromRoute) {
            return this.getFarmFromRoute.fields
        }
        return []
    }

    private mounted() {
        this.getData()
        this.setFarmMapControllers({ fill: true })
        this.setPlanningData({mapSelection: true, selectedFields: []})
        const data = JSON.parse(this.$route.params.culture)
        this.cultureData.culture = data.culture
        this.cultureData.parentCulture = data.parentCulture
        this.cultureData.sort = data.sort
        this.cultureData.id = data.id
    }

    private saveData() {
      const fields = this.getPlanningData.selectedFields
      this.dublicateToOtherFields(fields, this.cultureData.id)
      // fields.forEach((fieldId: any) => {
      //     this.updateHistory(fieldId)
      // })
    }

    private getData() {
        if (this.getFarmFromRoute) {
            this.selectedFarm = this.getFarmFromRoute.name
        }
    }

  private dublicateToOtherFields(fields: any, currentFieldId: number) {
    this.setLoadingDataNames('dublicateToOtherFields')
    httpPostFile({
      url: `${apiUrl}/crop_rotation/dublicate/`,
      isSecureRequest: true,
      detailResponse: true,
      body: {
        id: currentFieldId,
        fields
      },
      onSuccess: json => {
        if (json) {
          this.$alert('Данные успешно заполнены', 'Сохранено', 'success').then(() => {
            this.$router.back()
          }).catch(e => {/* */})
        }
      },
      onError: error => {
        console.log(error)
      },
      doFinally: () => {
        this.setLoadingDataNames('dublicateToOtherFields')
      }
    })
  }

    private setMapSelection() {
        this.setPlanningData({mapSelection: !this.getPlanningData.mapSelection})
    }

    private closeFieldDropdown() {
        this.fieldDropdown = false
    }

    private getFieldsNumberString() {
        const farm = this.getFarms.find((x: any) => x.name === this.selectedFarm)
        if (!farm || !farm.fields) return
        if (this.getPlanningData.selectedFields.length === 0) {
            return [i18n.t('messages.not_chosen')]
        } else if (farm.fields.length === this.getPlanningData.selectedFields.length) {
            return [i18n.t('messages.selections.all_fields')]
        } else {
            const str = ''
            const fields: any = []
            if (farm.fields && this.getPlanningData.selectedFields) {
              for (const field of farm.fields) {
                if (this.getPlanningData.selectedFields.includes(field.id)) {
                  fields.push(field.name)
                  // str = `${field.name} ${this.getAgroChemData.selectedFields.length > 1 ? ` +${this.getAgroChemData.selectedFields.length - 1}` : ''}`
                }
              }
            }
            return fields
        }
    }

    private chooseAll() {
        const farm = this.getFarms.find((x: any) => x.name === this.selectedFarm)
        if (farm.fields.length === this.getPlanningData.selectedFields.length) {
            this.setPlanningData({selectedFields: []})
        } else {
            const tempArr = []
            for (const field of farm.fields) {
                tempArr.push(field.id)
            }
            this.setPlanningData({selectedFields: tempArr})
        }
    }

    private changeSelectedField(fieldId: any) {
        const tempArr = this.getPlanningData.selectedFields
        if (tempArr.includes(fieldId)) {
            tempArr.splice(tempArr.indexOf(fieldId), 1)
        } else {
            tempArr.push(fieldId)
        }
        this.setPlanningData({selectedFields: tempArr})
    }

    private close() {
        this.$router.back()
    }

}
