












import { Component, Vue, Emit } from 'vue-property-decorator'
import { httpPutFormData } from '@/util/http'
import { apiUrl } from '@/domain/constants'
import { Getter, Action, Mutation } from 'vuex-class'
import { GetterNames, ActionNames, MutationNames } from '@/store/types'
import { Route } from 'vue-router'
import i18n from '@/i18n'
import * as turf from '@turf/turf'
import Farm from '@/models/Farm'

@Component
export default class NewNoteAlert extends Vue {
    private isShown = false
    @Action(ActionNames.reloadCurrentFarm) private reloadCurrentFarm!: any
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: Route
    @Getter(GetterNames.getFarmFromRoute) private getFarmFromRoute!: Farm
    @Getter(GetterNames.getLastNewNotePoint) private getLastNewNotePoint!: any
    @Getter(GetterNames.getFarms) private getFarms!: any
    @Getter(GetterNames.getSideboardIsShown) private getSideboardIsShown!: any
    @Getter(GetterNames.getFieldByAllnotes) private getFieldByAllnotes!: any

    @Mutation(MutationNames.setIsDataLoading) private setIsDataLoading!: any
    @Mutation(MutationNames.setIsNotesModeActive) private setIsNotesModeActive!: any
    @Mutation(MutationNames.setSideboardIsShown) private setSideboardIsShown !: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    @Mutation(MutationNames.setCurrentPlaceNotesCoordination) private setCurrentPlaceNotesCoordination!: any
    @Mutation(MutationNames.setAddAllNote) private setAddAllNote!: any

    get field() {
        if (this.getFarmFromRoute) {
            if (this.$route.name === 'allnotes') {
                return this.getFarmFromRoute.fields.find((x: any) => x.id === this.getFieldByAllnotes.id) // || x.id === this.getFieldByAllnotes.id
            } else {
                return this.getFarmFromRoute.fields.find((x: any) => x.id === parseInt(this.$route.params.subfieldId, 10))
            }
        }
        return false
    }

    private isInsideField() {
        if (this.getLastNewNotePoint.length === 0) return false
        const point = turf.point(this.getLastNewNotePoint)
        if (this.field && point) {
            const polygon = turf.polygon(this.field.geom.coordinates[0])
            if (turf.booleanContains(polygon, point)) {
                return true
            }
        }
        Vue.alert(`Выберите координаты внутри "№${this.field.name}" поля`, 'Ошибка', 'warning')
        return false
    }

    private getCoordinates() {
        const Longitude = this.getLastNewNotePoint[0] * 180 / 20037508.34
        const Latitude = Math.atan(Math.exp(this.getLastNewNotePoint[1] * Math.PI / 20037508.34)) * 360 / Math.PI - 90
        return Latitude.toFixed(6) + ', ' + Longitude.toFixed(6)
    }

    @Emit('reject')
    private rejectNote() {
        return {}
    }

    private setSelectedCultures(result: any) {
        if (!result.culture) return
        const formData = new FormData()
        formData.append('culture', result.culture)
        formData.append('parent_culture', result.parent_culture ? result.parent_culture : '')
        formData.append('sort', result.sort ? result.sort : '')
        formData.append('reproduction', result.reproduction ? result.reproduction : '')
        this.setLoadingDataNames('newNoteAlertSetSelectedCultures')
        httpPutFormData({
            url: `${apiUrl}/divided/passport/update/new/${this.currentRoute.params.subfieldId}/`,
            isSecureRequest: true,
            body: formData,
            onSuccess: json => {
                alert(i18n.t('messages.success.successfully_updated'))
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.reloadCurrentFarm()
                this.setLoadingDataNames('newNoteAlertSetSelectedCultures')
                this.approveNote(true)
            }
        })
    }

    private approveNote(val: any) {
        if (this.isInsideField() || val) {
            this.$router.push(`/cadastres/farm/${this.currentRoute.params.farmId}/subfields/${this.currentRoute.params.subfieldId ? this.currentRoute.params.subfieldId : this.getFieldByAllnotes.id}/noteregistration`)
            this.setSideboardIsShown(true)
            this.setAddAllNote(true)
            this.setCurrentPlaceNotesCoordination(this.getCoordinates())
        }
    }

    private show() {
        this.isShown = true
    }

    private hide() {
        this.isShown = false
        this.setIsNotesModeActive(false)
        this.setSideboardIsShown(true)
    }
}
