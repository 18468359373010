



























import { Component, Vue } from 'vue-property-decorator'
import { httpPost, httpPut } from '@/util/http'
import { apiUrl } from '@/domain/constants'
import { Mutation } from 'vuex-class'
import { MutationNames } from '@/store/types'

@Component
export default class NewFolderModal extends Vue {
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    private isShown = false
    private isUpdate = false
    private folder: any = {
        id: null,
        name: '',
        onerror: ''
    }

    private show() {
        this.isShown = true
        this.isUpdate = false
        this.folder.id = null
        this.folder.name = ''
        this.folder.onerror = ''
    }

    private createFolder() {
        if (this.checkForError()) return
        this.setLoadingDataNames('newFolderModalCreateFolder')
        httpPost({
            url: `${apiUrl}/workspace/get/folders/`,
            isSecureRequest: true,
            detailResponse: true,
            body: {
                name: this.folder.name
            },
            onSuccess: json => {
                if (json.ok) {
                    this.$alert('Группа успешно создана', 'Сохранено', 'success').then(() => {
                        this.$emit('reload')
                        this.hide()
                    })
                } else {
                    this.$alert(JSON.stringify(json.json), 'Ошибка', 'error')
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('newFolderModalCreateFolder')
            }
        })
    }

    private update(folder: any) {
        this.isShown = true
        this.isUpdate = true
        this.folder.id = folder.id
        this.folder.name = folder.name
        this.folder.onerror = ''
    }

    private updateFolder() {
        if (this.checkForError()) return
        this.setLoadingDataNames('newFolderModalUpdateFolder')
        httpPut({
            url: `${apiUrl}/workspace/folders/${this.folder.id}/`,
            isSecureRequest: true,
            detailResponse: true,
            body: {
                name: this.folder.name
            },
            onSuccess: json => {
                if (json.ok) {
                    this.$alert('Группа успешно обновлена', 'Сохранено', 'success').then(() => {
                        this.$emit('reload')
                        this.hide()
                    })
                } else {
                    this.$alert(`${json.json}`, 'Ошибка', 'error')
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('newFolderModalUpdateFolder')
            }
        })
    }

    private checkForError() {
        let counter = 0
        if (this.folder.name === '') {
            this.folder.onerror = 'Введите название'
            counter++
        }
        return counter > 0
    }

    private hide() {
        this.isShown = false
    }
}
