import NotificationsBoard from '@/views/MainPage/HomeView/Sideboards/NotificationsBoard/NotificationsView.vue'
import NotificationManage from '@/views/MainPage/HomeView/Sideboards/NotificationsBoard/NotificationManage/NotificationManage.vue'
import NotificationMessages from '@/views/MainPage/HomeView/Sideboards/NotificationsBoard/NotificationMessages/NotificationMessages.vue'

const routes = [
    {
        path: 'notifications/:info?',
        name: 'notifications',
        component: NotificationsBoard
    },
    {
        path: 'notifications/:info?/manage',
        name: 'notificationmanage',
        component: NotificationManage
    },
    {
        path: 'notifications/:info?/message/:messageId',
        name: 'notificationmessage',
        component: NotificationMessages
    }
]

export default routes