import autopark from './autopark/index'
import contacts from './contacts/index'
import dispatcher from './dispatcher/index'
import farm from './farm/index'
import fields from './fields/index'
import guide from './guide/index'
import orders from './orders/index'
import profile from './profile/index'
import projects from './projects/index'
import storage from './storage/index'
import tech_support from './tech-support/index'
import weather from './weather/index'

const workspace = {
    autopark,
    contacts,
    dispatcher,
    farm,
    fields,
    guide,
    orders,
    profile,
    projects,
    storage,
    tech_support,
    weather
}

export default workspace