















import { Component, Vue, Watch, Prop } from 'vue-property-decorator'

@Component
export default class BaseTextWithIcon extends Vue {
    @Prop() private text!: string
    @Prop({default: ''}) private img!: string
    @Prop({type: Boolean}) private active!: boolean
    @Prop({default: ''}) private strokeColor!: string
    @Prop({default: 0}) private height!: number
    @Prop({default: 0}) private width!: number
}
