import buttons from './components/buttons/index'
import map from './components/map/index'
import messages from './components/messages/index'
import tooltip from './components/tooltip/index'
import data from './data/index'
import general from './general/index'
import headers from './headers/index'

const ru = {
    buttons,
    general,
    headers,
    map,
    messages,
    data,
    tooltip
}

export default ru