






























































































































































































































































































































































































































































































































































































































import {Vue, Component, Watch} from "vue-property-decorator"
import {Getter, Mutation} from "vuex-class"
import {GetterNames, MutationNames} from "@/store/types"
import {httpDelete, httpGet} from "@/util/http"
import {apiUrl} from "@/domain/constants"
import Chart from '@/components/ChartComponent.vue'
import PlanningDetailTechnic from "@/views/MainPage/HomeView/Sideboards/FarmBoard/TechMapBoard/components/PlanningDashbroadList/PlanningDetailTechnic.vue"
import PlanningAddPreparation from "@/views/MainPage/HomeView/Sideboards/FarmBoard/TechMapBoard/components/PlanningDashbroadList/PlanningAddPreparation.vue"
import PlanningAddTechnic from "@/views/MainPage/HomeView/Sideboards/FarmBoard/TechMapBoard/components/PlanningDashbroadList/PlanningAddTechnic.vue"
import PlanningAddSeed from "@/views/MainPage/HomeView/Sideboards/FarmBoard/TechMapBoard/components/PlanningDashbroadList/PlanningAddSeed.vue"
@Component({
    components: {
        Chart,
        PlanningDetailTechnic,
        PlanningAddPreparation,
        PlanningAddTechnic,
        PlanningAddSeed
    }
})
export default class PlanningDashbroad extends Vue {
    // @Getter(GetterNames.getSidebarModel) private getSidebarModel!: any
    @Getter(GetterNames.getSidebarModel) private getSidebarModel!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any

    private colors: any = ['#2B4DFF', '#AA27E8', '#F37500', '#06BAF2', '#FFC700', '#5AF5D9', '#7D5EF8', '#FC7BE7', '#ADDE30']
    private months: any = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']

    private technology: any = null
    private tasks: any = null

    private dropdownEventsList: any = null
    private showListTechnic: boolean = true
    private taskTypeCategory: boolean = true
    private eventsList: any = []

    private mounted() {
        // console.log(this.$route.params)
        this.getTechnogolyDetail(this.$route.params.id)
    }

    private getTechnogolyDetail(id: any) {
        this.setLoadingDataNames('noteUpdateGetMembers')
        httpGet({
            url: `${apiUrl}/technology/detail/${id}/`,
            isSecureRequest: true,
            onSuccess: json => {
                this.technology = json
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('noteUpdateGetMembers')
            }
        })
    }

    private getTechnologyTaskDetail(id: any) {
        this.setLoadingDataNames('noteUpdateGetMembers')
        httpGet({
            url: `${apiUrl}/technology/task/detail/${id}/`,
            isSecureRequest: true,
            onSuccess: json => {
                this.tasks = json
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('noteUpdateGetMembers')
            }
        })
    }

    private deletePreparation(prep: any, id: any, taskId: any) {
        this.$confirm(`Вы действительно хотите удалить препарат:?`, 'Подтверждение', 'warning').then(() => {
            this.setLoadingDataNames('FieldNotesIsDeletingNote')
            httpDelete({
                url: `${apiUrl}/technology/task/${prep === 'seed' ? 'seed' : 'preparation'}/${id}/`,
                isSecureRequest: true,
                onSuccess: json => {
                    Vue.alert('', 'Успешно удалено', 'success')
                    this.getTechnologyTaskDetail(taskId)
                },
                onError: error => {
                    console.log(error)
                },
                doFinally: () => {
                    this.setLoadingDataNames('FieldNotesIsDeletingNote')
                }
            })
        })
    }

    get getNormalizeArea() {
        if (this.technology) {
            return (this.technology.technology_main_info.area / 10000).toFixed(2) || 'Нет данных'
        }
        return 'Нет данных'
    }

    get getNormalizeYield() {
        if (this.technology) {
            const area: any = (this.technology.technology_main_info.area / 10000).toFixed(2)
            return (this.technology.technology_main_info.productivity * area).toFixed(2) || 'Нет данных'
        }
        return 'Нет данных'
    }

    get getNormalizeExpenditure() {
        if (this.technology) {
            return (this.technology.technology_total_costs.total).toFixed(2) || 'Нет данных'
        }
        return 'Нет данных'
    }

    get getNormalizeCountTechnic() {
        if (this.technology) {
            return this.technology.technology_main_info.tractor_count || 'Нет данных'
        }
        return 'Нет данных'
    }

    private getNormalizePrice(price: any) {
        return Math.round(price)
    }

    private getColor(index: any, culture?: any) {
        return this.colors[index % 9]
    }

    private getNameCulture(culture: any) {
        if (culture[0] === 'seed_cost') {
            return 'Семенa'
        }
        if (culture[0] === 'fuel_cost') {
            return 'ГСМ'
        }
        if (culture[0] === 'work_cost') {
            return 'ФОТ'
        }
        if (culture[0] === 'ppp_cost') {
            return 'СРЗ'
        }
        if (culture[0] === 'fertilizer_cost') {
            return 'Удобрения'
        }
        if (culture[0] === 'additional_cost') {
            return 'Другое'
        }
        if (culture[0] === 'storage_cost') {
            return 'Склад'
        }
        if (culture[0] === 'total') {
            return 'Общие'
        }
    }

    private getDateDay(date: any) {
        const formatDay = new Date(date).getDate()
        return formatDay
    }

    private getDateMonth(date: any) {
        const formatMonth = new Date(date).getMonth()
        return this.months[formatMonth]
    }

    private getArea(area: any) {
        return (area / 10000).toFixed(2)
    }

    private getDate(date: any) {
        const day = new Date(date).getDate()
        const month = new Date(date).getMonth()
        return `${day} ${this.months[month]}`
    }

    private getNormalizeDate(date: any) {
        const str = date.split(' ').slice(0, -1).reverse().join()
        return str.split('-').reverse().join('.')
    }

    private getTechnologyTotalCost(val: any) {
        val.pop()
        return val
    }

    private getTotalCountPrepation(prepations: any) {
        return prepations.reduce((acc: any, item: any) => {
           return acc + item.cost
        }, 0)
    }

    private chartData() {
        return this.getTechnologyTotalCost(Object.values(this.technology.technology_total_costs))
        // const checkOnZero = Object.values(this.technology.technology_total_costs).every(el => el === 0)
        // if (checkOnZero) {
        //     return Object.values(this.technology.technology_total_costs).map((x: any) => {
        //         return 100 / Object.values(this.technology.technology_total_costs).length
        //     })
        // } else {
        //     return this.getTechnologyTotalCost(Object.values(this.technology.technology_total_costs))
        // }
    }

    private chartEvent(value: any) {
        const eventsList: any = []
        const currentPreparate: any = Object.entries(this.technology.technology_total_costs).find((x: any) => Math.round(x[1]) === Math.round(value)) // x[1] === value
        this.technology.technology_task_info.forEach((task: any) => {
            Object.entries(task).forEach((el: any) => {
                if (el[0] === currentPreparate[0]) {
                    eventsList.push(el)
                }
            })
        })
        this.chartEventCulture(eventsList)
    }

    private chartEventCulture(eventList: any) {
        this.eventsList = []
        this.technology.technology_task_info.forEach((x: any, index: any) => {
            this.eventsList.push({task_name: x.task_name, price: eventList[index][1]})
        })
    }

    private dropDownListContent(id: any, index: any) {
        if (this.dropdownEventsList === null) {
            this.getTechnologyTaskDetail(id)
            this.dropdownEventsList = index
            return
        } else this.dropdownEventsList = null
    }

    private formatExpences(expense: number) {
        return expense ? expense.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + '' : '0'
    }

    // N
    private isInSeason(start: any, end: any, season: any) {
        const startDate = new Date(start)
        const endDate = new Date(end)

        const startSeason = Math.ceil((startDate.getMonth() + 1) / 3)
        const endSeason = Math.ceil((endDate.getMonth() + 1) / 3)

        if (endSeason >= season && startSeason <= season) {
            return true
        }
    }

    private getMonth(date: any, season: any) {
        const newDate = new Date(date)
        if (Math.ceil((newDate.getMonth() + 1) / 3) !== season) return 0
        return this.getMarginLeft(Math.round((newDate.getMonth() + 1) % 3), newDate.getDate())
    }

    private getMarginLeft(lineMonth: any, monthDay: any) {
        let percent = lineMonth === 0 ? 33.33 * 2 : 33.33 * (lineMonth - 1)

        const additionalRatio = (monthDay / 28 > 1) ? 1 : monthDay / 28
        percent = additionalRatio * 33.33 + percent
        return percent
    }

    private getWidth(startDate: any, endDate: any, season: any) {
        const startDay = new Date(startDate)
        const endDay = new Date(endDate)
        const startSeason = Math.ceil((startDay.getMonth() + 1) / 3)
        const endSeason = Math.ceil((endDay.getMonth() + 1) / 3)

        if (endSeason !== season) return 100 - this.getMonth(startDate, season)
        if (endSeason === startSeason) return this.getMarginLeft(Math.round((endDay.getMonth() + 1) % 3), endDay.getDate()) - this.getMarginLeft(Math.round((startDay.getMonth() + 1) % 3), startDay.getDate())
        return this.getMarginLeft(Math.round((endDay.getMonth() + 1) % 3), endDay.getDate())
    }
}
