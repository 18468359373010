




































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import { GetterNames, MutationNames } from '@/store/types'
import { Route } from 'vue-router'
import { httpGet, httpPost } from '@/util/http'
import { apiUrl, baseUrl } from '@/domain/constants'
import i18n from '@/i18n'
import { egisticLogo, pdfWaterMark } from '@/data/pdfImageData'
import { makePDF, PdfActions } from '@/utils/services'
@Component
export default class CarReportView extends Vue {
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: Route
    @Getter(GetterNames.getSidebarModel) private getSidebarModel!: any
    @Getter(GetterNames.getPermissions) private getPermissions!: any
    @Getter(GetterNames.getLoadingDataNames) private getLoadingDataNames!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    private egisticLogo: any = egisticLogo
    private pdfWaterMark: any = pdfWaterMark
    private tractor: any = null
    private startDate: any = ''
    private endDate: any = ''
    private dataloading: any = false
    private report: any = null

    private getHours() {
        if (this.report && this.report.working_minutes) {
            const hours = Math.floor(this.report.working_minutes / 60)
            const minutes = Math.round(this.report.working_minutes - 60 * hours)
            return `${hours} часов ${minutes} минуты`
        } else return '0 часов'
    }

    private getDistance() {
        if (this.report && this.report.total_distance) {
            const km = Math.floor(this.report.total_distance / 1000)
            const meters = Math.round(this.report.total_distance - 1000 * km)
            return `${km} км ${meters} метров`
        } else return '0 км'
    }

    private getMaxSpeed() {
        if (this.report && this.report.max_speed) {
            return `${this.report.max_speed} км/ч`
        } else return '0 км/ч'
    }

    private normalizeTime(time: any) {
        if (time) {
            return `${time.substring(8, 10)}.${time.substring(5, 7)}.${time.substring(0, 4)} ${time.substring(11)}`
        } else return ''
    }

    private normalizeDate(date: any) {
        const d = new Date(date)
        return d.toLocaleString('ru', { hour12: false, day: 'numeric', month: 'numeric', year: 'numeric',  hour: "numeric", minute: "numeric", second: 'numeric'})
    }

    private normalizeDuration(duration: any) {
        if (duration > 60) {
            const hours = Math.floor(duration / 60)
            const minutes = Math.round(duration - 60 * hours)
            return `${hours} часов ${minutes} минуты`
        } else {
            return `${Math.round(duration) } минуты`
        }
    }

    private mounted() {
        this.fetchTractor()
    }

    private fetchTractor() {
        this.setLoadingDataNames('carReportFetchTractor')
        httpGet({
            url:  `${apiUrl}/yields/tractor/${this.currentRoute.params.carId}/`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                if (json.ok) {
                    this.tractor = json.json
                }
            },
            onError: error => {
            console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('carReportFetchTractor')
            }
        })
    }

    private fetchReport() {
        this.startDate = (this.$refs.startDateModel as any).getDate()
        this.endDate = (this.$refs.endDateModel as any).getDate()
        if (this.startDate && this.endDate) {
            this.dataloading = true
            httpPost({
                url:  `${apiUrl}/yields/tractor/report/`,
                isSecureRequest: true,
                detailResponse: true,
                body: {
                    tractor: Number(this.currentRoute.params.carId),
                    start_time: `${this.startDate} 00:00:00`,
                    end_time: `${this.endDate} 23:59:59`
                },
                onSuccess: json => {
                    if (json.ok) {
                        this.report = json.json
                    }
                },
                onError: error => {
                    console.log(error)
                },
                doFinally: () => {
                    this.dataloading = false
                }
            })
        }
    }

    private printPdf() {
        if (!this.report) return
        const startDate = `${this.report.start_time.substring(8, 10)}.${this.report.start_time.substring(5, 7)}.${this.report.start_time.substring(0, 4)}`
        const endDate = `${this.report.end_time.substring(8, 10)}.${this.report.end_time.substring(5, 7)}.${this.report.end_time.substring(0, 4)}`
        const docDefinition = {
            pageSize: {
                width: 842,
                height: 595
            },
            pageMargins: [16, 16, 16, 16],
            background: [
                {
                    image: this.pdfWaterMark,
                    margin: [400, 0, 0, 0]
                }
            ],
            content: [
                {
                    image: this.egisticLogo,
                    margin: [359, 24, 0, 0],
                    width: 91.14,
                    height: 30
                },
                {
                    text: `Отчёт по работе техники за ${startDate} - ${endDate}`,
                    fontSize: 16,
                    bold: true,
                    margin: [0, 24, 0, 0],
                    alignment: 'center'
                },

                {
                    layout: 'lightHorizontalLines',
                    margin: [24, 24, 24, 0],
                    table: {
                        body: [
                            [{text: this.tractor.class_name, bold: true, fontSize: 16, margin: [24, 0, 0, 0]}, '', '', '', '', ''],
                            [{text: i18n.t('headers.workspace.autopark.add_machine.model'), fontSize: 14, margin: [24, 12, 0, 0], color: '#878882'},
                            {text: this.tractor.model_name, fontSize: 14, margin: [24, 12, 12, 0]},
                            {text: i18n.t('headers.workspace.autopark.add_machine.state_num'), fontSize: 14, margin: [24, 12, 0, 0], color: '#878882'},
                            {text: this.tractor.uid, fontSize: 14, margin: [24, 12, 12, 0]},
                            {text: i18n.t('headers.workspace.autopark.add_machine.category.title'), fontSize: 14, margin: [24, 12, 0, 0], color: '#878882'},
                            {text: this.tractor.category_name, fontSize: 14, margin: [24, 12, 12, 0]}]
                        ]
                    }
                },
                {
                    layout: 'noBorders',
                    margin: [24, 24, 24, 0],
                    table: {
                        body: [
                            [
                                {
                                    text: `Рабочие часы: `,
                                    bold: true,
                                    fontSize: 14,
                                    color: '#1c1c1c'
                                },
                                {
                                    text: this.getHours(),
                                    fontSize: 14,
                                    color: '#1c1c1c'
                                }
                            ],
                            [
                                {
                                    text: `Пройденное расстояние: `,
                                    bold: true,
                                    fontSize: 14,
                                    color: '#1c1c1c'
                                },
                                {
                                    text: this.getDistance(),
                                    fontSize: 14,
                                    color: '#1c1c1c'
                                }
                            ],
                            [
                                {
                                    text: `MAX скорость: `,
                                    bold: true,
                                    fontSize: 14,
                                    color: '#1c1c1c'
                                },
                                {
                                    text: this.getMaxSpeed(),
                                    fontSize: 14,
                                    color: '#1c1c1c'
                                }
                            ]
                        ]
                    }
                },
                {
                    text: `Остановки техники`,
                    fontSize: 16,
                    bold: true,
                    margin: [0, 24, 0, 0],
                    alignment: 'center'
                },
                {
                    margin: [24, 12, 24, 0],
                    table: {
                        headerRows: 1,
                        widths: ['*', '*', '*'],
                        body: this.getStopsBody()
                    }
                },
                {
                    text: `Потери связи`,
                    fontSize: 16,
                    bold: true,
                    margin: [0, 24, 0, 0],
                    alignment: 'center'
                },
                {
                    margin: [24, 12, 24, 0],
                    table: {
                        headerRows: 1,
                        widths: ['*', '*', '*'],
                        body: this.getNoSignalBody()
                    }
                }
            ]
        }
        makePDF(PdfActions.PRINT, docDefinition)
    }

    private getStopsBody() {
        const body = []
        body.push([
            {text: 'Начало', fontSize: 12, bold: true, color: '#1c1c1c'},
            {text: 'Конец', fontSize: 12, bold: true, color: '#1c1c1c'},
            {text: 'Длительность', fontSize: 12, bold: true, color: '#1c1c1c'}
        ])
        for (const stop of this.report.stops) {
            body.push([
                {text: this.normalizeTime(stop.start_time), fontSize: 12, color: '#1c1c1c'},
                {text: this.normalizeTime(stop.end_time), fontSize: 12, color: '#1c1c1c'},
                {text: this.normalizeDuration(stop.interval_in_minuts), fontSize: 12, color: '#1c1c1c'}
            ])
        }
        // if (this.report.stops.length === 0) {
        //     body.push([
        //         {text: 'Нет данных', fontSize: 12, color: '#1c1c1c'}
        //     ])
        // }
        return body
    }

    private getNoSignalBody() {
        const body = []
        body.push([
            {text: 'Начало', fontSize: 12, bold: true, color: '#1c1c1c'},
            {text: 'Конец', fontSize: 12, bold: true, color: '#1c1c1c'},
            {text: 'Длительность', fontSize: 12, bold: true, color: '#1c1c1c'}
        ])
        for (const stop of this.report.no_signals) {
            body.push([
                {text: this.normalizeTime(stop.start_time), fontSize: 12, color: '#1c1c1c'},
                {text: this.normalizeTime(stop.end_time), fontSize: 12, color: '#1c1c1c'},
                {text: this.normalizeDuration(stop.interval_in_minuts), fontSize: 12, color: '#1c1c1c'}
            ])
        }
        // if (this.report.no_signals.length === 0) {
        //     body.push([
        //         {text: 'Нет данных', fontSize: 12, color: '#1c1c1c'}
        //     ])
        // }
        return body
    }

}
