









import { Component, Vue, Prop} from 'vue-property-decorator'
@Component
export default class BaseButton extends Vue {
    @Prop({type: Boolean}) private secondary!: boolean
    @Prop({type: Boolean}) private loading!: boolean
    @Prop({type: Boolean}) private white!: boolean
    @Prop({type: Boolean}) private deleted!: boolean
}
