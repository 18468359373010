import { IState } from './'

export type State = IState

export const Action = {
    fetchPermissions: 'fetchPermissions',
    setPermissionList: 'setPermissionList'
}

export const Mutation = {
}

export const Getter = {
    getPermissions: 'getPermissions'
}