























import { Component, Vue, Watch } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import {GetterNames, MutationNames } from '@/store/types'
import WeatherPanel from './WeatherPanel.vue'
import CadastreEditPanel from './CadastreEditPanel.vue'
import CadastreInfoPanel from './CadastreInfoPanel.vue'
import Telematics from './Telematics/Telematics.vue'
import CulturesPanel from '@/views/MainPage/HomeView/Panels/CulturesPanel.vue'
import FieldPanel from '@/views/MainPage/HomeView/Panels/FieldPanel.vue'
@Component({
    components: {
        WeatherPanel,
        CadastreEditPanel,
        Telematics,
        CadastreInfoPanel,
        CulturesPanel,
        FieldPanel
    }
})
export default class Panels extends Vue {
    @Getter(GetterNames.getFarmFromRoute) private getFarmFromRoute!: any
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: any
    @Getter(GetterNames.getFieldRequestComparisonMode) private getFieldRequestComparisonMode !: boolean
    @Getter(GetterNames.getTelematicsInfo) private getTelematicsInfo!: any
    @Mutation(MutationNames.setChroniclePanel) private setChroniclePanel!: any

    get isWeatherPanelExist() {
        return (this.getFarmFromRoute && !this.getFieldRequestComparisonMode)
    }

    get isWeatherPanelVisible() {
        if (this.currentRoute.name === 'fieldtelematics' || this.currentRoute.name === 'farmtelematics') {
            return !this.isTelematicsControlPanelVisible
        } else return true
    }

    get isTelematicsControlPanelVisible() {
        const result = this.getTelematicsInfo.tractorsData.find((x: any) => x.IS_LIVE || x.IS_HISTORY)
        return result ? true : false
    }

    @Watch('isWeatherPanelVisible')
    private onchangeWeatherPanel(val: any, old: any) {
        if (!val && val !== old) {
            this.setChroniclePanel({
                status: false,
                indicator: 'temperature',
                selected: 0,
                layer: 0
            })
        }
    }
}
