export default class NewTractor {
    public name: string
    public model: string
    public uid: string
    public category: string
    public id: number
    public checked: boolean

    constructor(name: string, id: number, model: string, uid: string, category: string) {
        this.name = name
        this.uid = uid
        this.id = id
        this.checked = false
        this.model = model
        this.category = category
    }
}