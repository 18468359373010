import Cookies from 'js-cookie'
const LAST_ORIGIN_HEADER = 'lastoriginheader'

export function openCrossOriginWebSite(targetDomain: string, path?: string) {
    let url = ''
    if (targetDomain === 'landing') {
      url = landingUrl()
    } else if (targetDomain === 'login') {
      url = loginUrl()
    } else if (targetDomain === 'farm') {
      url = farmUrl()
    } else if (targetDomain === 'consulting') {
      url = consultingUrl()
    } else if (targetDomain === 'market') {
      url = marketUrl()
    } else if (targetDomain === 'rabota') {
      url = rabotaUrl()
    } else if (targetDomain === 'nuxt') {
      url = geoUrl()
    } else if (targetDomain === 'micro') {
      url = microUrl()
    }

    if (url) {
      Cookies.set(LAST_ORIGIN_HEADER, targetDomain, { expires: 1800000, domain: mainDomain() })
      window.open(`${url}/${path || ''}`, '_self')
    }
}

export function landingUrl() {
    return getModuleDomainName()
  }

export function loginUrl() {
    return getModuleDomainName('login')
  }

export function farmUrl() {
    return getModuleDomainName('farm')
  }

export function consultingUrl() {
    return getModuleDomainName('consulting')
  }

export function marketUrl() {
    return getModuleDomainName('market')
  }

export function rabotaUrl() {
    return getModuleDomainName('rabota')
  }

export function geoUrl() {
    return getModuleDomainName('nuxt')
  }

export function microUrl() {
    return getModuleDomainName('micro')
  }

function getModuleDomainName(subdomain?: string) {
    const formattedSubdomain = subdomain ? `${subdomain}.` : ''
    if (domain.includes('openlayers')) {
      return `https://${formattedSubdomain}openlayers.kz`
    } else if (domain.includes('demoegistic')) {
      return `https://${formattedSubdomain}demoegistic.kz`
    } else if (domain.includes('egistic')) {
      return `https://${formattedSubdomain}egistic.kz`
    } else {
    return `https://${formattedSubdomain}egistic.kz`
    }
  }

function getCurrentDomain() {
    const hostname = window.location.hostname
    return hostname
  }
const domain = getCurrentDomain()

export function mainDomain() {
    if (domain.includes('localhost')) {
      return 'localhost'
    } else if (domain.includes('openlayers')) {
      return '.openlayers.kz'
    } else if (domain.includes('demoegistic')) {
      return '.demoegistic.kz'
    } else if (domain.includes('egistic')) {
      return '.egistic.kz'
    } else return '.egistic.kz'
}