



























import { Component, Vue } from 'vue-property-decorator'
import { httpPutFormData } from '@/util/http'
import { apiUrl } from '@/domain/constants'
import {Action, Getter, Mutation} from 'vuex-class'
import {ActionNames, GetterNames, MutationNames} from '@/store/types'
import Farm from '@/models/Farm'

@Component
export default class SubfieldRenameModel extends Vue {
  @Getter(GetterNames.getFarmFromRoute) private getFarmFromRoute!: Farm
  @Action(ActionNames.updateCurrentField) private updateCurrentField!: any
  @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
  private isShown = false
  private subfieldId = -1
  private name = {
    seletedName: '',
    onerror: ''
  }

  private show(subfield: any) {
    this.isShown = true
    this.subfieldId = subfield.id
    this.name.seletedName = this.getFarmFromRoute.fields.find((field: any) => field.id === this.subfieldId).name
  }

  private finishRegister() {
    if (!this.name.seletedName) {
      this.name.onerror = `${this.$i18n.t('messages.errors.field_name')}`
      return
    }
    const formData = new FormData()
    formData.append('name', this.name.seletedName)

    this.setLoadingDataNames('registerModalFinishRegister')
    httpPutFormData({
      url: `${apiUrl}/divided/passport/update/new/${this.subfieldId}/`,
      isSecureRequest: true,
      detailResponse: true,
      body: formData,
      onSuccess: json => {
        if (json.ok) {
          this.$alert(`${this.$i18n.t('messages.success.successfully_updated')}`, `${this.$i18n.t('messages.success.changes_saved')}`, 'success')
              .then(() => {
                this.getFarmFromRoute.fields.find((field: any) => field.id === this.subfieldId).name = json.json.name
                this.$emit('reload')
                this.hide()
              })
        } else {
          this.$alert(JSON.stringify(json.json), 'Ошибка', 'error')
        }
      },
      onError: error => {
        console.log(error)
      },
      doFinally: () => {
        this.setLoadingDataNames('registerModalFinishRegister')
      }
    })
  }

  private hide() {
    this.isShown = false
  }
}
