









































































































import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { httpGet } from '@/util/http'
import { apiUrl, baseUrl, apiUrl3 } from '@/domain/constants'
import { GetterNames, MutationNames } from '@/store/types'
import { Getter, Mutation } from 'vuex-class'
import TractorTelematics from '@/models/TractorTelematics'
import { egisticLogo, pdfWaterMark } from '@/data/pdfImageData'
import { makePDF, PdfActions } from '@/utils/services'
import NewEventModal from '@/views/MainPage/HomeView/Sideboards/FarmBoard/TechMapBoard/components/NewEventModal.vue'
import CultureTaskModal from '@/views/MainPage/HomeView/Sideboards/FarmBoard/TechMapBoard/components/CultureTaskModal.vue'
@Component({
    components: {
        NewEventModal,
        CultureTaskModal
    }
})
export default class EventView extends Vue {
    @Prop({default: -1}) private eventID!: number
    @Prop({default: -1}) private fieldId!: number
    @Prop() private newTractors!: any
    @Prop({default: ''}) private eventName!: any
    @Prop({default: false}) private taskEvent!: any
    @Getter(GetterNames.getTelematicsInfo) private getTelematicsInfo!: any
    @Mutation(MutationNames.setTelematicsInfo) private setTelematicsInfo!: any
    @Mutation(MutationNames.setIsDataLoading) private setIsDataLoading!: any

    private colors: any = ['#2B4DFF', '#AA27E8', '#F37500', '#06BAF2', '#FFC700', '#5AF5D9', '#7D5EF8', '#FC7BE7', '#ADDE30']
    private coverageColors: any = ['rgba(43,77,255, 0.25)',  'rgba(170,39,232, 0.25)', 'rgba(243,117,0, 0.25)', 'rgba(6,186,242, 0.25)',
      'rgba(255,199,0, 0.25)', 'rgba(90,245,217, 0.25)', 'rgba(125,94,248, 0.25)', 'rgba(252,123,231, 0.25)', 'rgba(173,222,48, 0.25)']
    private egisticLogo: any = egisticLogo
    private pdfWaterMark: any = pdfWaterMark
    private dataLoading = false
    private selectTractorOpen = false
    private fieldEvent: any = {
      tractors_read: []
    }
    private tractors: any = {
        origin: [],
        converted: [],
        colors: [],
        selected: null,
        selected_name: ''
    }

    get event() {
        // console.log(this.getTelematicsInfo.fieldEvent.event)
        return this.getTelematicsInfo.fieldEvent.event
    }

    private getEventDatesList() {
      const days: any = []
      const startDate = new Date(this.getTelematicsInfo.fieldEvent.event.start_date)
      const endDate = new Date(this.getTelematicsInfo.fieldEvent.event.end_date)
      if (startDate && endDate) {
        for (startDate; startDate <= endDate; startDate.setDate(startDate.getDate() + 1)) {
          days.push(startDate.toLocaleString('ru', {day: 'numeric', month: 'long'}) + ', ' + startDate.toLocaleString('Ru', {weekday: 'short'}) )
        }
      }
      return days
    }

    get reportType() {
        return this.getTelematicsInfo.fieldEvent.reportType
    }

    get flaws() {
        return this.getTelematicsInfo.fieldEvent.flaws
    }

    get overlaps() {
        return this.getTelematicsInfo.fieldEvent.overlaps
    }

    private toggleFlaws() {
        this.setTelematicsInfo({
            fieldEvent: {
                flaws: !this.getTelematicsInfo.fieldEvent.flaws
            }
        })
    }

    private toggleOverlaps() {
        this.setTelematicsInfo({
            fieldEvent: {
                overlaps: !this.getTelematicsInfo.fieldEvent.overlaps
            }
        })
    }

    private getPreparationNames(preparations: any) {
        return preparations.map((x: any) => x.preparation).join(', ')
    }

    private onSelectTractor(item: any) {
        this.selectTractorOpen = false
        const tractor = this.event.tractor_trailer.find((x: any) => `${x.tractor_name} (${x.tractor_uid})` === item)
        if (tractor) {
            this.tractors.selected = tractor
            this.tractors.selected_name = item
            this.setTelematicsInfo({
                fieldEvent: {
                    tractor: this.tractors.selected
                }
            })
            const tractorData = this.getTelematicsInfo.tractorsData.find((x: any) => x.tractorID === tractor.tractor)
            if (tractorData) {
              this.setTelematicsInfo({
                    autoTracking: {
                        mainTractorID: tractorData.tractorID
                    }
                })
              this.getTelematicsInfo.tractorsData.forEach((x: any, index: number) => {
                    this.getTelematicsInfo.tractorsData[index].IS_VISIBLE = x.tractorID === tractorData.tractorID
                })
            }
        }
    }

    private mounted() {
        if (this.eventID !== -1) {
            this.fetchTaskEvents()

          // if (this.taskEvent) {
          // } else {
          //   this.fetchEvents()
          // }
        }
        if (this.newTractors && this.newTractors.length > 0) {
          this.fieldEvent.tractors_read = this.newTractors
        }
    }

    private  fetchTaskEvents() {
      this.dataLoading = true
      httpGet({
        url:  `${apiUrl}/technology/fact/tasks/fields/${this.eventID}/`,
        isSecureRequest: true,
        detailResponse: true,
        onSuccess: json => {
          if (json.ok) {
            this.setTelematicsInfo({
              fieldEvent: {
                event: json.json
              }
            })
            this.tractors.origin = json.json.tractor_params
            this.tractors.converted = json.json.tractor_params
                .map((x: any) => `${x.name} (${x.uid})`)

            const tractor = json.json.tractor_params.length > 0 ? json.json.tractor_params[0] : null
            if (tractor) {
              this.tractors.selected = tractor
              this.setTelematicsInfo({
                fieldEvent: {
                  tractor: this.tractors.selected
                }
              })
              this.tractors.selected_name = `${tractor.name} (${tractor.id})`
            } else {
              this.tractors.selected = null
              this.tractors.selected_name = ''
            }
            // this.startLoadHistoryData(json.json.task_tractors)
            this.getHistoryData()
          }
        },
        onError: error => {
          console.log(error)
        },
        doFinally: () => {
          this.dataLoading = false
        }
      })
    }

    private fetchEvents() {
        const TelematicsSocketModel = this.$refs.telematicsSocketModel as any
        if (TelematicsSocketModel) {
            TelematicsSocketModel.clearSocket()
        }
        this.dataLoading = true
        httpGet({
            url:  `${apiUrl}/telematics/stat/event/${this.eventID}/`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                if (json.ok) {
                    this.setTelematicsInfo({
                        fieldEvent: {
                            event: json.json
                        }
                    })
                    this.tractors.origin = json.json.tractor_trailer
                    this.tractors.converted = json.json.tractor_trailer
                        .filter((x: any) => x.statistics && x.statistics.processed_area > 0)
                        .map((x: any) => `${x.tractor_name} (${x.tractor_uid})`)

                    const tractor = json.json.tractor_trailer.length > 0 ? json.json.tractor_trailer[0] : null
                    if (tractor) {
                        this.tractors.selected = tractor
                        this.setTelematicsInfo({
                            fieldEvent: {
                                tractor: this.tractors.selected
                            }
                        })
                        this.tractors.selected_name = `${tractor.tractor_name} (${tractor.tractor_uid})`
                    } else {
                        this.tractors.selected = null
                        this.tractors.selected_name = ''
                    }
                    this.getHistoryData()
                    // this.startLoadHistoryData(json.json.tractor_trailer)
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.dataLoading = false
            }
        })
    }

    private getHistoryData() {
        this.setIsDataLoading(true)
        httpGet({
            url:  `${apiUrl}/technology/fact/tasks/fields/${this.eventID}/geom/`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                if (json.ok) {
                    this.startLoadHistoryData(json.json)
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setIsDataLoading(false)
            }
        })
    }

    private startLoadHistoryData(tractors: any) {
          this.tractors.colors = []
          this.tractors.selected_name = 'Техника не выбрана'
          const lastTime = ''
          const tractorsTelematics: TractorTelematics[] = []
          const tractorPreviewData: any = []
          tractors.forEach((tractorData: any, i: number) => {
              const x = this.tractors.origin.find((y: any) => y.id === tractorData.tractor_id)
              tractorsTelematics.push(new TractorTelematics({
                  index: i,
                  folderID: 1,
                  complex: {
                      tractor: {
                          name: x.name
                      },
                      full_name: ''
                  },
                  tractorID: x.task_tractor_id,
                  tractorIMEI: x.imei,
                  tractorUID: x.uid,
                  FUEL_DATA: x.fuel_data ? x.fuel_data  : [],
                  SOCKET_STATUS: 0,
                  MAX_CAPACITY: x.capacity ? x.capacity : 0,
                  MIN_SPEED: x.min_speed ? x.min_speed : 0,
                  MAX_SPEED: x.max_speed ? x.max_speed : 999,
                  coverage: x.coverage ? x.coverage : 0,
                  tractorImage: x.tractor_image ? `${baseUrl}${x.tractor_image}` : '',
                  historyStartDate: (this.event.start_date && this.event.end_date) ? `${this.event.start_date} 00:00:00` : '',
                  historyEndDate: (this.event.start_date && this.event.end_date) ? `${this.event.end_date} 23:59:59` : '',
                  hasTrailer: false
              }))
              // if (x.statistics && x.statistics.processed_area) {
              //   this.tractors.colors.push(this.colors[i % 9])
              // }
              tractorsTelematics[i].roadColor = this.colors[i % 9]
              tractorsTelematics[i].coverageColor = this.coverageColors[i % 9]
              tractorsTelematics[i].IS_VISIBLE = false
              tractorsTelematics[i].IS_HISTORY = true

              this.setTelematicsInfo({
                tractorsData: tractorsTelematics,
                autoTracking: {
                  mainTractorID: x.tractorID,
                  tracking: true
                },
                tracktime: this.event.end_date ? Date.parse(this.event.end_date + ' 23:59:59') : 0
              })
              const previewData = {
                  coverageColor: this.colors[i % 9],
                  data: tractorData.geom ? tractorData.geom : tractorData.line
              }
              tractorPreviewData.push(previewData)
          })
          this.setTelematicsInfo({
              tractorsHistoryPreview: tractorPreviewData
          })
    }

    private toggleReportType(type: any) {
        this.setTelematicsInfo({
            fieldEvent: {
                reportType: type
            }
        })
        const tractorData = this.getTelematicsInfo.tractorsData.find((x: any) =>
            x.tractorID === this.getTelematicsInfo.autoTracking.mainTractorID)
        if (tractorData) {
            this.getTelematicsInfo.tractorsData.forEach((x: any, index: number) => {
                if (x.tractorID === tractorData.tractorID || type === 1) {
                    this.getTelematicsInfo.tractorsData[index].IS_VISIBLE = true
                } else {
                    this.getTelematicsInfo.tractorsData[index].IS_VISIBLE = false
                }
            })
        }
    }

    private printPdf() {
        if (this.event) {
            const startDate = `${this.event.start_date.substring(8, 10)}.${this.event.start_date.substring(5, 7)}.${this.event.start_date.substring(0, 4)}`
            const endDate = `${this.event.end_date.substring(8, 10)}.${this.event.end_date.substring(5, 7)}.${this.event.end_date.substring(0, 4)}`
            const docDefinition = {
                pageSize: {
                    width: 595,
                    height: 842
                },
                pageMargins: [16, 16, 16, 16],
                background: [
                    {
                        image: this.pdfWaterMark,
                        margin: [150, 0, 0, 0]
                    }
                ],
                content: [
                    {
                        image: this.egisticLogo,
                        margin: [0, 24, 0, 0],
                        alignment: 'center',
                        width: 91.14,
                        height: 30
                    },
                    {
                        text: `Отчет по мероприятию`,
                        fontSize: 16,
                        bold: true,
                        margin: [0, 24, 0, 0],
                        alignment: 'center'
                    },
                    {
                        margin: [20, 24, 20, 0],
                        table: {
                            widths: ['*', '*'],
                            body: [
                                [
                                    {text: 'Мероприятие', bold: true, fontSize: 10, color: '#1c1c1c', margin: [12, 8, 8, 8]},
                                    {text: this.event.task_name, fontSize: 10, color: '#1c1c1c', margin: [12, 8, 8, 8]}
                                ],
                                [
                                    {text: 'Период', bold: true, fontSize: 10, color: '#1c1c1c', margin: [12, 8, 8, 8]},
                                    {text: `${startDate} - ${endDate}`, fontSize: 10, color: '#1c1c1c', margin: [12, 8, 8, 8]}
                                ],
                                [
                                    {text: 'План на обработку гектаров', bold: true, fontSize: 10, color: '#1c1c1c', margin: [12, 8, 8, 8]},
                                    {text: `${this.event.general_area} га`, fontSize: 10, color: '#1c1c1c', margin: [12, 8, 8, 8]}
                                ],
                                [
                                    {text: 'Площадь огрехов', bold: true, fontSize: 10, color: '#1c1c1c', margin: [12, 8, 8, 8]},
                                    {text: `${this.event.non_covered_area} га`, fontSize: 10, color: '#1c1c1c', margin: [12, 8, 8, 8]}
                                ]
                            ]
                        }
                    },
                    ...this.getTractorsData()
                ]
            }
            makePDF(PdfActions.PRINT, docDefinition)
        }
    }

    private getTractorsData() {
        const data = []
        data.push({
            text: 'Производительность техники',
            margin: [0, 24, 0, 0],
            alignment: 'center',
            fontSize: 16,
            bold: true
        })
        for (const tractor of this.tractors.origin) {
            const tractorData = this.getTelematicsInfo.tractorsData.find((x: any) => x.tractorID === tractor.tractor)
            if (tractorData) {
                let stopData = ''
                let nosignalData = ''
                for (const stop of tractorData.stopData) {
                    stopData += `${this.normalizeDate(stop.start_time)} - ${this.normalizeDate(stop.end_time)} (${this.normalizeDuration(stop.interval)})\n`
                }
                for (const nosignal of tractorData.nosignalData) {
                    nosignalData += `${this.normalizeDate(nosignal.start_date)} - ${this.normalizeDate(nosignal.end_date)} (${this.normalizeDuration(nosignal.interval)})\n`
                }
                data.push({
                    layout: 'noBorders',
                    margin: [24, 24, 24, 0],
                    table: {
                        widths: ['auto', '*', 'auto', '*'],
                        body: [
                            [
                                {text: 'Техника:', bold: true, fontSize: 12, color: '#1c1c1c'},
                                {text: `${tractor ? tractor.tractor_name : 'не выбран'}`, fontSize: 12, color: '#1c1c1c'},
                                {text: `Госномер:`, bold: true, fontSize: 12, color: '#1c1c1c'},
                                {text: tractor.tractor_uid, fontSize: 12, color: '#1c1c1c'}
                            ],
                            [
                                {text: 'Агрегат:', bold: true, fontSize: 12, color: '#1c1c1c'},
                                {text: `${tractor.trailer_name ? tractor.trailer_name : 'не выбран'}`, fontSize: 12, color: '#1c1c1c'},
                                {text: 'Водитель:', bold: true, fontSize: 12, color: '#1c1c1c'},
                                {text: `${tractor.statistics.driver ? tractor.statistics.driver : 'не выбран'}`, fontSize: 12, color: '#1c1c1c'}
                            ]
                        ]
                    }
                })
                data.push({
                    margin: [20, 24, 20, 0],
                    table: {
                        widths: ['*', '*'],
                        body: [
                            [
                                {text: 'Реальный факт обработанной площади', bold: true, fontSize: 10, color: '#1c1c1c', margin: [8, 4, 4, 4]},
                                {text: tractor.statistics.processed_area ? `${tractor.statistics.processed_area} га` : 'Нет данных', fontSize: 10, color: '#1c1c1c', margin: [8, 4, 4, 4]}
                            ],
                            [
                                {text: 'Средний расход топлива на гектар', bold: true, fontSize: 10, color: '#1c1c1c', margin: [8, 4, 4, 4]},
                                {text: Number(tractor.statistics.fuel_cost_per_ga) ? `${tractor.statistics.fuel_cost_per_ga.toFixed(2)} л/га` : 'Нет данных', fontSize: 10, color: '#1c1c1c', margin: [8, 4, 4, 4]}
                            ],
                            [
                                {text: 'Средний расход топлива на моточас', bold: true, fontSize: 10, color: '#1c1c1c', margin: [8, 4, 4, 4]},
                                {text: Number(tractor.statistics.fuel_cost_per_time) ? `${tractor.statistics.fuel_cost_per_time.toFixed(2)} л/км` : 'Нет данных', fontSize: 10, color: '#1c1c1c', margin: [8, 4, 4, 4]}
                            ],
                            [
                                {text: 'Выработка', bold: true, fontSize: 10, color: '#1c1c1c', margin: [8, 4, 4, 4]},
                                {text: tractor.statistics.working_hours ? `${tractor.statistics.working_hours} час` : 'Нет данных', fontSize: 10, color: '#1c1c1c', margin: [8, 4, 4, 4]}
                            ],
                            [
                                {text: 'Остановки техники', bold: true, fontSize: 10, color: '#1c1c1c', margin: [8, 4, 4, 4]},
                                {text: stopData, fontSize: 10, color: '#1c1c1c', margin: [8, 4, 4, 4]}
                            ],
                            [
                                {text: 'Потеря сигнала', bold: true, fontSize: 10, color: '#1c1c1c', margin: [8, 4, 4, 4]},
                                {text: nosignalData, fontSize: 10, color: '#1c1c1c', margin: [8, 4, 4, 4]}
                            ]
                        ]
                    }
                })
            }

        }
        return data
    }

    private normalizeDate(date: any) {
        const d = new Date(date)
        return d.toLocaleString('ru', { hour12: false, day: 'numeric', month: 'short',  hour: "numeric", minute: "numeric", second: 'numeric'})
    }

    private normalizeDuration(duration: any) {
        if (duration > 60) {
            const hours = Math.floor(duration / 60)
            const minutes = Math.round(duration - 60 * hours)
            return `${hours} часов ${minutes} минуты`
        } else {
            return `${Math.round(duration) } минуты`
        }
    }
}
