







import { Component, Vue, Watch } from 'vue-property-decorator'
import { Mutation, Getter } from 'vuex-class'
import {GetterNames, MutationNames } from '@/store/types'
import '@/assets/sounds/notification.wav'
@Component({
    components: {
    }
})
export default class ErrorHandlerModel extends Vue {
    @Getter(GetterNames.getErrorHandler) private getErrorHandler!: any
    @Mutation(MutationNames.setErrorHandler) private setErrorHandler!: any

    @Watch('getErrorHandler')
    private onchange() {
        console.log(this.getErrorHandler.length)
    }

}
