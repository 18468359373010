
















import { Component, Vue } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import { GetterNames, MutationNames } from '@/store/types'
import { httpGet, httpPutFormData } from '@/util/http'
import { apiUrl } from '@/domain/constants'
import ViewTableWrapper from './components/ViewTableWrapper.vue'
import ViewPastures from './components/ViewPastures.vue'
import i18n from '@/i18n'
@Component({
    components: {
      ViewPastures,
      ViewTableWrapper
    }
})
export default class FarmsInfoView extends Vue {
    @Getter(GetterNames.getSidebarModel) private getSidebarModel!: any
    @Getter(GetterNames.getLoadingDataNames) private getLoadingDataNames!: any
    @Getter(GetterNames.getPermissions) private getPermissions!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    private data: any[] = []
    private pastures: any = {
      area: {
        value: 0,
        editable: false
      },
      kpc: {
        value: 0,
        editable: true
      },
      mpc: {
        value: 0,
        editable: true
      },
      horses: {
        value: 0,
        editable: true
      },
      improvements: {
        value: 0,
        editable: true
      },
      used_area: {
        value: 0,
        editable: false
      },
      percentage_used: {
        value: 0,
        editable: false
      },
      not_used_area: {
        value: 0,
        editable: false
      },
      percentage_not_used: {
        value: 0,
        editable: false
      },
      note: {
        value: null,
        editable: true
      }
    }
    private cardToggle = -1
    private componentKey = 1

    private mounted() {
        this.getData()
        this.pastures = {
            area: {
              value: 0,
              editable: false
            },
            kpc: {
              value: 0,
              editable: true
            },
            mpc: {
              value: 0,
              editable: true
            },
            horses: {
              value: 0,
              editable: true
            },
            improvements: {
              value: 0,
              editable: true
            },
            used_area: {
              value: 0,
              editable: false
            },
            percentage_used: {
              value: 0,
              editable: false
            },
            not_used_area: {
              value: 0,
              editable: false
            },
            percentage_not_used: {
              value: 0,
              editable: false
            },
            note: {
              value: null,
              editable: true
            }
        }
    }

    private fieldsToString(fields: any[]) {
        if ( fields ) {
            const str: string[] = []
            fields.forEach((field, index) => {
              str.push(field.name)
            })
            return str
        }
        return ''
    }

    private formatArea(area: any) {
        return Math.round(Number(area) / 100) / 100
    }

    private getData() {
        httpGet({
            url: `${apiUrl}/get/farm/info/new/`,
            isSecureRequest: true,
            onSuccess: json => {
                const newFarms: any = []
                json.cadastre_info.forEach((farmInfo: any) => {
                    const newData: any = {
                        farmMainTable: [
                            [
                                {
                                    id: farmInfo.id,
                                    icon: "/farm/farm_icon.svg",
                                    title: farmInfo.name
                                },
                                {
                                    icon: "/location_arrow_icon.svg",
                                    title: (farmInfo.region ? farmInfo.region + ", " : "") + (farmInfo.oblast ? farmInfo.oblast : "")
                                }
                            ],
                            [
                                {
                                    icon: "/farm/subfield_icon.svg",
                                    title: i18n.t('headers.workspace.farm.edit.cadastral_add.fields_num') + ": " + farmInfo.fields_count
                                },
                                {
                                    icon: "/ruler_icon.svg",
                                    title: this.formatArea(farmInfo.total_area) + " " + i18n.t('general.units.he')
                                }
                            ]
                        ]
                    }
                    const farmDetail: any[] = []
                    farmInfo.farm_info.forEach((info: any) => {
                        const newInfo = {
                            id: info.id,
                            cadastre: info.cadastre ? [...info.cadastre].map((d, i) => (i) % 3 === info.cadastre.length % 3 && i > 0 ? '-' + d : d).join('') : '',
                            area: this.formatArea(info.area),
                            area_by_doc: this.formatArea(info.area_by_doc) ? this.formatArea(info.area_by_doc) : '0' + ' га',
                            fields_info: info.fields_info,
                            arable: this.formatArea(info.arable),
                            araeble_by_doc: this.formatArea(info.araeble_by_doc) ? this.formatArea(info.araeble_by_doc) : 0,
                            pasture: this.formatArea(info.pasture),
                            haymaking: this.formatArea(info.haymaking),
                            others: this.formatArea(info.others),
                            registration_date: info.registration_date,
                            document_number: info.document_number && info.document_number !== 'null' ? info.document_number : '',
                            document_deadline: info.document_deadline,
                            capture: this.formatArea(info.capture),
                            notUsed: this.formatArea(info.not_used),
                            notes: info.notes
                        }
                        farmDetail.push(newInfo)
                    })
                    newData.farmDetail = farmDetail
                    newFarms.push(newData)
                })
                this.data = newFarms

                if (json.pasture_info) {
                  const pasture = json.pasture_info
                  this.pastures = {
                    area: {
                      value: this.formatArea(pasture.area),
                      editable: false
                    },
                    kpc: {
                      value: pasture.kpc,
                      editable: true
                    },
                    mpc: {
                      value: pasture.mpc,
                      editable: true
                    },
                    horses: {
                      value: pasture.horses,
                      editable: true
                    },
                    improvements: {
                      value: pasture.improvements ? this.formatArea(pasture.improvements) : 0,
                      editable: true
                    },
                    used_area: {
                      value: this.formatArea(pasture.used_area),
                      editable: false
                    },
                    percentage_used: {
                      value: pasture.percentage_used,
                      editable: false
                    },
                    not_used_area: {
                      value: this.formatArea(pasture.not_used_area),
                      editable: false
                    },
                    percentage_not_used: {
                      value: pasture.percentage_not_used,
                      editable: false
                    },
                    note: {
                      value: pasture.note,
                      editable: true
                    }
                }
              }
            },
            onError: err => {
                console.error(err)
            }
        })
    }

    private updateFieldInfo(newData: any) {
        if (newData === 'newData' || newData === 'newPastures') {
          this.data = []
          this.getData()
          return
        }
        const formData = new FormData()
        formData.append('id', newData.id)
        formData.append('others', (newData.others * 10000).toString())
        formData.append('pasture', (newData.pasture * 10000).toString())
        formData.append('area_by_doc', (newData.area_by_doc.toString().replace(' га', '') * 10000).toString())
        formData.append('araeble_by_doc', (newData.araeble_by_doc * 10000).toString())
        formData.append('haymaking', (newData.haymaking * 10000).toString())
        formData.append('registration_date', newData.registration_date)
        formData.append('document_number', newData.document_number)
        formData.append('document_deadline', newData.document_deadline)
        formData.append('notes', newData.notes)
        httpPutFormData({
            url: `${apiUrl}/get/farm/info/${newData.id}/`,
            isSecureRequest: true,
            body: formData,
            onSuccess: info => {

              if (typeof newData.pasture === 'string') {
                this.data = []
                this.getData()
              }
              // this.data = []
              // this.getData()
              // const newInfo = {
              //       id: info.id,
              //       cadastre: info.cadastre,
              //       area: this.formatArea(info.area),
              //       fields_info: this.fieldsToString(info.fields_info),
              //       arable: this.formatArea(info.arable),
              //       pasture: this.formatArea(info.pasture),
              //       haymaking: this.formatArea(info.haymaking),
              //       others: this.formatArea(info.others),
              //       registration_date: info.registration_date,
              //       document_number: info.document_number,
              //       document_deadline: info.document_deadline,
              //       capture: this.formatArea(info.capture),
              //       notUsed: this.formatArea(info.not_used)
              //   }
              //   let updated: any = []
              //
              //   this.data.forEach((farm: any, farmIndex: number) => {
              //     const cadastre = farm.farmDetail.findIndex((cad: any) => cad.id === newInfo.id)
              //
              //     if (cadastre !== -1) {
              //       this.data[farmIndex].farmDetail[cadastre] = newInfo
              //       console.log(cadastre, newInfo)
              //     }
              //   })

                // this.data.some((farm: any, index: number) => {
                //
                //   farm.farmDetail.findIndex((elem: any) =>  {
                //
                //   })
                // //     if (elem.id === info.id) {
                // //       this.data[index].farmDetail[ind] = newInfo
                // //     }
                // //   })
                // // })
                // // if ( updated ) {
                // //     updated = {
                // //         ...newInfo
                // //     }
                // })
            },
            onError: err => {
              this.$alert(err, 'Ошибка', 'error')
            }
        })
    }

    private close() {
        this.$router.push('/cadastres').catch(err => {/* */})
    }
}
