



































import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import Farm from '@/models/Farm'
import {Getter} from 'vuex-class'
import {GetterNames} from '@/store/types'

@Component({
  components: {
  }
})

export default class FarmItem extends Vue {
    @Getter(GetterNames.getShowAllFarms) private getShowAllFarms!: any
    @Prop() private farm!: Farm
    @Prop({type: Boolean}) private active!: boolean
    @Prop({type: Boolean}) private showOnMap!: boolean

    @Emit('click')
    private click() {
        return {}
    }
}
