import { IState } from '.'

export type State = IState

export const Action = {
    updateMapDataInRegion: 'updateMapDataInRegion'
}

export const Mutation = {
    setMapWidth: 'mapWidth',
    setGeolocVisibility: 'setGeolocVisibility',
    setMainMapCenter: 'setMainMapCenter',
    setMainMapZoom: 'setMainMapZoom',
    setActiveIndicator: 'setActiveIndicator',
    changeLayerType: 'changeLayerType',
    setIsProblemZonesMode: 'setIsProblemZonesMode',
    setFieldRequestComparisonMode: 'setFieldRequestComparisonMode',
    incZoom: 'incZoom',
    decZoom: 'decZoom',
    setSwiperPositionX: 'setSwiperPositionX',
    setUpdateRequestView: 'setUpdateRequestView',
    setAgroChemData: 'setAgroChemData',
    setPlanningData: 'setPlanningData',
    setPlanningDataField: 'setPlanningDataField',
    setAgroChemResult: 'setAgroChemResult',
    setAgroChemFieldResult: 'setAgroChemFieldResult',
    setUploadAgroChemResult: 'setUploadAgroChemResult',
    setFertilizerResult: 'setFertilizerResult',
    setWeedinessImage: 'setWeedinessImage',
    setRulerMode: 'setRulerMode',
    setPrintScreen: 'setPrintScreen',
    setFarmMapControllers: 'setFarmMapControllers',
    setFieldEditGeom: 'setFieldEditGeom',
    setYieldLayer: 'setYieldLayer',
    setSuperResolution: 'setSuperResolution'
}

export const Getter = {
    getSwiperPositionX: 'getSwiperPositionX',
    getIsProblemZonesMode: 'getIsProblemZonesMode',
    getFieldRequestComparisonMode: 'getFieldRequestComparisonMode',
    getMapData: 'getMapData',
    getLayerType: 'getLayerType',
    getMapDataCenterX: 'getMapDataCenterX',
    getActiveIndicator: 'getActiveIndicator',
    getMapWidth: 'getMapWidth',
    getAgroChemData: 'getAgroChemData',
    getPlanningData: 'getPlanningData',
    getAgroChemResult: 'getAgroChemResult',
    getAgroChemFieldResult: 'getAgroChemFieldResult',
    getUploadAgroChemResult: 'getUploadAgroChemResult',
    getFertilizerResult: 'getFertilizerResult',
    getWeedinessImage: 'getWeedinessImage',
    getRulerMode: 'getRulerMode',
    getPrintScreen: 'getPrintScreen',
    getFarmMapControllers: 'getFarmMapControllers',
    getFieldEditGeom: 'getFieldEditGeom',
    getYieldLayer: 'getYieldLayer',
    getSuperResolution: 'getSuperResolution',
    getAnimateParameters: 'getAnimateParameters'
}
