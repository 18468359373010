


















































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import HeaderWithPagination from '@/components/HeaderWithPagination.vue'
import Sideboard from '@/components/Sideboard.vue'
import BaseMenuItem from '@/components/BaseMenuItem.vue'
import InfoText from '@/components/cadastreBoard/InfoText.vue'
import { GetterNames, ActionNames, MutationNames } from '@/store/types'
import { Getter, Action, Mutation } from 'vuex-class'
import { httpDelete, httpGet } from '@/util/http'
import { apiUrl, apiUrl2 } from '@/domain/constants'
import { Route } from 'vue-router'
import Farm from '@/models/Farm'
import SubscriptionInfo from '@/components/SubscriptionInfo.vue'
import FieldModel from '@/components/tour/FieldModel.vue'
import DefaultModel from '@/components/tour/DefaultModel.vue'
import FinalModel from '@/components/tour/FinalModel.vue'
import {fieldContent} from '@/data/tourContent'
import i18n from '@/i18n'
import FieldAnalytics from './AnalyticsBoard/FieldAnalytics.vue'
import EventView from '../TelematicsBoard/FieldTelematics/components/EventView.vue'
@Component({
    components: {
        Sideboard,
        HeaderWithPagination,
        BaseMenuItem,
        InfoText,
        SubscriptionInfo,
        FieldModel,
        DefaultModel,
        FinalModel,
        FieldAnalytics,
        EventView
    }
})
export default class SubfieldBoard extends Vue {
    @Action(ActionNames.fetchFarmsList) private fetchFarmsList !: any
    @Action(ActionNames.reloadCurrentFarm) private reloadCurrentFarm!: any
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: Route
    @Getter(GetterNames.getFarmFromRoute) private getFarmFromRoute!: Farm
    @Getter(GetterNames.getGuideTourActivators) private getGuideTourActivators!: any
    @Getter(GetterNames.getPermissions) private getPermissions!: any

    @Mutation(MutationNames.setNewFarmRequest) private setNewFarmRequest!: any
    @Mutation(MutationNames.setGuideTourActivators) private setGuideTourActivators!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    @Action(ActionNames.fetchFieldIndicatorData) private fetchFieldIndicatorData!: any
    @Mutation(MutationNames.setTelematicsInfo) private setTelematicsInfo!: any
    @Mutation(MutationNames.setIsDataLoading) private setIsDataLoading !: any
    private fieldHistory = []
    private techmap = null
    private tasks = []
    private notes = []
    private viewEventID = -1

    private async mounted() {
        await this.getFieldHistory()
        await this.getFieldTasks()
        await this.getFieldNotes()
        await this.fetchFieldIndicatorData()
        this.setTelematicsInfo({
            geozone: {
            data: null,
            view: false,
            edit: 0,
            type: 'edit'
            },
            folders: [],
            tractorsData: [],
            autoplay: false,
            tracktime: 0,
            autoTracking: {
            mainTractorID: -1,
            tracking: false
            },
            arrowsView: false,
            speedLimitsView: false,
            stopsView: false,
            fieldEvent: {
            reportType: 1,
            event: null,
            tractor: null,
            flaws: false,
            overlaps: false
            },
            controlPanelExtension: false
        })
    }

    @Watch('currentRoute.params.subfieldId')
    private onChangeSubfield(newVal: any) {
        this.fetchFieldIndicatorData()
    }

    get field() {
        if (this.getFarmFromRoute) {
            const field = this.getFarmFromRoute.fields.find((x: any) => x.id === parseInt(this.$route.params.subfieldId, 10))
            // console.log(field)
            if (field) {
                return field
            } else {
                this.fetchFarmsList()
                return false
            }
        } else {
            this.fetchFarmsList()
            return false
        }
    }

    private normalizeDate(date: string) {
        if (date) {
            return `${date.substring(8, 10)}.${date.substring(5, 7)}.${date.substring(0, 4)}`
        } else return i18n.t('messages.phrases.no_data')
    }

    @Watch('getGuideTourActivators.fieldTour')
    private onchangeFieldTour() {
        if (this.getGuideTourActivators.fieldTour) {
            (this.$refs.fieldModel as any).show()
        }
    }

    private endFieldTour() {
        this.setGuideTourActivators({fieldTour: false})
    }

    private startFieldTour(index: any) {
        if (index === 'end') {
            (this.$refs.finalModel as any).show()
            const model = this.$refs.defaultModel as any
            model.hide()
            this.endFieldTour()
        } else {
            const content: any = fieldContent[index]
            const element: any = document.getElementById(fieldContent[index].id)
            const container: any = document.getElementById('subfield_container')
            container.scrollTop = container.scrollHeight
            const elementBounding: any = element.getBoundingClientRect()
            const model = this.$refs.defaultModel as any
            model.show(fieldContent[index], {
                modelTop:  content.modelTop,
                modelBottom: content.modelBottom ? content.modelBottom : (window.innerHeight - elementBounding.bottom - 84) + 'px',
                modelLeft: content.modelLeft,
                modelRight: content.modelRight,
                arrowTop: content.arrowTop,
                arrowLeft: content.arrowLeft
            })
        }
    }

    private DeleteField() {
        const fieldID = this.$route.params.subfieldId
        this.setLoadingDataNames('fieldBoardDeleteField')
        httpDelete({
            url: `${apiUrl}/cadastre/divide/delete/${parseInt(fieldID, 10)}`,
            isSecureRequest: true,
            onSuccess: () => {
                alert(i18n.t('messages.success.deleted'))
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('fieldBoardDeleteField')
                this.openCadastreBoard()
                this.reloadCurrentFarm()
            }
        })
    }

    private orderRequest() {
        this.setNewFarmRequest({isNewRequest: true, farmId: this.getFarmFromRoute.id, selectedFarms: []})
        this.$router.push({
            name: 'farmorderdata',
            params: {
                status: 'loading'
            }
        }).catch(e => { /* */ })
    }

    private openCadastreBoard() {
        if (this.getFarmFromRoute) {
            this.$router.push(`/cadastres/farm/${this.getFarmFromRoute.id}/subfields`)
        }
    }

    private openPrevField() {
        if (this.getFarmFromRoute) {
            const prevFieldId = this.getFarmFromRoute.getPrevFieldId(this.$route.params.subfieldId)
            if (prevFieldId !== -1) {
                this.openFieldRoute('subfield', `${this.getFarmFromRoute.id}`, `${prevFieldId}`)
            }
        }
    }

    private openNextField() {
        if (this.getFarmFromRoute) {
            const nextFieldId = this.getFarmFromRoute.getNextFieldId(this.$route.params.subfieldId)
            if (nextFieldId !== -1) {
                this.openFieldRoute('subfield', `${this.getFarmFromRoute.id}`, `${nextFieldId}`)
            }
        }
    }

    private openFieldRoute(routeName: any, farmId?: any, fieldId?: any) {
        console.log(routeName)
        if (this.getFarmFromRoute) {
            const currentFarmId = `${this.getFarmFromRoute.id}`
            const currentFieldId = `${this.$route.params.subfieldId}`
            this.$router.push({
                name: routeName,
                params: {
                    farmId: farmId ? farmId : currentFarmId,
                    subfieldId: fieldId ? fieldId : currentFieldId
                }
            }).catch(e => { /* */ })
        }
    }

    private getFieldHistory() {
        this.setLoadingDataNames('fieldHistoryGetHistoryData')
        httpGet({
            url: `${apiUrl}/get/history/by/divided/${this.currentRoute.params.subfieldId}/`,
            isSecureRequest: true,
            onSuccess: json => {
                this.fieldHistory = json.reverse()
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('fieldHistoryGetHistoryData')
            }
        })
    }

    private getFieldTasks() {
        this.techmap = null
        this.tasks = []
        this.setLoadingDataNames('FieldTasksFetchFieldTechmap')
        httpGet({
            url: `${apiUrl}/technology/fact/tasks/by/field/${this.$route.params.subfieldId}/`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                if (json.ok) {
                    this.techmap = json.json
                    this.tasks = json.json
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('FieldTasksFetchFieldTechmap')
            }
        })
    }

    private getFieldNotes() {
        this.setLoadingDataNames('FieldNotesIsGettingNotes')
        httpGet({
            url: `${apiUrl2}/notes/by_field/${this.currentRoute.params.subfieldId}/`,
            isSecureRequest: true,
            onSuccess: json => {
                this.notes = json.reverse()
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('FieldNotesIsGettingNotes')
            }
        })
    }

    private formatDate(start: any, end?: any) {
        const startDate = new Date(start)
        const endDate = new Date(end)

        return startDate.toLocaleDateString('RU-ru') + ' -' + endDate.toLocaleDateString('RU-ru')
    }

    private toggleEventView(id: any, activities?: any) {
        this.setTelematicsInfo({
            geozone: {
                data: null,
                view: false,
                edit: 0,
                type: 'edit'
            },
            folders: [],
            tractorsData: [],
            autoplay: false,
            tracktime: 0,
            autoTracking: {
                mainTractorID: -1,
                tracking: false
            },
            arrowsView: false,
            speedLimitsView: false,
            stopsView: false,
            fieldEvent: {
                reportType: 1,
                event: null,
                tractor: null,
                flaws: false,
                overlaps: false
            },
            controlPanelExtension: false
        })
        this.setIsDataLoading(false)
        if (this.viewEventID === id) {
            this.viewEventID = -1
        } else {
            this.viewEventID = id
        }
    }

    private getStatus(event: any, color: boolean) {
        if (event.percentage >= 100) {
            return color ? '#4B970F' : 'Выполнено'
        } else if (event.end_date) {
            const today = new Date()
            const endDate = new Date(event.end_date)
            if (today > endDate) {
                return color ? '#D51900' : 'Просрочено'
            }
        }
        return color ? '#FFC700' : 'В процессе'
    }

    @Watch('currentRoute.params.subfieldId')
    private changeField(val: any) {
        this.getFieldHistory()
        this.getFieldTasks()
        this.getFieldNotes()
    }

}
