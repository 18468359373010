
























































import { Component, Vue } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import { GetterNames, MutationNames } from '@/store/types'
import '@splidejs/splide/dist/css/themes/splide-default.min.css'
import {clgreenGistogram, ndviGistogram, ndmiGistogram, ndviContrastedGistogram} from '@/data/gistogramData'
@Component
export default class FieldIndicatorsSlider extends Vue {
    public $refs!: {
        indicatorsSlide: any
    }
    @Getter(GetterNames.getSideboardIsShown) private sideboardIsShown !: any
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: any
    @Getter(GetterNames.getSubfieldIndicatorData) private getSubfieldIndicatorData!: any
    @Getter(GetterNames.getActiveIndicator) private getActiveIndicator!: any
    @Mutation(MutationNames.setSubfieldIndicatorData) private setSubfieldIndicatorData!: any
    private indicatorsData = {
        rewind : false,
        autoWidth: true,
        pagination: false,
        gap: '8px',
        perMove: 2,
        focus: 'center'
    }

    private normalizeDate(date: any) {
        if (date) {
            return date.substring(8, 10) + '.' + date.substring(5, 7) + '.' + date.substring(0, 4)
        } else {
            return ''
        }
    }

    private getContrastedColor(request: any) {
        if (request.contrasted_ndvi) {
            const newContrastedGistogram = ndviContrastedGistogram.map((data: any, index: number) => {
                return {
                    color: data.color,
                    from: Math.round(request.contrasted_ndvi.levels[index] * 1000) / 1000,
                    to: Math.round(request.contrasted_ndvi.levels[index + 1] * 1000) / 1000
                }
            })
            const range = newContrastedGistogram.find((x: any) => x.from <= request.ndvi.mean && x.to >= request.ndvi.mean)
            if (range) return range.color
        }
    }

    private getIndicatorColor(value: any ) {
        if (value) {
            let ranges: any = []
            if (this.getActiveIndicator === 'ndvi') ranges = ndviGistogram
            if (this.getActiveIndicator === 'contrasted_ndvi') ranges = ndviGistogram
            if (this.getActiveIndicator === 'ndmi') ranges = ndmiGistogram
            if (this.getActiveIndicator === 'gndvi') ranges = ndviGistogram
            if (this.getActiveIndicator === 'clgreen') ranges = clgreenGistogram
            if (ranges.length > 0) {
                const range = ranges.find((x: any) => x.from <= value && x.to >= value)
                if (range) return range.color
            }
        }
        return '#FFFFFF'
    }

    private setIndicatorData(request: any, index: any) {
        this.setSubfieldIndicatorData({
            currentRequestId: request.id,
            secondRequestId: request.id,
            currentRequestDir: request.images_urls ? request.images_urls : null,
            secondRequestDir: request.images_urls ? request.images_urls : null,
            currentBbox: request.extra_params ? request.extra_params.bbox ? request.extra_params.bbox : null : null,
            secondBbox: request.extra_params ? request.extra_params.bbox ? request.extra_params.bbox : null : null
        })
        if (this.$refs.indicatorsSlide) {
            this.$refs.indicatorsSlide.$data.splide.go(index)
        }
    }
}
