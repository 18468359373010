






































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import Sideboard from '@/components/Sideboard.vue'
import { Getter, Action, Mutation } from 'vuex-class'
import {GetterNames, MutationNames, ActionNames} from '@/store/types'
import { httpGet, httpPutFormData } from '@/util/http'
import { apiUrl } from '@/domain/constants'
import { Route } from 'vue-router'
import CultureChoose from '@/components/CultureChoose.vue'
import DatePicker from '@/components/DatePicker.vue'
import { CultureReproduction } from '@/data/FieldCulture'
import i18n from '@/i18n'
@Component({
    components: {
        Sideboard,
        CultureChoose,
        DatePicker
    }
})
export default class EditFieldPassport extends Vue {
    @Action(ActionNames.updateCurrentField) private updateCurrentField!: any
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: Route
    @Getter(GetterNames.getFieldEditGeom) private getFieldEditGeom!: any
    @Getter(GetterNames.getFieldFromRoute) private getFieldFromRoute!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    @Mutation(MutationNames.setFieldEditGeom) private setFieldEditGeom!: any
    private CultureReproduction = CultureReproduction
    private name = {
        selectedName: '',
        onerror: ''
    }
    private springTreatments: any = []
    private autumnTreatments: any = []
    private history: any = {
        fieldId: -1,
        parent_culture: '',
        culture: '',
        sort: '',
        reproduction: '',
        start_date: '',
        end_date: '',
        spring_treatment: '',
        autumn_treatment: '',
        is_rest: false,
        oldFile: null,
        file: null
    }
    private productivity = {
        origin: [
            {
                id: 1,
                name: this.$i18n.t('general.units.centner_he')
            },
            {
                id: 2,
                name: this.$i18n.t('general.units.t_he')
            }
        ],
        converted: [this.$i18n.t('general.units.centner_he'), this.$i18n.t('general.units.t_he')],
        selectedUnit: {
            id: 1,
            name: this.$i18n.t('general.units.centner_he')
        },
        unitName: this.$i18n.t('general.units.centner_he'),
        selectedName: '',
        onerror: ''
    }

    private onSelectSpringTreatment(item: string) {
        this.history.spring_treatment = item
    }

    private onSelectAutumnTreatment(item: string) {
        this.history.autumn_treatment = item
    }

    private onSelectUnit(item: string) {
        const unit = this.productivity.origin.find((x: any) => x.name === item)
        if (unit) {
            this.productivity.selectedUnit = unit
            this.productivity.unitName = unit.name
        }
    }

    private setIsRest(val: boolean) {
        this.history.is_rest = val
    }

    @Watch('name.selectedName')
    private onchangeName() {
        this.name.onerror = ''
    }

    @Watch('productivity.selectedName')
    private onchangeProductivity() {
        this.productivity.onerror = ''
    }

    @Watch('history.is_rest')
    private onchangeIsRest() {
        this.productivity.onerror = ''
    }

    private getFileName() {
        if (this.history.file) {
            if ( typeof(this.history.file) === 'string' && (this.history.file as any).includes('/media/crop_rotation/')) {
                return (this.history.file as any).split('/media/crop_rotation/')[1]
            }
            if ((this.history.file as any).name) return (this.history.file as any).name
        }
        return this.$i18n.t('headers.workspace.fields.documents.add')
    }

    private openFileUpload() {
        const upload = this.$refs.uploadfile as any
        if (upload) {
            upload.click()
        }
    }

    private filesUploaded(files: any) {
        this.history.file = files[0]
    }

    @Watch('getFieldFromRoute')
    private onchangeField(val: any, old: any) {
        if (val && val !== old) {
            this.fillData()
        }
    }

    private mounted() {
        this.fillData()
    }

    private fillData() {
        if (this.getFieldFromRoute) {
            this.fetchData()
            const field = this.getFieldFromRoute
            const history = this.getFieldFromRoute.passport
            this.name.selectedName = field.name
            this.history = {
                fieldId: field.id,
                parent_culture: history.parent_culture || '',
                culture: history.culture || '',
                sort: history.sort || '',
                reproduction: history.reproduction || '',
                start_date: history.start_date || '',
                end_date: history.end_date || '',
                spring_treatment: history.spring_treatment || '',
                autumn_treatment: history.autumn_treatment || '',
                is_rest: history.is_rest,
                oldFile: history.file || null,
                file: history.file || null
            }
            this.productivity.selectedUnit = {
                id: history.measure,
                name: history.measure === 1 ? this.$i18n.t('general.units.centner_he') : this.$i18n.t('general.units.t_he')
            }
            this.productivity.unitName = history.measure === 1 ?
                this.$i18n.t('general.units.centner_he') : this.$i18n.t('general.units.t_he')
            this.productivity.selectedName = history.productivity ? `${history.productivity}` : ''
            const el: any = this.$refs.cultureChoose
            if (el) {
                el.setData(this.history.parent_culture, this.history.culture, this.history.sort, this.history.reproduction)
            }
        }
    }

    private fetchData() {
        httpGet({
            url: `${apiUrl}/crop_rotation/treatment/`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                if (json.ok) {
                    this.springTreatments = json.json['1'].map((x: any) => x.name)
                    this.autumnTreatments = json.json['2'].map((x: any) => x.name)
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => { /* */ }
        })
    }

    private editFieldPassport() {
        if (this.checkForError()) return
        const formData = new FormData()
        formData.append('name', this.name.selectedName)
        formData.append('culture', this.history.is_rest ? '' : this.history.culture)
        formData.append('parent_culture', this.history.is_rest ? '' : this.history.parent_culture)
        formData.append('sort', this.history.is_rest ? '' : this.history.sort)
        formData.append('reproduction', this.history.is_rest ? '' : this.history.reproduction)
        formData.append('start_date', this.history.is_rest ? '' : this.history.start_date)
        formData.append('end_date', this.history.is_rest ? '' : this.history.end_date)
        formData.append('productivity', this.history.is_rest ? '' : this.productivity.selectedName)
        formData.append('measure', this.productivity.selectedUnit.id as any)
        formData.append('spring_treatment', this.history.spring_treatment)
        formData.append('autumn_treatment', this.history.autumn_treatment)
        formData.append('is_rest', this.history.is_rest)
        if (this.getFieldEditGeom.fieldId !== -1 && this.getFieldEditGeom.geometry !== null) {
            if (this.getFieldEditGeom.fieldId.toString() === this.currentRoute.params.subfieldId) {
                if (this.getFieldEditGeom.geometry !== this.getFieldEditGeom.cachedGeometry) {
                    formData.append('geom', this.getFieldEditGeom.geometry)
                }
            }
        }
        if (this.history.file === null) {
            formData.append('file', '')
        } else if (this.history.file !== this.history.oldFile) {
            formData.append('file', this.history.file)
        }
        this.setLoadingDataNames('editFieldPassportPassportUpdate')
        this.setFieldEditGeom({errorPoints: []})
        httpPutFormData({
            url: `${apiUrl}/divided/passport/update/new/${this.history.fieldId}/`,
            isSecureRequest: true,
            detailResponse: true,
            body: formData,
            onSuccess: json => {
                if (json.ok) {
                    this.$alert(`${this.$i18n.t('messages.success.successfully_updated')}`, `${this.$i18n.t('messages.success.changes_saved')}`, 'success')
                    .then(() => {
                        this.$router.push({
                            name: 'subfield',
                            params: {
                                farmId: this.$route.params.farmId,
                                subfieldId: this.$route.params.subfieldId
                            }
                        }).catch(err => {/* */})
                        this.updateCurrentField(json.json)
                    })
                } else if (json.status === 400) {
                    this.$alert(`${this.$i18n.t('messages.errors.already_exists_field')}`, 'Предупреждение', 'warning')
                    this.name.onerror = `${this.$i18n.t('messages.errors.already_exists_field')}`
                } else if (json.status === 422) {
                    const errors: any = []
                    if (json.json.hasOwnProperty('geom')) {
                        for (const error of json.json.geom) {
                            if (error.point && error.problem_type) {
                                const points = error.point.replaceAll(/[()]/g, '').split(' ')
                                errors.push({
                                    coordinates: [Number(points[1]), Number(points[2])],
                                    problem_type: error.problem_type
                                })
                            }

                        }
                        this.$alert('Ошибка в геометрии поле. Проверьте нарисованное поле.', 'Предупреждение', 'warning').then(() => {
                            this.setFieldEditGeom({errorPoints: errors})
                        })
                    }
                } else {
                    this.$alert(JSON.parse(json.json), 'Ошибка', 'error')
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('editFieldPassportPassportUpdate')
            }
        })
    }

    private checkForError() {
        this.history.start_date = (this.$refs.startDateModel as any).getDate()
        this.history.end_date = (this.$refs.endDateModel as any).getDate()
        const el: any = this.$refs.cultureChoose
        if (el) {
            const result = el.getData()
            this.history.parent_culture = result.parent_culture || ''
            this.history.culture = result.culture || ''
            this.history.sort = result.sort || ''
            this.history.reproduction = result.reproduction || ''
        }
        let counter = 0
        if (this.name.selectedName === '') {
            this.name.onerror = `${this.$i18n.t('messages.errors.field_name')}`
            counter++
        }
        if (!this.history.is_rest && this.productivity.selectedName !== '') {
            if (isNaN(this.productivity.selectedName as any)) {
                this.productivity.onerror = `${this.$i18n.t('messages.errors.incorrect_yield')}`
                counter++
            }
        }
        return counter > 0
    }

    private closeRegisterField() {
        this.$router.push({
            name: 'subfield',
            params: {
                farmId: this.$route.params.farmId,
                subfieldId: this.$route.params.subfieldId
            }
        }).catch(err => {/* */})
    }
}
