export default {
    title: "Log in",
    register: "Or | sign up for free",
    number: "Mobile number or e-mail",
    remember: "Remember me",
    password_forgotten: "Forgot password?",
    email_confirm: "Email confirmation",
    password_reset: {
        title: "Password recovery",
        explanation_1: "Enter your email address for password recovery",
        notification: "A confirmation link has been sent to your email address |. Please follow this link to log in.",
        explanation_2: "Create a new password",
        new_password: "New password",
        password_repeat: "Confirm your password",
        success: "The password was successfully changed!",
        message: "Please log in using your new password"
    },
    errors: {
        google: "Problem with google account, try again",
        typo: "Wrong username or password entered",
        email: "Incorrect format of email",
        not_found: "User with this email was not found",
        server: "Server error",
        data: "Data entered incorrectly",
        password: "The password must contain at least 6 characters"
    }
}