
















































import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter, Mutation} from 'vuex-class'
import Sideboard from '@/components/Sideboard.vue'
import { ActionNames, GetterNames, MutationNames } from '@/store/types'
import { apiUrl } from '@/domain/constants'
import { httpGet } from '@/util/http'
import i18n from '@/i18n'
import RegisterFarm from '@/models/RegisterFarm'
@Component({
    components: {
        Sideboard
    }
})
export default class SearchResult extends Vue {
    @Action(ActionNames.updateMapDataInRegion) private updateMapDataInRegion!: any

    @Getter(GetterNames.getCurrentRoute) private getCurrentRoute!: any
    @Getter(GetterNames.getRegisterFarms) private getRegisterFarms!: any

    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    @Mutation(MutationNames.setRegisterFarms) private setRegisterFarms!: any
    private isDataLoading = false
    private hasCadastres = false
    private regionName = ''
    private counter = {
        max: 0,
        current: 0
    }

    private mounted() {
        this.setRegisterFarms({cadastres: [], selectedCadastres: [], cachedCadastres: []})
        this.isDataLoading = true
        httpGet({
            url:  `${apiUrl}/oblast/data/${this.getCurrentRoute.params.regionId}/`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                if (json.ok) {
                    this.hasCadastres = json.json.has_cadastres
                    this.regionName = `${json.json.name ? `${json.json.name}, ` : ''}${json.json.oblast}`
                    if (json.json.has_cadastres) {
                        this.updateMapDataInRegion({
                            boundaries: JSON.parse(JSON.stringify(json.json.boundaries)),
                            center: JSON.parse(JSON.stringify(json.json.center.coordinates))
                        })
                    }
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.isDataLoading = false
            }
        })
    }

    private setCadastresSelected() {
        this.$ga.event('register farm', 'set selected cadastres', 'from searchresult', 1)
        this.counter.max = this.getRegisterFarms.selectedCadastres.length
        this.counter.current = 0
        this.setLoadingDataNames('searchResultSetCadastresSelected')
        for (const cadastre of this.getRegisterFarms.selectedCadastres) {
            this.fetchCadastre(cadastre)
        }
    }

    private fetchCadastre(newcadastre: any) {
        httpGet({
            url:  `${apiUrl}/cadastre_by_value/${newcadastre.documentId}/`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                if (json.ok) {
                    if (Object.keys(json.json).length !== 0) {
                        let field: any = null
                        if (json.json.field.length > 0) {
                            field = JSON.parse(JSON.stringify(json.json.field))
                        } else {
                            field = [{
                                id: json.json.documentId,
                                geometry: json.json.geomjson,
                                area: json.json.area,
                                name: null
                            }]
                        }
                        const cadastre = new RegisterFarm()
                        cadastre.number = json.json.documentId
                        cadastre.setData({
                            id: json.json.id,
                            documentId: json.json.documentId,
                            location: `${json.json.region ? `${json.json.region}, ` : ''}${json.json.oblast}`,
                            area: json.json.area,
                            fields: field,
                            cadastre: json.json,
                            readonly: false
                        })
                        this.getRegisterFarms.cachedCadastres.push(cadastre)
                    }
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.counter.current += 1
                if (this.counter.max === this.counter.current) {
                    this.setLoadingDataNames('searchResultSetCadastresSelected')
                    this.$router.push('/registercadastre').catch(err => {/* */})
                }
            }
        })
    }
}
