












































import { Component, Vue, Emit } from 'vue-property-decorator'
import { httpGet } from '@/util/http'
import { apiUrl } from '@/domain/constants'
import { GetterNames, MutationNames } from '@/store/types'
import { Getter, Mutation } from 'vuex-class'
import i18n from '@/i18n'
@Component
export default class WorkspaceInfoModel extends Vue {
    @Getter(GetterNames.getLoadingDataNames) private getLoadingDataNames!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    private isShown = false
    private members = []

    private normalizeTime(member: any) {
        if (!member.is_accepted) {
            return i18n.t('headers.workspace.projects.participants.last_access.not_activated')
        } else if (member.since_last_login < 0) {
            return i18n.t('headers.workspace.projects.participants.last_access.offline')
        } else {
            if (member.since_last_login < 1) {
                return i18n.t('headers.workspace.projects.participants.last_access.just_now')
            } else if (member.since_last_login < 60) {
                const second = Math.round(member.since_last_login)
                return i18n.tc('headers.workspace.projects.participants.last_access.sec', second)
            } else if (member.since_last_login < 60 * 60) {
                const minute = Math.round(member.since_last_login / 60)
                return i18n.tc('headers.workspace.projects.participants.last_access.min', minute)
            } else if (member.since_last_login < 60 * 60 * 24) {
                const hour = Math.round(member.since_last_login / (60 * 60))
                return i18n.tc('headers.workspace.projects.participants.last_access.h', hour)
            } else if (member.since_last_login < 60 * 60 * 24 * 7) {
                const day = Math.round(member.since_last_login / (60 * 60 * 24))
                return  i18n.tc('headers.workspace.projects.participants.last_access.day', day)
            } else if (member.since_last_login < 60 * 60 * 24 * 30) {
                const week = Math.round(member.since_last_login / (60 * 60 * 24 * 7))
                return  i18n.tc('headers.workspace.projects.participants.last_access.week', week)
            } else if (member.since_last_login < 60 * 60 * 24 * 30 * 12) {
                const month = Math.round(member.since_last_login / (60 * 60 * 24 * 30))
                return  i18n.tc('headers.workspace.projects.participants.last_access.month', month)
            } else {
                const year = Math.round(member.since_last_login / (60 * 60 * 24 * 30 * 12))
                return  i18n.tc('headers.workspace.projects.participants.last_access.year', year)
            }
        }
    }

    private show(id: any) {
        this.isShown = true
        this.fetchData(id)
    }

    private fetchData(id: any) {
        this.setLoadingDataNames('workspaceInfoFetchData')
        httpGet({
            url:  `${apiUrl}/workspace/get/employee/by/rw/${id}/`,
            isSecureRequest: true,
            onSuccess: json => {
                this.members = json.sort((a: any, b: any) => {
                    if (Number(a.since_last_login) === -1) return 1
                    else if (Number(b.since_last_login) === -1) return -1
                    else if (Number(a.since_last_login) > Number(b.since_last_login)) return 1
                    else if (Number(a.since_last_login) < Number(b.since_last_login)) return -1
                    else return 0
                })
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('workspaceInfoFetchData')
            }
        })
    }

    @Emit('reload')
    private reload() {
        return {}
    }
    private hide() {
        this.isShown = false
    }
}
