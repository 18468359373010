import { render, staticRenderFns } from "./EventsView.vue?vue&type=template&id=49708555&scoped=true&"
import script from "./EventsView.vue?vue&type=script&lang=ts&"
export * from "./EventsView.vue?vue&type=script&lang=ts&"
import style0 from "./EventsView.vue?vue&type=style&index=0&id=49708555&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49708555",
  null
  
)

export default component.exports