































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Getter, Action, Mutation } from 'vuex-class'
import { GetterNames, ActionNames, MutationNames } from '@/store/types'
import { Route } from 'vue-router'
import { httpGet, httpDelete } from '@/util/http'
import { apiUrl } from '@/domain/constants'
import i18n from '@/i18n'
import NewStorageModel from './NewStorageModel.vue'
import {egisticLogo, pdfWaterMark} from '@/data/pdfImageData'
import { makePDF, PdfActions } from '@/utils/services'
@Component({
    components: {
        NewStorageModel
    }
})
export default class StorageView extends Vue {
    @Getter(GetterNames.getSidebarModel) private getSidebarModel!: any
    @Getter(GetterNames.getPermissions) private getPermissions!: any
    @Getter(GetterNames.getLoadingDataNames) private getLoadingDataNames!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    @Mutation(MutationNames.setSideboardIsShown) private setSideboardIsShown !: any
    private egisticLogo: any = egisticLogo
    private pdfWaterMark: any = pdfWaterMark
    private storages: any = []
    private searchText = ''
    private selectedStorages: any = []
    private farms: any = []
    private farmSelection: any = {
        farms: [],
        dropdown: false
    }
    private sortingType: any = {
        property: '',
        alphabetically: true
    }

    @Watch('searchText')
    private onchangeSearchText(val: any, old: any) {
        if (val !== old) {
            this.selectedStorages = []
        }
    }

    @Watch('farmSelection.farms')
    private onchangeFarmSelection(val: any, old: any) {
        const arr = []
        for (const id of this.selectedStorages) {
            const storage = this.storages.find((x: any) => x.id === id)
            if (storage) {
                if (this.farmSelection.farms.includes(storage.group_users)) {
                    arr.push(id)
                }
            }
        }
        this.selectedStorages = arr
    }

    private getStorages() {
        const searchResult = []
        for (const storage of this.storages) {
            if (this.farmSelection.farms.includes(storage.group_users)) {
                if (storage.title.toLowerCase().includes(this.searchText.toLowerCase()) ||
                    storage.group_users_name.toLowerCase().includes(this.searchText.toLowerCase()) ||
                    storage.description.toLowerCase().includes(this.searchText.toLowerCase())) {
                        searchResult.push(storage)
                } else {
                    for (const type of storage.types_name) {
                        if (type.toLowerCase().includes(this.searchText.toLowerCase())) {
                            searchResult.push(storage)
                            break
                        }
                    }
                }
            }
        }
        if (this.sortingType.property !== '') {
            searchResult.sort((a: any, b: any) => a[this.sortingType.property] > b[this.sortingType.property] ? 1 : -1)
            if (!this.sortingType.alphabetically) searchResult.reverse()
        }
        return searchResult
    }

    private changeSortingType(type: any) {
        if (this.sortingType.property === type) {
            this.sortingType.alphabetically = !this.sortingType.alphabetically
        } else {
            this.sortingType.property = type
            this.sortingType.alphabetically = true
        }
    }

    private normalizeTypes(types: any) {
        let str = ''
        for (const type of types) {
            str += type + '; '
        }
        return str
    }

    private mounted() {
        this.fetchFarms()
        this.fetchStorages()
        this.setSideboardIsShown(true)
    }

    private fetchFarms() {
        this.setLoadingDataNames('storageFetchFarms')
        httpGet({
            url:  `${apiUrl}/group/user/list/`,
            isSecureRequest: true,
            onSuccess: json => {
                this.farms = json
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.selectAll()
                this.setLoadingDataNames('storageFetchFarms')
            }
        })
    }

    private fetchStorages() {
        this.setLoadingDataNames('storageFetchStorages')
        httpGet({
            url:  `${apiUrl}/storage/`,
            isSecureRequest: true,
            onSuccess: json => {
                this.storages = json
                this.selectedStorages = json.map((x: any) => x.id)
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('storageFetchStorages')
            }
        })
    }

    private deleteStorage(id: any) {
        if (confirm(i18n.t('messages.questions.delete.storage') as string)) {
            httpDelete({
                url: `${apiUrl}/storage/${id}`,
                isSecureRequest: true,
                onSuccess: () => {
                    alert(i18n.t('messages.success.deleted'))
                },
                onError: error => {
                    console.log(error)
                },
                doFinally: () => {
                    this.fetchStorages()
                }
            })
        }
    }

    private selectAllStorages() {
        const arr = []
        for (const storage of this.getStorages()) {
            arr.push(storage.id)
        }
        console.log(arr)
        if (JSON.stringify(arr.sort()) === JSON.stringify(this.selectedStorages.sort())) {
            this.selectedStorages = []
        } else {
            this.selectedStorages = arr
        }
    }

    private toggleStorageSelection(id: any) {
        if (this.selectedStorages.includes(id)) {
            const index = this.selectedStorages.indexOf(id)
            this.selectedStorages.splice(index, 1)
        } else {
            this.selectedStorages.push(id)
        }
    }

    private getSelectedFarms() {
        if (this.farmSelection.farms.length === 0) {
            return i18n.t('messages.selections.farm')
        } else if (this.farmSelection.farms.length === this.farms.length) {
            return i18n.t('messages.selections.all_farms')
        } else {
            let str = ''
            for (const id of this.farmSelection.farms) {
                const farm = this.farms.find((x: any) => x.id === id)
                str += farm.name + ', '
            }
            if (str.length > 30) {
                return str.substring(0, 27) + '...'
            }
            return str
        }
    }

    private selectAll() {
        if (this.farmSelection.farms.length === this.farms.length) {
            this.farmSelection.farms = []
        } else {
            this.farmSelection.farms = []
            for (const farm of this.farms) {
                this.farmSelection.farms.push(farm.id)
            }
        }
    }

    private toggleFarmSelection(id: any) {
        if (this.farmSelection.farms.includes(id)) {
            const index = this.farmSelection.farms.indexOf(id)
            this.farmSelection.farms.splice(index, 1)
        } else {
            this.farmSelection.farms.push(id)
        }
    }

    private getExcelData() {
        const excelData: any = []
        if (this.getStorages().length === 0) {
            excelData.push({
                '№': '',
                [i18n.t('headers.workspace.storage.logs.name') as string]: '',
                [i18n.t('headers.workspace.storage.logs.fields.type') as string]: '',
                [i18n.t('headers.workspace.storage.logs.farm') as string]: '',
                [i18n.t('headers.workspace.storage.logs.fields.desc') as string]: ''
            })
        } else {
            this.getStorages().forEach((storage: any, index: any) => {
                excelData.push({
                    '№': index + 1,
                    [i18n.t('headers.workspace.storage.logs.name') as string]: storage.title,
                    [i18n.t('headers.workspace.storage.logs.fields.type') as string]: this.normalizeTypes(storage.types_name),
                    [i18n.t('headers.workspace.storage.logs.farm') as string]: storage.group_users_name,
                    [i18n.t('headers.workspace.storage.logs.fields.desc') as string]: storage.description
                })
            })
        }
        return excelData
    }

    private downloadExcel() {
        const downloader: any = document.getElementById('downloadExcel')
        if (downloader) {
            downloader.click()
        }
    }

    private printPdf() {
        const date = new Date()
        const today = date.toLocaleString('ru', { hour12: false, day: 'numeric', month: 'numeric', year: 'numeric',  hour: "numeric", minute: "numeric"})
        let farmsList = ''
        for (const farm of this.farms) {
            if (this.farmSelection.farms.includes(farm.id)) {
                 farmsList += farm.name
                 if (this.farms[this.farms.length - 1] !== farm) {
                     farmsList += '; '
                 }
            }
        }
        const docDefinition = {
            pageSize: {
                width: 842,
                height: 595
            },
            pageMargins: [30, 30, 30, 30],
            background: [
                {
                    image: this.pdfWaterMark,
                    margin: [386, 0, 0, 0]
                }
            ],
            content: [
                {
                    image: this.egisticLogo,
                    margin: [0, 10, 0, 0],
                    width: 91.14,
                    height: 30,
                    alignment: 'center'
                },
                {
                    text: i18n.t('headers.workspace.storage.list'),
                    fontSize: 16,
                    bold: true,
                    margin: [0, 24, 0, 0],
                    alignment: 'center'
                },
                {
                    text: today,
                    fontSize: 16,
                    bold: true,
                    margin: [0, 8, 0, 0],
                    alignment: 'center'
                },
                {
                    margin: [16, 16, 0, 24],
                    columns: [
                        {
                            width: 'auto',
                            text: i18n.t('headers.workspace.storage.list_by_farms') + ':',
                            fontSize: 14,
                            bold: true,
                            color: '#000000'
                        },
                        {
                            width: 'auto',
                            text: farmsList,
                            fontSize: 14,
                            color: '#000000'
                        }
                    ]
                },
                {
                    table: {
                        widths: [40, 170, 170, 170, 195],
                        headerRows: 1,
                        body: this.getPdfTableBody()
                    }
                }
            ]
        }
        makePDF(PdfActions.PRINT, docDefinition)
    }

    private getPdfTableBody() {
        const table: any = []
        const row: any = []
        const tableHeaders = ['№',
        i18n.t('headers.workspace.storage.logs.name'),
        i18n.t('headers.workspace.storage.logs.fields.type'),
        i18n.t('headers.workspace.storage.logs.farm'),
        i18n.t('headers.workspace.storage.logs.fields.desc')]
        tableHeaders.forEach((e: any) => {
            row.push({text: e, fontSize: 14})
        })
        table.push(row)
        this.getStorages().forEach((storage: any, index: any) => {
            table.push([
                {text: index + 1, fortSize: 14},
                {text: storage.title, fortSize: 14},
                {text: this.normalizeTypes(storage.types_name), fortSize: 14},
                {text: storage.group_users_name, fortSize: 14},
                {text: storage.description === '' ? i18n.t('messages.errors.no_description') : storage.description, fontSize: 14,
                    color: storage.description === '' ? '#ABAEA3' : '#1C1C1C'}
            ])
        })
        return table
    }

    private closeFarmsDropdown() {
        this.farmSelection.dropdown = false
    }

    private openStorageRemains() {
        if (this.selectedStorages.length === 0) return
        this.$router.push(
            {
                name: 'storageremains',
                params: {
                    storages: JSON.stringify(this.selectedStorages)
                }
            }
        )
    }

    private close() {
        this.$router.push(`/cadastres`).catch(e => {/**/})
    }
}
