












import { Component, Vue, Watch } from 'vue-property-decorator'
import { Route } from 'vue-router'
import { httpPut, httpPost } from '@/util/http'
import { apiUrl } from '@/domain/constants'
import { Getter, Mutation } from 'vuex-class'
import { GetterNames, MutationNames } from './store/types'
import { setLastLanguage } from '@/domain/constants'
import { openCrossOriginWebSite } from '@/domain/constants'
import Bowser from "bowser"
@Component
export default class App extends Vue {
    @Getter(GetterNames.IS_SIGNED_IN) private IS_SIGNED_IN!: any
    @Mutation(MutationNames.setCurrentRoute) private setCurrentRoute!: any
    @Mutation(MutationNames.setFullBlindLoading) private setFullBlindLoading!: any
    private userInfo: any = {
        IP_INFO: null,
        onerror: false,
        GEO_INFO: null
    }

    private created() {
        if (!this.IS_SIGNED_IN) {
            this.setFullBlindLoading(true)
            console.log(this.IS_SIGNED_IN)
            openCrossOriginWebSite('login', 'signin/farm')
        }
    }

    private mounted() {
        const browser = Bowser.parse(window.navigator.userAgent)
        if (browser) {
            this.$ga.event('browser', browser.browser.name, JSON.stringify(browser), 1)
            this.$gtag.event('farm: browser-enter')
        } else {
            this.$ga.event('browser', 'unknown', JSON.stringify(browser), 1)
            this.$gtag.event('farm: unknown-enter')
        }
        if (this.IS_SIGNED_IN) {
            this.fetchData()
        }
    }

    private fetchData() {
        return
        fetch('http://ip-api.com/json').then(response => {
            return response.json()
        }).then(json => {
            this.userInfo.IP_INFO = json
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(this.showPosition, this.postUserInfo)
            } else {
                this.postUserInfo()
            }
        }).catch(err => {
            this.userInfo.onerror = true
            this.userInfo.IP_INFO = err
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(this.showPosition)
            } else {
                this.postUserInfo()
            }
        })
    }

    private showPosition(position: any) {
        this.userInfo.GEO_INFO = position
        this.postUserInfo()
    }

    private postUserInfo() {
        const addressPoint = this.userInfo.onerror ? null : {
            type: 'Point',
            coordinates: [this.userInfo.IP_INFO.lon, this.userInfo.IP_INFO.lat]
        }
        let locationPoint = null
        if (this.userInfo.GEO_INFO !== null) {
            if (this.userInfo.GEO_INFO.coords) {
                if (this.userInfo.GEO_INFO.coords.longitude && this.userInfo.GEO_INFO.coords.latitude) {
                    locationPoint = {
                        type: 'Point',
                        coordinates: [this.userInfo.GEO_INFO.coords.longitude, this.userInfo.GEO_INFO.coords.latitude]
                    }
                }
            }
        }
        httpPost({
            url:  `${apiUrl}/user-sign-in-history/`,
            isSecureRequest: true,
            detailResponse: true,
            body: {
                city: this.userInfo.onerror ? null : this.userInfo.IP_INFO.city,
                country: this.userInfo.onerror ? null : this.userInfo.IP_INFO.country,
                ip: this.userInfo.onerror ? null : this.userInfo.IP_INFO.query,
                geolocation: locationPoint,
                accuracy: this.userInfo.GEO_INFO ? this.userInfo.GEO_INFO.coords.accuracy : null,
                address: addressPoint,
                body: this.userInfo.IP_INFO
            },
            onSuccess: json => {
                if (json.ok) {
                    // ignore
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                // ignore
            }
        })
    }

    @Watch('$root.$i18n.locale', { immediate: true, deep: true })
    private onchangeLocal(newLang: any, oldLang: any) {
        if (newLang !== oldLang) {
            setLastLanguage(newLang)
        }
    }

    @Watch('$route', { immediate: true, deep: true })
    private onUrlChange(route: Route, old: Route) {
        this.setCurrentRoute(route)
    }
}
