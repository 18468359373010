







































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import {GetterNames} from '@/store/types'
@Component({
    components: {
    }
})
export default class LoadingModel extends Vue {
    @Getter(GetterNames.getLoadingDataNames) private getLoadingDataNames!: any
    @Getter(GetterNames.getFullBlindLoading) private getFullBlindLoading!: any
}
