











import { Component, Vue, Watch} from 'vue-property-decorator'
import { GetterNames } from '@/store/types'
import { Getter } from 'vuex-class'
import { ndviGistogram } from '@/data/gistogramData'
@Component ({
    components: {
    }
})
export default class FertilizerResult extends Vue {
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: any
    @Getter(GetterNames.getFertilizerResult) private getFertilizerResult!: any
    @Getter(GetterNames.getFarmFromRoute) private getFarmFromRoute!: any
    @Getter(GetterNames.getSubfieldIndicatorData) private getSubfieldIndicatorData!: any
    private ndviCategories = ndviGistogram
    private textScale = 1
    private boundaries: any = []
    private mounted() {
        this.getBoundaries()
    }

    @Watch('getFarmFromRoute')
    private onloadFarm() {
        this.getBoundaries()
    }

    @Watch('currentRoute')
    private getBoundaries() {
        if (this.getFarmFromRoute) {
            const fieldId = this.currentRoute.params.subfieldId
            if (!fieldId) return
            const field = this.getFarmFromRoute.fields.find((x: any) => x.id.toString() === fieldId)
            let Xmin = Number.MAX_SAFE_INTEGER
            let Xmax = Number.MIN_SAFE_INTEGER
            let Ymin = Number.MAX_SAFE_INTEGER
            let Ymax = Number.MIN_SAFE_INTEGER
            for (const arr of field.geom.coordinates[0][0]) {
                Xmax = arr[0] > Xmax ? arr[0] : Xmax
                Xmin = arr[0] < Xmin ? arr[0] : Xmin
                Ymax = arr[1] > Ymax ? arr[1] : Ymax
                Ymin = arr[1] < Ymin ? arr[1] : Ymin
            }
            this.boundaries = [Xmin, Ymin, Xmax, Ymax]
        }
    }
}
