























































































































import { Component, Vue, Watch, Emit } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import {GetterNames, MutationNames } from '@/store/types'
import Chart from 'chart.js/auto'
import { egisticLogo, pdfWaterMark } from '@/data/pdfImageData'
import { makePDF, PdfActions } from '@/utils/services'

@Component
export default class TelematicsGraph extends Vue {
    @Getter(GetterNames.getTelematicsInfo) private getTelematicsInfo!: any
    @Mutation(MutationNames.setTelematicsInfo) private setTelematicsInfo!: any
    private egisticLogo: any = egisticLogo
    private pdfWaterMark: any = pdfWaterMark
    private fuelChart: any = null
    private selectTractorOpen = false
    private chartControl: any = {
        fuel: true,
        speed: true,
        fullData: true,
        index: 0
    }
    private tractors: any = {
        origin: [],
        converted: [],
        selected: null,
        selected_name: ''
    }

    private months: any = ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек']

    get getBackArrowView() {
        if (this.chartControl.fullData) {
            return true
        } else if (this.chartControl.index === 0) {
            return true
        } else return false
    }

    get getForwardArrowView() {
        if (this.chartControl.fullData) {
            return true
        } else if (this.getTelematicsInfo.autoTracking.mainTractorID !== -1) {
            const tractor = this.getTelematicsInfo.tractorsData.find((x: any) => x.tractorID === this.getTelematicsInfo.autoTracking.mainTractorID)
            if (tractor) {
                if (tractor.speedData.length >= (this.chartControl.index + 1) * 500) {
                    return false
                }
            }
        }
        return true
    }

    get getMainTractorData() {
        if (this.getTelematicsInfo.autoTracking.mainTractorID !== -1) {
            const tractor = this.getTelematicsInfo.tractorsData.find((x: any) => x.tractorID === this.getTelematicsInfo.autoTracking.mainTractorID)
            if (tractor) {
                return tractor.speedData
            }
        }
        return []
    }

    @Watch('getMainTractorData')
    private onchangeTractorData(val: any, old: any) {
        this.updateData()
    }

    private changeFuelView() {
        this.chartControl.fuel = !this.chartControl.fuel
        this.updateData()
    }

    private changeSpeedView() {
        this.chartControl.speed = !this.chartControl.speed
        this.updateData()
    }

    private changeFullData(val: any) {
        if (val) {
            this.chartControl.fullData = val
        } else {
            this.chartControl.fullData = val
            this.chartControl.index = 0
        }
        this.updateData()
    }

    private back() {
        if (!this.chartControl.fullData && this.chartControl.index !== 0) {
            this.chartControl.index -= 1
            this.updateData()
        }
    }

    private forward() {
        if (!this.chartControl.fullData) {
            if (this.getTelematicsInfo.autoTracking.mainTractorID !== -1) {
                const tractor = this.getTelematicsInfo.tractorsData.find((x: any) => x.tractorID === this.getTelematicsInfo.autoTracking.mainTractorID)
                if (tractor) {
                    if (tractor.speedData.length >= (this.chartControl.index + 1) * 500) {
                        this.chartControl.index += 1
                        this.updateData()
                    }
                }
            }
        }
    }

    private mounted() {
        // Chart.defaults.line.showLines = true
        // Chart.defaults.line.spanGaps = false
        const ctx: any = document.getElementById('fuel-chart')
        this.fuelChart = new Chart(ctx.getContext('2d'), {
            type: 'line',
            data: {
                datasets: []
            },
            options: {
                plugins: {
                    legend: {
                        display: false
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                elements: {
                    point: {
                        radius: 0
                    }
                },
                layout: {
                    padding: 0
                },
                interaction: {
                    mode: 'index',
                    intersect: false
                },
                scales: {
                    y: {
                        type: 'linear',
                        display: true,
                        position: 'left'
                    },
                    y1: {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        grid: {
                            drawOnChartArea: false // only want the grid lines for one axis to show up
                        }
                    }
                }
            }
        })
        const currentTractor = this.getTelematicsInfo.tractorsData.find((x: any) => x.tractorID === this.getTelematicsInfo.autoTracking.mainTractorID)
        this.tractors.selected_name = currentTractor ? currentTractor.complex.tractor.name : ''
        this.tractors.selected = currentTractor
        this.tractors.converted = this.getTelematicsInfo.tractorsData.filter((x: any) => x.START_DATE).map((tractor: any) => {
            return {
                id: tractor.tractorID,
                imei: tractor.tractorIMEI,
                roadColor: tractor.roadColor,
                name: tractor.complex.tractor.name
            }
        })
        this.updateData()
    }

    private onSelectTractor(tractor: any) {
        this.selectTractorOpen = false
        this.tractors.selected_name = tractor.name
        this.tractors.selected = tractor
        this.setTelematicsInfo({
            autoTracking: {
                mainTractorID: tractor.id
            }
        })
        this.updateData()
    }

    private updateData() {
        if (this.getTelematicsInfo.autoTracking.mainTractorID !== -1) {
            const tractor = this.getTelematicsInfo.tractorsData.find((x: any) => x.tractorID === this.getTelematicsInfo.autoTracking.mainTractorID)
            if (tractor) {
                let speedData = []
                let fuelData = []
                if (this.chartControl.fullData) {
                    speedData = tractor.speedData
                    fuelData = tractor.fuelData
                } else {
                    if (tractor.speedData.length >= this.chartControl.index * 500) {
                        speedData = tractor.speedData.slice(this.chartControl.index * 500, (this.chartControl.index + 1) * 500)
                        fuelData = tractor.fuelData.slice(this.chartControl.index * 500, (this.chartControl.index + 1) * 500)
                    } else {
                        this.chartControl.index = 0
                        speedData = tractor.speedData.slice(this.chartControl.index * 500, (this.chartControl.index + 1) * 500)
                        fuelData = tractor.fuelData.slice(this.chartControl.index * 500, (this.chartControl.index + 1) * 500)
                    }
                }
                const date = speedData.map((x: any) =>
                    `${new Date(x.t).getDate()} ${this.months[new Date(x.t).getMonth()]}` + ' ' +
                    `${new Date(x.t).getHours().toString().length > 1 ? new Date(x.t).getHours() : `0${new Date(x.t).getHours()}` }:${new Date(x.t).getMinutes().toString().length > 1 ? new Date(x.t).getMinutes() : `0${new Date(x.t).getMinutes()}` }:${new Date(x.t).getSeconds().toString().length > 1 ? new Date(x.t).getSeconds() : `0${new Date(x.t).getSeconds()}` }`
                )
                const newData: any = {
                    labels: date,
                    datasets: []
                }
                if (this.chartControl.speed) {
                    newData.datasets.push({
                        label: 'Скорость (км/ч)',
                        data: speedData.map((x: any) => x.y),
                        fill: false,
                        borderColor: '#FFC700',
                        backgroundColor: '#FFC700',
                        pointHitRadius: 5,
                        yAxisID: 'y1' // 'right'
                    })
                }
                // if (this.chartControl.fuel) {
                //     newData.datasets.push({
                //         label: 'Уровень топлива (л)',
                //         data: fuelData.map((x: any) => x.y),
                //         fill: false,
                //         borderColor: '#4B970F',
                //         backgroundColor: '#4B970F',
                //         pointHitRadius: 5,
                //         yAxisID: 'y' // 'left'
                //     })
                // }
                // this.fuelChart.data.datasets = data
                this.fuelChart.data = newData
                this.fuelChart.update()
            }
        }
    }

    private printPdf() {
        if (this.getTelematicsInfo.autoTracking.mainTractorID !== -1) {
            const tractor = this.getTelematicsInfo.tractorsData.find((x: any) => x.tractorID === this.getTelematicsInfo.autoTracking.mainTractorID)
            if (tractor) {
                if (tractor.data.length > 0) {
                    const startDate = `${tractor.START_DATE.substring(8, 10)}.${tractor.START_DATE.substring(5, 7)}.${tractor.START_DATE.substring(0, 4)}`
                    const endDate = `${tractor.LAST_DATE.substring(8, 10)}.${tractor.LAST_DATE.substring(5, 7)}.${tractor.LAST_DATE.substring(0, 4)}`
                    const canvas = document.getElementById('fuel-chart') as HTMLCanvasElement
                    const docDefinition = {
                        pageSize: {
                            width: 842,
                            height: 595
                        },
                        pageMargins: [16, 16, 16, 16],
                        background: [
                            {
                                image: this.pdfWaterMark,
                                margin: [400, 0, 0, 0]
                            }
                        ],
                        content: [
                            {
                                image: this.egisticLogo,
                                margin: [359, 24, 0, 0],
                                width: 91.14,
                                height: 30
                            },
                            {
                                text: `Отчёт по работе техники за ${startDate} - ${endDate}`,
                                fontSize: 16,
                                bold: true,
                                margin: [0, 24, 0, 0],
                                alignment: 'center'
                            },
                            {
                                layout: 'noBorders',
                                margin: [24, 24, 24, 0],
                                table: {
                                    widths: ['auto', '*', 'auto', '*'],
                                    body: [
                                        [
                                            {text: 'Техника:', bold: true, fontSize: 14, color: '#1c1c1c'},
                                            {text: `${tractor.complex.tractor ? tractor.complex.tractor.name : 'не выбран'}`, fontSize: 14, color: '#1c1c1c'},
                                            {text: `Госномер:`, bold: true, fontSize: 14, color: '#1c1c1c'},
                                            {text: tractor.tractorUID, fontSize: 14, color: '#1c1c1c'}
                                        ],
                                        [
                                            {text: 'Агрегат:', bold: true, fontSize: 14, color: '#1c1c1c'},
                                            {text: `${tractor.complex.trailer ? tractor.complex.trailer.name : 'не выбран'}`, fontSize: 14, color: '#1c1c1c'},
                                            {text: 'Водитель:', bold: true, fontSize: 14, color: '#1c1c1c'},
                                            {text: `${tractor.complex.driver || tractor.complex.full_name ? tractor.complex.full_name : 'не выбран'}`, fontSize: 14, color: '#1c1c1c'}
                                        ]
                                    ]
                                }
                            },
                            {
                                margin: [0, 24, 0, 0],
                                image: canvas.toDataURL("image/png"),
                                width: 770,
                                alignment: 'center'
                            }
                        ]
                    }
                    makePDF(PdfActions.PRINT, docDefinition)
                }
            }
        }
    }

    private close() {
        this.$emit('close')
    }
}
