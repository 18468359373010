























































































































































import { Component, Vue, Emit, Watch } from 'vue-property-decorator'
import { httpGet, httpPost, httpPostFormData, httpPutFormData } from '@/util/http'
import { apiUrl, apiUrl3, baseUrl } from '@/domain/constants'
import { GetterNames, MutationNames } from '@/store/types'
import { Getter, Mutation } from 'vuex-class'
import i18n from '@/i18n'
@Component({
  components: {}
})
export default class NewCarModel extends Vue {
    @Getter(GetterNames.getLoadingDataNames) private getLoadingDataNames!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    @Mutation(MutationNames.setPhotosData) private setPhotosData!: any
    private isShown = false
    private car: any = {
        class: {
            origin: [],
            converted: [],
            selected: '',
            onerror: ''
        },
        model: {
            converted: [],
            selected: '',
            onerror: ''
        },
        category: {
            id: 0,
            has_coverage: false,
            origin: [],
            converted: [],
            selected: '',
            onerror: ''
        },
        uid: {
            selected: '',
            onerror: ''
        },
        imei: {
            selected: '',
            onerror: ''
        },
        capacity: {
            selected: '',
            onerror: ''
        },
        coverage: {
            selected: '',
            onerror: ''
        },
        image: {
            file: null,
            url: null
        }
    }
    private isUpdate = false
    private carForUpdate: any = null

    private isShowCalibration: boolean = false

    private calibrationss: any = [
        {
            obj: {},
            objParams: [{ key: '', value: ''}],
            paramsName: [],
            selectedName: ''
        }
    ]

    private paramsName: any = []

    private calibrationsForRost: any = [
        {
            param_name: '',
            calibration_data: null as any
        }
    ]

    private protocolForPost: any = 1

    private idForDeletedCalibrations: any = null
    private isDeletedCalibrations: boolean = false

    @Watch('car.imei.selected')
    private watchImei(val: any) {
        this.getCalebarations()
    }

    private showFirstBtnAdd() {
        if (this.isShowCalibration === false) {
            return true
        }
    }

    private getCalebarations() {
        httpPost({
            url: `${apiUrl}/yields/get/param_name/`,
            isSecureRequest: true,
            detailResponse: true,
            body: {
                imei: `${this.car.imei.selected}`
            },
            onSuccess: json => {
                if (json.ok) {
                    this.protocolForPost = json.json.protocol || 1
                    this.paramsName = json.json.param_names
                }

            },
            onError: error => {
                console.log(error)
            }
        })
    }

    private showCalibrationForm() {
        this.isShowCalibration = true
        if (this.calibrationss.length === 0) {
            this.calibrationss.push({
                obj: {},
                objParams: [{ key: '', value: ''}],
                paramsName: [],
                selectedName: ''
            })
        }
        if (this.calibrationsForRost.length === 0) {
            this.calibrationsForRost.push({
                param_name: '',
                calibration_data: null as any
            })
        }
    }

    private addNewObjParam(index: number) {
        this.calibrationss[index].objParams.push({key: '', value: ''})
    }

    private deleteObjParams(index: number, paramsIndex: number) {
        this.calibrationss[index].objParams.splice(paramsIndex, 1)
    }

    private addNewCalibrations() {
        this.calibrationss.push({
            obj: {},
            objParams: [
                {
                    key: '',
                    value: ''
                }
            ],
            protocol: -1,
            paramsName: [],
            selectedName: ''
        })
        this.calibrationsForRost.push({param_name: '', calibration_data: null as any})
        this.getCalebarations()
    }

    private deleteCalibrations(index: number) {
        if (this.calibrationss.length === 1) return this.isShowCalibration = false
        this.calibrationss.splice(index, 1)
        this.calibrationsForRost.splice(index, 1)
    }

    private deleteCalibrationsForUpdate(item: any, index: any) {
        this.isDeletedCalibrations = true
        this.idForDeletedCalibrations = item.selectedName
        this.calibrationss.splice(index, 1)
        this.calibrationsForRost.splice(index, 1)
    }

    private onSelectParams(item: any, index: number) {
        this.calibrationss[index].selectedName = item
    }

    private onSelectCarClass(item: any) {
        this.car.class.selected = item
        const carClass = this.car.class.origin.find((x: any) => x.name === item)
        if (carClass) {
            this.car.model.converted = carClass.tractor_name.map((x: any) => x.name)
        } else {
            this.car.model.converted = []
        }
    }

    private onSelectCarModel(item: any) {
        this.car.model.selected = item
    }

    private onSelectCaCategory(item: any) {
        const category = this.car.category.origin.find((x: any) => x.name === item)
        if (category) {
            this.car.category.selected = item
            this.car.category.id = category.id
            this.car.category.has_coverage = category.has_coverage
            this.car.class.origin = category.models
            this.car.class.converted = category.models.map((x: any) => x.name)
        }
    }

    private getImageUrl(event: any) {
        for (const file of event.target.files) {
            this.car.image.file = file
            this.car.image.url = URL.createObjectURL(file)
        }
        event.target.value = ''
    }

    private deleteImage() {
        this.car.image.file = null
        this.car.image.url = null
    }

    private openImage() {
        if (this.car.image.url) {
            const images = []
            if (this.car.image.file) {
                images.push(this.car.image.file)
            } else {
                images.push({
                    created_at: '',
                    full_name: '',
                    id: 1,
                    image: this.car.image.url,
                    is_mobile: false
                })
            }
            this.setPhotosData({currentIndex: 0, data: images})
        }
    }

    private getCarData(carId: any) {
        httpGet({
            url:  `${apiUrl}/yields/tractor/${carId}`,
            isSecureRequest: true,
            onSuccess: json => {
                const car = json
                this.car = {
                    class: {
                        origin: [],
                        converted: [],
                        selected: car.class_name,
                        onerror: ''
                    },
                    model: {
                        converted: [],
                        selected: car.model_name,
                        onerror: ''
                    },
                    category: {
                        id: car.category,
                        has_coverage: car.coverage ? true : false,
                        origin: [],
                        converted: [],
                        selected: '',
                        onerror: ''
                    },
                    uid: {
                        selected: car.uid,
                        onerror: ''
                    },
                    imei: {
                        selected: car.imei ? car.imei : '',
                        onerror: ''
                    },
                    capacity: {
                        selected: car.capacity ? car.capacity.toString() : '',
                        onerror: ''
                    },
                    coverage: {
                        selected: car.coverage ? String(car.coverage) : '',
                        onerror: ''
                    },
                    image: {
                        file: null,
                        url: car.image ? `${baseUrl}${car.image}` : null
                    }
                }
                if (car.calibrations) {
                    this.isShowCalibration = true
                    car.calibrations.reverse().forEach((item: any, index: any) => {
                        const arr: any = []
                        Object.entries(car.calibrations[index].calibration_data).map(([keys, values]: any) => {
                            return arr.push({key: keys, value: values})
                        })
                        this.calibrationss[index].objParams = arr
                        this.calibrationss[index].selectedName = car.calibrations[index].param_name
                        this.calibrationsForRost[index].id = car.calibrations[index].id
                        this.calibrationss.push({
                            obj: {},
                            objParams: [{ key: '', value: ''}],
                            paramsName: [],
                            selectedName: ''
                        })
                        this.calibrationsForRost.push({
                            param_name: '',
                            calibration_data: null as any
                        })
                    })
                    this.calibrationss.pop()
                    this.calibrationsForRost.pop()
                }
                this.getCarCategories()
            },
            onError: error => {
                console.log(error)
            }
        })
    }

    private update(car: any) {
        this.carForUpdate = car
        this.isShown = true
        this.isUpdate = true
        this.getCarData(car.id)

    }

    private show() {
        this.isShown = true
        this.isUpdate = false
        this.car = {
            class: {
                origin: [],
                converted: [],
                selected: '',
                onerror: ''
            },
            model: {
                converted: [],
                selected: '',
                onerror: ''
            },
            category: {
                id: 0,
                has_coverage: false,
                origin: [],
                converted: [],
                selected: '',
                onerror: ''
            },
            uid: {
                selected: '',
                onerror: ''
            },
            imei: {
                selected: '',
                onerror: ''
            },
            capacity: {
                selected: '',
                onerror: ''
            },
            coverage: {
                selected: '',
                onerror: ''
            },
            image: {
                file: null,
                url: null
            }
        }
        if (this.car.imei.selected) {
            return this.getCarCategories()
        }
        this.getCarCategories()
    }

    private getCarCategories() {
        this.setLoadingDataNames('newCarGetCategories')
        httpGet({
            url:  `${apiUrl}/yields/tractor/data/v2/`,
            isSecureRequest: true,
            onSuccess: json => {
                this.car.category.origin = json.category
                this.car.category.converted = json.category.map((x: any) => x.name)
                this.car.model.origin = json.model
                if (this.car.category.id) {
                    const category = json.category.find((x: any) => x.id === this.car.category.id)
                    if (category) {
                        this.car.category.selected = category.name
                        this.car.category.has_coverage = category.has_coverage
                        this.car.class.origin = category.models
                        this.car.class.converted = category.models.map((x: any) => x.name)
                        if (this.car.class.selected) {
                            const carClass = this.car.class.origin.find((x: any) => x.name === this.car.class.selected)
                            if (carClass) {
                                this.car.model.converted = carClass.tractor_name.map((x: any) => x.name)
                            }
                        }
                    }
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('newCarGetCategories')
            }
        })
    }

    private add2000Cars(index: number) {
        if (index <= 500) {
            const category = this.car.category.origin[Math.floor(Math.random() * this.car.category.origin.length)]
            const model: any = category.models[Math.floor(Math.random() * category.models.length)]
            this.addCar(index, model.name, `model ${index}`, `A-${index}`, `${category.id}`)
        }
    }

    private addCar(index: number, className: string, modelName: string, uid: string, category: string) {
        const formData = new FormData()
        formData.append('class_name', className)
        formData.append('model_name', modelName)
        formData.append('capacity', '500')
        formData.append('imei', this.car.imei.selected)
        formData.append('uid', uid)
        formData.append('category', category)
        httpPostFormData({
            url: apiUrl + '/yields/tractor/',
            isSecureRequest: true,
            detailResponse: true,
            body: formData,
            onSuccess: json => {
                if (json.ok) {
                    console.log(index)
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.add2000Cars(index + 1)
            }
        })
    }

    private hasDuplicates(arr: any) {
        return arr.some((x: any) => arr.indexOf(x) !== arr.lastIndexOf(x))
    }

    private normalizeData() {
        // уникальность X
        this.calibrationss.forEach((x: any) => {
            const arrs = x.objParams.map((y: any) => {
                return y.key
            })
            if (this.hasDuplicates(arrs)) {
                Vue.alert('У Тарировки значение Х должно быть уникальным', 'Ошибка', 'warning')
                return
            }

            // Проверка на наличия param_name
            if (x.selectedName === '') {
                Vue.alert('Выберите Параметр', 'Ошибка', 'warning')
                return
            }

            x.objParams.forEach((y: any) => {
                if (y.key !== '' && y.value === '' || y.key === '' && y.value !== '') {
                    Vue.alert('Введите значение по X, Y', 'Ошибка', 'warning')
                    return
                }
                // { key: '', value: ''}
                x.obj[y.key] = y.value
            })
        })

        // уникальность param_name
        const arr: any = []
        this.calibrationss.forEach((x: any) => {
            arr.push(x.selectedName)
        })
        if (this.hasDuplicates(arr)) {
            Vue.alert('У Тарировки должны быть уникальные параметры', 'Ошибка', 'warning')
            return
        }

        this.normalizeDataForPost()
    }

    private normalizeDataForPost() {
        // For Post
        this.calibrationss.forEach((data: any, index: any) => {
            this.calibrationsForRost[index].calibration_data = this.calibrationss[index].obj
            this.calibrationsForRost[index].param_name = this.calibrationss[index].selectedName
        })
        this.funcForDeletedCalibrations()
    }

    private funcForDeletedCalibrations() {
        if (this.isDeletedCalibrations) {
            const deleteObj = this.calibrationsForRost.find((x: any) => x.param_name === this.idForDeletedCalibrations)
            const deletedObjIndex = this.calibrationsForRost.indexOf(deleteObj)
            if (deletedObjIndex !== -1) {
                this.calibrationsForRost.splice(deletedObjIndex, 1)
            }
            this.isDeletedCalibrations = false
        }
    }

    private addNewCar() {
        if (this.isShowCalibration) {
            this.normalizeData()
        }
        if (this.checkForErrors()) return
        const formData = new FormData()
        formData.append('class_name', this.car.class.selected)
        formData.append('model_name', this.car.model.selected)
        formData.append('capacity', this.car.capacity.selected)
        formData.append('imei', this.car.imei.selected)
        formData.append('uid', this.car.uid.selected)
        formData.append('category', this.car.category.id.toString())
        if (this.isShowCalibration) {
            formData.append('calibrations', JSON.stringify(this.calibrationsForRost))
            formData.append('protocol', this.protocolForPost)
        }
        if (this.car.image.file) {
            formData.append('image', this.car.image.file)
        }
        if (this.car.category.has_coverage || this.car.category.has_coverage === false) {
            formData.append('coverage', this.car.coverage.selected)
        }
        this.setLoadingDataNames('newCarAddCar')
        httpPostFormData({
            url: apiUrl + '/yields/tractor/',
            isSecureRequest: true,
            detailResponse: true,
            body: formData,
            onSuccess: json => {
                if (json.ok) {
                    this.$alert(i18n.t('messages.success.car_added') as any, 'Сохранено', 'success')
                    this.reload()
                    this.hide()
                } else if (json.status === 400) {
                    if (json.json.uid) {
                        this.car.uid.onerror = json.json.uid[0]
                    }
                    if (json.json.imei) {
                        this.car.imei.onerror = json.json.imei[0]
                    }
                    if (json.json.non_field_errors) {
                        this.$alert(json.json.non_field_errors, 'Ошибка', 'error')
                    }
                } else {
                    this.$alert('Ошибка сервера', 'Ошибка', 'error')
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('newCarAddCar')
            }
        })
    }

    private checkForErrors() {
        let counter = 0
        if (this.car.class.selected === '') {
            this.car.class.onerror = i18n.t('messages.instructions.machine_class')
            counter++
        }
        if (this.car.model.selected === '') {
            this.car.model.onerror = i18n.t('messages.instructions.machine_model')
            counter++
        }
        if (this.car.uid.selected === '') {
           this.car.uid.onerror = i18n.t('messages.instructions.machine_state_num')
           counter++
        }
        if (this.car.category.id === 0) {
            this.car.category.onerror = i18n.t('messages.selections.category')
            counter++
        }
        if (this.car.category.has_coverage) {
            if (this.car.coverage.selected === '') {
                this.car.coverage.onerror = i18n.t('messages.instructions.width')
                counter++
            } else if (isNaN(this.car.coverage.selected)) {
                this.car.coverage.onerror = 'Введите число'
                counter++
            }
        }
        if (isNaN(this.car.capacity.selected)) {
            this.car.capacity.onerror = 'Введите число'
            counter++
        }
        return counter > 0
    }

    private updateCar() {
        if (this.isShowCalibration) {
            this.normalizeData()
        }
        if (this.checkForErrors()) return
        const formData = new FormData()
        formData.append('class_name', this.car.class.selected)
        formData.append('model_name', this.car.model.selected)
        formData.append('capacity', this.car.capacity.selected)
        formData.append('imei', this.car.imei.selected)
        formData.append('uid', this.car.uid.selected)
        formData.append('category', this.car.category.id.toString())
        if (this.isShowCalibration) {
            formData.append('calibrations', JSON.stringify(this.calibrationsForRost))
            formData.append('protocol', this.protocolForPost)
        }
        if (this.car.image.file) {
            formData.append('image', this.car.image.file)
        }
        if (this.car.image.url === null) {
            formData.append('is_image_deleted', 'true')
        }
        if (this.car.category.has_coverage || this.car.category.has_coverage === false) {
            formData.append('coverage', this.car.coverage.selected)
        }
        this.setLoadingDataNames('newCarUpdateCar')
        httpPutFormData({
            url: `${apiUrl}/yields/tractor/${this.carForUpdate.id}`,
            isSecureRequest: true,
            detailResponse: true,
            body: formData,
            onSuccess: json => {
                if (json.ok) {
                    this.$alert(i18n.t('messages.success.car_updated') as any, 'Сохранено', 'success')
                    this.reload()
                    this.hide()
                } else if (json.status === 400) {
                    if (json.json.uid) {
                        this.car.uid.onerror = json.json.uid
                    }
                    if (json.json.non_field_errors) {
                        this.$alert(json.json.non_field_errors, 'Ошибка', 'error')
                    }
                } else {
                    this.$alert('Ошибка сервера', 'Ошибка', 'error')
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('newCarUpdateCar')
            }
        })
    }

    @Emit('reload')
    private reload() {
        return {}
    }

    private hide() {
        this.isShown = false
        this.isUpdate = false
        this.isShowCalibration = false
        this.calibrationss = [
            {
                obj: {},
                objParams: [{key: '', value: ''}],
                protocol: -1,
                paramsName: '',
                selectedName: ''
            }
        ]
        this.calibrationsForRost = [
            {
                param_name: '',
                calibration_data: null as any
            }
        ]
    }
}
