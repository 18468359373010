














































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { GetterNames, MutationNames } from '@/store/types'
import { Getter, Mutation } from 'vuex-class'
import { httpGet } from '@/util/http'
import { apiUrl } from '@/domain/constants'
import Sideboard from '@/components/Sideboard.vue'
import HeaderWithPagination from '@/components/HeaderWithPagination.vue'
import { ndviGistogram } from '@/data/gistogramData'
import Chart from 'chart.js'
import i18n from '@/i18n'
import {countBy} from 'lodash'
@Component({
    components: {
        Sideboard,
        HeaderWithPagination
    }
})
export default class FieldYield extends Vue {
    @Getter(GetterNames.getFarmFromRoute) private getFarmFromRoute!: any
    @Getter(GetterNames.getFieldFromRoute) private getFieldFromRoute!: any
    @Getter(GetterNames.getYieldLayer) private getYieldLayer!: any

    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    @Mutation(MutationNames.setYieldLayer) private setYieldLayer!: any
    private type = 1
    private YieldChart: any
    private fieldPredictionError: any = false
    private yieldData: any = []
    private yields: any = {
        origin: [],
        converted: [],
        selected_name: ''
    }
    private colors: any = ndviGistogram

    private getHoverValue(index: any) {
        if (this.getYieldLayer.selected) {
            const max = this.getYieldLayer.selected.yield_range.max
            const min = this.getYieldLayer.selected.yield_range.min
            if (index === 0) return min.toFixed(2)
            else {
                return ((max - min) * (index + 1) / 20 + min).toFixed(2)
            }
        } else return ''
    }

    private getStartValue() {
        if (this.getYieldLayer.selected) {
            return `${this.getYieldLayer.selected.yield_range.min.toFixed(2)} ${i18n.t('general.units.centner_he')}`
        } else return '0'
    }

    private getCenterValue() {
        if (this.getYieldLayer.selected) {
            const max = this.getYieldLayer.selected.yield_range.max
            const min = this.getYieldLayer.selected.yield_range.min
            return `${((max + min) / 2).toFixed(2)} ${i18n.t('general.units.centner_he')}`
        } else return '0.5'
    }

    private getEndValue() {
        if (this.getYieldLayer.selected) {
            return `${this.getYieldLayer.selected.yield_range.max.toFixed(2)} ${i18n.t('general.units.centner_he')}`
        } else return '1'
    }

    private onchooseDate(item: any) {
        const selectedYield = this.yields.origin.find((x: any) =>
            `${x.t.substring(8, 10)}.${x.t.substring(5, 7)}.${x.t.substring(0, 4)}` === item)
        if (selectedYield) {
            this.yields.selected_name = item
            this.setYieldLayer({selected: selectedYield})
        }
    }

    private mounted() {
        this.setYieldLayer({layer: 'predicted'})
        this.drawGraph()
        this.getYieldData()
    }

    private checkErrorsForFieldPrediction(data: any) {
        if (data.culture) this.fieldPredictionError = data.culture
        if (data.count) this.fieldPredictionError = `Осталось снимков для прогноза урожайности: ${data.count}`
        return false
    }

    @Watch('$root.$i18n.locale')
    private drawGraph() {
        Chart.defaults.line.showLines = true
        Chart.defaults.line.spanGaps = true
        const ctx: any = document.getElementById('yield-chart')
        this.YieldChart = new Chart(ctx.getContext('2d'), {
            type: 'line',
            data: {
                datasets: [{label: 'yield', data: this.yieldData, fill: true, fillColor: '#4B970F',  borderColor: '#4B970F'}]
            },
            options: {
                legend: false,
                points: true,
                discrete: false,
                scales: {
                    yAxes: [
                        {
                            id: 'y-axis-1',
                            position: 'left',
                            scaleLabel: {
                                display: true,
                                labelString: i18n.t('general.units.centner_he'),
                                fontSize: 16
                            },
                            ticks: {
                                beginAtZero: true
                            }
                        }
                    ],
                    xAxes: [
                        {
                            offset: true,
                            type: 'time',
                            time: {
                                unit: 'day',
                                tooltipFormat: 'DD.MM.YY'
                            },
                            ticks: {
                                fontColor: '#000000',
                                autoSkip: true,
                                maxTicksLimit: 4,
                                maxRotation: 0,
                                minRotation: 0,
                                callback(label: any) {
                                    if (i18n.locale === 'en') {
                                        return label
                                    }
                                    const EnRuArr = [{en: 'Jan', ru: 'Янв'}, {en: 'Feb', ru: 'Фев'},
                                                {en: 'Mar', ru: 'Мар'}, {en: 'Apr', ru: 'Апр'},
                                                {en: 'May', ru: 'Май'}, {en: 'Jun', ru: 'Июн'},
                                                {en: 'Jul', ru: 'Июл'}, {en: 'Aug', ru: 'Авг'},
                                                {en: 'Sep', ru: 'Сен'}, {en: 'Oct', ru: 'Окт'},
                                                {en: 'Nov', ru: 'Ноя'}, {en: 'Dec', ru: 'Дек'}]
                                    if (typeof(label) === 'string') {
                                        const month = EnRuArr.find((x: any) => x.en === label.substring(0, 3))
                                        if (month) {
                                            return month.ru + label.substring(3)
                                        }
                                    }
                                    return label
                                }
                            }
                    }]
                },
                tooltips: {
                    titleFontSize: 0,
                    custom(tooltip: any) {
                        tooltip.backgroundColor = '#FFFFFF'
                        tooltip.borderWidth = 1
                        tooltip.displayColors = false
                        tooltip.borderColor = '#4B970F'
                    },
                    callbacks: {
                        title: () => { return },
                        label(tooltipItem: any, chart: any) {
                            return `${parseFloat(tooltipItem.value).toFixed(2)} ${i18n.t('general.units.centner_he')}`
                        },
                        labelTextColor(tooltipItem: any, chart: any) {
                            const index = tooltipItem.datasetIndex
                            if (chart.data.datasets[index].label === 'yield') {
                                return chart.data.datasets[index].borderColor
                            }
                        }
                    }
                }
            }
        })
    }

    @Watch('getFieldFromRoute')
    private getYieldData() {
        this.yields.origin = []
        this.yields.converted = []
        this.yields.selected_name = ''
        this.yieldData = []
        this.setYieldLayer({selected: null})
        this.setLoadingDataNames('FieldYieldGetYieldData')
        httpGet({
            url:  `${apiUrl}/yields/get/yield_graph/${this.$route.params.subfieldId}/`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                if (json.ok) {
                    this.checkErrorsForFieldPrediction(json.json)
                    if (json.json.predictions && json.json.predictions.length > 0) {
                        const predictions = json.json.predictions.reverse()
                        this.yields.origin = predictions
                        this.yields.converted = predictions.map((x: any) => {
                            return `${x.t.substring(8, 10)}.${x.t.substring(5, 7)}.${x.t.substring(0, 4)}`
                        })
                        const y = predictions[predictions.length - 1]
                        this.setYieldLayer({selected: y})
                        this.yields.selected_name = `${y.t.substring(8, 10)}.${y.t.substring(5, 7)}.${y.t.substring(0, 4)}`
                        this.yieldData = predictions.map((x: any) => {
                            return {
                                t: x.t,
                                y: x.y
                            }
                        })
                    } else {
                        this.setYieldLayer({selectedUrl: ''})
                    }
                    this.YieldChart.data.datasets = [{label: 'yield', data: this.yieldData, fill: true, fillColor: '#4B970F',  borderColor: '#4B970F'}]
                    this.YieldChart.update()
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('FieldYieldGetYieldData')
            }
        })
    }

    private toggleLayer(val: any) {
        if (this.getYieldLayer.layer === val) {
            this.setYieldLayer({layer: ''})
        } else {
            this.setYieldLayer({layer: val})
        }
    }

    private openFarmSubfieldBoard() {
        this.$router.push(`/cadastres/farm/${this.$route.params.farmId}/subfields/${this.$route.params.subfieldId}`)
    }

    private openPrevField() {
        if (this.getFarmFromRoute) {
            const prevFieldId = this.getFarmFromRoute.getPrevFieldId(this.$route.params.subfieldId)
            if (prevFieldId !== -1) {
                this.$router.push(`/cadastres/farm/${this.getFarmFromRoute.id}/subfields/${prevFieldId}/yield`)
            }
        }
    }

    private openNextField() {
        if (this.getFarmFromRoute) {
            const nextFieldId = this.getFarmFromRoute.getNextFieldId(this.$route.params.subfieldId)
            if (nextFieldId !== -1) {
                this.$router.push(`/cadastres/farm/${this.getFarmFromRoute.id}/subfields/${nextFieldId}/yield`)
            }
        }
    }
}
