import HomeView from '@/views/MainPage/HomeView/Index.vue'
import CadastresBoard from '@/views/MainPage/HomeView/Sideboards/CadastresBoard.vue'
import WorkspaceBoard from '@/views/MainPage/HomeView/Sideboards/WorkspaceBoard/WorkspaceView.vue'
import MembersBoard from '@/views/MainPage/HomeView/Sideboards/MembersBoard/MembersView.vue'

import profileRoutes from '@/routes/homeView/profile/router'
import farmViewRoutes from '@/routes/homeView/farmView/router'
import fieldViewRoutes from '@/routes/homeView/fieldView/router'
import generalRoutes from '@/routes/homeView/general/router'
import carparkRoutes from '@/routes/homeView/carpark/router'
import storageRoutes from '@/routes/homeView/storage/router'
import notificationsRoutes from '@/routes/homeView/notifications/router'
import CadastreInfo from '@/views/MainPage/HomeView/Sideboards/FarmBoard/CadastreInfo/CadastreInfo.vue'

const routes = [
    {
        path: '',
        name: 'home',
        component: HomeView,
        children: [
            {
                path: 'cadastres',
                name: 'cadastresList',
                component: CadastresBoard
            },
            {
                path: 'workspace',
                name: 'workspace',
                component: WorkspaceBoard
            },
            {
                path: 'members',
                name: 'members',
                component: MembersBoard
            },
            {
                path: 'cadastres/farm/:farmId/cadastre/:cadastreId',
                name: 'cadastreinfo',
                component: CadastreInfo
            },
            ...profileRoutes,
            ...farmViewRoutes,
            ...fieldViewRoutes,
            ...generalRoutes,
            ...carparkRoutes,
            ...storageRoutes,
            ...notificationsRoutes
        ]
    }
]

export default routes