export default {
    cultures: {
        crops: {
            title: "Астық",
            1: {
                title: "Дәнді дақылдар (күздік және жаздық)",
                wheat: {
                    title: "Бидай",
                    types: ["Қатты күздік бидай", "Жұмсақ күздік бидай", "Жұмсақ жаздық бидай", "Қатты жаздық бидай", "Тургидум бидайы"]
                },
                rye: {
                    title: "Қарабидай",
                    types: ["Күздік қарабидай"]
                },
                barley: {
                    title: "Арпа",
                    types: ["Күздік арпа", "Жаздық арпа"]
                },
                oat: {
                    title: "Сұлы",
                    types: ["Жаздық сұлы"]
                },
                triticale: {
                    title: "тритикале",
                    types: ["Күздік тритикале"]
                }
            },
            2: {
                title: "Жаздық дақылдар және басқа тұқымдастардың өсімдіктері",
                maize: {
                    title: "Жүгері",
                    types: ["Жүгері"]
                },
                millet: {
                    title: "Тары",
                    types: []
                },
                rice: {
                    title: "Күріш",
                    types: ["Күріш"]
                },
                sorghum: {
                    title: "Құмай",
                    types: ["Дәнді құмай"]
                },
                buckwheat: {
                    title: "Қарақұмық",
                    types: ["Қарақұмық"]
                }
            },
            3: {
                title: "Бұршақ дәнділер",
                peas: {
                    title: "Асбұршақ",
                    types: ["Екпе бұршақ"]
                },
                beans: {
                    title: "Малазықтық бұршақ тәрізділер",
                    types: []
                },
                lentil: {
                    title: "Жасымық",
                    types: ["Жасымық"]
                }
            }
        },
        root_vegetables: {
            title: "Тамыр жеміс, түйнек жеміс, бақша дақылдары, жемдік қырыққабат",
            1: {
                title: "Тамыр жеміс ",
                sugar_beet: {
                    title: "Қант қызылшасы",
                    types: ["Асхана қызылшасы"]
                },
                fodder_beet: {
                    title: "Жем қызылшасы",
                    types: ["Жем қызылшасы"]
                }
            },
            2: {
                title: "Жемдік қырыққабат",
                feed_cabbage: {
                    title: "Жемдік қырыққабат",
                    cabbage_white: {
                        title: "Ақбас қырыққабат"
                    },
                    cabbage_red: {
                        title: "Қызыл қырыққабат"
                    },
                    cabbage_colorful: {
                        title: "Гүлді қырыққабат"
                    },
                    cabbage_peking: {
                        title: "Пекин қырыққабаты"
                    },
                    broccoli: {
                        title: "Брокколи қырыққабаты"
                    },
                    salad: {
                        title: "Салат"
                    },
                    cabbage_savoy: {
                        title: "Савоя қырыққабаты"
                    }
                },
                kohlrabi: {
                    title: "кольраби"
                }
            },
            3: {
                title: "Түйнек жеміс",
                potato: {
                    title: "Картоп",
                    types: ["Картоп"]
                },
                artichoke: {
                    title: "Жер алмұрты"
                }
            },
            4: {
                title: "Бақша дақылдары",
                watermelon: {
                    title: "Қарбыз",
                    types: ["Қарбыз"]
                },
                melon: {
                    title: "Қауын",
                    types: ["Қауын"]
                },
                pumpkin: {
                    title: "Асқабақ",
                    types: ["Мал азықтық асқабақ", "Асқабақ"]
                }
            }
        },
        forage_crops: {
            title: "Азықтық дақылдар",
            1: {
                title: "Көпжылдық бұршақ  шөптер",
                clover: {
                    title: "Беде",
                    types: ["Қызылбас беде"]
                },
                lucerne: {
                    title: "Жоңышқа",
                    types: ["Жоңышқа"]
                },
                sainfoin: {
                    title: "эспарцет"
                },
                other: {
                    title: "басқа ..."
                }
            },
            2: {
                title: "Көпжылдық дәнді шөптер",
                timothy: {
                    title: "Атқонақ;"
                },
                fescue: {
                    title: "Еркек",
                    types: ["Су бетеге", "Борозды бетеге", "Құрақтәрізді бетеге"]
                },
                wheatgrass: {
                    title: "Еркекшөп",
                    types: ["Еркекшөп"]
                }
            },
            3: {
                title: "Жаңа көпжылдық мал азығы өсімдіктері",
                sosnovsy_hogweed: {
                    title: "Қарағай убалдырғаны"
                },
                weyrich_highlander: {
                    title: "Вейрихтің тараны "
                },
                goat_rue: {
                    title: "Шығыстың ешкі шөбі"
                }
            },
            4: {
                title: "Біржылдық бұршақ шөптер",
                vicky: {
                    title: "Сиыржоңышқасы"
                },
                seradella: {
                    title: "сераделла"
                },
                crimson_clover: {
                    title: "Нарттай беде"
                },
                other: {
                    title: "Басқа ..."
                }
            },
            5: {
                title: "Злаковые травы, біржылдық",
                sudanese_grass: {
                    title: "Судан шөбі"
                },
                panic: {
                    title: "Қонақ"
                },
                plague: {
                    title: "чумиза"
                }
            },
            6: {
                title: "Жаңа біржылдық мал азығы өсімдіктері",
                whorled_mallow: {
                    title: "Бұйра құлқайыр"
                },
                oil_radish: {
                    title: "майлы шомыр"
                }
            }
        },
        oil_bearing: {
            title: "Майлы және эфир майы алынатын дақылдар",
            1: {
                title: "Майлы",
                sunflower: {
                    title: "Күнбағар",
                    types: ["Күнбағар"]
                },
                safflower: {
                    title: "Мақсары",
                    types: ["Мақсары"]
                },
                mustard: {
                    title: "Қыша",
                    types: ["Сұр қыша", "Сарепт қыша", "Салат қышасы"]
                },
                rape: {
                    title: "рапс",
                    types: ["Жемге арналған күздік рапс", "Күздік рапс", "Жаздық рапс", "Жемге арналған жаздық рапс"]
                },
                mushroom: {
                    title: "Арыш",
                    types: ["Жаздық арыш"]
                },
                castor_oil_plant: {
                    title: "Майкене",
                    types: ["Майкене"]
                },
                sesame: {
                    title: "Күнжіт",
                    types: ["Күнжіт"]
                },
                oil_flax: {
                    title: "Майлы зығыр",
                    types: ["Майлы зығыр"]
                },
                peanut: {
                    title: "Жержаңғақ "
                },
                perilla: {
                    title: "перилла"
                },
                lallementia: {
                    title: "ляллеменция"
                }
            },
            2: {
                title: "Эфир майы алынатын дақылдар",
                coriander: {
                    title: "Күнзе"
                },
                anise: {
                    title: "Әніс"
                },
                cumin: {
                    title: "Зире"
                },
                peppermint: {
                    title: "Бұрыш жалбыз"
                },
                clay_sage: {
                    title: "Жұпарлы сәлбен"
                }
            }
        },
        spinning: {
            title: "Талшықты дақылдар",
            1: {
                title: "Талшықтыпен дәндері бар өсімдіктер",
                cotton: {
                    title: "Мақта"
                }
            },
            2: {
                title: "Талшықты өсімдіктер",
                linen: {
                    title: "Зығыр "
                },
                hemp: {
                    title: "Кендір"
                },
                kenaf: {
                    title: "Кенеп"
                }
            }
        },
        tobacco: {
            title: "Темекі және қара темекі",
            1: {
                title: "Есірткі дақылдар",
                tobacco: {
                    title: "Темекі",
                    types: ["Темекі"]
                },
                makhorka: {
                    title: "Қара темекі"
                }
            }
        }

    },
    gistogram: {
        ndvi: ["Өсімдіктерге жатпайтын объектілер", "Ашық топырақ", "нашар сирек кездесетін өсімдіктер", "Сирек өсімдіктер", "Тығыз өсімдіктер"],
        ndmi: ["Ашық топырақ", "Өсімдік дерлік жоқ", "Өсімдік өте төмен деңгейде", "Өсімдіктердің төмен деңгейі, құрғақ немесе өте төмен өсімдіктер, ылғалдық",
                "Өсімдіктердің орташа-төмен деңгейі, төмен ылғалдылық немесе өсімдіктердің төмен деңгейі, орташа ылғалдылық", "Өсімдіктердің орташа деңгейі, төмен ылғалдылық, немесе өсімдіктердің орташа-төмен деңгейі, орташа ылғалдылық",
                "Өсімдіктердің орташа деңгейі, төмен ылғалдылық, немесе Өсімдіктердің орташа-төмен деңгейі, орташа ылғалдылық", "Өсімдіктердің орташа-жоғары деңгейі, төмен ылғалдылық немесе орташа өсімдіктер, орташа ылғалдылық",
                "Өсімдіктердің жоғары деңгейі, достаточно влаги", "Өсімдіктердің жоғары деңгейі, ылғал жеткілікті", "Өсімдіктерді толық жабу немесе  батпақтанғандық (су объектілері)"]
    },
    phenophase: {
        crop_1: {
            title: "Күздік бидай",
            data: ["Көктеу", "Түптену", "Қар ерігеннен кейін (көктем)", "Түтіктену", "Басының масақтануы- гүлдену", "Астық қалыптастыру", "Сүттеніп пісу", "Тазалау алдында"]
        },
        crop_2: {
            title: "Жаздық бидай",
            data: ["Көктеу", "Үшінші парақ", "с", "Түтіктену", "Басының масақтануы, гүлдену", "Астықты байлау – сүттеніп пісу", "Сүтті-балауызды пісу", "Балауызданып пісу", "Толық пісу"]
        },
        crop_3: {
            title: "Жаздық арпа",
            data: ["Көктеу", "Түптену", "Түтіктену", "Басының масақтануы, гүлдену", "Астықты байлау – сүттеніп пісу", "Сүтті-балауызды пісу", "Балауызданып пісу", "Толық пісу"]
        },
        crop_4: {
            title: "Күздік арпа",
            data: ["Көктеу", "Түптену", "Түтіктену", "Лигула сатысы", "Басының масақтануы", "Гүлдену", "Сүттеніп пісу", "Балауызданып пісу", "Толық пісу"]
        },
        crop_5: {
            title: "Жүгері",
            data: ["Көктеу", "Бірінші парақ", "3–5 листьев", "Түтіктену", "Сабақтың өсуі", "Шашақтану", "Гүлдену сіпсебас", "Дәншік қалыптастыру", "Дәннің толысуы", "Дәннің пісуі"]
        },
        crop_6: {
            title: "Айқабақ",
            data: ["Көктеу", "Бүрлену", "Гүлдену", "Дәннің толысуы", "Балбырау"]
        },
        crop_7: {
            title: "Зығыр",
            data: ["Көктеу", "Сабақтың ұзаруы", "Бүрлену", "Гүлдену", "Жасыл қораптар", "Қоңыр қораптар", "Толық жетілу"]
        },
        crop_8: {
            title: "Күздік рапс",
            data: ["Көктеу", "Тұқымжарнақ", "Розетка", "Сабақтың өсуі", "Бүрлену", "Гүлденудің басталуы", "Гүлдену ортасы", "Бұршаққындарды қалыптасуы", "Балбырау", "Толық жетілу"]
        },
        crop_9: {
            title: "Жаздық рапс",
            data: ["Көктеу", "Тұқымжарнақ", "Розетка", "Сабақтың өсуі", "Бүрлену", "Гүлденудің басталуы", "Гүлдену ортасы", "Бұршаққындарды қалыптасуы", "Балбырау", "Толық жетілу"]
        },
        crop_10: {
            title: "Соя",
            data: ["Көктеу", "Бұтақтану", "Бүрлену", "Гүлдену", "Бұршақтар құрылуы", "Пісудің басталуы", "Толық жетілу"]
        },
        crop_11: {
            title: "Мақсары",
            data: ["Көктеу", "Розетка", "Сабақтану", "Бұтақтану", "Бүрлену", "Гүлдену", "Балбырау", "Толық жетілу"]
        }
    }
}