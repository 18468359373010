


















































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import Sideboard from '@/components/Sideboard.vue'
import { GetterNames, MutationNames, ActionNames } from '@/store/types'
import { Action, Getter, Mutation } from 'vuex-class'
import { httpGet, httpPostFormData, httpPutFormData } from '@/util/http'
import { apiUrl, apiUrl2, baseUrl } from '@/domain/constants'
import { Route } from 'vue-router'
import Farm from '@/models/Farm'
import { problemTypes } from '@/data/notesData'
import DatePicker from '@/components/DatePicker.vue'
import BaseDropdownContainer from '@/components/BaseDropdownContainer.vue'
import i18n from '@/i18n'
import EXIF from "exif-js"
import FarmFields from "@/views/MainPage/HomeView/MapContainer/MapView/components/FarmFields.vue"
import * as turf from '@turf/turf'
@Component({
    components: {
        Sideboard,
        DatePicker,
        BaseDropdownContainer,
        FarmFields
    }
})
export default class NoteUpdate extends Vue {
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: Route
    @Getter(GetterNames.getFarmFromRoute) private getFarmFromRoute!: Farm
    @Getter(GetterNames.getLoadingDataNames) private getLoadingDataNames!: any
    @Getter(GetterNames.getMapData) private getMapData!: any
    @Getter(GetterNames.getSideboardIsShown) private getSideboardIsShown!: any
    @Getter(GetterNames.getLastNewNotePoint) private getLastNewNotePoint!: any
    @Getter(GetterNames.getNormalDateRound) private getNormalDateRound!: any
    @Getter(GetterNames.getFieldByAllnotes) private getFieldByAllnotes!: any

    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    @Mutation(MutationNames.setPhotosData) private setPhotosData!: any
    @Mutation(MutationNames.setIsNotesModeActive) private setIsNotesModeActive!: any
    @Mutation(MutationNames.setFieldByAllnotes) private setFieldByAllnotes!: any

    @Action(ActionNames.fetchFieldsNoteImage) private fetchFieldsNoteImage!: any

    private problemTypes = problemTypes

    private members = []
    private usersDropdown = false
    private memberSearchText = ''
    private selectedUsers: any[] = []
    private imageData: any = []

    private currentPage = 1
    private currentProblemTypesIndex: any = -1
    private isCheckedAll: boolean = false
    private uploadOnce: boolean = false
    private isUpdate: boolean = false
    private currentDate = new Date().toLocaleDateString('sv-SE')

    private notes: any = [
        {
            problem: '',
            round_date: this.currentDate,
            name: '',
            text: '',
            employees: [],
            images: [],
            is_send: true,
            image_points: {},
            already_create: false
        }
    ]

    private mapZoom: any = 16

    private photoName = ''
    private lonFinal = 0
    private latFinal = 0

    private notesTypes: any = [
        {
            problem: 'type1',
            round_date: this.notes[0].round_date,
            name: '',
            text: '',
            employees: [],
            images: [],
            image_points: {},
            already_create: false
        },
        {
            problem: 'type2',
            round_date: this.notes[0].round_date,
            name: '',
            text: '',
            employees: [],
            images: [],
            image_points: {},
            already_create: false
        },
        {
            problem: 'type3',
            round_date: this.notes[0].round_date,
            name: '',
            text: '',
            employees: [],
            images: [],
            image_points: {},
            already_create: false
        },
        {
            problem: 'type4',
            round_date: this.notes[0].round_date,
            name: '',
            text: '',
            employees: [],
            images: [],
            image_points: {},
            already_create: false
        }
    ]

    private disableTimeFrom: any = {
        from: new Date(Date.now())
    }

    get getEmployeesArray() {
        return this.members.filter((x: any) => x.user_fullname.includes(this.memberSearchText))
    }

    private getAllLoading() {
        return (!this.getLoadingDataNames('noteUpdateGetMembers')
            && !this.getLoadingDataNames('NoteUpdateDeletingImage')
            && !this.getLoadingDataNames('NoteUpdateAddImage')
            && !this.getLoadingDataNames('noteUpdateGetCurrentNote')
            && !this.getLoadingDataNames('noteUpdateGetTypeData')
            && !this.getLoadingDataNames('NoteUpdateUploadingNote')
            && !this.getLoadingDataNames('noteUpdateGetPhenophase')
            && !this.getLoadingDataNames('NoteRegistrationUploadingNote'))
    }

    private mounted() {
        if (this.currentRoute.name === 'noteupdate') {
            this.getCurrentNote()
        }
        this.getMembers()
    }

    private getProblemName(name: any) {
        if (name === 'type1') {
            return this.$t('headers.workspace.fields.notes.note_addition.problem.type1')
        }
        if (name === 'type2') {
            return this.$t('headers.workspace.fields.notes.note_addition.problem.type2')
        }
        if (name === 'type3') {
            return this.$t('headers.workspace.fields.notes.note_addition.problem.type3')
        }
        if (name === 'type4') {
            return this.$t('headers.workspace.fields.notes.note_addition.problem.type4')
        }
    }

    private checkPhotoOnGeo(image: any) {
        if (image.point === this.getLastNewNotePoint) return true
        return false
    }

    private closeDropdownUsers() {
        this.usersDropdown = false
    }

    @Watch('selectedUsers')
    private watchSelectedUsers(val: any) {
        if (val.length > 0) {
            this.selectedUsers.forEach((employee: any) => {
                if (this.notesTypes[this.currentProblemTypesIndex] !== undefined) {
                    this.notesTypes[this.currentProblemTypesIndex].employees.push(employee.user_id)
                } else {
                    this.notes[0].employees.push(employee.user_id)
                }
            })
        }
    }

    @Watch('getNormalDateRound')
    private watchGetNormalDateRound(val: any) {
        if (this.notesTypes[this.currentProblemTypesIndex] !== undefined) {
            this.notesTypes[this.currentProblemTypesIndex].round_date = val
        } else {
            this.notes[0].round_date = val
        }
    }

    private getCurrentNote() {
        this.setLoadingDataNames('noteUpdateGetCurrentNote')
        httpGet({
            url: `${apiUrl2}/notes/${this.currentRoute.params.noteId}/`,
            isSecureRequest: true,
            onSuccess: json => {
                this.notes[0].problem = json.problem
                this.notes[0].round_date = json.round_date
                this.notes[0].name = json.name
                this.notes[0].text = json.text
                json.replies.forEach((y: any) => {
                    this.notes[0].employees.push(y.id)
                    this.selectedUsers.push({ user_id: y.employee_id, user_fullname: y.employee })
                })
                this.notes[0].images = json.images
                this.notes[0].is_send = json.is_send
                this.isUpdate = true
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('noteUpdateGetCurrentNote')
            }
        })
    }

    private getMembers() {
        this.setLoadingDataNames('noteUpdateGetMembers')
        httpGet({
            url: `${apiUrl}/workspace/get/employee/by/divided/${this.currentRoute.params.subfieldId}/`,
            isSecureRequest: true,
            onSuccess: json => {
                this.members = json
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('noteUpdateGetMembers')
            }
        })
    }

    private checkForError(note: any) {
        let counter = 0
        if (note.round_date === "") {
            Vue.alert('', 'Введите дату заметки', 'warning')
            counter++
        }
        if (note.name && note.name === "") {
            // if () {
            Vue.alert('', 'Введите название заметки', 'warning')
            counter++
            // }
        }
        return counter === 0
    }

    private uploadNoteNew(note: any) {
        this.setLoadingDataNames('noteUpdateGetCurrentNote')
        if (!this.checkForError(note)) return
        const formData = new FormData()
        formData.append('problem', note.problem)
        formData.append('text', note.text)
        formData.append('round_date', note.round_date)
        formData.append('employees', JSON.stringify(this.selectedUsers.map((user: any) => {
            return user.user_id
        })))
        httpPutFormData({
            url: `${apiUrl2}/notes/update/owner/${this.currentRoute.params.noteId}/`,
            isSecureRequest: true,
            body: formData,
            onSuccess: json => {
                Vue.alert('', 'Успешно обновлено', 'success')
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('noteUpdateGetCurrentNote')
                this.$router.push({ name: 'allnotes', params: { farmId: this.currentRoute.params.farmId } })
            }
        })
    }

    private createNoteNew(note: any) {
        if (!this.checkForError(note)) return
        this.setLoadingDataNames('NoteUpdateUploadingNote')
        const formData = new FormData()
        formData.append('employees', JSON.stringify(note.employees))
        formData.append('divided_cadastre_user', (this.currentRoute.params.subfieldId !== "undefined") ? String(this.currentRoute.params.subfieldId) : String(this.getFieldByAllnotes.id))
        formData.append('problem', JSON.stringify(note.problem))
        formData.append('text', JSON.stringify(note.text))
        formData.append('round_date', JSON.stringify(note.round_date))
        formData.append('mobile', String(false))
        formData.append('name', JSON.stringify(note.name))
        formData.append('point', 'POINT(' + this.getLastNewNotePoint[0] + ' ' + this.getLastNewNotePoint[1] + ')')
        if (note.images) {
            note.images.forEach((x: any) => {
                formData.append('images_upload', x)
                this.imageData.forEach((y: any) => {
                    if (y.image === x) {
                        if (y.point[0] === this.getLastNewNotePoint[0] || y.point[1] === this.getLastNewNotePoint[1]) {
                            note.image_points[y.name] = null
                        } else {
                            note.image_points[y.name] = 'POINT(' + y.point[0] + ' ' + y.point[1] + ')'
                        }
                    }
                })
            })
        }
        formData.append('image_points', JSON.stringify(note.image_points))
        formData.append('is_send', JSON.stringify(true))
        httpPostFormData({
            url: `${apiUrl2}/notes/`,
            isSecureRequest: true,
            body: formData,
            onSuccess: json => {
                if (Number.isInteger(json.id)) {
                    this.notes[0].already_create = true
                    if (this.imageData.every(this.isChoosenPhoto) === true) {
                        Vue.alert('', 'Заметка создана', 'success')
                        this.$router.push({ name: 'allnotes', params: { farmId: this.currentRoute.params.farmId } })
                    } else {
                        Vue.alert('', 'Заметка создана', 'success')
                    }
                } else {
                    Vue.alert('', 'Ошибка при создание', 'warning')
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setFieldByAllnotes([])
                this.setLoadingDataNames('NoteUpdateUploadingNote')
            }
        })
    }

    private isChoosenPhoto(el: any) {
        return el.is_send === true
    }

    private getImgUrl(photo: any) {
        if (photo.hasOwnProperty('is_mobile')) {
            return `${baseUrl}${photo.image}`
        } else {
            return URL.createObjectURL(photo)
        }
    }

    private addImageToList(files: any) {
        for (const file of files) {
            if (this.imageData.length >= 10) {
                alert(i18n.t('more.messages.instructions.max_photo'))
                return
            }
            if (this.imageData.find((x: any) => x.image.name === file.name)) {
                console.log('file with name of ' + files.name + ' already exists')
            } else {
                this.getLocation(file)
            }
        }
    }

    private getLocation(event: any) {
        EXIF.getData(event, () => {
            if (Object.values(event.exifdata).length > 0 && event.exifdata.GPSLatitude && event.exifdata.GPSLongitude) {
                const latDegree = event.exifdata.GPSLatitude[0].numerator
                const latMinute = event.exifdata.GPSLatitude[1].numerator
                const latSecondNum = event.exifdata.GPSLatitude[2].numerator
                const latSecondDen = event.exifdata.GPSLatitude[2].denominator
                const latSecond = latSecondNum / latSecondDen

                const lonDegree = event.exifdata.GPSLongitude[0].numerator
                const lonMinute = event.exifdata.GPSLongitude[1].numerator
                const lonSecondNum = event.exifdata.GPSLongitude[2].numerator
                const lonSecondDen = event.exifdata.GPSLongitude[2].denominator
                const lonSecond = lonSecondNum / lonSecondDen

                this.photoName = event.name
                this.lonFinal = convertDMSToDDLon(lonDegree, lonMinute, lonSecond)
                this.latFinal = convertDMSToDDLat(latDegree, latMinute, latSecond)

                function convertDMSToDDLon(degrees: any, minutes: any, seconds: any) {
                    const dd: any = degrees + (minutes / 60) + (seconds / 3600)
                    return (dd * 20037508.34) / 180
                }

                function convertDMSToDDLat(degrees: any, minutes: any, seconds: any) {
                    const dd: any = degrees + (minutes / 60) + (seconds / 3600)
                    const y = Math.log(Math.tan(((90 + dd) * Math.PI) / 360)) / (Math.PI / 180)
                    return (y * 20037508.34) / 180
                }

                this.imageData.push({ name: event.name, point: [this.lonFinal, this.latFinal], image: event, is_send: false })
                // const arrForCheck = []
                // arrForCheck.push(this.lonFinal, this.latFinal)
                //
                // if (this.isInsideField(arrForCheck)) {
                //     this.imageData.push({ name: event.name, point: [this.lonFinal, this.latFinal], image: event, is_send: false })
                //     return
                // } else {
                //     Vue.alert('Координаты фото не в периметре поля', 'Предупреждение', 'warning')
                //     return
                // }
            } else {
                this.imageData.push({ name: event.name, point: this.getLastNewNotePoint, image: event, is_send: false })
            }
        })
    }

    private isInsideField(points: any) {
        if (points.some(isNaN)) return true
        const point = turf.point(points)
        if (this.getFarmFromRoute.fields && point) {
            const polygon = turf.polygon(this.getFarmFromRoute.fields[0].geom.coordinates[0])
            if (turf.booleanContains(polygon, point)) {
                return true
            }
        }
        return false
    }

    private addUploadPhoto() {
        this.currentPage = 4
    }

    /* ------------------------------------------- Tagging User Functions -------------------------------------- */
    private addUser(note: any, user: any) {
        const newuser: any = this.members.find((u: any) => u.user_id === user.user_id)
        if (newuser && !this.selectedUsers.find((u: any) => u.user_id === newuser.user_id)) { // u.user_id === newuser.user_id
            this.selectedUsers.push(newuser)
        }
        this.usersDropdown = false
    }

    private removeUser(user: any) {
        if (this.selectedUsers.includes(user)) {
            this.selectedUsers.splice(this.notes[0].employees.indexOf(user), 1)
        }
    }

    private openNotes() {
        if (this.currentPage === 1) {
            if (this.$route.name !== 'subfieldnotes') {
                this.$router.push({ name: 'subfieldnotes' })
                this.setIsNotesModeActive(false)
            }
        } else {
            if (this.notes[0].problem === '') {
                this.currentPage--
                return
            }
            return this.currentPage = 1
        }
    }

    private addToDelete(image: any, index: any) {
        if (confirm(i18n.t('messages.confirm.delete.photo') as string)) {
            if (this.notesTypes[this.currentProblemTypesIndex] !== undefined) {
                this.notesTypes[this.currentProblemTypesIndex].images.splice(index, 1)
                return
            } else {
                this.notes[0].images.splice(index, 1)
            }
        }
    }

    private addToDelete2(item: any, index: any) {
        if (confirm(i18n.t('messages.confirm.delete.photo') as string)) {
            this.imageData.splice(index, 1)
        }
    }

    private changeCategoryNotes(item: any) {
        this.currentProblemTypesIndex = this.notesTypes.findIndex((x: any) => x.problem === item.problem)
        this.notes = this.notesTypes.filter((x: any) => x.problem === item.problem)
    }

    private nextPage() {
        if (this.currentPage === 2) {
            if (this.imageData.length < 1) {
                Vue.alert('Фото должно быть', 'Добавите фото', 'warning')
                return
            }
            this.currentPage = 3
        }
        if (this.currentPage === 4) {
            if (this.notes[0].problem === "") {
                if (this.notesTypes[this.currentProblemTypesIndex].images.length === 0) {
                    Vue.alert('', 'Выберите фото', 'warning')
                    return
                }
                this.nextPageFour()
                return
            }
            return this.currentPage = 1
        }
    }

    private nextPageFour() {
        this.notes = this.notesTypes.filter((x: any) => x.images.length > 0)
        this.currentPage = 1
    }

    private justSavePhoto() {
        if (this.imageData.length < 1) {
            Vue.alert('Фото должно быть', 'Добавите фото', 'warning')
            return
        }
        this.imageData.forEach((x: any) => {
            this.notes[0].images.push(x.image)
        })
        this.uploadOnce = true
        this.currentPage = 1
    }

    /*----------CurentPage 3--------------*/
    private choosePhotoCategory(item: any, index: any) {
        this.currentProblemTypesIndex = this.notesTypes.findIndex((x: any) => x.problem === item.type)
        this.notesTypes[this.currentProblemTypesIndex].round_date = this.notes[0].round_date
        this.notesTypes[this.currentProblemTypesIndex].name = this.notes[0].name
        this.notesTypes[this.currentProblemTypesIndex].text = this.notes[0].text
        this.currentPage = 4
    }

    /*----------CurentPage 4--------------*/
    private checkboxButton(image: any) {
        const index = this.imageData.find((x: any) => x.name === image.name)
        const numberIndex = this.notesTypes[this.currentProblemTypesIndex].images.findIndex((x: any) => x === image)
        if (numberIndex !== -1) {
            this.notesTypes[this.currentProblemTypesIndex].images.splice(numberIndex, 1)
        } else {
            if (this.checkedValue(image)) return
            this.notesTypes[this.currentProblemTypesIndex].images.push(image)
            index.is_send = true
        }
    }

    private checkedValue(image: any) {
        return this.notesTypes.find((x: any) => {
            if (x.images.includes(image)) {
                return true
            } else {
                return this.notesTypes[this.currentProblemTypesIndex].images.includes(image)
            }
        })
    }

    private checkboxAllButton() {
        if (this.notesTypes[this.currentProblemTypesIndex].images.length !== this.imageData.length) {
            this.imageData.forEach((x: any) => {
                if (x.is_send === false) {
                    this.notesTypes[this.currentProblemTypesIndex].images.push(x.image)
                }
            })
            this.isCheckedAll = true
            return
        }
        this.notesTypes[this.currentProblemTypesIndex].images = []
        this.isCheckedAll = false
    }
}
