


























































































































































import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter, Mutation } from 'vuex-class'
import { ActionNames, GetterNames, MutationNames } from '@/store/types'
import { httpGet } from '@/util/http'
import { apiUrl, openCrossOriginWebSite } from '@/domain/constants'
import i18n from '@/i18n'

@Component
export default class Menubar extends Vue {
    @Action(ActionNames.LOG_OUT) private LOG_OUT !: any
    @Getter(GetterNames.getSidebarModel) private getSidebarModel!: any
    @Getter(GetterNames.getPermissions) private getPermissions!: any
    @Mutation(MutationNames.setSidebarModel) private setSidebarModel!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    private isProfileDrowndownVisible = false
    private userData = {
        email: '',
        first_name: '',
        last_name: '',
        username: ''
    }

    private mounted() {
        this.loadUserData()
    }

    private loadUserData() {
        httpGet({
            url: apiUrl + '/user_info/',
            isSecureRequest: true,
            onSuccess: json => {
                const user = json.user
                if (user) {
                    this.userData = user
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                //
            }
        })
    }

    private openCrossDomainWebsite(url: string, path: string) {
        openCrossOriginWebSite(url, path)
    }

    private logout() {
        this.setLoadingDataNames('logoutloading')
        this.LOG_OUT()
        this.openCrossDomainWebsite('login', 'signin/') // farm
    }

    private toggleSidebar() {
        this.setSidebarModel(!this.getSidebarModel)
    }

    private toggleProfileDropdown() {
        this.isProfileDrowndownVisible = !this.isProfileDrowndownVisible
    }

    private closeProfileDropdown() {
        this.isProfileDrowndownVisible = false
    }
}
