















































import { Component, Vue, Prop } from 'vue-property-decorator'
import { baseUrl } from '@/domain/constants'
import i18n from '@/i18n'
@Component({
    components: {
    }
})
export default class NoteMention extends Vue {
    @Prop({default: null}) private note!: any
    private reply = {
        dropdown: false,
        id: -1
    }

    private getDatetoString(date: any) {
        if (date) {
            const months = [ i18n.t('general.dates.months[0]') as string, i18n.t('general.dates.months[1]') as string, i18n.t('general.dates.months[2]') as string, i18n.t('general.dates.months[3]') as string, i18n.t('general.dates.months[4]') as string, i18n.t('general.dates.months[5]') as string, i18n.t('general.dates.months[6]') as string, i18n.t('general.dates.months[7]') as string, i18n.t('general.dates.months[8]') as string, i18n.t('general.dates.months[9]') as string, i18n.t('general.dates.months[10]') as string, i18n.t('general.dates.months[11]') as string]
            return date.substring(8, 10) + ' ' + months[Number(date.substring(5, 7)) - 1] + ' ' + date.substring(0, 4)
        }
        return i18n.t('general.dates.format_date')
    }

    private normalizedDate(date: any) {
        if (date) {
            return date.substring(8, 10) + '.' + date.substring(5, 7) + '.' + date.substring(0, 4)
        }
        return i18n.t('general.dates.format_date')
    }

    private getImageUrl(imageUrl: any) {
        return `${baseUrl}${imageUrl}`
    }

    private changeReplyDropdown(id: any) {
        if (this.reply.id === id) {
            this.reply.dropdown = this.reply.dropdown === true ? false : true
        } else {
            this.reply.id = id
            this.reply.dropdown = true
        }
    }

    private closeReplyDropdown() {
        this.reply.dropdown = false
    }
}
