












































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import HeaderWithPagination from '@/components/HeaderWithPagination.vue'
import Sideboard from '@/components/Sideboard.vue'
import { ActionNames, GetterNames, MutationNames } from '@/store/types'
import { Action, Getter, Mutation } from 'vuex-class'
import BaseMenuItem from '@/components/BaseMenuItem.vue'
import Gistogram from './Gistogram.vue'
import {clgreenGistogram, ndviGistogram, ndmiGistogram, ndviContrastedGistogram} from '@/data/gistogramData'
import { Route } from 'vue-router'
import Farm from '@/models/Farm'
import { httpPost, httpPostZip } from '@/util/http'
import { apiUrl2, baseUrl } from '@/domain/constants'
import NewNoteAlert from '@/views/MainPage/HomeView/Sideboards/FarmBoard/SubfieldBoard/NoteBoard/NewNoteAlert.vue'
import IndicatorsModel from '@/components/tour/IndicatorsModel.vue'
import DefaultModel from '@/components/tour/DefaultModel.vue'
import FinalModel from '@/components/tour/FinalModel.vue'
import {indicatorsContent} from '@/data/tourContent'
import i18n from '@/i18n'
import { egisticLogo, pdfWaterMark } from '@/data/pdfImageData'
import { makePDF, PdfActions, sleep } from '@/utils/services'
@Component({
    components: {
        Sideboard,
        HeaderWithPagination,
        BaseMenuItem,
        Gistogram,
        NewNoteAlert,
        IndicatorsModel,
        DefaultModel,
        FinalModel
    }
})
export default class SubfieldsIndicatorsBoard extends Vue {
    @Action(ActionNames.changeFarmSeason) private changeFarmSeason!: any
    @Action(ActionNames.updateMapDataInRegion) private updateMapDataInRegion!: any

    @Getter(GetterNames.getCurrentRoute) private currentRoute !: Route
    @Getter(GetterNames.getFarmFromRoute) private getFarmFromRoute!: Farm
    @Getter(GetterNames.getFieldRequestComparisonMode) private getFieldRequestComparisonMode !: boolean
    @Getter(GetterNames.getSubfieldIndicatorData) private getSubfieldIndicatorData!: any
    @Getter(GetterNames.getLastNewNotePoint) private getLastNewNotePoint!: any
    @Getter(GetterNames.getIsProblemZonesMode) private getIsProblemZonesMode!: any
    @Getter(GetterNames.getFertilizerResult) private getFertilizerResult!: any
    @Getter(GetterNames.getWeedinessImage) private getWeedinessImage!: any
    @Getter(GetterNames.getGuideTourActivators) private getGuideTourActivators!: any
    @Getter(GetterNames.getFieldFromRoute) private getFieldFromRoute!: any
    @Getter(GetterNames.getCurrentSeason) private getCurrentSeason!: any
    @Getter(GetterNames.getMapScreen) private getMapScreen!: any
    @Getter(GetterNames.getActiveIndicator) private getActiveIndicator!: any

    @Mutation(MutationNames.changeLegendDividingMode) private changeLegendDividingMode!: any
    @Mutation(MutationNames.setFieldRequestComparisonMode) private setFieldRequestComparisonMode!: any
    @Mutation(MutationNames.setSubfieldIndicatorData) private setSubfieldIndicatorData!: any
    @Mutation(MutationNames.setIsDataLoading) private setIsDataLoading!: any
    @Mutation(MutationNames.setNewFarmRequest) private setNewFarmRequest!: any
    @Mutation(MutationNames.setIsProblemZonesMode) private setIsProblemZonesMode!: any
    @Mutation(MutationNames.setFertilizerResult) private setFertilizerResult!: any
    @Mutation(MutationNames.setWeedinessImage) private setWeedinessImage!: any
    @Mutation(MutationNames.setGuideTourActivators) private setGuideTourActivators!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    @Mutation(MutationNames.setMapScreen) private setMapScreen!: any
    @Mutation(MutationNames.setActiveIndicator) private setActiveIndicator!: any
    @Action(ActionNames.fetchFieldIndicatorData) private fetchFieldIndicatorData!: any

    private azoteOn = false
    private humidityOn = false
    private chlorineOn = false
    private vegetationOn = false
    private gistogramData = { 'clgreen': clgreenGistogram, 'ndvi': ndviGistogram, 'contrasted_ndvi': ndviContrastedGistogram, 'gndvi': ndviGistogram, 'ndmi': ndmiGistogram }
    private isTreeSelectShown = false
    private indicatorsDropdown = true
    private seasonDropdown = false
    private weedinessStatus = false
    private fertilizerRequestId = -1
    private weedinessRequestId = -1
    private fertilizerNorms: any = ['', '', '']
    private fertilizerCOlors: any = ['#f7fcb9', '#acdd8e', '#006737']

    get getChosenSeason() {
        if (this.getCurrentSeason) {
            return i18n.t('headers.workspace.fields.analytics.cur_season') + this.getCurrentSeason.name
        } else return i18n.t('messages.errors.no_seasons')
    }
    @Watch('getGuideTourActivators.indicatorsTour')
    private onchangeIndicatorsTour() {
        if (this.getGuideTourActivators.indicatorsTour) {
            (this.$refs.indicatorsModel as any).show()
            this.setFieldRequestComparisonMode(false)
            this.setFieldRequestComparisonMode(true)
        }
    }

    private endIndicatorsTour() {
        this.setGuideTourActivators({indicatorsTour: false})
    }

    private startIndicatorsTour(index: any) {
        if (index === 'end') {
            (this.$refs.finalModel as any).show()
            const model = this.$refs.defaultModel as any
            model.hide()
            this.endIndicatorsTour()
        } else {
            const content: any = indicatorsContent[index]
            const model = this.$refs.defaultModel as any
            model.show(indicatorsContent[index], {
                modelTop:  content.modelTop,
                modelBottom: content.modelBottom,
                modelLeft: content.modelLeft ? content.modelLeft : (content.nextIndex === 2 ? (window.innerWidth / 2 + 65 - 237.5) + 'px' : (window.innerWidth - 612) + 'px'),
                modelRight: content.modelRight,
                arrowTop: content.arrowTop,
                arrowLeft: content.arrowLeft
            })
        }
    }

    @Watch('getLastNewNotePoint')
    private setNoteModalActive() {
        if (this.getLastNewNotePoint.length !== 0) {
            (this.$refs.newNoteAlert as any).show()
        }
    }

    @Watch('getSubfieldIndicatorData.alertProblemZones')
    private showProblemZonesAlert() {
        if (!this.getSubfieldIndicatorData.alertProblemZones) return
        (this.$refs.alertModal as any).show()
        this.setSubfieldIndicatorData({alertProblemZones: false})
    }

    @Watch('getSubfieldIndicatorData.currentRequestId')
    private updateFertilizerOnChange() {
        this.updateFertilizerResult()
        this.getWeedinessData()
    }

    private getWeedinessData() {
        if (!this.getWeedinessImage.visibility) return
        if (this.getWeedinessImage.requestId === this.getSubfieldIndicatorData.currentRequestId) return
        if (this.weedinessRequestId === this.getSubfieldIndicatorData.currentRequestId) return
        this.setWeedinessImage({
            image: null,
            requestId: null,
            download: false
        })
        const request = this.getRequestById(this.getSubfieldIndicatorData.currentRequestId)
        if (!request.is_present) return
        this.weedinessRequestId = this.getSubfieldIndicatorData.currentRequestId
        this.setLoadingDataNames('indicatorsBoardGetWeediness')
        httpPost({
            url: `${apiUrl2}/get/classify/crop/`,
            isSecureRequest: true,
            body: {
                request: this.getSubfieldIndicatorData.currentRequestId
            },
            onSuccess: json => {
                if (json === 'error') {
                    alert(i18n.t('messages.errors.data'))
                    return
                }
                this.setWeedinessImage({image: baseUrl + json.raster_path, requestId: this.getSubfieldIndicatorData.currentRequestId})
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('indicatorsBoardGetWeediness')
            }
        })
    }

    @Watch('getFertilizerResult.visibility')
    private updateFertilizerResult() {
        if (!this.getFertilizerResult.visibility) return
        if (this.getFertilizerResult.requestId === this.getSubfieldIndicatorData.currentRequestId) return
        if (this.fertilizerRequestId === this.getSubfieldIndicatorData.currentRequestId) return
        this.setFertilizerResult({
            norm: [],
            requestId: -1,
            imgUrl: null,
            data: [],
            download: false
        })
        const request = this.getRequestById(this.getSubfieldIndicatorData.currentRequestId)
        if (!request.is_present) return
        this.fertilizerRequestId = this.getSubfieldIndicatorData.currentRequestId
        this.setLoadingDataNames('indicatorsBoardGetClasters')
        httpPost({
            url: `${apiUrl2}/get/cluster/`,
            isSecureRequest: true,
            body: {
                request: this.getSubfieldIndicatorData.currentRequestId,
                n_clusters: 3,
                ndvi: true,
                gndvi: true,
                clgreen: true
            },
            onSuccess: json => {
            if (json.detail) {
                alert(i18n.t('messages.instructions.processing'))
            } else {
                this.setFertilizerResult({imgUrl: baseUrl + json.raster_path, data: json.geometry.features, requestId: this.getSubfieldIndicatorData.currentRequestId})
                this.fertilizerNorms = ['', '', '']
            }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('indicatorsBoardGetClasters')
            }
        })
    }

    private downloadFertilizerMap() {
        if (!Number(this.fertilizerNorms[0]) || !Number(this.fertilizerNorms[1]) || !Number(this.fertilizerNorms[2])) {
            alert(i18n.t('messages.instructions.fill_form'))
            return
        }
        httpPostZip({
            url: `${apiUrl2}/fertilizer/map/download/`,
            isSecureRequest: true,
            body: {
                request: this.getFertilizerResult.requestId,
                n_clusters: 3,
                ndvi: true,
                gndvi: true,
                clgreen: true,
                geom_data: {
                    0: Number(this.fertilizerNorms[0]),
                    1: Number(this.fertilizerNorms[1]),
                    2: Number(this.fertilizerNorms[2])
                }
            },
            onSuccess: response => {
                response.blob().then((promise: any) => {
                    const url = window.URL.createObjectURL(promise)
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', 'egistic_map.zip')
                    document.body.appendChild(link)
                    link.click()
                })
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setIsDataLoading(false)
            }
        })
    }

    private getClusterColor(value: any) {
        for (const range of this.gistogramData.ndvi) {
            if (range.from <= value && range.to >= value) return range.color
        }
    }

    private calculateTotalFertilizerAmount() {
        let amount = 0
        for (let i = 0; i < 3; i++) {
            if (isNaN(this.fertilizerNorms[i]) || this.fertilizerNorms[i] === '') {
                amount += 0
            } else {
                amount += Number(this.fertilizerNorms[i]) * Math.round(this.getFertilizerResult.data[i].properties.area)
            }
        }
        return amount
    }

    private getRequestById(requestId: number) {
        return this.getSubfieldIndicatorData.data.find((x: any) => requestId === x.id)
    }

    private mounted() {
        this.setActiveIndicator('ndvi')
        this.setFertilizerResult({result: null, requestId: -1, visibility: false})
        this.setWeedinessImage({image: null, requestId: null, download: false, visibility: false})
        this.setIsProblemZonesMode(false)
        this.setFieldRequestComparisonMode(false)
        this.getContrastedData()
        this.fetchFieldIndicatorData()
    }

    @Watch('currentRoute.params.subfieldId')
    private onchangeFarmId(val: any, old: any) {
        if (val && val !== old) {
            this.fetchFieldIndicatorData()
        }
    }

    private ChangeIndicatorType(val: string) {
        this.setActiveIndicator(this.getActiveIndicator === val ? '' : val)
    }

    private getIndicatorValue(type: string) {
        if (this.getSubfieldIndicatorData.data.length > 0) {
            const request = this.getRequestById(this.getSubfieldIndicatorData.currentRequestId)
            if (!request.is_present) return false
            if (type === 'ndvi') return request.ndvi ? request.ndvi.mean.toFixed(2) : ''
            if (type === 'contrastred_ndvi') return request.ndvi ? request.ndvi.mean.toFixed(2) : ''
            if (type === 'gndvi') return request.gndvi ? request.gndvi.mean.toFixed(2) : ''
            if (type === 'clgreen') return request.clgreen ? request.clgreen.mean.toFixed(2) : ''
            if (type === 'ndmi') return request.ndmi ? request.ndmi.mean.toFixed(2) : ''
        }
        return false
    }

    private getIndicatorColor(type: any, ranges: any[] ) {
        const value = this.getIndicatorValue(type === 'contrasted_ndvi' ? 'ndvi' : type)
        if (value) {
            const range = ranges.find(x => x.from <= value && x.to >= value)
            if (range) return range.color
        }
        return false
    }

    @Watch('getSubfieldIndicatorData.currentRequestId')
    private getContrastedData() {
        if (!this.getSubfieldIndicatorData.currentRequestId) return
        const currentContrastedData = this.getRequestById(this.getSubfieldIndicatorData.currentRequestId).contrasted_ndvi
        if (currentContrastedData) {
            const newGistogramData = this.gistogramData.contrasted_ndvi.map((data: any, index: number) => {
                return {
                    color: data.color,
                    from: Math.round(currentContrastedData.levels[index] * 1000) / 1000,
                    to: Math.round(currentContrastedData.levels[index + 1] * 1000) / 1000
                }
            })
            this.gistogramData.contrasted_ndvi = newGistogramData
        }
    }

    private openFarmSubfieldBoard() {
        this.$router.push(`/cadastres/farm/${this.currentRoute.params.farmId}/subfields/${this.currentRoute.params.subfieldId}`)
        this.changeLegendDividingMode(false)
        this.setFieldRequestComparisonMode(false)
        this.setIsProblemZonesMode(false)
        this.setActiveIndicator('ndvi')
    }

    private openPrevField() {
        if (this.getFarmFromRoute) {
            const prevFieldId = this.getFarmFromRoute.getPrevFieldId(this.$route.params.subfieldId)
            if (prevFieldId !== -1) {
                this.$router.push(`/cadastres/farm/${this.getFarmFromRoute.id}/subfields/${prevFieldId}/indicators`)
                this.setFertilizerResult({result: null, requestId: -1, visibility: false})
                this.setWeedinessImage({visibility: false})
                this.setIsProblemZonesMode(false)
            }
        }
    }

    private openNextField() {
        if (this.getFarmFromRoute) {
            const nextFieldId = this.getFarmFromRoute.getNextFieldId(this.$route.params.subfieldId)
            if (nextFieldId !== -1) {
                this.$router.push(`/cadastres/farm/${this.getFarmFromRoute.id}/subfields/${nextFieldId}/indicators`)
                this.setFertilizerResult({result: null, requestId: -1, visibility: false})
                this.setWeedinessImage({visibility: false})
                this.setIsProblemZonesMode(false)
            }
        }
    }

    get getPdfHeader() {
        if ( this.getFertilizerResult.visibility ) {
            return i18n.t('headers.workspace.fields.indicators.fertilization.map')
        } else if ( this.getWeedinessImage.visibility && this.getIsProblemZonesMode ) {
            return i18n.t('headers.workspace.fields.indicators.report.pz_n_w')
        } else if ( this.getWeedinessImage.visibility ) {
            return i18n.t('headers.workspace.fields.indicators.report.weediness')
        } else if ( this.getIsProblemZonesMode ) {
            return i18n.t('headers.workspace.fields.indicators.report.problem_zones')
        } else {
            return i18n.t('headers.workspace.fields.indicators.report.title')
        }
    }

    get getIndicatorTitle() {
        if ( this.getActiveIndicator === 'ndvi' ) {
            return i18n.t('headers.workspace.fields.indicators.indicators.vegetation.title')
        } else if ( this.getActiveIndicator === 'gndvi') {
            return i18n.t('headers.workspace.fields.indicators.indicators.nitrogen.title')
        } else if ( this.getActiveIndicator === 'ndmi' ) {
            return i18n.t('headers.workspace.fields.indicators.indicators.humidity.title')
        } else if ( this.getActiveIndicator === 'clgreen') {
            return i18n.t('headers.workspace.fields.indicators.indicators.chlorophyll.title')
        }
    }

    private normalizeScreen(farm: any, field: any) {
        let Xmin = Number.MAX_SAFE_INTEGER
        let Xmax = Number.MIN_SAFE_INTEGER
        let Ymin = Number.MAX_SAFE_INTEGER
        let Ymax = Number.MIN_SAFE_INTEGER
        for (const arr of field.geom.coordinates[0][0]) {
            Xmax = arr[0] > Xmax ? arr[0] : Xmax
            Xmin = arr[0] < Xmin ? arr[0] : Xmin
            Ymax = arr[1] > Ymax ? arr[1] : Ymax
            Ymin = arr[1] < Ymin ? arr[1] : Ymin
        }
        this.updateMapDataInRegion({
            boundaries: [Xmin, Ymin, Xmax, Ymax],
            center: [(Xmax + Xmin) / 2, (Ymax + Ymin) / 2]
        })
    }

    @Watch('getWeedinessImage.download')
    private downloadPdf() {
        if ( !this.getWeedinessImage.download ) return

        this.setLoadingDataNames('DownloadIndicatorsReport')
        const farm = this.getFarmFromRoute
        const field = farm.fields.find((x: any) => x.id.toString() === this.currentRoute.params.subfieldId)
        this.normalizeScreen(farm, field)
        const docDefinition: any = {
            pageSize: {
                width: 595,
                height: 842
            },
            pageMargins: [30, 40, 30, 40],
            background: [
                {
                    image: pdfWaterMark,
                    margin: [139, 0, 0, 0]
                }
            ]
        }
        const content: any = [
            {
                image: egisticLogo,
                margin: [0, 0, 0, 0],
                width: 91.14,
                height: 30,
                alignment: 'center'
            },
            {
                text: this.getPdfHeader,
                fontSize: 16,
                bold: true,
                alignment: 'center',
                margin: [0, 24, 0, 12]
            },
            {
                text: `${ i18n.t('headers.workspace.farm.title')}: ${this.getFarmFromRoute.name}: ${i18n.t('headers.workspace.fields.field')} #${field.name ? field.name : field.number}`,
                fontSize: 16,
                bold: true,
                alignment: 'center',
                margin: [0, 0, 0, 24]
            }
        ]

        this.setMapScreen({start: true})
        sleep(1000).then(() => {
            content.push({
                text: this.getIndicatorTitle,
                fontSize: 16,
                alignment: 'center'
            })
            content.push({
                image: this.getMapScreen.blobImg,
                width: 550,
                fit: [550, 250],
                alignment: 'center'
            })
        }).then(() => {
            docDefinition.content = content
            makePDF(PdfActions.DOWNLOAD, docDefinition)
            this.setWeedinessImage({download: false})
            this.setLoadingDataNames('DownloadIndicatorsReport')
        })
    }

    private closeSeasonDropdown() {
        this.seasonDropdown = false
    }
}
