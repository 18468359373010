





























import { Component, Vue, Watch, Emit, Prop } from 'vue-property-decorator'

@Component
export default class DefaultModel extends Vue {
    private isShown = false
    private content: any = {
        header: '',
        text: '',
        catalogList: [],
        nextIndex: 0
    }
    private position: any = {
        modelTop: '50vh',
        modelBottom: null,
        modelLeft: '492px',
        modelRight: null,
        arrowTop: '89px',
        arrowLeft: '-13px'
    }

    private show(content: any, position: any) {
        this.isShown = true
        this.content = content
        this.position = position
    }
    private getNextIndex() {
        return this.content.nextIndex
    }
    private hide() {
        this.isShown = false
    }
    @Emit('end')
    private end() {
        return {}
    }
    @Emit('confirm')
    private confirm() {
        return {}
    }
}
