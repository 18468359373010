






































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import { GetterNames, MutationNames } from '@/store/types'
import NewNoteAlert from './NewNoteAlert.vue'
import { Route } from 'vue-router'
import { httpGet, httpDelete } from '@/util/http'
import { baseUrl, apiUrl, apiUrl2 } from '@/domain/constants'
import Farm from '@/models/Farm'
import i18n from '@/i18n'
import NoteWindow from '@/views/MainPage/HomeView/Sideboards/FarmBoard/NotesBoard/NoteWindow.vue'
@Component({
    components: {
        NewNoteAlert,
        NoteWindow
    }
})
export default class FieldNotes extends Vue {
    @Getter(GetterNames.getMapData) private mapData !: any
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: Route
    @Getter(GetterNames.getLastNewNotePoint) private getLastNewNotePoint!: any
    @Getter(GetterNames.getFarmFromRoute) private getFarmFromRoute!: Farm
    @Getter(GetterNames.getSubfieldNoteData) private getSubfieldNoteData!: any
    @Getter(GetterNames.getPermissions) private getPermissions!: any
    @Getter(GetterNames.getLoadingDataNames) private getLoadingDataNames!: any
    @Getter(GetterNames.getIsNotesModeActive) private getIsNotesModeActive!: any

    @Mutation(MutationNames.setIsNotesModeActive) private setIsNotesModeActive!: any
    @Mutation(MutationNames.setSubfieldNoteData) private setSubfieldNoteData!: any
    @Mutation(MutationNames.setSideboardIsShown) private setSideboardIsShown !: any
    @Mutation(MutationNames.setIsDataLoading) private setIsDataLoading!: any
    @Mutation(MutationNames.setNoteSupport) private setNoteSupport!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    private fieldNotes = []
    private optionStatus = false
    private optionid = 0
    private moreDropdown = -1

    @Watch('getLastNewNotePoint')
    private setNoteModalActive() {
        if (this.getLastNewNotePoint.length !== 0) {
            (this.$refs.newNoteAlert as any).show()
        }
    }

    private getAllLoading() {
        return (!this.getLoadingDataNames('FieldNotesIsDeletingNote')
        && !this.getLoadingDataNames('FieldNotesIsGettingNotes'))
    }

    private checkReplyDate(replies: any) {
        const reply = replies.find((x: any) => x.is_my_reply)
        if (reply) {
            return reply.reply_date ? true : false
        }
        return false
    }

    private mounted() {
        this.getNotes()
    }

    private checkMyNotes() {
        if (this.getSubfieldNoteData.data.length > 0
        && this.getSubfieldNoteData.fieldId === this.currentRoute.params.subfieldId) return true
        return false
    }

    private getImageUrl(imageUrl: any) {
        return `${baseUrl}${imageUrl}`
    }

    private getDatetoString(date: any) {
        if (date) {
            const months = [ i18n.t('general.dates.months[0]') as string, i18n.t('general.dates.months[1]') as string, i18n.t('general.dates.months[2]') as string, i18n.t('general.dates.months[3]') as string, i18n.t('general.dates.months[4]') as string, i18n.t('general.dates.months[5]') as string, i18n.t('general.dates.months[6]') as string, i18n.t('general.dates.months[7]') as string, i18n.t('general.dates.months[8]') as string, i18n.t('general.dates.months[9]') as string, i18n.t('general.dates.months[10]') as string, i18n.t('general.dates.months[11]') as string]
            return date.substring(8, 10) + ' ' + months[Number(date.substring(5, 7)) - 1] + ', ' + date.substring(11, 16)
        }
        return i18n.t('general.dates.format_date')
    }

    private normalizedDate(date: any) {
        if (date) {
            return date.substring(8, 10) + '.' + date.substring(5, 7) + '.' + date.substring(0, 4)
        }
        return i18n.t('general.dates.format_date')
    }

    private getNotes() {
        this.setSideboardIsShown(!this.getIsNotesModeActive)
        this.setLoadingDataNames('FieldNotesIsGettingNotes')
        httpGet({
            url: `${apiUrl2}/notes/by_field/${this.currentRoute.params.subfieldId}/`,
            isSecureRequest: true,
            onSuccess: json => {
                this.setSubfieldNoteData({
                    fieldId: this.currentRoute.params.subfieldId,
                    currentNoteId: null,
                    data: json.reverse()
                })
                console.log(json, 'json')
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('FieldNotesIsGettingNotes')
            }
        })
    }

    private deleteNote(id: any) {
        this.setLoadingDataNames('FieldNotesIsDeletingNote')
        httpDelete({
            url: `${apiUrl2}/notes/${id}/`,
            isSecureRequest: true,
            onSuccess: () => {
                this.moreDropdown = -1
                alert(i18n.t('messages.success.note_deleted'))
                this.getNotes()
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('FieldNotesIsDeletingNote')
            }
        })
    }

    private moreDropdownCollapse(index: number) {
        if ( this.moreDropdown === index ) {
            this.moreDropdown = -1
        } else {
            this.moreDropdown = index
        }
    }

    private closeMoreDropdown(index: number) {
        this.moreDropdown = -2
    }

    private toggleMorebtnDropdown(val: number) {
        if (val === this.optionid) {
            this.optionStatus = this.optionStatus === true ? false : true
        } else {
            this.optionid = val
            this.optionStatus = true
        }
    }

    private closeMorebtnDropdown() {
        this.optionStatus = false
    }

    private openNote(noteId: number) {
        this.setNoteSupport({noteId})
        this.$router.push(`/cadastres/farm/${this.getFarmFromRoute.id}/subfields/${this.currentRoute.params.subfieldId}/notes/${noteId}`)
    }

    private openNoteUpdate(id: any) {
        if (this.$route.name !== 'noteupdate') {
            this.$router.push(`/cadastres/farm/${this.currentRoute.params.farmId}/subfields/${this.currentRoute.params.subfieldId}/noteupdate/${id}`)
        }
    }
    private openNoteReply(id: any) {
        if (this.$route.name !== 'notereply') {
            this.$router.push(`/cadastres/farm/${this.currentRoute.params.farmId}/subfields/${this.currentRoute.params.subfieldId}/notereply/${id}`)
        }
    }

    private close() {
        this.$router.push(`/cadastres/farm/${this.getFarmFromRoute.id}/subfields/${this.currentRoute.params.subfieldId}`)
    }

}
