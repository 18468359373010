import FarmSubfieldBoard from '@/views/MainPage/HomeView/Sideboards/FarmBoard/SubfieldBoard/Index.vue'
import FarmFieldHistory from '@/views/MainPage/HomeView/Sideboards/FarmBoard/SubfieldBoard/HistoryBoard/FieldHistory.vue'
import FarmFieldNotes from '@/views/MainPage/HomeView/Sideboards/FarmBoard/SubfieldBoard/NoteBoard/FieldNotes.vue'
import FarmFieldAnalytics from '@/views/MainPage/HomeView/Sideboards/FarmBoard/SubfieldBoard/AnalyticsBoard/FieldAnalytics-full.vue'
import FarmFieldTasks from '@/views/MainPage/HomeView/Sideboards/FarmBoard/SubfieldBoard/TasksBoard/FieldTasks.vue'
import FieldYield from '@/views/MainPage/HomeView/Sideboards/FarmBoard/SubfieldBoard/YieldBoard/FieldYield.vue'
import FieldAgroChem from '@/views/MainPage/HomeView/Sideboards/FarmBoard/SubfieldBoard/AgroChemBoard/FieldAgroChem.vue'

import EditFieldPassport from '@/views/MainPage/HomeView/Sideboards/FarmBoard/SubfieldBoard/EditFieldPassport.vue'
import SubfieldIndicatorBoard from '@/views/MainPage/HomeView/Sideboards/FarmBoard/SubfieldBoard/IndicatorsBoard/Index.vue'
import NoteUpdate from '@/views/MainPage/HomeView/Sideboards/FarmBoard/SubfieldBoard/NoteBoard/NoteUpdate.vue'

import FieldTelematics from '@/views/MainPage/HomeView/Sideboards/FarmBoard/TelematicsBoard/FieldTelematics/FieldTelematics.vue'

import NoteDetails from '@/views/MainPage/HomeView/Sideboards/FarmBoard/SubfieldBoard/NoteBoard/NoteDetails.vue'

const routes = [
    {
        path: 'cadastres/farm/:farmId/subfields/:subfieldId',
        name: 'subfield',
        component: FarmSubfieldBoard,
        children: [
            {
                path: 'history',
                name: 'subfieldhistory',
                component: FarmFieldHistory
            },
            {
                path: 'notes',
                name: 'subfieldnotes',
                component: FarmFieldNotes
            },
            {
                path: 'analytics',
                name: 'subfieldanalytics',
                component: FarmFieldAnalytics
            },
            {
                path: 'tasks',
                name: 'subfieldtasks',
                component: FarmFieldTasks
            }
        ]
    },
    {
        path: 'cadastres/farm/:farmId/subfields/:subfieldId/notes/:noteId',
        name: 'subfieldnotedetails',
        component: NoteDetails
    },
    {
        path: 'editfieldpassport/farm/:farmId/subfield/:subfieldId',
        name: 'editfieldpassport',
        component: EditFieldPassport
    },
    {
        path: 'cadastres/farm/:farmId/subfields/:subfieldId/indicators',
        name: 'subfieldindicators',
        component: SubfieldIndicatorBoard
    },
    {
        path: 'cadastres/farm/:farmId/subfields/:subfieldId/yield',
        name: 'subfieldyield',
        component: FieldYield
    },
    {
        path: 'cadastres/farm/:farmId/subfields/:subfieldId/noteregistration',
        name: 'noteregistration',
        component: NoteUpdate
    },
    {
        path: 'cadastres/farm/:farmId/subfields/:subfieldId/noteupdate/:noteId',
        name: 'noteupdate',
        component: NoteUpdate
    },
    {
        path: 'cadastres/farm/:farmId/subfields/:subfieldId/notereply/:noteId',
        name: 'notereply',
        component: NoteUpdate
    },
    {
        path: 'cadastres/farm/:farmId/subfields/:subfieldId/telematics/:currentevent?',
        name: 'fieldtelematics',
        component: FieldTelematics
    },
    {
        path: 'cadastres/farm/:farmId/subfields/:subfieldId/agrochem',
        name: 'fieldagrochem',
        component: FieldAgroChem
    }
]

export default routes