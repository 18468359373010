




















import { Component, Vue, Prop, Watch} from 'vue-property-decorator'
@Component
export default class BaseControllerButton extends Vue {
    public $refs!: {
        ButtonContainer: HTMLElement
    }
    @Prop({default: ''}) private src!: string
    @Prop({default: ''}) private tooltiptext!: string
    @Prop({default: ''}) private divID!: string
    @Prop({default: false}) private active!: boolean
    @Prop({default: false}) private active2!: boolean
    @Prop({default: false}) private muted!: boolean
    @Prop({default: false}) private circle!: boolean
    @Prop({default: 'left'}) private position!: string

    private changeIfStandard() {
        const paths = [...this.$refs.ButtonContainer.getElementsByTagName('svg')[0].children]
        for (const path of paths) {
            if (path.getAttribute('fill') !== null) {
                path.setAttribute('fill', '#1C1C1C')
            }
            if (path.getAttribute('stroke') !== null) {
                path.setAttribute('stroke', '#1C1C1C')
            }
        }
    }
    @Watch('active')
    private changeIfActive() {
        if (!this.active) {
            if (!this.muted) this.changeIfStandard()
            return
        }
        const paths = [...this.$refs.ButtonContainer.getElementsByTagName('svg')[0].children]
        for (const path of paths) {
            if (path.getAttribute('fill') !== null) {
                path.setAttribute('fill', '#FFFFFF')
            }
            if (path.getAttribute('stroke') !== null) {
                path.setAttribute('stroke', '#FFFFFF')
            }
        }
    }
    @Watch('muted')
    private changeIfMuted() {
        if (!this.muted) {
            if (!this.active) this.changeIfStandard()
            return
        }
        const paths = [...this.$refs.ButtonContainer.getElementsByTagName('svg')[0].children]
        for (const path of paths) {
            if (path.getAttribute('fill') !== null) {
                path.setAttribute('fill', '#ABAEA3')
            }
            if (path.getAttribute('stroke') !== null) {
                path.setAttribute('stroke', '#ABAEA3')
            }
        }
    }
}
