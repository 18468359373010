




























import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
@Component
export default class BaseMenuItem extends Vue {
    @Prop() private text!: string
    @Prop({default: ''}) private icon!: string
    @Prop({default: ''}) private textColor!: string
    @Prop({default: ''}) private strokeColor!: string
    @Prop({default: false}) private active!: boolean
    @Prop({default: false}) private beta!: boolean
    private isHovering = false

}
