







































































import { Component, Vue } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import { GetterNames, MutationNames } from '@/store/types'
import { httpGet } from '@/util/http'
import { apiUrl, apiUrl3 } from '@/domain/constants'
import NewEventModal from '@/views/MainPage/HomeView/Sideboards/FarmBoard/TechMapBoard/components/NewEventModal.vue'
import EventsView from '@/views/MainPage/HomeView/Sideboards/FarmBoard/TechMapBoard/components/EventsView.vue'
@Component({
    components: {
        NewEventModal,
        EventsView
    }
})
export default class FieldTasks extends Vue {
    @Getter(GetterNames.getSidebarModel) private getSidebarModel!: any
    @Getter(GetterNames.getPermissions) private getPermissions!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    private techmap = null
    private tasks = []
    private arrForEventTechnic: any = []
    private arrForEventsFeilds: any = []
    private arrForEventsPreparations: any = []
    private arrForEventsCultures: any = []
    private dropdownFields: any = false

    private mounted() {
        this.fetchData()
    }

    private fetchData() {
        this.techmap = null
        this.tasks = []
        this.setLoadingDataNames('FieldTasksFetchFieldTechmap')
        httpGet({
            url: `${apiUrl}/technology/fact/tasks/by/field/${this.$route.params.subfieldId}/detail/`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                if (json.ok) {
                    this.techmap = json.json
                    this.tasks = json.json
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('FieldTasksFetchFieldTechmap')
            }
        })
    }

    private getDaysAboutEvents(date: any) {
        const newData = date.split('T').reverse().splice(1).join('-')
        return newData.split('-').reverse().join('.')
    }

    private getCountOfDaysEvents(date: any) {
        const start = date.split('T').reverse().slice(1).join() // mm-dd-yyyy
        const end = new Date() // Wed Feb 02 2022 00:00:00

        const date1 = new Date(start) // Wed Feb 02 2022 00:00:00
        const date2 = new Date(end) // Sep 08 2022 18:17:32

        const oneDay = 1000 * 60 * 60 * 24 // 86400000

        const diffInTime = date2.getTime() - date1.getTime() // 1662639472750 - 1643738400000
        return Math.round(diffInTime / oneDay) // Math.round(8901072750 / 86400000)
    }

    private close() {
        this.$router.push({
            name: 'subfield',
            params: {
                farmId: this.$route.params.farmId,
                subfieldId: this.$route.params.subfieldId
            }
        })
    }
}
