import i18n from '@/i18n'

export const CultureReproduction: any = [
  {
    name: "Оригинальные семена (суперэлита)",
    short: "суперэлита"
  },
  {
    name: "Cемена элита",
    short: "элита"
  },
  {
    name: "Гибрид F1",
    short: "F1"
  },
  {
    name: "Репродукционные семена I",
    short: "I реп."
  },
  {
    name: "Репродукционные семена II",
    short: "II реп."
  },
  {
    name: "Репродукционные семена III",
    short: "III реп."
  },
  {
    name: "Репродукционные семена IV",
    short: "IV реп."
  },
  {
    name: "Репродукционные семена V",
    short: "V реп."
  }
]

export const CultureColors: any = {
  defined: [
    {
      crop: 'Пшеница мягкая яровая',
      color: '#B2F507'
    },
    {
      crop: 'Пшеница твердая яровая',
      color: '#B2F59f'
    },
    {
      crop: 'Ячмень',
      color: '#06BAF2'
    },
    {
      crop: 'Подсолнечник',
      color: '#5AF5D9'
    },
    {
      crop: 'Лен',
      color: '#F37500'
    },
    {
      crop: 'Рапс',
      color: '#FC7BE7'
    },
    {
      crop: 'Кукуруза',
      color: '#719D00'
    },
    {
      crop: 'Горох',
      color: '#AA27E8'
    },
    {
      crop: 'Чечевица',
      color: '#2B4DFF'
    },
    {
      crop: 'Соя',
      color: '#FFD400'
    },
    {
      crop: 'Овес',
      color: '#FF0000'
    },
    ///
    {
      crop: 'Пшеница тургидум',
      color: '#abfcb3'
    },
    {
      crop: 'Подсолнечник',
      color: '#91f7bc'
    },
    {
      crop: 'Рапс озимый на корм',
      color: '#97f4b8'
    },
    {
      crop: 'Рапс озимый',
      color: '#f2a7bc'
    },
    {
      crop: 'Рапс яровой',
      color: '#f7b4c5'
    },
    {
      crop: 'Рапс яровой на корм',
      color: '#6ecfea'
    },
    {
      crop: 'Лен масличный',
      color: '#d6f78f'
    },
    {
      crop: 'Горох посевной',
      color: '#a04907'
    },
    {
      crop: 'Капуста савойская',
      color: '#81ef9d'
    },
    {
      crop: 'Житняк',
      color: '#a4f9e8'
    },
    {
      crop: 'Овес яровой',
      color: '#d897f4'
    },
    {
      crop: 'Анис',
      color: '#f896ff'
    },
    {
      crop: 'Абрикос',
      color: '#4F0BBD'
    },
    {
      crop: 'Редька масличная',
      color: '#f9bdc7'
    },
    {
      crop: 'Ячмень озимый',
      color: '#73ef81'
    },
    {
      crop: 'Арбуз',
      color: '#ca69ea'
    },
    {
      crop: 'Борщевик Сосновского',
      color: '#e61df7'
    },
    {
      crop: 'Вика',
      color: '#5f02dc'
    },
    {
      crop: 'Горец Вейриха',
      color: '#ab7263'
    },
    {
      crop: 'Горчица сизая',
      color: '#1a70e5'
    },
    {
      crop: 'Горчица сарептская',
      color: '#4ecbd5'
    },
    {
      crop: 'Горчича салатная',
      color: '#a1bef1'
    },
    {
      crop: 'Гречиха',
      color: '#8F2323'
    },
    {
      crop: 'Дыня',
      color: '#b864d9'
    },
    {
      crop: 'Земляная груша',
      color: '#b864d9'
    },
    {
      crop: 'Кенаф',
      color: '#d7c704'
    },
    {
      crop: 'Клевер луговой',
      color: '#eda84e'
    },
    {
      crop: 'Клевер пунцовый',
      color: '#09c31b'
    },
    {
      crop: 'Клещевина',
      color: '#09c31b'
    },
    {
      crop: 'Козлятник восточный',
      color: '#09c31b'
    },
    {
      crop: 'Кольраби',
      color: '#2b756d'
    },
    {
      crop: 'Конопля',
      color: '#2b756d'
    },
    {
      crop: 'Конопля',
      color: '#6beaca'
    },
    {
      crop: 'Кориандр',
      color: '#6beaca'
    },
    {
      crop: 'Капуста белокочанная',
      color: '#b9e501'
    },
    {
      crop: 'Айва',
      color: '#b9e501'
    },
    {
      crop: 'Рис',
      color: '#9255db'
    },
    {
      crop: 'Картофель',
      color: '#355b9e'
    },
    {
      crop: 'Ячмень яровой',
      color: '#4F8F23'
    },
    {
      crop: 'Ячмень яровой',
      color: '#4F8F23'
    },
    {
      crop: 'Пшеница твердая яровая',
      color: '#27aa20'
    },
    {
      crop: 'Пшеница мягкая  яровая',
      color: '#105fcd'
    },
    {
      crop: 'Кабачок',
      color: '#23628F'
    },
    {
      crop: 'Просо',
      color: '#f28f48'
    },
    {
      crop: 'Просо',
      color: '#f28f48'
    },
    {
      crop: 'Виноград',
      color: '#ec663e'
    },
    {
      crop: 'Клубника',
      color: '#7a5ef5'
    },
    {
      crop: 'Яблоня',
      color: '#dda627'
    },
    {
      crop: 'Тритикале озимая ',
      color: '#cf5cb1'
    }
  ],
  others: [
    '#b673dd',
    '#b26ad8',
    '#4F8F23',
    '#8F6A23',
    '#23628F',
    '#8F6A23',
    '#8F6A23',
    '#8F6A23',
    '#355b9e',
    '#a04907'
  ]
}