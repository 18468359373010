



































































import { Component, Vue, Watch} from 'vue-property-decorator'
import { GetterNames, MutationNames } from '@/store/types'
import { Getter, Mutation } from 'vuex-class'
import { httpPut, httpPost, httpDelete } from '@/util/http'
import { apiUrl } from '@/domain/constants'
import olStyle from 'ol/style/Style'
import olFill from 'ol/style/Fill'
import olStroke from 'ol/style/Stroke'
import olCircle from 'ol/style/Circle'
import olMultiPoint from 'ol/geom/MultiPoint'
import { geojson2wkt } from '@/utils/services'
import * as turf from '@turf/turf'
@Component
export default class GeozoneEdit extends Vue {
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: any
    @Getter(GetterNames.getTelematicsInfo) private getTelematicsInfo!: any
    @Mutation(MutationNames.setTelematicsInfo) private setTelematicsInfo!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    @Mutation(MutationNames.setSideboardIsShown) private setSideboardIsShown!: any
    private drawnFeatures: any = []
    private drawnVertices: any = []
    private drawnLines: any = []
    private finalResult: any = null

    @Watch('getTelematicsInfo.geozone.edit')
    private async onchangeEditStage(val: any, old: any) {
        if (val && val !== old) {
            if (val === 1) {
                this.drawnVertices = []
                this.drawnFeatures = []
                this.drawnLines = []
                if (this.getTelematicsInfo.geozone.data) {
                    this.drawnFeatures.push({
                        type: "Feature",
                        geometry: this.getTelematicsInfo.geozone.data.geom
                    })
                }
            } else if (val === 3) {
                this.drawnFeatures = []
                this.drawnVertices = []
                this.drawnLines = []
                this.setTelematicsInfo({geozone: {edit: 0}})
                this.setSideboardIsShown(true)
            } else if (val === 4) {
                await this.mergePolygons()
                if (this.drawnFeatures.length > 0 && !this.getTelematicsInfo.geozone.data) {
                    const result = geojson2wkt(this.drawnFeatures[0].geometry)
                    this.setLoadingDataNames('GeozoneEditAddGeozone')
                    httpPost({
                        url:  `${apiUrl}/geozone/`,
                        isSecureRequest: true,
                        detailResponse: true,
                        body: {
                            group_user: Number(this.currentRoute.params.farmId),
                            geom: result
                        },
                        onSuccess: json => {
                            if (json.ok) {
                                this.$alert('Геозона успешно обновлено', 'Сохранено', 'success')
                                this.setTelematicsInfo({
                                    geozone: {
                                        data: json.json
                                    }
                                })
                            } else {
                                this.$alert('Ошибка сервера', 'Ошибка', 'error')
                            }
                        },
                        onError: error => {
                            console.log(error)
                        },
                        doFinally: () => {
                            this.setLoadingDataNames('GeozoneEditAddGeozone')
                        }
                    })
                } else if (this.drawnFeatures.length > 0 && this.getTelematicsInfo.geozone.data) {
                    const result = geojson2wkt(this.drawnFeatures[0].geometry)
                    this.setLoadingDataNames('GeozoneEditUpdageGeozone')
                    httpPut({
                        url: `${apiUrl}/geozone/${this.getTelematicsInfo.geozone.data.id}/`,
                        isSecureRequest: true,
                        detailResponse: true,
                        body: {
                            geom: result
                        },
                        onSuccess: json => {
                            if (json.ok) {
                                this.$alert('Геозона успешно обновлено', 'Сохранено', 'success')
                                this.setTelematicsInfo({
                                    geozone: {
                                        data: json.json
                                    }
                                })
                            } else {
                                this.$alert('Ошибка сервера', 'Ошибка', 'error')
                            }
                        },
                        onError: error => {
                            console.log(error)
                        },
                        doFinally: () => {
                            this.setLoadingDataNames('GeozoneEditUpdageGeozone')
                        }
                    })
                } else if (this.drawnFeatures.length === 0 && this.getTelematicsInfo.geozone.data) {
                    this.setLoadingDataNames('GeozoneEditDeleteGeozone')
                    httpDelete({
                        url: `${apiUrl}/geozone/${this.getTelematicsInfo.geozone.data.id}/`,
                        isSecureRequest: true,
                        detailResponse: true,
                        onSuccess: json => {
                            if (json.ok) {
                                this.$alert('Геозона успешно обновлено', 'Сохранено', 'success')
                                this.setTelematicsInfo({
                                    geozone: {
                                        data: null
                                    }
                                })
                            } else {
                                this.$alert('Ошибка сервера', 'Ошибка', 'error')
                            }
                        },
                        onError: error => {
                            console.log(error)
                        },
                        doFinally: () => {
                            this.setLoadingDataNames('GeozoneEditDeleteGeozone')
                        }
                    })
                }
                this.setTelematicsInfo({
                    geozone: {
                        edit: 0,
                        type: 'edit'
                    }
                })
                this.drawnFeatures = []
                this.drawnVertices = []
                this.drawnLines = []
                this.setSideboardIsShown(true)
            }
        }
    }

    private verticesDrawEnd() {
        if (this.drawnFeatures.length > 0 && this.drawnVertices.length > 0) {
            const detectPolygon = turf.polygon(this.drawnVertices[0].geometry.coordinates)
            const features: any = []
            for (const feature of this.drawnFeatures) {
                const coordinates = JSON.parse(JSON.stringify(feature.geometry.coordinates))
                const result: any = []
                for (const [i, container] of coordinates.entries()) {
                    const polygoncontainer = []
                    for (const [j, polygon] of container.entries()) {
                        const points = turf.points(polygon)
                        const ptsWithin: any = turf.pointsWithinPolygon(points, detectPolygon)
                        const copyofpolygon = JSON.parse(JSON.stringify(polygon))
                        for (const point of ptsWithin.features) {
                            const coordinate = point.geometry.coordinates
                            while (true) {
                                const foundpoint = copyofpolygon.find((x: any) => JSON.stringify(x) === JSON.stringify(coordinate))
                                if (foundpoint) {
                                    const index = copyofpolygon.indexOf(foundpoint)
                                    copyofpolygon.splice(index, 1)
                                } else {
                                    break
                                }
                            }
                        }
                        if (copyofpolygon.length === 0) {
                            continue
                        } else if (JSON.stringify(copyofpolygon[0]) !== JSON.stringify(copyofpolygon[copyofpolygon.length - 1])) {
                            copyofpolygon.push(copyofpolygon[0])
                        }
                        if (copyofpolygon.length > 3) {
                            polygoncontainer.push(copyofpolygon)
                        }
                    }
                    if (polygoncontainer.length === 0) {
                        continue
                    }
                    result.push(polygoncontainer)
                }
                if (result.length > 0) {
                    features.push({
                        type: "Feature",
                        id: feature.id,
                        properties: {
                            id: feature.id
                        },
                        geometry: {
                            type: "MultiPolygon",
                            coordinates: result
                        }
                    })
                }
            }
            this.drawnFeatures = features
            this.drawnVertices = []
        }
    }

    private async roadDrawEnd() {
        if (this.drawnLines.length > 0) {
            const line = turf.toWgs84(turf.lineString(JSON.parse(JSON.stringify(this.drawnLines[0].geometry.coordinates))))
            const road: any = turf.toMercator(turf.buffer(line, 0.01))
            this.drawnFeatures.push({
                type: "Feature",
                id: Math.random(),
                properties: {
                    id: Math.random()
                },
                geometry: {
                    type: 'MultiPolygon',
                    coordinates: road.geometry.type === 'MultiPolygon' ? road.geometry.coordinates : [road.geometry.coordinates]
                }
            })
            this.drawnLines = []
            await this.mergePolygons()
        }
    }

    private mergePolygons() {
        return new Promise((resolve, reject) => {
            if (this.drawnFeatures.length > 0) {
                let union: any = turf.multiPolygon(this.drawnFeatures[0].geometry.coordinates)
                for (let i = 1; i < this.drawnFeatures.length; i++) {
                    const multiPoly: any = turf.multiPolygon(this.drawnFeatures[i].geometry.coordinates)
                    const tempUnion = turf.union(union, multiPoly)
                    union = tempUnion
                }
                if (union) {
                    let optimizeUnion: any = null
                    if (union.geometry.type === 'MultiPolygon') {
                        optimizeUnion = turf.multiPolygon([union.geometry.coordinates[0]])
                        for (let i = 1; i < union.geometry.coordinates.length; i++) {
                            const multiPoly: any = turf.multiPolygon([union.geometry.coordinates[i]])
                            const tempUnion = turf.union(optimizeUnion, multiPoly)
                            optimizeUnion = tempUnion
                        }
                    } else {
                        optimizeUnion = turf.multiPolygon([union.geometry.coordinates])
                    }
                    if (optimizeUnion) {
                        this.drawnFeatures = []
                        this.drawnFeatures.push({
                            type: "Feature",
                            geometry: optimizeUnion.geometry
                        })
                    }
                }
            }
            return resolve(true)
        })
    }

    private styleFuncFactory() {
        return (feature: any) => {
            const firstconcat = [].concat.apply([], feature.getGeometry().getCoordinates())
            const coordinates = [].concat.apply([], firstconcat)
            const styles = [
                new olStyle({
                    stroke: new olStroke({
                        color: '#FFC700',
                        width: 2,
                        lineDash: [8, 16]
                    }),
                    fill: new olFill({
                        color: 'rgba(255, 199, 0, 0.2)'
                    })
                }),
                new olStyle({
                    image: new olCircle({
                        radius: 5,
                        stroke: new olStroke({
                            color: '#FFFFFF',
                            width: 2
                        }),
                        fill: new olFill({
                            color: '#4B970F'
                        })
                    }),
                    geometry: new olMultiPoint(coordinates)
                })
            ]
            return styles
        }
    }

}
