































































































import { Component, Vue } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import { GetterNames, MutationNames } from '@/store/types'
import { httpGet } from '@/util/http'
import { apiUrl } from '@/domain/constants'
import NewEventModel from '../../../FarmBoard/TechMapBoard/components/NewEventModal.vue'
import EventViewModel from './EventViewModel.vue'
import i18n from '@/i18n'
@Component({
    components: {
        NewEventModel,
        EventViewModel
    }
})
export default class TemplateEventsView extends Vue {
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: any
    @Getter(GetterNames.getSidebarModel) private getSidebarModel!: any
    @Getter(GetterNames.getLoadingDataNames) private getLoadingDataNames!: any
    @Getter(GetterNames.getPermissions) private getPermissions!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    private currentTemplate: any = null
    private moreButtunDropdown = false
    private moreinfoIds: any = []

    private normalizeDate(date: any) {
        if (date) {
            return date.substring(8, 10) + '.' + date.substring(5, 7) + '.' + date.substring(0, 4)
        } else {
            return i18n.t('general.dates.format_date')
        }
    }

    private mounted() {
        this.fetchData()
    }

    private fetchData() {
        this.setLoadingDataNames('TemplatesViewGetTemplates')
        httpGet({
            url:  `${apiUrl}/techmap/tasks/by/techmap/${this.currentRoute.params.templateId}/`,
            isSecureRequest: true,
            onSuccess: json => {
                this.currentTemplate = json
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('TemplatesViewGetTemplates')
            }
        })
    }

    private toggleMoreInfoIds(id: any) {
        const index = this.moreinfoIds.indexOf(id)
        if (index === -1) {
            this.moreinfoIds.push(id)
        } else {
            this.moreinfoIds.splice(index, 1)
        }
    }

    private toggleMoreButton() {
        this.moreButtunDropdown = !this.moreButtunDropdown
    }

    private closeMorebtnDropdown() {
        this.moreButtunDropdown = false
    }

    private close() {
        this.$router.push({
            name: 'templates'
        })
    }
}
