























import { Component, Vue, Watch } from 'vue-property-decorator'
import { Mutation, Getter } from 'vuex-class'
import {GetterNames, MutationNames} from '@/store/types'
import '@/assets/sounds/notification.wav'
import { getAuthToken } from '@/domain/constants'
import { baseSocketUrl } from '@/domain/constants'
@Component({
    components: {
    }
})
export default class ContactModel extends Vue {
    @Getter(GetterNames.IS_SIGNED_IN) private isSignedIn !: boolean
    @Getter(GetterNames.getPermissions) private getPermissions!: any
    @Mutation(MutationNames.setNotificationData) private setNotificationData!: any
    private socket: any = null
    private reconnect: any = {
        seconds: 1,
        attempt: 0
    }
    private notifications: any = []
    private myAudioElement: any = null
    private canplaythrough = false
    private token: any = ''

    @Watch('isSignedIn')
    private onchangeIsSignedIn() {
        if (this.isSignedIn) {
            this.createSocket()
        } else {
            this.clearSocket()
        }
    }

    private mounted() {
        this.myAudioElement = new Audio(require('@/assets/sounds/notification.wav'))
        this.myAudioElement.addEventListener("canplaythrough", () => {
            this.canplaythrough = true
        })
        this.onchangeIsSignedIn()
    }

    private removeNotification() {
        if (this.notifications.length > 1) {
            this.notifications.splice(0, 1)
        }
    }

    private addnewNotification(notification: any) {
        if (this.canplaythrough) {
            this.myAudioElement.play()
        }
        this.notifications.push(notification)
        if (this.notifications.length > 3) {
            this.notifications.shift()
        }
        setTimeout(() => this.removeNotification(), 5000)
    }

    private createSocket() {
        if (!this.isSignedIn) return
        const currentToken = getAuthToken().split(' ')[1]
        if (currentToken === this.token) return
        if (this.socket) {
            if (this.socket.readyState === WebSocket.OPEN) {
                this.socket.close()
            }
        }
        this.token = currentToken
        this.socket = new WebSocket(`${baseSocketUrl}/ws/notifier/`, ['subprotocol', this.token])
        this.socket.onclose = (event: any) => {
            this.token = ''
            if (event.wasClean) {
                // console.log(`[close] Соединение закрыто чисто, код=${event.code} причина=${event.reason}`)
            } else {
                // console.log('[close] Соединение прервано')
                if (this.reconnect.attempt !== 3) {
                    setTimeout(() => this.createSocket(), this.reconnect.seconds * 1000)
                }
                if (this.reconnect.seconds === 1) {
                    this.reconnect.seconds = 10
                } else if (this.reconnect.seconds === 10) {
                    this.reconnect.seconds = 30
                } else if (this.reconnect.seconds === 30) {
                    this.reconnect.seconds = 60
                } else {
                    this.reconnect.attempt += 1
                }
            }
        }
        this.socket.onerror = (error: any) => {
            this.token = ''
        }
        this.socket.onmessage = (event: any) => {
            const response: any = JSON.parse(event.data)
            if (response.message) {
                if (response.message.hasOwnProperty('count')) {
                    if (!isNaN(response.message.count)) {
                        if (response.message.data) {
                            if (!response.message.data.is_user_level) {
                                this.setNotificationData({count: response.message.count})
                            }
                        } else {
                            this.setNotificationData({count: response.message.count})
                        }
                    }
                }
                if (response.message.data) {
                    this.addnewNotification(response.message.data)
                }
            }
        }
    }

    @Watch('getPermissions')
    private onchangeWorkspace(newVal: any, oldVal: any) {
        setTimeout(() => this.createSocket(), 500)
        this.notifications = []
    }

    private clearSocket() {
        if (this.socket) {
            if (this.socket.readyState === WebSocket.OPEN) {
                this.socket.close()
            }
        }
    }

    private openNotification(notification: any) {
        this.$ga.event('message', 'open', 'from pop-up window', 1)
        if (notification.is_user_level) {
            this.$router.push({
                name: 'workspace'
            })
        } else {
        this.$router.push({
            name: 'notificationmessage',
            params: {
                info: JSON.stringify({
                    category: 0,
                    type: 0,
                    page: 1
                }),
                messageId: notification.id
            }
            }).catch(err => {/**/})
        this.notifications = []
        }
    }

    private closeNotification(index: any) {
        if (this.notifications[index]) {
            this.notifications.splice(index, 1)
        }
    }
}
