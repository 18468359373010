





































































import { Component, Vue, Emit } from 'vue-property-decorator'
import { httpGet, httpPostFormData, httpPutFormData } from '@/util/http'
import { apiUrl } from '@/domain/constants'
import { GetterNames, MutationNames } from '@/store/types'
import { Getter, Mutation } from 'vuex-class'
import i18n from '@/i18n'
@Component
export default class NewAgregateModel extends Vue {
    @Getter(GetterNames.getLoadingDataNames) private getLoadingDataNames!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    @Mutation(MutationNames.setPhotosData) private setPhotosData!: any
    private isShown = false
    private agregate: any = {
        name: {
            converted: [],
            selected: '',
            onerror: ''
        },
        category: {
            origin: [],
            converted: [],
            id: 0,
            selected: '',
            onerror: ''
        },
        coverage: {
            selected: '',
            onerror: ''
        },
        image: {
            file: null,
            url: null
        }
    }
    private carModels: any = []
    private uploadedFile: any = []
    private imageData: any =  []
    private agregateForUpdate: any = null
    private isUpdate = false

    private onSelectAgregateName(item: any) {
        this.agregate.name.selected = item
        if (this.agregate.category.id === 0) {
            const category = this.agregate.category.origin.find((x: any) => x.name === item)
            if (category) {
                this.agregate.category.id = category.id
                this.agregate.category.selected = category.name
            }
        }
    }

    private onSelectAgregateCategory(item: any) {
        const category = this.agregate.category.origin.find((x: any) => x.name === item)
        if (category) {
            this.agregate.category.id = category.id
            this.agregate.category.selected = category.name
            this.agregate.name.converted = category.models.map((x: any) => x.name)
            this.agregate.name.selected = ''
        }
    }

    private getImageUrl(files: any) {
        for (const file of files) {
            this.agregate.image.file = file
            this.agregate.image.url = URL.createObjectURL(file)
        }
    }

    private openImage() {
        if (this.agregate.image.url) {
            const images = []
            if (this.agregate.image.file) {
                images.push(this.agregate.image.file)
            } else {
                images.push({
                    created_at: '',
                    full_name: '',
                    id: 1,
                    image: this.agregate.image.url,
                    is_mobile: false
                })
            }
            this.setPhotosData({currentIndex: 0, data: images})
        }
    }

    private deleteImage() {
        this.agregate.image.file = null
        this.agregate.image.url = null
    }

    private update(agregate: any) {
        this.isShown = true
        this.isUpdate = true
        this.agregateForUpdate = agregate
        this.agregate = {
            name: {
                converted: [],
                selected: agregate.name,
                onerror: ''
            },
            category: {
                origin: [],
                converted: [],
                id: agregate.category,
                selected: '',
                onerror: ''
            },
            coverage: {
                selected: agregate.coverage ? agregate.coverage.toString() : '',
                onerror: ''
            },
            image: {
                file: null,
                url: agregate.image ? agregate.image : null
            }
        }
        this.getAgregateCategories()
    }

    private show() {
        this.isShown = true
        this.isUpdate = false
        this.agregate = {
            name: {
                converted: [],
                selected: '',
                onerror: ''
            },
            category: {
                origin: [],
                converted: [],
                id: 0,
                selected: '',
                onerror: ''
            },
            coverage: {
                selected: '',
                onerror: ''
            },
            image: {
                file: null,
                url: null
            }
        }
        this.getAgregateCategories()
    }

    private getAgregateCategories() {
        this.setLoadingDataNames('newAgregateGetCategories')
        httpGet({
            url:  `${apiUrl}/yields/tractor/data/v2/`,
            isSecureRequest: true,
            onSuccess: json => {
                this.agregate.category.origin = json.trailer_category
                this.agregate.category.converted = json.trailer_category.map((x: any) => x.name)
                if (this.agregate.category.id) {
                    const category = this.agregate.category.origin.find((x: any) => x.id === this.agregate.category.id)
                    if (category) {
                        this.agregate.category.selected = category.name
                        this.agregate.name.converted = category.models.map((x: any) => x.name)
                    }
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('newAgregateGetCategories')
            }
        })
    }

    private updateAgregate() {
        if (this.checkForError()) return
        const formData = new FormData()
        formData.append('name', this.agregate.name.selected)
        formData.append('category', this.agregate.category.id.toString())
        formData.append('coverage', this.agregate.coverage.selected)
        if (this.agregate.image.file) {
            formData.append('image', this.agregate.image.file)
        }
        if (this.agregate.image.url === null) {
            formData.append('is_image_deleted', 'true')
        }
        this.setLoadingDataNames('newAgregateUpdateAgregate')
        httpPutFormData({
            url: `${apiUrl}/yields/trailer/${this.agregateForUpdate.id}`,
            isSecureRequest: true,
            detailResponse: true,
            body: formData,
            onSuccess: json => {
                if (json.ok) {
                    this.$alert(i18n.t('messages.success.device_updated') as any, 'Сохранено', 'success')
                    this.reload()
                    this.hide()
                } else if (json.status === 400) {
                    if (json.json.non_field_errors) {
                        this.$alert(json.json.non_field_errors, 'Ошибка', 'error')
                    } else {
                        this.$alert(json.json, 'Ошибка', 'error')
                    }
                } else {
                    this.$alert('Ошибка сервера', 'Ошибка', 'error')
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('newAgregateUpdateAgregate')
                this.hide()
            }
        })
    }

    private checkForError() {
        let counter = 0
        if (this.agregate.name.selected === '') {
            this.agregate.name.onerror = i18n.t('messages.selections.device')
            counter++
        }
        if (this.agregate.coverage.selected === '') {
            this.agregate.coverage.onerror = i18n.t('messages.instructions.width')
            counter++
        } else if (isNaN(this.agregate.coverage.selected)) {
            this.agregate.coverage.onerror = 'Введите число'
            counter++
        }
        if (this.agregate.category.id === 0) {
            this.agregate.category.onerror = i18n.t('messages.selections.category')
            counter++
        }
        return counter > 0
    }

    private addNewAgregate() {
        if (this.checkForError()) return
        const formData = new FormData()
        formData.append('name', this.agregate.name.selected)
        formData.append('category', this.agregate.category.id.toString())
        formData.append('coverage', this.agregate.coverage.selected)
        if (this.agregate.image.file) {
            formData.append('image', this.agregate.image.file)
        }
        this.setLoadingDataNames('newAgregateAddAgregate')
        httpPostFormData({
            url: apiUrl + '/yields/trailer/',
            isSecureRequest: true,
            detailResponse: true,
            body: formData,
            onSuccess: json => {
                if (json.ok) {
                    this.$alert(i18n.t('messages.success.device_added') as any, 'Сохранено', 'success')
                    this.reload()
                    this.hide()
                } else if (json.status === 400) {
                    if (json.json.non_field_errors) {
                        this.$alert(json.json.non_field_errors, 'Ошибка', 'error')
                    } else {
                        this.$alert(json.json, 'Ошибка', 'error')
                    }
                } else {
                    this.$alert('Ошибка сервера', 'Ошибка', 'error')
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('newAgregateAddAgregate')
            }
        })
    }

    @Emit('reload')
    private reload() {
        return {}
    }

    private hide() {
        this.isShown = false
    }
}
