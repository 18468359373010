


























































































































































































































import {Component, Vue, Watch} from 'vue-property-decorator'
import ChangeAgregateModel from './ChangeAgregateModel.vue'
import EventsStatisticsModal from './EventsStatisticsModal.vue'
import {httpGet, httpPost, httpPostFile, httpDeleteWithBody} from '@/util/http'
import {apiUrl} from '@/domain/constants'
import {GetterNames, MutationNames} from '@/store/types'
import { Getter, Mutation } from 'vuex-class'

@Component({
  components: {
      ChangeAgregateModel,
      EventsStatisticsModal
  }
})

export default class TractorsDailyInfoView extends Vue {
  @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
  @Getter(GetterNames.getCurrentSeason) private getCurrentSeason !: any
  private searchText = ''
  private days: any = []
  private dailyData: any = []
  private startDate = ''
  private endDate: any = ''
  private currentPartId = -1
  private changingFields: any = []
  private selectedFields: any = []
  private selectedFieldNames: any = []
  private scrolledToTheEnd = false
  private deleteModal = false
  private deletingObject: any = {}
  private weeks: any = []
  private calendarColors = ['#F6412D', '#FF5607', '#FF9800', '#FFC100', '#FFEC19']
  private monthsArray = [ 'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август',  'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']
  private maximumReports = 100
  private currentWeekId = 0

  private mounted() {
    this.getCalendarData()
    this.changingFields = []
  }

  private getCalendarData() {
    httpGet({
      url: `${apiUrl}/telematics/get/shift/reports/weeks/?year=${this.getCurrentSeason.name}`,
      isSecureRequest: true,
      detailResponse: true,
      onSuccess: json => {
        if (json) {

          const months = json.json.filter((x: any) => x.month_count).filter((week: any) => week.reports !== 0)
          this.weeks = months
          const weekData = months.flatMap((x: any) => x.weeks).find((week: any) => week.reports !== 0)
          const weekReports = months.flatMap((x: any) => x.weeks).map((week: any) => week.reports)
          this.maximumReports = Math.max(...weekReports) + 1


          if (weekData) {
            this.currentWeekId = weekData.week
            const startDay = new Date(weekData.date_end)
            startDay.setDate(startDay.getDate())
            this.startDate = startDay.toLocaleDateString("sv-SE")
            this.setDaysList(startDay)
            this.fetchData(false)
          }
        }
      },
      onError: error => {
        console.log(error)
      }
    })
  }

  private getDataRange(week: any) {
    if (this.startDate === week.date_end) {
      const startDate = new Date(week.date_end)
      const endDate = new Date(week.date_start)

      const startString = startDate.toLocaleString('ru-RU', { month: 'short', day: 'numeric'})
      const endString = endDate.toLocaleString('ru-RU', { month: 'short', day: 'numeric'})

      return `( ${startString} - ${endString} )`
    }

    return ''
  }

  private notLastWeek() {
    if (this.currentWeekId === -1 ) return false
    const lastWeek = this.weeks.flatMap((x: any) => x.weeks).filter((week: any) => week.reports !== 0).slice(-1)
    if (lastWeek) {
      return lastWeek[0].week !== this.currentWeekId
    }

    return false
  }

  private setDaysList(start: any, withPrevious?: any) {
    if (!withPrevious) {
      this.days = []
    }
    for (let i = 0; i < 7; i++) {
      const day = new Date(start)
      day.setDate(start.getDate() - i)
      this.endDate = day
      const dayData = {
        date: day.toLocaleDateString("sv-SE"),
        stringDate: day.toLocaleString('ru-RU', { month: 'short', day: 'numeric'}),
        dataQuantity: 0
      }
      this.days.push(dayData)
    }
  }

  private scrollFunction(event: any) {
     this.scrolledToTheEnd = Math.round(event.target.scrollLeft) + 10 > event.target.scrollWidth - event.target.clientWidth
  }

  private showNextPage(week?: any) {
    let nextWeekDay: any
    let nextWeekString: any
    if (!week) {
      const newWeekData = this.weeks.flatMap((x: any) => x.weeks).filter((weekData: any) => weekData.reports !== 0).find((weekFiltered: any) => weekFiltered.week > this.currentWeekId)

      if (newWeekData) {
        this.currentWeekId = newWeekData.week
        nextWeekDay = new Date(newWeekData.date_end)
      } else {
        this.currentWeekId = -1
      }
    } else {
      this.currentWeekId = week.week
      nextWeekDay = new Date(week.date_end)
    }

    nextWeekString = nextWeekDay.toLocaleDateString("sv-SE")
    const withPrevious = week ? false : true
    this.startDate = nextWeekString
    this.setDaysList(nextWeekDay, withPrevious)
    this.fetchData(withPrevious, nextWeekString)
    this.scrolledToTheEnd = false
  }

  @Watch('dailyData')
  private onChangeDaily(newVal: any, oldVal: any) {
    const daysCount: any = {}
    newVal.forEach((tractor: any) => Object.entries(tractor.reports).forEach((day: any) => {
        Object.entries(day[1]).forEach((part: any) => {
          if (part[1].length > 0) {
            if (daysCount[day[0]]) {
              daysCount[day[0]] += 1
            } else {
              daysCount[day[0]] = 1
            }
          }
        })
    }))
    Object.entries(daysCount).forEach((day: any) => {
      const daysDayIndex = this.days.findIndex((dayData: any) => dayData.date === day[0])
      if (daysDayIndex !== -1) {
        this.days[daysDayIndex].dataQuantity = day[1]
      }
    })
  }

  private updateData(data: any) {
      if (Array.isArray(data)) {
          data.forEach((day: any) => {
              const tractorIndex = this.dailyData.findIndex((tractor: any) => tractor.imei === day.imei)
              if (tractorIndex !== -1) {
                  const dayData = this.dailyData[tractorIndex].reports[day.date]
                  if (dayData) {
                      this.dailyData[tractorIndex].reports[day.date][day.shift ? day.shift : 1][0] = day
                  }
              }
          })
          this.$forceUpdate()
          return
      }
      const tractorIdx = this.dailyData.findIndex((tractor: any) => tractor.imei === data.imei)
      if (tractorIdx !== -1) {
          const dayData = this.dailyData[tractorIdx].reports[data.date]
          if (dayData) {
              this.dailyData[tractorIdx].reports[data.date][data.shift ? data.shift : 1][0] = data
              this.$forceUpdate()
          }
      } else {
          const tractorInfoIdx = this.dailyData.findIndex((tractor: any) => tractor.id === data.tractor.id)
          if (tractorInfoIdx !== -1) {
              this.dailyData[tractorInfoIdx].trailer = data.trailer
              this.dailyData[tractorInfoIdx].task_static_name = data.task_static
              this.dailyData[tractorInfoIdx].max_speed = data.max_speed
              this.dailyData[tractorInfoIdx].min_speed = data.min_speed
              this.$forceUpdate()
          }
      }
  }

  private getTractorsList() {
    const searchValue = this.searchText.trim()
    const searchResult = []
    if (this.dailyData) {
      for (const tractor of this.dailyData) {
        if (tractor.class_name.toLowerCase().includes(searchValue.toLowerCase()) ) {
          searchResult.push(tractor)
        } else if (tractor.model_name.toLowerCase().includes(searchValue.toLowerCase()) ) {
          searchResult.push(tractor)
        } else if (tractor.uid.toLowerCase().includes(searchValue.toLowerCase()) ) {
          searchResult.push(tractor)
        }
      }
    }

    return searchResult
  }

  private setChangingFieldsData(fields: any, partId: number) {
    if (this.currentPartId !== partId) {
      this.currentPartId = partId
      this.selectedFields = []
      this.selectedFieldNames = []
      let fieldsData: any = []
      if (fields) {
        fieldsData = fields.map((field: any) => {
          if (!field.hide) {
            this.selectedFields.push(field.field_id)
            this.selectedFieldNames.push(`№${field.field_name}`)
          }
          return {
            id: field.field_id,
            hide: field.hide,
            name: field.field_name,
            area: field.area
          }
        })
      }
      this.changingFields = fieldsData
    }
  }

  private onChangeFields(field: any) {
      const currentField = this.changingFields.findIndex((x: any) => x.id === field.id)
      if (currentField !== -1) {
        this.changingFields[currentField].hide = !this.changingFields[currentField].hide

        const selectedField = this.selectedFields.findIndex((x: any) => x === field.id)
        if (selectedField !== -1) {
          this.selectedFields.splice(selectedField, 1)
          this.selectedFieldNames.splice(selectedField, 1)
        } else {
          this.selectedFields.push(field.id)
          this.selectedFieldNames.push(`№${field.name}`)
        }
        this.changeFieldsAppearance(field.id)
      }
  }

  private changeFieldsAppearance(id: number) {
    if (this.currentPartId === -1) return
    httpPost({
      url:  `${apiUrl}/telematics/get/shift/reports/${this.currentPartId}/hide/`,
      isSecureRequest: true,
      detailResponse: true,
      body: {
        field_ids: [id]
      },
      onSuccess: json => {
        if (json.ok) {
          // this.$alert('Поля успешно обнавлены', 'Сохранено', 'success')
          const tractorIndex = this.dailyData.findIndex((tractor: any) => tractor.imei === json.json.imei)
          if (tractorIndex !== -1) {
            this.dailyData[tractorIndex].reports[json.json.date][json.json.shift ? json.json.shift : 1][0] = json.json
          }
        } else {
          this.$alert('Ошибка сервера', 'Ошибка', 'error')
        }
      },
      onError: error => {
        console.log(error)
      }
    })
  }

  private getDaysInfo(daysReport: any) {
    const formattedDays: any = []
    Object.entries(daysReport).forEach((day: any) => {
      const formattedDay: any = []
      Object.entries(day[1]).forEach((part: any) => {
        if (part[1].length > 0) {
          const partData = part[1][0]
          const newPart = {
            id: partData.id,
            task_name: partData.other_params && partData.other_params.has_task ? partData.other_params.task_name : '',
            applied_coverage: Math.round(partData.applied_coverage * 100) / 100,
            area: Math.round(partData.area * 100) / 100,
            date: partData.date,
            driver_full_name: partData.driver_full_name ? partData.driver_full_name : 'Нет данных',
            fields: partData.other_params && partData.other_params.fields ? partData.other_params.fields : [],
            fieldNames: partData.other_params && partData.other_params.fields ? partData.other_params.fields.filter((field: any) => field.hide === false).map((field: any) => `№${field.field_name}`) : [],
            imei: partData.imei,
            length: Math.round(partData.length * 100) / 100,
            working_hours: partData.other_params ? Math.round(partData.other_params.working_hours * 100) / 100 + 'ч.' : 'Нет данных',
            fuel_cost_per_ga: partData.fuel_params ? Math.round(partData.fuel_params.fuel_cost_per_ga * 100) / 100 : 0,
            fuel_cost_per_km: partData.fuel_params ? Math.round(partData.fuel_params.fuel_cost_per_km * 100) / 100 : 0,
            start_time: partData.shift_start_time ? partData.shift_start_time.slice(0, 5) : 'Нет данных',
            end_time: partData.shift_end_time ? partData.shift_end_time.slice(0, 5) : '',
            max_speed: partData.speed_stat ? Math.round(partData.speed_stat.max * 100) / 100 : 'Нет данных',
            min_speed: partData.speed_stat ? Math.round(partData.speed_stat.min * 100) / 100 : 'Нет данных',
            mean_speed: partData.speed_stat ? Math.round(partData.speed_stat.mean * 100) / 100 : 'Нет данных',
            trailer: partData.trailer ? partData.trailer : -1,
            tractor_shift: partData.tractor_shift ? partData.tractor_shift : -1
          }
          formattedDay.push(newPart)
        }
      })
      formattedDays.push(formattedDay.reverse())
    })
    return formattedDays
  }

  private fetchData(withPrevious: boolean, nextWeek?: any) {
    const sendingDay = nextWeek ? nextWeek : this.startDate
    this.setLoadingDataNames('fetchDailyData')
    httpGet({
      url:  `${apiUrl}/telematics/get/shift/reports/?date=${sendingDay}&group_user=${this.$route.params.farmId}`,
      isSecureRequest: true,
      detailResponse: true,
      onSuccess: json => {
        if (json.ok) {
          if (withPrevious && nextWeek) {
            const newData: any = []
            this.dailyData.forEach((tractor: any) => {
              const newTractorData = json.json.find((track: any) => track.id === tractor.id)
              if (newTractorData) {
                tractor.reports = Object.assign(tractor.reports, newTractorData.reports)
                newData.push(tractor)
              }
            })
            this.dailyData = newData
          } else {
            this.dailyData = json.json
          }
        }
      },
      onError: error => {
        console.log(error)
      },
      doFinally: () => {
        this.setLoadingDataNames('fetchDailyData')
      }
    })
  }

  private downloadDailyData(day: any) {
    this.setLoadingDataNames('downloadDailyData')
    httpPostFile({
      url: `${apiUrl}/telematics/get/shift/reports/excel/?start_date=${day}&end_date=${day}&group_user=${this.$route.params.farmId}`,
      isSecureRequest: true,
      detailResponse: true,
      body: {},
      onSuccess: json => {
        if (json) {
          const url = window.URL.createObjectURL(json)
          const a = document.createElement('a')
          a.href = url
          a.download = "egistic-kz.xlsx"
          document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click()
          a.remove()
        }
      },
      onError: error => {
        console.log(error)
      },
      doFinally: () => {
        this.setLoadingDataNames('downloadDailyData')
      }
    })
  }

  private deleteDailyReport() {
    if (!this.deletingObject) return
    this.setLoadingDataNames('deleteDailyReport')
    httpDeleteWithBody({
      url:  `${apiUrl}/telematics/get/shift/reports/delete/`,
      isSecureRequest: true,
      detailResponse: true,
      body: {
        reports: [this.deletingObject.id]
      },
      onSuccess: json => {
        if (json.ok) {
          this.$alert('Отчет успешно удален', 'Удалено', 'success')
          this.deleteModal = false

          const deletedPartIdx = this.dailyData.findIndex((day: any) => day.imei === this.deletingObject.imei)
          if (deletedPartIdx !== -1) {
              const dayData = this.dailyData[deletedPartIdx].reports[this.deletingObject.date]
              if (dayData) {
                  this.dailyData[deletedPartIdx].reports[this.deletingObject.date][this.deletingObject.tractor_shift.shifts] = []
              }
          }
          this.$forceUpdate()
        } else {
          this.$alert('Ошибка сервера', 'Ошибка', 'error')
        }
      },
      onError: error => {
        console.log(error)
      },
      doFinally: () => {
        this.setLoadingDataNames('deleteDailyReport')
      }
    })
  }

}
