







































































import { Component, Vue, Emit } from 'vue-property-decorator'
import { httpPut } from '@/util/http'
import { apiUrl } from '@/domain/constants'
import { GetterNames, MutationNames } from '@/store/types'
import { Getter, Mutation } from 'vuex-class'
import {unitsData} from '@/data/newOperationData'
import i18n from '@/i18n'
@Component({
    components: {
    }
})
export default class NewStorageModel extends Vue {
    @Getter(GetterNames.getLoadingDataNames) private getLoadingDataNames!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    private isShown = false
    private products: any = null
    private product = {
        name: '',
        id: -1,
        amount: '',
        maxAmount: 9999999999,
        unit: '',
        cost: '',
        nameDropdown: false,
        unitDropdown: false
    }
    private isUpdate = false
    private productForUpdate: any = null
    private units: any = unitsData

    get getUnits() {
        if (this.isUpdate) {
            return []
        } else if (this.products === null) {
            let types: any = []
            for (const type of this.units) {
                types = types.concat(type.types)
            }
            return types
        } else {
            return []
        }
    }

    private setProductFromArray(product: any) {
        this.product.name = product.title
        this.product.id = product.id
        this.product.amount = product.amount.toString()
        this.product.maxAmount = product.amount
        this.product.unit = product.unit
        this.product.cost = product.cost
        this.product.nameDropdown = false
    }

    private show(products: any) {
        this.products = products
        this.isShown = true
        this.isUpdate = false
        this.product = {
            name: '',
            id: -1,
            amount: '',
            maxAmount: 9999999999,
            unit: '',
            cost: '',
            nameDropdown: false,
            unitDropdown: false
        }
    }

    private update(product: any) {
        this.products = null
        this.isShown = true
        this.isUpdate = true
        this.productForUpdate = product
        this.product = {
            name: product.title,
            id: product.id,
            amount: product.amount.toString(),
            maxAmount: 9999999999,
            unit: product.unit,
            cost: product.cost.toString(),
            nameDropdown: false,
            unitDropdown: false
        }
    }

    private checkForErrors() {
        if (!this.isShown) return
        this.product.amount = this.product.amount.replace(',', '.')
        if (this.product.name === '') {
            alert(i18n.t('messages.selections.title'))
            return false
        }
        if (this.product.amount === '') {
            alert(i18n.t('messages.instructions.type_amount'))
            return false
        } else if (isNaN(this.product.amount as any)) {
            alert('Введите число')
            return false
        }
        if (this.product.unit === '') {
            alert(i18n.t('messages.selections.scaling'))
            return false
        }
        if (this.product.cost === '') {
            alert(i18n.t('messages.instructions.type_cost'))
            return false
        }
        return true
    }

    private addNewProduct() {
        if (!this.checkForErrors()) return
        this.hide()
        this.reload()
    }

    private updateProduct() {
        if (!this.checkForErrors()) return
        if (this.getLoadingDataNames('newProductUpdateProduct')) return
        const putBody: any = {}
        if (this.productForUpdate.title !== this.product.name) {
            putBody.title = this.product.name
        }
        if (this.productForUpdate.amount.toString() !== this.product.amount) {
            putBody.amount = this.product.amount
        }
        if (this.productForUpdate.cost.toString() !== this.product.cost) {
            putBody.cost = this.product.cost
        }
        if (JSON.stringify(putBody) === JSON.stringify({})) {
            this.hide()
        }
        this.setLoadingDataNames('newProductUpdateProduct')
        httpPut({
            url: `${apiUrl}/storage/product/report/${this.productForUpdate.id}/`,
            isSecureRequest: true,
            body: putBody,
            onSuccess: json => {
                this.reload()
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('newProductUpdateProduct')
                this.hide()
            }
        })
    }

    @Emit('reload')
    private reload() {
        return {}
    }

    private getProduct() {
        if (this.product.maxAmount < Number(this.product.amount)) {
            this.product.amount = this.product.maxAmount.toString()
        }
        return this.product
    }

    private hide() {
        this.isShown = false
        this.isUpdate = false
    }

    private closeNameDropdown() {
        this.product.nameDropdown = false
    }

    private closeUnitDropdown() {
        this.product.unitDropdown = false
    }
}
