import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { openCrossOriginWebSite } from '@/domain/constants'
import './registerServiceWorker'
import i18n from './i18n'
import Checkbox from "@/components/Checkbox.vue"

import 'vue2-datepicker/locale/zh-cn'

import VueLayers from 'vuelayers'
import 'vuelayers/lib/style.css'
Vue.use(VueLayers)

import {
    OutsideClick,
    BaseIcon,
    BaseSelectBox,
    BaseInputField,
    TransformObserver,
    BaseMoreButton,
    BaseSortButton,
    BaseCheckbox,
    BaseRadio,
    BaseDialog,
    BaseCombobox
} from 'egistic_base_components/src/install'
Vue.directive('outside-click', OutsideClick)
Vue.component('base-icon', BaseIcon)
Vue.component('base-select-box', BaseSelectBox)
Vue.component('transform-observer', TransformObserver)
Vue.component('base-more-button', BaseMoreButton)
Vue.component('base-input-field', BaseInputField)
Vue.component('base-sort-button', BaseSortButton)
Vue.component('base-checkbox', BaseCheckbox)
Vue.component('base-radio', BaseRadio)
Vue.component('base-dialog', BaseDialog)
Vue.component('base-combobox', BaseCombobox)

import BaseControllerButton from '@/components/BaseControllerButton.vue'
import Modal from '@/components/Modal.vue'
import ConfirmModal from '@/components/ConfirmModal.vue'
import AlertModal from '@/components/AlertModal.vue'
import Spinner from '@/components/Spinner.vue'
import BaseButton from '@/components/BaseButton.vue'
import SwitchButton from '@/components/SwitchButton.vue'
import DatePicker from '@/components/DatePicker.vue'
import BaseTextWithIcon from '@/components/BaseTextWithIcon.vue'

Vue.component('BaseControllerButton', BaseControllerButton)
Vue.component('Modal', Modal)
Vue.component('ConfirmModal', ConfirmModal)
Vue.component('AlertModal', AlertModal)
Vue.component('Spinner', Spinner)
Vue.component('BaseButton', BaseButton)
Vue.component('SwitchButton', SwitchButton)
Vue.component('DatePicker', DatePicker)
Vue.component('base-text-with-icon', BaseTextWithIcon)
Vue.component('Checkbox', Checkbox)

import VueAnalytics from 'vue-analytics'
Vue.use(VueAnalytics, {
    id: 'UA-135349691-1',
    router,
    checkDuplicatedScript: true,
    debug: true
})

declare module 'vue/types/vue' {
    interface Vue {
        $ga: VueAnalytics
    }
}

import VueGtag from 'vue-gtag'

Vue.use(VueGtag, {
    config: {
        id: "G-BQ3R3BR1XZ",
        params: {
            send_page_view: false
        }
    }
})

import VueYandexMetrika from 'vue-yandex-metrika'
Vue.use(VueYandexMetrika, {
    id: 73800022,
    router,
    env: 'production'
})

import device from "vue-device-detector"
Vue.use(device)

import VueTour from 'vue-tour'
require('vue-tour/dist/vue-tour.css')
Vue.use(VueTour)

import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format'
Vue.use(VueFilterDateFormat)

import VueSplide from '@splidejs/vue-splide'
Vue.use(VueSplide)

import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
Vue.component('clip-loader', ClipLoader)

import { VueMaskDirective } from 'v-mask'
Vue.directive('mask', VueMaskDirective)

import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)

import VueSimpleAlert from 'vue-simple-alert'
Vue.use(VueSimpleAlert, {
    confirmButtonColor: '#4B970F',
    cancelButtonColor: '#E0E2D8',
    cancelButtonText: 'Отмена',
    reverseButtons: false,
    customClass: {
        confirmButton: 'swal2-button--confirm',
        cancelButton: 'swal2-button--cancel'
    }
})

Vue.config.productionTip = false

new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App)
}).$mount('#app')


export const tokenExpiredLogout = () => {
    store.dispatch('LOG_OUT')
    openCrossOriginWebSite('login', 'signin/farm')
}

export const settingErrorHandler = (responseStatus: any, currentResponse: any) => {
    const newId = Math.floor(Math.random() * Date.now())
    store.commit('setErrorHandler', {
        id: newId,
        status: responseStatus,
        response: currentResponse
    })
}