


















































































import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter, Mutation } from 'vuex-class'
import { ActionNames, GetterNames, MutationNames } from '@/store/types'
import { httpGet, httpPost, httpPostFormData } from '@/util/http'
import { apiUrl } from '@/domain/constants'
import { ASTANA_BOUNDARIES } from '@/domain/constants'
import i18n from '@/i18n'
import { geojson2wkt } from '@/utils/services'
import * as turf from '@turf/turf'
import RegisterFarm from '@/models/RegisterFarm'
@Component({
    components: {
    }
})
export default class SearchByCadastre extends Vue {
    @Action(ActionNames.fetchFarmsList) private fetchFarmsList !: any
    @Action(ActionNames.updateMapDataInRegion) private updateMapDataInRegion!: any

    @Getter(GetterNames.getSidebarModel) private getSidebarModel!: any
    @Getter(GetterNames.getRegisterFarms) private getRegisterFarms!: any
    @Getter(GetterNames.getLoadingDataNames) private getLoadingDataNames!: any

    @Mutation(MutationNames.setCurrentCadastreIndex) private setCurrentCadastreIndex!: any
    @Mutation(MutationNames.setSideboardIsShown) private setSideboardIsShown !: any
    @Mutation(MutationNames.setIsDataLoading) private setIsDataLoading!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    @Mutation(MutationNames.setRegisterFarms) private setRegisterFarms!: any
    private farm: any = {
        name: '',
        onerror: ''
    }
    private borderColors: any = ['#B2F507', '#06BAF2', '#5AF5D9', '#F37500', '#FC7BE7', '#719D00', '#AA27E8', '#2B4DFF', '#4F0BBD', '#AD3E00']

    private getBorderColor(documentId: any) {
        if (documentId) {
            const cadastre = this.getRegisterFarms.cadastres.find((x: any) => x.documentId === documentId)
            const cadastres = this.getRegisterFarms.cadastres.filter((x: any) => x.documentId)
            if (cadastre && cadastres) {
                const index = cadastres.indexOf(cadastre)
                if (index !== -1) {
                    return this.borderColors[index % 10]
                }
            }
        }
        return 'rgba(40, 49, 70, 0.12)'
    }

    private IsAddFarmActive() {
        if (this.farm.name === '') {
            return false
        } else if (!this.getRegisterFarms.cadastres.find((x: any) => x.cadastre)) {
            return false
        } else {
            return true
        }
    }

    private mounted() {
        this.updateMapDataInRegion({
            MIN_ZOOM: 5
        })
        const registerFarms: RegisterFarm[] = []
        registerFarms.push(new RegisterFarm())
        this.setRegisterFarms({
            cadastres: registerFarms,
            editType: 'none',
            editStage: 0,
            selectedCadastres: [],
            errorPoints: [],
            newField: false
        })
        if (this.getRegisterFarms.cachedCadastres.length > 0) {
            this.setRegisterFarms({
                cadastres: this.getRegisterFarms.cachedCadastres,
                cachedCadastres: []
            })
        }
        this.setMapData()
    }

    private registerNewFarm() {
        if (this.farm.name === '') {
            this.$alert(i18n.t('messages.instructions.farm_name') as string, 'Предупреждение', 'warning')
            this.farm.onerror = i18n.t('messages.instructions.farm_name') as string
            return
        }
        if (!this.getRegisterFarms.cadastres.find((x: any) => x.cadastre)) {
            this.$alert(i18n.t('messages.selections.cadastre') as string, 'Предупреждение', 'warning')
            return
        }
        this.$ga.event('register farm', 'register new farm', 'from searchByCadastre', 1)
        const cadastresInfo: any = []
        const cadastres = this.getRegisterFarms.cadastres.filter((x: any) => x.cadastre)
        for (const cadastre of cadastres) {
            const editedArr: any = []
            if (cadastre.fields.length !== 0) {
                for (const field of cadastre.fields) {
                    const result = geojson2wkt(field.geometry)
                    if (result) {
                        editedArr.push({
                            geometry: result,
                            name: field.name
                        })
                    }
                }
                if (editedArr.length === 0) {
                    editedArr.push({
                    geometry: cadastre.cadastre.geomstring,
                    name: null
                })
                }
                cadastresInfo.push({
                    id: isNaN(cadastre.id) ? null : cadastre.id,
                    meta: {
                        edited: editedArr,
                        added: [],
                        itself: false
                    }
                })
            }
        }
        if (cadastresInfo.length === 0) {
            this.$alert(i18n.t('messages.errors.no_cadastres') as string, 'Предупреждение', 'warning')
            return
        }
        this.setLoadingDataNames('addFarmAddFarm')
        this.setRegisterFarms({errorPoints: []})
        httpPost({
            url: `${apiUrl}/new/add/cadastre`,
            isSecureRequest: true,
            detailResponse: true,
            body: {
                name: this.farm.name,
                cadastre_info: cadastresInfo
            },
            onSuccess: json => {
                if (json.ok) {
                    this.$alert(`${i18n.t('headers.workspace.farm.title')} ${this.farm.name} ${i18n.t('messages.success.successfully_added')}`,
                        'Хозяйство успешно добавлено', 'success')
                    this.$router.push({
                        name: 'cadastresList',
                        params: {
                            isDataSaved: 'true'
                        }
                    })
                    this.fetchFarmsList({reload: true})
                } else if (json.status === 400) {
                    this.$alert(i18n.t('messages.errors.farm_exists') as string, 'Предупреждение', 'warning')
                } else if (json.status === 422) {
                    const errors: any = []
                    for (const cadastre of json.json.cadastre_info) {
                        if (Object.keys(cadastre).length !== 0) {
                            for (const field of cadastre.meta.edited) {
                                if (Object.keys(field).length !== 0) {
                                    if (field.geometry && field.geometry.point) {
                                        const points = field.geometry.point.replaceAll(/[()]/g, '').split(' ')
                                        errors.push({
                                            coordinates: [Number(points[1]), Number(points[2])],
                                            problem_type: field.geometry.problem_type
                                        })
                                    }
                                }
                            }
                        }
                    }
                    this.$alert('Ошибка в геометрии хозяйства. Проверьте нарисованные поля.', 'Предупреждение', 'warning').then(() => {
                        this.setRegisterFarms({errorPoints: errors})
                    })
                } else {
                    this.$alert('Ошибка сервера', 'Ошибка', 'error')
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('addFarmAddFarm')
            }
        })
    }

    private filesUploaded(uploadedFile: any) {
        if (uploadedFile.length > 0) {
            this.$ga.event('register farm', 'file upload', 'from searchByCadastre', 1)
            const formData = new FormData()
            for (const file of uploadedFile) {
                formData.append('files', file)
            }
            this.setLoadingDataNames('addFarmFileUpload')
            httpPostFormData({
                url: `${apiUrl}/get/geom/from/files/`,
                isSecureRequest: true,
                detailResponse: true,
                body: formData,
                onSuccess: json => {
                    if (json.ok) {
                        const newcadastre = json.json
                        if (!this.getRegisterFarms.cadastres.find((x: any) => x.documentId === json.json.documentId)) {
                            const cadastre = new RegisterFarm()
                            cadastre.number = newcadastre.documentId
                            cadastre.setData({
                                id: newcadastre.id,
                                documentId: newcadastre.documentId,
                                location: `${newcadastre.region ? `${newcadastre.region}, ` : ''}${newcadastre.oblast}`,
                                area: newcadastre.area,
                                fields: newcadastre.field,
                                cadastre: newcadastre,
                                readonly: true
                            })
                            this.getRegisterFarms.cadastres.push(cadastre)
                            this.setMapData()
                        }
                    } else {
                        if (json.status === 400) {
                            this.$alert(json.json.files, 'Ошибка файла', 'warning')
                        } else {
                            this.$alert('Ошибка сервера', 'Ошибка', 'error')
                        }
                    }
                },
                onError: error => {
                    console.log(error)
                },
                doFinally: () => {
                    this.setLoadingDataNames('addFarmFileUpload')
                    const upload = this.$refs.uploadfile as any
                    if (upload) {
                        upload.value = ''
                    }
                }
            })
        }
    }

    private searchCadastre(cadastreNumber: any, index: any) {
        const registerFarm = this.getRegisterFarms.cadastres[index]
        if (registerFarm) {
            if (registerFarm.readonly === false) {
                if (cadastreNumber.length === 14 || cadastreNumber.length === 15) {
                    const documentId = cadastreNumber.replaceAll('-', '')
                    if (!this.getRegisterFarms.cadastres.find((x: any) => x.documentId === documentId)) {
                        if (this.getLoadingDataNames(`addFarmsearchCadastre-${documentId}`)) return
                        this.setLoadingDataNames(`addFarmsearchCadastre-${documentId}`)
                        httpGet({
                            url:  `${apiUrl}/cadastre_by_value/${cadastreNumber.replaceAll('-', '')}/`,
                            isSecureRequest: true,
                            detailResponse: true,
                            onSuccess: json => {
                                if (json.ok) {
                                    if (this.getRegisterFarms.cadastres[index].number === cadastreNumber) {
                                        if (!this.getRegisterFarms.cadastres.find((x: any) => x.documentId === documentId)) {
                                            if (Object.keys(json.json).length === 0) {
                                                this.getRegisterFarms.cadastres[index].clear()
                                                this.setMapData()
                                                this.$alert('Кадастр не найден', 'Предупреждение', 'warning')
                                            } else {
                                                let field: any = null
                                                if (json.json.field.length > 0) {
                                                    field = JSON.parse(JSON.stringify(json.json.field))
                                                } else {
                                                    field = [{
                                                        id: json.json.documentId,
                                                        geometry: json.json.geomjson,
                                                        area: json.json.area,
                                                        name: null
                                                    }]
                                                }
                                                this.getRegisterFarms.cadastres[index].setData({
                                                    id: json.json.id,
                                                    documentId: json.json.documentId,
                                                    location: `${json.json.region ? `${json.json.region}, ` : ''}${json.json.oblast}`,
                                                    area: json.json.area,
                                                    fields: field,
                                                    cadastre: json.json,
                                                    readonly: false
                                                })
                                                this.setMapData()
                                            }
                                        }
                                    }
                                } else {
                                    this.getRegisterFarms.cadastres[index].clear()
                                    this.setMapData()
                                    this.$alert('Ошибка сервера', 'Ошибка', 'error')
                                }
                            },
                            onError: error => {
                                console.log(error)
                            },
                            doFinally: () => {
                                this.setLoadingDataNames(`addFarmsearchCadastre-${documentId}`)
                            }
                        })
                    }
                } else {
                    this.getRegisterFarms.cadastres[index].clear()
                    this.setMapData()
                }
            }
        }
    }

    private setEditCadastre(index: any) {
        if (this.getRegisterFarms.cadastres[index].cadastre) {
            this.getRegisterFarms.cadastres.map((x: any, i: any) => {
                if (x.editing) {
                    this.getRegisterFarms.cadastres[i].editing = false
                }
            })
            const cadastre = this.getRegisterFarms.cadastres[index].cadastre
            this.updateMapDataInRegion({
                animateBbox: cadastre.boundary
            })
            this.getRegisterFarms.cadastres[index].editing = true
            this.setRegisterFarms({editType: 'none', editStage: 0})
            this.setSideboardIsShown(false)
        }
    }

    private deleteCadastre(index: any) {
        this.getRegisterFarms.cadastres.splice(index, 1)
        this.setMapData()
    }

    private setMapData() {
        if (this.getRegisterFarms.cadastres.filter((x: any) => x.cadastre).length === 0) {
            this.setCurrentCadastreIndex(7952883)
            this.updateMapDataInRegion( {
                boundaries: ASTANA_BOUNDARIES
            })
        } else {
            let Xmin = Number.MAX_SAFE_INTEGER
            let Xmax = Number.MIN_SAFE_INTEGER
            let Ymin = Number.MAX_SAFE_INTEGER
            let Ymax = Number.MIN_SAFE_INTEGER
            this.getRegisterFarms.cadastres.filter((x: any) => x.cadastre).map((cadastre: any) => {
                const boundaries = cadastre.cadastre.boundary
                Xmax = boundaries[2] > Xmax ? boundaries[2] : Xmax
                Xmin = boundaries[0] < Xmin ? boundaries[0] : Xmin
                Ymax = boundaries[3] > Ymax ? boundaries[3] : Ymax
                Ymin = boundaries[1] < Ymin ? boundaries[1] : Ymin
            })
            const cadastres = this.getRegisterFarms.cadastres.filter((x: any) => x.cadastre)
            this.setCurrentCadastreIndex(`-${cadastres[cadastres.length - 1].documentId}`)
            this.updateMapDataInRegion( {
                animateBbox: [Xmin, Ymin, Xmax, Ymax]
            })
        }
    }

    private addNewField() {
        this.getRegisterFarms.cadastres.push(new RegisterFarm())
    }

    private openFileUpload() {
        const upload = this.$refs.uploadfile as any
        if (upload) {
            upload.click()
        }
    }
}
