











































































































import { Component, Vue, Emit } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import { GetterNames, MutationNames } from '@/store/types'
import { httpGet, httpPut } from '@/util/http'
import { apiUrl } from '@/domain/constants'
import { permissionTypes } from '@/data/rolesData'
import { notificationsPermissions } from '@/data/notificationsData'
import i18n from '@/i18n'
@Component({
    components: {
    }
})
export default class NotificationManage extends Vue {
    @Getter(GetterNames.getSidebarModel) private getSidebarModel!: any
    @Getter(GetterNames.getPermissions) private getPermissions!: any
    @Getter(GetterNames.getLoadingDataNames) private getLoadingDataNames!: any
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any

    private members = []
    private membersSearch = ''
    private editButton = {
        status: false,
        id: -1
    }
    private nPermissions = [
        {
            title: i18n.t('headers.workspace.dispatcher.permissions.alarms.title'),
            icon: {
                icon: require('!html-loader!@/assets/icons/bell_icon.svg'),
                color: "#1c1c1c"
            },
            type: 1,
            content: this.getNotifTypes(1)
        },
        {
            title: i18n.t('headers.workspace.dispatcher.permissions.warnings.title'),
            icon: {
                icon: require('!html-loader!@/assets/icons/alert_icon.svg'),
                color: "#D41900"
            },
            type: 2,
            content: this.getNotifTypes(2)
        }
    ]

    private mounted() {
        if (!this.currentRoute.params.info) {
            this.$router.replace({
                name: 'notificationmanage',
                params: {
                    info: JSON.stringify({
                        category: 0,
                        type: 0,
                        page: 1
                    })
                }
            })
        }
        this.getMembers()
    }

    @Emit('reload')
    private reload() {
        return {}
    }

    private getPermissionTypes(roles: any) {
        let str = ''
        for (const role of permissionTypes) {
            if (roles.includes(role.num)) {
                str += role.name + ', '
            }
        }
        if (str !== '') {
            return str.substring(0, str.length - 2)
        }
        return str
    }

    get searchResult() {
        if ( this.membersSearch !== '') {
            return this.members.filter((user: any) => user.user.first_name.includes(this.membersSearch) || user.user.last_name.includes(this.membersSearch))
        } else {
            return this.members
        }
    }

    private notifToggle(newId: number) {
        this.editButton = {
            status: !this.editButton.status,
            id: newId
        }
    }

    private checkAll(index: number, user: any) {
        let val = true
        if ( this.checkIfSelectedAllGroup(index, user) ) { val = false }
        for (const nType of this.nPermissions[index].content) {
            user[nType.key_value] = val
        }
    }

    private getNotifTypes(type: number) {
        return notificationsPermissions.filter((n: any) => n.type === type)
    }

    private close() {
        this.$router.push({
            name: 'notifications',
            params: {
                info: this.currentRoute.params.info ? this.currentRoute.params.info : null
            }
        })
    }

    private hide() {
        this.editButton.status = false
    }

    private saveChanges(member: any) {
        let newbody = {}
        for ( const nType of notificationsPermissions ) {
            newbody = {
                ...newbody,
                [nType.key_value]: member[nType.key_value]
            }
        }
        this.setLoadingDataNames('memberUpdateNotifications')
        httpPut({
            url: `${apiUrl}/notifications/${member.id}`,
            isSecureRequest: true,
            body: newbody,
            onSuccess: json => {
                if (!json.detail) {
                    alert(i18n.t('messages.success.changes_saved'))
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('memberUpdateNotifications')
                this.hide()
                this.reload()
            }
        })
    }

    private getMemberNotifTypes(user: any) {
        let res = ''
        let num = 0

        for (const group of this.nPermissions) {
            for (const notif of group.content) {
                num += user[notif.key_value] ? 1 : 0
            }

            if ( num > 0 ) {
                res += group.title + ', '
                num = 0
            }
        }

        if ( res.length > 0 ) {
            return res.slice(0, res.length - 2)
        }
        return i18n.t('messages.not_chosen')
    }

    private getGroupProgress(index: number, member: any) {
        const group = this.nPermissions[index]
        if (group && group.content) {
            const length = group.content.length
            let sum = 0
            for (const nType of group.content) {
                if (member[nType.key_value]) {
                    sum += 1
                }
            }
            return Math.round(sum * 100 / length)
        }
    }

    private checkIfSelectedAllGroup(index: number, member: any) {
        const group = this.nPermissions[index]
        if (group && group.content) {
            for (const nType of group.content) {
                if ( !member[nType.key_value] ) {
                    return false
                }
            }
        }
        return true
    }

    private getMembers() {
        this.setLoadingDataNames('membersNotificationTypes')
        httpGet({
            url:  `${apiUrl}/notifications/all/dispatchers`,
            isSecureRequest: true,
            onSuccess: json => {
                this.members = json
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('membersNotificationTypes')
            }
        })
    }
}
