import { Module, GetterTree, ActionTree, MutationTree } from 'vuex'
import { GetterNames, ActionNames, MutationNames } from '../../types'
import { MIN_ZOOM, MAX_ZOOM, ASTANA_GEOLOCATION, ASTANA_BOUNDARIES } from '@/domain/constants'
import UploadAgroChem from '@/models/UploadAgroChem'

const states = {
    mapWidth: 0,
    mapData: {
        zoom: MIN_ZOOM,
        animateZoom: MIN_ZOOM,
        center: ASTANA_GEOLOCATION,
        animateCenter: ASTANA_GEOLOCATION,
        mapBoundaries: ASTANA_BOUNDARIES,
        rotation: 0,
        geolocIsShown: true,
        geolocPosition: ASTANA_GEOLOCATION,
        layerType: 'google',
        MAX_ZOOM,
        MIN_ZOOM,
        activeIndicator: 'ndvi'
    },
    fieldRequestComparisonMode: false,
    isProblemZonesMode: false,
    swiperPositionX: 0,
    agroChemData: {
        selectedFields: [],
        fieldPoints: [],
        mapSelection: false,
        pointEdit: false,
        editType: 'edit_icon'
    },
    planningData: {
        culturesData: null,
        cropRotations: [],
        fields: [],
        selectedFields: [],
        fieldPoints: [],
        mapSelection: false,
        pointEdit: false,
        editType: 'edit_icon',
        culture: '',
        season: '',
        page: 1,
        fieldOnMap: false
    },
    agroChemResult: {
        selectedFields: [],
        analysisResult: [],
        selectedCategory: 0,
        mapSelection: false
    },
    agroChemFieldResult: {
        selectedField: 0,
        analysisResult: null,
        selectedCategory: 0
    },
    uploadAgroChemResult: {
        data: new Array<UploadAgroChem>(),
        selectedField: undefined,
        selectedPoint: {
            fieldId: null,
            fieldIndex: null,
            pointIndex: null
        },
        draw: false
    },
    fertilizerResult: {
        norm: [],
        requestId: -1,
        imgUrl: null,
        data: [],
        visibility: false,
        download: false
    },
    weedinessImage: {
        visibility: false,
        image: null,
        requestId: null,
        download: false
    },
    RulerMode: {
        active: false,
        isDistance: true,
        isArea: false,
        isPoint: false
    },
    PrintScreen: false,
    FarmMapControllers: {
        selectedFields: [],
        textType: 1,
        fill: false,
        strokeWidth: 1,
        strokeColor: '#FFFFFF',
        textMaxScale: 4,
        textColor: '#FFFFFF',
        telematicsTextType: 0,
        coverageView: true
    },
    FieldEditGeom: {
        fieldId: -1,
        geometry: null,
        cachedGeometry: null,
        errorPoints: []
    },
    YieldLayer: {
        layer: '',
        selectedUrl: '',
        selected: null
    },
    SuperResolution: false
}

export type IState = typeof states

const mutations: MutationTree<IState> = {
    [MutationNames.setMapWidth](state, mapWidth: any) {
        state.mapWidth = mapWidth
    },

    [MutationNames.setGeolocVisibility](state, val: boolean) {
        state.mapData.geolocIsShown = val
    },

    [MutationNames.setMainMapCenter](state, center: number[]) {
        state.mapData.center = center
    },

    [MutationNames.setMainMapZoom](state, zoom: number) {
        state.mapData.zoom = zoom
    },

    [MutationNames.setActiveIndicator](state, val: string) {
        state.mapData.activeIndicator = val
    },

    [MutationNames.changeLayerType](state, val: string) {
        state.mapData.layerType = val
    },

    [MutationNames.setFieldRequestComparisonMode](state, val: boolean) {
        state.fieldRequestComparisonMode = val
    },

    [MutationNames.incZoom](state) {
        const zoom = state.mapData.zoom
        state.mapData.zoom = zoom >= MAX_ZOOM ? MAX_ZOOM : (zoom + 0.3)
    },

    [MutationNames.decZoom](state) {
        const zoom = state.mapData.zoom
        state.mapData.zoom = zoom <= state.mapData.MIN_ZOOM ? state.mapData.MIN_ZOOM : (zoom - 0.3)
    },

    [MutationNames.setSwiperPositionX](state, val: number) {
        state.swiperPositionX = val
    },

    [MutationNames.setIsProblemZonesMode](state, val: boolean) {
        state.isProblemZonesMode = val
    },

    [MutationNames.setAgroChemData](state, val: any) {
        if (val.hasOwnProperty('selectedFields')) state.agroChemData.selectedFields = val.selectedFields
        if (val.hasOwnProperty('fieldPoints')) state.agroChemData.fieldPoints = val.fieldPoints
        if (val.hasOwnProperty('mapSelection')) state.agroChemData.mapSelection = val.mapSelection
        if (val.hasOwnProperty('pointEdit')) state.agroChemData.pointEdit = val.pointEdit
        if (val.hasOwnProperty('editType')) state.agroChemData.editType = val.editType
    },

    [MutationNames.setPlanningData](state, val: any) {
        if (val.hasOwnProperty('fields')) state.planningData.fields = val.fields
        if (val.hasOwnProperty('cropRotations')) state.planningData.cropRotations = val.cropRotations
        if (val.hasOwnProperty('culturesData')) state.planningData.culturesData = val.culturesData
        if (val.hasOwnProperty('selectedFields')) state.planningData.selectedFields = val.selectedFields

        if (val.hasOwnProperty('fieldPoints')) state.planningData.fieldPoints = val.fieldPoints
        if (val.hasOwnProperty('mapSelection')) state.planningData.mapSelection = val.mapSelection
        if (val.hasOwnProperty('pointEdit')) state.planningData.pointEdit = val.pointEdit
        if (val.hasOwnProperty('editType')) state.planningData.editType = val.editType
        if (val.hasOwnProperty('season')) state.planningData.season = val.season
        if (val.hasOwnProperty('page')) state.planningData.page = val.page
        if (val.hasOwnProperty('fieldOnMap')) state.planningData.fieldOnMap = val.fieldOnMap
    },

    [MutationNames.setAgroChemResult](state, val: any) {
        if (val.hasOwnProperty('selectedFields')) state.agroChemResult.selectedFields = val.selectedFields
        if (val.hasOwnProperty('analysisResult')) state.agroChemResult.analysisResult = val.analysisResult
        if (val.hasOwnProperty('selectedCategory')) state.agroChemResult.selectedCategory = val.selectedCategory
        if (val.hasOwnProperty('mapSelection')) state.agroChemResult.mapSelection = val.mapSelection
    },

    [MutationNames.setAgroChemFieldResult](state, val: any) {
        if (val.hasOwnProperty('selectedField')) state.agroChemFieldResult.selectedField = val.selectedField
        if (val.hasOwnProperty('analysisResult')) state.agroChemFieldResult.analysisResult = val.analysisResult
        if (val.hasOwnProperty('selectedCategory')) state.agroChemFieldResult.selectedCategory = val.selectedCategory
    },

    [MutationNames.setUploadAgroChemResult](state, val: any) {
        if (val.hasOwnProperty('data')) state.uploadAgroChemResult.data = val.data
        if (val.hasOwnProperty('selectedField')) state.uploadAgroChemResult.selectedField = val.selectedField
        if (val.hasOwnProperty('draw')) state.uploadAgroChemResult.draw = val.draw
        if (val.hasOwnProperty('selectedPoint')) state.uploadAgroChemResult.selectedPoint = val.selectedPoint
    },

    [MutationNames.setFertilizerResult](state, val: any) {
        if (val.hasOwnProperty('norm')) state.fertilizerResult.norm = val.norm
        if (val.hasOwnProperty('requestId')) state.fertilizerResult.requestId = val.requestId
        if (val.hasOwnProperty('imgUrl')) state.fertilizerResult.imgUrl = val.imgUrl
        if (val.hasOwnProperty('data')) state.fertilizerResult.data = val.data
        if (val.hasOwnProperty('visibility')) state.fertilizerResult.visibility = val.visibility
        if (val.hasOwnProperty('download')) state.fertilizerResult.download = val.download
    },

    [MutationNames.setWeedinessImage](state, val: any) {
        if (val.hasOwnProperty('visibility')) state.weedinessImage.visibility = val.visibility
        if (val.hasOwnProperty('image')) state.weedinessImage.image = val.image
        if (val.hasOwnProperty('requestId')) state.weedinessImage.requestId = val.requestId
        if (val.hasOwnProperty('download')) state.weedinessImage.download = val.download
    },

    [MutationNames.setRulerMode](state, val: any) {
        if (val.hasOwnProperty('active')) state.RulerMode.active = val.active
        if (val.hasOwnProperty('isDistance')) state.RulerMode.isDistance = val.isDistance
        if (val.hasOwnProperty('isArea')) state.RulerMode.isArea = val.isArea
        if (val.hasOwnProperty('isPoint')) state.RulerMode.isPoint = val.isPoint
    },

    [MutationNames.setPrintScreen](state, val: any) {
        state.PrintScreen = val
    },

    [MutationNames.setFarmMapControllers](state, val: any) {
        if (val.hasOwnProperty('selectedFields')) state.FarmMapControllers.selectedFields = val.selectedFields
        if (val.hasOwnProperty('textType')) state.FarmMapControllers.textType = val.textType
        if (val.hasOwnProperty('fill')) state.FarmMapControllers.fill = val.fill
        if (val.hasOwnProperty('strokeWidth')) state.FarmMapControllers.strokeWidth = val.strokeWidth
        if (val.hasOwnProperty('strokeColor')) state.FarmMapControllers.strokeColor = val.strokeColor
        if (val.hasOwnProperty('textMaxScale')) state.FarmMapControllers.textMaxScale = val.textMaxScale
        if (val.hasOwnProperty('textColor')) state.FarmMapControllers.textColor = val.textColor
        if (val.hasOwnProperty('telematicsTextType')) state.FarmMapControllers.telematicsTextType = val.telematicsTextType
        if (val.hasOwnProperty('coverageView')) state.FarmMapControllers.coverageView = val.coverageView
    },

    [MutationNames.setFieldEditGeom](state, val: any) {
        if (val.hasOwnProperty('fieldId')) state.FieldEditGeom.fieldId = val.fieldId
        if (val.hasOwnProperty('geometry')) state.FieldEditGeom.geometry = val.geometry
        if (val.hasOwnProperty('cachedGeometry')) state.FieldEditGeom.cachedGeometry = val.cachedGeometry
        if (val.hasOwnProperty('errorPoints')) state.FieldEditGeom.errorPoints = val.errorPoints
    },

    [MutationNames.setYieldLayer](state, val: any) {
        if (val.hasOwnProperty('layer')) state.YieldLayer.layer = val.layer
        if (val.hasOwnProperty('selectedUrl')) state.YieldLayer.selectedUrl = val.selectedUrl
        if (val.hasOwnProperty('selected')) state.YieldLayer.selected = val.selected
    },

    [MutationNames.setSuperResolution](state, val: any) {
        state.SuperResolution = val
    }
}

const actions: ActionTree<IState, any> = {
    [ActionNames.updateMapDataInRegion]({ state, getters }, data: any) {
        state.mapData.geolocIsShown = false
        if (data.hasOwnProperty('boundaries')) {
            const width = getters.getSideboardIsShown ? 472 : 72
            const zoomY = ((Math.log(2 * Math.PI * 6378137 / ((data.boundaries[2] - data.boundaries[0]) / ((window.innerWidth - width) * 0.80))) / Math.log(2)) - 8)
            const zoomX = ((Math.log(2 * Math.PI * 6378137 / ((data.boundaries[3] - data.boundaries[1]) / (window.innerHeight * 0.80))) / Math.log(2)) - 8)
            state.mapData.zoom = Math.min(zoomY, zoomX) < state.mapData.MIN_ZOOM ? state.mapData.MIN_ZOOM : Math.min(zoomX, zoomY)
            state.mapData.center = [(data.boundaries[0] + data.boundaries[2]) / 2, (data.boundaries[1] + data.boundaries[3]) / 2],
                state.mapData.mapBoundaries = data.boundaries
        }
        if (data.hasOwnProperty('center')) state.mapData.center = data.center
        if (data.hasOwnProperty('MIN_ZOOM')) state.mapData.MIN_ZOOM = data.MIN_ZOOM
        if (data.hasOwnProperty('animateBbox')) {
            const width = getters[GetterNames.getSideboardIsShown] ? 472 : 72
            const zoomY = ((Math.log(2 * Math.PI * 6378137 / ((data.animateBbox[2] - data.animateBbox[0]) / ((window.innerWidth - width) * 0.80))) / Math.log(2)) - 8)
            const zoomX = ((Math.log(2 * Math.PI * 6378137 / ((data.animateBbox[3] - data.animateBbox[1]) / (window.innerHeight * 0.80))) / Math.log(2)) - 8)
            state.mapData.animateZoom = Math.min(zoomY, zoomX) < state.mapData.MIN_ZOOM ? state.mapData.MIN_ZOOM : Math.min(zoomX, zoomY)
            state.mapData.animateCenter = [(data.animateBbox[0] + data.animateBbox[2]) / 2, (data.animateBbox[1] + data.animateBbox[3]) / 2]
            state.mapData.mapBoundaries = data.animateBbox
        }
        if (data.hasOwnProperty('currentZoom')) state.mapData.zoom = data.currentZoom
    }
}

const getter: GetterTree<IState, any> = {
    [GetterNames.getSwiperPositionX]: state => state.swiperPositionX,

    [GetterNames.getMapWidth]: state => state.mapWidth,

    [GetterNames.getFieldRequestComparisonMode]: state => state.fieldRequestComparisonMode,

    [GetterNames.getIsProblemZonesMode]: state => state.isProblemZonesMode,

    [GetterNames.getAgroChemData]: state => state.agroChemData,

    [GetterNames.getPlanningData]: state => state.planningData,

    [GetterNames.getAgroChemResult]: state => state.agroChemResult,

    [GetterNames.getAgroChemFieldResult]: state => state.agroChemFieldResult,

    [GetterNames.getUploadAgroChemResult]: state => state.uploadAgroChemResult,

    [GetterNames.getFertilizerResult]: state => state.fertilizerResult,

    [GetterNames.getWeedinessImage]: state => state.weedinessImage,

    [GetterNames.getRulerMode]: state => state.RulerMode,

    [GetterNames.getMapData]: state => {
        return state.mapData
    },

    [GetterNames.getLayerType]: state => {
        return state.mapData.layerType
    },

    [GetterNames.getMapDataCenterX]: state => {
        return state.mapData.center[0]
    },

    [GetterNames.getActiveIndicator]: state => state.mapData.activeIndicator,

    [GetterNames.getPrintScreen]: state => state.PrintScreen,

    [GetterNames.getFarmMapControllers]: state => state.FarmMapControllers,

    [GetterNames.getFieldEditGeom]: state => state.FieldEditGeom,

    [GetterNames.getYieldLayer]: state => state.YieldLayer,

    [GetterNames.getSuperResolution]: state => state.SuperResolution,

    [GetterNames.getAnimateParameters]: state => {
        return {
            center: state.mapData.animateCenter,
            zoom: state.mapData.animateZoom,
            duration: 800
        }
    }
}

const auth: Module<IState, any> = {
    state: states,
    actions,
    mutations,
    getters: getter
}

export default auth
