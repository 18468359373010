var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vl-map',{ref:"map",staticClass:"map",attrs:{"load-tiles-while-animating":true,"load-tiles-while-interacting":true,"controls":false,"data-projection":"EPSG:3857","cross-origin":"anonymous"},on:{"pointermove":_vm.onPointerMove,"click":_vm.onClickOnMap}},[(!_vm.mapData.geolocIsShown)?_c('vl-view',{ref:"vlView",attrs:{"zoom":_vm.mapData.zoom,"center":_vm.mapData.center,"min-zoom":_vm.mapData.MIN_ZOOM,"max-zoom":_vm.mapData.MAX_ZOOM,"rotation":_vm.mapData.rotation,"enable-rotation":false,"cross-origin":"anonymous"},on:{"update:zoom":function($event){return _vm.$set(_vm.mapData, "zoom", $event)},"update:center":function($event){return _vm.$set(_vm.mapData, "center", $event)},"update:rotation":function($event){return _vm.$set(_vm.mapData, "rotation", $event)}}}):_c('vl-view',{ref:"vlView",attrs:{"zoom":_vm.mapData.zoom,"center":_vm.mapData.geolocPosition,"min-zoom":_vm.mapData.MIN_ZOOM,"max-zoom":_vm.mapData.MAX_ZOOM,"rotation":_vm.mapData.rotation,"enable-rotation":false},on:{"update:zoom":function($event){return _vm.$set(_vm.mapData, "zoom", $event)},"update:center":function($event){return _vm.$set(_vm.mapData, "geolocPosition", $event)},"update:minZoom":function($event){return _vm.$set(_vm.mapData, "MIN_ZOOM", $event)},"update:min-zoom":function($event){return _vm.$set(_vm.mapData, "MIN_ZOOM", $event)},"update:maxZoom":function($event){return _vm.$set(_vm.mapData, "MAX_ZOOM", $event)},"update:max-zoom":function($event){return _vm.$set(_vm.mapData, "MAX_ZOOM", $event)},"update:rotation":function($event){return _vm.$set(_vm.mapData, "rotation", $event)}}}),_c('LayerGroup'),(_vm.getFarmFromRoute && _vm.$route.name === 'cadastreinfo')?_c('CadastreRequests',{attrs:{"vlView":_vm.$refs.vlView}}):_vm._e(),(_vm.$route.name === 'cadastresList' && _vm.getShowAllFarms)?_c('AllFarms',{attrs:{"vlView":_vm.$refs.vlView}}):_vm._e(),(_vm.getFarmFromRoute
            && _vm.$route.name !== 'editfarm'
            && _vm.$route.name !== 'orderagrochem'
            && _vm.$route.name !== 'cadastreinfo'
            && _vm.$route.name !== 'fieldcadastre'
            && _vm.$route.name !== 'agrochem')?_c('FarmFields',{attrs:{"fields":_vm.getFarmFromRoute.fields,"vlView":_vm.$refs.vlView}}):_vm._e(),(_vm.getFarmFromRoute && _vm.$route.name === 'culturetask')?_c('TaskRequests',{attrs:{"fields":_vm.getFarmFromRoute.fields,"vlView":_vm.$refs.vlView}}):_vm._e(),(_vm.getFarmFromRoute && _vm.$route.name === 'planning' || _vm.currentRoute.name === 'clonecropdata')?_c('PlanningRequests',{attrs:{"fields":_vm.getFarmFromRoute.fields,"vlView":_vm.$refs.vlView}}):_vm._e(),(_vm.getFarmFromRoute && _vm.$route.name === 'fieldcadastre')?_c('FieldCadastreRequests',{attrs:{"vlView":_vm.$refs.vlView}}):_vm._e(),(_vm.$route.name === 'searchonmap')?_c('RegionCadastres',{ref:"regioncadastres",attrs:{"map":_vm.$refs.map}}):_vm._e(),(_vm.$route.name === 'registercadastre'
            || _vm.$route.name === 'editfarm')?_c('RegisterFarm'):_vm._e(),(_vm.$route.name === 'editfieldpassport')?_c('FieldEdit'):_vm._e(),(_vm.$route.name === 'subfieldyield')?_c('YieldLayer',{ref:"yieldlayer",attrs:{"map":_vm.$refs.map}}):_vm._e(),(_vm.getFarmFromRoute
            && _vm.$route.name !== 'editfarm'
            && _vm.$route.name !== 'orderagrochem'
            && _vm.$route.name !== 'agrochem')?_c('FieldRequests'):_vm._e(),(_vm.$route.name === 'subfieldnotes'
            || _vm.$route.name === 'noteupdate'
            || _vm.$route.name === 'notereply'
            || _vm.$route.name === 'subfieldnotedetails'
            || _vm.$route.name === 'noteregistration'
            || (_vm.$route.name === 'allnotes' && _vm.getAddAllNote)
            || _vm.$route.name === 'notes')?_c('SubfieldNotes'):_vm._e(),(_vm.$route.name === 'orderagrochem')?_c('AgroChemPoints'):_vm._e(),(_vm.$route.name === 'agrochem')?_c('AgroChemResult'):_vm._e(),(_vm.$route.name === 'fieldagrochem')?_c('AgroChemFieldResult'):_vm._e(),(_vm.$route.name === 'uploadagrochemresult'
            && _vm.$refs.map
            && _vm.$refs.vlView)?_c('AgroChemFielUpload',{attrs:{"map":_vm.$refs.map,"view":_vm.$refs.vlView}}):_vm._e(),(_vm.$route.name === 'subfieldindicators')?_c('FertilizerResult'):_vm._e(),(_vm.$route.name === 'farmorderdata')?_c('OrderFarmData'):_vm._e(),(_vm.$route.name === 'notificationmessage')?_c('NotificationMessages'):_vm._e(),(_vm.getFarmFromRoute)?_c('WeatherMap',{attrs:{"map":_vm.$refs.map}}):_vm._e(),(_vm.$route.name === 'farmtelematics'
            || _vm.$route.name === 'fieldtelematics'
            || _vm.$route.name === 'culturetask'
            || _vm.$route.name === 'subfield'
            || _vm.$route.name === 'editfieldpassport')?_c('TelematicsContainer',{attrs:{"map":_vm.$refs.map}}):_vm._e(),_c('Ruler',{ref:"ruler"}),((_vm.$route.name === 'subfield' || _vm.$route.name === 'subfieldindicators'))?_c('IndicatorsHover',{ref:"indicatorshover",attrs:{"map":_vm.$refs.map}}):_vm._e(),_c('MapScreen',{attrs:{"map":_vm.$refs.map}}),(_vm.$route.name === 'subfieldnotes'
            || _vm.$route.name === 'subfieldindicators'
            || (_vm.$route.name === 'allnotes' && _vm.getAddAllNote === false))?_c('newNote',{attrs:{"entOnMap":_vm.entOnMap}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }