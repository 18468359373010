




























































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import {GetterNames, MutationNames} from '@/store/types'
import { httpGet } from '@/util/http'
import { apiUrl } from '@/domain/constants'
import Chart from 'chart.js/auto'
import i18n from '@/i18n'
import {temperatureRanges, precipitationRanges, cloudsRanges, pressureRanges } from '@/data/gistogramData'
interface MeteoData {
    date: string
    hourly: [Hourly]
    humidity: number
    precipitation: number
    temperature: number
    day_night: any,
    wind: number
    wind_east: number
    wind_north: number
    weather_condition: string
}
interface Hourly {
    date: string
    humidity: number
    precipitation: number
    temperature: number
    wind: number
    wind_east: number
    wind_north: number
    weather_condition: string
}
interface MeteoStatistic {
    day: number
    month: string
    weekday: string
    temperature: number
    max_temp: number,
    min_temp: number,
    humidity: number
    precipitation: number
    wind: number
    direction: string
    TemperatureData: any
    HumidityData: any
    WindData: any
    PrecipitationData: any
    weather: string
}
@Component({
  components: {
  }
})
export default class WeatherPanel extends Vue {
    public $refs!: {
        chronicleSplide: any
    }
    @Getter(GetterNames.getFarmFromRoute) private getFarmFromRoute!: any
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: any
    @Getter(GetterNames.getSidebarModel) private getSidebarModel!: any
    @Getter(GetterNames.getChroniclePanel) private getChroniclePanel!: any
    @Getter(GetterNames.getIsDataLoading) private getIsDataLoading!: any
    @Mutation(MutationNames.setIsDataLoading) private setIsDataLoading !: any
    @Mutation(MutationNames.setChroniclePanel) private setChroniclePanel!: any
    private arrOfWeedDays = [i18n.t('general.dates.week[6]') as string, i18n.t('general.dates.week[0]') as string, i18n.t('general.dates.week[1]') as string, i18n.t('general.dates.week[2]') as string, i18n.t('general.dates.week[3]') as string, i18n.t('general.dates.week[4]') as string, i18n.t('general.dates.week[5]') as string]
    private arrayOfMonth = [i18n.t('general.dates.month[0]') as string, i18n.t('general.dates.month[1]') as string, i18n.t('general.dates.month[2]') as string, i18n.t('general.dates.month[3]') as string, i18n.t('general.dates.month[4]') as string, i18n.t('general.dates.month[5]') as string, i18n.t('general.dates.month[6]') as string, i18n.t('general.dates.month[7]') as string, i18n.t('general.dates.month[8]') as string, i18n.t('general.dates.month[9]') as string, i18n.t('general.dates.month[10]') as string, i18n.t('general.dates.month[11]') as string]
    private isWeatherPanelVisible = false
    private meteoRecent: MeteoData[] = []
    private convertedMeteoData: MeteoStatistic[] = []
    private currentTabIndex = 0
    private currentDayIndex = 0
    private meteoChart: any
    private splideOption = {
        rewind : false,
        autoWidth: true,
        pagination: false,
        perMove: 2
    }
    private splideOptions2 = {
        rewind : false,
        autoWidth: true,
        pagination: false,
        perMove: 2,
        focus: 'center'
    }
    private currentMeteoId: any = {
        farmId: -1,
        fieldId: -1
    }
    private chroniclesArray: any = []
    private autoplay: any = false
    private chroniclesTimes: any = ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00',
        '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00']
    private selectedTime: any = '0'
    private ignorNext: boolean = false

    @Watch('selectedTime')
    private onchangeSelectedTime(val: any, old: any) {
        if (val === '0') {
            if (this.ignorNext) {
                this.ignorNext = false
            } else {
                if (this.getChroniclePanel.selected !== 0) {
                    console.log(this.getChroniclePanel.selected)
                    const index = this.getChroniclePanel.selected - 1
                    this.setChroniclePanel({selected: index})
                    this.$refs.chronicleSplide.$data.splide.go(index)
                    this.ignorNext = true
                    this.selectedTime = '24'
                }
            }
        } else if (val === '24') {
            if (this.ignorNext) {
                this.ignorNext = false
            } else {
                if (this.getChroniclePanel.selected !== this.chroniclesArray.length - 1) {
                    const index = this.getChroniclePanel.selected + 1
                    this.setChroniclePanel({selected: index})
                    this.$refs.chronicleSplide.$data.splide.go(index)
                    this.ignorNext = true
                    this.selectedTime = '0'
                }
            }
        }
        const count = this.getChroniclePanel.selected * 24 + Number(this.selectedTime)
        if (this.getChroniclePanel.layer !== count) {
            this.setChroniclePanel({layer: count + 24 + new Date().getTimezoneOffset() / 60})
        }
    }

    private changeAutoPlay(val: boolean, isTimeOut: boolean) {
        if (isTimeOut) {
            if (this.autoplay && this.getChroniclePanel.status) {
                if (this.getIsDataLoading) {
                    setTimeout(() => {this.changeAutoPlay(true, true)}, 1000)
                } else {
                    if (this.getChroniclePanel.selected !== this.chroniclesArray.length - 1 || this.selectedTime !== '24') {
                        const current = Number(this.selectedTime)
                        this.selectedTime = (current + 1).toString()
                        setTimeout(() => {this.changeAutoPlay(true, true)}, 2000)
                    } else {
                        this.changeAutoPlay(false, false)
                    }
                }
            } else {
                this.changeAutoPlay(false, false)
            }
        } else {
            this.autoplay = val
            if (this.autoplay === true) {
                setTimeout(() => {this.changeAutoPlay(true, true)}, 2000)
            }
        }
    }

    private getLegendValue(val: any) {
        if (this.getChroniclePanel.indicator === 'temperature') {
            if (val === 'array') return temperatureRanges.slice().reverse()
            if (val === 'unit') return '°C'
        } else if (this.getChroniclePanel.indicator === 'precipitation') {
            if (val === 'array') return precipitationRanges.slice().reverse()
            if (val === 'unit') return 'мм'
        } else if (this.getChroniclePanel.indicator === 'cloudiness') {
            if (val === 'array') return cloudsRanges.slice().reverse()
            if (val === 'unit') return '%'
        } else if (this.getChroniclePanel.indicator === 'pressure') {
            if (val === 'array') return pressureRanges.slice().reverse()
            if (val === 'unit') return 'мм'
        }
    }

    private isTimeVisible(index: any) {
        if (window.innerWidth > 1200) {
            return (index + 1) % 2 === 0
        } else if (window.innerWidth > 800) {
            return (index + 1) % 3 === 0
        } else if (window.innerWidth > 600) {
            return (index + 1) % 4 === 0
        } else {
            return (index + 1) % 6
        }
    }

    private getWeatherPanelHeight() {
        if (this.getChroniclePanel.status) {
            return '175px'
        } else return this.isWeatherPanelVisible ? '303px' : '40px'
    }

    private setWeatherPanelVisible(val: any) {
        this.isWeatherPanelVisible = val
    }

    private setWeatherChroniclesVisible(val: any) {
        this.setChroniclePanel({status: val})
    }

    private setChronicleSelectDay(index: any) {
        this.changeAutoPlay(false, false)
        this.setChroniclePanel({selected: index})
        this.$refs.chronicleSplide.$data.splide.go(index)
        const count = this.getChroniclePanel.selected * 24 + Number(this.selectedTime)
        if (this.getChroniclePanel.layer !== count) {
            this.setChroniclePanel({layer: count + 24 + new Date().getTimezoneOffset() / 60})
        }
    }

    private setCurrentIndicator(val: any) {
        this.setChroniclePanel({indicator: val})
        this.changeAutoPlay(false, false)
    }

    private getWeatherIconName(condition: any) {
        if (condition === 'sunny') {
            return 'sunny_icon'
        } else if (condition === 'partially cloudy') {
            return 'partially_cloudy_icon'
        } else if (condition === 'cloudy') {
            return 'cloudy_icon'
        } else if (condition === 'rainy') {
            return 'rainy_icon'
        } else if (condition === 'snowy') {
            return 'snowy_icon'
        } else if (condition === 'stormy') {
            return 'stormy_icon'
        } else {
            return 'sunny_icon'
        }
    }

    private mounted() {
        this.setWeatherPanelVisible(false)
        // Chart.defaults.line.showLines = true
        // Chart.defaults.line.spanGaps = true
        const ctx: any = document.getElementById('weather-chart')
        this.meteoChart = new Chart(ctx.getContext('2d'), {
            type: 'line',
            data: {
                datasets: []
            }
            // plugins: {
            //     zoom: {
            //         pan: {
            //             enabled: true,
            //             mode: 'xy'
            //         },
            //         zoom: {
            //             enabled: true,
            //             mode: 'xy'
            //         }
            //     }
            // },
            // options: {
            //     legend: false,
            //     elements: {
            //         point: {
            //             radius: 0
            //         }
            //     },
            //     scales: {
            //         yAxes: [
            //             {
            //                 id: 'y-axis',
            //                 position: 'left',
            //                 gridLines: {color: 'rgba(255, 255, 255, 0.08)', zeroLineColor: 'rgba(255, 255, 255, 0.08)'},
            //                 ticks: {
            //                     maxTicksLimit: 4
            //                 }
            //             }
            //         ],
            //         xAxes: [
            //             {
            //                 type: 'time',
            //                 time: {
            //                     unit: 'hour',
            //                     tooltipFormat: 'HH:mm',
            //                     displayFormats: {
            //                         'hour': 'HH:mm'
            //                     }
            //                 },
            //                 ticks: {
            //                     fontColor: 'rgba(255, 255, 255, 0.4)',
            //                     maxTicksLimit: 6,
            //                     maxRotation: 0,
            //                     minRotation: 0
            //                 }
            //             }
            //         ]
            //     },
            //     tooltips: {
            //         displayColors: false
            //     }
            // }
        })
        this.fetchMeteoForWeek()
        this.fetchChroniclesData()
    }

    private fetchChroniclesData() {
        this.setChroniclePanel({
            status: false,
            indicator: 'precipitation',
            selected: 0,
            layer: 0
        })
        this.chroniclesArray = []
        const startDate = new Date()
        for (let i = 0; i < 14; i++) {
            const day = new Date(startDate.getTime() + i * (24 * 60 * 60 * 1000))
            this.chroniclesArray.push({
                day: day.getDate(),
                month: this.arrayOfMonth[day.getMonth()],
                weekday: this.arrOfWeedDays[day.getDay()]
            })
        }
        this.selectedTime = startDate.getHours().toString()
        this.setChroniclePanel({layer: startDate.getHours() + 24 + new Date().getTimezoneOffset() / 60})
    }

    private ExtendWeatherPanel() {
        const newData: any = {
            labels: '.....',
            datasets: []
        }
        // const data = []
        if (this.currentTabIndex === 0) {
            newData.datasets.push({
                label: 'Температура',
                data: this.convertedMeteoData[this.currentDayIndex].TemperatureData.map((x: any) => x.y),
                backgroundColor: 'rgba(255, 199, 0, 0.12)',
                borderColor: '#FFC700',
                fill: 'start',
                pointHitRadius: 5,
                pointRadius: 0,
                borderWidth: 1
            })
        } else if (this.currentTabIndex === 1) {
            newData.datasets.push({
                label: 'Влажность',
                data: this.convertedMeteoData[this.currentDayIndex].HumidityData.map((x: any) => x.y),
                backgroundColor: 'rgba(0, 140, 219, 0.12)',
                borderColor: '#008CDB',
                fill: 'start',
                pointHitRadius: 5,
                pointRadius: 0,
                borderWidth: 1
            })
        } else if (this.currentTabIndex === 2) {
            newData.datasets.push({
                label: 'Ветер',
                data: this.convertedMeteoData[this.currentDayIndex].WindData.map((x: any) => x.y),
                backgroundColor: 'rgba(255, 199, 0, 0.12)',
                borderColor: '#FFC700',
                fill: 'start',
                pointHitRadius: 5,
                pointRadius: 0,
                borderWidth: 1
            })
        } else if (this.currentTabIndex === 3) {
            newData.datasets.push({
                label: 'Осадки',
                data: this.convertedMeteoData[this.currentDayIndex].PrecipitationData.map((x: any) => x.y),
                backgroundColor: 'rgba(0, 140, 219, 0.12)',
                borderColor: '#008CDB',
                fill: 'start',
                pointHitRadius: 5,
                pointRadius: 0,
                borderWidth: 1
            })
        }
        // this.meteoChart.data.datasets = data
        this.meteoChart.data = newData
        this.meteoChart.update()
    }

    @Watch('getFarmFromRoute')
    private fetchMeteoForWeek() {
        if (this.getFarmFromRoute) {
            if (this.currentRoute.params.subfieldId) {
                if (Number(this.currentRoute.params.subfieldId) !== this.currentMeteoId.fieldId) {
                    this.setIsDataLoading(true)
                    httpGet({
                        url: `${apiUrl}/web/cached/by_field/${this.currentRoute.params.subfieldId}/`,
                        isSecureRequest: true,
                        onSuccess: (json: any) => {
                            this.meteoRecent = json
                            this.currentDayIndex = 0
                            this.convertedMeteoData = []
                            this.normolizeWeatherData()
                        },
                        onError: error => console.log(error),
                        doFinally: () => {
                            this.setIsDataLoading(false)
                            this.currentMeteoId.fieldId = Number(this.currentRoute.params.subfieldId)
                        }
                    })
                }
            } else if (this.getFarmFromRoute.id !== this.currentMeteoId.farmId) {
                this.setIsDataLoading(true)
                httpGet({
                    url: `${apiUrl}/web/cached/by_farm/${this.getFarmFromRoute.id}/`,
                    isSecureRequest: true,
                    onSuccess: (json: any) => {
                        this.meteoRecent = json
                        this.currentDayIndex = 0
                        this.convertedMeteoData = []
                        this.normolizeWeatherData()
                    },
                    onError: error => console.log(error),
                    doFinally: () => {
                        this.setIsDataLoading(false)
                        this.currentMeteoId.farmId = this.getFarmFromRoute.id
                    }
                })
            }
        }
    }

    private normolizeWeatherData() {
        const todayDate = new Date()
        const currentDay: any = this.formatDate(todayDate)
        for (const day of this.meteoRecent) {
            const temperatureArray = []
            const humidityArray = []
            const windArray = []
            const precipitationArray = []
            for (const hour of day.hourly) {
                temperatureArray.push({t: hour.date.substring( 0, 16 ), y: hour.temperature})
                humidityArray.push({t: hour.date.substring( 0, 16 ), y: Math.round(hour.humidity * 10) / 10})
                const windDirectionHour = this.getWindDirection(hour.wind_east, hour.wind_north)
                windArray.push({t: hour.date.substring( 0, 16 ), y: Math.round(hour.wind * 10) / 10, direction: windDirectionHour})
                precipitationArray.push({t: hour.date.substring( 0, 16 ), y: Math.round(hour.precipitation * 100) / 100})
            }
            const windDirection = this.getWindDirection(day.wind_east, day.wind_north)
            this.convertedMeteoData.push({
                day: new Date(day.date.substring(0, 10)).getDate(),
                month: this.arrayOfMonth[new Date(day.date.substring(0, 10)).getMonth()],
                weekday: this.arrOfWeedDays[new Date(day.date.substring(0, 10)).getDay()],
                temperature: day.temperature,
                max_temp: day.day_night.day_temperature,
                min_temp: day.day_night.night_temperature,
                humidity: day.humidity,
                wind: day.wind,
                precipitation: day.precipitation,
                direction: windDirection,
                TemperatureData: temperatureArray,
                HumidityData: humidityArray,
                WindData: windArray,
                PrecipitationData: precipitationArray,
                weather: day.weather_condition
            })
        }
        this.ExtendWeatherPanel()
    }

    private getWindDirection(x: any, y: any) {
        const directionData: any = [
            {from: -1, to: 22.5, value: i18n.t('headers.workspace.weather.directions.east')},
            {from: 22.5, to: 67.5, value: i18n.t('headers.workspace.weather.directions.north_east')},
            {from: 67.5, to: 112.5, value: i18n.t('headers.workspace.weather.directions.north')},
            {from: 112.5, to: 157.5, value: i18n.t('headers.workspace.weather.directions.north_west')},
            {from: 157.5, to: 202.5, value: i18n.t('headers.workspace.weather.directions.west')},
            {from: 202.5, to: 247.5, value: i18n.t('headers.workspace.weather.directions.south_west')},
            {from: 247.5, to: 292.5, value: i18n.t('headers.workspace.weather.directions.south')},
            {from: 292.5, to: 337.5, value: i18n.t('headers.workspace.weather.directions.south_east')},
            {from: 337.5, to: 361, value: i18n.t('headers.workspace.weather.directions.east')}
        ]
        const windAngle = Math.acos(x / Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2))) * 180 / Math.PI
        for (const direction of directionData) {
            if (direction.from < windAngle && direction.to >= windAngle) {
                return direction.value
            }
        }
        return 'О'
    }

    private formatDate(date: any) {
        const d = new Date(date)
        let month = '' + (d.getMonth() + 1)
        let day = '' + d.getDate()
        const year = d.getFullYear()
        if (month.length < 2) {
            month = '0' + month
        }
        if (day.length < 2) {
            day = '0' + day
        }
        return [year, month, day].join('-')
    }

}
