




































import { Component, Vue, Watch} from 'vue-property-decorator'
import { GetterNames } from '@/store/types'
import { Getter } from 'vuex-class'
import { baseUrl } from '@/domain/constants'
import { categories } from '@/data/agroChemCategories'
@Component ({
    components: {
    }
})
export default class AgroChemFieldResult extends Vue {
    @Getter(GetterNames.getMapData) private mapData !: any
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: any
    @Getter(GetterNames.getAgroChemFieldResult) private getAgroChemFieldResult!: any
    @Getter(GetterNames.getFarmFromRoute) private getFarmFromRoute!: any
    private resultCategories = categories
    private textScale = 1

    private getImageUrl(imageUrl: any) {
        return `${baseUrl}${imageUrl}`
    }

    private getValue(point: any) {
        const propertyArr = ['ph', 'phosphorus', 'potassium', 'nitrogen', 'sulfur', 'humus']
        const value = point[propertyArr[this.getAgroChemFieldResult.selectedCategory]]
        if (value) {
            return value.toFixed(2)
        }
        return '0'
    }
    private getFillColor(point: any) {
        const category: any = this.resultCategories[this.getAgroChemFieldResult.selectedCategory]
        const propertyArr = ['ph', 'phosphorus', 'potassium', 'nitrogen', 'sulfur', 'humus']
        const value = point[propertyArr[this.getAgroChemFieldResult.selectedCategory]]
        for (const row of category.indicators) {
            if (row.lower === null) {
                if (Number(row.upper) > value) return row.color
            } else if (row.upper === null) {
                return row.color
            } else if (row.upper && row.lower) {
                if ((Number(row.upper) > value)) return row.color
            }
        }
        return 'rgba(255,255,255, 0.12)'
    }

    private getAgroChemImage() {
        const propertyArr = ['ph', 'phosphorus', 'potassium', 'nitrogen', 'sulfur', 'humus']
        const image = this.getAgroChemFieldResult.analysisResult.result_images.find((x: any) => x.name === propertyArr[this.getAgroChemFieldResult.selectedCategory])
        if (image) {
            return image.image
        }
        return null
    }
    private getCurrentField() {
        if (this.getFarmFromRoute) {
            const field = this.getFarmFromRoute.fields.find((x: any) => x.id.toString() === this.currentRoute.params.subfieldId)
            if (field) {
                return field
            }
        }
        return false
    }
    private getBoundaries(field: any) {
        if (!field) return [0, 0, 0, 0]
        let Xmin = Number.MAX_SAFE_INTEGER
        let Xmax = Number.MIN_SAFE_INTEGER
        let Ymin = Number.MAX_SAFE_INTEGER
        let Ymax = Number.MIN_SAFE_INTEGER
        for (const arr of field.geom.coordinates[0][0]) {
            Xmax = arr[0] > Xmax ? arr[0] : Xmax
            Xmin = arr[0] < Xmin ? arr[0] : Xmin
            Ymax = arr[1] > Ymax ? arr[1] : Ymax
            Ymin = arr[1] < Ymin ? arr[1] : Ymin
        }
        return [Xmin, Ymin, Xmax, Ymax]
    }
    private getPointRadius() {
        return 50 / this.calculateMetersInPixel()
    }
    private calculateMetersInPixel() {
        const Spixel = ( 2 * Math.PI * 6378137) / Math.pow( 2, this.mapData.zoom + 8 )
        return Spixel
    }
    @Watch('zoom')
    private zoomChanged(currentZoom: number) {
      this.textScale = this.mapZoomToTextScale(currentZoom)
    }
    private mapZoomToTextScale(currentZoom: number) {
      const maxScale = 4
      const minScale = 1
      const zoomRatio = (currentZoom - this.mapData.MIN_ZOOM) / (this.mapData.MAX_ZOOM - this.mapData.MIN_ZOOM)

      return zoomRatio * (maxScale - minScale) + minScale
    }
}
