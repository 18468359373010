














































































































































































































































import { Component, Vue, Emit } from 'vue-property-decorator'
import { httpPost, httpPut } from '@/util/http'
import { apiUrl } from '@/domain/constants'
import { GetterNames, MutationNames } from '@/store/types'
import { Getter, Mutation } from 'vuex-class'
import { serviceTypes, repairStages, recordStatuses } from '@/data/serviceBookData'
import i18n from '@/i18n'
import DateRangePicker from '@/components/DateRangePicker.vue'
interface DefaultArgs {
  selected: any,
  selectedName: string,
  quantity: any,
  onerror: string,
  visible: boolean
}
@Component({
  components: {
      DateRangePicker
  }
})
export default class NewCarModel extends Vue {
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: any
    @Getter(GetterNames.getLoadingDataNames) private getLoadingDataNames!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    private isShown = false
    private repairStages: any = repairStages
    private recordStatuses: any = recordStatuses
    private record: any = {
        serviceType: {
            selected: '',
            onerror: ''
        },
        repairStage: {
          selected: '',
          onerror: ''
        },
        date: {
          start: '',
          end: '',
          onerror: ''
        },
        consumables: {
            selected: '',
            collapsed: false,
            onerror: ''
        },
        costs: {
          selected: '',
          collapsed: false,
          onerror: ''
        },
        status: {
            selected: '',
            onerror: ''
        },
        mileage: {
            selected: '',
            onerror: ''
        },
        engine_hour: {
            selected: '',
            onerror: ''
        },
        cost: {
            totalCost: 0,
            selected: '',
            onerror: ''
        },
        comments: {
            selected: '',
            onerror: ''
        }
    }
    private isUpdate = false
    private recordForUpdate: any = null

    private consumables = new Array<DefaultArgs>({selectedName: '', selected: '', quantity: '', onerror: '', visible: false })
    private costs = new Array<DefaultArgs>({selectedName: '', selected: '', quantity: '', onerror: '', visible: false })

    private getDuration() {
        const datePicker: any = this.$refs.daterangepicker as any
        if (datePicker) {
            const startDate = datePicker.getStartDate()
            const endDate = datePicker.getEndDate()
            if (startDate && endDate && !startDate.includes('NaN-NaN-NaN') && !endDate.includes('NaN-NaN-NaN')) {
                const startDay = startDate.split(' ')[0].split('-')
                const startTime = startDate.split(' ')[1].split(':')
                const endDay = endDate.split(' ')[0].split('-')
                const endTime = endDate.split(' ')[1].split(':')
                return i18n.t('general.dates.from') + startDay[2] + '.' + startDay[1] + '.' + startDay[0].substring(2) + ' ' + startTime[0] + ':' + startTime[1] +
                    ' - ' + i18n.t('general.dates.to') + endDay[2] + '.' + endDay[1] + '.' + endDay[0].substring(2) + ' ' + endTime[0] + ':' + endTime[1]
            }
        }
        return (i18n.t('general.dates.from') as string) + ( i18n.t('general.dates.format_date') as string) + ' ' + (i18n.t('general.dates.format_time') as string) + ' - ' + (i18n.t('general.dates.to') as string) + ( i18n.t('general.dates.format_date') as string) + ' ' + (i18n.t('general.dates.format_time') as string)
    }

    private getServiceTypes() {
      const allTypes: any = []
      serviceTypes.forEach((category: any) => {
        category.types.forEach((type: string) => {
          allTypes.push(`${category.name} / ${type}`)
        })
      })
      return allTypes
    }

    private onSelectServiceType(item: any) {
      this.record.serviceType.selected = item
    }

    private onSelectRepairStage(item: any) {
      this.record.repairStage.selected = item
    }

    private consumablesToggle() {
        this.record.consumables.collapsed = !this.record.consumables.collapsed
    }

    private costsToggle() {
      this.record.costs.collapsed = !this.record.costs.collapsed
    }

    private addMaterial() {
      this.consumables.push({selectedName: '', selected: '', quantity: '', onerror: '', visible: true })
    }

    private addCost() {
      this.costs.push({selectedName: '', selected: '', quantity: '', onerror: '', visible: true })
    }

    private visibilityToggle(item: any) {
      item.visible = !item.visible
    }

    private removeFromList(array: any, index: number) {
        array.splice(index, 1)
    }

    private getStatus(status: any, startDate: any, endDate: any) {
        const today = new Date()
        const startTime = new Date(startDate)
        const endTime = new Date(endDate)
        if (status === 'done') return (i18n.t('general.process.done') as string)
        if (status === 'cancelled') return (i18n.t('general.process.cancelled') as string)
        if (today.getTime() < startTime.getTime()) {
            return (i18n.t('general.process.planned') as string)
        } else if (today.getTime() < endTime.getTime()) {
            return (i18n.t('general.process.in_process') as string)
        } else {
            return (i18n.t('general.process.outdated') as string)
        }
    }

    private update(record: any) {
      this.recordForUpdate = record
      this.isShown = true
      this.isUpdate = true
      this.record = {
          serviceType: {
            selected: record.service_type,
            onerror: ''
          },
          repairStage: {
            selected: record.repair_stage,
            onerror: ''
          },
          date: {
            start: record.start_time,
            end: record.end_time,
            onerror: ''
          },
          consumables: {
            selected: record.consumables,
            collapsed: false,
            onerror: ''
          },
          costs: {
            collapsed: false,
            onerror: ''
          },
          status: {
            selected: '',
            onerror: ''
          },
          mileage: {
            selected: record.mileage.toString(),
            onerror: ''
          },
          engine_hour: {
            selected: record.engine_hour.toString(),
            onerror: ''
          },
          cost: {
            totalCost: record.cost,
            selected: record.cost.toString(),
            onerror: ''
          },
          comments: {
            selected: record.comments,
            onerror: ''
          },
          serviceTypeDropdown: false,
          repairStageDropdown: false,
          statusDropdown: false
        }
      this.consumables[0].selectedName = record.consumables
      const datePicker: any = this.$refs.daterangepicker as any
      if (datePicker) {
            datePicker.setTime(record.start_time, record.end_time)
        }
    }

    private show() {
      this.isShown = true
      this.record = {
        serviceType: {
          selected: '',
          onerror: ''
        },
        repairStage: {
          selected: '',
          onerror: ''
        },
        date: {
          start: '',
          end: '',
          onerror: ''
        },
        consumables: {
          selected: '',
          collapsed: false,
          onerror: ''
        },
        costs: {
          collapsed: false,
          onerror: ''
        },
        status: {
          selected: '',
          onerror: ''
        },
        mileage: {
          selected: '',
          onerror: ''
        },
        engine_hour: {
          selected: '',
          onerror: ''
        },
        cost: {
          totalCost: 0,
          selected: '',
          onerror: ''
        },
        comments: {
          selected: '',
          onerror: ''
        },
        serviceTypeDropdown: false,
        repairStageDropdown: false,
        statusDropdown: false
      }
    }

    private addNewRecord() {
        if (this.checkForError()) return
        const recordStatus = 'normal'
        const startDate = (this.$refs.startDateModel as any).getDate()
        const endDate = (this.$refs.endDateModel as any).getDate()
        this.setLoadingDataNames('newRecordAddRecord')
        httpPost({
            url: `${apiUrl}/yields/service/book/create`,
            isSecureRequest: true,
            body: {
                tractor: this.currentRoute.params.carId,
                status: recordStatus,
                start_time: new Date(startDate),
                end_time: new Date(endDate),
                mileage: isNaN(this.record.mileage.selected) ? 0 : Number(this.record.mileage.selected),
                engine_hour: isNaN(this.record.engine_hour.selected) ? 0 : Number(this.record.engine_hour.selected),
                comments: this.record.comments.selected,
                service_type_info: [
                    {
                        service_type: this.record.serviceType.selected,
                        consumables: this.record.consumables.selected,
                        cost: isNaN(this.record.cost.selected) ? 0 : Number(this.record.cost.selected),
                        repair_stage: this.record.repairStage.selected
                    }
                ]
            },
            onSuccess: json => {
                this.reload()
            },
            doFinally: () => {
                this.setLoadingDataNames('newRecordAddRecord')
                this.hide()
            }
        })
    }

    private checkForError() {
      const startDate = (this.$refs.startDateModel as any).getDate()
      const endDate = (this.$refs.endDateModel as any).getDate()
      let counter = 0
      if (this.getLoadingDataNames('newRecordAddRecord') || this.getLoadingDataNames('newRecordUpdateRecord')) return false
      if (!this.record.serviceType.selected) {
            this.record.serviceType.onerror =  i18n.t('headers.workspace.autopark.add_record.errors.type')
            counter++
        } else {
            this.record.serviceType.onerror = ''
        }
      if (this.record.serviceType.selected.includes('Ремонт / ') && !this.record.repairStage.selected) {
            this.record.repairStage.onerror = i18n.t('headers.workspace.autopark.add_record.errors.repair_stage')
            counter++
        }
        // if (!this.costs[0].selectedName) {
        //     this.record.cost.onerror = i18n.t('headers.workspace.autopark.add_record.errors.sum')
        //     counter++
        // }
      if (!startDate || !endDate) {
            this.record.date.onerror = i18n.t('headers.workspace.autopark.add_record.errors.date_range')
            counter++
        } else {
          this.record.date.onerror = ''
        }
      return counter > 0
    }

    private updateRecord() {
        if (this.checkForError()) return
        const recordStatus = 'normal'
        const startDate = (this.$refs.startDateModel as any).getDate()
        const endDate = (this.$refs.endDateModel as any).getDate()
        this.setLoadingDataNames('newRecordUpdateRecord')
        httpPut({
            url: `${apiUrl}/yields/service/book/${this.recordForUpdate.id}`,
            isSecureRequest: true,
            body: {
                status: recordStatus,
                start_time: new Date(startDate),
                end_time: new Date(endDate),
                mileage: isNaN(this.record.mileage.selected) ? 0 : Number(this.record.mileage.selected),
                engine_hour: isNaN(this.record.engine_hour.selected) ? 0 : Number(this.record.engine_hour.selected),
                comments: this.record.comments.selected,
                service_type: this.record.serviceType.selected,
                consumables: this.record.consumables.selected,
                cost: isNaN(this.record.cost.selected) ? 0 : Number(this.record.cost.selected),
                repair_stage: this.record.repairStage.selected
            },
            onSuccess: json => {
                this.reload()
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('newRecordUpdateRecord')
                this.hide()
            }
        })
    }

    @Emit('reload')
    private reload() {
        return {}
    }

    private hide() {
        this.isShown = false
        this.isUpdate = false
        this.closeDatePicker()
        const datePicker: any = this.$refs.daterangepicker as any
        if (datePicker) {
            datePicker.discard()
        }
    }

    private showDateRangePicker() {
        const container: any = document.getElementById('record-body')
        container.scrollTop = container.scrollHeight
        const datePicker: any = this.$refs.daterangepicker as any
        if (datePicker) {
            datePicker.show()
        }
    }

    private closeServiceTypeDropdown() {
        this.record.serviceTypeDropdown = false
    }

    private closeRepairStageDropdown() {
        this.record.repairStageDropdown = false
    }

    private closeStatusDropdown() {
        this.record.statusDropdown = false
    }

    private closeDatePicker() {
        const datePicker: any = this.$refs.daterangepicker as any
        if (datePicker) {
            datePicker.hide()
        }
    }
}
