export default {
    title: "Диспетчерлік",
    manage: "Хабарландыруларды басқару",
    permissions: {
        alarms: {
            title: "Жариялау",
            workspace_change: "Жобаға шақыру, кіру немесе шығу",
            notice_mention: "Жазбалардағы белгі",
            notice_reply: "Жазбаға жауап",
            response_new_image: "Проблемалық аймақтары бар жаңа суреттер",
            croprotation_empty: "Толтырылмаған өріс тарихы",
            task_add: "Тапсырмаларға қосу",
            tractor_motion: "Техника жұмысының басталуы мен аяқталуы",
            payment_done: "Жазылымды төлеу"
        },
        warnings: {
            title: "Қауіп",
            notice_field_not_visit: "Қаралмаған жерлер",
            weather_draught: "Жауын-шашынның болмауы",
            response_dangerous_zone: "Проблемалық аймақтары бар жаңа суреттер",
            task_incomplete: "Орындалмаған міндеттер",
            telematic_max_speed: "Жылдамдықты арттыру",
            telematic_out_of_field: "Шеқараларда жұмыс істеу",
            tractor_stop: "Жазық техника",
            tractor_breakdown: "Техниканың бұзылуы",
            tractor_expired_maintenace: "Техникаға қызмет көрсету мерзімі өткен",
            telematic_fuel: "Телематика-отын (ағызу, резервуардың толып кетуі)",
            telematic_no_info: "Телематика-деректердің болмауы (желі мәселесі, өшіру, сенсордағы кернеудің төмендеуі)"
        }
    },
    notif_categories: {
        all: "Барлық",
        notification: "Хабарланыру",
        alarm: "Дабыл",
        spam: "Спам"
    },
    msg_categories: {
        all: "Барлық",
        starred: "Белгіленген",
        read: "Оқылған",
        not_read: "Оқылмаған",
        not_marked: "Белгісіз"
    },
    msg_actions: {
        spam: "Спамға",
        share: "Бөлісу",
        print: "Басып шығару",
        to_notes: "Жазбаларға өту",
        to_projects: "Проектілерге өту",
        to_farm: "Шаруашылыққа өту"
    }
}