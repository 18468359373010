import { render, staticRenderFns } from "./Telematics.vue?vue&type=template&id=6270e03f&scoped=true&"
import script from "./Telematics.vue?vue&type=script&lang=ts&"
export * from "./Telematics.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6270e03f",
  null
  
)

export default component.exports