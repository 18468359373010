




































































































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import i18n from '@/i18n'
import DatePicker from '@/components/DatePicker.vue'
import {httpGet, httpPost, httpDelete} from '@/util/http'
import {apiUrl} from '@/domain/constants'
import {Mutation} from 'vuex-class'
import {MutationNames} from '@/store/types'
@Component({
    components: {
        DatePicker
    }
})
export default class ViewTable extends Vue {
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    @Prop({default: []}) private data!: any[]
    @Prop({default: -1}) private farmId!: number
    private thisPasture = true
    private types: any[] = [
        {
            name: 'cadastre',
            type: 'string',
            canEdit: true
        },
        {
            name: 'area',
            unit: i18n.t('general.units.he'),
            type: 'number',
            canEdit: false
        },
        {
            name: 'area_by_doc',
            unit: i18n.t('general.units.he'),
            type: 'number',
            canEdit: true
        },
        {
            name: 'fields_info',
            canEdit: false
        },
        {
            name: 'arable',
            type: 'number',
            canEdit: false
        },
        {
            name: 'araeble_by_doc',
            type: 'number',
            canEdit: true
        },
        {
            name: 'pasture',
            type: 'number',
            canEdit: true
        },
        {
            name: 'haymaking',
            type: 'number',
            canEdit: true
        },
        {
            name: 'others',
            type: 'number',
            canEdit: true
        },
        {
            name: 'registration_date',
            type: 'date',
            canEdit: true
        },
        {
            name: 'document_number',
            type: 'string',
            pre: '№',
            canEdit: true
        },
        {
            name: 'document_deadline',
            type: 'date',
            canEdit: true
        },
        {
          name: 'notes',
          type: 'string',
          canEdit: true
        }
    ]
    private headers: string[] = [
        i18n.t('headers.workspace.profile.farms.table.cadastre_number') as string,
        i18n.t('headers.workspace.profile.farms.table.area') as string,
        i18n.t('headers.workspace.profile.farms.table.area_by_doc') as string,
        i18n.t('headers.workspace.profile.farms.table.fields') as string,
        i18n.t('headers.workspace.profile.farms.table.arable_land_fact') as string,
        i18n.t('headers.workspace.profile.farms.table.arable_land') as string,
        i18n.t('headers.workspace.profile.farms.table.pasture') as string,
        i18n.t('headers.workspace.profile.farms.table.haymaking') as string,
        i18n.t('headers.workspace.profile.farms.table.others') as string,
        i18n.t('headers.workspace.profile.farms.table.registration_date') as string,
        i18n.t('headers.workspace.profile.farms.table.registration_number') as string,
        i18n.t('headers.workspace.profile.farms.table.agreement_date') as string,
        i18n.t('headers.workspace.profile.farms.pastures.note') as string
    ]
    private myData: any[] = []
    private newCadastreMode = false
    private newCadastreNumber = ''
    private newCadastreArea = ''
    private newCadastreValid = -1
    private deletingCadastre = null

    private mounted() {
        for (const d of this.data) {
            const newval: any = {
                ...d,
                isEditing: false
            }
            this.myData.push(newval)
        }
    }

    private roundTo(num: number, decimal: number) {
        return Math.round(num * Math.pow(10, decimal)) / Math.pow(10, decimal)
    }

    private checkForDeadline(date: any) {

      if (date) {
        const currentDate = new Date(date)
        const now = new Date()
        const daysCount = Math.round((currentDate.getTime() - now.getTime()) / (1000 * 60 * 60 * 24))

        return daysCount < 182
      }
      return false
    }

    private saveChanges(index: number) {
        const dates = this.$refs.datePicker as any
        this.myData[index].document_deadline = dates[1].getDate()
        this.myData[index].registration_date = dates[0].getDate()
        if ( this.myData[index].area < 0 || this.myData[index].arable < 0 || this.myData[index].haymaking < 0 || this.myData[index].pasture < 0 || this.myData[index].others < 0) {
            alert(i18n.t('messages.errors.negative_area'))
            return
        }
        if ( this.myData[index].cadastre.replaceAll('-', '').length !== 11 ) {
            alert(i18n.t('messages.errors.eleven_digit_cadastre'))
            return
        }
        this.$emit('saveChanges', this.myData[index])
        this.myData[index].isEditing = false
    }

    private cancel(index: number) {
        this.myData[index].isEditing = false
    }

    private openFieldCadastre(fieldId: any) {

      this.$router.push(
          {
            name: 'fieldcadastre',
            params: {
              farmId: String(this.farmId),
              fieldId: String(fieldId)
            }
          })
    }

    private openCadastre(cadastre: any) {
      const cadastreId = cadastre.replace(/-/g, '')

      this.$router.push(
          {
            name: 'cadastreinfo',
            params: {
              farmId: String(this.farmId),
              cadastreId: String(cadastreId)
            }
          })
    }


  private searchCadastre(cadastreNumber: any) {
    if (cadastreNumber.length === 14 || cadastreNumber.length === 15) {
      const documentId = cadastreNumber.replaceAll('-', '')
      httpGet({
          url:  `${apiUrl}/check/cadastre/except/my/${this.farmId}/${cadastreNumber.replaceAll('-', '')}/`,
          isSecureRequest: true,
          detailResponse: true,
          onSuccess: json => {
            if (json.ok) {
                  if (json.json.error) {
                    this.newCadastreValid = 0
                    this.newCadastreArea = json.json.error
                  } else if (json.json.area) {
                    this.newCadastreValid = 1
                    this.newCadastreArea = Math.round(json.json.area / 100) / 100 + ' га'
                  }
            } else {
              this.$alert('Ошибка сервера', 'Ошибка', 'error')
            }
          },
          onError: error => {
            console.log(error)
          }
        })
      }
  }

  private addNewCadastre() {
      if (this.newCadastreValid !== 1) return this.newCadastreValid = 0
      httpPost({
        url:  `${apiUrl}/add/farm/info/`,
        isSecureRequest: true,
        detailResponse: true,
        body: {
          cadastre: this.newCadastreNumber.replaceAll('-', ''),
          farm: this.farmId
        },
        onSuccess: json => {
          if (json.ok) {
            this.$alert('Кадастр успешно добавлен', 'Сохранено', 'success')
            this.$emit('saveChanges', 'newData')
          } else {
            this.$alert('Ошибка сервера', 'Ошибка', 'error')
          }
        },
        onError: error => {
          console.log(error)
        }
      })
  }

  private deleteCadastre(cadastreId: any) {
      this.deletingCadastre = null
      httpDelete({
        url:  `${apiUrl}/delete/farm/info/${cadastreId}/ `,
        isSecureRequest: true,
        detailResponse: true,
        onSuccess: json => {
          if (json.ok) {
            this.$alert('Кадастр успешно удален', 'Удалено', 'success')
            this.$emit('saveChanges', 'newData')
          } else {
            this.$alert('Ошибка сервера', 'Ошибка', 'error')
          }
        },
        onError: error => {
          console.log(error)
        }
      })
  }

}
