export default {
    title: "Вход в кабинет",
    register: "Либо | зарегистрируйтесь бесплатно",
    number: "Номер телефона или e-mail",
    remember: "Запомнить меня",
    password_forgotten: "Забыли пароль?",
    email_confirm: "Подтверждение почты",
    password_reset: {
        title: "Восстановление пароля",
        explanation_1: "Укажите email для восстановления пароля",
        notification: "На Вашу почту | отправлена ссылка для подтверждения. Перейдите, пожалуйста, по ней для входа.",
        explanation_2: "Придумайте новый пароль",
        new_password: "Новый пароль",
        password_repeat: "Подтвердите пароль",
        success: "Пароль успешно изменен!",
        message: "Пожалуйста войдите в систему используя новый пароль"
    },
    errors: {
        google: "Проблема с google account, попробуйте еще раз",
        typo: "Неправильно введен логин или пароль",
        email: "Неправильный формат email-a",
        not_found: "Пользователь с данным email-ом не найден",
        server: "Ошибка сервера",
        data: "Неправильно введены данные",
        password: "Пароль должен содержать не менее 6 символов"
    }
}