




















































































































































































import { Vue, Component, Watch } from "vue-property-decorator"
import TimePicker from '@/components/TimePicker.vue'
import {httpPost, httpPut, httpDelete} from "@/util/http"
import {apiUrl} from "@/domain/constants"
import {Mutation} from "vuex-class"
import {MutationNames} from "@/store/types"
@Component({
    components: {
        TimePicker
    }
})
export default class PlanningDetailTechnic extends Vue {
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any

    private months: any = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']
    private colors: any = ['#2B4DFF', '#AA27E8', '#F37500', '#06BAF2', '#FFC700', '#5AF5D9', '#7D5EF8', '#FC7BE7', '#ADDE30']
    private isShown = false
    private technology: any = null

    private currentEventIndex = 0
    private twoDrivers: boolean = false
    private area = 0
    private infoAboutEvents: any = 0

    private idTractor: any = -1
    private idTechnology: any = -1
    private cultireId: any = -1

    @Watch('twoDrivers')
    private twoDriversWatcher(val: any) {
        if (val === true && this.technology[this.currentEventIndex].drivers.length === 1) {
            this.technology[this.currentEventIndex].drivers.push({
                start_time: '',
                end_time: '',
                shift: 2,
                salary_rate_for_ha: '',
                plan_task_tractor: this.technology[this.currentEventIndex].drivers[0].plan_task_tractor,
                driver: null
            })
        }
    }

    private getTechnicInfo(idTractor: any, idTechnology: any) {
        this.setLoadingDataNames('noteUpdateGetMembers')
        httpPost({
            url: `${apiUrl}/technology/tractor/detail/`,
            isSecureRequest: true,
            body: {
                technology: idTechnology,
                tractor: idTractor
            },
            onSuccess: json => {
                this.technology = json
                this.twoDrivers = this.technology[this.currentEventIndex].drivers.length === 2
                // this.normalizeDate()
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('noteUpdateGetMembers')
            }
        })
    }

    private checkForError() {
        let counter = 0

        this.technology[this.currentEventIndex].drivers.forEach((driver: any, index: number) => {
            if (index === 1 && !this.twoDrivers) return

            const regExp = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/
            if (!driver.start_time.match(regExp)) {
                Vue.alert('', 'Введите начальное время', 'warning')
                counter++
            }

            if (!driver.end_time.match(regExp)) {
                Vue.alert('', 'Введите время окончание', 'warning')
                counter++
            }

            if (driver.salary_rate_for_ha === '') {
                Vue.alert('', 'Введите время окончание', 'warning')
                counter++
            }
        })
        return counter === 0
    }

    private sendTechnicInfo() {
        const drivers = [{
            driver: '',
            start_time: this.technology[this.currentEventIndex].drivers[0].start_time,
            end_time: this.technology[this.currentEventIndex].drivers[0].end_time,
            shift: 1,
            salary_rate_for_ha: this.technology[this.currentEventIndex].drivers[0].salary_rate_for_ha,
            cost: 0
        }]
        if (this.twoDrivers) {
            drivers.push({
                driver: '',
                start_time: this.technology[this.currentEventIndex].drivers[1].start_time,
                end_time: this.technology[this.currentEventIndex].drivers[1].end_time,
                shift: 2,
                salary_rate_for_ha: this.technology[this.currentEventIndex].drivers[1].salary_rate_for_ha,
                cost: 0
            })
        }
        const tractor = {
            plan_task: this.technology[this.currentEventIndex].plan_task_id,
            tractors: [this.technology[this.currentEventIndex].tractor.id],
            average_speed: this.technology[this.currentEventIndex].tractor.average_speed,
            fuel_consumption_rate_for_ha: this.technology[this.currentEventIndex].tractor.fuel_consumption_rate_for_ha,
            required_volume: this.technology[this.currentEventIndex].tractor.required_volume,
            cost: this.technology[this.currentEventIndex].tractor.cost,
            fuel_price: this.technology[this.currentEventIndex].tractor.fuel_price
        }
        this.setLoadingDataNames('noteUpdateGetMembers')
        console.log(tractor)
        httpPut({
            url: `${apiUrl}/technology/task/tractor/${this.technology[this.currentEventIndex].tractor.id}/`,
            isSecureRequest: true,
            detailResponse: true,
            body: {
                tractor,
                drivers
            },
            onSuccess: json => {
                if (json.ok) {
                    Vue.alert('', 'Вы успешно обновили данные техники', 'success').then(() => {
                        this.hide()
                    })
                } else {
                    this.$alert('Ошибка сервера', 'Ошибка', 'error')
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('noteUpdateGetMembers')
            }
        })
    }

    private deleteTechnic() {
        httpDelete({
            url: `${apiUrl}/technology/task/tractor/${this.idTechnology}/`,
            isSecureRequest: true,
            onSuccess: json => {
                console.log(json)
                if (json.ok) {
                    Vue.alert('', 'Успешно удалено', 'success').then(() => {
                        this.hide()
                    })
                }
            },
            onError: error => {
                console.log(error)
            }
        })
    }

    private changeEvent(index: any) {
        this.currentEventIndex = index
    }

    // 30 Dec = 364
    private getNumberOfDay(date: any) {
        const newDate = new Date(date)
        const start: any = new Date(newDate.getFullYear(), 0, 0)
        return Math.floor((newDate.getTime() - start) / (1000 * 60 * 60 * 24))
    }

    // num from left px { margin-left: 87px }
    private getPercentage(day: number, index?: number, endDayIndex?: number) {
        const result = Math.floor((day / 365) * 100)
        if (result <= 10) {
            this.infoAboutEvents = -1
        } else if (result <= 90) {
            this.infoAboutEvents = 1
        }
        return result >= 0 ? result : 0
    }

    // line-width = { width: 60px }
    private getLineWidth(startDate: any, endDate: any) {
        const start = this.getNumberOfDay(startDate)
        const end = this.getNumberOfDay(endDate)
        const result = ((end - start) / 365) * 100
        return result >= 0 ? Math.floor(result) : 100 - this.getPercentage(start)// Math.floor(100 - Math.abs(result))
    }

    private getNormalize(day: any) {
        const currentDay = new Date(day)
        return currentDay.toLocaleDateString('ru')
    }

    private getPerformanceCost() {
        let cost: any = 0
        const areaOfTractor: any = (Number(this.technology[this.currentEventIndex].tractor.workload) / 10000).toFixed(2)
        cost = (this.technology[this.currentEventIndex].tractor.fuel_consumption_rate_for_ha * areaOfTractor).toFixed(2)
        return this.formatExpences(Math.abs(cost))
    }

    private getRequiredFuelVolume() {
        let cost = 0
        const areaOfTractor: any = (Number(this.technology[this.currentEventIndex].tractor.workload) / 10000).toFixed(2)
        cost = Math.floor(areaOfTractor * Number(this.technology[this.currentEventIndex].tractor.fuel_price || 1))
        return this.formatExpences(cost)
    }

    private formatExpences(expense: number) {
        return expense ? expense.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + '' : '0'
    }

    private show(cultireId: any, idTractor: any, idTechnology: any, area: any) {
        this.isShown = true
        this.cultireId = cultireId
        this.area = area
        this.idTractor = idTractor
        this.idTechnology = idTechnology
        this.getTechnicInfo(idTractor, idTechnology)
    }

    private hide() {
        this.isShown = false
        this.currentEventIndex = 0
        console.log(this.isShown)
    }
}
