









import { Component, Vue, Prop } from 'vue-property-decorator'
import { baseUrl } from '@/domain/constants'
import i18n from '@/i18n'
@Component({
    components: {
    }
})
export default class ImageResponses extends Vue {
    @Prop({default: null}) private responses!: any

    private normalizedDate(date: any) {
        if (date) {
            return date.substring(8, 10) + '.' + date.substring(5, 7) + '.' + date.substring(0, 4)
        }
        return i18n.t('general.dates.format_date')
    }
}
