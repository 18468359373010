































import { Component, Vue } from 'vue-property-decorator'
import { httpPut } from '@/util/http'
import { apiUrl } from '@/domain/constants'
import { GetterNames, MutationNames } from '@/store/types'
import { Getter, Mutation } from 'vuex-class'
@Component
export default class NewAgregateModel extends Vue {
    @Getter(GetterNames.getTelematicsInfo) private getTelematicsInfo!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    private isShown = false
    private folders: any = {
        id: -1,
        origin: [],
        converted: [],
        selected: null,
        selected_name: '',
        onerror: ''
    }

    private onselectFolder(item: any) {
        const folder = this.folders.origin.find((x: any) => x.name === item)
        if (folder) {
            this.folders.selected = folder.id
            this.folders.selected_name = folder.name
        }
    }

    private show(complexId: any, id: any) {
        this.isShown = true
        const folder: any = this.getTelematicsInfo.folders.find((x: any) => x.id === id)
        this.folders = {
            id: complexId,
            origin: this.getTelematicsInfo.folders.map((x: any) => {
                return {
                    id: x.id,
                    name: x.name
                }
            }),
            converted: this.getTelematicsInfo.folders.map((x: any) => x.name),
            selected: id,
            selected_name: folder ? folder.name : '',
            onerror: ''
        }
    }

    private changeFolder() {
        this.$confirm(`Вы уверены что хотите переместить в группу ${this.folders.selected_name}`, 'Подтверждение', 'info').then(() => {
            this.setLoadingDataNames('AgroComplexChangeFolder')
            httpPut({
                url: `${apiUrl}/yields/folder/tractor/${this.folders.id}/`,
                isSecureRequest: true,
                detailResponse: true,
                body: {
                    folder: this.folders.selected
                },
                onSuccess: json => {
                    if (json.ok) {
                        this.$alert('Комплекс успешно перемешен', 'Сохранено', 'success')
                        this.$emit('reload')
                    } else {
                        this.$alert('Ошибка сервера', 'Ошибка', 'error')
                    }
                },
                onError: error => {
                    console.log(error)
                },
                doFinally: () => {
                    this.setLoadingDataNames('AgroComplexChangeFolder')
                }
            })
        })
    }

    private hide() {
        this.isShown = false
    }
}
