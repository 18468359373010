


















































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import {GetterNames, MutationNames } from '@/store/types'
import { httpGet, httpPut } from '@/util/http'
import { apiUrl } from '@/domain/constants'
import Sideboard from '@/components/Sideboard.vue'
import i18n from '@/i18n'
@Component({
  components: {
      Sideboard
  }
})
export default class EditProfileView extends Vue {
    @Getter(GetterNames.getLoadingDataNames) private getLoadingDataNames!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    private userData = {
        email: '',
        first_name: '',
        last_name: '',
        username: ''
    }
    private cachedUserData = {
        email: '',
        first_name: '',
        last_name: '',
        username: ''
    }
    private password = {
        old: '',
        new1: '',
        new2: '',
        showOld: false,
        showNew1: false,
        showNew2: false,
        oldError: '',
        newError1: '',
        newError2: ''
    }

    private mounted() {
        console.log('xaxaxa')
        this.loadUserData()
    }

    @Watch('password.old')
    private onchangeOld() {
        this.password.oldError = ''
    }

    @Watch('password.new1')
    private onchangeNew1() {
        this.password.newError1 = ''
    }

    @Watch('password.new2')
    private onchangeNew2() {
        this.password.newError2 = ''
    }

    private loadUserData() {
        this.setLoadingDataNames('editProfileUserInfo')
        httpGet({
            url: apiUrl + '/user_info/',
            isSecureRequest: true,
            onSuccess: json => {
                const user = json.user
                if (user) {
                    this.userData = JSON.parse(JSON.stringify(user))
                    this.cachedUserData = JSON.parse(JSON.stringify(user))
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('editProfileUserInfo')
            }
        })
    }

    private updateProfileInfo() {
        if (JSON.stringify(this.userData) !== JSON.stringify(this.cachedUserData)) {
            this.saveProfileInfo()
        }
        if (this.password.old.length > 0) {
            if (this.password.new1.length < 6) {
                this.password.newError1 = i18n.t('messages.errors.password_six') as string
            } else if (this.password.new1 !== this.password.new2) {
                this.password.newError2 = i18n.t('messages.errors.passwords_dont_match') as string
            } else {
                this.changePassword()
            }
        }
    }

    private saveProfileInfo() {
        if (this.getLoadingDataNames('editProfileUserUpdate')) return
        this.setLoadingDataNames('editProfileUserUpdate')
        httpPut({
            url: apiUrl + '/user_update/',
            isSecureRequest: true,
            body: this.userData,
            onSuccess: json => {
                this.userData = json
                if (json) {
                    (this.$refs.alertModal as any).show()
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('editProfileUserUpdate')
            }
        })
    }

    private changePassword() {
        if (this.getLoadingDataNames('editProfilePasswordReset')) return
        this.setLoadingDataNames('editProfilePasswordReset')
        httpPut({
            url: apiUrl + '/user_reset_password/',
            isSecureRequest: true,
            body: {
                old_password: this.password.old,
                new_password: this.password.new1
            },
            onSuccess: json => {
                if (json !== 'Success') {
                    this.password.oldError = i18n.t('headers.auth.signin.errors.data') as string
                    return
                }
                if (json === 'Success') {
                    (this.$refs.alertModal as any).show()
                    this.password = {
                        old: '',
                        new1: '',
                        new2: '',
                        showOld: false,
                        showNew1: false,
                        showNew2: false,
                        oldError: '',
                        newError1: '',
                        newError2: ''
                    }
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('editProfilePasswordReset')
            }
        })
    }
}
