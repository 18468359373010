





























import { Component, Vue } from 'vue-property-decorator'
import { Mutation } from 'vuex-class'
import { MutationNames } from '@/store/types'
import { httpGet, httpPost } from '@/util/http'
import { apiUrl } from '@/domain/constants'

@Component
export default class TransferMember extends Vue {
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    private isShown = false
    private memberId = -1
    private folders: any = {
        origin: [],
        converted: [],
        current: null,
        selected: null,
        selected_name: '',
        onerror: ''
    }

    private onselectFolder(item: any) {
        const folder: any = this.folders.origin.find((x: any) => `${x.name} (${x.employee_count})` === item)
        if (folder) {
            this.folders.selected = folder
            this.folders.selected_name = `${folder.name} (${folder.employee_count})`
        }
    }

    private show(selectedId: number, memberId: number) {
        this.isShown = true
        this.memberId = memberId
        this.setLoadingDataNames('transferMemberGetFolders')
        httpGet({
            url: `${apiUrl}/workspace/get/folders/`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                if (json.ok) {
                    this.folders.origin = json.json
                    this.folders.converted = json.json.map((x: any) => `${x.name} (${x.employee_count})`)
                    const current = json.json.find((x: any) => x.id === selectedId)
                    if (current) {
                        this.folders.current = current
                        this.folders.selected = current
                        this.folders.selected_name = `${current.name} (${current.employee_count})`
                    }
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('transferMemberGetFolders')
            }
        })
    }

    private transfer() {
        if (this.checkForErrors()) return
        this.setLoadingDataNames('TransferMemberPostRequest')
        httpPost({
            url: `${apiUrl}/workspace/transfer/employee/in/folder/`,
            isSecureRequest: true,
            detailResponse: true,
            body: {
                role_workspace: this.memberId,
                from_folder: this.folders.current.id,
                to_folder: this.folders.selected.id
            },
            onSuccess: json => {
                if (json.ok) {
                    this.$alert('Сотрудник успешно перемещен', 'Успешно', 'success').then(() => {
                        this.$emit('reload')
                    })
                } else {
                    this.$alert(JSON.stringify(json.json), 'Ошибка', 'error')
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('TransferMemberPostRequest')
            }
        })
    }

    private checkForErrors() {
        let counter = 0
        if (this.folders.selected === null || this.folders.selected_name === '') {
            this.folders.onerror = 'Выберите группу'
            counter++
        }
        return counter > 0
    }

    private hide() {
        this.isShown = false
    }
}
