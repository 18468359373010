


















































































































































































































































import { Component, Vue, Watch, Prop} from 'vue-property-decorator'
import { GetterNames, MutationNames } from '@/store/types'
import { Getter, Mutation } from 'vuex-class'
import olStyle from 'ol/style/Style'
import olIcon from 'ol/style/Icon'
import olStroke from 'ol/style/Stroke'
import olFill from 'ol/style/Fill'
import {telematicsDirectionArrow} from '@/data/pdfImageData'
import * as turf from '@turf/turf'
@Component
export default class TelematicsMap extends Vue {
    @Prop({default: null}) private map!: any

    @Getter(GetterNames.getTelematicsInfo) private getTelematicsInfo!: any
    @Getter(GetterNames.getMapData) private mapData !: any
    @Getter(GetterNames.getFarmMapControllers) private getFarmMapControllers!: any
    @Getter(GetterNames.getFarmFromRoute) private getFarmFromRoute!: any
    @Getter(GetterNames.getTaskData) private getTaskData!: any
    @Getter(GetterNames.getFuelStopTechnic) private getFuelStopTechnic !: any
    @Getter(GetterNames.getIsShowStopTechnic) private getIsShowStopTechnic !: any

    @Mutation(MutationNames.setTaskData) private setTaskData!: any
    @Mutation(MutationNames.setMainMapCenter) private setMainMapCenter!: any
    @Mutation(MutationNames.setMainMapZoom) private setMainMapZoom!: any
    private directionArrow: any = telematicsDirectionArrow
    private showTractorOverlayId = -1
    private selectedStopId = -1
    private selectedFuelStopId = -1
    private arrowPositionings: any = []

    private colors: any = ['#2B4DFF', '#AA27E8', '#F37500', '#06BAF2', '#FFC700', '#5AF5D9', '#7D5EF8', '#FC7BE7', '#ADDE30']

    get getTrackingIndexChange() {
        if (this.getTelematicsInfo.autoTracking.tracking) {
            if (this.getTelematicsInfo.autoTracking.mainTractorID !== -1) {
                const tractor = this.getTelematicsInfo.tractorsData.find((x: any) => x.tractorID === this.getTelematicsInfo.autoTracking.mainTractorID)
                if (tractor) {
                    if (tractor.data.length > 0) {
                        return tractor.currentIndex
                    }
                }
            }
        }
        return -1
    }

    get getFieldFlows() {
        // console.log(this.getTelematicsInfo.tractorsData, 'getTelematicsInfo.tractorsData')
        if (!this.getTelematicsInfo.by_agregate) {
            if (this.getTelematicsInfo.fieldEvent.event) {
                if (this.getTelematicsInfo.fieldEvent.flaws) {
                    if (this.getTelematicsInfo.fieldEvent.reportType === 1) {
                        if (this.getTelematicsInfo.fieldEvent.event.non_covered_geom) {
                            return this.getTelematicsInfo.fieldEvent.event.non_covered_geom
                        }
                    } else if (this.getTelematicsInfo.fieldEvent.reportType === 2) {
                        if (this.getTelematicsInfo.fieldEvent.tractor) {
                            if (this.getTelematicsInfo.fieldEvent.tractor.statistics.geom) {
                                return this.getTelematicsInfo.fieldEvent.tractor.statistics.geom.coordinates
                            }
                        }
                    }
                }
            }
        }
        return false
    }

    get previewData() {
        if (this.getTelematicsInfo.tractorsHistoryPreview) {
            return !this.getTelematicsInfo.tractorsData.find((x: any) => x.IS_VISIBLE)
        }
        return false
    }

    private getTracktorSats(id: any, column: any) {
        const tractor = this.getTelematicsInfo.tractorsData.find((x: any) => x.tractorID === id)
        if (tractor) {
            const data = tractor.data[tractor.currentIndex]
            if (data) {
                const sats = data.sats
                if (column === 1) {
                    return sats > 0 && sats <= 3
                } else if (column === 2) {
                    return sats > 3 && sats <= 6
                } else if (column === 3) {
                    return sats > 6 && sats <= 12
                } else if (column === 4) {
                    return sats > 12
                }
            } else return 0
        } else return 0
    }

    @Watch('getTrackingIndexChange')
    private onchangeCurrentIndex() {
        if (this.getTrackingIndexChange !== -1) {
            const tractor = this.getTelematicsInfo.tractorsData.find((x: any) => x.tractorID === this.getTelematicsInfo.autoTracking.mainTractorID)
            if (tractor) {
                if (tractor.data.length > 0) {
                    this.setMainMapCenter(tractor.data[tractor.currentIndex].point.coordinates)
                }
            }
        }
    }

    @Watch('getTelematicsInfo.autoTracking.tracking')
    private onchangeAutoTracking(val: any, old: any) {
        if (val && val !== old) {
            if (this.getTelematicsInfo.autoTracking.mainTractorID !== -1) {
                const tractor = this.getTelematicsInfo.tractorsData.find((x: any) => x.tractorID === this.getTelematicsInfo.autoTracking.mainTractorID)
                if (tractor) {
                    if (tractor.data.length > 0) {
                        this.setMainMapCenter(tractor.data[tractor.currentIndex].point.coordinates)
                        this.setMainMapZoom(17)
                    }
                }
            }
        }
    }

    private makeStyleFunc() {
        const cache: any = new Map()
        return (feature: any) => {
            const tractor = this.getTelematicsInfo.tractorsData.find((x: any) => x.tractorID === feature.values_.tractorID)
            if (tractor) {
                let style = null
                if (cache.has(`${feature.values_.tractorID}|${feature.values_.arrIndex}`)) {
                    return [cache.get(`${feature.values_.tractorID}|${feature.values_.arrIndex}`)]
                } else {
                    style = new olStyle({
                        image: new olIcon({
                            anchor: [0.5, 0.5],
                            src: this.directionArrow,
                            rotation: feature.values_.rotation,
                            scale: 0.5
                        })
                    })
                    cache.set(`${feature.values_.tractorID}|${feature.values_.arrIndex}`, style)
                    return [style]
                }
            }
        }
    }

    private makeStyleFuncMin() {
        const cache: any = new Map()
        return (feature: any) => {
            const tractor = this.getTelematicsInfo.tractorsData.find((x: any) => x.tractorID === feature.values_.tractorID)
            if (tractor) {
                if (tractor.checkIfVisibleObject(feature.values_.end_time)) {
                    let style = null
                    if (cache.has(`${feature.values_.tractorID}|${feature.values_.index}`)) {
                        return [cache.get(`${feature.values_.tractorID}|${feature.values_.index}`)]
                    } else {
                        style = new olStyle({
                            stroke: new olStroke({
                                color: '#FFFF00',
                                width: 3
                            }),
                            fill: new olFill({
                                color: '#FFFF00'
                            })
                        })
                        cache.set(`${feature.values_.tractorID}|${feature.values_.index}`, style)
                        return [style]
                    }
                }
            }
        }
    }

    private makeStyleFuncMax() {
        const cache: any = new Map()
        return (feature: any) => {
            const tractor = this.getTelematicsInfo.tractorsData.find((x: any) => x.tractorID === feature.values_.tractorID)
            if (tractor) {
                const featureDate = new Date(feature.values_.end_time)
                if (featureDate < this.getTelematicsInfo.tracktime) {
                    let style = null
                    if (cache.has(`${feature.values_.tractorID}|${feature.values_.index}`)) {
                        return [cache.get(`${feature.values_.tractorID}|${feature.values_.index}`)]
                    } else {
                        style = new olStyle({
                            stroke: new olStroke({
                                color: '#FF0000',
                                width: 3
                            }),
                            fill: new olFill({
                                color: '#FF0000'
                            })
                        })
                        cache.set(`${feature.values_.tractorID}|${feature.values_.index}`, style)
                        return [style]
                    }
                }
            }
        }
    }

    private getTractorText(tractor: any) {
        if (this.getFarmMapControllers.telematicsTextType === 1) {
            return tractor.tractorUID
        } else if (this.getFarmMapControllers.telematicsTextType === 2) {
            return tractor.complex.tractor.name
        } else if (this.getFarmMapControllers.telematicsTextType === 3) {
            if (tractor.complex.full_name) {
                return tractor.complex.full_name
            } else {
                return ''
            }
        }
    }

    private getPositioning(id: any) {
        const position = this.arrowPositionings.find((x: any) => x.id === id)
        const currentTractor = this.getTelematicsInfo.tractorsData.find((x: any) => x.tractorID === id)
        if (currentTractor && currentTractor.currentCoordinates.length > 0) {
            const lastpoint = currentTractor.currentCoordinates[currentTractor.currentCoordinates.length - 1]
            for (const tractor of this.getTelematicsInfo.tractorsData) {
                if (tractor.currentCoordinates.length > 0 && tractor.tractorID !== id) {
                    const other = tractor.currentCoordinates[tractor.currentCoordinates.length - 1]
                    const length = Math.sqrt(Math.pow(other[0] - lastpoint[0], 2) + Math.pow(other[1] - lastpoint[1], 2))
                    if (length < 500) {
                        const difY = other[1] - lastpoint[1]
                        const difX = other[0] - lastpoint[0]
                        const angle = difX === 0 || difY === 0 ? 0 : Math.atan2(difX, difY) * 180 / Math.PI
                        if (angle >= -90 && angle <= 90) {
                            return 'center-right'
                        } else {
                            return 'center-left'
                        }
                    }
                }
            }
        }
        return 'center-left'
    }

    private getOffset(id: any) {
        const position = this.getPositioning(id)
        const diff = this.mapData.zoom - 20
        if (position) {
            return position.includes('left') ? [30 + diff * 2.8, 0] : [-30 - diff * 2.8, 0]
        } else {
            return [30 + diff * 2.8, 0]
        }
    }

    private transferCoordinates(coordinates: any) {
        const lon = coordinates[0] * 180 / 20037508.34
        const lat = Math.atan(Math.exp(coordinates[1] * Math.PI / 20037508.34)) * 360 / Math.PI - 90
        return lon + ', ' + lat
    }

    private normalizeDate(date: any) {
        const d = new Date(date)
        return d.toLocaleString('ru', { hour12: false, day: 'numeric', month: 'numeric', year: 'numeric',  hour: "numeric", minute: "numeric", second: 'numeric'})
    }

    private calculatePixelsInMeters(coverage: any) {
        if (this.map) {
            let c = 1.7
            if (this.getFarmFromRoute) {
                const point: any = turf.toWgs84(turf.point(this.getFarmFromRoute.center))
                c = Math.cos(point.geometry.coordinates[1] * Math.PI / 180)
            }
            return coverage / (c * this.map.getView().getResolution())
        }
        const Spixel = 0.6 * ( 2 * Math.PI * 6378137) / Math.pow( 2, this.mapData.zoom + 8 )
        return coverage / Spixel
    }

    private getImageScale() {
        const scale = (this.mapData.zoom - 10) * 3 + 16
        return scale
    }

    private getStopScale() {
        const scale = ((this.mapData.zoom - 10) * 3 + 16) * 1.2
        return scale
    }

    private toggleStopId(id: any) {
        this.selectedStopId = this.selectedStopId === id ? -1 : id
    }

    private closeStops(type: any) {
        this.selectedStopId = -1
        if (type === 'main') {
          this.setTaskData({currentStopPoint: null})
        }
    }

    private checkTractorStop(tractorId: any, date: any) {
        const time = new Date(date)
        // const tractor = this.getTelematicsInfo.tractorsData.find((x: any) => x.tractorID === tractorId)
        // if (tractor.data.length === 0) return false
        // const currentDate = new Date(tractor.data[tractor.currentIndex].date_time)
        // console.log(time , currentDate)
        const tracktime = this.getTelematicsInfo.tracktime
        return time.getTime() < tracktime
    }

    private normalizeDuration(duration: any) {
        if (duration > 60) {
            const hours = Math.floor(duration / 60)
            const minutes = Math.round(duration - 60 * hours)
            return `${hours} часов ${minutes} минуты`
        } else {
            return `${Math.round(duration) } минуты`
        }
    }

    // fuel stop
    private toggleFuelStopId(id: any) {
        this.selectedFuelStopId = this.selectedFuelStopId === id ? -1 : id
    }

    private closeFuels(type: any) {
        this.selectedFuelStopId = -1
    }

    private getTractorImageByCategoryId(category: string) {
        switch (category) {
            case 'Тракторы': return 'tractor'
            case 'Самоходные опрыскиватели': return 'tractor'
            case 'Погрузчик': return 'loader'
            case 'Комбайны': return 'combine'
            case 'Транспортное средство': return 'vehicle'
            case 'Заправщик': return 'tanker'
            case 'Самосвал': return 'dumper'
            case 'Грузовик': return 'truck'
            case 'Самоходная косилка': return 'harvester'
        }
    }
}
