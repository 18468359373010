


































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Getter, Action, Mutation } from 'vuex-class'
import { GetterNames, ActionNames, MutationNames } from '@/store/types'
import { apiUrl } from '@/domain/constants'
import { httpGet, httpPost } from '@/util/http'
import Farm from '@/models/Farm'
import i18n from '@/i18n'

@Component
export default class FarmOrders extends Vue {
    @Action(ActionNames.fetchFarmsList) private fetchFarmsList !: () => void
    @Action(ActionNames.fetchAllFarms) private fetchAllFarms!: any
    @Action(ActionNames.updateMapDataInRegion) private updateMapDataInRegion!: any

    @Getter(GetterNames.getFarms) private getFarms!: any
    @Getter(GetterNames.getNewFarmRequest) private getNewFarmRequest!: any
    @Getter(GetterNames.getFarmFromRoute) private getFarmFromRoute!: Farm
    @Getter(GetterNames.getCurrentCadastreId) private getCurrentCadastreId!: any
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: any

    @Mutation(MutationNames.setNewFarmRequest) private setNewFarmRequest!: any
    @Mutation(MutationNames.setIsDataLoading) private setIsDataLoading!: any
    @Mutation(MutationNames.setCurrentCadastreIndex) private setCurrentCadastreIndex!: any
    private paymentType = 'card'
    private payboxUrl = ''
    private readonly FARM_DATA_REQUESTS_URL = `${apiUrl}/pay/group/users/`
    private orderType = 'lastDays'
    private isRequestSending = false
    private requestSendSuccess = false
    private orderPrice: any = {
        price: 0,
        discount: 0,
        is_discount_active: false,
        loading: false
    }

    public close() {
        this.$router.push(`/cadastres/`)
    }

    get cadastreId() {
        return parseInt(this.$route.params.cadastreId, 10)
    }

    get totalSum() {
        let sum = 0
        const farmrequest = this.getNewFarmRequest
        farmrequest.selectedFarms.forEach((selectedFarm: any) => {
            sum += this.calculatePrice(farmrequest.selectedFarms.indexOf(selectedFarm))
        })
        return sum
    }

    get getPrice() {
        let str = ' '
        if (this.orderPrice.is_discount_active) {
            str += (this.orderPrice.price - this.orderPrice.price * this.orderPrice.discount / 100) + ' ₸ ) ' + '-' + this.orderPrice.discount + '%'
        } else {
             str += this.orderPrice.price + ' ₸ '
        }
        return str
    }

    public sendOrderRequest() {
        if (this.generateRequestody() === []) return
        if (this.isRequestSending) return
        this.isRequestSending = true
        this.setIsDataLoading(true)
        httpPost({
            url: this.FARM_DATA_REQUESTS_URL,
            isSecureRequest: true,
            body: this.generateRequestody(),
            onSuccess: json => {
                this.requestSendSuccess = true
                if (json.length > 0) {
                    if (this.paymentType === 'card') {
                        this.openPaybox(json[0].payment_url)
                    } else {
                        const arr: any = []
                        json.forEach((bill: any) => {
                            arr.push(bill.id)
                        })
                        this.goToBillView(json)
                    }
                }
            },
            doFinally: () => {
                this.isRequestSending = false
                this.setIsDataLoading(false)
            }
        })
    }

    private getAllFieldsId(farm: any) {
        const arr: any = []
        farm.fields.forEach((field: any) => {
            arr.push(field.id)
        })
        return arr
    }

    @Watch('currentRoute.params.status')
    private onloadAllFarms(val: any, old: any) {
        if (val === 'loaded' && val !== old) {
            if (this.getNewFarmRequest.isNewRequest) {
                const farmrequest = this.getNewFarmRequest
                farmrequest.selectedFarms = []
                farmrequest.selectedFarms.push({
                    farm: this.getFarms.find((x: any) => x.id === this.getNewFarmRequest.farmId),
                    fields: this.getAllFieldsId(this.getFarms.find((x: any) => x.id === this.getNewFarmRequest.farmId)),
                    notFarm: false,
                    mapSelection: false,
                    dropdownCollapsed: false,
                    fieldDropdown: false
                })
                this.setNewFarmRequest({isNewRequest: false, cadastreId: '', selectedFarms: farmrequest.selectedFarms})
                const farm = this.getFarms.find((x: any) => x.id === this.getNewFarmRequest.farmId)
                if (farm) {
                    this.setCurrentCadastreIndex(farm.id)
                    this.updateMapDataInRegion({
                        animateBbox: farm.boundaries
                    })
                }
            } else {
                if (this.getFarms.length === 0) {
                    return
                }
                const farmrequest = this.getNewFarmRequest
                farmrequest.selectedFarms = []
                farmrequest.selectedFarms.push({
                    farm: this.getFarms[0], fields: this.getAllFieldsId(this.getFarms[0]),
                    notFarm: false, mapSelection: false, dropdownCollapsed: false, fieldDropdown: false
                })
                this.setNewFarmRequest({selectedFarms: farmrequest.selectedFarms})
                this.setCurrentCadastreIndex(this.getFarms[0].id)
                this.updateMapDataInRegion({
                    animateBbox: this.getFarms[0].boundaries
                })
            }
        }
    }

    @Watch('getFarms')
    private onloadFarmsList(val: any, old: any) {
        if (val && val !== old) {
            this.fetchAllFarms()
        }
    }

    private mounted() {
        this.$router.push({
            params: {
                status: 'loading'
            }
        }).catch(e => {/* */})
        this.fetchFarmsList()
        this.fetchAllFarms()
        this.orderPrice.loading = true
        httpGet({
            url:  `${apiUrl}/get/price/`,
            isSecureRequest: true,
            onSuccess: json => {
                this.orderPrice.price = json.price
                this.orderPrice.discount = json.discount
                this.orderPrice.is_discount_active = json.is_discount_active
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.orderPrice.loading = false
            }
        })
    }

    private addNewFarm() {
        const farmrequest = this.getNewFarmRequest
        farmrequest.selectedFarms.push({farm: this.getFarms[0], fields: this.getAllFieldsId(this.getFarms[0]),
        notFarm: false, mapSelection: false, dropdownCollapsed: false, fieldDropdown: false})
        this.setNewFarmRequest({selectedFarms: farmrequest.selectedFarms})
    }

    private changeSelectedFarmAtIndex(index: number, farm: Farm) {
        const farmrequest = this.getNewFarmRequest
        if (farmrequest.selectedFarms[index].farm.id === this.getCurrentCadastreId) {
            this.setCurrentCadastreIndex(farm.id)
            this.updateMapDataInRegion({
                animateBbox: farm.boundaries
            })
        }
        farmrequest.selectedFarms[index].farm = farm
        farmrequest.selectedFarms[index].notFarm = false
        farmrequest.selectedFarms[index].fields = this.getAllFieldsId(farm)
        farmrequest.selectedFarms[index].mapSelection = false
        farmrequest.selectedFarms[index].dropdownCollapsed = false
        farmrequest.selectedFarms[index].dropdownCollapsed = false
        farmrequest.selectedFarms[index].fieldDropdown = false
        this.setNewFarmRequest({selectedFarms: farmrequest.selectedFarms})
    }

    private getFieldsNumberString(index: number) {
        const farmrequest = this.getNewFarmRequest
        if (farmrequest.selectedFarms[index].fields.length === 0) {
            return i18n.t('messages.not_chosen')
        } else if (farmrequest.selectedFarms[index].farm.fields.length === farmrequest.selectedFarms[index].fields.length) {
            return i18n.t('messages.selections.all_fields')
        } else {
            let str = ''
            for (const field of farmrequest.selectedFarms[index].farm.fields) {
                if (farmrequest.selectedFarms[index].fields.includes(field.id)) {
                    str += '№' + (field.name ? field.name : field.number) + ', '
                }
            }
            return str
        }
    }

    private changeSelectedField(farmIndex: number, fieldId: number) {
        const farmrequest = this.getNewFarmRequest
        if (fieldId === -1) {
            if (farmrequest.selectedFarms[farmIndex].farm.fields.length === farmrequest.selectedFarms[farmIndex].fields.length) {
                farmrequest.selectedFarms[farmIndex].fields = []
            } else {
                for (const field of farmrequest.selectedFarms[farmIndex].farm.fields) {
                    if (!farmrequest.selectedFarms[farmIndex].fields.includes(field.id)) {
                        farmrequest.selectedFarms[farmIndex].fields.push(field.id)
                    }
                }
            }
        } else {
            if (farmrequest.selectedFarms[farmIndex].fields.includes(fieldId)) {
                farmrequest.selectedFarms[farmIndex].
                fields.splice(farmrequest.selectedFarms[farmIndex].fields.indexOf(fieldId), 1)
            } else {
                farmrequest.selectedFarms[farmIndex].fields.push(fieldId)
            }
        }
        this.setNewFarmRequest({selectedFarms: farmrequest.selectedFarms})
    }

    private getTotalArea(index: number) {
        const farmrequest = this.getNewFarmRequest
        let area = 0
        for (const field of farmrequest.selectedFarms[index].farm.fields) {
            if (farmrequest.selectedFarms[index].fields.includes(field.id)) {
                area += field.area
            }
        }
        return (area / 10000)
    }

    private deleteFarm(index: number) {
        const farmrequest = this.getNewFarmRequest
        if (farmrequest.selectedFarms.length > 1) {
            const deleteFarm = farmrequest.selectedFarms[index].farm
            farmrequest.selectedFarms.splice(index, 1)
            if (deleteFarm.id === this.getCurrentCadastreId) {
                this.setCurrentCadastreIndex(farmrequest.selectedFarms[0].farm.id)
                this.updateMapDataInRegion({
                    animateBbox: farmrequest.selectedFarms[0].farm.boundaries
                })
            }
        }
        this.setNewFarmRequest({selectedFarms: farmrequest.selectedFarms})
    }

    private goToBillView(requestArr: any) {
        this.$router.push(
            {
                name: 'farmbill',
                params: {
                request: requestArr
                }
            }
        )
    }

    private openPaybox(payboxUrl: string) {
        window.location.href = payboxUrl
    }

    private getFormattedDateForCadastreDataRequest() {
        const date = new Date()
        return `${date.getUTCFullYear()}-${date.getUTCMonth() +
        1}-${date.getUTCDate()}`
    }

    private calculatePrice(index: number) {
        return Math.round(this.getTotalArea(index) * this.orderPrice.price)
    }

    private toggleMapSelection(index: number) {
        const farmrequest = this.getNewFarmRequest
        farmrequest.selectedFarms.forEach((element: any, i: any) => {
            if (i !== index) farmrequest.selectedFarms[i].mapSelection = false
        })
        farmrequest.selectedFarms[index].mapSelection = !farmrequest.selectedFarms[index].mapSelection
        if (farmrequest.selectedFarms[index].mapSelection) {
            this.setCurrentCadastreIndex(farmrequest.selectedFarms[index].farm.id)
            this.updateMapDataInRegion({
                animateBbox: farmrequest.selectedFarms[index].farm.boundaries
            })
        }
        this.setNewFarmRequest({selectedFarms: farmrequest.selectedFarms})
    }

    private toggleDropdownAtIndex(index: number) {
        const farmrequest = this.getNewFarmRequest
        if (farmrequest.selectedFarms[index]) {
            farmrequest.selectedFarms[index].dropdownCollapsed = !farmrequest.selectedFarms[index].dropdownCollapsed
        }
        this.setNewFarmRequest({selectedFarms: farmrequest.selectedFarms})
    }

    private toggleFieldDropdown(index: number) {
        const farmrequest = this.getNewFarmRequest
        if (farmrequest.selectedFarms[index].notFarm) return
        farmrequest.selectedFarms[index].fieldDropdown = !farmrequest.selectedFarms[index].fieldDropdown
        this.setNewFarmRequest({selectedFarms: farmrequest.selectedFarms})
    }

    private closeDropdownAtIndex(index: number) {
        const farmrequest = this.getNewFarmRequest
        if (index < farmrequest.selectedFarms.length) {
            farmrequest.selectedFarms[index].dropdownCollapsed = false
            this.setNewFarmRequest({selectedFarms: farmrequest.selectedFarms})
        }
    }

    private closeFieldDropdown(index: number) {
        const farmrequest = this.getNewFarmRequest
        if (index < farmrequest.selectedFarms.length) {
            farmrequest.selectedFarms[index].fieldDropdown = false
            this.setNewFarmRequest({selectedFarms: farmrequest.selectedFarms})
        }
    }

    private generateRequestody() {
        const body: any[] = []
        const farmrequest = this.getNewFarmRequest
        farmrequest.selectedFarms.forEach((selectedFarm: any) => {
            if (selectedFarm.notFarm !== true && selectedFarm.fields.length !== 0) {
                body.push({
                    group_user: selectedFarm.farm.id,
                    divided_cadastre_users: selectedFarm.fields
                })
            }
        })
        return body
    }
}
