import NewTractor from "@/views/MainPage/HomeView/Sideboards/FarmBoard/TelematicsBoard/FarmTelematics/components/NewTractor"
export default class Folder {
    public name: string
    public collapsed: boolean
    public tractors: NewTractor[]
    public checked: boolean
    public quantity: number

    constructor(name: string, tractors: NewTractor[], quantity: number) {
        this.name = name
        this.checked = false
        this.collapsed = true
        this.tractors = tractors
        this.quantity = quantity
    }
}
interface IFolder {
    name: string,
    tractors: ITractor[]
}
interface ITractor {
    tractor: {
        name: string,
        uid: string
    }
}