






















































import { Vue, Component, Watch } from 'vue-property-decorator'
import DatePicker from '@/components/DatePicker.vue'
import {httpGet, httpPost} from '@/util/http'
import {apiUrl} from '@/domain/constants'
import { MutationNames } from '@/store/types'
import { Mutation } from 'vuex-class'
@Component({
    components: {
        DatePicker
    }
})
export default class EventsStatisticsModal extends Vue {
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    private isShown = false
    private traktorName = ''
    private events: any = {
        origin: [],
        converted: [],
        selectedName: ''
    }
    private statisticsBody: any = {
        start_date: '',
        end_date: '',
        imei: '',
        group_user: -1,
        task_name: null
    }
    private dataEvents: any = null

    private show(data: any) {
        this.traktorName = data.class_name
        this.statisticsBody.imei = data.imei
        this.statisticsBody.group_user = this.$route.params.farmId
        this.isShown = true
    }

    private hide() {
        this.events.selectedName = ''
        this.statisticsBody.start_date = ''
        this.statisticsBody.end_date = ''
        this.dataEvents = null
        this.isShown = false
    }

    private onSelectEvent(item: any) {
        const event: any = this.events.origin.find((x: any) => x.name === item)
        if (event) {
            this.statisticsBody.task_name = event.name
        }
    }

    private checkError() {
        let counter = 0
        if (this.statisticsBody.task_name === '') {
            Vue.alert('Укажите Название и Сроки мероприятие', 'Ошибка', 'warning')
            counter++
        }
        if (this.statisticsBody.start_date === '') {
            Vue.alert('Укажите Название и Сроки мероприятие', 'Ошибка', 'warning')
            counter++
        }
        if (this.statisticsBody.end_date === '') {
            Vue.alert('Укажите Название и Сроки мероприятие', 'Ошибка', 'warning')
            counter++
        }
        return counter === 0
    }

    private mounted() {
        this.fetchEvents()
    }

    private fetchEvents() {
        httpGet({
            url:  `${apiUrl}/techmap/static/`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                if (json.ok) {
                    this.events.origin = json.json
                    this.events.converted = json.json.map((x: any) => x.name)
                }
            },
            onError: error => {
                console.log(error)
            }
        })
    }

    private fetchEventsStatistics() {
        const date1: any = this.$refs.startDateModel
        const date2: any = this.$refs.endDateModel
        if (date1) {
            this.statisticsBody.start_date = date1.getDate()
        }
        if (date2) {
            this.statisticsBody.end_date = date2.getDate()
        }
        if (this.checkError() === false) return
        this.setLoadingDataNames('newFolderModelAddFolder')
        httpPost({
            url:  `${apiUrl}/telematics/get/shift/reports/by/tractor/`,
            isSecureRequest: true,
            detailResponse: true,
            body: this.statisticsBody,
            onSuccess: json => {
                if (json.ok) {
                    this.dataEvents = json.json
                    console.log(this.dataEvents)
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('newFolderModelAddFolder')
            }
        })
    }
}
