










































































































































































































import {Component, Vue, Watch} from 'vue-property-decorator'
import {httpDelete, httpGet} from '@/util/http'
import {apiUrl, baseUrl} from '@/domain/constants'
import {GetterNames, MutationNames} from '@/store/types'
import {Getter, Mutation} from 'vuex-class'
import AgroComplex from './AgroComplex.vue'
import NewFolderModel from './NewFolderModel.vue'
import TelematicsSocket from '../../TelematicsSocket.vue'
import TractorTelematics from '@/models/TractorTelematics'
import DateRangePicker from '@/components/DateRangePicker.vue'
import _ from 'lodash'

@Component({
    components: {
        AgroComplex,
        NewFolderModel,
        TelematicsSocket,
        DateRangePicker
    }
})
export default class AgregatesView extends Vue {
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: any
    @Getter(GetterNames.getTelematicsInfo) private getTelematicsInfo!: any
    @Getter(GetterNames.getLoadingDataNames) private getLoadingDataNames!: any

    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    @Mutation(MutationNames.setTelematicsInfo) private setTelematicsInfo!: any
    @Mutation(MutationNames.setSideboardIsShown) private setSideboardIsShown!: any
    @Mutation(MutationNames.setIsDataLoading) private setIsDataLoading !: any
    private searchText = ''
    private autoClick = true
    private filters: any = []
    private agregatesViewType = 0
    private openedFolders: any = []
    private autoBroadcast: any = []
    private onlineTractors: any = []
    private mainFilters: any = {
        all: true,
        online: false,
        aim: false
    }
    private startDate: any = null
    private endDate: any = null

    get getLiveBroadCast() {
        const tractors = this.getTelematicsInfo.tractorsData.map((x: any) => {
            return {
                imei: x.tractorIMEI,
                is_online: x.IS_ONLINE
            }
        })
        return tractors
    }

    get getHistoryBroadCast() {
        const tractors = this.getTelematicsInfo.tractorsData.map((x: any) => {
            return {
                imei: x.tractorIMEI,
                is_history: x.IS_HISTORY
            }
        })
        return tractors
    }

    private changeFilter(event: any, type: any) {
        console.log(event, type)
    }

    // @Watch('getHistoryBroadCast', {immediate: true, deep: true})
    private onchangeHistoryBroadCast(val: any, old: any) {
        if (val && old) {
            if (val.find((x: any) => x.is_history)) {
                this.autoBroadcast = []
            }
        }
    }

    @Watch('searchText')
    private onchangeSearchText(val: any, old: any) {
        if (val && val !== old) {
            if (this.openedFolders.length !== this.getTelematicsInfo.folders.length) {
                for (const folder of this.getTelematicsInfo.folders) {
                    if (!this.openedFolders.includes(folder.id)) {
                        this.openedFolders.push(folder.id)
                    }
                }
            }
        }
    }

    @Watch('getLiveBroadCast', { immediate: true, deep: true })
    private onchangeLiveBroadCasting(val: any, old: any) {
        if (val && old) {
            const imeis = val.filter((x: any) => old.some((y: any) => y.imei === x.imei && x.is_online && !y.is_online))
                .map((x: any) => x.imei).forEach((x: any) => {
                const tractor = this.getTelematicsInfo.tractorsData.find((y: any) => y.tractorIMEI === x)
                if (tractor && !tractor.IS_LIVE) {
                    if (this.autoBroadcast.includes(tractor.folderID)) {
                        this.getTelematicsInfo.tractorsData[tractor.index].clearData()
                        this.getTelematicsInfo.tractorsData[tractor.index].IS_LIVE = true
                    }
                }
            })
        }
    }

    private sortedFolderTractors(folderID: any, filter?: string) {
        let tractors = this.getTelematicsInfo.tractorsData.filter((x: any) => x.folderID === folderID)
        tractors.sort((a: any, b: any) => (a.IS_ONLINE < b.IS_ONLINE) ? 1 : -1)
        tractors = this.additionalFilters(tractors, filter)
        if (this.searchText !== '') {
            const result = tractors.filter((x: any) =>
                x.complex.tractor.name.toLowerCase().includes(this.searchText.toLowerCase())
                || x.tractorUID.toLowerCase().includes(this.searchText.toLowerCase())
            )
            return result
        }
        return tractors
    }

    private activeFilter(filter: string) {
        if (filter === 'all' && this.filters.length === 0) {
            return true
        } else if (this.filters.includes(filter)) {
            return true
        }
        return false
    }

    private additionalFilters(tractors: any, filter?: string) {
        if (filter && filter !== 'all') {
            let oppositeFilter = ''
            switch (filter) {
                case 'online': oppositeFilter = 'offline'; break
                case 'offline': oppositeFilter = 'online'; break
                case 'geozone': oppositeFilter = 'outside'; break
                case 'outside': oppositeFilter = 'geozone'; break
            }
            if (this.filters.includes(filter)) {
                this.filters = this.filters.filter((i: string) => i !== filter)
            } else if (this.filters.includes(oppositeFilter)) {
                this.filters = this.filters.filter((i: string) => i !== oppositeFilter)
                this.filters.push(filter)
            } else {
                this.filters.push(filter)
            }
        } else if (filter === 'all') {
            this.filters = []
        }
        this.filters.forEach((item: string) => {
            if (item === 'online') {
                tractors = tractors.filter((x: any) => (x.IS_ONLINE === true))

            } else if (item === 'offline') {
                tractors = tractors.filter((x: any) => (x.IS_ONLINE === false))
            }
            if (item === 'geozone') {
                tractors = tractors.filter((x: any) => (x.hasTrailer === true))
                // tractors = tractors.filter((x: any) => (x.complex.applied_geozone !== null))

            } else if (item === 'outside') {
                tractors = tractors.filter((x: any) => (x.hasTrailer === false))
                // tractors = tractors.filter((x: any) => (x.complex.applied_geozone === null))
            }
        })
        return tractors
    }

    private getTableBody(filter?: string) {
        if (filter === 'all') {
            this.agregatesViewType = 1
        } else {
            this.agregatesViewType = 2
        }
        let tractors: any = JSON.parse(JSON.stringify(this.getTelematicsInfo.tractorsData))
        tractors.sort((a: any, b: any) => (a.IS_ONLINE < b.IS_ONLINE) ? 1 : -1)
        tractors = this.additionalFilters(tractors, filter)
        if (this.searchText !== '') {
            const result = tractors.filter((x: any) =>
                x.complex.tractor.name.toLowerCase().includes(this.searchText.toLowerCase())
                || x.tractorUID.toLowerCase().includes(this.searchText.toLowerCase())
            )
            return result
        }
        return tractors
    }

    private changeAgregateViewType(type: any) {
        this.agregatesViewType = type
    }

    @Watch('getLiveBroadCast', { immediate: true, deep: true })
    private getData() {
        if (this.getTelematicsInfo.socket && this.getTelematicsInfo.socket.readyState === WebSocket.OPEN) {
            const onlineAmount = this.getTelematicsInfo.tractorsData.filter((tractor: any) => tractor.IS_ONLINE === true).length
            if (onlineAmount < 10) {
                this.changeAutoBroadCastList(this.autoClick)
            }
            this.autoClick = false
        }
    }


    private changeAutoBroadCastList(clientClick: boolean) {
        if (this.getTelematicsInfo.folders.length === this.autoBroadcast.length && clientClick) {
            for (const folder of this.getTelematicsInfo.folders) {
                this.changeAutoBroadCastFolder(folder.id)
            }
        } else if (clientClick) {
            for (const folder of this.getTelematicsInfo.folders) {
                if (!this.autoBroadcast.includes(folder.id)) {
                    this.changeAutoBroadCastFolder(folder.id)
                }
            }
        }

        if (this.onlineTractors.length === 0 && !clientClick) {
            this.autoBroadcast = []
        }

    }

    private changeAutoBroadCastFolder(id: any) {
        this.getTelematicsInfo.tractorsData.forEach((x: any) => {
            if (x.IS_HISTORY) {
                this.getTelematicsInfo.tractorsData[x.index].clearData()
                this.getTelematicsInfo.tractorsData[x.index].IS_HISTORY = false
            }
        })
        const tractors = this.getTelematicsInfo.tractorsData.filter((x: any) => x.folderID === id).forEach((x: any) => {
          if (x.IS_ONLINE) {
            this.onlineTractors.push(x)
          }
          if (this.autoBroadcast.includes(id)) {
                if (x.IS_LIVE) {
                    this.getTelematicsInfo.tractorsData[x.index].clearData()
                    this.getTelematicsInfo.tractorsData[x.index].IS_LIVE = false
                }
            } else if (x.IS_ONLINE && !x.IS_LIVE) {
                this.getTelematicsInfo.tractorsData[x.index].clearData()
                this.getTelematicsInfo.tractorsData[x.index].IS_LIVE = true
            }
        })
        const index = this.autoBroadcast.findIndex((x: any) => x === id)
        if (index === -1) {
            this.autoBroadcast.push(id)
        } else {
            this.autoBroadcast.splice(index, 1)
        }
    }

    private newFolder(folder: any) {
        this.getTelematicsInfo.folders.push(folder)
    }

    private updateFolder(folder: any) {
        const index = this.getTelematicsInfo.folders.findIndex((x: any) => x.id === folder.id)
        if (index !== -1) {
            this.getTelematicsInfo.folders[index].name = folder.name
        }
    }

    private toggleFolder(id: any) {
        const index = this.openedFolders.findIndex((x: any) => x === id)
        if (index === -1) {
            this.openedFolders.push(id)
        } else {
            this.openedFolders.splice(index, 1)
        }
    }

    private mounted() {
        this.agregatesViewType = 1
        const today = new Date()
        this.startDate = today // this.getTelematicsInfo.historyStart ||
        this.endDate = today // this.getTelematicsInfo.historyEnd ||
        this.fetchFolders()
    }

    private resetDate(args: any) {
        console.log('here', args)
        // this.getTelematicsInfo.historyStart = new Date()
        // this.getTelematicsInfo.historyEnd = new Date()
    }

    private clearTelematics() {
        this.setTelematicsInfo({
            geozone: {
                data: null,
                view: false,
                edit: 0,
                type: 'edit'
            },
            folders: [],
            tractorsData: [],
            autoplay: false,
            tracktime: 0,
            autoTracking: {
                mainTractorID: -1,
                tracking: false
            },
            arrowsView: false,
            speedLimitsView: false,
            stopsView: false,
            fieldEvent: {
                reportType: 1,
                event: null,
                tractor: null,
                flaws: false,
                overlaps: false
            },
            controlPanelExtension: false
        })
        this.setIsDataLoading(false)
    }

    private fetchFolders() {
        let startDate: any = ''
        let endDate: any = ''
        if (this.startDate && this.endDate) {
            const startDateString = this.startDate.toLocaleDateString('es-CL')
            const endDateString = this.endDate.toLocaleDateString('es-CL')

            if (startDateString !== endDateString) {
                startDate = startDateString
                endDate = endDateString
            }

            this.setTelematicsInfo({historyStart: this.startDate, historyEnd: this.endDate})
        }
        this.clearTelematics()
        this.setLoadingDataNames('AgregatesViewGetData')
        httpGet({
            url: `${apiUrl}/yields/folder/period/?group_user=${this.currentRoute.params.farmId}&start=${startDate}&end=${endDate}`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                if (json.ok) {
                    this.openedFolders = []
                    this.autoBroadcast = []
                    this.setTelematicsInfo({
                        farmId: Number(this.currentRoute.params.farmId),
                        geozone: {
                            data: json.json.geozone
                        },
                        folders: json.json.folders
                    })
                    const tractors: any = []
                    this.getTelematicsInfo.folders.forEach((x: any) => {
                        tractors.push(...x.tractors)
                    })
                    const tractorsTelematics: TractorTelematics[] = []
                    tractors.forEach((x: any, i: number) => {
                        tractorsTelematics.push(new TractorTelematics({
                            index: i,
                            folderID: x.folder,
                            complex: x,
                            tractorID: x.tractor.id,
                            tractorIMEI: x.tractor.imei,
                            tractorUID: x.tractor.uid,
                            FUEL_DATA: x.tractor.fuel_data ? x.tractor.fuel_data : [],
                            SOCKET_STATUS: 0,
                            MAX_CAPACITY: x.tractor.capacity,
                            MIN_SPEED: x.min_speed ? x.min_speed : 0,
                            MAX_SPEED: x.max_speed ? x.max_speed : 999,
                            coverage: x.trailer ? x.trailer.coverage : (x.tractor.coverage ? x.tractor.coverage : 0),
                            tractorImage: x.tractor.image ? `${baseUrl}${x.tractor.image}` : '',
                            hasTrailer: x.tractor.has_trailer,
                            taskEvent: x.task_static
                        }))
                    })
                    this.setTelematicsInfo({tractorsData: tractorsTelematics})

                    const TelematicsSocketModel = this.$refs.telematicsSocketModel as any
                    if (TelematicsSocketModel) {
                        TelematicsSocketModel.createSocket(1)
                    }
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('AgregatesViewGetData')
                // if (args !== undefined) {
                //     const index = this.getTelematicsInfo.tractorsData.findIndex((x:any) => x.tractorID === args.index)
                //     this.getTelematicsInfo.tractorsData[index].taskEvent = args.event.selected
                //     console.log(this.getTelematicsInfo.tractorsData[index], index, 'index')
                // }
            }
        })
    }

    private deleteFolder(folder: any) {
        this.$confirm(`Вы действительно хотите удалить группу "${folder.name}"?`, 'Подтверждение', 'info').then(() => {
            this.setLoadingDataNames('AgregatesViewDeleteFolder')
            httpDelete({
                url: `${apiUrl}/yields/folder/${folder.id}/`,
                isSecureRequest: true,
                detailResponse: true,
                onSuccess: json => {
                    if (json.ok) {
                        this.$alert('Группа успешна удалена', 'Удалено', 'success')
                        this.fetchFolders()
                    } else {
                        this.$alert('Ошибка сервера', 'Ошибка', 'error')
                    }
                },
                onError: error => {
                    console.log(error)
                },
                doFinally: () => {
                    this.setLoadingDataNames('AgregatesViewDeleteFolder')
                }
            })
        }).catch(e => {/* */})
    }

    private setEditStage() {
        if (this.getTelematicsInfo.geozone.edit === 0) {
            this.setSideboardIsShown(false)
            this.setTelematicsInfo({geozone: {edit: 1, type: 'edit'}})
        } else {
            this.setTelematicsInfo({geozone: {edit: 3}})
        }
    }

    private setNewDateRange(startDate: any, endDate: any) {
        this.startDate = new Date(startDate)
        this.endDate =  new Date(endDate)

        this.fetchFolders()

        if (this.getTelematicsInfo.autoTracking.mainTractorID !== -1) {
            const tractor = this.getTelematicsInfo.tractorsData.find((x: any) =>
                x.tractorID === this.getTelematicsInfo.autoTracking.mainTractorID)
            if (tractor) {
                if (tractor.IS_HISTORY) {
                    this.getTelematicsInfo.tractorsData[tractor.index].clearData()
                }
                this.getTelematicsInfo.tracktime = Date.parse(startDate)
                this.getTelematicsInfo.tractorsData[tractor.index].historyStartDate = startDate
                this.getTelematicsInfo.tractorsData[tractor.index].historyEndDate = endDate
                this.getTelematicsInfo.tractorsData[tractor.index].IS_HISTORY = true
            }
        }
    }

    private isLastDate() {
        if (this.startDate && this.endDate) {
            const today = new Date()
            const startOfYear = new Date(this.endDate.getFullYear(), 0, 0)

            if (this.endDate.toLocaleDateString() === today.toLocaleDateString()) {

                const startDaysCount = Math.floor((this.startDate.getTime() - startOfYear.getTime()) / 1000 / 60 / 60 / 24)
                const endDaysCount = Math.floor((this.endDate.getTime() - startOfYear.getTime()) / 1000 / 60 / 60 / 24)

                return endDaysCount - startDaysCount + 1
            }
        }
    }

    private selectDateRangeFromToday(day: number) {
        const today = new Date()
        let startDate = new Date()
        startDate = new Date(startDate.setDate(today.getDate() - day))

        this.setNewDateRange(startDate, today)
    }
}
