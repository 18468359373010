


























import { Component, Vue } from 'vue-property-decorator'
import i18n from '@/i18n'
@Component({
  components: {
  }
})
export default class HandBookView extends Vue {
    private searchText = ''
    private category = [
        {
            url: 'diseases',
            img: 'https://cabinet.egistic.kz/media/front/diseases.png',
            text: i18n.t('headers.workspace.fields.notes.note_addition.problem.type1')
        },
        {
            url: 'pests',
            img: 'https://cabinet.egistic.kz/media/front/pests.png',
            text: i18n.t('headers.workspace.fields.notes.note_addition.problem.type2')
        },
        {
            url: 'weedplants',
            img: 'https://cabinet.egistic.kz/media/front/weedplants.png',
            text: i18n.t('headers.workspace.fields.notes.note_addition.problem.type3')
        }
    ]
    get getCategory() {
        return this.category.filter((x: any) => x.text.toUpperCase().includes(this.searchText.toUpperCase()))
    }
    private openCategory(item: any) {
        this.$router.push(`/handbook/${item.url}`)
    }
    private close() {
        this.$router.push(`/cadastres`).catch(e => {/**/})
    }
}
