










































































































































import { Component, Vue, Watch, Emit } from 'vue-property-decorator'
import { httpGet, httpPostFormData, httpPutFormData } from '@/util/http'
import { apiUrl } from '@/domain/constants'
import { Mutation } from 'vuex-class'
import { MutationNames } from '@/store/types'
import CultureChoose from '@/components/CultureChoose.vue'
import DatePicker from '@/components/DatePicker.vue'

@Component({
    components: {
        CultureChoose,
        DatePicker
    }
})
export default class RegisterHistoryModel extends Vue {
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    private isShown = false
    private isUpdate = false
    private season = {
        origin: [],
        selectedName: '',
        onerror: ''
    }
    private springTreatments: any = []
    private autumnTreatments: any = []
    private history: any = {
        fieldId: -1,
        parent_culture: '',
        culture: '',
        sort: '',
        reproduction: '',
        start_date: '',
        end_date: '',
        spring_treatment: '',
        autumn_treatment: '',
        is_rest: false,
        file: null
    }
    private productivity = {
        origin: [
            {
                id: 1,
                name: this.$i18n.t('general.units.centner_he')
            },
            {
                id: 2,
                name: this.$i18n.t('general.units.t_he')
            }
        ],
        converted: [this.$i18n.t('general.units.centner_he'), this.$i18n.t('general.units.t_he')],
        selectedUnit: {
            id: 1,
            name: this.$i18n.t('general.units.centner_he')
        },
        unitName: this.$i18n.t('general.units.centner_he'),
        selectedName: '',
        onerror: ''
    }
    private historyForUpdate: any = null
    private isDataLoading = false

    private onSelectSeason(item: string) {
        this.season.selectedName = item
    }

    private onSelectSpringTreatment(item: string) {
        this.history.spring_treatment = item
    }

    private onSelectAutumnTreatment(item: string) {
        this.history.autumn_treatment = item
    }

    private onSelectUnit(item: string) {
        const unit = this.productivity.origin.find((x: any) => x.name === item)
        if (unit) {
            this.productivity.selectedUnit = unit
            this.productivity.unitName = unit.name
        }
    }

    private setIsRest(val: boolean) {
        this.history.is_rest = val
    }

    @Emit('reload')
    private reload() {
        return { }
    }

    @Watch('season.selectedName')
    private onchangeName() {
        this.season.onerror = ''
    }

    @Watch('productivity.selectedName')
    private onchangeProductivity() {
        this.productivity.onerror = ''
    }

    @Watch('history.is_rest')
    private onchangeIsRest() {
        this.productivity.onerror = ''
    }

    private getFileName() {
        if (this.history.file) {
            if (this.isUpdate && this.historyForUpdate.file === this.history.file) {
                return this.history.file.split('/media/crop_rotation/')[1]
            }
            if (this.history.file.name) return this.history.file.name
        }
        return `${this.$i18n.t('headers.workspace.fields.documents.add')}`
    }

    private openFileUpload() {
        const upload = this.$refs.uploadfile as any
        if (upload) {
            upload.click()
        }
    }

    private filesUploaded(files: any) {
        this.history.file = files[0]
    }

    private show(id: any, season?: any) {
        this.isShown = true
        this.isUpdate = false
        this.season = {
            origin: [],
            selectedName: season || '',
            onerror: ''
        }
        this.history = {
            fieldId: id,
            parent_culture: '',
            culture: '',
            sort: '',
            reproduction: '',
            start_date: '',
            end_date: '',
            spring_treatment: '',
            autumn_treatment: '',
            is_rest: false,
            file: null
        }
        this.productivity = {
            origin: [
                {
                    id: 1,
                    name: this.$i18n.t('general.units.centner_he')
                },
                {
                    id: 2,
                    name: this.$i18n.t('general.units.t_he')
                }
            ],
            converted: [this.$i18n.t('general.units.centner_he'), this.$i18n.t('general.units.t_he')],
            selectedUnit: {
                id: 1,
                name: this.$i18n.t('general.units.centner_he')
            },
            unitName: this.$i18n.t('general.units.centner_he'),
            selectedName: '',
            onerror: ''
        }
        this.isDataLoading = true
        httpGet({
            url: `${apiUrl}/get/crop_rotation/names/${id}/`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                if (json.ok) {
                    this.season.origin = json.json
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.isDataLoading = false
                this.fetchData()
            }
        })
    }

    private fetchData() {
        this.isDataLoading = true
        httpGet({
            url: `${apiUrl}/crop_rotation/treatment/`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                if (json.ok) {
                    this.springTreatments = json.json['1'].map((x: any) => x.name)
                    this.autumnTreatments = json.json['2'].map((x: any) => x.name)
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.isDataLoading = false
            }
        })
    }

    private update(history: any, id: any) {
        this.isShown = true
        this.isUpdate = true
        this.season = {
            origin: [],
            selectedName: history.name,
            onerror: ''
        }
        this.historyForUpdate = history
        this.history = {
            fieldId: id,
            parent_culture: history.parent_culture || '',
            culture: history.culture || '',
            sort: history.sort || '',
            reproduction: history.reproduction || '',
            start_date: history.start_date || '',
            end_date: history.end_date || '',
            spring_treatment: history.spring_treatment || '',
            autumn_treatment: history.autumn_treatment || '',
            is_rest: history.is_rest,
            file: history.file
        }
        this.productivity = {
            origin: [
                {
                    id: 1,
                    name: this.$i18n.t('general.units.centner_he')
                },
                {
                    id: 2,
                    name: this.$i18n.t('general.units.t_he')
                }
            ],
            converted: [this.$i18n.t('general.units.centner_he'), this.$i18n.t('general.units.t_he')],
            selectedUnit: {
                id: history.measure,
                name: history.measure === 1 ? this.$i18n.t('general.units.centner_he') : this.$i18n.t('general.units.t_he')
            },
            unitName: history.measure === 1 ? this.$i18n.t('general.units.centner_he') : this.$i18n.t('general.units.t_he'),
            selectedName: history.productivity ? `${history.productivity}` : '',
            onerror: ''
        }
        const el: any = this.$refs.cultureChoose
        if (el) {
            el.setData(this.history.parent_culture, this.history.culture, this.history.sort, this.history.reproduction)
        }
        this.fetchData()
    }

    private checkForError() {
        this.history.start_date = (this.$refs.startDateModel as any).getDate()
        this.history.end_date = (this.$refs.endDateModel as any).getDate()
        const el: any = this.$refs.cultureChoose
        if (el) {
            const result = el.getData()
            this.history.parent_culture = result.parent_culture || ''
            this.history.culture = result.culture || ''
            this.history.sort = result.sort || ''
            this.history.reproduction = result.reproduction || ''
        }
        let counter = 0
        if (this.season.selectedName === '') {
            this.season.onerror = `${this.$i18n.t('messages.errors.type_season')}`
            counter++
        } else if (isNaN(this.season.selectedName as any) && !this.isUpdate) {
            this.season.onerror = `${this.$i18n.t('messages.errors.type_season')}`
            counter++
        }
        if (!this.history.is_rest && this.productivity.selectedName !== '') {
            if (isNaN(this.productivity.selectedName as any)) {
                this.productivity.onerror = `${this.$i18n.t('messages.errors.incorrect_yield')}`
                counter++
            }
        }
        return counter > 0
    }

    private addHistory() {
        if (this.checkForError()) return
        const formData = new FormData()
        formData.append('name', this.season.selectedName)
        formData.append('divided_cadastre_user', this.history.fieldId as any)
        formData.append('culture', this.history.is_rest ? '' : this.history.culture)
        formData.append('parent_culture', this.history.is_rest ? '' : this.history.parent_culture)
        formData.append('sort', this.history.is_rest ? '' : this.history.sort)
        formData.append('reproduction', this.history.is_rest ? '' : this.history.reproduction)
        formData.append('start_date', this.history.is_rest ? '' : this.history.start_date)
        formData.append('end_date', this.history.is_rest ? '' : this.history.end_date)
        formData.append('productivity', this.history.is_rest ? '' : this.productivity.selectedName)
        formData.append('measure', this.productivity.selectedUnit.id as any)
        formData.append('is_rest', this.history.is_rest)
        formData.append('spring_treatment', this.history.spring_treatment)
        formData.append('autumn_treatment', this.history.autumn_treatment)
        if (this.history.file) {
            formData.append('file', this.history.file as any)
        }
        this.setLoadingDataNames('registerHistoryModelAddHistory')
        httpPostFormData({
            url: `${apiUrl}/crop_rotation/`,
            isSecureRequest: true,
            detailResponse: true,
            body: formData,
            onSuccess: json => {
                if (json.ok) {
                    this.$alert(`${this.$i18n.t('messages.success.successfully_added')}`, 'Добавлено', 'success').then(() => {
                        this.$emit('addnewval', {val: json.json, id: this.history.fieldId})
                        this.$emit('reload')
                        this.hide()
                    }).catch(e => {/* */})
                } else {
                    if (json.json.name.includes('Unique validation of name')) {
                        this.$alert(`${this.$i18n.t('messages.errors.already_exists_history')}`, 'Ошибка', 'error')
                    } else {
                        this.$alert(JSON.stringify(json.json), 'Ошибка', 'error')
                    }
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('registerHistoryModelAddHistory')
            }
        })
    }

    private updateHistory() {
        if (this.checkForError()) return
        const formData = new FormData()
        formData.append('culture', this.history.is_rest ? '' : this.history.culture)
        formData.append('parent_culture', this.history.is_rest ? '' : this.history.parent_culture)
        formData.append('sort', this.history.is_rest ? '' : this.history.sort)
        formData.append('reproduction', this.history.is_rest ? '' : this.history.reproduction)
        formData.append('start_date', this.history.is_rest ? '' : this.history.start_date)
        formData.append('end_date', this.history.is_rest ? '' : this.history.end_date)
        formData.append('productivity', this.history.is_rest ? '' : this.productivity.selectedName)
        formData.append('is_rest', this.history.is_rest)
        formData.append('measure', this.productivity.selectedUnit.id as any)
        formData.append('spring_treatment', this.history.spring_treatment)
        formData.append('autumn_treatment', this.history.autumn_treatment)
        if (this.history.file === null) {
            formData.append('file', '')
        } else if (this.history.file !== this.historyForUpdate.file) {
            formData.append('file', this.history.file)
        }
        this.setLoadingDataNames('registerHistoryModelUpdateHistory')
        httpPutFormData({
            url: `${apiUrl}/crop_rotation/${this.historyForUpdate.id}/`,
            isSecureRequest: true,
            detailResponse: true,
            body: formData,
            onSuccess: json => {
                if (json.ok) {
                    this.$alert(`${this.$i18n.t('messages.success.successfully_updated')}`, 'Обновлено', 'success').then(() => {
                        this.$emit('addnewval', {val: json.json, id: this.history.fieldId})
                        this.$emit('reload')
                        this.hide()
                    }).catch(e => {/* */})
                } else {
                    this.$alert(JSON.stringify(json.json), 'Ошибка', 'error')
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('registerHistoryModelUpdateHistory')
            }
        })
    }

    private hide() {
        this.isShown = false
    }
}
