












































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Getter, Action, Mutation } from 'vuex-class'
import { GetterNames, ActionNames, MutationNames } from '@/store/types'
import { Route } from 'vue-router'
import { httpPost } from '@/util/http'
import { apiUrl } from '@/domain/constants'
import Farm from '@/models/Farm'
import Chart from 'chart.js/auto'
import i18n from '@/i18n'
import {checkbox, checkboxBlue, checkboxYellow, egisticLogo, pdfWaterMark, select, selectBlue, selectGreen, selectRed, selectYellow} from '@/data/pdfImageData'
import { makePDF, PdfActions, sleep } from '@/utils/services'
@Component
export default class FieldAnalytics extends Vue {
    @Action(ActionNames.fetchFarmsList) private fetchFarmsList !: () => void
    @Action(ActionNames.changeFarmSeason) private changeFarmSeason!: any
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: Route
    @Getter(GetterNames.getFarmFromRoute) private getFarmFromRoute!: Farm
    @Getter(GetterNames.getIsDataLoading) private getIsDataLoading!: any
    @Getter(GetterNames.getSidebarModel) private getSidebarModel!: any
    @Getter(GetterNames.getCurrentSeason) private getCurrentSeason!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    private fromDate = ''
    private toDate = ''
    private IsDataLoadingFirst = false
    private IsDataLoadingSecond = false
    private seasonDropdown = false
    private secondSeasonDropdown = false
    private seasonComparisonMode = false
    private secondSelectedSeason: any = {
        name: '',
        id: -1,
        start_date: '',
        end_date: ''
    }
    private activeIndicators: any = {
        ndvi: true,
        gndvi: false,
        ndmi: false,
        clgreen: false,
        temperature: true,
        precipitation: true
    }
    private AnalyticsData: any = {
        from_date: '',
        to_date: '',
        rating_number: 0,
        average_value: 0,
        crop_type: i18n.t('messages.not_chosen'),
        ndvi: [],
        maxndvi: [],
        minndvi: [],
        gndvi: [],
        maxgndvi: [],
        mingndvi: [],
        ndmi: [],
        maxndmi: [],
        minndmi: [],
        clgreen: [],
        maxclgreen: [],
        minclgreen: [],
        temperature: [],
        precipitation: []
    }
    private secondAnalyticsData: any = {
        from_date: '',
        to_date: '',
        ndvi: [],
        gndvi: [],
        ndmi: [],
        clgreen: [],
        temperature: [],
        precipitation: []
    }
    private indicatorsChart: any
    private meteoChart: any
    private egisticLogo = egisticLogo
    private pdfWaterMark = pdfWaterMark

    @Watch('indicatorsChart') // own function
    private watchOnChart(val: any) {
        console.log(val)
    }

    get getChosenSeason() {
        if (this.getCurrentSeason) {
            return i18n.t('headers.workspace.fields.analytics.cur_season') + this.getCurrentSeason.name
        } else return i18n.t('messages.errors.no_seasons')
    }

    @Watch('getCurrentSeason')
    private onchangeCurrentSeason() {
        if (this.getCurrentSeason) {
            this.fromDate = this.getCurrentSeason.start_date
            this.toDate = this.getCurrentSeason.end_date
        }
    }

    private mounted() {
        const ctx: any = document.getElementById('indicators-chart')
        this.indicatorsChart = new Chart(ctx.getContext('2d'), {
            type: 'line',
            data: {
                datasets: []
            },
            options: {
                hover: {
                    mode: 'point'
                },
                scales: {
                    yAxes: [
                        {
                            id: 'y-axis-1',
                            position: 'left',
                            gridLines: {color: 'rgba(0, 0, 0, 0.64)', zeroLineColor: '#000000'}
                        },
                        {
                            id: 'y-axis-2',
                            position: 'right',
                            gridLines: {color: 'rgba(0, 0, 0, 0.64)', zeroLineColor: '#000000'},
                            ticks: {
                                callback(label: any) {
                                    return
                                }
                            }
                        }] as any,
                    xAxes: [
                        {
                            type: 'time',
                            time: {
                                unit: 'day',
                                tooltipFormat: 'DD.MM.YY'
                            },
                        ticks: {
                            fontColor: '#000000',
                            maxTicksLimit: 8,
                            maxRotation: 0,
                            minRotation: 0,
                            callback(label: any) {
                                const EnRuArr = [{en: 'Jan', ru: 'Янв'}, {en: 'Feb', ru: 'Фев'},
                                            {en: 'Mar', ru: 'Мар'}, {en: 'Apr', ru: 'Апр'},
                                            {en: 'May', ru: 'Май'}, {en: 'Jun', ru: 'Июн'},
                                            {en: 'Jul', ru: 'Июл'}, {en: 'Aug', ru: 'Авг'},
                                            {en: 'Sep', ru: 'Сен'}, {en: 'Oct', ru: 'Окт'},
                                            {en: 'Nov', ru: 'Ноя'}, {en: 'Dec', ru: 'Дек'}]
                                if (typeof(label) === 'string') {
                                    const month = EnRuArr.find((x: any) => x.en === label.substring(0, 3))
                                    if (month) {
                                        return month.ru + label.substring(3)
                                    }
                                }
                                return label
                            }
                        }
                    }] as any
                }

                // tooltips: {
                //     titleFontSize: 0,
                //     custom(tooltip: any) {
                //         tooltip.backgroundColor = '#FFFFFF'
                //         tooltip.borderWidth = 1
                //         tooltip.displayColors = false
                //         if (tooltip.labelTextColors) {
                //             tooltip.borderColor = tooltip.labelTextColors[0]
                //         }
                //     },
                //     callbacks: {
                //         title: () => { return },
                //         label(tooltipItem: any, chart: any) {
                //             const EnRuArr = [{en: 'Jan', ru: 'Янв'}, {en: 'Feb', ru: 'Фев'},
                //                         {en: 'Mar', ru: 'Мар'}, {en: 'Apr', ru: 'Апр'},
                //                         {en: 'May', ru: 'Май'}, {en: 'Jun', ru: 'Июн'},
                //                         {en: 'Jul', ru: 'Июл'}, {en: 'Aug', ru: 'Авг'},
                //                         {en: 'Sep', ru: 'Сен'}, {en: 'Oct', ru: 'Окт'},
                //                         {en: 'Nov', ru: 'Ноя'}, {en: 'Dec', ru: 'Дек'}]
                //             if (typeof(tooltipItem.label) === 'string') {
                //                 const month = EnRuArr[Number(tooltipItem.label.substring(3, 5)) - 1]
                //                 if (month) {
                //                     return Number(tooltipItem.label.substring(0, 2)) + ' ' + month.ru + ': ' + tooltipItem.value
                //                 }
                //             }
                //             return tooltipItem.value
                //         },
                //         labelTextColor(tooltipItem: any, chart: any) {
                //             const index = tooltipItem.datasetIndex
                //             if (chart.data.datasets[index].label === 'second') {
                //                 return chart.data.datasets[index].borderColor
                //             } else {
                //                 return chart.data.datasets.find((x: any) => x.label === 'avg').borderColor
                //             }
                //         }
                //     }
                // }
            }
        })

        const ctx2: any = document.getElementById('meteo-chart')
        this.meteoChart = new Chart(ctx2.getContext('2d'), {
            type: 'line',
            data: {
                datasets: [
                    {} as any
                ]
            },
            options: {
                // legend: false,
                elements: {
                    point: {
                        radius: 0
                    }
                },
                layout: {
                    padding: 0
                },
                maintainAspectRatio: true,

                scales: {
                    y: {
                        type: 'linear',
                        display: true,
                        position: 'left'
                    },
                    y1: {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        grid: {
                            drawOnChartArea: false // only want the grid lines for one axis to show up
                        }
                    }
                }
            }
        })

        if (this.getCurrentSeason) {
            this.fromDate = this.getCurrentSeason.start_date
            this.toDate = this.getCurrentSeason.end_date
        } else {
            let startDate: any = new Date()
            startDate.setDate(startDate.getDate() - 14)
            startDate = startDate.toJSON().slice(0, 10)
            const finishDate = new Date().toJSON().slice(0, 10)
            this.fromDate = startDate
            this.toDate = finishDate
        }
    }

    get getFromDate() {
        if (this.fromDate === '') return i18n.t('general.dates.format_date')
        return this.fromDate.substring(8, 10) + '.' + this.fromDate.substring(5, 7) + '.' + this.fromDate.substring(2, 4)
    }

    get getToDate() {
        if (this.toDate === '') return i18n.t('general.dates.format_date')
        return this.toDate.substring(8, 10) + '.' + this.toDate.substring(5, 7) + '.' + this.toDate.substring(2, 4)
    }

    @Watch('getFromDate')
    private FromDateChange(newVal: any, oldVal: any) {
        if (this.seasonComparisonMode) {
            console.log(this.seasonComparisonMode, this.AnalyticsData)
            if (this.AnalyticsData.from_date.substring(4) === this.fromDate.substring(4) && this.AnalyticsData.to_date.substring(4) === this.toDate.substring(4)) return
            const fromDate = `${this.getCurrentSeason.start_date.substring(0, 4)}${this.fromDate.substring(4) === '-02-29' ? '-02-28' : this.fromDate.substring(4)}`
            const toDate = `${this.getCurrentSeason.start_date.substring(0, 4)}${this.toDate.substring(4) === '-02-29' ? '-03-01' : this.toDate.substring(4)}`
            const secondFromDate = `${this.secondSelectedSeason.start_date.substring(0, 4)}${this.fromDate.substring(4) === '-02-29' ? '-02-28' : this.fromDate.substring(4)}`
            const secondToDate = `${this.secondSelectedSeason.start_date.substring(0, 4)}${this.toDate.substring(4) === '-02-29' ? '-03-01' : this.toDate.substring(4)}`
            this.getAnalyticsData(fromDate, toDate)
            this.getAnalyticsSecondData(secondFromDate, secondToDate)
            this.getAnaliticsMeteo(fromDate, toDate)
            this.getAnalyticsSecondMeteo(secondFromDate, secondToDate)
        } else {
            if (this.AnalyticsData.from_date === this.fromDate &&
                this.AnalyticsData.to_date === this.toDate) return
            this.getAnalyticsData(this.fromDate, this.toDate)
            this.getAnaliticsMeteo(this.fromDate, this.fromDate)
        }
    }

    private changeSwitcher() {
        this.seasonComparisonMode = !this.seasonComparisonMode
        if (this.seasonComparisonMode) {
            this.secondSelectedSeason = {
                name: '',
                start_date: '',
                end_date: '',
                id: -1
            }
        } else {
            this.updateFirstData()
            this.updateSecondChart()
        }
    }

    @Watch('getToDate')
    private ToDateChange(newVal: any, oldVal: any) {
        if (this.seasonComparisonMode) {
            if (this.AnalyticsData.from_date.substring(4) === this.fromDate.substring(4) &&
                this.AnalyticsData.to_date.substring(4) === this.toDate.substring(4)) return
            const fromDate = `${this.getCurrentSeason.start_date.substring(0, 4)}${this.fromDate.substring(4) === '-02-29' ? '-02-28' : this.fromDate.substring(4)}`
            const toDate = `${this.getCurrentSeason.start_date.substring(0, 4)}${this.toDate.substring(4) === '-02-29' ? '-03-01' : this.toDate.substring(4)}`
            const secondFromDate = `${this.secondSelectedSeason.start_date.substring(0, 4)}${this.fromDate.substring(4) === '-02-29' ? '-02-28' : this.fromDate.substring(4)}`
            const secondToDate = `${this.secondSelectedSeason.start_date.substring(0, 4)}${this.toDate.substring(4) === '-02-29' ? '-03-01' : this.toDate.substring(4)}`
            this.getAnalyticsData(fromDate, toDate)
            this.getAnalyticsSecondData(secondFromDate, secondToDate)
            this.getAnaliticsMeteo(fromDate, toDate)
            this.getAnalyticsSecondMeteo(secondFromDate, secondToDate)
        } else {
            if (this.AnalyticsData.from_date === this.fromDate &&
                this.AnalyticsData.to_date === this.toDate) return
            this.getAnalyticsData(this.fromDate, this.toDate)
            this.getAnaliticsMeteo(this.fromDate, this.toDate)
        }
    }

    private changeSecondSeason(season: any) {
        this.secondSelectedSeason = season
        this.secondSeasonDropdown = false
        const fromDate = `${this.getCurrentSeason.start_date.substring(0, 4)}${this.fromDate.substring(4) === '-02-29' ? '-02-28' : this.fromDate.substring(4)}`
        const toDate = `${this.getCurrentSeason.start_date.substring(0, 4)}${this.toDate.substring(4) === '-02-29' ? '-03-01' : this.toDate.substring(4)}`
        const secondFromDate = `${this.secondSelectedSeason.start_date.substring(0, 4)}${this.fromDate.substring(4) === '-02-29' ? '-02-28' : this.fromDate.substring(4)}`
        const secondToDate = `${this.secondSelectedSeason.start_date.substring(0, 4)}${this.toDate.substring(4) === '-02-29' ? '-03-01' : this.toDate.substring(4)}`
        this.getAnalyticsData(fromDate, toDate)
        this.getAnalyticsSecondData(secondFromDate, secondToDate)
        this.getAnaliticsMeteo(fromDate, toDate)
        this.getAnalyticsSecondMeteo(secondFromDate, secondToDate)
    }

    private getAnalyticsData(fromDate: any, toDate: any) {
        if (this.getFromDate !== i18n.t('general.dates.format_date') && this.getToDate !== i18n.t('general.dates.format_date')) {
            this.IsDataLoadingFirst = true
            httpPost({
                url: apiUrl + '/analytics/divided/',
                isSecureRequest: true,
                body: {
                    divided_cadastre_user: parseInt(this.currentRoute.params.subfieldId, 10),
                    from_date: fromDate,
                    to_date: toDate
                },
                onSuccess: json => {
                    if (this.seasonComparisonMode) {
                        if (json.from_date.substring(4) === (this.fromDate.substring(4) === '-02-29' ? '-02-28' : this.fromDate.substring(4)) &&
                    json.to_date.substring(4) === (this.toDate.substring(4) === '-02-29' ? '-03-01' : this.toDate.substring(4))) {
                                this.dataParsing(json)
                        }
                    } else {
                        if (json.from_date === this.fromDate && json.to_date === this.toDate) {
                            this.dataParsing(json)
                        }
                    }
                },
                onError: error => {
                    console.log(error)
                },
                doFinally: () => {
                    this.IsDataLoadingFirst = false
                }
            })
        }
    }

    private getAnalyticsSecondData(fromDate: any, toDate: any) {
        if (this.getFromDate !== i18n.t('general.dates.format_date') && this.getToDate !== i18n.t('general.dates.format_date')) {
            this.IsDataLoadingFirst = true
            httpPost({
                url: apiUrl + '/analytics/divided/',
                isSecureRequest: true,
                body: {
                    divided_cadastre_user: parseInt(this.currentRoute.params.subfieldId, 10),
                    from_date: fromDate,
                    to_date: toDate
                },
                onSuccess: json => {
                    if (json.from_date.substring(4) === (this.fromDate.substring(4) === '-02-29' ? '-02-28' : this.fromDate.substring(4)) &&
                    json.to_date.substring(4) === (this.toDate.substring(4) === '-02-29' ? '-03-01' : this.toDate.substring(4))) {
                            this.secondDataParsing(json)
                    }
                },
                onError: error => {
                    console.log(error)
                },
                doFinally: () => {
                    this.IsDataLoadingFirst = false
                }
            })
        }
    }

    private getAnaliticsMeteo(fromDate: any, toDate: any) {
        if (this.getFromDate !== i18n.t('general.dates.format_date') && this.getToDate !== i18n.t('general.dates.format_date')) {
            this.IsDataLoadingSecond = true
            httpPost({
                url: apiUrl + '/analytics/divided/meteo/',
                isSecureRequest: true,
                body: {
                    divided_cadastre_user: parseInt(this.currentRoute.params.subfieldId, 10),
                    from_date: fromDate,
                    to_date: toDate
                },
                onSuccess: json => {
                    if (json.from_date.substring(4) === (this.fromDate.substring(4) === '-02-29' ? '-02-28' : this.fromDate.substring(4)) &&
                    json.to_date.substring(4) === (this.toDate.substring(4) === '-02-29' ? '-03-01' : this.toDate.substring(4))) {
                        this.AnalyticsData.from_date = json.from_date
                        this.AnalyticsData.to_date = json.to_date
                        this.AnalyticsData.temperature = json.meteo.temperature
                        this.AnalyticsData.precipitation = json.meteo.precipitation
                        this.updateSecondChart()
                    }
                },
                onError: error => {
                    console.log(error)
                },
                doFinally: () => {
                    this.IsDataLoadingSecond = false
                }
            })
        }
    }

    private getAnalyticsSecondMeteo(fromDate: any, toDate: any) {
        if (this.getFromDate !== i18n.t('general.dates.format_date') && this.getToDate !== i18n.t('general.dates.format_date')) {
            this.IsDataLoadingSecond = true
            httpPost({
                url: apiUrl + '/analytics/divided/meteo/',
                isSecureRequest: true,
                body: {
                    divided_cadastre_user: parseInt(this.currentRoute.params.subfieldId, 10),
                    from_date: fromDate,
                    to_date: toDate
                },
                onSuccess: json => {
                    if (json.from_date.substring(4) === (this.fromDate.substring(4) === '-02-29' ? '-02-28' : this.fromDate.substring(4)) &&
                        json.to_date.substring(4) === (this.toDate.substring(4) === '-02-29' ? '-03-01' : this.toDate.substring(4))) {
                            this.secondAnalyticsData.temperature = json.meteo.temperature ? json.meteo.temperature : []
                            this.secondAnalyticsData.precipitation = json.meteo.precipitation ? json.meteo.precipitation : []
                            this.updateSecondChart()
                    }
                },
                onError: error => {
                    console.log(error)
                },
                doFinally: () => {
                    this.IsDataLoadingSecond = false
                }
            })
        }
    }

    private dataParsing(json: any) {
        this.AnalyticsData.ndvi = []
        this.AnalyticsData.maxndvi = []
        this.AnalyticsData.minndvi = []
        this.AnalyticsData.gndvi = []
        this.AnalyticsData.maxgndvi = []
        this.AnalyticsData.mingndvi = []
        this.AnalyticsData.ndmi = []
        this.AnalyticsData.maxndmi = []
        this.AnalyticsData.minndmi = []
        this.AnalyticsData.clgreen = []
        this.AnalyticsData.maxclgreen = []
        this.AnalyticsData.minclgreen = []
        this.AnalyticsData.temperature = []
        this.AnalyticsData.precipitation = []
        this.AnalyticsData.from_date = json.from_date
        this.AnalyticsData.to_date = json.to_date
        this.AnalyticsData.rating_number = json.rating_order
        this.AnalyticsData.average_value = Math.round((json.rating_value + Number.EPSILON) * 100) / 100
        this.AnalyticsData.crop_type = json.crop_type === null ? i18n.t('messages.not_chosen') : json.crop_type
        for (const day of json.field) {
            const date = day.actual_date.substring(0, 10)
            if (day.ndvi) {
                this.AnalyticsData.ndvi.push({t: date, y: Math.floor(day.ndvi.mean * 100) / 100})
                this.AnalyticsData.maxndvi.push({t: date, y:  Math.floor(day.ndvi.max * 100) / 100})
                this.AnalyticsData.minndvi.push({t: date, y: Math.floor(day.ndvi.min * 100) / 100})
            }
            if (day.gndvi) {
                this.AnalyticsData.gndvi.push({t: date, y: Math.floor(day.gndvi.mean * 100) / 100})
                this.AnalyticsData.maxgndvi.push({t: date, y: Math.floor(day.gndvi.max * 100) / 100})
                this.AnalyticsData.mingndvi.push({t: date, y: Math.floor(day.gndvi.min * 100) / 100})
            }
            if (day.ndmi) {
                this.AnalyticsData.ndmi.push({t: date, y: Math.floor(day.ndmi.mean * 100) / 100})
                this.AnalyticsData.maxndmi.push({t: date, y: Math.floor(day.ndmi.max * 100) / 100})
                this.AnalyticsData.minndmi.push({t: date, y: Math.floor(day.ndmi.min * 100) / 100})
            }
            if (day.clgreen) {
                this.AnalyticsData.clgreen.push({t: date, y: Math.floor(day.clgreen.mean * 100) / 100})
                this.AnalyticsData.maxclgreen.push({t: date, y: Math.floor(day.clgreen.max * 100) / 100})
                this.AnalyticsData.minclgreen.push({t: date, y: Math.floor(day.clgreen.min * 100) / 100})
            }
        }
        this.updateFirstData()
    }

    private secondDataParsing(json: any) {
        this.secondAnalyticsData = {
            ndvi: [],
            gndvi: [],
            ndmi: [],
            clgreen: [],
            temperature: [],
            precipitation: []
        }
        this.secondAnalyticsData.from_date = json.from_date
        this.secondAnalyticsData.to_date = json.to_date
        for (const day of json.field) {
            const date = day.actual_date.substring(0, 10)
            const year = this.getCurrentSeason.start_date.substring(0, 4)
            if (date.substring(4) === '-02-29') return
            if (day.ndvi) {
                this.secondAnalyticsData.ndvi.push({t: year + date.substring(4), y: day.ndvi.mean.toFixed(2)})
            }
            if (day.gndvi) {
                this.secondAnalyticsData.gndvi.push({t: year + date.substring(4), y: day.gndvi.mean.toFixed(2)})
            }
            if (day.ndmi) {
                this.secondAnalyticsData.ndmi.push({t: year + date.substring(4), y: day.ndmi.mean.toFixed(2)})
            }
            if (day.clgreen) {
                this.secondAnalyticsData.clgreen.push({t: year + date.substring(4), y: day.clgreen.mean.toFixed(2)})
            }
        }
        this.updateFirstData()
    }

    private updateFirstData() {
        const newData: any = {
            labels: this.AnalyticsData.minclgreen.map((x: any) => x.t),
            datasets: []
        }
        if (this.activeIndicators.ndvi) {
            newData.datasets.push({label: 'min',
                data: this.AnalyticsData.minndvi.map((x: any) => x.y),
                pointHitRadius: 5,
                pointRadius: 0,
                pointHoverBackgroundColor: '#4B970F',
                fill: false})
            newData.datasets.push({label: 'avg',
                data: this.AnalyticsData.ndvi.map((x: any) => x.y),
                backgroundColor: 'rgba(75, 151, 15, 0.1)',
                borderColor: '#4B970F',
                fill: "-1"})
            newData.datasets.push({label: 'max',
                data: this.AnalyticsData.maxndvi.map((x: any) => x.y),
                backgroundColor: 'rgba(75, 151, 15, 0.1)',
                pointHitRadius: 5,
                pointRadius: 0,
                pointHoverBackgroundColor: '#4B970F',
                fill: "-1",
                line: false})
            if (this.seasonComparisonMode) {
                newData.datasets.push({label: 'second', data: this.secondAnalyticsData.ndvi.map((x: any) => x.y), fill: false, borderColor: '#878882'})
            }
        }
        if (this.activeIndicators.gndvi) {
            newData.datasets.push({label: 'min',
                data: this.AnalyticsData.mingndvi.map((x: any) => x.y),
                pointHitRadius: 5,
                pointRadius: 0,
                pointHoverBackgroundColor: '#008CDB',
                fill: false})
            newData.datasets.push({label: 'avg',
                data: this.AnalyticsData.gndvi.map((x: any) => x.y),
                backgroundColor: 'rgba(0, 140, 219, 0.1)',
                borderColor: '#008CDB',
                fill: "-1"})
            newData.datasets.push({label: 'max',
                data: this.AnalyticsData.maxgndvi.map((x: any) => x.y),
                backgroundColor: 'rgba(0, 140, 219, 0.1)',
                pointHitRadius: 5,
                pointRadius: 0,
                pointHoverBackgroundColor: '#008CDB',
                fill: "-1",
                line: false})
            if (this.seasonComparisonMode) {
                newData.datasets.push({label: 'second', data: this.secondAnalyticsData.gndvi.map((x: any) => x.y), fill: false, borderColor: '#878882'})
            }
        }
        if (this.activeIndicators.ndmi) {
            newData.datasets.push({label: 'min',
                data: this.AnalyticsData.minndmi.map((x: any) => x.y),
                pointHitRadius: 5,
                pointRadius: 0,
                pointHoverBackgroundColor: '#FFC700',
                fill: false})
            newData.datasets.push({label: 'avg',
                data: this.AnalyticsData.ndmi.map((x: any) => x.y),
                backgroundColor: 'rgba(255, 199, 0, 0.1)',
                borderColor: '#FFC700',
                fill: "-1"})
            newData.datasets.push({label: 'max',
                data: this.AnalyticsData.maxndmi.map((x: any) => x.y),
                backgroundColor: 'rgba(255, 199, 0, 0.1)',
                pointHitRadius: 5,
                pointRadius: 0,
                pointHoverBackgroundColor: '#FFC700',
                fill: "-1",
                line: false})
            if (this.seasonComparisonMode) {
                newData.data.push({label: 'second', data: this.secondAnalyticsData.ndmi.map((x: any) => x.y), fill: false, borderColor: '#878882'})
            }
        }
        if (this.activeIndicators.clgreen) {
            newData.datasets.push({label: 'min',
                data: this.AnalyticsData.minclgreen.map((x: any) => x.y),
                pointHitRadius: 5,
                pointRadius: 0,
                pointHoverBackgroundColor: '#D51900',
                fill: false})
            newData.datasets.push({label: 'avg',
                data: this.AnalyticsData.clgreen.map((x: any) => x.y),
                backgroundColor: 'rgba(213, 25, 0, 0.1)',
                borderColor: '#D51900',
                fill: "-1"})
            newData.datasets.push({label: 'max',
                data: this.AnalyticsData.maxclgreen.map((x: any) => x.y),
                backgroundColor: 'rgba(213, 25, 0, 0.1)',
                pointHoverBackgroundColor: '#D51900',
                pointHitRadius: 5,
                pointRadius: 0,
                fill: "-1",
                line: false})
            if (this.seasonComparisonMode) {
                newData.datasets.push({label: 'second', data: this.secondAnalyticsData.clgreen.map((x: any) => x.y), fill: false, borderColor: '#878882'})
            }
        }

        const dataNew = {
            labels: this.AnalyticsData.minclgreen.map((x: any) => x.t),
            datasets: [{
                label: 'min',
                data: this.AnalyticsData.minclgreen.map((x: any) => x.y),
                fill: false,
                borderColor: 'rgb(75, 192, 192)',
                pointHoverBackgroundColor: '#4B970F',
                pointHitRadius: 5,
                pointRadius: 0,
                tension: 0.1

            },
            {
                label: 'avg',
                data: this.AnalyticsData.clgreen.map((x: any) => x.y),
                fill: false,
                borderColor: 'rgb(75, 192, 192)',
                pointHoverBackgroundColor: '#4B970F',
                pointHitRadius: 5,
                pointRadius: 0,
                tension: 0.1
            },
            {
                label: 'max',
                data: this.AnalyticsData.maxclgreen.map((x: any) => x.y),
                fill: false,
                borderColor: 'rgb(75, 192, 192)',
                pointHoverBackgroundColor: '#4B970F',
                pointHitRadius: 5,
                pointRadius: 0,
                tension: 0.1
            }]
        }

        this.indicatorsChart.data = newData

        this.indicatorsChart.update()
    }

    private updateSecondChart() {
        const firstTemperatureData: any = this.AnalyticsData.temperature.map((x: any) => ({t: x.date, y: x.value}))
        const firstPrecipitationData: any = this.AnalyticsData.precipitation.map((x: any) => {
            return {t: x.date, y: x.value}
        })
        const secondTemperatureData: any = this.secondAnalyticsData.temperature.filter((x: any) => x.date.substring(4) !== '-02-29').map((x: any) => {
            return {t: `${this.getCurrentSeason.start_date.substring(0, 4)}${x.date.substring(4)}`, y: x.value}
        })
        const secondPrecipitationData: any = this.secondAnalyticsData.precipitation.filter((x: any) => x.date.substring(4) !== '-02-29').map((x: any) => {
            return {t: this.getCurrentSeason.start_date.substring(0, 4) + x.date.substring(4), y: x.value}
        })
        if (this.activeIndicators.temperature && this.activeIndicators.precipitation && this.seasonComparisonMode) {
            this.activeIndicators.precipitation = false
        }

        const newData: any = {
            labels: this.AnalyticsData.minclgreen.map((x: any) => x.t),
            datasets: []
        }

        if (this.activeIndicators.temperature) {
            newData.datasets.push({
                label: 'temperature-1',
                data: firstTemperatureData.map((x: any) => x.y),
                borderColor: '#FFC700',
                backgroundColor: 'rgba(255, 199, 0, 0.1)',
                pointHitRadius: 5,
                yAxisID: 'y' // 'left'
            })
            if (this.seasonComparisonMode) {
                newData.datasets.push({
                    label: 'temperature-2',
                    data: secondTemperatureData.map((x: any) => x.y),
                    borderColor: '#878882',
                    pointHitRadius: 5,
                    yAxisID: 'y' // 'left'
                })
            }
        }
        if (this.activeIndicators.precipitation) {
            newData.datasets.push({
                label: 'precipitation-1',
                data: firstPrecipitationData.map((x: any) => x.y),
                borderColor: '#008CDB',
                backgroundColor: 'rgba(0, 140, 219, 0.1)',
                pointHitRadius: 5,
                yAxisID: 'y1' // 'right'
            })
            if (this.seasonComparisonMode) {
                newData.datasets.push({
                    label: 'precipitation-2',
                    data: secondPrecipitationData.map((x: any) => x.y),
                    borderColor: '#878882',
                    pointHitRadius: 5,
                    yAxisID: 'y1' // 'right'
                })
            }
        }
        this.meteoChart.data = newData
        this.meteoChart.update()
    }

    private getSecondChosenSeason() {
        if (this.secondSelectedSeason.id === -1) return i18n.t('messages.selections.season')
        else return this.secondSelectedSeason.name
    }

    private changeIndicators(indicator: any) {
        this.activeIndicators.ndvi = false
        this.activeIndicators.gndvi = false
        this.activeIndicators.ndmi = false
        this.activeIndicators.clgreen = false
        if (indicator === 'ndvi') this.activeIndicators.ndvi = true
        if (indicator === 'gndvi') this.activeIndicators.gndvi = true
        if (indicator === 'ndmi') this.activeIndicators.ndmi = true
        if (indicator === 'clgreen') this.activeIndicators.clgreen = true
        this.updateFirstData()
    }

    private changeMeteoRatio(meteo: any) {
        if (meteo === 'temperature') {
            if (this.seasonComparisonMode) {
                this.activeIndicators.temperature = true
                this.activeIndicators.precipitation = false
            } else {
                this.activeIndicators.temperature = !this.activeIndicators.temperature
            }
        }
        if (meteo === 'precipitation') {
            if (this.seasonComparisonMode) {
                this.activeIndicators.precipitation = true
                this.activeIndicators.temperature = false
            } else {
                this.activeIndicators.precipitation = !this.activeIndicators.precipitation
            }
        }
        this.updateSecondChart()
    }

    private closeSeasonDropdown() {
        this.seasonDropdown = false
    }

    private closeSecondSeasonDropdown() {
        this.secondSeasonDropdown = false
    }

/* ----------------------------------------- Print data ------------------------------------------ */

    get field() {
        if (this.getFarmFromRoute) {
            const field = this.getFarmFromRoute.fields.find((x: any) => x.id === parseInt(this.$route.params.subfieldId, 10))
            if (field) {
                return field
            } else {
                this.fetchFarmsList()
                return false
            }
        } else {
            this.fetchFarmsList()
            return false
        }
    }

    private indicatorGraph = (content: any) => {
        content.push({
            margin: [0, 5, 0, 15],
            table: {
                headerRows: 1,
                body: [
                    [
                        {
                            svg: this.activeIndicators.ndvi ? selectGreen : select,
                            height: 20,
                            width: 20
                        },
                        {
                            text: i18n.t('headers.workspace.fields.indicators.indicators.vegetation.title'),
                            margin: [6, 3, 30, 4]
                        },
                        {
                            svg: this.activeIndicators.gndvi ? selectBlue : select,
                            height: 20,
                            width: 20
                        },
                        {
                            text: i18n.t('headers.workspace.fields.indicators.indicators.nitrogen.title'),
                            margin: [6, 3, 30, 4]
                        },
                        {
                            svg: this.activeIndicators.ndmi ? selectYellow : select,
                            height: 20,
                            width: 20
                        },
                        {
                            text: i18n.t('headers.workspace.fields.indicators.indicators.humidity.title'),
                            margin: [6, 3, 30, 4]
                        },
                        {
                            svg: this.activeIndicators.clgreen ? selectRed : select,
                            height: 20,
                            width: 20
                        },
                        {
                            text: i18n.t('headers.workspace.fields.indicators.indicators.chlorophyll.title'),
                            margin: [6, 3, 30, 4]
                        }
                    ]
                ]
            },
            layout: 'noBorders'
        })
        const canvas = document.getElementById('indicators-chart') as HTMLCanvasElement
        content.push({
            image: canvas.toDataURL("image/png"),
            width: 525,
            pageBreak: this.activeIndicators.ndvi || this.activeIndicators.ndmi ? 'after' : ''
        })
    }

    private meteoRatioLables = (meteo: string, content: any) => {
        content.push({
            margin: [0, 5, 0, 15],
            table: {
                headerRows: 1,
                body: [
                    [
                        {
                            svg: (!meteo || meteo === 'temperature') ? checkboxYellow : checkbox,
                            height: 20,
                            width: 20
                        },
                        {
                            text: i18n.t('headers.workspace.fields.analytics.graphs[1]'),
                            margin: [6, 3, 30, 4]
                        },
                        {
                            svg: (!meteo || meteo === 'precipitation') ? checkboxBlue : checkbox,
                            height: 20,
                            width: 20
                        },
                        {
                            text: i18n.t('headers.workspace.fields.analytics.graphs[2]'),
                            margin: [6, 3, 30, 4]
                        }
                    ]
                ]
            },
            layout: 'noBorders'
        })
    }

    private getMeteoGraph = (content: any) => {
        const canvas = document.getElementById('meteo-chart') as HTMLCanvasElement
        content.push({
            image: canvas.toDataURL("image/png"),
            width: 525
        })
    }

    private makeDoc(action: string) {
        this.setLoadingDataNames('PrintingFieldAnalyticsData')
        const docDefinition: any = {
            pageSize: {
                width: 595,
                height: 842
            },
            pageMargins: [30, 30, 30, 30],
            background: [
                {
                    image: this.pdfWaterMark,
                    margin: [308, 0, 0, 0],
                    width: 360
                }
            ]
        }
        const content: any = [
            {
                image: this.egisticLogo,
                margin: [0, 10, 0, 0],
                width: 91.14,
                height: 30,
                alignment: 'center'
            },
            {
                text: i18n.t('headers.workspace.fields.analytics.title'),
                fontSize: 16,
                bold: true,
                margin: [0, 24, 0, 0],
                alignment: 'center'
            },
            {
                text: `${i18n.t('headers.workspace.farm.title')}: ${this.getFarmFromRoute.name}   ${i18n.t('headers.workspace.fields.field')}: #${this.field.name}`,
                fontSize: 16,
                bold: true,
                margin: [0, 8, 0, 24],
                alignment: 'center'
            },
            {
                columns: [
                    {
                        width: '40%',
                        text: [
                            {
                                text: i18n.t('headers.workspace.fields.analytics.rating') + "\n",
                                fontSize: 16,
                                bold: true,
                                lineHeight: 1.6
                            },
                            {
                                text: [
                                    {
                                        text:  i18n.t('headers.workspace.fields.analytics.num_rating') + " ",
                                        fontSize: 12,
                                        lineHeight: 1.4
                                    },
                                    {
                                        text: this.AnalyticsData.rating_number ? " " + this.AnalyticsData.rating_number + "\n" : ' 0\n',
                                        bold: true,
                                        fontSize: 16,
                                        lineHeight: 1.4
                                    }
                                ]
                            },
                            {
                                text: [
                                    {
                                        text: i18n.t('headers.workspace.fields.analytics.avg_value') + " ",
                                        fontSize: 12,
                                        lineHeight: 2
                                    },
                                    {
                                        text: this.AnalyticsData.average_value ? " " + this.AnalyticsData.average_value + "\n" : ' 0\n',
                                        fontSize: 16,
                                        color: '#76B561',
                                        lineHeight: 2
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        width: '*',
                        table: {
                            widths: ['*', '*'],
                            body: [
                                [
                                    {
                                        text: i18n.t('headers.workspace.farm.season.start'),
                                        color: "#878882",
                                        height: '24'
                                    },
                                    {
                                        text: i18n.t('headers.workspace.farm.season.end'),
                                        color: "#878882",
                                        height: '24'
                                    }
                                ],
                                [
                                    {
                                        text: this.getFromDate,
                                        bold: true,
                                        fontSize: '14',
                                        lineHeight: 1.4
                                    },
                                    {
                                        text: this.getToDate,
                                        bold: true,
                                        fontSize: '14',
                                        lineHeight: 1.4
                                    }
                                ],
                                [
                                    {
                                        text: this.seasonComparisonMode ? i18n.t('headers.workspace.fields.analytics.compare_seasons') + "\n" : "",
                                        color: "#878882",
                                        height: '24'
                                    },
                                    {
                                        text: ''
                                    }
                                ],
                                [
                                    {
                                        width: '50%',
                                        margin: [0, 0, 0, 0],
                                        table: {
                                            body: [
                                                [
                                                    {
                                                        text: this.seasonComparisonMode ? this.getCurrentSeason.name + "\n" : "",
                                                        bold: true,
                                                        fontSize: '14',
                                                        lineHeight: 1.4
                                                    },
                                                    {
                                                        svg: this.seasonComparisonMode ? '<svg width="20" height="8" viewBox="0 0 20 8" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="20" height="8" fill="#4B970F"/></svg>' : '<svg width="0" height="0" viewBox="0 0 16 16"></svg>',
                                                        margin: [2, 2, 2, 0]
                                                    },
                                                    {
                                                        svg: this.seasonComparisonMode ? '<svg width="20" height="8" viewBox="0 0 20 8" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="20" height="8" fill="#008CDB"/></svg>' : '<svg width="0" height="0" viewBox="0 0 16 16"></svg>',
                                                        margin: [2, 2, 2, 0]
                                                    },
                                                    {
                                                        svg: this.seasonComparisonMode ? '<svg width="20" height="8" viewBox="0 0 20 8" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="20" height="8" fill="#FFC700"/></svg>' : '<svg width="0" height="0" viewBox="0 0 16 16"></svg>',
                                                        margin: [2, 2, 2, 0]
                                                    },
                                                    {
                                                        svg: this.seasonComparisonMode ? '<svg width="20" height="8" viewBox="0 0 20 8" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="20" height="8" fill="#D41900"/></svg>' : '<svg width="0" height="0" viewBox="0 0 16 16"></svg>',
                                                        margin: [2, 2, 2, 0]
                                                    }
                                                ]
                                            ]
                                        },
                                        layout: 'noBorders'
                                    },
                                    {
                                        width: '50%',
                                        table: {
                                            body: [
                                                [
                                                    {
                                                        text: this.seasonComparisonMode ? this.secondSelectedSeason.name + "\n" : "",
                                                        bold: true,
                                                        fontSize: '14',
                                                        lineHeight: 1.4
                                                    },
                                                    {
                                                        svg: this.seasonComparisonMode ? '<svg width="20" height="8" viewBox="0 0 20 8" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="20" height="8" fill="#878882"/></svg>' : '<svg width="0" height="0" viewBox="0 0 16 16"></svg>',
                                                        margin: [2, 2, 2, 0]
                                                    }
                                                ]
                                            ]
                                        },
                                        layout: 'noBorders'
                                    }
                                ]
                            ]
                        },
                        layout: 'noBorders'
                    }
                ]
            },
            {
                text: [
                    {
                        text: i18n.t('headers.workspace.fields.analytics.by_crop') + " ",
                        fontSize: 16,
                        bold: true,
                        lineHeight: 1.4
                    },
                    {
                        text: this.AnalyticsData.crop_type,
                        fontSize: 16,
                        bold: true,
                        color: "#4B970F",
                        lineHeight: 1.4
                    }
                ]
            }
        ]

        this.changeIndicators('ndvi')
        sleep(2000).then(() => {
            this.indicatorGraph(content)
            sleep(100).then(() => {
                this.changeIndicators('gndvi')
                sleep(2000).then(() => {
                    this.indicatorGraph(content)
                    sleep(100).then(() => {
                        this.changeIndicators('ndmi')
                        sleep(2000).then(() => {
                            this.indicatorGraph(content)
                            sleep(100).then(() => {
                                this.changeIndicators('clgreen')
                                sleep(2000).then(() => {
                                    this.indicatorGraph(content)
                                    sleep(100).then(() => {
                                        content.push({
                                            text: i18n.t('headers.workspace.fields.analytics.graphs[0]'),
                                            bold: true,
                                            fontSize: 16,
                                            lineHeight: 1.6
                                        })

                                        if ( this.seasonComparisonMode ) {
                                            this.changeMeteoRatio('temperature')
                                            this.meteoRatioLables('temperature', content)
                                            sleep(2000).then(() => {
                                                this.getMeteoGraph(content)
                                                sleep(100).then(() => {
                                                    this.changeMeteoRatio('precipitation')
                                                    this.meteoRatioLables('precipitation', content)
                                                    sleep(2000).then(() => {
                                                        this.getMeteoGraph(content)
                                                        sleep(100).then(() => {
                                                            docDefinition.content = content
                                                            this.setLoadingDataNames('PrintingFieldAnalyticsData')
                                                            if ( action === 'print' ) {
                                                                makePDF(PdfActions.PRINT, docDefinition)
                                                            } else {
                                                                makePDF(PdfActions.DOWNLOAD, docDefinition)
                                                            }
                                                        })
                                                    })
                                                })
                                            })
                                        } else {
                                            this.meteoRatioLables('', content)
                                            sleep(2000).then(() => {
                                                this.getMeteoGraph(content)
                                                sleep(100).then(() => {
                                                    docDefinition.content = content
                                                    this.setLoadingDataNames('PrintingFieldAnalyticsData')
                                                    if ( action === 'print' ) {
                                                        makePDF(PdfActions.PRINT, docDefinition)
                                                    } else {
                                                        makePDF(PdfActions.DOWNLOAD, docDefinition)
                                                    }
                                                })
                                            })
                                        }
                                    })
                                })
                            })
                        })
                    })
                })
            })
        })
    }

    private close() {
        this.$router.push(`/cadastres/farm/${this.getFarmFromRoute.id}/subfields/${this.currentRoute.params.subfieldId}`)
    }
}
