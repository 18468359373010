




























import { Component, Vue, Emit } from 'vue-property-decorator'
import { httpPost, httpPut } from '@/util/http'
import { apiUrl } from '@/domain/constants'
import { GetterNames, MutationNames } from '@/store/types'
import { Getter, Mutation } from 'vuex-class'
import i18n from '@/i18n'
@Component
export default class NewAgregateModel extends Vue {
        @Getter(GetterNames.getCurrentRoute) private currentRoute !: any
    @Getter(GetterNames.getLoadingDataNames) private getLoadingDataNames!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    private isShown = false
    private isUpdate = false
    private folderForUpdate: any = null
    private folder: any = {
        name: '',
        onerror: ''
    }

    private update(folder: any) {
        this.isShown = true
        this.isUpdate = true
        this.folder = {
            name: folder.name,
            onerror: ''
        }
        this.folderForUpdate = folder
    }

    private show() {
        this.isShown = true
        this.isUpdate = false
        this.folder = {
            name: '',
            onerror: ''
        }
    }

    private addNewFolder() {
        if (this.checkForError()) return
        this.setLoadingDataNames('newFolderModelAddFolder')
        httpPost({
            url:  `${apiUrl}/yields/folder/`,
            isSecureRequest: true,
            detailResponse: true,
            body: {
                name: this.folder.name,
                group_user: this.currentRoute.params.farmId
            },
            onSuccess: json => {
                if (json.ok) {
                    this.$alert('Группа успешно добавлено', 'Сохранено', 'success')
                    this.$emit('newfolder', json.json)
                    this.hide()
                } else {
                    this.$alert('Ошибка сервера', 'Ошибка', 'error')
                }

            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('newFolderModelAddFolder')
            }
        })
    }

    private checkForError() {
        if (this.folder.name === '') {
            this.folder.onerror = 'Введите название'
            return true
        } else {
            return false
        }
    }

    private updateFolder() {
        if (this.checkForError()) return
        this.setLoadingDataNames('newFolderModelUpdateFolder')
        httpPut({
            url: `${apiUrl}/yields/folder/${this.folderForUpdate.id}/`,
            isSecureRequest: true,
            detailResponse: true,
            body: {
                name: this.folder.name
            },
            onSuccess: json => {
                if (json.ok) {
                    this.$alert('Группа успешно обновлено', 'Сохранено', 'success')
                    this.$emit('updatefolder', json.json)
                    this.hide()
                } else {
                    this.$alert('Ошибка сервера', 'Ошибка', 'error')
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('newFolderModelUpdateFolder')
            }
        })
    }

    private hide() {
        this.isShown = false
    }
}
