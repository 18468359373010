export default {
    ruler: "Ruler",
    zoom_in: "Zoom in",
    zoom_out: "Zoom out",
    loading: "Data is being uploaded..",
    layers: {
        title: "Layers",
        snow: "Map of a snow",
        soil: "The soil map",
        relief: "Relief map"
    },
    distance: "Distance",
    area: "Area",
    coordinate: "Coordinates",
    location: "My location",
    follow: "Follow",
    snapshot: "Picture",
    background: {
        title: "Basemap",
        satellite: "Satellite",
        scheme: "Scheme"
    },
    welcome: "Welcome, ",
    info: "Features of Egistic Limited are listed below",
    guide: {
        congratulations: "Congratulations",
        msg_final: "You have finished a small tutorial on getting to know your workspace! I wil be helping you with every section.",
        btn_final: "Let's start",
        indicators: "Indicators",
        msg_indicators: "Analyze your fields by four indices, identify problem areas and debris",
        btn_indicators: "Start a guide"
    }
}