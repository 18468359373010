export default {
    temperature: "Қызу..",
    humidity: "Ылғалдылық;..",
    wind: "Жел",
    rainfall: "Жауын-шашын",
    directions: {
        north: "С",
        north_east: "С-Ш",
        east: "Ш",
        south_east: "О-Ш",
        south: "О",
        south_west: "О-Б",
        west: "Б",
        north_west: "С-Б"
    }
}