import Cookies from 'js-cookie'
import {
    environment,
    mainDomain,
    loginUrl,
    landingUrl,
    farmUrl,
    consultingUrl,
    marketUrl,
    rabotaUrl
} from './environment'

const AUTH_HEADER_PREFIX = 'Token'
export const COOKIE_KEY_AUTH_HEADER = 'token'
export const PASSWORD_MIN_LENGTH = 6
export const ADVERTISEMENT_MODEL_HEADER = 'advertisementModel'
export const LAST_LANGUAGE_HAEDER = 'lastusedlanguage'
export const LAST_ORIGIN_HEADER = 'lastoriginheader'

export const imageEnvironment = 'https://map.egistic.kz/images'
export const baseUrl = `https://${environment}`
export const baseSocketUrl = `wss://${environment}`
export const apiUrl = `${baseUrl}/api/v1`
export const apiUrl2 = `${baseUrl}/api/v2`
export const apiUrl3 = `${baseUrl}/api/v3`

export const MIN_ZOOM = 8
export const MAX_ZOOM = 20
export const ASTANA_GEOLOCATION: number[] = [7952883, 6647522]
export const ASTANA_BOUNDARIES: number[] = [7944883, 6639522, 7960883, 6655522]

export const advertisementModel = true

export const isLoggedIn = () => {
    const authHeader = Cookies.get(COOKIE_KEY_AUTH_HEADER, { domain: mainDomain() })
    if (authHeader === undefined) return false
    return authHeader.indexOf(AUTH_HEADER_PREFIX) !== -1
}

export const clearCookies = () => {
    Cookies.remove(COOKIE_KEY_AUTH_HEADER)
    Cookies.remove(COOKIE_KEY_AUTH_HEADER, { domain: mainDomain() })
    Cookies.remove(ADVERTISEMENT_MODEL_HEADER, { domain: mainDomain() })
}

export const getAuthToken = () => {
    // console.log(Cookies.get(COOKIE_KEY_AUTH_HEADER, { domain: mainDomain() }))
    return Cookies.get(COOKIE_KEY_AUTH_HEADER, { domain: mainDomain() })
}

export const saveAuthHeaderToCookie = (token: string, time: number) => {
    clearCookies()
    Cookies.remove('token', { expires: 500, domain: 'farm.localhost.kz' })
    Cookies.set(
        COOKIE_KEY_AUTH_HEADER,
        `${AUTH_HEADER_PREFIX} ${token}`,
        { expires: time, domain: mainDomain() }
    )
}

export const openCrossOriginWebSite = (domain: string, path?: string) => {
    // Cookies.set('token', 'Token cec648424f6633417d29f24f4eb255cd6379556a') // Limited - prod
    // Cookies.set('token', 'Token e04b552f7c39e2eb15f662e9d2a173504891b977') // жокей агро - prod
    // Cookies.set('token', 'Token 1b9f01a647bbd8b146687e18d58e404c1be9e68e') // Erlan - debug
    // Cookies.set('token', 'Token 083a3f8eaccb645b4043609ef25b9a2191e45d9e') // Zhangos - debug
    // Cookies.set('token', 'Token 16ee0a1002424b07d48e2854a3f06e784b27fafb')  // жокей агро - debug
    let url = ''
    if (domain === 'landing') url = landingUrl()
    else if (domain === 'login') url = loginUrl()
    else if (domain === 'farm') url = farmUrl()
    else if (domain === 'consulting') url = consultingUrl()
    else if (domain === 'market') url = marketUrl()
    else if (domain === 'rabota') url = rabotaUrl()
    if (url) {
        Cookies.set(LAST_ORIGIN_HEADER, domain, { expires: 1800000, domain: mainDomain() })
        window.open(`${url}/${path ? path : ''}`, "_self")
    }
}

export const getAdvertisementModel = () => {
    const model = Cookies.get(ADVERTISEMENT_MODEL_HEADER, { domain: mainDomain() })
    if (model === undefined) return 'true'
    else return model
}

export const setAdvertisementModel = (val: string) => {
    Cookies.remove('token', { expires: 500, domain: 'farm.localhost.kz' })
    Cookies.set(ADVERTISEMENT_MODEL_HEADER, val, { domain: mainDomain() })
}

export const setLastLanguage = (val: string) => {
    Cookies.remove('token', { expires: 500, domain: 'farm.localhost.kz' })
    Cookies.set(LAST_LANGUAGE_HAEDER, val, { expires: 1800000, domain: mainDomain() })
}

export const getLastLanguage = () => {
    const language = Cookies.get(LAST_LANGUAGE_HAEDER, { domain: mainDomain() })
    if (language === undefined) {
        const windowNavigator: any = window.navigator
        const userLang: any = windowNavigator.language || windowNavigator.userLanguage
        return userLang.substring(0, 2)
    } else return language
}