

































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { GetterNames, MutationNames } from '@/store/types'
import { Getter, Mutation } from 'vuex-class'
import FieldSlider from './FieldSlider.vue'
import { httpPost } from '@/util/http'
import { apiUrl2, imageEnvironment } from '@/domain/constants'
@Component({
    components: {
        FieldSlider
    }
})
export default class FieldRequests extends Vue {
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: any
    @Getter(GetterNames.getFarmFromRoute) private getFarmFromRoute!: any
    @Getter(GetterNames.getMapData) private mapData !: any
    @Getter(GetterNames.getSubfieldIndicatorData) private getSubfieldIndicatorData!: any
    @Getter(GetterNames.getFieldRequestComparisonMode) private getFieldRequestComparisonMode !: boolean
    @Getter(GetterNames.getSwiperPositionX) private swiperPositionX!: number
    @Getter(GetterNames.getIsProblemZonesMode) private getIsProblemZonesMode!: any
    @Getter(GetterNames.getWeedinessImage) private getWeedinessImage!: any
    @Getter(GetterNames.getLegendCoordinatesArray) private legendCoordinatesArray !: number[][][]
    @Getter(GetterNames.panelsState) private panelsState!: any
    @Mutation(MutationNames.setSubfieldIndicatorData) private setSubfieldIndicatorData!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames !: any
    @Mutation(MutationNames.setLegendCoordinatesArray) private setLegendCoordinatesArray!: any
    private boundaries: any = [0, 0, 0, 0]
    private problemZones: any = []
    private problemZoneRequestId = -1
    private firstAlert = false
    private colorArray = ['rgb(255,0,0)', 'rgb(0,0,255)']

    get currentBbox() {
        const bbox = []
        if (this.getFieldRequestComparisonMode) {
            bbox.push(this.swiperPositionX)
        } else {
            bbox.push(this.getSubfieldIndicatorData.currentBbox ? this.getSubfieldIndicatorData.currentBbox[0] : this.boundaries[0])
        }
        bbox.push(this.getSubfieldIndicatorData.currentBbox ? this.getSubfieldIndicatorData.currentBbox[1] : this.boundaries[1])
        bbox.push(this.getSubfieldIndicatorData.currentBbox ? this.getSubfieldIndicatorData.currentBbox[2] : this.boundaries[2])
        bbox.push(this.getSubfieldIndicatorData.currentBbox ? this.getSubfieldIndicatorData.currentBbox[3] : this.boundaries[3])
        return bbox
    }

    get secondBbox() {
        const bbox = []
        bbox.push(this.getSubfieldIndicatorData.secondBbox ? this.getSubfieldIndicatorData.secondBbox[0] : this.boundaries[0])
        bbox.push(this.getSubfieldIndicatorData.secondBbox ? this.getSubfieldIndicatorData.secondBbox[1] : this.boundaries[1])
        bbox.push(this.swiperPositionX)
        bbox.push(this.getSubfieldIndicatorData.secondBbox ? this.getSubfieldIndicatorData.secondBbox[3] : this.boundaries[3])
        return bbox
    }

    private mounted() {
        this.getDangerPoints()
        this.getBoundaries()
    }

    private getImageUrl(imageUrl: any) {
        return `${imageEnvironment}${imageUrl.substring(16)}`
    }

    @Watch('currentRoute')
    private getBoundaries() {
        this.setLegendCoordinatesArray([])
        const fieldId = this.currentRoute.params.subfieldId
        if (!fieldId) return
        const field = this.getFarmFromRoute.fields.find((x: any) => x.id.toString() === fieldId.toString())
        let Xmin = Number.MAX_SAFE_INTEGER
        let Xmax = Number.MIN_SAFE_INTEGER
        let Ymin = Number.MAX_SAFE_INTEGER
        let Ymax = Number.MIN_SAFE_INTEGER
        if ( field.geom) {
          for (const arr of field.geom.coordinates[0][0]) {
            Xmax = arr[0] > Xmax ? arr[0] : Xmax
            Xmin = arr[0] < Xmin ? arr[0] : Xmin
            Ymax = arr[1] > Ymax ? arr[1] : Ymax
            Ymin = arr[1] < Ymin ? arr[1] : Ymin
          }
        }
        this.boundaries = [Xmin, Ymin, Xmax, Ymax]
    }

    private checkIsLayerCreated(requestId: number) {
        if (!this.currentRoute.params.subfieldId) return false
        if (this.currentRoute.params.subfieldId === this.getSubfieldIndicatorData.fieldId) {
            if (this.getSubfieldIndicatorData.data.find((x: any) => x.id === requestId)) {
                return this.getSubfieldIndicatorData.data.find((x: any) => x.id === requestId).is_layer_created
            }
        }
        return false
    }

    @Watch('currentRoute')
    private ifCurrentRouteChanged() {
        this.getDangerPoints()
    }

    @Watch('getSubfieldIndicatorData.currentRequestId')
    private checkDateChange() {
        this.getDangerPoints()
    }

    @Watch('getIsProblemZonesMode')
    private getDangerPoints() {
        if (!this.getIsProblemZonesMode) return
        const request = this.getSubfieldIndicatorData.data.find((x: any) => x.id === this.getSubfieldIndicatorData.currentRequestId)
        if (request) {
            if (!request.is_present) return
        }
        const fieldId = this.currentRoute.params.subfieldId
        if (!fieldId) return
        this.problemZones = []
        this.setLoadingDataNames('fieldRequestsGetDangerPoints')
        httpPost({
            url: `${apiUrl2}/get/danger/points/new/`,
            isSecureRequest: true,
            body: {
                divided_cad_user: Number(fieldId),
                date: this.getDangerPointDate()
            },
            onSuccess: json => {
                if (json.geometry) {
                    this.problemZones = json.geometry.features
                    this.problemZoneRequestId = this.getSubfieldIndicatorData.currentRequestId
                    if (!this.firstAlert) {
                        this.setSubfieldIndicatorData({alertProblemZones: true})
                        this.firstAlert = true
                    }
                } else {
                    this.problemZones = false
                    this.problemZoneRequestId = -1
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('fieldRequestsGetDangerPoints')
            }
        })
    }

    private getDangerPointDate() {
        const request = this.getSubfieldIndicatorData.data.find((x: any) => x.id === this.getSubfieldIndicatorData.currentRequestId)
        return request.actual_date.substring(0, 10)
    }
}
