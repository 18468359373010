import SearchResult from '@/views/MainPage/HomeView/Sideboards/RegisterFarm/SearchOnMap/SearchResult.vue'
import FarmOrderData from '@/views/MainPage/HomeView/Sideboards/FarmBoard/OrderData/OrderData.vue'
import FarmBill from '@/views/MainPage/HomeView/Sideboards/FarmBoard/OrderData/Bill.vue'
import RegisterFarm from '@/views/MainPage/HomeView/Sideboards/RegisterFarm/RegisterFarm.vue'

const routes = [
    {
        path: 'registercadastre',
        name: 'registercadastre',
        component: RegisterFarm
    },
    {
        path: 'searchonmap/:regionId/:regionIndex',
        name: 'searchonmap',
        component: SearchResult
    },
    {
        path: '/farm/orderdata/:status',
        name: 'farmorderdata',
        component: FarmOrderData
    },
    {
        path: '/farm/bill',
        name: 'farmbill',
        component: FarmBill
    }
]

export default routes