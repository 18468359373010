



















































































































































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator'
import { Getter, Action, Mutation } from 'vuex-class'
import { GetterNames, ActionNames, MutationNames } from '@/store/types'
import { Route } from 'vue-router'
import { httpGet, httpPost, httpDelete } from '@/util/http'
import { baseUrl, apiUrl } from '@/domain/constants'
import Farm from '@/models/Farm'
import { categories } from '@/data/agroChemCategories'
import i18n from '@/i18n'
import {egisticLogo, pdfWaterMark} from '@/data/pdfImageData'

@Component
export default class FieldAgroChem extends Vue {
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: Route
    @Getter(GetterNames.getFarmFromRoute) private getFarmFromRoute!: Farm
    @Getter(GetterNames.getAgroChemFieldResult) private getAgroChemFieldResult!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    @Mutation(MutationNames.setAgroChemFieldResult) private setAgroChemFieldResult!: any
    private egisticLogo: any = egisticLogo
    private pdfWaterMark: any = pdfWaterMark
    private resultCategories = categories
    private agroChemList: any = {
        data: [],
        selected: {
            created_at: '',
            id: 0
        },
        dropdown: false
    }

    get currentFieldId() {
        return this.currentRoute.params.subfieldId
    }

    private getUrl(url: any) {
        return `${baseUrl}${url}`
    }

    @Watch('currentFieldId')
    private onchangeField(val: any, old: any) {
        if (val && val !== old) {
            this.getData()
        }
    }

    private normalizeDate(date: any) {
        if (date === '') {
            return i18n.t('general.dates.format_date')
        } else {
            return date.substring(8, 10) + '.' + date.substring(5, 7) + '.' + date.substring(0, 4)
        }
    }

    private mounted() {
        this.getData()
    }

    private getData() {
        this.getAgroAnalysisList(this.currentRoute.params.subfieldId)
    }

    private setSelectedAgroAnalysis(result: any) {
        if (this.agroChemList.selected.id !== result.id) {
            this.agroChemList.selected = result
            this.getAgroAnalysisResult(this.currentRoute.params.subfieldId, result.id)
        }
        this.closeAgroChemListDropdown()
    }

    private currentIndicator() {
        return this.resultCategories[this.getAgroChemFieldResult.selectedCategory]
    }

    private changeAgroChemCategory(category: any) {
        this.setAgroChemFieldResult({selectedCategory: category})
    }

    private getAgroChemValue(category: any) {
        const propertyArr = ['ph', 'phosphorus', 'potassium', 'nitrogen', 'sulfur', 'humus']
        const value = this.getAgroChemFieldResult.analysisResult[propertyArr[category]]
        if (value) {
            return value.toFixed(2)
        } else {
            return '0'
        }
    }

    private getAgroChemColor(categoryIndex: any) {
        const category: any = this.resultCategories[categoryIndex]
        const value = Number(this.getAgroChemValue(categoryIndex))
        for (const row of category.indicators) {
            if (row.lower === null) {
                if (Number(row.upper) > value) return row.color
            }
            if (row.upper === null) {
                if (Number(row.lower) <= value) return row.color
            }
            if (row.upper && row.lower) {
                if (Number(row.upper) > value) return row.color
            }
        }
    }

    private rangeToString(lower: any, upper: any) {
        if (lower === null) {
            return '< ' + upper
        } else if (upper === null) {
            return '> ' + lower
        } else {
            return lower + ' - ' + upper
        }
    }

    private getAgroAnalysisList(fieldId: any) {
        this.setAgroChemFieldResult({
            selectedField: 0,
            analysisResult: null,
            selectedCategory: 0
        })
        this.setLoadingDataNames('FieldAgroChemGetAgroChemList')
        httpGet({
            url: `${apiUrl}/agro_analysis/short/by/field/${fieldId}/`,
            isSecureRequest: true,
            onSuccess: json => {
                this.agroChemList.data = json
                if (json.length > 0) {
                    if (json[0].hasOwnProperty('id')) {
                        this.agroChemList.selected = json[0]
                        this.getAgroAnalysisResult(fieldId, this.agroChemList.selected.id)
                    }
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('FieldAgroChemGetAgroChemList')
            }
        })
    }

    private getAgroAnalysisResult(subfieldId: any, id: any) {
        this.setLoadingDataNames('FieldAgroChemGetAgroChem')
        httpGet({
            url: `${apiUrl}/agro_analysis/agro/analys/by/id/${id}/`,
            isSecureRequest: true,
            onSuccess: json => {
                if (json.length === 0) {
                    this.setAgroChemFieldResult({
                        selectedField: subfieldId,
                        analysisResult: null
                    })
                } else {
                    this.setAgroChemFieldResult({
                        selectedField: subfieldId,
                        analysisResult: json
                    })
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('FieldAgroChemGetAgroChem')
            }
        })
    }

    private closeAgroChemListDropdown() {
        this.agroChemList.dropdown = false
    }

    private openUploadAgroChem() {
        this.$router.push({
            name: 'uploadagrochemresult',
            params: {
                farmId: this.currentRoute.params.farmId
            }
        })
    }

    private openOrderAgroChem() {
        this.$router.push(`/orderagrochem/${this.currentRoute.params.farmId}`)
    }

    private close() {
        this.$router.push({
            name: 'subfield',
            params: {
                farmId: this.currentRoute.params.farmId,
                subfieldId: this.currentRoute.params.subfieldId
            }
        })
    }
}
