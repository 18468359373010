export const operationData = [
    {
        title: 'Приход',
        header: 'Тип прихода',
        placeholder: 'Выберите тип прихода',
        types: [
            {
                index: 1,
                title: 'Поставщик',
                header: 'Поставщик',
                placeholder: 'Введите поставщика',
                dropdown: false
            },
            {
                index: 2,
                title: 'Сотрудник',
                header: 'Сотрудник',
                placeholder: 'Выберите сотрудника',
                dropdown: true
            },
            {
                index: 3,
                title: 'Возврат покупателя',
                header: 'Клиент',
                placeholder: 'Введите ФИО покупателя',
                dropdown: false
            },
            {
                index: 4,
                title: 'Остатки с мероприятия',
                header: 'Мероприятие',
                placeholder: 'Введите мероприятие',
                dropdown: false
            }
        ]
    },
    {
        title: 'Расход',
        header: 'Тип расхода',
        placeholder: 'Выберите тип расхода',
        types: [
            {
                index: 1,
                title: 'Сотрудник',
                header: 'Сотрудник',
                placeholder: 'Выберите сотрудника',
                dropdown: true
            },
            {
                index: 2,
                title: 'Покупатель',
                header: 'Покупатель',
                placeholder: 'Введите ФИО покупателя',
                dropdown: false
            },
            {
                index: 3,
                title: 'Заправка',
                header: 'Техника',
                placeholder: 'Выберите технику',
                dropdown: true
            },
            {
                index: 4,
                title: 'Обслуживание техники',
                header: 'Техника',
                placeholder: 'Выберите технику',
                dropdown: true
            }
        ]
    },
    {
        title: 'Перемещение',
        header: 'Склад - получатель',
        placeholder: 'Выберите склад',
        types: []
    },
    {
        title: 'Возврат',
        header: 'Поставщик',
        placeholder: 'Введите поставщика',
        types: []
    },
    {
        title: 'Списание',
        header: 'Дата',
        placeholder: 'ДД/ММ/ГГ',
        types: []
    }
]

export const unitsData = [
    {
        type: 'mass',
        types: [
            {
                name: 'г.',
                amount: 1000000
            },
            {
                name: 'кг.',
                amount: 1000
            },
            {
                name: 'ц.',
                amount: 10
            },
            {
                name: 'т.',
                amount: 1
            }
        ]
    },
    {
        type: 'capacity',
        types: [
            {
                name: 'мл.',
                amount: 1000
            },
            {
                name: 'л.',
                amount: 1
            }
        ]
    },
    {
        type: 'amount',
        types: [
            {
                name: 'шт.',
                amount: 1
            }
        ]
    }
]