



























































import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter, Mutation } from 'vuex-class'
import { ActionNames, GetterNames, MutationNames } from '@/store/types'
import { httpGet } from '@/util/http'
import { apiUrl } from '@/domain/constants'
import i18n from '@/i18n'
import {egisticLogo, pdfWaterMark} from '@/data/pdfImageData'
import NoteMention from './components/NoteMention.vue'
import ImageResponses from './components/ImageResponses.vue'
@Component({
    components: {
        NoteMention,
        ImageResponses
    }
})
export default class NotificationMessages extends Vue {
    @Action(ActionNames.updateMapDataInRegion) private updateMapDataInRegion!: any
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: any
    @Getter(GetterNames.getMapData) private getMapData!: any
    @Getter(GetterNames.getSidebarModel) private getSidebarModel!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    @Mutation(MutationNames.setNotificationData) private setNotificationData!: any
    private egisticLogo: any = egisticLogo
    private pdfWaterMark: any = pdfWaterMark
    private message: any = null

    private getButtonText() {
        if (this.message) {
            if (this.message.perm_name === 'notice_mention') {
                return i18n.t('headers.workspace.dispatcher.msg_actions.to_notes')
            } else if (this.message.perm_name === 'notice_reply') {
                return i18n.t('headers.workspace.dispatcher.msg_actions.to_notes')
            } else if (this.message.perm_name === 'workspace_change') {
                return i18n.t('headers.workspace.dispatcher.msg_actions.to_projects')
            } else if (this.message.perm_name === 'response_new_image') {
                return i18n.t('headers.workspace.dispatcher.msg_actions.to_farm')
            } else if (this.message.perm_name === 'weather_draught') {
                return i18n.t('headers.workspace.dispatcher.msg_actions.to_farm')
            }
        }
        return ''
    }

    private getMessageTime() {
        if (this.message) {
            const date = this.message.created_at
            if (date) {
                return date.substring(8, 10) + '.' + date.substring(5, 7) + '.' + date.substring(0, 4) + ', ' +
                date.substring(11, 16)
            }
        }
    }

    private openNotificationSource() {
        if (this.message) {
            if (this.message.perm_name === 'notice_mention' ||
                this.message.perm_name === 'notice_reply') {
                this.$router.push({
                    name: 'subfieldnotedetails',
                    params: {
                        farmId: this.message.group_user.id.toString(),
                        subfieldId: this.message.divided_cadastre_users[0].toString(),
                        noteId: this.message.act.id
                    }
                })
            } else if (this.message.perm_name === 'workspace_change') {
                this.$router.push({
                    name: 'workspace'
                })
            } else if (this.message.perm_name === 'response_new_image') {
                this.$router.push({
                    name: 'farm',
                    params: {
                        farmId: this.message.group_user.id.toString()
                    }
                })
            } else if (this.message.perm_name === 'weather_draught') {
                this.$router.push({
                    name: 'farm',
                    params: {
                        farmId: this.message.group_user.id.toString()
                    }
                })
            }
        }
    }

    private getCategiryColor(category: any) {
        if (category === 3) {
            return '#E0E2D8'
        } else if (category === 2) {
            return '#D51900'
        } else {
            return '#008CDB'
        }
    }

    private mounted() {
        this.setNotificationData({message: null})
        this.getCurrentMessage()
    }

    private getCurrentMessage() {
        if (this.currentRoute.params.messageId) {
            const messageId = this.currentRoute.params.messageId
            this.setLoadingDataNames('notificationMessagesGetMessage')
            httpGet({
                url:  `${apiUrl}/notifications/msg/${messageId}/`,
                isSecureRequest: true,
                onSuccess: json => {
                    this.message = json
                    if (json.group_user) {
                        this.updateMapDataInRegion({
                            boundaries: json.group_user.geom_info.bbox
                        })
                    }
                    this.setNotificationData({message: json})
                },
                onError: error => {
                    console.log(error)
                },
                doFinally: () => {
                    this.setLoadingDataNames('notificationMessagesGetMessage')
                }
            })
        }
    }

    private close() {
        this.$router.push({
            name: 'notifications',
            params: {
                info: this.currentRoute.params.info ? this.currentRoute.params.info : null
            }
        })
    }
}
