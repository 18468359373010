





































































import { Component, Vue, Prop, Watch} from 'vue-property-decorator'
import { GetterNames } from '@/store/types'
import { Getter } from 'vuex-class'
import { imageEnvironment } from '@/domain/constants'
import { ndviGistogram, ndmiGistogram, clgreenGistogram } from '@/data/gistogramData'
import { CultureColors } from '@/data/FieldCulture'
import i18n from '@/i18n'

@Component
export default class FarmFields extends Vue {
    @Getter(GetterNames.getMapData) private mapData !: any
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: any
    @Getter(GetterNames.getFarmFromRoute) private getFarmFromRoute!: any
    @Getter(GetterNames.getFarmMapControllers) private getFarmMapControllers!: any
    @Getter(GetterNames.getFieldsRating) private getFieldsRating!: any
    @Getter(GetterNames.getRulerMode) private getRulerMode!: any
    @Getter(GetterNames.getChroniclePanel) private getChroniclePanel!: any
    @Getter(GetterNames.getCurrentFarmIndicators) private getCurrentFarmIndicators!: any
    @Prop() private fields!: any
    @Prop() private vlView!: any
    private textScale = 1
    private CultureColors = CultureColors
    private otherCultures: any = []
    private selectedFeatures: any[] = []

    private getIsFieldVisible(fieldId: any) {
        if (this.currentRoute.name === 'farm' || this.currentRoute.name === 'farmtelematics') {
            return this.getFarmMapControllers.selectedFields.includes(fieldId)
        } else return true
    }

    private getFieldFillText(field: any) {
        if (this.currentRoute.name === 'editfieldpassport') {
            if (this.currentRoute.params.subfieldId === field.id.toString()) {
                return ''
            }
        }
        if (this.currentRoute.name === 'farm' || this.currentRoute.name === 'farmtelematics') {
            if (this.getFarmMapControllers.textType === 0) {
                return `№${field.name}\n${Math.round(field.area / 100) / 100} ${i18n.t('general.units.he')}`
            } else if (this.getFarmMapControllers.textType === 1) {
                return ''
            } else if (this.getFarmMapControllers.textType === 2) {
                return `№${field.name}`
            } else if (this.getFarmMapControllers.textType === 3) {
                return field.passport.culture ?
                    `${field.passport.culture.substring(0, field.passport.culture.indexOf(' '))}\n${field.passport.culture.substring(field.passport.culture.indexOf(' ') + 1)}` : ''
            } else if (this.getFarmMapControllers.textType === 4) {
                return `${Math.round(field.area / 100) / 100}га`
            } else {
                return ''
            }
        } else {
            return `№${field.name}\n${Math.round(field.area / 100) / 100} ${i18n.t('general.units.he')}`
        }
    }

    private getFillColor(field: any) {
        if (this.currentRoute.name === 'farm' || this.currentRoute.name === 'techmap' || this.currentRoute.name === 'farmtelematics') {
            return this.getColor(field)
        } else {
            if (this.currentRoute.name === 'subfieldsrating' && this.mapData.activeIndicator.length === 0) {
                const result = this.getFieldsRating.result.find((x: any) => x.field_id === field.id)
                if (result) {
                    if (this.getFieldsRating.indicator === 'ndvi' || this.getFieldsRating.indicator === 'gndvi') {
                        for (const i of ndviGistogram) {
                            if (result.index.mean >= i.from && result.index.mean <= i.to) return i.color
                        }
                    } else if (this.getFieldsRating.indicator === 'ndmi') {
                        for (const i of ndmiGistogram) {
                            if (result.index.mean >= i.from && result.index.mean <= i.to) return i.color
                        }
                    } else if (this.getFieldsRating.indicator === 'clgreen') {
                        for (const i of clgreenGistogram) {
                            if (result.index.mean >= i.from && result.index.mean <= i.to) return i.color
                        }
                    }
                } else return 'rgba(255,255,255,0)'
            } else return 'rgba(255,255,255,0)'
        }

    }

    private getColor(field: any) {
        if (this.currentRoute.name === 'farm' || this.currentRoute.name === 'techmap' || this.currentRoute.name === 'farmtelematics') {
            if (this.getFarmMapControllers.fill) {
                const parentCulture = field.passport.parent_culture
                const result = this.CultureColors.defined.find((x: any) => {
                    const crop = x.crop.toLowerCase()
                    if (parentCulture && (parentCulture.toLowerCase().includes(crop) || crop.includes(parentCulture.toString().toLowerCase()))) {
                        return x
                    }
                })
                if (result) {
                    return result.color
                }
                const result2 = this.otherCultures.find((x: any) => {
                    if (x.culture && parentCulture) {
                        if (x.culture.toLowerCase().includes(parentCulture.toLowerCase()) ||
                            parentCulture.toLowerCase().includes(x.culture.toLowerCase())) {
                            return x
                        }
                    } else if (x.culture === parentCulture || (x.culture === '' && parentCulture === null)
                        || (x.culture === null && parentCulture === '')) {
                        return x
                    }
                })
                if (result2) {
                    return this.CultureColors.others[result2.index]
                } else {
                    if (this.otherCultures.length > 0) {
                        const index = this.otherCultures[this.otherCultures.length - 1].index
                        this.otherCultures.push({
                            culture: parentCulture,
                            index: (index + 1) % 10
                        })
                        return this.CultureColors.others[index + 1]
                    } else {
                        this.otherCultures.push({
                            culture: parentCulture,
                            index: 0
                        })
                        return this.CultureColors.others[0]
                    }
                }
            } else {
                const result = this.getCurrentFarmIndicators.data.find((x: any) => x.id === field.id)
                if (result) {
                    if (result.images_urls === null) return '#999999'
                    else return 'rgba(255, 255, 255, 0)'
                } else return 'rgba(255, 255, 255, 0)'
            }
        } else return 'rgba(255, 255, 255, 0)'
    }

    private getStrokeWidth() {
        if (this.currentRoute.name === 'farm' || this.currentRoute.name === 'farmtelematics') {
            return Number(this.getFarmMapControllers.strokeWidth)
        } else return 1
    }

    private getStrokeColor() {
        if (this.currentRoute.name === 'farm' || this.currentRoute.name === 'farmtelematics') {
            return this.getFarmMapControllers.strokeColor
        } else return '#FFFFFF'
    }

    private getTextColor() {
        if (this.currentRoute.name === 'farm' || this.currentRoute.name === 'farmtelematics') {
            return this.getFarmMapControllers.textColor
        } else return '#FFFFFF'
    }

    private getImageUrl(imageUrl: any) {
        return `${imageEnvironment}${imageUrl.substring(16)}`
    }

    @Watch('mapData.zoom')
    private zoomChanged(currentZoom: number) {
        this.textScale = this.mapZoomToTextScale(currentZoom)
    }

    private isSelectedField(id: any) {
        if (this.currentRoute.params.subfieldId) {
            return this.currentRoute.params.subfieldId === id.toString()
        }
        return false
    }

    @Watch('getFarmMapControllers.textMaxScale')
    private onchangeMaxScale(val: any, old: any) {
        if (val && val !== old) {
            this.textScale = this.mapZoomToTextScale(this.mapData.zoom)
        }
    }

    private mapZoomToTextScale(currentZoom: number) {
        let maxScale = 3
        if (this.currentRoute.name === 'farm') {
            maxScale = this.getFarmMapControllers.textMaxScale
        }
        const minScale = 1
        const zoomRatio = (currentZoom - this.mapData.MIN_ZOOM - 2) / (this.mapData.MAX_ZOOM - this.mapData.MIN_ZOOM)
        return zoomRatio * (maxScale - minScale) + minScale
    }

    @Watch('selectedFeatures')
    private setChosenCadastreField(features: any, oldFeatures: any) {
        if (features.length === 0) return
        if (this.$route.name !== 'registercadastre') {
            this.handleFieldsClick(features)
        }
    }

    private handleFieldsClick(features: any[]) {
        if (this.getChroniclePanel.status) return
        if (features === null) return
        if (this.currentRoute.name !== 'cadastre' && this.currentRoute.name !== 'subfield'
            && this.currentRoute.name !== 'subfields' && !this.currentRoute.params.subfieldId
            && this.currentRoute.name !== 'farm' && this.currentRoute.name !== 'subfieldsrating'
            && this.currentRoute.name !== 'fieldagrochem' && this.currentRoute.name !== 'cadastreinfo') return
        if (features.length > 0) {
            if (features[0].properties === null) return
            const currentFeatureProps = features.pop().properties
            if (currentFeatureProps && currentFeatureProps.type === 'cadastre') {
              this.vlView.animate({
                center: this.getFarmFromRoute.getCenter(currentFeatureProps.id.toString()),
                zoom: Math.floor(this.getFarmFromRoute.getZoom(currentFeatureProps.id.toString())),
                duration: 800
              })
            } else if (currentFeatureProps && currentFeatureProps.type === 'field') {
                if (this.getFarmFromRoute) {
                    if (this.currentRoute.name === 'subfield' || this.currentRoute.name === 'subfields') {
                        this.$router.push(`/cadastres/farm/${this.getFarmFromRoute.id}/subfields/${currentFeatureProps.id}`).catch(err => { /* */})
                    } else if (this.currentRoute.name === 'subfieldindicators') {
                        this.$router.push(`/cadastres/farm/${this.getFarmFromRoute.id}/subfields/${currentFeatureProps.id}/indicators`).catch(err => { /* */})
                    } else if (this.currentRoute.name === 'farm') {
                        this.$router.push(`/cadastres/farm/${this.getFarmFromRoute.id}/subfields/${currentFeatureProps.id}`).catch(err => { /* */})
                    } else if (this.currentRoute.name === 'fieldagrochem') {
                        this.$router.push(`/cadastres/farm/${this.getFarmFromRoute.id}/subfields/${currentFeatureProps.id}/agrochem`).catch(err => { /* */})
                    } else if (this.currentRoute.name === 'subfieldsrating') {
                        this.vlView.animate({
                            center: this.getFarmFromRoute.getCenter(currentFeatureProps.id.toString()),
                            zoom: Math.floor(this.getFarmFromRoute.getZoom(currentFeatureProps.id.toString())),
                            duration: 800
                        })
                    } else if (this.currentRoute.name === 'subfieldyield') {
                        this.$router.push(`/cadastres/farm/${this.getFarmFromRoute.id}/subfields/${currentFeatureProps.id}/yield`).catch(err => { /* */})
                    }
                }
            }
        }
    }
}
