












































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { httpGet } from '@/util/http'
import { apiUrl } from '@/domain/constants'
import { GetterNames, MutationNames } from '@/store/types'
import { Getter, Mutation } from 'vuex-class'
import EventsContainer from '@/views/MainPage/HomeView/Sideboards/FarmBoard/TelematicsBoard/FieldTelematics/components/EventsContainer.vue'
@Component({
    components: {
        EventsContainer
    }
})
export default class FieldEventsView extends Vue {
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: any
    @Getter(GetterNames.getLoadingDataNames) private getLoadingDataNames!: any
    @Getter(GetterNames.getFarmFromRoute) private getFarmFromRoute!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    @Mutation(MutationNames.setTelematicsInfo) private setTelematicsInfo!: any
    @Mutation(MutationNames.setMainMapCenter) private setMainMapCenter!: any
    @Mutation(MutationNames.setMainMapZoom) private setMainMapZoom!: any
    @Mutation(MutationNames.setIsDataLoading) private setIsDataLoading !: any
    private searchText = ''
    private fields: any = []
    private viewFieldID = -1

    private mounted() {
        this.fetchEvents()
    }

    private getFieldList() {
      const searchValue = this.searchText.replace('№', '').trim()
      const searchResult = []
      for (const field of this.fields) {
        if (field.name.toLowerCase().includes(searchValue.toLowerCase()) ) {
          searchResult.push(field)
        }
      }
      searchResult.sort((a: any, b: any) => a.name[0] - b.name[0])
      return searchResult
    }

    private fetchEvents() {
        this.clearTelematics()
        this.setLoadingDataNames('FieldEventsViewGetFields')
        httpGet({
            url:  `${apiUrl}/telematics/by/kletka/group_user/${this.currentRoute.params.farmId}/`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                if (json.ok) {
                    this.fields = json.json
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('FieldEventsViewGetFields')
            }
        })
    }

    private clearTelematics() {
        this.setTelematicsInfo({
            geozone: {
                data: null,
                view: false,
                edit: 0,
                type: 'edit'
            },
            folders: [],
            tractorsData: [],
            autoplay: false,
            tracktime: 0,
            autoTracking: {
                mainTractorID: -1,
                tracking: false
            },
            arrowsView: false,
            speedLimitsView: false,
            stopsView: false,
            fieldEvent: {
                reportType: 1,
                event: null,
                tractor: null,
                flaws: false,
                overlaps: false
            },
            controlPanelExtension: false
        })
        this.setIsDataLoading(false)
    }

    private toogleFieldEvents(id: any) {
        this.clearTelematics()
        if (this.viewFieldID === id) {
            this.viewFieldID = -1
        } else {
            this.viewFieldID = id
            if (this.getFarmFromRoute) {
                this.setMainMapZoom(this.getFarmFromRoute.getZoom(id) - 1)
                this.setMainMapCenter(this.getFarmFromRoute.getCenter(id))
            }
        }
    }

    private openFieldTelematics(id: any) {
        this.$router.push(`/cadastres/farm/${this.getFarmFromRoute.id}/subfields/${id}/telematics`)
    }

}
