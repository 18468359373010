

























import { Component, Vue } from 'vue-property-decorator'
import Sideboard from '@/components/Sideboard.vue'
import {GetterNames, MutationNames} from '@/store/types'
import {Getter, Mutation} from 'vuex-class'
import { Route } from 'vue-router'
import Farm from '@/models/Farm'
import i18n from '@/i18n'
import EventsContainer from './components/EventsContainer.vue'
@Component({
    components: {
      Sideboard,
      EventsContainer
    }
})
export default class FieldTelematics extends Vue {
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: Route
    @Getter(GetterNames.getFarmFromRoute) private getFarmFromRoute!: Farm
    @Mutation(MutationNames.setTelematicsInfo) private setTelematicsInfo!: any

    get field() {
        if (this.getFarmFromRoute) {
            return this.getFarmFromRoute.fields.find((x: any) => x.id === parseInt(this.$route.params.subfieldId, 10))
        }
        return false
    }

    private mounted() {
      this.setTelematicsInfo({
        geozone: {
          data: null,
          view: false,
          edit: 0,
          type: 'edit'
        },
        folders: [],
        tractorsData: [],
        autoplay: false,
        tracktime: 0,
        autoTracking: {
          mainTractorID: -1,
          tracking: false
        },
        arrowsView: false,
        speedLimitsView: false,
        stopsView: false,
        fieldEvent: {
          reportType: 1,
          event: null,
          tractor: null,
          flaws: false,
          overlaps: false
        },
        controlPanelExtension: false
      })
    }

    private openFields() {
        this.$router.back()
        // this.$router.push(`/cadastres/farm/${this.getFarmFromRoute.id}/subfields/${this.currentRoute.params.subfieldId}`)
    }
}
