





































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { CultureReproduction } from '@/data/FieldCulture'
import { httpGet } from '@/util/http'
import { apiUrl } from '@/domain/constants'
import i18n from '@/i18n'
@Component
export default class CultureChoose extends Vue {
    @Prop({default: '341px'}) private width!: string
    @Prop({default: ''}) private onerror!: string
    private isDropdownVisible = false

    private FieldCultureData: any = []
    private CultureReproduction: any = CultureReproduction
    private cultureSearch = ''
    private fieldCulture = ''
    private fieldType = ''
    private fieldSort = ''
    private fieldReproduction = ''
    private addednewCulture = ''
    private TypeDropdown = false
    private SortDropdown = false
    private reproductionDropdown = false
    private addOwnCultureClass = false
    private isDataLoading = false

    get getIsButtonActive() {
        return this.fieldType !== ''
    }

    get getSorts() {
        const culture = this.FieldCultureData.find((x: any) => {
            if (x.name === this.fieldCulture
            || this.fieldCulture.includes(x.name)
            || this.fieldType.includes(x.name)) {
                return true
            } else return false
        })
        if (culture) {
            const type = culture.cultures.find((x: any) => x.name === this.fieldType)
            if (type) {
                return type.sorts
            } else return []
        } else return []
    }

    get getCulture() {
        const reproduction = this.CultureReproduction.find((x: any) => x.name === this.fieldReproduction)
        const sort = this.addOwnCultureClass ? this.addednewCulture : this.fieldSort
        return `${this.fieldType ? this.fieldType : ''}${sort ? ` • ${sort}` : ''}${reproduction ? ` • ${reproduction.short}` : ''}`
    }

    private getData() {
        return {
            parent_culture: this.fieldCulture,
            culture: this.fieldType,
            sort: this.addOwnCultureClass ? this.addednewCulture : this.fieldSort,
            reproduction: this.fieldReproduction,
            is_new: this.addOwnCultureClass
        }
    }

    private setData(parentCulture: string, culture: string, sort: string, reproduction: string) {
        this.fieldCulture = parentCulture
        this.fieldType = culture
        this.fieldSort = sort
        this.fieldReproduction = reproduction
    }

    private mounted() {
        this.isDataLoading = true
        httpGet({
            url: `${apiUrl}/get/culture/static/`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                if (json.ok) {
                    this.FieldCultureData = json.json
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.isDataLoading = false
            }
        })
    }

    private SearchResults() {
        const resultArray = []
        for (const culture of this.FieldCultureData) {
            for (const type of culture.cultures) {
                if (type.name.toLowerCase().includes(this.cultureSearch.toLowerCase())) {
                    resultArray.push({
                        class: i18n.t('headers.workspace.orders.culture.title'),
                        found: type.name,
                        culture: culture.name,
                        type: type.name,
                        sort: ''
                    })
                }
                for (const sort of type.sorts) {
                    if (sort.toLowerCase().includes(this.cultureSearch.toLowerCase())) {
                        resultArray.push({
                            class: i18n.t('headers.workspace.orders.culture.variety.title'),
                            found: sort,
                            culture: culture.name,
                            type: type.name,
                            sort
                        })
                    }
                }
            }
        }
        return resultArray
    }

    private setSearchResult(result: any) {
        this.fieldCulture = result.culture
        this.fieldType = result.type
        this.fieldSort = result.sort
        this.cultureSearch = ''
    }

    private changeFieldType(culture: any, type: any) {
        this.fieldCulture = culture
        this.fieldType = type
        this.TypeDropdown = false
        this.fieldSort = ''
    }

    private changeFieldSort(sort: any) {
        this.fieldSort = sort
        this.SortDropdown = false
    }

    private changeFieldReproduction(reproduction: any) {
        this.fieldReproduction = reproduction.name
        this.reproductionDropdown = false
    }

    private closeTypeDropdown() {
        this.TypeDropdown = false
    }

    private closeSortDropdown() {
        this.SortDropdown = false
    }

    private closeReproductionDropdown() {
        this.reproductionDropdown = false
    }

    private toggleAddOwnCulture(val: boolean) {
        this.addOwnCultureClass = val
        if (this.addOwnCultureClass) {
            this.fieldSort = ''
        } else {
            this.addednewCulture = ''
        }
    }

    private toggleDropdown() {
        this.isDropdownVisible = !this.isDropdownVisible
    }

    private closeDropdown() {
        const data = this.getData()
        this.$emit('culture-choose', data)
        this.isDropdownVisible = false
        // this.fieldCulture = ''
        // this.fieldType = ''
        // this.fieldSort = ''
        // this.addednewCulture
        // this.fieldReproduction = ''
    }
}
