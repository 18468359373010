



























import { Component, Vue, Prop } from 'vue-property-decorator'
import { CultureReproduction } from '@/data/FieldCulture'

@Component
export default class FieldsView extends Vue {
    @Prop({type: Array, default: () => []}) private fields: any
    private CultureReproduction: any = CultureReproduction
    private dropdown = false

    get getFields() {
        return this.fields.map((x: any) => `№${x.name}`).join(', ')
    }

    private fieldText(field: any) {
        const reproduction = this.CultureReproduction.find((x: any) => x.name === field.reproduction)
        return `№${field.name}${field.sort ? ` • ${field.sort}` : ''}${reproduction ? ` • ${reproduction.short}` : ''}`
    }

    private toggleDropdown() {
        this.dropdown = !this.dropdown
    }

    private closeDropdown() {
        this.dropdown = false
    }
}
