import { IState } from './'

export type State = IState

export const Action = {
    setCurrentCadastreMapData: 'setCurrentCadastreMapData',
    pushCadastreToRoute: 'pushCadastreToRoute'
}

export const Mutation = {
    changeLegendDividingMode: 'changeLegendDividingMode',
    setIsNotesModeActive: 'setIsNotesModeActive',
    setNotificationData: 'setNotificationData',
    setChroniclePanel: 'setChroniclePanel',
    setTelematicsInfo: 'setTelematicsInfo',
    setFuelStopTechnic: 'setFuelStopTechnic',
    setIsShowStopTechnic: 'setIsShowStopTechnic'
    // setTechnicEventTelematics: 'setTechnicEventTelematics'
}

export const Getter = {
    panelsState: 'panelsState',
    getIsNotesModeActive: 'getIsNotesModeActive',
    getNotificationData: 'getNotificationData',
    getChroniclePanel: 'getChroniclePanel',
    getTelematicsInfo: 'getTelematicsInfo',
    getFuelStopTechnic: 'getFuelStopTechnic',
    getIsShowStopTechnic: 'getIsShowStopTechnic'
    // getTechnicEventTelematics: 'getTechnicEventTelematics'
}
