import i18n from '@/i18n'

export const categoryTypes = [
    {
        name: 'Все',
        value: 0
    },
    {
        name: 'Рабочая область',
        value: 1
    },
    {
        name: 'Заметки',
        value: 2
    },
    {
        name: 'Снимки',
        value: 3
    },
    {
        name: 'Севооборот',
        value: 4
    },
    {
        name: 'Задачи',
        value: 5
    },
    {
        name: 'Техника',
        value: 6
    },
    {
        name: 'Оплата',
        value: 7
    },
    {
        name: 'Погода',
        value: 8
    },
    {
        name: 'Телематика',
        value: 9
    }
]

export const notificationsPermissions = [
    {
        title: i18n.t('headers.workspace.dispatcher.permissions.alarms.workspace_change'),
        key_value: 'workspace_change',
        type: 1
    },
    {
        title: i18n.t('headers.workspace.dispatcher.permissions.alarms.notice_mention'),
        key_value: 'notice_mention',
        type: 1
    },
    {
        title: i18n.t('headers.workspace.dispatcher.permissions.alarms.notice_reply'),
        key_value: 'notice_reply',
        type: 1
    },
    {
        title: i18n.t('headers.workspace.dispatcher.permissions.alarms.response_new_image'),
        key_value: 'response_new_image',
        type: 1
    },
    {
        title: i18n.t('headers.workspace.dispatcher.permissions.alarms.croprotation_empty'),
        key_value: 'croprotation_empty',
        type: 1
    },
    {
        title: i18n.t('headers.workspace.dispatcher.permissions.alarms.task_add'),
        key_value: 'task_add',
        type: 1
    },
    {
        title: i18n.t('headers.workspace.dispatcher.permissions.alarms.tractor_motion'),
        key_value: 'tractor_motion',
        type: 1
    },
    {
        title: i18n.t('headers.workspace.dispatcher.permissions.alarms.payment_done'),
        key_value: 'payment_done',
        type: 1
    },
    {
        title: i18n.t('headers.workspace.dispatcher.permissions.warnings.notice_field_not_visit'),
        key_value: 'notice_field_not_visit',
        type: 2
    },
    {
        title: i18n.t('headers.workspace.dispatcher.permissions.warnings.weather_draught'),
        key_value: 'weather_draught',
        type: 2
    },
    {
        title: i18n.t('headers.workspace.dispatcher.permissions.warnings.response_dangerous_zone'),
        key_value: 'response_dangerous_zone',
        type: 2
    },
    {
        title: i18n.t('headers.workspace.dispatcher.permissions.warnings.task_incomplete'),
        key_value: 'task_incomplete',
        type: 2
    },
    {
        title: i18n.t('headers.workspace.dispatcher.permissions.warnings.telematic_max_speed'),
        key_value: 'telematic_max_speed',
        type: 2
    },
    {
        title: i18n.t('headers.workspace.dispatcher.permissions.warnings.telematic_out_of_field'),
        key_value: 'telematic_out_of_field',
        type: 2
    },
    {
        title: i18n.t('headers.workspace.dispatcher.permissions.warnings.tractor_stop'),
        key_value: 'tractor_stop',
        type: 2
    },
    {
        title: i18n.t('headers.workspace.dispatcher.permissions.warnings.tractor_breakdown'),
        key_value: 'tractor_breakdown',
        type: 2
    },
    {
        title: i18n.t('headers.workspace.dispatcher.permissions.warnings.tractor_expired_maintenace'),
        key_value: 'tractor_expired_maintenace',
        type: 2
    },
    {
        title: i18n.t('headers.workspace.dispatcher.permissions.warnings.telematic_fuel'),
        key_value: 'telematic_fuel',
        type: 2
    },
    {
        title: i18n.t('headers.workspace.dispatcher.permissions.warnings.telematic_no_info'),
        key_value: 'telematic_no_info',
        type: 2
    },
    {
        title: i18n.t('headers.workspace.dispatcher.permissions.warnings.response_dangerous_zone'),
        key_value: 'response_dangerous_zone',
        type: 2
    }
]