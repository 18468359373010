





























import Vue from 'vue'
export default Vue.extend({
  name: 'base-dialog',
  props: {
    isShown: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: 389
    },
    alertConfirm: {
      type: Boolean,
      default: false
    },
    deleted: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    accept() {
      this.$emit('accept')
    },
    hide() {
      this.$emit('hide')
    }
  }
})
