





































































import { Vue, Component } from "vue-property-decorator"
import { httpGet } from '@/util/http'
import { apiUrl } from '@/domain/constants'
import { Mutation } from "vuex-class"
import { MutationNames } from "@/store/types"
import claasStatistics from "@/views/MainPage/HomeView/Sideboards/CarparkBoard/claas/claasStatistics.vue"
@Component({
    components: {
        claasStatistics
    }
})
export default class ClaasTechnicDetail extends Vue {
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    private isShown = false
    private claas: any = {}

    private getNormalizeDate(date: any) { // 2022-07-03 16:41:56
        const str = date.split(' ').slice(0, -1).reverse().join()
        return str.split('-').reverse().join('.') // 06.07.2022
    }

    private claasDetail(id: any) {
        this.setLoadingDataNames('setLoadingDataNames')
        httpGet({
            url: `${apiUrl}/yields/claas/get/machines/${id}`,
            isSecureRequest: true,
            onSuccess: json => {
                this.claas = json
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('setLoadingDataNames')
            }
        })
    }

    private show(id: any) {
        this.isShown = true
        this.claasDetail(id)
    }

    private hide() {
        this.isShown = false
    }

}
