



















































































import { Component, Vue, Emit } from 'vue-property-decorator'
import { httpGet, httpPost, httpPut } from '@/util/http'
import { apiUrl } from '@/domain/constants'
import { GetterNames, MutationNames } from '@/store/types'
import { Getter, Mutation } from 'vuex-class'
import i18n from '@/i18n'
@Component
export default class NewTractorModel extends Vue {
        @Getter(GetterNames.getCurrentRoute) private currentRoute !: any
    @Getter(GetterNames.getLoadingDataNames) private getLoadingDataNames!: any
    @Getter(GetterNames.getTelematicsInfo) private getTelematicsInfo!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    private isShown = false
    private isUpdate = false
    private complexForUpdate: any = null
    private complex: any = {
        tractor: {
            origin: [],
            converted: [],
            selected: null,
            selected_name: '',
            selected_items: [],
            onerror: ''
        },
        trailer: {
            origin: [],
            converted: [],
            seleceted: null,
            selected_name: '',
            onerror: ''
        },
        driver: {
            origin: [],
            converted: [],
            selected: null,
            name: {
                selected_name: '',
                onerror: ''
            },
            phone: {
                selected_name: '',
                onerror: ''
            }
        },
        folder: {
            origin: [],
            converted: [],
            selected: null,
            selected_name: '',
            onerror: ''
        },
        geozone: true
    }

    private onselectTractor(item: any) {
        const tractor = this.complex.tractor.origin.find((x: any) => `${x.name} (${x.uid})` === item)
        if (tractor) {
            this.complex.tractor.selected = tractor.id
            this.complex.tractor.selected_name = item
            this.complex.tractor.onerror = ''
        }
    }

    private onselectTrailer(item: any) {
        const trailer = this.complex.trailer.origin.find((x: any) => `${x.name} (${x.coverage})` === item)
        if (trailer) {
            this.complex.trailer.selected = trailer.id
            this.complex.trailer.selected_name = item
            this.complex.trailer.onerror = ''
        }
    }

    private onselectFolder(item: any) {
        const folder = this.complex.folder.origin.find((x: any) => `${x.name} (${x.tractors_count})` === item)
        if (folder) {
            this.complex.folder.selected = folder.id
            this.complex.folder.selected_name = item
            this.complex.folder.onerror = ''
        }
    }

    private onselectDriver(item: any) {
        const driver = this.complex.driver.origin.find((x: any) => `${x.first_name} ${x.last_name}` === item)
        if (driver) {
            this.complex.driver.selected = driver.id
            this.complex.driver.name.selected_name = item
            this.complex.driver.phone.selected_name = driver.username
        }
    }

    private update(complex: any) {
        this.isShown = true
        this.isUpdate = true
        this.complexForUpdate = complex
        this.complex = {
            tractor: {
                origin: [],
                converted: [],
                selected: complex.tractor.id,
                selected_name: `${complex.tractor.name} (${complex.tractor.uid})`,
                onerror: ''
            },
            trailer: {
                origin: [],
                converted: [],
                seleceted: complex.trailer ? complex.trailer.id : null,
                selected_name: complex.trailer ? `${complex.trailer.name} (${complex.trailer.coverage})` : '',
                onerror: ''
            },
            driver: {
                origin: [],
                converted: [],
                selected: complex.driver,
                name: {
                    selected_name: complex.full_name ? complex.full_name : '',
                    onerror: ''
                },
                phone: {
                    selected_name: complex.phone ? complex.phone : '',
                    onerror: ''
                }
            },
            folder: {
                origin: [],
                converted: [],
                selected: complex.folder,
                selected_name: '',
                onerror: ''
            },
            geozone: true
        }
        this.fetchData()
    }

    private show() {
        this.isShown = true
        this.isUpdate = false
        this.complex = {
            tractor: {
                origin: [],
                converted: [],
                selected: null,
                selected_name: '',
                onerror: ''
            },
            trailer: {
                origin: [],
                converted: [],
                seleceted: null,
                selected_name: '',
                onerror: ''
            },
            driver: {
                origin: [],
                converted: [],
                selected: null,
                name: {
                    selected_name: '',
                    onerror: ''
                },
                phone: {
                    selected_name: '',
                    onerror: ''
                }
            },
            folder: {
                origin: [],
                converted: [],
                selected: null,
                selected_name: '',
                onerror: ''
            },
            geozone: true
        }
        this.fetchData()
    }

    private fetchData() {
        this.complex.folder.origin = this.getTelematicsInfo.folders
        this.complex.folder.converted = this.getTelematicsInfo.folders.map((x: any) => `${x.name} (${x.tractors_count})`)
        if (this.complex.folder.selected) {
            const folder = this.complex.folder.origin.find((x: any) => x.id === this.complex.folder.selected)
            if (folder) {
                this.complex.folder.selected_name = `${folder.name} (${folder.tractors_count})`
            }
        }
        this.setLoadingDataNames('newComplexModelGetData')
        httpGet({
            url: `${apiUrl}/yields/tractor/without/folder/${this.currentRoute.params.farmId}/`,
            isSecureRequest: true,
            onSuccess: json => {
                this.complex.tractor.origin = json.tractors
                this.complex.tractor.converted = json.tractors.map((x: any) => `${x.name} (${x.uid})`)
                this.complex.trailer.origin = json.trailers
                this.complex.trailer.converted = json.trailers.map((x: any) => `${x.name} (${x.coverage})`)
                this.complex.driver.origin = json.users
                this.complex.driver.converted = json.users.map((x: any) => `${x.first_name} ${x.last_name}`)
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('newComplexModelGetData')
            }
        })
    }

    private checkForError() {
        let counter = 0
        if (this.complex.tractor.selected === null) {
            this.complex.tractor.onerror = 'Выберите технику'
            counter++
        }
        return counter > 0
    }

    private addNewComplex() {
        if (this.checkForError()) return
        this.setLoadingDataNames('newFolderModelAddFolder')
        httpPost({
            url:  `${apiUrl}/yields/folder/tractor/`,
            isSecureRequest: true,
            detailResponse: true,
            body: {
                tractor: this.complex.tractor.selected,
                trailer: this.complex.trailer.selected,
                folder: this.complex.folder.selected,
                driver: this.complex.driver.selected,
                full_name: this.complex.driver.name.selected_name,
                phone: this.complex.driver.phone.selected_name,
                applied_geozone: this.complex.geozone && this.getTelematicsInfo.geozone.data ? this.getTelematicsInfo.geozone.data.id : null
            },
            onSuccess: json => {
                if (json.ok) {
                    this.$alert('Комплекс успешно добавлен', 'Сохранено', 'success')
                    this.$emit('reload')
                    this.hide()
                } else {
                    this.$alert('Ошибка сервера', 'Ошибка', 'error')
                }

            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('newFolderModelAddFolder')
            }
        })
    }

    private updateComplex() {
        if (this.checkForError()) return
        this.setLoadingDataNames('newComplexModelUpdateComplex')
        httpPut({
            url: `${apiUrl}/yields/folder/tractor/${this.complexForUpdate.id}/`,
            isSecureRequest: true,
            detailResponse: true,
            body: {
                trailer: this.complex.trailer.selected,
                folder: this.complex.folder.selected,
                driver: this.complex.driver.selected,
                full_name: this.complex.driver.name.selected_name,
                phone: this.complex.driver.phone.selected_name,
                applied_geozone: this.complex.geozone && this.getTelematicsInfo.geozone.data ? this.getTelematicsInfo.geozone.data.id : null
            },
            onSuccess: json => {
                if (json.ok) {
                    this.$alert('Комплекс успешно обновлен', 'Сохранено', 'success')
                    this.$emit('reload')
                    this.hide()
                } else {
                    this.$alert('Ошибка сервера', 'Ошибка', 'error')
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('newComplexModelUpdateComplex')
            }
        })
    }

    private hide() {
        this.isShown = false
    }
}
