import {IState} from './'

export type State = IState

export const Action = {
    fetchFarmsList: 'fetchFarmsList',
    fetchCurrentFarm: 'fetchCurrentFarm',
    fetchCurrentFarmCadastres: 'fetchCurrentFarmCadastres',
    fetchAllFarms: 'fetchAllFarms',
    reloadCurrentFarm: 'reloadCurrentFarm',
    changeFarmSeason: 'changeFarmSeason',
    updateCurrentField: 'updateCurrentField'
}

export const Mutation = {
    setFarms: 'setFarms',
    setShowAllFarms: 'setShowAllFarms',
    setCurrentCadastreIndex: 'setCurrentCadastreIndex',
    setSelectedCadastresFarm: 'setSelectedCadastresFarm',
    setRegisterFarms: 'setRegisterFarms',
    setCurrentFarmIndicators: 'setCurrentFarmIndicators'
}

export const Getter = {
    getCurrentCadastreId: 'getCurrentCadastreId',
    getFarms: 'getFarms',
    getShowAllFarms: 'getShowAllFarms',
    getFarmFromRoute: 'getFarmFromRoute',
    getCadastreIdFromRoute: 'getCadastreIdFromRoute',
    getSelectedCadastresFarm: 'getSelectedCadastresFarm',
    getCurrentSeason: 'getCurrentSeason',
    getRegisterFarms: 'getRegisterFarms',
    getCurrentFarmIndicators: 'getCurrentFarmIndicators',
    getCurrentFarmCadastres: 'getCurrentFarmCadastres'
}
