






































































import { Component, Vue } from 'vue-property-decorator'
import FarmItem from '@/components/FarmItem.vue'
import Sideboard from '@/components/Sideboard.vue'
import { Getter, Action, Mutation } from 'vuex-class'
import Farm from '@/models/Farm'
import {GetterNames, MutationNames, ActionNames} from '@/store/types'
import {ASTANA_GEOLOCATION, ASTANA_BOUNDARIES, MIN_ZOOM, apiUrl} from '@/domain/constants'
import {httpGet, httpPost} from '@/util/http'
@Component({
    components: {
        Sideboard,
        FarmItem
    }
})
export default class CadastresBoard extends Vue {
    @Action(ActionNames.fetchFarmsList) private fetchFarmsList!: any
    @Action (ActionNames.updateMapDataInRegion) private updateMapDataInRegion!: any

    @Getter(GetterNames.getFarms) private getFarms!: Farm[]
    @Getter(GetterNames.getPermissions) private getPermissions!: any
    @Getter(GetterNames.getLoadingDataNames) private getLoadingDataNames!: any
    @Getter(GetterNames.getShowAllFarms) private getShowAllFarms!: any

    @Mutation(MutationNames.setSideboardIsShown) private setSideboardIsShown !: any
    @Mutation(MutationNames.setGeolocVisibility) private setGeolocVisibility!: any
    @Mutation(MutationNames.setShowAllFarms) private setShowAllFarms!: any
    private minZoom: any = MIN_ZOOM
    private showOnMap = false

    private mounted() {
        this.showOnMap = this.getShowAllFarms
        this.setSideboardIsShown(true)
        this.fetchFarmsList()
        this.updateMapDataInRegion({
            boundaries: ASTANA_BOUNDARIES,
            center: ASTANA_GEOLOCATION,
            currentZoom: 10,
            MIN_ZOOM: this.minZoom
        })
    }

    private loadAllFarms() {
      this.showOnMap = !this.showOnMap
      this.setShowAllFarms(this.showOnMap)
    }

    private openFarm(farm: Farm) {
        this.setGeolocVisibility(false)
        this.$router.push({name: 'farm', params: {farmId: farm.id.toString()}}).catch(e => {/* */})
    }
}
