

























































import { Vue, Component, Prop } from "vue-property-decorator"
import {httpDelete} from "@/util/http"
import {apiUrl, apiUrl2, baseUrl} from "@/domain/constants"
import i18n from "@/i18n"
import {Getter, Mutation} from "vuex-class"
import {GetterNames, MutationNames} from "@/store/types"
import Farm from "@/models/Farm"
import {Route} from "vue-router"
@Component
export default class NoteWindow extends Vue {
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    @Mutation(MutationNames.setNoteSupport) private setNoteSupport!: any
    @Getter(GetterNames.getFarmFromRoute) private getFarmFromRoute!: Farm
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: Route
    @Getter(GetterNames.getSubfieldNoteData) private getSubfieldNoteData!: any
    @Prop({default: {}}) private note!: any

    private moreDropdown = -1

    private getNameField(id: any) {
        return this.getFarmFromRoute.fields.find((x: any) => x.id === id).name
    }

    private getTypeProblem(problem: any) {
        switch (problem) {
            case 'type1':
                return 'Болезнь'
            case 'type2':
                return 'Вредители'
            case 'type3':
                return 'Сорняки'
            case 'type4':
                return 'Другое'
            default:
                return 'Заметка без категории'
        }
    }

    private openNote(note: any) {
        console.log(note, 'note')
        console.log(this.getSubfieldNoteData.data)
        this.setNoteSupport({noteID: note.id})
        if (this.$route.name === 'subfieldnotes') { // заходит с поля
            this.$router.push(`/cadastres/farm/${this.getFarmFromRoute.id}/subfields/${this.currentRoute.params.subfieldId}/notes/${note.id}`)
        } else {
            this.$router.push(`/cadastres/farm/${this.getFarmFromRoute.id}/subfields/${note.divided_cadastre_user.id}/notes/${note.id}`)
        }
    }

    private openNoteUpdate(note: any) {
        if (this.$route.name !== 'noteupdate') {
            this.$router.push(`/cadastres/farm/${this.currentRoute.params.farmId}/subfields/${note.divided_cadastre_user.id}/noteupdate/${note.id}`)
        }
    }
    private openNoteReply(note: any) {
        if (this.$route.name !== 'notereply') {
            this.$router.push(`/cadastres/farm/${this.currentRoute.params.farmId}/subfields/${note.divided_cadastre_user.id}/notereply/${note.id}`)
        }
    }

    private deleteNote(note: any) {
        this.setLoadingDataNames('FieldNotesIsDeletingNote')
        httpDelete({
            url: `${apiUrl2}/notes/${note.id}/`,
            isSecureRequest: true,
            onSuccess: () => {
                this.moreDropdown = -1
                Vue.alert('', `${this.$t('messages.success.note_deleted')}`, 'success')
                this.$emit('getNotesList')
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('FieldNotesIsDeletingNote')
            }
        })
    }

    private getImageUrl(imageUrl: any) {
        return `${baseUrl}${imageUrl}`
    }

    private moreDropdownCollapse(index: number) {
        if ( this.moreDropdown === index ) {
            this.moreDropdown = -1
        } else {
            this.moreDropdown = index
        }
    }

    private closeMoreDropdown() {
        this.moreDropdown = -1
    }
}
