







































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import { GetterNames, MutationNames } from '@/store/types'
import { Route } from 'vue-router'
import { httpGet, httpPost, httpPut } from '@/util/http'
import { apiUrl } from '@/domain/constants'
import Farm from '@/models/Farm'
import i18n from '@/i18n'

@Component
export default class OrderAgroChem extends Vue {
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: Route
    @Getter(GetterNames.getFarmFromRoute) private getFarmFromRoute!: Farm
    @Getter(GetterNames.getFarms) private getFarms!: any
    @Getter(GetterNames.getAgroChemData) private getAgroChemData!: any
    @Mutation(MutationNames.setAgroChemData) private setAgroChemData!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    private selectedFarm = ''
    private probeArea = [1, 5, 10, 15, 20, 25, 30, 40, 50]
    private selectedProbe = 25
    private fieldDropdown = false
    private probeDropdown = false
    private phoneNumber = ''
    private editDropdown = false
    private alertInfo = false
    private companyList: any = {
        list: [],
        chosen: -1,
        price: 0
    }

    private getCurrentFarmFields() {
        if (this.getFarmFromRoute) {
            return this.getFarmFromRoute.fields
        }
        return []
    }

    private mounted() {
        this.setAgroChemData({selectedFields: [], fieldPoints: [], mapSelection: false})
        this.getAgroCompanies()
        this.getData()
    }

    private getData() {
        if (this.getFarmFromRoute) {
            this.selectedFarm = this.getFarmFromRoute.name
        }
    }
    private getAgroCompanies() {
        this.setLoadingDataNames('orderAgroChemGetCompanies')
        httpGet({
            url: `${apiUrl}/agro_analysis/agrarian/list`,
            isSecureRequest: true,
            onSuccess: json => {
                this.companyList.list = json
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('orderAgroChemGetCompanies')
            }
        })
    }

    private POSTFINALREQUEST() {
        if (!this.alertInfo && this.getAgroChemData.pointEdit) {
            alert(i18n.t('messages.instructions.finish_editing'))
            this.alertInfo = true
            return
        }
        this.setAgroChemData([])
        if (this.phoneNumber === '') {
            alert(i18n.t('messages.errors.number'))
            return
        } else {
            let checkIfUpdateFinish = 0
            for (const request of this.getAgroChemData.fieldPoints) {
                this.setLoadingDataNames(`orderAgroChemPUTPointUpdate${request.id}`)
                checkIfUpdateFinish += 1
                const requestBody = []
                for (const point of request.points) {
                    if (point.hasOwnProperty('newpoint')) {
                        requestBody.push({
                            geom: point.geom
                        })
                    } else {
                        requestBody.push({
                            id: point.id,
                            geom: point.geom,
                            number: point.number
                        })
                    }
                }
                httpPut({
                    url: `${apiUrl}/agro_analysis/point/update/${request.id}/`,
                    isSecureRequest: true,
                    body: {
                        points_update: requestBody,
                        divided_cadastre_user: request.divided_cadastre_user,
                        proba: request.proba
                    },
                    onSuccess: json => {
                        checkIfUpdateFinish -= 1
                        this.setLoadingDataNames(`orderAgroChemPUTPointUpdate${request.id}`)
                    },
                    onError: error => {
                        console.log(error)
                    },
                    doFinally: () => {
                        if (checkIfUpdateFinish === 0) {
                            this.setLoadingDataNames('orderAgroChemPOSTFinalRequest')
                            const requestArr = []
                            for (const request2 of this.getAgroChemData.fieldPoints) {
                                requestArr.push(request2.id)
                            }
                            httpPost({
                                url: `${apiUrl}/agro_analysis/request/new/`,
                                isSecureRequest: true,
                                body: {
                                    agro_analys: requestArr,
                                    agrarian: this.companyList.chosen,
                                    customer_phone: this.phoneNumber
                                },
                                onSuccess: json => {
                                    console.log(json)
                                },
                                doFinally: () => {
                                    this.setLoadingDataNames('orderAgroChemPOSTFinalRequest')
                                    this.$router.push('/cadastres').catch(e => {/**/})
                                }
                            })
                        }
                    }
                })
            }
        }
        (this.$refs.alertModal as any).show()
    }

    private postAgroAnalysis() {
        if (this.getAgroChemData.selectedFields.length === 0 ) return
        this.setLoadingDataNames('orderAgroChemGetPoints')
        this.setAgroChemData({fieldPoints: []})
        const postBody: any = []
        for (const fieldId of this.getAgroChemData.selectedFields) {
            postBody.push({
                divided_cadastre_user: fieldId,
                proba: this.selectedProbe
            })
        }
        httpPost({
            url: `${apiUrl}/agro_analysis/`,
            isSecureRequest: true,
            body: postBody,
            onSuccess: json => {
                this.setAgroChemData({ fieldPoints: json })
            },
            doFinally: () => {
                this.setLoadingDataNames('orderAgroChemGetPoints')
            }
        })
    }

    private setMapSelection() {
        this.setAgroChemData({mapSelection: !this.getAgroChemData.mapSelection})
        if (this.getAgroChemData.mapSelection) {
            this.setAgroChemData({pointEdit: false})
        }
    }

    private setPointEdit(val: any) {
        if (val === 'cancel') {
            this.setAgroChemData({pointEdit: true})
            this.setAgroChemData({pointEdit: false, editType: 'edit_icon'})
        } else {
            this.setAgroChemData({pointEdit: false})
            this.setAgroChemData({pointEdit: true, editType: val})
        }
        if (this.getAgroChemData.pointEdit) {
            this.setAgroChemData({mapSelection: false})
        }
        this.editDropdown = false
    }

    private closeEditDropdown() {
        this.editDropdown = false
    }

    private closeFieldDropdown() {
        this.fieldDropdown = false
    }

    private closeProbeDropdown() {
        this.probeDropdown = false
    }

    private getTotalArea() {
        const farm = this.getFarms.find((x: any) => x.name === this.selectedFarm)
        let area = 0
        for (const field of this.getAgroChemData.selectedFields) {
            area += farm.fields.find((x: any) => x.id === field).area
        }
        return (area / 10000).toFixed(1)
    }

    private getTotalPoints() {
        let amount = 0
        for (const field of this.getAgroChemData.fieldPoints) {
            amount += field.points.length
        }
        return amount
    }

    private getTotalAmount() {
        return this.getTotalPoints() * this.companyList.price
    }

    private getFieldsNumberString() {
        const farm = this.getFarms.find((x: any) => x.name === this.selectedFarm)
        if (this.getAgroChemData.selectedFields.length === 0) {
            return i18n.t('messages.not_chosen')
        } else if (farm.fields.length === this.getAgroChemData.selectedFields.length) {
            return i18n.t('messages.selections.all_fields')
        } else {
            let str = ''
            for (const field of farm.fields) {
                if (this.getAgroChemData.selectedFields.includes(field.id)) {
                    str += '#' + field.number + ', '
                }
            }
            if (str.length > 24) {
                return str.substring(0, 22) + '...'
            }
            return str
        }
    }

    private chooseAll() {
        const farm = this.getFarms.find((x: any) => x.name === this.selectedFarm)
        if (farm.fields.length === this.getAgroChemData.selectedFields.length) {
            this.setAgroChemData({selectedFields: []})
        } else {
            const tempArr = []
            for (const field of farm.fields) {
                tempArr.push(field.id)
            }
            this.setAgroChemData({selectedFields: tempArr})
        }
    }

    private changeSelectedField(fieldId: any) {
        const tempArr = this.getAgroChemData.selectedFields
        if (tempArr.includes(fieldId)) {
            tempArr.splice(tempArr.indexOf(fieldId), 1)
        } else {
            tempArr.push(fieldId)
        }
        this.setAgroChemData({selectedFields: tempArr})
    }

    private close() {
        this.$router.push(`/argochem/${this.currentRoute.params.farmId}`)
    }

}
