import { Module, GetterTree, ActionTree, MutationTree } from 'vuex'
import { GetterNames, ActionNames, MutationNames } from '../../types'
import Farm from '@/models/Farm'
import RegisterFarm from '@/models/RegisterFarm'
import { apiUrl } from '@/domain/constants'
import { httpGet, httpPut } from '@/util/http'
import router from '@/router'

const states = {
    farms: new Array<Farm>(),
    showAllFarms: false,
    RegisterFarms: {
        addType: 0,
        cadastres: new Array<RegisterFarm>(),
        editType: 'none',
        editStage: 0,
        selectedCadastres: [],
        cachedCadastres: new Array<RegisterFarm>(),
        errorPoints: [],
        newField: false
    },
    selectedCadastresFarm: null,
    selectedCadastreIndex: -1,
    currentFarmIndicators: {
        farmId: null,
        dates: [],
        data: [],
        selectedDate: ''
    }
}
const coverageColors: any = ['#2B4DFF', '#AA27E8', '#F37500', '#06BAF2', '#FFC700', '#5AF5D9', '#7D5EF8', '#FC7BE7', '#ADDE30']

export type IState = typeof states

const mutations: MutationTree<IState> = {
    [MutationNames.setFarms](state, val: any) {
        state.farms = val
    },

    [MutationNames.setCurrentCadastreIndex](state, index: any) {
        state.selectedCadastreIndex = index
    },

    [MutationNames.setSelectedCadastresFarm](state, index: any) {
        state.selectedCadastresFarm = index
    },

    [MutationNames.setShowAllFarms](state, val: any) {
        state.showAllFarms = val
    },

    [MutationNames.setRegisterFarms](state, val: any) {
        if (val.hasOwnProperty('addType')) state.RegisterFarms.addType = val.addType
        if (val.hasOwnProperty('cadastres')) state.RegisterFarms.cadastres = val.cadastres
        if (val.hasOwnProperty('editType')) state.RegisterFarms.editType = val.editType
        if (val.hasOwnProperty('editStage')) state.RegisterFarms.editStage = val.editStage
        if (val.hasOwnProperty('selectedCadastres')) state.RegisterFarms.selectedCadastres = val.selectedCadastres
        if (val.hasOwnProperty('cachedCadastres')) state.RegisterFarms.cachedCadastres = val.cachedCadastres
        if (val.hasOwnProperty('errorPoints')) state.RegisterFarms.errorPoints = val.errorPoints
        if (val.hasOwnProperty('newField')) state.RegisterFarms.newField = val.newField
    },

    [MutationNames.setCurrentFarmIndicators](state, val: any) {
        if (val.hasOwnProperty('farmId')) state.currentFarmIndicators.farmId = val.farmId
        if (val.hasOwnProperty('dates')) state.currentFarmIndicators.dates = val.dates
        if (val.hasOwnProperty('data')) state.currentFarmIndicators.data = val.data
        if (val.hasOwnProperty('selectedDate')) state.currentFarmIndicators.selectedDate = val.selectedDate
    }
}

const actions: ActionTree<IState, any> = {
    [ActionNames.fetchFarmsList]({ state, commit, getters, dispatch }, reload = false) {
        if (getters[GetterNames.getLoadingDataNames]('fetchFarmsList')) return
        if (state.farms.length === 0 || reload) {
            commit(MutationNames.setLoadingDataNames, 'fetchFarmsList')
            httpGet({
                url: `${apiUrl}/group/user/list/`,
                isSecureRequest: true,
                onSuccess: json => {
                    const farms: Farm[] = json.map((farm: any, index: number) => {
                        return new Farm({
                            id: farm.id,
                            name: farm.name,
                            fieldCount: farm.sub_fields_num,
                            area: farm.total_area,
                            oblast: farm.oblast,
                            region: farm.region,
                            color: coverageColors[index]
                        })
                    })
                    state.farms = farms
                },
                onError: error => {
                    console.log(error)
                },
                doFinally: () => {
                    commit(MutationNames.setLoadingDataNames, 'fetchFarmsList')
                    dispatch(ActionNames.fetchCurrentFarm)
                }
            })
        } else {
            dispatch(ActionNames.fetchCurrentFarm)
        }
    },

    [ActionNames.fetchCurrentFarm]({ state, commit, getters, dispatch }) {
        if (getters[GetterNames.getCurrentRoute].params.farmId) {
            const farmId = getters[GetterNames.getCurrentRoute].params.farmId
            const farm = state.farms.find((x: any) => x.id.toString() === farmId)
            if (farm) {
                if (!farm.fullData) {
                    if (getters[GetterNames.getLoadingDataNames]('fetchCurrentFarm')) return
                    commit(MutationNames.setLoadingDataNames, 'fetchCurrentFarm')
                    httpGet({
                        url: `${apiUrl}/group/user/list/new/${getters[GetterNames.getCurrentRoute].params.farmId}`,
                        isSecureRequest: true,
                        onSuccess: json => {
                            if (json.detail) {
                                if (json.detail === "You do not have permission to perform this action.") {
                                    router.push(`/cadastres`).catch(e => {/* */ })
                                }
                            }
                            const index = state.farms.findIndex((x: any) => x.id === farm.id)
                            if (index !== -1) {
                                state.farms[index].ratingDate = json.rating_date === null ? '' : json.rating_date
                                state.farms[index].fields = json.sub_fields
                                state.farms[index].isPaid = json.is_subscribed_now
                                state.farms[index].seasons = json.group_season
                                state.farms[index].setProperties()
                                // console.log(JSON.stringify({
                                //     type: 'FeatureCollection',
                                //     crs: {
                                //         type: 'name',
                                //         properties: {
                                //             name: 'urn:ogc:def:crs:EPSG::3857'
                                //         }
                                //     },
                                //     features: json.sub_fields.map((x: any) => {
                                //         return {
                                //             type: 'Feature',
                                //             properties: {
                                //                 name: x.name
                                //             },
                                //             geometry: x.geom
                                //         }
                                //     })
                                // }))
                            }
                        },
                        onError: error => {
                            console.log(error)
                        },
                        doFinally: () => {
                            commit(MutationNames.setLoadingDataNames, 'fetchCurrentFarm')
                        }
                    })
                }
            } else {
                router.push(`/cadastres`).catch(e => {/* */ })
            }
            dispatch(ActionNames.fetchFieldIndicatorData)
        }
    },

    [ActionNames.fetchCurrentFarmCadastres]({ state, commit, getters, dispatch }) {
        const farmId = getters[GetterNames.getSelectedCadastresFarm]
        if (farmId) {
            // commit(MutationNames.setLoadingDataNames, 'fetchCurrentFarmCadastres')
            httpGet({
                url: `${apiUrl}/get/farm/info/geom/${farmId}/`,
                isSecureRequest: true,
                onSuccess: json => {
                    const index = state.farms.findIndex((x: any) => x.id === farmId)
                    if (index !== -1) {
                        // const cadastres = json.filter((cadastre: any) => cadastre.cadastre && cadastre.fields.length > 0)
                        state.farms[index].cadastres = json
                    }
                },
                onError: error => {
                    console.log(error)
                }
                // doFinally: () => {
                //     commit(MutationNames.setLoadingDataNames, 'fetchCurrentFarmCadastres')
                // }
            })
        } else {
            router.push(`/cadastres`).catch(e => {/* */ })
        }
    },

    [ActionNames.fetchAllFarms]({ state, commit }) {
        for (const farm of state.farms) {
            if (!farm.fullData) {
                commit(MutationNames.setLoadingDataNames, `fetchAllFarms${farm.id}`)
                httpGet({
                    url: `${apiUrl}/group/user/list/new/${farm.id}`,
                    isSecureRequest: true,
                    onSuccess: json => {
                        if (json.detail) {
                            if (json.detail === "You do not have permission to perform this action.") {
                                router.push(`/cadastres/`)
                            }
                        }
                        const index = state.farms.findIndex((x: any) => x.id === farm.id)
                        if (index !== -1) {
                            state.farms[index].ratingDate = json.rating_date === null ? '' : json.rating_date
                            state.farms[index].fields = json.sub_fields
                            state.farms[index].isPaid = json.is_subscribed_now
                            state.farms[index].seasons = json.group_season
                            state.farms[index].setProperties()
                        }
                    },
                    onError: error => {
                        console.log(error)
                    },
                    doFinally: () => {
                        if (!state.farms.find((x: any) => !x.fullData)) {
                            router.push({
                                params: {
                                    status: 'loaded'
                                }
                            }).catch(e => {/* */ })
                        }
                        commit(MutationNames.setLoadingDataNames, `fetchAllFarms${farm.id}`)
                    }
                })
            }
            if (!state.farms.find((x: any) => !x.fullData)) {
                router.push({
                    params: {
                        status: 'loaded'
                    }
                }).catch(e => {/* */ })
            }
        }
    },

    [ActionNames.reloadCurrentFarm]({ state, getters, dispatch }) {
        if (getters[GetterNames.getCurrentRoute].params.farmId) {
            const farmId = getters[GetterNames.getCurrentRoute].params.farmId
            const farm = state.farms.find((x: any) => x.id.toString() === farmId)
            if (farm) {
                const index = state.farms.indexOf(farm)
                if (index !== -1) {
                    state.farms[index].fullData = false
                    dispatch(ActionNames.fetchCurrentFarm)
                }
            }
        }
    },

    [ActionNames.changeFarmSeason]({ state, commit, getters, dispatch }, seasonId: any) {
        if (seasonId) {
            commit(MutationNames.setLoadingDataNames, 'seasonsChangeCurrentSeason')
            httpPut({
                url: `${apiUrl}/new/seasons/${seasonId}/`,
                isSecureRequest: true,
                body: {
                    is_current: true
                },
                onSuccess: json => {
                    if (json.hasOwnProperty('group_user')) {
                        if (json.group_user === getters[GetterNames.getFarmFromRoute].id) {
                            const oldIndex = getters[GetterNames.getFarmFromRoute].seasons.findIndex((x: any) => x.is_current)
                            const farmIndex = state.farms.findIndex((x: any) => x.id === getters[GetterNames.getFarmFromRoute].id)
                            const seasonIndex = getters[GetterNames.getFarmFromRoute].seasons.findIndex((x: any) => x.id === json.id)
                            state.farms[farmIndex].seasons[oldIndex].is_current = false
                            state.farms[farmIndex].seasons[seasonIndex] = json
                            state.farms[farmIndex].fullData = false
                            state.farms[farmIndex].fullData = true
                            dispatch(ActionNames.fetchFieldIndicatorData)
                        }
                    }
                },
                onError: error => {
                    console.log(error)
                },
                doFinally: () => {
                    dispatch(ActionNames.reloadCurrentFarm)
                    commit(MutationNames.setLoadingDataNames, 'seasonsChangeCurrentSeason')
                }
            })
        }
    },

    [ActionNames.updateCurrentField]({ state, commit, getters }, val: any) {
        const currentFarm = getters[GetterNames.getFarmFromRoute]
        if (currentFarm) {
            const farmIndex = state.farms.findIndex((x: any) => x.id === currentFarm.id)
            if (farmIndex !== -1) {
                const currentField = getters[GetterNames.getFieldFromRoute]
                const fieldIndex = currentFarm.fields.findIndex((x: any) => x.id === currentField.id)
                if (fieldIndex !== -1) {
                    state.farms[farmIndex].fields[fieldIndex] = val
                }
            }
        }
    }
}

const getter: GetterTree<IState, any> = {

    [GetterNames.getFarms]: state => {
        return state.farms
    },

    [GetterNames.getCurrentCadastreId]: (state, getters) => {
        if (getters.getFarmFromRoute) return (getters.getFarmFromRoute as Farm).id
        else return state.selectedCadastreIndex
    },

    [GetterNames.getFarmFromRoute]: (state, getters) => {
        const farmId = getters[GetterNames.getCurrentRoute].params.farmId
        if (farmId) {
            const farm: any = state.farms.find((x: any) => x.id.toString() === farmId)
            if (farm) {
                if (farm.fullData) {
                    if (farm) state.selectedCadastreIndex = farmId
                    return farm || false
                }
            }
        }
        return false
    },

    [GetterNames.getRegisterFarms]: state => {
        return state.RegisterFarms
    },

    [GetterNames.getCurrentSeason]: (state, getters) => {
        const farm = getters[GetterNames.getFarmFromRoute]
        if (farm) {
            if (farm.fullData) {
                const season = farm.seasons.find((x: any) => x.is_current)
                if (season) {
                    return season || false
                }
            }
        }
        return false
    },

    [GetterNames.getCurrentFarmIndicators]: state => {
        return state.currentFarmIndicators
    },

    [GetterNames.getSelectedCadastresFarm]: state => {
        return state.selectedCadastresFarm
    },

    [GetterNames.getShowAllFarms]: state => {
        return state.showAllFarms
    }
}

const auth: Module<IState, any> = {
    state: states,
    actions,
    mutations,
    getters: getter
}

export default auth
