
































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { GetterNames, MutationNames } from '@/store/types'
import { Getter, Mutation } from 'vuex-class'
@Component
export default class FieldSlider extends Vue {
    @Getter(GetterNames.getMapWidth) private mapWidth !: any
    @Getter(GetterNames.getMapData) private mapData !: any
    @Getter(GetterNames.getSwiperPositionX) private swiperPositionX!: any
    @Getter(GetterNames.getSubfieldIndicatorData) private getSubfieldIndicatorData!: any

    @Mutation(MutationNames.setSwiperPositionX) private setSwiperPositionX!: any
    @Mutation(MutationNames.setSubfieldIndicatorData) private setSubfieldIndicatorData!: any
    private isSwiping = false
    private sliderPosition = 0
    private difference = 0
    private lastMousePosition = -1

    private prevDatesShown = false
    private nextDatesShown = false

    @Watch('mapWidth')
    private mapWidthChanged(newVal: number, oldValue: number) {
        this.sliderPosition += (newVal - oldValue) / 2
    }

    @Watch('mapData.center', {deep: true, immediate: true})
    private mapDataChanged(newVal: any) {
        this.setSwiperPositionX(this.mapData.center[0] + this.calculatePixelsInMeters(this.sliderPosition - this.mapWidth / 2))
    }

    private mounted() {
        this.sliderPosition = this.mapWidth / 2
        this.setSwiperPositionX(this.mapData.center[0])
        document.addEventListener('mouseup', this.cancelSwiping)
        document.addEventListener('mousemove', this.trackMousePosition)
    }

    private getRequestDate(requestId: number) {
        const date = this.getSubfieldIndicatorData.data.find((x: any) => requestId === x.id).actual_date
        return date.substring(8, 10) + '.' + date.substring(5, 7) + '.' + date.substring(0, 4)
    }

    private setCurrentRequest(request: any) {
        this.setSubfieldIndicatorData({currentRequestId: request.id,
            currentRequestDir: request.images_urls ? request.images_urls : null,
            currentBbox: request.extra_params ? request.extra_params.bbox ? request.extra_params.bbox : null : null
        })
        this.nextDatesShown = false
    }

    private setSecondRequest(request: any) {
        this.setSubfieldIndicatorData({
            secondRequestId: request.id,
            secondRequestDir: request.images_urls ? request.images_urls : null,
            secondBbox: request.extra_params ? request.extra_params.bbox ? request.extra_params.bbox : null : null
        })
        this.prevDatesShown = false
    }

    private computeBoundaries(boundaries: number[]) {
        return boundaries
    }

    private trackMousePosition(e: any) {
        if (this.isSwiping) {
            this.difference = e.screenX - this.lastMousePosition
            this.setSwiperPositionX(
                this.mapData.center[0] + this.calculatePixelsInMeters(this.sliderPosition + this.difference - this.mapWidth / 2)
            )
        }
    }

    private calculatePixelsInMeters(diff: number) {
        const Spixel = ( 2 * Math.PI * 6378137) / Math.pow( 2, this.mapData.zoom + 8 )
        return diff * Spixel
    }

    private cancelSwiping() {
        this.isSwiping = false
        this.sliderPosition += this.difference
        this.difference = 0
    }

    private mousePressed(e: any) {
        this.isSwiping = true
        this.difference = 0
        this.lastMousePosition = e.screenX

    }

    private destroyed() {
        document.removeEventListener('mouseup', this.cancelSwiping)
        document.removeEventListener('mousemove', this.trackMousePosition)
    }
}
