export default {
    title: "Registration form",
    login: "Enter your details to continue, or | Sign in",
    name: "First name",
    surname: "Last name",
    password: "Password to login",
    passwordrepeat: "Confirm your password",
    agreement: "By clicking on the | button you agree to | 'User agreement'",
    phone: {
        countrySelectorLabel: "Country code",
        countrySelectorError: "Not chosen",
        phoneNumberLabel: "Phone number",
        example: "Example: "
    }
}