











































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { httpGet, httpPost, httpPut } from '@/util/http'
import { apiUrl } from '@/domain/constants'
import { MutationNames } from '@/store/types'
import { Mutation } from 'vuex-class'
import { moduleTypes, permissionModules } from '@/data/rolesData'
import NewMemberScript from './newMemberScript'
@Component({
    components: {
    }
})
export default class NewMemberModal extends Vue {
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    private permissionModules: any = permissionModules
    private moduleTypes: any = moduleTypes
    private member = new NewMemberScript()
    private isShown = false
    private isUpdate = false
    private memberForUpdate: any = null

    @Watch('member.email.selectedName')
    private checkEmail() {
        if (!this.member.allFieldsIsFullUsername) {
            if (this.isUpdate) return
            this.member.checkEmail()
        }
    }

    @Watch('member.username.selectedName')
    private checkNumber() {
        if (this.isUpdate) return
        this.member.checkNumber()
    }

    private show() {
        this.isShown = true
        this.isUpdate = false
        this.member = new NewMemberScript()
        this.getData()
    }

    private update(member: any) {
        this.isShown = true
        this.isUpdate = true
        this.memberForUpdate = member
        this.member = new NewMemberScript()
        this.getData()
    }

    private getData() {
        this.setLoadingDataNames('NewMemberScriptGetShortFarms')
        httpGet({
            url: `${apiUrl}/get/short/named/groups/`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                console.log(json)
                if (json.ok) {
                    this.member.farms.origin = json.json
                    this.member.farms.converted = json.json.map((x: any) => x.name)
                } else {
                    this.$alert(JSON.stringify(json.json), 'Ошибка', 'error').then(() => {
                        this.hide()
                    })
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('NewMemberScriptGetShortFarms')
                if (this.isUpdate && this.memberForUpdate) {
                    this.setLoadingDataNames('newMemberFetchUpdateInfo')
                    httpGet({
                        url: `${apiUrl}/workspace/get/employee/info/${this.memberForUpdate.id}`,
                        isSecureRequest: true,
                        detailResponse: true,
                        onSuccess: json => {
                            if (json.ok) {
                                for (const farm of json.json.group_users) {
                                    if (!this.member.farms.origin.find((x: any) => x.id === farm.id)) {
                                        this.member.farms.origin.push(farm)
                                        this.member.farms.converted.push(farm.name)
                                    }
                                    const originFarm = this.member.farms.origin.find((x: any) => x.id === farm.id)
                                    if (originFarm) {
                                        this.member.farms.selected.push({
                                            id: farm.id,
                                            name: farm.name,
                                            onerror: '',
                                            fields: {
                                                origin: originFarm.divideds,
                                                selected: farm.divideds.map((x: any) => x.id),
                                                selectedNames: farm.divideds.map((x: any) => `№${x.name}`),
                                                onerror: ''
                                            }
                                        })
                                    }
                                }
                                this.member.IS_FAKE = json.json.is_fake
                                this.member.email.selectedName = json.json.user.email
                                this.member.username.selectedName = json.json.user.username
                                this.member.FIRST_NAME.selectedName = json.json.user.first_name
                                this.member.LAST_NAME.selectedName = json.json.user.last_name
                                this.member.permissions = json.json.permission_list
                                for (const role of json.json.roles) {
                                    const originRole = this.permissionModules.find((x: any) => x.num === role)
                                    if (originRole) {
                                        this.member.roles.selected.push(originRole.num)
                                        this.member.roles.selectedNames.push(originRole.name)
                                    } else {
                                        this.member.roles.selected.push(role)
                                    }
                                }
                            } else {
                                this.$alert(JSON.stringify(json.json), 'Ошибка', 'error').then(() => {
                                    this.hide()
                                })
                            }
                        },
                        onError: error => {
                            console.log(error)
                        },
                        doFinally: () => {
                            this.setLoadingDataNames('newMemberFetchUpdateInfo')
                        }
                    })
                } else {
                    this.member.farms.selected.push({
                        id: -1,
                        name: '',
                        onerror: '',
                        fields: {
                            origin: [],
                            selected: [],
                            selectedNames: [],
                            onerror: ''
                        }
                    })
                }
            }
        })
    }

    private addNewMember() {
        if (this.member.checkForErrors()) return
        this.setLoadingDataNames('NewMemberModalAddMember')
        httpPost({
            url: `${apiUrl}/workspace/add/role/workspace/`,
            isSecureRequest: true,
            detailResponse: true,
            body: this.member.getPostBody(),
            onSuccess: json => {
                if (json.ok) {
                    this.$alert(`${this.$i18n.t('messages.success.staff_added')}`, 'Добавлено', 'success').then(() => {
                        this.$emit('reload')
                        this.hide()
                    })
                } else {
                    this.$alert(JSON.stringify(json.json), 'Ошибка', 'error')
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('NewMemberModalAddMember')
            }
        })
    }

    private updateMember() {
        if (this.member.checkForErrors()) return
        this.setLoadingDataNames('newMemberUpdateMember')
        httpPut({
            url: `${apiUrl}/workspace/change/permissions/in/workspace/${this.memberForUpdate.id}`,
            isSecureRequest: true,
            detailResponse: true,
            body: this.member.getPutBody(),
            onSuccess: json => {
                if (json.ok) {
                    this.$alert(`${this.$i18n.t('messages.success.changes_saved')}`, 'Обновлено', 'success').then(() => {
                        this.$emit('reload')
                        this.hide()
                    })
                } else {
                    this.$alert(JSON.stringify(json.json), 'Ошибка', 'error')
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('newMemberUpdateMember')
            }
        })
    }

    private hide() {
        this.isShown = false
    }
}
