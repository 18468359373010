






















export interface TextWithIcon {
    icon: string
    title: string
}

import {Action, Mutation} from 'vuex-class'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import {ActionNames, MutationNames} from '@/store/types'

@Component
export default class ViewActivatorCard extends Vue {
    @Action(ActionNames.fetchCurrentFarmCadastres) private fetchCurrentFarmCadastres !: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    @Mutation(MutationNames.setSelectedCadastresFarm) private setSelectedCadastresFarm!: any
    @Prop({}) private data!: TextWithIcon[][]
    @Prop({default: false}) private active!: boolean
    @Prop({default: -1}) private farmId!: number

    private activateTable() {
        this.$emit('tableActivated',  !this.active)

        if (!this.active) {
          this.setSelectedCadastresFarm(this.farmId)
          this.fetchCurrentFarmCadastres()
        }
    }
}
