







































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Getter, Action, Mutation } from 'vuex-class'
import { GetterNames, ActionNames, MutationNames } from '@/store/types'
import { Route } from 'vue-router'
import { httpGet } from '@/util/http'
import { baseUrl, apiUrl } from '@/domain/constants'
import Farm from '@/models/Farm'
import { categories } from '@/data/agroChemCategories'
import i18n from '@/i18n'
import {egisticLogo, pdfWaterMark, pochvaPrint} from '@/data/pdfImageData'
import { makePDF, PdfActions } from '@/utils/services'
import * as turf from '@turf/turf'

@Component
export default class AgroChem extends Vue {
    @Action(ActionNames.fetchFarmsList) private fetchFarmsList!: any
    @Action(ActionNames.updateMapDataInRegion) private updateMapDataInRegion!: any

    @Getter(GetterNames.getCurrentRoute) private currentRoute !: Route
    @Getter(GetterNames.getFarmFromRoute) private getFarmFromRoute!: Farm
    @Getter(GetterNames.getFarms) private getFarms!: any
    @Getter(GetterNames.getMapData) private getMapData!: any
    @Getter(GetterNames.getAgroChemResult) private getAgroChemResult!: any
    @Getter(GetterNames.getSidebarModel) private getSidebarModel!: any
    @Getter(GetterNames.getMapScreen) private getMapScreen!: any

    @Mutation(MutationNames.setAgroChemResult) private setAgroChemResult!: any
    @Mutation(MutationNames.setMapScreen) private setMapScreen!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    private egisticLogo: any = egisticLogo
    private pdfWaterMark: any = pdfWaterMark
    private pochvaPrint: any = pochvaPrint
    private resultCategories = categories
    private farmName = ''
    private fieldDropdown = false
    private indicatorDropdown = false
    private selectedIndicatorIndex = 0
    private agroChemList: any = {
        data: [],
        selected: {
            created_at: '',
            id: 0
        },
        dropdown: false
    }
    private resultFile = null
    private stamp = null
    private logo = null

    private normalizeDate(date: any) {
        if (date === '') {
            return i18n.t('general.dates.format_date')
        } else {
            return date.substring(8, 10) + '.' + date.substring(5, 7) + '.' + date.substring(0, 4)
        }
    }

    private getUrl(url: any) {
        return `${baseUrl}${url}`
    }

    private setSelectedAgroAnalysis(result: any) {
        if (this.agroChemList.selected.id !== result.id) {
            this.agroChemList.selected = result
            this.getAgroAnalysisResult(result.id)
        }
        this.closeAgroChemListDropdown()
    }

    private currentIndicator() {
        return this.resultCategories[this.selectedIndicatorIndex]
    }

    private rangeToString(lower: any, upper: any) {
        if (lower === null) {
            return '< ' + upper
        } else if (upper === null) {
            return '> ' + lower
        } else {
            return lower + ' - ' + upper
        }
    }

    private downloadMap() {
        this.setLoadingDataNames('agroChemDownloadMap')
        const farm = this.getFarms.find((x: any) => x.id === parseInt(this.currentRoute.params.farmId, 10))
        const fields = farm.fields.filter((x: any) => this.getAgroChemResult.selectedFields.includes(x.id))
        let Xmin = Number.MAX_SAFE_INTEGER
        let Xmax = Number.MIN_SAFE_INTEGER
        let Ymin = Number.MAX_SAFE_INTEGER
        let Ymax = Number.MIN_SAFE_INTEGER
        for (const field of fields) {
            const bbox = turf.bbox(turf.multiPolygon(field.geom.coordinates))
            if (bbox) {
                Xmax = bbox[0] > Xmax ? bbox[0] : Xmax
                Xmin = bbox[0] < Xmin ? bbox[0] : Xmin
                Ymax = bbox[1] > Ymax ? bbox[1] : Ymax
                Ymin = bbox[1] < Ymin ? bbox[1] : Ymin
            }
        }
        this.updateMapDataInRegion({
            boundaries: [Xmin, Ymin, Xmax, Ymax],
            center: [(Xmax + Xmin) / 2, (Ymax + Ymin) / 2]
        })
        this.setMapScreen({start: !this.getMapScreen.start, blobImg: null})
    }

    @Watch('getMapScreen.start')
    private async downloadPdf() {
        if (this.getMapScreen.start || !this.getMapScreen.blobImg) return
        const farm = this.getFarms.find((x: any) => x.id === parseInt(this.currentRoute.params.farmId, 10))
        const docDefinition: any = {
            pageSize: {
                width: 595,
                height: 842
            },
            pageMargins: [24, 16, 24, 8],
            background: [
                {
                    image: this.pdfWaterMark,
                    margin: [139, 0, 0, 0]
                }
            ],
            content: [
                {
                    text: i18n.t('headers.workspace.farm.agrochem_analys.report'),
                    fontSize: 16,
                    bold: true,
                    alignment: 'center',
                    margin: [0, 24, 0, 0]
                },
                {
                    text: i18n.t('headers.workspace.fields.indicators.indicator') + ': ' + this.currentIndicator().name,
                    fontSize: 16,
                    bold: true,
                    alignment: 'center'
                },
                {
                    text: i18n.t('headers.workspace.farm.title') + ': ' + farm.name + ': ' + this.getFieldsNumberString(),
                    fontSize: 16,
                    margin: [0, 16, 0, 0]
                },
                {
                    image: this.getMapScreen.blobImg,
                    width: 547,
                    fit: [547, 300],
                    alignment: "center"
                },
                {
                    text: this.currentIndicator().header,
                    color: '#878882',
                    fontSize: 12,
                    margin: [0, 16, 0, 0],
                    alignment: 'center'
                },
                {
                    table: {
                        widths: [ 77, 120, 120],
                        headerRows: 1,
                        body: this.createTableContent()
                    },
                    margin: [115, 4, 0, 0]
                }
            ]
        }
        if (this.logo) {
            docDefinition.content.unshift({
                image: await this.imageToBase64(this.logo),
                margin: [0, 0, 0, 0],
                height: 50,
                alignment: 'center'
            })
        } else {
            docDefinition.content.unshift({
                image: this.egisticLogo,
                margin: [0, 0, 0, 0],
                width: 91.14,
                height: 30,
                alignment: 'center'
            })
        }
        if (this.stamp) {
            docDefinition.content.push({
                image: await this.imageToBase64(this.stamp),
                margin: [16, 32, 24, 0],
                width: 256,
                height: 108,
                alignment: 'right'
            })
        }
        makePDF(PdfActions.DOWNLOAD, docDefinition)
        this.setLoadingDataNames('agroChemDownloadMap')
    }

    private imageToBase64(url: any) {
        return new Promise((resolve, reject) => {
            const img = new Image()
            img.setAttribute("crossOrigin", "anonymous")
            img.src = this.getUrl(url)
            img.onload = () => {
                const canvas = document.createElement("canvas")
                canvas.width = img.width
                canvas.height = img.height
                const ctx: any = canvas.getContext("2d")
                ctx.drawImage(img, 0, 0)
                const dataURL = canvas.toDataURL("image/png")
                resolve(dataURL)
            }
        })
    }

    private createTableContent() {
        const body: any = []
        for (const range of this.currentIndicator().indicators) {
            body.push([
                {text: '', fillColor: range.color},
                {text: this.rangeToString(range.lower, range.upper), alignment: 'center', fontSize: 12},
                {text: range.value, alignment: 'center', fontSize: 12}
            ])
        }
        return body
    }

    @Watch('getFarmFromRoute')
    private onchangeFarm(val: any, old: any) {
        if (val !== old) {
            this.getData()
        }
    }

    private mounted() {
        this.fetchFarmsList()
        this.setAgroChemResult({
            selectedFields: [],
            analysisResult: [],
            mapSelection: false
        })
        this.getData()
    }

    private getData() {
        if (this.getFarmFromRoute) {
            this.farmName = this.getFarmFromRoute.name
            this.getAgroAnalysisList(this.getFarmFromRoute.id)
        }
    }

    private getAgroAnalysisList(farmId: any) {
        this.setLoadingDataNames('agroChemGetAgroChemList')
        httpGet({
            url: `${apiUrl}/agro_analysis/short/by/farm/${farmId}/`,
            isSecureRequest: true,
            onSuccess: json => {
                this.agroChemList.data = json
                if (json.length > 0) {
                    if (json[0].hasOwnProperty('id')) {
                        this.agroChemList.selected = json[0]
                        this.chooseAll()
                        this.getAgroAnalysisResult(this.agroChemList.selected.id)
                    }
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('agroChemGetAgroChemList')
            }
        })
    }

    private getAgroAnalysisResult(id: any) {
        this.setLoadingDataNames('agroChemGetResults')
        httpGet({
            url: `${apiUrl}/agro_analysis/agro/request/by/id/${id}/`,
            isSecureRequest: true,
            onSuccess: json => {
                if (json.hasOwnProperty('agro_analysis')) {
                    this.setAgroChemResult({analysisResult: json.agro_analysis})
                    this.resultFile = json.result_file
                    this.stamp = json.stamp
                    this.logo = json.logo
                } else {
                    this.setAgroChemResult({analysisResult: []})
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('agroChemGetResults')
            }
        })
    }

    private getCurrentFarmFields() {
        if (this.getFarmFromRoute) {
            return this.getFarmFromRoute.fields
        }
        return []
    }

    private getFieldsNumberString() {
        const farm = this.getFarms.find((x: any) => x.name === this.farmName)
        if (!farm) return ''
        if (this.getAgroChemResult.selectedFields.length === 0) {
            return i18n.t('messages.not_chosen')
        } else if (farm.fields.length === this.getAgroChemResult.selectedFields.length) {
            return i18n.t('messages.selections.all_fields')
        } else {
            let str = ''
            for (const field of farm.fields) {
                if (this.getAgroChemResult.selectedFields.includes(field.id)) {
                    str += '№' + (field.name ? field.name : field.number) + ', '
                }
            }
            if (str.length > 24) {
                return str.substring(0, 22) + '...'
            }
            return str
        }
    }

    private chooseAll() {
        const farm = this.getFarms.find((x: any) => x.name === this.farmName)
        if (farm.fields.length === this.getAgroChemResult.selectedFields.length) {
            this.setAgroChemResult({selectedFields: []})
        } else {
            const tempArr = []
            for (const field of farm.fields) {
                tempArr.push(field.id)
            }
            this.setAgroChemResult({selectedFields: tempArr})
        }
    }

    private changeSelectedField(fieldId: any) {
        const tempArr = this.getAgroChemResult.selectedFields
        if (tempArr.includes(fieldId)) {
            tempArr.splice(tempArr.indexOf(fieldId), 1)
        } else {
            tempArr.push(fieldId)
        }
        this.setAgroChemResult({selectedFields: tempArr})
    }

    private changeSelectedCategory(index: any) {
        this.selectedIndicatorIndex = index
        this.indicatorDropdown = false
        this.setAgroChemResult({selectedCategory: index})
    }

    private setMapSelection() {
        this.setAgroChemResult({mapSelection: !this.getAgroChemResult.mapSelection})
    }

    private openUploadAgroChem() {
        this.$router.push({
            name: 'uploadagrochemresult',
            params: {
                farmId: this.currentRoute.params.farmId
            }
        })
    }

    private openOrderAgroChem() {
        this.$router.push(`/orderagrochem/${this.currentRoute.params.farmId}`)
    }

    private close() {
        this.$router.push(`/cadastres/farm/${this.currentRoute.params.farmId}`)
    }

    private closeIndicatorDropdown() {
        this.indicatorDropdown = false
    }

    private closeAgroChemListDropdown() {
        this.agroChemList.dropdown = false
    }

    private closeFieldDropdown() {
        this.fieldDropdown = false
    }
}
