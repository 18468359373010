

























































































import {Vue, Component} from "vue-property-decorator"
import { CultureReproduction } from '@/data/FieldCulture'
import {httpGet, httpPost} from "@/util/http"
import {apiUrl} from "@/domain/constants"
import {Mutation} from "vuex-class"
import {MutationNames} from "@/store/types"
@Component
export default class PlanningAddSeed extends Vue {
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any

    private CultureReproduction: any = CultureReproduction
    private isShown = false
    private preparationId = -1
    private totalArea = 0
    private taskId = -1
    private technology = {
        reproduction: '',
        cultureName: '',
        cultures: {
            origin: [],
            converted: []
        },
        sorts: {
            origin: [],
            converted: []
        },
        sort: ''
    }

    private seed = {
        origin: new Array(),
        converted: new Array(),
        selected: ''
    }

    private preparation = {
        raw_spacing: '',
        plant_distance: '',
        sowing_depth: '',
        sowing_density: '',
        sowing_density_measure: {
            id: 0,
            name: ''
        },
        price: '',
        total: 0 as any
    }

    private getReproduction() {
        return this.CultureReproduction.map((x: any) => x.name)
    }

    private onSelectSort(item: string) {
        this.technology.sort = item
    }

    private onSelectReproduction(item: any) {
        this.technology.reproduction = item
    }

    private onSelectSeedMeasure(item: any) {
        const currentMeasure = this.seed.origin.find((x: any) => x.name === item)
        if (currentMeasure) {
            this.preparation.sowing_density_measure = currentMeasure
        }
    }

    private getPreparationTotal() {
        const total = (Number(this.preparation.sowing_density) * Number(this.preparation.price) * Number(this.totalArea) / 1000).toFixed(0)
        this.preparation.total = total
        return total
    }

    private getPreparations(cultureName: any) {
        httpGet({
            url:  `${apiUrl}/techmap/get/seeds/by/culture/?culture=${cultureName}`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                if (json.ok) {
                    this.seed.origin = json.json.measures
                    this.seed.converted = json.json.measures.map((x: any) => x.name)
                    this.preparation.sowing_density_measure = json.json.measures[0]
                }
            },
            onError: error => {
                console.log(error)
            }
        })
    }

    private checkForError() {
        console.log(this.preparation)
        let counter = 0
        if (this.preparation.raw_spacing === '') {
            Vue.alert('', 'Введите Междурядное расстояние', 'warning')
            counter++
        }
        if (this.preparation.plant_distance === '') {
            Vue.alert('', 'Введите Расстояние между растениями', 'warning')
            counter++
        }
        if (this.preparation.sowing_depth === '') {
            Vue.alert('', 'Введите Глубину посева', 'warning')
            counter++
        }
        if (this.preparation.sowing_density === '') {
            Vue.alert('', 'Введите Густоту посева', 'warning')
            counter++
        }
        if (this.preparation.price === '') {
            Vue.alert('', 'Введите цену', 'warning')
            counter++
        }
        console.log(counter)
        return counter === 0
    }

    private createSeed() {
        if (!this.checkForError()) return
        this.setLoadingDataNames('modelGetCars')
        httpPost({
            url:  `${apiUrl}/technology/task/seed/create/`,
            isSecureRequest: true,
            detailResponse: true,
            body: {
                plan_task: this.taskId,
                type: 1,
                name: this.technology.sort,
                raw_spacing: this.preparation.raw_spacing,
                plant_distance: this.preparation.plant_distance,
                sowing_depth: this.preparation.sowing_depth,
                sowing_density: this.preparation.sowing_density,
                sowing_density_measure: this.preparation.sowing_density_measure.id,
                price: this.preparation.price,
                cost: this.preparation.total.toFixed(0)
            },
            onSuccess: json => {
                if (json.ok) {
                    Vue.alert('', 'Семена успешно создано', 'success').then(() => {
                        this.hide()
                    })
                } else {
                    this.$alert('Ошибка сервера', 'Ошибка', 'error')
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('modelGetCars')
            }
        })
    }

    private getListSort(cultureName: any) {
        let currentCulture: any = null
        this.technology.cultures.origin.forEach((x: any) => {
            if (x.cultures.find((y: any) => y.name === cultureName)) {
                currentCulture = x.cultures.find((l: any) => l.name = cultureName)
            }
        })
        if (cultureName) {
            this.technology.cultureName = currentCulture.name
            this.technology.sorts.converted = currentCulture.sorts
            this.technology.sort = ''
        }
    }

    private fetchCultures() {
        httpGet({
            url:  `${apiUrl}/get/culture/static/`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                if (json.ok) {
                    this.technology.cultures.origin = json.json
                    this.technology.cultures.converted = json.json.flatMap((x: any) => x.cultures.map((y: any) => y.name))
                }
            },
            onError: error => {
                console.log(error)
            }
        })
    }

    private show(id: any, cultureName: any, area: any, taskId: any) {
        this.getPreparations(cultureName)
        this.preparationId = id
        this.totalArea = area
        this.taskId = taskId
        this.isShown = true
        this.getListSort(cultureName)
        this.fetchCultures()
    }

    private hide() {
        this.isShown = false
    }
}
