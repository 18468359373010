







import { Component, Vue } from 'vue-property-decorator'
import MapView from './MapView/MapView.vue'
import MapControllers from './MapControllers/MapControllers.vue'
@Component({
    components: {
        MapView,
        MapControllers
    }
})
export default class MapContainer extends Vue {
}
