










































import { Component, Vue } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import {GetterNames, MutationNames } from '@/store/types'

@Component
export default class GeozoneEdit extends Vue {
    @Getter(GetterNames.getTelematicsInfo) private getTelematicsInfo!: any
    @Mutation(MutationNames.setTelematicsInfo) private setTelematicsInfo!: any

    private activateButton(editType: any) {
        if (this.getTelematicsInfo.geozone.type !== editType) {
            this.setTelematicsInfo({geozone: {type: editType}})
        }
    }

    private getHelpInfoText() {
        if (this.getTelematicsInfo.geozone.edit === 1) {
            if (this.getTelematicsInfo.geozone.type === 'edit') {
                return 'Редактируйте и сохраните геозону'
            } else if (this.getTelematicsInfo.geozone.type === 'vertix') {
                return 'Зажмите кнопку Alt на клавиатуре и выберите вершину для удаления'
            } else if (this.getTelematicsInfo.geozone.type === 'vertices') {
                return 'Нарисуйте область содержащую точки для удаления'
            } else if (this.getTelematicsInfo.geozone.type === 'road') {
                return 'Нарисуйте дорогу'
            }
        } else if (this.getTelematicsInfo.geozone.edit === 2) {
            return 'Не забудьте сохранить или отменить изменение'
        } else {
            return 'none'
        }
    }

    private cancelEditing() {
        this.setTelematicsInfo({geozone: {edit: 3}})
    }

    private saveEditing() {
        this.setTelematicsInfo({geozone: {edit: 4}})
    }
}
