














































import { Component, Vue, Watch } from 'vue-property-decorator'
import Sideboard from '@/components/Sideboard.vue'
import { GetterNames, MutationNames } from '@/store/types'
import { Getter, Mutation } from 'vuex-class'
import { Route } from 'vue-router'
import Farm from '@/models/Farm'
import i18n from '@/i18n'
import AgregatesView from './components/AgregatesView.vue'
import FieldEventsView from './components/FieldEventsView.vue'
import NewComplexModel from './components/NewComplexModel.vue'
import NewTractorModel from './components/NewTractorModel.vue'
import ChangeAgregateModal from '@/views/MainPage/HomeView/Sideboards/FarmBoard/TechMapBoard/components/ChangeAgregateModel.vue'
@Component({
    components: {
      NewTractorModel,
      Sideboard,
      AgregatesView,
      NewComplexModel,
      FieldEventsView,
      ChangeAgregateModal
    }
})
export default class FarmTelematics extends Vue {
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: Route
    @Getter(GetterNames.getFarmFromRoute) private getFarmFromRoute!: Farm
    @Getter(GetterNames.getLoadingDataNames) private getLoadingDataNames!: any
    @Getter(GetterNames.getTelematicsInfo) private getTelematicsInfo!: any

    @Mutation(MutationNames.setActiveIndicator) private setActiveIndicator!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    @Mutation(MutationNames.setLoadingTractorInfo) private setLoadingTractorInfo!: any
    @Mutation(MutationNames.setIsDataLoading) private setIsDataLoading!: any
    @Mutation(MutationNames.setTelematicsInfo) private setTelematicsInfo!: any

    private mounted() {
        this.setTelematicsInfo({
            by_agregate: true,
            farmId: Number(this.currentRoute.params.farmId),
            geozone: {
                data: null,
                view: false,
                edit: 0,
                type: 'edit'
            },
            folders: [],
            tractorsData: [],
            autoplay: false,
            tracktime: 0,
            autoTracking: {
                mainTractorID: -1,
                tracking: false
            },
            arrowsView: false,
            speedLimitsView: false,
            stopsView: false,
            fieldEvent: {
                reportType: 1,
                event: null,
                tractor: null,
                flaws: false,
                overlaps: false
            },
            controlPanelExtension: false
        })
        this.setActiveIndicator('')
        this.setLoadingTractorInfo('clean')
        this.setIsDataLoading(false)
    }

    @Watch('currentRoute.name')
    private changeDateHistory(val: any) {
        console.log(val, ';')
    }

    private openFarm() {
        this.$router.push(`/cadastres/farm/${this.getFarmFromRoute.id}`)
    }

    private openFieldTelematics(id: any) {
        this.$router.push(`/cadastres/farm/${this.getFarmFromRoute.id}/subfields/${id}/telematics`)
    }
}
