



















































































































































































import { Component, Vue, Emit } from 'vue-property-decorator'
import { httpGet, httpPost } from '@/util/http'
import { apiUrl } from '@/domain/constants'
import { GetterNames, MutationNames } from '@/store/types'
import { Getter, Mutation } from 'vuex-class'
import i18n from '@/i18n'
import {operationData} from '@/data/newOperationData'
import NewProductModel from './NewProductModel.vue'
@Component({
    components: {
        NewProductModel
    }
})
export default class NewOperationModel extends Vue {
    @Getter(GetterNames.getLoadingDataNames) private getLoadingDataNames!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    private isShown = false
    private newOperationData: any = operationData
    private storages: any = []
    private members: any = []
    private agregates: any = []
    private operation: any = {
        farm: '',
        farmId: -1,
        storageId: -1,
        storageName: '',
        operation: '',
        supplier: '',
        client: '',
        event: '',
        customer: '',
        operationDate: '',
        incomeType: '',
        outcomeType: '',
        transferStorage: {
            name: '',
            id: -1
        },
        employee: {
            name: '',
            id: -1
        },
        agregate: {
            name: '',
            id: -1
        },
        products: [],
        current_products: [],
        operationDropdown: false,
        secondDropdown: false,
        thirdDropdown: false
    }
    private operationTypes: any = [
        i18n.t('headers.workspace.storage.logs.income'),
        i18n.t('headers.workspace.storage.logs.expense'),
        i18n.t('headers.workspace.storage.logs.movement'),
        i18n.t('headers.workspace.storage.logs.return'),
        i18n.t('headers.workspace.storage.logs.writeoff')]
    private incomeTypes: any = [
        i18n.t('headers.workspace.storage.logs.income_type.supplier'),
        i18n.t('headers.workspace.storage.logs.income_type.employee'),
        i18n.t('headers.workspace.storage.logs.income_type.return'),
        i18n.t('headers.workspace.storage.logs.income_type.event')]
    private outcomeTypes: any = [
        i18n.t('headers.workspace.storage.logs.expense_type.employee'),
        i18n.t('headers.workspace.storage.logs.expense_type.customer'),
        i18n.t('headers.workspace.storage.logs.expense_type.refill'),
        i18n.t('headers.workspace.storage.logs.expense_type.maintenance')]

    get getOperationDate() {
        if (this.operation.operationDate === '') return i18n.t('general.dates.format_date')
        return this.operation.operationDate.substring(8, 10) + '.' + this.operation.operationDate.substring(5, 7) + '.' + this.operation.operationDate.substring(2, 4)
    }

    get getSecondDropdown() {
        if (this.operation.operation === i18n.t('headers.workspace.storage.logs.income')) {
            return this.operation.incomeType
        }
        if (this.operation.operation === i18n.t('headers.workspace.storage.logs.expense')) {
            return this.operation.outcomeType
        }
        if (this.operation.operation === i18n.t('headers.workspace.storage.logs.movement')) {
            return this.operation.transferStorage.name
        }
    }

    get getThirdDropdown() {
        if (this.getThirdDropdownInfo('header').header === i18n.t('headers.workspace.storage.logs.income_type.employee')) {
            return this.operation.employee.name
        } else if (this.getThirdDropdownInfo('header').header === i18n.t('headers.workspace.storage.logs.equipment')) {
            return this.operation.agregate.name
        }
    }

    get getProductTableVisibility() {
        if (this.operation.operation === i18n.t('headers.workspace.storage.logs.income')) {
            if (this.operation.incomeType !== '') {
                return true
            }
        } else if (this.operation.operation === i18n.t('headers.workspace.storage.logs.expense')) {
            if (this.operation.outcomeType !== '') {
                return true
            }
        } else if (this.operation.operation !== '') {
            return true
        }
        return false
    }

    private getSecondDropdownInfo(type: any) {
        if (type === 'array') {
            if (this.operation.operation === i18n.t('headers.workspace.storage.logs.movement')) {
                return this.storages.filter((x: any) => x.group_users === this.operation.farmId && x.id !== this.operation.storageId)
            } else {
                return this.newOperationData.find((x: any) => x.title === this.operation.operation).types
            }
        }
    }

    private getThirdDropdownInfo(type: any) {
        const operation = this.newOperationData.find((x: any) => x.title === this.operation.operation)
        if (operation) {
            if (operation.title === i18n.t('headers.workspace.storage.logs.income')) {
                if (type === 'header') {
                    return operation.types.find((x: any) => x.title === this.operation.incomeType)
                } else {
                    return this.members
                }
            } else if (operation.title === i18n.t('headers.workspace.storage.logs.expense')) {
                if (type === 'header') {
                    return operation.types.find((x: any) => x.title === this.operation.outcomeType)
                } else {
                    if (operation.types.find((x: any) => x.title === this.operation.outcomeType).title === i18n.t('headers.workspace.storage.logs.income_type.employee')) {
                        return this.members
                    } else {
                        return this.agregates
                    }
                }
            }
        }
    }

    private setSecondDropdown(type: any) {
        this.operation.supplier = ''
        this.operation.client = ''
        this.operation.event = ''
        this.operation.customer = ''
        this.operation.client = ''
        this.operation.client = ''
        this.operation.operationDate = ''
        this.operation.incomeType = ''
        this.operation.outcomeType = ''
        this.operation.transferStorage = {
            name: '',
            id: -1
        }
        this.operation.employee = {
            name: '',
            id: -1
        }
        this.operation.agregate = {
            name: '',
            id: -1
        }
        if (this.operation.operation === i18n.t('headers.workspace.storage.logs.income')) {
            this.operation.incomeType = type.title
        }
        if (this.operation.operation === i18n.t('headers.workspace.storage.logs.expense')) {
            this.operation.outcomeType = type.title

        }
        if (this.operation.operation === i18n.t('headers.workspace.storage.logs.movement')) {
            this.operation.transferStorage.name = type.title
            this.operation.transferStorage.id = type.id
        }
    }

    private setThirdDropdown(type: any) {
        if (this.getThirdDropdownInfo('header').header === i18n.t('headers.workspace.storage.logs.income_type.employee')) {
            this.operation.employee = {
                name: type.user_fullname,
                id: type.user_id
            }
        } else if (this.getThirdDropdownInfo('header').header === i18n.t('headers.workspace.storage.logs.equipment')) {
            this.operation.agregate = {
                name: type.name,
                id: type.id
            }
        }
    }

    private setOperationType(type: any) {
        if (this.operation.operation !== type) {
            this.operation.operation = type
            this.operation.supplier = ''
            this.operation.client = ''
            this.operation.event = ''
            this.operation.customer = ''
            this.operation.client = ''
            this.operation.client = ''
            this.operation.operationDate = ''
            this.operation.incomeType = ''
            this.operation.outcomeType = ''
            this.operation.transferStorage = {
                name: '',
                id: -1
            }
            this.operation.employee = {
                name: '',
                id: -1
            }
            this.operation.agregate = {
                name: '',
                id: -1
            }
            this.operation.operationDropdown = false
        }
    }

    private getNewProduct(product: any) {
        this.operation.products.push(product)
    }

    private addNewProduct() {
        const model: any = this.$refs.newProductModel as any
        if (this.operation.operation === i18n.t('headers.workspace.storage.logs.income')) {
            model.show(null)
        } else {
            model.show(this.operation.current_products)
        }
    }

    private deleteProduct(index: any) {
        this.operation.products.splice(index, 1)
    }

    private show(storage: any) {
        this.isShown = true
        this.operation = {
            farm: storage.group_users_name,
            farmId: storage.group_users,
            storageId: storage.id,
            storageName: storage.title,
            operation: '',
            supplier: '',
            client: '',
            event: '',
            customer: '',
            operationDate: '',
            incomeType: '',
            outcomeType: '',
            transferStorage: {
                name: '',
                id: -1
            },
            employee: {
                name: '',
                id: -1
            },
            agregate: {
                name: '',
                id: -1
            },
            products: [],
            current_products: storage.current_products,
            operationDropdown: false,
            secondDropdown: false,
            thirdDropdown: false
        }
        this.getData()
    }

    private getData() {
        this.setLoadingDataNames('newOperationGetStorages')
        this.setLoadingDataNames('newOperationGetMembers')
        this.setLoadingDataNames('newOperationsGetTractors')
        httpGet({
            url:  `${apiUrl}/storage/`,
            isSecureRequest: true,
            onSuccess: json => {
                this.storages = json
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('newOperationGetStorages')
            }
        })
        httpGet({
            url:  `${apiUrl}/workspace/get/employee/by/storage/`,
            isSecureRequest: true,
            onSuccess: json => {
                this.members = json
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('newOperationGetMembers')
            }
        })
        httpGet({
            url:  `${apiUrl}/yields/tractor/`,
            isSecureRequest: true,
            onSuccess: json => {
                this.agregates = json
            },
            onError: error => {
            console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('newOperationsGetTractors')
            }
        })
    }

    private addNewOperation() {
        if (this.getLoadingDataNames('newOperationAddOperation')) return
        if (!this.checkForErrors()) return
        this.setLoadingDataNames('newOperationAddOperation')
        let operationName: any = ''
        let operationBody: any = null
        const productInfo: any = []
        for (const product of this.operation.products) {
            if (product.id !== -1) {
                productInfo.push({
                    id: product.id,
                    title: product.name,
                    amount: Number(product.amount),
                    unit: product.unit,
                    cost: Number(product.cost)
                })
            } else {
                productInfo.push({
                    title: product.name,
                    amount: Number(product.amount),
                    unit: product.unit,
                    cost: Number(product.cost)
                })
            }
        }
        if (this.operation.operation === i18n.t('headers.workspace.storage.logs.income')) {
            operationName = 'Income'
            const types: any = this.newOperationData.find((x: any) => x.title === i18n.t('headers.workspace.storage.logs.income')).types
            let incomeName = null
            if (this.operation.incomeType === i18n.t('headers.workspace.storage.logs.income_type.supplier')) {
                incomeName = this.operation.supplier
            }
            if (this.operation.incomeType === i18n.t('headers.workspace.storage.logs.income_type.return')) {
                incomeName = this.operation.client
            }
            operationBody = {
                type: types.find((x: any) => x.title === this.operation.incomeType).index,
                name:  incomeName,
                event_info: this.operation.event === '' ? null : this.operation.event,
                employee: this.operation.employee.id === -1 ? null : this.operation.employee.id,
                storage: this.operation.storageId,
                date: this.operation.operationDate,
                product_info_create: productInfo

            }
        }
        if (this.operation.operation === i18n.t('headers.workspace.storage.logs.expense')) {
            operationName = 'Outcome'
            const types: any = this.newOperationData.find((x: any) => x.title === i18n.t('headers.workspace.storage.logs.expense')).types
            operationBody = {
                type: types.find((x: any) => x.title === this.operation.outcomeType).index,
                name: this.operation.customer === '' ? null : this.operation.customer,
                tractor: this.operation.agregate.id === -1 ? null : this.operation.agregate.id,
                employee: this.operation.employee.id === -1 ? null : this.operation.employee.id,
                storage: this.operation.storageId,
                date: this.operation.operationDate,
                product_info_create: productInfo
            }
        }
        if (this.operation.operation === i18n.t('headers.workspace.storage.logs.movement')) {
            operationName = 'Transfer'
            operationBody = {
                storage: this.operation.storageId,
                date: this.operation.operationDate,
                destination: this.operation.transferStorage.id,
                product_info_create: productInfo
            }
        }
        if (this.operation.operation === i18n.t('headers.workspace.storage.logs.return')) {
            operationName = 'Return'
            operationBody = {
                supplier: this.operation.supplier,
                storage: this.operation.storageId,
                date: this.operation.operationDate,
                product_info_create: productInfo
            }
        }
        if (this.operation.operation === i18n.t('headers.workspace.storage.logs.writeoff')) {
            operationName = 'WriteOff'
            operationBody = {
                storage: this.operation.storageId,
                date: this.operation.operationDate,
                product_info_create: productInfo
            }
        }
        httpPost({
            url: `${apiUrl}/storage/operation/create/new/?operation_name=${operationName}`,
            isSecureRequest: true,
            body: operationBody,
            onSuccess: json => {
                console.log(json)
            },
            doFinally: () => {
                this.setLoadingDataNames('newOperationAddOperation')
                this.hide()
                this.reload()
            }
        })
    }

    private checkForErrors() {
        if (!this.isShown) return
        if (this.operation.operation === '') {
            alert(i18n.t('messages.selections.operation'))
            return false
        }
        if (this.operation.operation === i18n.t('headers.workspace.storage.logs.income')) {
            if (this.operation.incomeType === '') {
                alert(i18n.t('messages.selections.income_type'))
                return false
            }
            if (this.operation.incomeType === i18n.t('headers.workspace.storage.logs.income_type.supplier')) {
                if (this.operation.supplier === '') {
                    alert(i18n.t('messages.selections.supplier'))
                    return false
                }
            }
            if (this.operation.incomeType === i18n.t('headers.workspace.storage.logs.income_type.employee')) {
                if (this.operation.employee.id === -1) {
                    alert(i18n.t('messages.selections.staff'))
                    return false
                }
            }
            if (this.operation.incomeType === i18n.t('headers.workspace.storage.logs.income_type.return')) {
                if (this.operation.client === '') {
                    alert(i18n.t('messages.selections.customer_fullname'))
                    return false
                }
            }
            if (this.operation.incomeType === i18n.t('headers.workspace.storage.logs.income_type.event')) {
                if (this.operation.event === '') {
                    alert(i18n.t('messages.selections.event'))
                    return false
                }
            }
        }
        if (this.operation.operation === i18n.t('headers.workspace.storage.logs.expense')) {
            if (this.operation.outcomeType === '') {
                alert(i18n.t('messages.selections.expense_type'))
                return false
            }
            if (this.operation.outcomeType === i18n.t('headers.workspace.storage.logs.expense_type.employee')) {
                if (this.operation.employee.id === -1) {
                    alert(i18n.t('messages.selections.staff'))
                    return false
                }
            }
            if (this.operation.outcomeType === i18n.t('headers.workspace.storage.logs.expense_type.customer')) {
                if (this.operation.customer === '') {
                    alert(i18n.t('messages.selections.curstomer_fullname'))
                    return false
                }
            }
            if (this.operation.outcomeType === i18n.t('headers.workspace.storage.logs.expense_type.refill') || this.operation.outcomeType === i18n.t('headers.workspace.storage.logs.expense_type.maintenance')) {
                if (this.operation.agregate.id === -1) {
                    alert(i18n.t('messages.selections.equipment'))
                    return false
                }
            }
        }
        if (this.operation.operation === i18n.t('headers.workspace.storage.logs.movement')) {
            if (this.operation.transferStorage.id === -1) {
                alert(i18n.t('messages.selections.storage'))
                return false
            }
        }
        if (this.operation.operation === i18n.t('headers.workspace.storage.logs.return')) {
            if (this.operation.supplier === '') {
                alert(i18n.t('messages.selections.supplier'))
                return false
            }
        }
        if (this.operation.operationDate === '') {
            alert(i18n.t('messages.selections.date_title'))
            return false
        }
        if (this.operation.products.length === 0) {
            alert(i18n.t('messages.instructions.add_title'))
            return false
        }
        return true
    }

    @Emit('reload')
    private reload() {
        return {}
    }

    private hide() {
        this.isShown = false
    }

    private closeOperationDropdown() {
        this.operation.operationDropdown = false
    }

    private closeSecondDropdown() {
        this.operation.secondDropdown = false
    }

    private closeThirdDropdown() {
        this.operation.thirdDropdown = false
    }
}
