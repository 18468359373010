












import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import {Getter} from 'vuex-class'
import {GetterNames} from '@/store/types'
import {clgreenGistogram, ndmiGistogram, ndviContrastedGistogram, ndviGistogram} from '@/data/gistogramData'
import * as turf from '@turf/turf'

@Component
export default class IndicatorsHover extends Vue {
    @Getter(GetterNames.getFieldFromRoute) private getFieldFromRoute!: any
    @Getter(GetterNames.getActiveIndicator) private getActiveIndicator!: any
    @Getter(GetterNames.getWeedinessImage) private getWeedinessImage!: any
    @Getter(GetterNames.getFertilizerResult) private getFertilizerResult!: any
    @Getter(GetterNames.getSubfieldIndicatorData) private getSubfieldIndicatorData!: any
    @Prop() private map!: any
    private contrastedColors: any = []

    private indicatorsTip: any = {
        active: false,
        value: '',
        coordinates: [0, 0]
    }

    @Watch('getWeedinessImage.visibility')
    private onchangeWeediness() {
        this.indicatorsTip.active = false
    }

    @Watch('getFertilizerResult.visibility')
    private onchangeFertilizer() {
        this.indicatorsTip.active = false
    }

    @Watch('getSubfieldIndicatorData.currentRequestId')
    private getContrastedColors(newVal: any) {
        const currentData = this.getSubfieldIndicatorData.data.find((data: any) => data.id === newVal)
        if (!currentData.contrasted_ndvi || !currentData.contrasted_ndvi.levels) return
        this.contrastedColors = ndviContrastedGistogram.map((data: any, index: number) => {
            return {
                color: data.color,
                from: Math.round(currentData.contrasted_ndvi.levels[index] * 1000) / 1000,
                to: Math.round(currentData.contrasted_ndvi.levels[index + 1] * 1000) / 1000
            }
        })
    }

    private onPointerMove(evt: any) {
        if (this.getWeedinessImage.image && this.getWeedinessImage.visibility) return
        if (this.getFertilizerResult.imgUrl && this.getFertilizerResult.visibility) return
        if (this.getFieldFromRoute) {
            const point = turf.point(evt.coordinate)
            const polygon = turf.polygon(this.getFieldFromRoute.geom.coordinates[0])
            if (turf.booleanContains(polygon, point)) {
                if (this.getActiveIndicator.length === 0) {
                    this.indicatorsTip.active = false
                    return
                }
                const currentlayer = this.getActiveIndicator
                const map = this.map.$map
                if (!map) {
                    this.indicatorsTip.active = false
                    return
                }
                map.once('rendercomplete', async (event: any) => {
                    const canvas = event.context.canvas
                    const magnitude = canvas.width / canvas.getBoundingClientRect().width
                    const x = evt.pixel[0] * magnitude
                    const y = evt.pixel[1] * magnitude
                    const c = canvas.getContext('2d')
                    const p = c.getImageData(x, y, 1, 1).data
                    const hex = "#" + ("000000" + this.rgbToHex(p[0], p[1], p[2])).slice(-6)
                    let ranges: any = []

                    if (currentlayer === 'ndvi') ranges = ndviGistogram
                    if (currentlayer === 'contrasted_ndvi') ranges = this.contrastedColors
                    if (currentlayer === 'ndmi') ranges = ndmiGistogram
                    if (currentlayer === 'gndvi') ranges = ndviGistogram
                    if (currentlayer === 'clgreen') ranges = clgreenGistogram
                    const range = ranges.find((val: any) => val.color === hex)
                    if (range) {
                        this.indicatorsTip.active = true
                        this.indicatorsTip.coordinates = evt.coordinate
                        this.indicatorsTip.value = range.to.toString()
                    } else if (hex === '#999999') {
                        this.indicatorsTip.active = true
                        this.indicatorsTip.coordinates = evt.coordinate
                        this.indicatorsTip.value = 'облако'
                    } else {
                        const minValue = {
                            value: -1,
                            min: Number.MAX_SAFE_INTEGER
                        }
                        for (const i of ranges) {
                            const diff = this.ColorDiff(i.color, hex)
                            if (diff < minValue.min) {
                                minValue.value = i.to
                                minValue.min = diff
                            }
                        }
                        if (minValue.min < 20) {
                            this.indicatorsTip.active = true
                            this.indicatorsTip.coordinates = evt.coordinate
                            this.indicatorsTip.value = minValue.value.toString()
                        } else {
                            this.indicatorsTip.active = false
                        }
                    }
                })
                map.renderSync()
            } else {
                this.indicatorsTip.active = false
            }
        }
    }

    private ColorDiff(c1: any, c2: any) {
        const aRgbHex1 = c1.substring(1, 7).match(/.{1,2}/g)
        const aRgbHex2 = c2.substring(1, 7).match(/.{1,2}/g)
        const aRgb1 = [
            parseInt(aRgbHex1[0], 16),
            parseInt(aRgbHex1[1], 16),
            parseInt(aRgbHex1[2], 16)
        ]
        const aRgb2 = [
            parseInt(aRgbHex2[0], 16),
            parseInt(aRgbHex2[1], 16),
            parseInt(aRgbHex2[2], 16)
        ]
        return Math.sqrt(Math.pow((aRgb1[0] - aRgb2[0]), 2) + Math.pow((aRgb1[1] - aRgb2[1]), 2) + Math.pow((aRgb1[2] - aRgb2[2]), 2))
    }

    private rgbToHex(r: any, g: any, b: any) {
        if (r > 255 || g > 255 || b > 255) {
            return ''
        } else {
            r = r.toString(16)
            g = g.toString(16)
            b = b.toString(16)
            if (r.length === 1) {
                r = "0" + r
            }
            if (g.length === 1) {
                g = "0" + g
            }
            if (b.length === 1) {
                b = "0" + b
            }
            return "#" + r + g + b
        }
    }
}
