import { render, staticRenderFns } from "./CultureChoose.vue?vue&type=template&id=6719a558&scoped=true&"
import script from "./CultureChoose.vue?vue&type=script&lang=ts&"
export * from "./CultureChoose.vue?vue&type=script&lang=ts&"
import style0 from "./CultureChoose.vue?vue&type=style&index=0&id=6719a558&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6719a558",
  null
  
)

export default component.exports