export default {
    main: {
        services: "Services we provide: ",
        to_farm: "Go to the farm",
        faq: {
            title: "Frequently asked questions: ",
            subtitle: "If you still have any questions, please call +7 705 739 3965."
        },
        for_pc: "PCs",
        for_mobile: "Mac devices",
        for_tablets: "Android devices",
        feedback: "Our users' feedback",
        media: "We are in the media: ",
        mobility: "Mobility and effectiveness. You can use Egistic on"
    },
    about_us: {
        team: "Our team",
        partners: "Our partners",
        support: "Our supporters"
    },
    services: "Egistic provides these services: ",
    news: {
        title: "News",
        others: "Other news",
        sections: {
            all: "all",
            news: "news",
            promotions: "promotions",
            updates: "updates"
        }
    },
    support: {
        webinars: {
            title: "Webinars",
            details: "Видеозаписи вебинаров от команды EGISTIC, в которых подробно рассказывается о том, как использовать нашу платформу для решения задач и к каким результатом это приведет. Также доступно расписание будущих вебинаров.",
            content: {
                future: "Upcoming webinars",
                past: "Recordings"
            }
        },
        lessons: {
            title: "Tutorial Videos",
            details: "Здесь Вы можете найти подробные пошаговые инструкции функционала EGISTIC для Вас и Ваших сотрудников, а также посмотреть обзор новых обновлений платформы.",
            content: {
                lessons: "Tutorial videos",
                where_start: "Where to start?",
                how_manage: "How to manage a farm?",
                features: "Features",
                all: "All videos"
            }
        },
        user_guide: {
            title: "User Guide",
            menu: "Content",
            details: "Руководство пользователя - это полезный ресурс для Вас и Вашей команды, представленный в виде текста и фотографий. Для Вашего удобства Руководство можно скачать в формате PDF.",
            content: {
                button: "Content",
                title: "«Egistic» services user guide"
            }
        }
    }
}