

















































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Getter, Action, Mutation } from 'vuex-class'
import {GetterNames, MutationNames, ActionNames} from '@/store/types'
import { Route } from 'vue-router'

import FarmFields from './components/FarmFields.vue'
import AllFarms from './components/AllFarms.vue'
import FieldRequests from './components/FieldRequests.vue'
import CadastreRequests from './components/CadastreRequests.vue'
import FieldCadastreRequests from './components/FieldCadastreRequests.vue'
import TaskRequests from './components/TaskRequests.vue'
import PlanningRequests from './components/PlanningRequests.vue'
import LayerGroup from './components/LayerGroup.vue'
import YieldLayer from './components/YieldLayer.vue'
import FertilizerResult from './components/FertilizerResult.vue'
import TelematicsContainer from './components/Telematics/TelematicsContainer.vue'
import NotificationMessages from './components/NotificationMessages.vue'

import RegionCadastres from './components/RegionCadastres.vue'
import RegisterFarm from './components/RegisterFarm.vue'
import FieldEdit from './components/FieldEdit.vue'
import OrderFarmData from './components/OrderFarmData.vue'

import newNote from './components/newNote.vue'
import SubfieldNotes from './components/SubfieldNotes.vue'
import SubNotes from './components/SubNotes.vue'

import AgroChemPoints from './components/AgroChemPoints.vue'
import AgroChemResult from './components/AgroChemResult.vue'
import AgroChemFieldResult from './components/AgroChemFieldResult.vue'
import AgroChemFielUpload from './components/AgroChemFileUpload.vue'

import Ruler from './components/Ruler.vue'
import IndicatorsHover from './components/IndicatorsHover.vue'
import MapScreen from './components/MapScreen.vue'
import WeatherMap from './components/WeatherMap.vue'

@Component({
    components: {
        LayerGroup,
        FarmFields,
        RegionCadastres,
        RegisterFarm,
        FieldEdit,
        FieldRequests,
        newNote,
        SubfieldNotes,
        SubNotes,
        AgroChemPoints,
        AgroChemResult,
        AgroChemFieldResult,
        AgroChemFielUpload,
        FertilizerResult,
        OrderFarmData,
        NotificationMessages,
        WeatherMap,
        TelematicsContainer,
        YieldLayer,
        Ruler,
        IndicatorsHover,
        MapScreen,
        CadastreRequests,
        TaskRequests,
        PlanningRequests,
        AllFarms,
        FieldCadastreRequests
    }
})
export default class MapView extends Vue {
    public $refs!: {
        map: any,
        vlView: any,
        regioncadastres: any,
        yieldlayer: any,
        ruler: any,
        indicatorshover: any
    }
    @Action(ActionNames.fetchFarmsList) private fetchFarmsList!: any
    @Action(ActionNames.updateMapDataInRegion) private updateMapDataInRegion!: any

    @Getter(GetterNames.getMapData) private mapData !: any
    @Getter(GetterNames.getFarmFromRoute) private getFarmFromRoute!: any
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: Route
    @Getter(GetterNames.getRulerMode) private getRulerMode!: any
    @Getter(GetterNames.getFieldFromRoute) private getFieldFromRoute!: any
    @Getter(GetterNames.getFieldsRating) private getFieldsRating!: any
    @Getter(GetterNames.getAnimateParameters) private getAnimateParameters!: any
    @Getter(GetterNames.getShowAllFarms) private getShowAllFarms!: any
    @Getter(GetterNames.getAddAllNote) private getAddAllNote!: any
    @Getter(GetterNames.getSubfieldNoteData) private getSubfieldNoteData!: any

    @Mutation(MutationNames.setMapWidth) private setMapWidth !: any
    @Mutation(MutationNames.setGeolocVisibility) private setGeolocVisibility!: any
    @Mutation(MutationNames.setFieldByAllnotes) private setFieldByAllnotes!: any
    @Mutation(MutationNames.setLastNewNotePoint) private setLastNewNotePoint!: any

    private entOnMap: any = null

    @Watch('getAnimateParameters', { immediate: true, deep: true })
    private onchangeAnimateParameters(val: any) {
        if (this.$refs.vlView) {
            this.$refs.vlView.animate(val)
        }
    }

    @Watch('getFieldsRating.fieldId')
    private ifSubfieldRatingChosen(val: any) {
        if (val) {
            this.$refs.vlView.animate({
                center: this.getFarmFromRoute.getCenter(val.toString()),
                zoom: Math.floor(this.getFarmFromRoute.getZoom(val.toString())),
                duration: 800
            })
        }
    }

    @Watch('currentRoute.params.farmId')
    private onchangeFarmId(val: any, old: any) {
        if (val && val !== old) {
            this.fetchFarmsList()
        }
    }

    @Watch('getFarmFromRoute')
    private currentFarmChanged(newFarm: any, oldFarm: any) {
        if (!newFarm) {
            if (!this.currentRoute.params.farmId && this.$route.name !== 'farmorderdata') {
                this.setGeolocVisibility(true)
            }
        } else {
            if (newFarm === oldFarm) return
            this.updateMapDataInRegion({ boundaries: newFarm.boundaries })
        }
    }

    private mounted() {
        this.setMapWidth(this.$refs.map.$el.clientWidth)
        window.addEventListener('resize', this.onWindowResize)
        this.$refs.vlView.$createPromise.then(() => {
            this.$refs.vlView.fit(this.mapData.mapBoundaries, {
                constrainResolution: false,
                duration: undefined
            })
        })
    }

    @Watch('getFieldFromRoute')
    private onchangeField(val: any, old: any) {
        if (val !== old && val) {
            this.centerToField(val.id)
        }
    }

    private centerToField(fieldId: string) {
        if (this.getFarmFromRoute && this.$refs.vlView) {
            this.$refs.vlView.animate({
                center: this.getFarmFromRoute.getCenter(fieldId.toString()),
                zoom: Math.floor(this.getFarmFromRoute.getZoom(fieldId.toString())),
                duration: 800
            })
        }
    }

    private onWindowResize() {
        if (this.$refs.map) this.setMapWidth(this.$refs.map.$el.clientWidth)
    }

    private onPointerMove(evt: any) {
        if (this.getRulerMode.active) {
            this.$refs.ruler.updateTipCoordinate(evt.coordinate)
        }
        if (this.$route.name === 'subfieldyield') {
            if (this.$refs.yieldlayer) {
                this.$refs.yieldlayer.onPointerMove(evt)
            }
        } else if (this.$route.name === 'subfieldindicators' || this.$route.name === 'subfield') {
            this.$refs.indicatorshover.onPointerMove(evt)
        }
    }

    private onClickOnMap(evt: any) {
        this.entOnMap = evt
        if (this.$route.name === 'searchonmap') {
            if (this.$refs.regioncadastres) {
                this.$refs.regioncadastres.clickEvent(evt)
            }
        } else if (this.$route.name === 'subfieldyield') {
            if (this.$refs.yieldlayer) {
                this.$refs.yieldlayer.clickEvent(evt)
            }
        }
    }
}
