export default {
    cultures: {
        crops: {
            title: "Зерновые",
            1: {
                title: "Зерновые хлеба (озимые и яровые)",
                wheat: {
                    title: "пшеница",
                    types: ["Пшеница твердая озимая", "Пшеница мягкая озимая", "Пшеница мягкая яровая", "Пшеница твердая яровая", "Пшеница тургидум"]
                },
                rye: {
                    title: "рожь",
                    types: ["Рожь озимая"]
                },
                barley: {
                    title: "ячмень",
                    types: ["Ячмень озимый", "Ячмень яровой"]
                },
                oat: {
                    title: "овес",
                    types: ["Овес яровой"]
                },
                triticale: {
                    title: "тритикале",
                    types: ["Тритикале озимая"]
                }
            },
            2: {
                title: "Зерновые хлеба яровые и растения других семейств",
                maize: {
                    title: "Kукуруза",
                    types: ["Кукуруза"]
                },
                millet: {
                    title: "просо",
                    types: []
                },
                rice: {
                    title: "рис",
                    types: ["Рис"]
                },
                sorghum: {
                    title: "сорго",
                    types: ["Сорго зерновое"]
                },
                buckwheat: {
                    title: "гречиха",
                    types: ["Гречиха"]
                }
            },
            3: {
                title: "Зерновые бобовые",
                peas: {
                    title: "Горох",
                    types: ["Горох посевной"]
                },
                beans: {
                    title: "кормовые бобы",
                    types: []
                },
                lentil: {
                    title: "чечевица",
                    types: ["Чечевица"]
                }
            }
        },
        root_vegetables: {
            title: "Корнеплоды, клубнеплоды, бахчевые, кормовая капуста",
            1: {
                title: "Корнеплоды",
                sugar_beet: {
                    title: "Сахарная свекла",
                    types: ["Свекла столовая"]
                },
                fodder_beet: {
                    title: "кормовая свекла",
                    types: ["Свекла кормовая"]
                }
            },
            2: {
                title: "Кормовая капуста",
                feed_cabbage: {
                    title: "Кормовая капуста",
                    cabbage_white: {
                        title: "Капуста белокачанная"
                    },
                    cabbage_red: {
                        title: "Капуста краснокачанная"
                    },
                    cabbage_colorful: {
                        title: "Капуста цветная"
                    },
                    cabbage_peking: {
                        title: "Капуста пекинская"
                    },
                    broccoli: {
                        title: "Капуста брокколи"
                    },
                    salad: {
                        title: "Салат"
                    },
                    cabbage_savoy: {
                        title: "Капуста савойская"
                    }
                },
                kohlrabi: {
                    title: "кольраби"
                }
            },
            3: {
                title: "Клубнеплоды",
                potato: {
                    title: "Картофель",
                    types: ["Картофель"]
                },
                artichoke: {
                    title: "земляная груша"
                }
            },
            4: {
                title: "Бахчевые",
                watermelon: {
                    title: "Арбуз",
                    types: ["Арбуз"]
                },
                melon: {
                    title: "дыня",
                    types: ["Дыня"]
                },
                pumpkin: {
                    title: "тыква",
                    types: ["Тыква кормовая", "Тыква"]
                }
            }
        },
        forage_crops: {
            title: "Кормовые культуры",
            1: {
                title: "Бобовые травы многолетние",
                clover: {
                    title: "Клевер",
                    types: ["Клевер луговой"]
                },
                lucerne: {
                    title: "люцерна",
                    types: ["Люцерна"]
                },
                sainfoin: {
                    title: "эспарцет"
                },
                other: {
                    title: "другое ..."
                }
            },
            2: {
                title: "Злаковые травы, многолетние",
                timothy: {
                    title: "Тимофеевка"
                },
                fescue: {
                    title: "овсяница",
                    types: ["Овсяница луговая", "Овсяница бороздчатая", "Овсяница тростниковая"]
                },
                wheatgrass: {
                    title: "житняк",
                    types: ["Житняк"]
                }
            },
            3: {
                title: "Новые многолетние кормовые растения",
                sosnovsy_hogweed: {
                    title: "Борщевик Сосновского"
                },
                weyrich_highlander: {
                    title: "горец Вейриха"
                },
                goat_rue: {
                    title: "козлятник восточный"
                }
            },
            4: {
                title: "Бобовые травы однолетние",
                vicky: {
                    title: "Вика"
                },
                seradella: {
                    title: "сераделла"
                },
                crimson_clover: {
                    title: "клевер пунцовый"
                },
                other: {
                    title: "другое ..."
                }
            },
            5: {
                title: "Злаковые травы, однолетние",
                sudanese_grass: {
                    title: "Суданская трава"
                },
                panic: {
                    title: "могар"
                },
                plague: {
                    title: "чумиза"
                }
            },
            6: {
                title: "Новые однолетние кормовые растения",
                whorled_mallow: {
                    title: "Мальва мутовчатая"
                },
                oil_radish: {
                    title: "редька масличная"
                }
            }
        },
        oil_bearing: {
            title: "Масличные и эфиромасличные",
            1: {
                title: "Масличные",
                sunflower: {
                    title: "Подсолнечник",
                    types: ["Подсолнечник"]
                },
                safflower: {
                    title: "сафлор",
                    types: ["Сафлор"]
                },
                mustard: {
                    title: "горчица",
                    types: ["Горчица сизая", "Горчица сарептская", "Горчича салатная"]
                },
                rape: {
                    title: "рапс",
                    types: ["Рапс озимый на корм", "Рапс озимый", "Рапс яровой", "Рапс яровой на корм"]
                },
                mushroom: {
                    title: "рыжик",
                    types: ["Рыжик яровой"]
                },
                castor_oil_plant: {
                    title: "клещевина",
                    types: ["Клещевина"]
                },
                sesame: {
                    title: "кунжут",
                    types: ["Кунжут"]
                },
                oil_flax: {
                    title: "Лён масличный",
                    types: ["Лён масличный"]
                },
                peanut: {
                    title: "арахис"
                },
                perilla: {
                    title: "перилла"
                },
                lallementia: {
                    title: "ляллеменция"
                }
            },
            2: {
                title: "Эфиромасличные",
                coriander: {
                    title: "Кориандр"
                },
                anise: {
                    title: "анис"
                },
                cumin: {
                    title: "тмин"
                },
                peppermint: {
                    title: "мята перечная"
                },
                clay_sage: {
                    title: "шалфей мускатный"
                }
            }
        },
        spinning: {
            title: "Прядильные",
            1: {
                title: "Растения с волокном на семенах",
                cotton: {
                    title: "Хлопчатник"
                }
            },
            2: {
                title: "Лубоволокнистые",
                linen: {
                    title: "Лён"
                },
                hemp: {
                    title: "конопля"
                },
                kenaf: {
                    title: "кенаф"
                }
            }
        },
        tobacco: {
            title: "Табак и махорка",
            1: {
                title: "Наркотические",
                tobacco: {
                    title: "Табак",
                    types: ["Табак"]
                },
                makhorka: {
                    title: "махорка"
                }
            }
        }

    },
    gistogram: {
        ndvi: ["Объекты, не относящиеся к растительности", "Открытая почва", "Слабо разряженная растительность", "Разряженная растительность", "Густая растительность"],
        ndmi: ["Открытая почва", "Растительность почти отсутствует", "Очень низкий уровень растительности", "Низкий уровень растительности, сухая или очень низкая растительность, влага",
                "Средне-низкий уровень растительности, низкая влажность или низкий уровень растительности, средняя влажность", "Средний уровень растительности, низкий уровень влажности, или средне-низкий уровень растительности, средняя влажность",
                "Средний уровень растительности, низкий уровень влажности, или средне-низкий уровень растительности, средняя влажность", "Средне-высокий уровень растительности, низкая влажность или средняя растительность, средняя влажность",
                "Высокий уровень растительности, достаточно влаги", "Очень высокий уровень растительности, достаточно влаги", "Полное покрытие растительности или заболоченность (водные объекты)"]
    },
    phenophase: {
        crop_1: {
            title: "Пшеница озимая",
            data: ["Всходы", "Кущение", "После таяния снега (весна)", "Выход в трубку", "Колошение–цветение", "Формирование зерна", "Молочная спелост", "Перед уборкой"]
        },
        crop_2: {
            title: "Пшеница яровая",
            data: ["Всходы, третий лист", "Кущение", "Выход в трубку", "Колошение, цветение", "Завязывание зерна –молочная спелост", "Молочно-восковая спелость", "Восковая спелость", "Полная спелость"]
        },
        crop_3: {
            title: "Ячмень яровой",
            data: ["Всходы", "Кущение", "Выход в трубку", "Колошение, цветение", "Завязывание зерна–молочная спелост", "Молочно-восковая спелость", "Восковая спелость", "Полная спелость"]
        },
        crop_4: {
            title: "Ямень озимый",
            data: ["Всходы", "Кущение", "Выход в трубку", "Стадия лигулы", "Колошение", "Цветение", "Молочная спелость", "Восковая спелость", "Полная спелость"]
        },
        crop_5: {
            title: "Кукуруза",
            data: ["всходы", "первый лист", "3–5 листьев", "выхода в трубку", "Рост стебля", "выметывание", "цветение метелки", "формирование зерновки", "налив зерновки", "созревание зерновки"]
        },
        crop_6: {
            title: "Подсолнечник",
            data: ["всходы", "бутонизация", "цветения", "налива семянок", "Созревание"]
        },
        crop_7: {
            title: "Лён",
            data: ["Всходы", "Удлинения стебля", "Бутинизация", "Цветение", "Зеленые коробочки", "Коричневые коробочки", "Полная зрелость"]
        },
        crop_8: {
            title: "Рапс озимый",
            data: ["Всходы", "Семядоля", "Розетка", "Рост стебля", "Бутонизация", "Начало цветения", "Середина цветения", "Формирование стручков", "Созревание", "Полное созревание"]
        },
        crop_9: {
            title: "Рапс яровой",
            data: ["Всходы", "Семядоля", "Розетка", "Рост стебля", "Бутонизация", "Начало цветения", "Середина цветения", "Формирование стручков", "Созревание", "Полное созревание"]
        },
        crop_10: {
            title: "Соя",
            data: ["всходы", "Ветвление", "Бутонизация", "Цветение", "Образование бобов", "Начало созревания", "полное созревание"]
        },
        crop_11: {
            title: "Сафлор",
            data: ["Всходы", "Розетка", "стеблевание", "ветвление", "бутонизация", "Цветение", "Созревание", "Полная спелость"]
        }
    }
}