import { Module, GetterTree, ActionTree, MutationTree } from 'vuex'
import { GetterNames, ActionNames, MutationNames } from '../../types'
import { httpPost } from '@/util/http'
import { apiUrl2 } from '@/domain/constants'
import i18n from '@/i18n'
// import Note from '@/models/Note'

const states = {
    legendCoordinatesArray: new Array<number[][]>(),
    lastNewNodePoint: [],
    FieldsRating: {
        result: [],
        indicator: 'ndvi',
        fieldId: 0
    },
    subfieldIndicatorData: {
        fieldId: null,
        seasonId: null,
        currentRequestId: null,
        secondRequestId: null,
        currentRequestDir: null,
        secondRequestDir: null,
        currentBbox: null,
        secondBbox: null,
        data: [],
        alertProblemZones: false
    },
    subfieldNoteData: {
        fieldId: null,
        currentNoteId: null,
        data: []
    },
    cadastreData: {
        cadastreId: null,
        area: null,
        araeble_by_doc: null,
        pasture: null,
        arable: null,
        fields: new Array<number[][]>(),
        capture: null,
        notUsed: null,
        documentDeadline: null,
        showFields: true,
        showCapture: true,
        showNotUsed: true,
        showCadastre: true,
        showFromAnother: true
    },
    taskData: {
        taskId: null,
        fields: [],
        fieldIds: [],
        currentStopPoint: null
    },
    currentPlaceNotesCoordination: null as any,
    normalDateRound: ''
}

export type IState = typeof states

const mutations: MutationTree<IState> = {
    [MutationNames.setLegendCoordinatesArray](state, newCoordinates: number[][][]) {
        state.legendCoordinatesArray = newCoordinates
    },

    [MutationNames.setLastNewNotePoint](state, val: any) {
        state.lastNewNodePoint = val
    },

    [MutationNames.setFieldsRating](state, val: any) {
        if (val.hasOwnProperty('result')) state.FieldsRating.result = val.result
        if (val.hasOwnProperty('indicator')) state.FieldsRating.indicator = val.indicator
        if (val.hasOwnProperty('fieldId')) state.FieldsRating.fieldId = val.fieldId
    },

    [MutationNames.setSubfieldNoteData](state, val: any) {
        if (val.fieldId) state.subfieldNoteData.fieldId = val.fieldId
        if (val.currentNoteId) state.subfieldNoteData.currentNoteId = val.currentNoteId
        if (val.data) state.subfieldNoteData.data = val.data
    },

    [MutationNames.setSubfieldIndicatorData](state, val: any) {
        if (val.hasOwnProperty('fieldId')) state.subfieldIndicatorData.fieldId = val.fieldId
        if (val.hasOwnProperty('seasonId')) state.subfieldIndicatorData.seasonId = val.seasonId
        if (val.hasOwnProperty('currentRequestId')) state.subfieldIndicatorData.currentRequestId = val.currentRequestId
        if (val.hasOwnProperty('secondRequestId')) state.subfieldIndicatorData.secondRequestId = val.secondRequestId
        if (val.hasOwnProperty('currentRequestDir')) state.subfieldIndicatorData.currentRequestDir = val.currentRequestDir
        if (val.hasOwnProperty('secondRequestDir')) state.subfieldIndicatorData.secondRequestDir = val.secondRequestDir
        if (val.hasOwnProperty('currentBbox')) state.subfieldIndicatorData.currentBbox = val.currentBbox
        if (val.hasOwnProperty('secondBbox')) state.subfieldIndicatorData.secondBbox = val.secondBbox
        if (val.hasOwnProperty('data')) state.subfieldIndicatorData.data = val.data
        if (val.hasOwnProperty('alertProblemZones')) state.subfieldIndicatorData.alertProblemZones = val.alertProblemZones
    },

    [MutationNames.setCadastreData](state, val: any) {
        if (val.hasOwnProperty('cadastreId')) state.cadastreData.cadastreId = val.cadastreId
        if (val.hasOwnProperty('area')) state.cadastreData.area = val.area
        if (val.hasOwnProperty('arable')) state.cadastreData.arable = val.arable
        if (val.hasOwnProperty('araeble_by_doc')) state.cadastreData.araeble_by_doc = val.araeble_by_doc
        if (val.hasOwnProperty('pasture')) state.cadastreData.pasture = val.pasture
        if (val.hasOwnProperty('fields')) state.cadastreData.fields = val.fields
        if (val.hasOwnProperty('capture')) state.cadastreData.capture = val.capture
        if (val.hasOwnProperty('notUsed')) state.cadastreData.notUsed = val.notUsed
        if (val.hasOwnProperty('documentDeadline')) state.cadastreData.documentDeadline = val.documentDeadline
        if (val.hasOwnProperty('showFields')) state.cadastreData.showFields = val.showFields
        if (val.hasOwnProperty('showCapture')) state.cadastreData.showCapture = val.showCapture
        if (val.hasOwnProperty('showNotUsed')) state.cadastreData.showNotUsed = val.showNotUsed
        if (val.hasOwnProperty('showCadastre')) state.cadastreData.showCadastre = val.showCadastre
        if (val.hasOwnProperty('showFromAnother')) state.cadastreData.showFromAnother = val.showFromAnother
    },

    [MutationNames.setTaskData](state, val: any) {
        if (val.hasOwnProperty('taskId')) state.taskData.taskId = val.taskId
        if (val.hasOwnProperty('fields')) state.taskData.fields = val.fields
        if (val.hasOwnProperty('fieldIds')) state.taskData.fieldIds = val.fieldIds
        if (val.hasOwnProperty('currentStopPoint')) state.taskData.currentStopPoint = val.currentStopPoint
    },

    [MutationNames.setCurrentPlaceNotesCoordination](state, val: any) {
        state.currentPlaceNotesCoordination = val
    },

    [MutationNames.setNormalDateRound](state, val: any) {
        state.normalDateRound = val
    }
}

const actions: ActionTree<IState, any> = {
    [ActionNames.fetchDividedLegends]({ state, commit }, body: any) {
        commit('setIsDataLoading', true)
        commit('changeLegendDividingMode', false)
        commit('setLegendCoordinatesArray', [])
        httpPost({
            url: `${apiUrl2}/get_legend/`,
            isSecureRequest: true,
            body,
            onSuccess: json => {
                if (json.coordinates) {
                  commit('setLegendCoordinatesArray', json.coordinates)
                  commit('changeLegendDividingMode', true)
                }
            },
            doFinally: () => {
              commit('setIsDataLoading', false)
            }
        })
    },

    [ActionNames.fetchFieldsNoteImage]({state, commit}, data: any) {
        commit('setFieldsNoteImage', data)
    },

    [ActionNames.fetchFieldIndicatorData]({state, commit, getters}) {
        if (getters[GetterNames.getFarmFromRoute]) {
            if (getters[GetterNames.getCurrentRoute].params.subfieldId) {
                const farmId = getters[GetterNames.getCurrentRoute].params.farmId
                const subfieldId = getters[GetterNames.getCurrentRoute].params.subfieldId
                if (farmId && subfieldId) {
                    const season = getters[GetterNames.getFarmFromRoute].seasons.find((x: any) => x.is_current)
                    if (season) {
                        if (state.subfieldIndicatorData.fieldId !== subfieldId ||
                            season.id !== state.subfieldIndicatorData.seasonId) {
                            commit(MutationNames.setLoadingDataNames, 'fetchFieldIndicatorData')
                            httpPost({
                                url: `${apiUrl2}/get/response/by/divided/season/`,
                                isSecureRequest: true,
                                body: {
                                    divided: Number(subfieldId),
                                    start_date: season.start_date,
                                    end_date: season.end_date
                                },
                                onSuccess: json => {
                                    const result: any = []
                                    let cloudiness: any = {
                                        tempRequest: null,
                                        start_date: '',
                                        end_date: '',
                                        amount: 0,
                                        count: 0
                                    }
                                    for (const [index, request] of json.entries()) {
                                        if (request.is_present) {
                                            if (cloudiness.tempRequest) {
                                                result.push({
                                                    ...cloudiness.tempRequest,
                                                    start_date: cloudiness.start_date,
                                                    end_date: cloudiness.end_date,
                                                    average_cloudiness: (cloudiness.amount / cloudiness.count),
                                                    image_count: cloudiness.count
                                                })
                                                cloudiness = {
                                                    tempRequest: null,
                                                    start_date: '',
                                                    end_date: '',
                                                    amount: 0,
                                                    count: 0
                                                }
                                            }
                                            result.push(request)
                                        } else {
                                            if (cloudiness.tempRequest) {
                                                cloudiness.end_date = request.actual_date
                                                cloudiness.amount += request.cloudness
                                                cloudiness.count += 1
                                            } else {
                                                cloudiness.tempRequest = request
                                                cloudiness.start_date = request.actual_date
                                                cloudiness.end_date = request.actual_date
                                                cloudiness.amount += request.cloudness
                                                cloudiness.count += 1
                                            }
                                            if (json.length === index + 1) {
                                                result.push({
                                                    ...cloudiness.tempRequest,
                                                    start_date: cloudiness.start_date,
                                                    end_date: cloudiness.end_date,
                                                    average_cloudiness: (cloudiness.amount / cloudiness.count),
                                                    image_count: cloudiness.count
                                                })
                                                cloudiness = {
                                                    tempRequest: null,
                                                    start_date: '',
                                                    end_date: '',
                                                    amount: 0,
                                                    count: 0
                                                }
                                            }
                                        }
                                    }
                                    let presentsResult = []
                                    if (result.length > 0) {
                                        presentsResult = result.filter((x: any) => x.is_present)
                                    }
                                    const tempCurrentBbox = result.length > 0 ? (presentsResult.length > 0 ? (
                                        presentsResult[0].extra_params ? presentsResult[0].extra_params.bbox ? presentsResult[0].extra_params.bbox : null : null
                                        ) : (result[0].extra_params ? result[0].extra_params.bbox ? result[0].extra_params.bbox : null : null)) : null
                                    const tempSecondBbox = result.length > 0 ? (presentsResult.length > 0 ? (
                                        presentsResult[0].extra_params ? presentsResult[0].extra_params.bbox ? presentsResult[0].extra_params.bbox : null : null
                                        ) : (result[0].extra_params ? result[0].extra_params.bbox ? result[0].extra_params.bbox : null : null)) : null
                                    commit(MutationNames.setSubfieldIndicatorData,
                                        {
                                            fieldId: subfieldId,
                                            seasonId: season.id,
                                            currentRequestId: result.length > 0 ? (presentsResult.length > 0 ? presentsResult[0].id : result[0].id) : null,
                                            secondRequestId: result.length > 0 ? (presentsResult.length > 0 ? presentsResult[0].id : result[0].id) : null,
                                            currentRequestDir: result.length > 0 ? (presentsResult.length > 0 ? (presentsResult[0].images_urls ? presentsResult[0].images_urls : null) : (result[0].images_urls ? result[0].images_urls : null)) : null,
                                            secondRequestDir: result.length > 0 ? (presentsResult.length > 0 ? (presentsResult[0].images_urls ? presentsResult[0].images_urls : null) : (result[0].images_urls ? result[0].images_urls : null)) : null,
                                            currentBbox: tempCurrentBbox,
                                            secondBbox: tempSecondBbox,
                                            data: result
                                        })
                                },
                                onError: error => {
                                    console.log(error)
                                },
                                doFinally: () => {
                                    commit(MutationNames.setLoadingDataNames, 'fetchFieldIndicatorData')
                                }
                            })
                        }
                    }
                }
            }
        }
    }
}

const getter: GetterTree<IState, any> = {
    [GetterNames.getLegendCoordinatesArray]: state => {
        return state.legendCoordinatesArray
    },

    [GetterNames.getLastNewNotePoint]: state => {
        return state.lastNewNodePoint
    },

    [GetterNames.getFieldsRating]: state => {
        return state.FieldsRating
    },

    [GetterNames.getSubfieldIndicatorData]: state => {
        return state.subfieldIndicatorData
    },

    [GetterNames.getSubfieldNoteData]: state => {
        return state.subfieldNoteData
    },

    [GetterNames.getCadastreData]: state => {
        return state.cadastreData
    },

    [GetterNames.getTaskData]: state => {
        return state.taskData
    },

    [GetterNames.getFieldFromRoute]: (state, getters) => {
        const farmId = getters[GetterNames.getCurrentRoute].params.farmId
        const fieldId = getters[GetterNames.getCurrentRoute].params.subfieldId
        if (farmId && fieldId) {
            const farm: any = getters[GetterNames.getFarms].find((x: any) => x.id.toString() === farmId)
            if (farm) {
                if (farm.fullData) {
                    const field = farm.fields.find((x: any) => x.id.toString() === fieldId)
                    if (field) {
                        return field || false
                    }
                }
            }
        }
        return false
    },

    [GetterNames.getCurrentPlaceNotesCoordination]: state => {
        return state.currentPlaceNotesCoordination
    },

    [GetterNames.getNormalDateRound]: state => {
        return state.normalDateRound
    }
}

const auth: Module<IState, any> = {
    state: states,
    actions,
    mutations,
    getters: getter
}

export default auth
