export default {
    weather: "See the weather",
    first_order: "Make first order",
    culture: {
        search: "Search by variety",
        title: "Field culture",
        type: "Type",
        variety: {
            title: "Variety",
            name: "Variety name"
        }
    }
}