



















































import { Component, Vue } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import { GetterNames, MutationNames } from '@/store/types'
import { baseUrl } from '@/domain/constants'
@Component({
    components: {
    }
})
export default class LoadingModel extends Vue {
    @Getter(GetterNames.getPhotosData) private getPhotosData!: any
    @Mutation(MutationNames.setPhotosData) private setPhotosData!: any

    private closeModal() {
        this.setPhotosData({currentIndex: 0, data: []})
    }

    get getImageInfo() {
        if (this.getPhotosData.data.length > 0) {
            if (this.getPhotosData.data[this.getPhotosData.currentIndex]) {
                const photo = this.getPhotosData.data[this.getPhotosData.currentIndex]
                if (photo.hasOwnProperty('is_mobile')) {
                    return photo
                } else {
                    return false
                }
            } else {
                return false
            }
        } else {
            return false
        }
    }

    private normalizeDate(date: string) {
        if (date) {
            return date.substring(8, 10) + '.' + date.substring(5, 7) + '.' + date.substring(0, 4)
        } else {
            return ''
        }
    }

    private findImageUrl() {
        if (this.getPhotosData.data.length > 0) {
            if (this.getPhotosData.data[this.getPhotosData.currentIndex]) {
                const photo = this.getPhotosData.data[this.getPhotosData.currentIndex]
                if (photo.hasOwnProperty('is_mobile')) {
                    if (photo.image_squeezed) return `${baseUrl}${photo.image_squeezed}`
                    else return `${baseUrl}${photo.image}`
                } else if (photo.image.includes(baseUrl)) {
                    return photo.image
                } else if (photo.image_squeezed.includes(baseUrl)) {
                    return photo.image_squeezed
                } else {
                    // if (photo.image) return URL.createObjectURL(photo.image)
                    // else if (photo.image_squeezed) return URL.createObjectURL(photo.image_squeezed)
                    return `${baseUrl}${photo.image}`
                    // return URL.createObjectURL(photo.image)
                }
            } else {
                return ''
            }
        } else {
            return ''
        }
    }

    private previousImage() {
        if (this.getPhotosData.data.length > 0) {
            let index = this.getPhotosData.currentIndex
            index = ((index - 1) === -1) ? (this.getPhotosData.data.length - 1) : (index - 1)
            this.setPhotosData({currentIndex: index})
        }
    }

    private nextImage() {
        if (this.getPhotosData.data.length > 0) {
            let index = this.getPhotosData.currentIndex
            index = ((index + 1) === this.getPhotosData.data.length) ? 0 : (index + 1)
            this.setPhotosData({currentIndex: index})
        }
    }
}
