import {
    Action as AuthActions,
    Mutation as AuthMutaions,
    Getter as AuthGetter
} from './modules/auth/types'

import {
    Action as CadastresActions,
    Mutation as CadastresMutaions,
    Getter as CadastresGetter
} from './modules/cadastres/types'

import {
    Action as MapDataActions,
    Mutation as MapDataMutaions,
    Getter as MapDataGetter
} from './modules/mapData/types'

import {
    Action as PanelsActions,
    Mutation as PanelsMutaions,
    Getter as PanelsGetter
} from './modules/panels/types'


import {
    Action as FieldsActions,
    Mutation as FieldsMutaions,
    Getter as FieldsGetter
} from './modules/fields/types'

import {
    Action as GeneralActions,
    Mutation as GeneralMutaions,
    Getter as GeneralGetter
} from './modules/general/types'

import {
    Action as WorkspaceActions,
    Mutation as WorkspaceMutations,
    Getter as WorkspaceGetters
} from './modules/workspace/types'

export const ActionNames = {
    ...AuthActions,
    ...CadastresActions,
    ...MapDataActions,
    ...PanelsActions,
    ...FieldsActions,
    ...GeneralActions,
    ...WorkspaceActions
}

export const MutationNames = {
    ...AuthMutaions,
    ...CadastresMutaions,
    ...MapDataMutaions,
    ...PanelsMutaions,
    ...FieldsMutaions,
    ...GeneralMutaions,
    ...WorkspaceMutations
}

export const GetterNames = {
    ...AuthGetter,
    ...CadastresGetter,
    ...MapDataGetter,
    ...PanelsGetter,
    ...FieldsGetter,
    ...GeneralGetter,
    ...WorkspaceGetters
}

