



















































































































import {Component, Vue } from 'vue-property-decorator'
import {httpDelete, httpGet} from '@/util/http'
import {apiUrl} from '@/domain/constants'
import {Getter, Mutation} from 'vuex-class'
import {GetterNames, MutationNames} from '@/store/types'
import Farm from '@/models/Farm'
import FarmFields from '@/views/MainPage/HomeView/MapContainer/MapView/components/FarmFields.vue'
import PlanningModalView from './PlanningModalView.vue'
@Component({
    components: {
        FarmFields,
        PlanningModalView
    }
})
export default class PlanningView extends Vue {
    @Getter(GetterNames.getMapData) private getMapData!: any
    @Getter(GetterNames.getFarmFromRoute) private getFarmFromRoute!: Farm
    @Getter(GetterNames.getFarmMapControllers) private getFarmMapControllers!: any
    @Getter(GetterNames.getCurrentSeason) private getCurrentSeason!: any
    @Mutation(MutationNames.setFarmMapControllers) private setFarmMapControllers!: any

    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    private isShown = false
    private isShowDetail = false

    private technologyList: any = null
    private detailId: any = null

    private showModal() {
        this.setLoadingDataNames('planningModal')
        setTimeout( () => {
            this.isShown = true
        }, 1000)
    }

    private mounted() {
        this.setFarmMapControllers({ fill: false })
        this.getListTechnologies()
    }

    private getListTechnologies() {
        this.setLoadingDataNames('noteUpdateGetMembers')
        httpGet({
            url: `${apiUrl}/technology/?group_user=${this.getFarmFromRoute.id}&group_season=${this.getCurrentSeason.id}`,
            isSecureRequest: true,
            onSuccess: json => {
                this.technologyList = json
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('noteUpdateGetMembers')
            }
        })
    }

    private deleteTechnology(id: any, name: any) {
        this.$confirm(`Вы действительно хотите удалить: "${name}"?`, 'Подтверждение', 'warning').then(() => {
            this.setLoadingDataNames('TechMapBoardDeleteTechMap')
            httpDelete({
                url: `${apiUrl}/technology/${id}/`,
                isSecureRequest: true,
                onSuccess: () => {
                    this.$alert('Шаблон успешно удален', 'Удалено', 'success')
                    this.getListTechnologies()
                },
                onError: error => {
                    console.log(error)
                },
                doFinally: () => {
                    this.setLoadingDataNames('TechMapBoardDeleteTechMap')
                }
            })
        }).catch(e => {/* */})
    }

    private normalizeArea(area: any) {
        return (area / 10000).toFixed(2)
    }

    private openRoute(id: any) {
        return this.$router.push({ name: 'planning-technology', params: { id }})
    }
}
