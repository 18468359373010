import { render, staticRenderFns } from "./HeaderWithPagination.vue?vue&type=template&id=132f028a&scoped=true&"
import script from "./HeaderWithPagination.vue?vue&type=script&lang=ts&"
export * from "./HeaderWithPagination.vue?vue&type=script&lang=ts&"
import style0 from "./HeaderWithPagination.vue?vue&type=style&index=0&id=132f028a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "132f028a",
  null
  
)

export default component.exports