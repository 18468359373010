








































































import { Component, Vue, Emit } from 'vue-property-decorator'
import { httpGet, httpPost, httpPut } from '@/util/http'
import { apiUrl } from '@/domain/constants'
import { GetterNames } from '@/store/types'
import { Getter } from 'vuex-class'
import i18n from '@/i18n'
@Component({
    components: {
    }
})
export default class NewStorageModel extends Vue {
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: any
    @Getter(GetterNames.getFarms) private getFarms!: any
    private isShown = false
    private types: any = []
    private storage: any = {
        farm: {
            name: '',
            id: -1
        },
        title: '',
        types: [],
        description: '',
        farmDropdown: false,
        typesDropdown: false
    }
    private datasending = false
    private isUpdate = false
    private storageForUpdate: any = null

    get getSelectedTypes() {
        let str = ''
        for (const [i, type] of this.types.entries()) {
            if (this.storage.types.includes(i + 1)) {
                str += type.name + ', '
            }
        }
        if (str.length > 30) {
            return str.substring(0, 27) + '...'
        }
        return str
    }

    private show() {
        this.isShown = true
        this.storage = {
            farm: {
                name: '',
                id: -1
            },
            title: '',
            types: [],
            description: '',
            farmDropdown: false,
            typesDropdown: false
        }
        this.datasending = false
    }

    private update(storage: any) {
        this.storageForUpdate = storage
        this.isShown = true
        this.isUpdate = true
        const typesToId = []
        for (const [i, type] of this.types.entries()) {
            if (storage.types_name.find((x: any) => x === type.name)) {
                typesToId.push(i + 1)
            }
        }
        this.storage = {
            farm: {
                name: storage.group_users_name,
                id: storage.group_users
            },
            title: storage.title,
            types: typesToId,
            description: storage.description,
            farmDropdown: false,
            typesDropdown: false
        }
    }

    private mounted() {
        this.getTypes()
    }

    private addNewStorage() {
        if (!this.checkForErrors()) return
        if (this.datasending) return
        this.datasending = true
        httpPost({
            url: `${apiUrl}/storage/`,
            isSecureRequest: true,
            body: {
                group_users: this.storage.farm.id,
                title: this.storage.title,
                types: this.storage.types,
                description: this.storage.description
            },
            onSuccess: json => {
                this.reload()
            },
            doFinally: () => {
                this.datasending = false
                this.hide()
            }
        })
    }

    private checkForErrors() {
        if (this.storage.farm.id === -1) {
            alert(i18n.t('messages.selections.farm'))
            return false
        }
        if (this.storage.title === '') {
            alert(i18n.t('messages.instructions.storage_name'))
            return false
        }
        if (this.storage.types.length === 0) {
            alert(i18n.t('messages.selections.storage_type'))
            return false
        }
        return true
    }

    private updateStorage() {
        if (!this.checkForErrors()) return
        if (this.datasending) return
        this.datasending = true
        httpPut({
            url: `${apiUrl}/storage/${this.storageForUpdate.id}`,
            isSecureRequest: true,
            body: {
                group_users: this.storage.farm.id,
                title: this.storage.title,
                types: this.storage.types,
                description: this.storage.description
            },
            onSuccess: json => {
                this.reload()
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.datasending = false
                this.hide()
            }
        })
    }

    private getTypes() {
        httpGet({
            url:  `${apiUrl}/storage/types/`,
            isSecureRequest: true,
            onSuccess: json => {
                this.types = json
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                //
            }
        })
    }

    private setTypes(id: any) {
        if (this.storage.types.includes(id)) {
            const index = this.storage.types.indexOf(id)
            this.storage.types.splice(index, 1)
        } else {
            this.storage.types.push(id)
        }
    }

    @Emit('reload')
    private reload() {
        return {}
    }

    private hide() {
        this.isShown = false
        this.isUpdate = false
    }

    private closeFarmDropdown() {
        this.storage.farmDropdown = false
    }

    private closeTypesDropdown() {
        this.storage.typesDropdown = false
    }
}
