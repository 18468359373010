import Vue from 'vue'
import VueI18n, { LocaleMessages } from 'vue-i18n'
import enlang from './locales/en/en'
import rulang from './locales/ru/ru'
import kzlang from './locales/kz/kz'
import { getLastLanguage } from '@/domain/constants'

Vue.use(VueI18n)

function loadLocaleMessages(): LocaleMessages {
    const messages = {
        en: enlang,
        ru: rulang,
        kz: kzlang
    }
    return messages
}

let lang = getLastLanguage()

if ( process.env.VUE_APP_I18N_SUPPORTED_LOCALE ) {
    const supportedLangs = process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',')
    if ( !supportedLangs.includes(lang) ) {
        lang = 'en'
    }
}

export default new VueI18n({
    pluralizationRules: {
        'ru'(choice, choicesLength) {
            if  ( choice === 0 ) {
                return 0
            }
            if ( choicesLength === 2 && choice > 1 ) {
                return 1
            }
            if ( choicesLength > 2 ) {
                if ( choice < 5 && choice > 1 ) {
                    return choicesLength - 2
                } else if ( choice >= 5 ) {
                    return choicesLength - 1
                } else if ( choice === 1 ) {
                    return choicesLength - 3
                }
            }
            return 0
        }
    },
    locale: lang,
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    messages: loadLocaleMessages()
})
