



























































import { Component, Vue, Watch} from 'vue-property-decorator'
import { GetterNames, MutationNames } from '@/store/types'
import { Getter, Mutation } from 'vuex-class'
import { categories } from '@/data/agroChemCategories'
@Component ({
    components: {
    }
})
export default class AgroChemResult extends Vue {
    @Getter(GetterNames.getMapData) private mapData !: any
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: any
    @Getter(GetterNames.getFarmFromRoute) private getFarmFromRoute!: any
    @Getter(GetterNames.getAgroChemResult) private getAgroChemResult!: any
    @Getter(GetterNames.getFarms) private getFarms!: any
    @Getter(GetterNames.getMapScreen) private getMapScreen!: any
    @Mutation(MutationNames.setAgroChemResult) private setAgroChemResult!: any
    private resultCategories = categories
    private textScale = 1
    private selectedFeatures: any[] = []
    @Watch('selectedFeatures')
    private featureSelected(newArr: any, oldArr: any) {
        if (newArr.length === 0) return
        else {
            if (this.getAgroChemResult.mapSelection) {
                if (newArr[0].properties) {
                    const fieldId = newArr[0].properties.id
                    const tempArr = this.getAgroChemResult.selectedFields
                    if (tempArr.includes(fieldId)) {
                        tempArr.splice(tempArr.indexOf(fieldId), 1)
                    } else {
                        tempArr.push(fieldId)
                    }
                    this.setAgroChemResult({selectedFields: tempArr})
                }
            }
        }
        this.selectedFeatures = []
    }

    private getFillColor(fieldId: any) {
        const request = this.getAgroChemResult.analysisResult.find((x: any) => x.divided_cadastre_user === fieldId)
        if (request) {
            const category: any = this.resultCategories[this.getAgroChemResult.selectedCategory]
            const propertyArr = ['ph', 'phosphorus', 'potassium', 'nitrogen', 'sulfur', 'humus']
            const value = request.data[0][propertyArr[this.getAgroChemResult.selectedCategory]]
            for (const row of category.indicators) {
                if (row.lower === null) {
                    if (Number(row.upper) > value) return row.color
                }
                if (row.upper === null) {
                    if (Number(row.lower) <= value) return row.color
                }
                if (row.upper && row.lower) {
                    if ((Number(row.upper) > value)) return row.color
                }
            }
        } else return 'rgba(255,255,255, 0.12)'
    }

    private getCurrentFarmFields() {
        if (this.getFarmFromRoute) {
            return this.getFarmFromRoute.fields
        }
        return []
    }

    private getFieldbyId(fieldId: any) {
        if (this.getFarmFromRoute) {
            return this.getFarmFromRoute.fields.find((x: any) => x.id === fieldId)
        }
        return false
    }

    private getFieldCenter(field: any) {
        if (field) {
            let Xmin = Number.MAX_SAFE_INTEGER
            let Xmax = Number.MIN_SAFE_INTEGER
            let Ymin = Number.MAX_SAFE_INTEGER
            let Ymax = Number.MIN_SAFE_INTEGER
            for (const arr of field.geom.coordinates[0][0]) {
                Xmax = arr[0] > Xmax ? arr[0] : Xmax
                Xmin = arr[0] < Xmin ? arr[0] : Xmin
                Ymax = arr[1] > Ymax ? arr[1] : Ymax
                Ymin = arr[1] < Ymin ? arr[1] : Ymin
            }
            return [(Xmin + Xmax) / 2, (Ymin + Ymax) / 2]
        }
        return [0, 0]
    }

    private mounted() {
        this.zoomChanged(this.mapData.zoom)
    }

    @Watch('mapData.zoom')
    private getPointRadius() {
        return 15 / this.calculateMetersInPixel()
    }

    private calculateMetersInPixel() {
        const Spixel = ( 2 * Math.PI * 6378137) / Math.pow( 2, this.mapData.zoom + 8 )
        return Spixel
    }

    @Watch('mapData.zoom')
    private zoomChanged(currentZoom: number) {
        this.textScale = this.mapZoomToTextScale(currentZoom)
    }

    private mapZoomToTextScale(currentZoom: number) {
      const maxScale = 3
      const minScale = 1
      const zoomRatio = (currentZoom - this.mapData.MIN_ZOOM) / (this.mapData.MAX_ZOOM - this.mapData.MIN_ZOOM)
      return zoomRatio * (maxScale - minScale) + minScale
    }
}
