










































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import { GetterNames, MutationNames } from '@/store/types'
import { httpGet, httpDelete, httpPost } from '@/util/http'
import { apiUrl, apiUrl3 } from '@/domain/constants'
import { CultureColors } from '@/data/FieldCulture'
import FieldsView from './components/FieldsView.vue'
import NewProgramModal from './components/NewProgramModal.vue'
import EventsView from './components/EventsView.vue'
import TractorsDailyInfoView from './components/TractorsDailyInfoView.vue'
import PlanningView from './components/PlanningView.vue'
import ByCultureView from './components/ByCultureView.vue'
import NewEventModal from './components/NewEventModal.vue'

@Component({
    components: {
        FieldsView,
        NewProgramModal,
        EventsView,
        NewEventModal,
        TractorsDailyInfoView,
        ByCultureView,
        PlanningView
    }
})
export default class TechmapBoard extends Vue {
    @Getter(GetterNames.getSidebarModel) private getSidebarModel!: any
    @Getter(GetterNames.getPermissions) private getPermissions!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    private CultureColors = CultureColors
    private otherCultures = new Array()
    private viewType = 4 // 3
    private byCultures: any = []
    private currentProgram = null
    private byDays = {
        days: new Array(),
        selected: null as any,
        selectedName: ''
    }
    private selectedDay = ''
    private tasks = []
    private selected = {
        cultureIndex: 0,
        techmapIndex: 0
    }
    private splideOptions = {
        rewind : false,
        autoWidth: true,
        pagination: false,
        gap: '8px',
        padding: {
            left: 60,
            right: 60
        }
    }
    private splideOptions2 = {
        rewind : false,
        autoWidth: true,
        pagination: false,
        gap: '8px',
        padding: {
            left: 60,
            right: 60
        },
        focus: 'center'
    }

    private byEvents: any = null
    private dropdownFields: any = false

    private arrForEventTechnic: any = []
    private arrForEventsFeilds: any = []
    private arrForEventsPreparations: any = []
    private arrForEventsCultures: any = []

    private reversed: any = true

    get getTechmap() {
        if (this.byCultures.length > 0) {
            const culture = this.byCultures[this.selected.cultureIndex]
            if (culture) {
                const techmap = culture.techmaps[this.selected.techmapIndex]
                if (techmap) {
                    if (techmap.id) {
                        return techmap
                    } else return null
                } else return null
            } else return null
        } else return null
    }

    get getTechmaps() {
        if (this.byCultures.length > 0) {
            return this.byCultures[this.selected.cultureIndex].techmaps
        } else return []
    }

    private getCulture(techmap: any) {
        if (techmap.is_rest) return 'Пары'
        else if (techmap.culture) {
            return `${techmap.culture || ''}` +
                    `${techmap.sort ? `, ${techmap.sort}` : ''}` +
                    `${techmap.reproduction ? `, ${techmap.reproduction}` : ''}`
        } else {
            return 'Нет культуры'
        }
    }

    private changeViewType(type: number) {
        this.$route.query.page = ''
        this.viewType = type
        if (this.viewType === 1) {
            //
        } else if (this.viewType === 2) {
            //
        } else if (this.viewType === 5) {
            this.fetchAboutEvents()
        }
    }

    private selectCulture(index: number) {
        if (this.selected.cultureIndex !== index) {
            this.selected.cultureIndex = index
            this.selected.techmapIndex = 0
            this.fetchCurrentTechmap()
        }
    }

    private selectTechmap(index: number) {
        if (this.selected.techmapIndex !== index) {
            this.selected.techmapIndex = index
            this.fetchCurrentTechmap()
        }
    }

    private mounted() {
        if (this.$route.query && this.$route.query.page) {
            if (this.$route.query.page === 'byCulture') {
                this.changeViewType(4)
            }
            if (this.$route.query.page === 'Planning') {
                this.changeViewType(1)
            }
        }
    }

    private onSelectDay(index: number) {
        if (this.byDays.days[index]) {
            this.byDays.selected = this.byDays.days[index]
            this.byDays.selectedName = this.byDays.days[index].date
            const el = this.$refs.daysSlider as any
            if (el) {
                el.$data.splide.go(index)
            }
            this.tasks = []
            this.setLoadingDataNames('TechmapBoardOnselectDay')
            httpPost({
                url: `${apiUrl3}/techmap/tasks/by/date/`,
                isSecureRequest: true,
                detailResponse: true,
                body: {
                    group_user: Number(this.$route.params.farmId),
                    date: this.byDays.selectedName
                },
                onSuccess: json => {
                    if (json.ok) {
                        this.tasks = json.json
                    }
                },
                onError: error => {
                    console.log(error)
                },
                doFinally: () => {
                    this.setLoadingDataNames('TechmapBoardOnselectDay')
                }
            })
        }
    }

    private fetchCurrentTechmap() {
        const culture = this.byCultures[this.selected.cultureIndex]
        this.tasks = []
        if (culture) {
            const techmap = culture.techmaps[this.selected.techmapIndex]
            if (techmap) {
                if (techmap.id) {
                    this.setLoadingDataNames('TechMapBoardGetCurrentTechmap')
                    httpGet({
                        url:  `${apiUrl3}/techmap/tasks/${techmap.id}/`,
                        isSecureRequest: true,
                        detailResponse: true,
                        onSuccess: json => {
                            if (json.ok) {
                                this.tasks = json.json
                            }
                        },
                        onError: error => {
                            console.log(error)
                        },
                        doFinally: () => {
                            this.setLoadingDataNames('TechMapBoardGetCurrentTechmap')
                        }
                    })
                }
            }
        }
    }

    private getColor(item: any) {
        const result = this.CultureColors.defined.find((x: any) => {
            if (item && item.toLowerCase().includes(x.crop.toLowerCase())) return x
        })
        if (result) return result.color

        const result2 = this.otherCultures.find((x: any) => {
            if (x.culture && item) {
                if (x.culture.toLowerCase().includes(item.toLowerCase()) ||
                    item.toLowerCase().includes(x.culture.toLowerCase())) {
                    return x
                }
            } else if (x.culture === item || (x.culture === '' && item === null)
                || (x.culture === null && item === '')) {
                return x
            }
        })
        if (result2) {
            return this.CultureColors.others[result2.index]
        } else {
            if (this.otherCultures.length > 0) {
                const index = this.otherCultures[this.otherCultures.length - 1].index
                this.otherCultures.push({
                    culture: item,
                    index: (index + 1) % 10
                })
                return this.CultureColors.others[index + 1]
            } else {
                this.otherCultures.push({
                    culture: item,
                    index: 0
                })
                return this.CultureColors.others[0]
            }
        }
    }

    private createTechmap() {
        const culture = this.byCultures[this.selected.cultureIndex]
        if (culture) {
            const techmap = culture.techmaps[this.selected.techmapIndex]
            if (techmap) {
                const el: any = this.$refs.newProgramModal
                if (el) {
                    const data = {
                        id: techmap.id,
                        name: techmap.name || '',
                        parent_culture: techmap.parent_culture || '',
                        culture: techmap.culture || '',
                        is_rest: techmap.is_rest || false,
                        group_user: Number(this.$route.params.farmId),
                        fields: techmap.fields
                    }
                    el.show(data)
                }
            }
        }
    }

    private deleteTechmap(id: number) {
        this.$confirm('Вы уверены что хотите удалить технологическую карту?', 'Подтверждение', 'info').then(() => {
            this.setLoadingDataNames('NewProgramModalDeleteTechmap')
            httpDelete({
                url: `${apiUrl3}/techmap/${id}/`,
                isSecureRequest: true,
                detailResponse: true,
                onSuccess: json => {
                    if (json.ok) {
                        this.$alert('Технологическая карта успешна удалена', 'Удалено', 'success')
                    } else {
                        this.$alert(`${JSON.stringify(json.json)}`, 'Ошибка', 'error')
                    }
                },
                onError: error => {
                    console.log(error)
                },
                doFinally: () => {
                    this.setLoadingDataNames('NewProgramModalDeleteTechmap')
                }
            })
        }).catch(() => {/* */})
    }

    private fetchAboutEvents() {
        this.setLoadingDataNames('TechMapBoardFetchTechmapByCulture')
        httpGet({
            url: `${apiUrl}/technology/fact/tasks/by/group_user/${this.$route.params.farmId}/`,
            isSecureRequest: true,
            detailResponse: true,
            onSuccess: json => {
                if (json.ok) {
                    this.byEvents = this.byEventsSortDate(json.json)
                }
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('TechMapBoardFetchTechmapByCulture')
            }
        })
    }

    private getDaysAboutEvents(date: any) {
        const newData = date.split('T').reverse().splice(1).join('-')
        return newData.split('-').reverse().join('.')
    }

    private getCountOfDaysEvents(date: any) {
        const start = date.split('T').reverse().slice(1).join() // mm-dd-yyyy
        const end = new Date() // Wed Feb 02 2022 00:00:00

        const date1 = new Date(start) // Wed Feb 02 2022 00:00:00
        const date2 = new Date(end) // Sep 08 2022 18:17:32

        const oneDay = 1000 * 60 * 60 * 24 // 86400000

        const diffInTime = date2.getTime() - date1.getTime() // 1662639472750 - 1643738400000
        return Math.round(diffInTime / oneDay) // Math.round(8901072750 / 86400000)
    }

    private dateSort(arr: any) {
        this.reversed = false
        const reversedArr = arr.reverse()
        return this.byEventsSortDate(reversedArr)
    }

    private byEventsSortDate(arr: any) {
        if (!this.reversed) return arr
        return arr.sort((a: any, b: any) => {
            const date1: any = new Date(b.start_date)
            const date2: any = new Date(a.start_date)
            return (date1 - date2)
        })
    }

    private getExpensesEvents(cost: any) {
        return cost !== null ? cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ' тг' : 'Нет данных'
    }

    private closeFiedlsDropdown(index: any) {
        return this.arrForEventsFeilds.pop(index)
        // return this.arrForEventsFeilds.pop()
    }

    private close() {
        this.$router.push({
            name: 'farm',
            params: {
                farmId: this.$route.params.farmId
            }
        })
    }
}
