






































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import {GetterNames, MutationNames} from '@/store/types'

@Component
export default class FarmMapControllers extends Vue {
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: any
    @Getter(GetterNames.getSideboardIsShown) private sideboardIsShown !: any
    @Getter(GetterNames.getFarmFromRoute) private getFarmFromRoute!: any
    @Getter(GetterNames.getFarmMapControllers) private getFarmMapControllers!: any
    @Getter(GetterNames.getTelematicsInfo) private getTelematicsInfo!: any
    @Mutation(MutationNames.setFarmMapControllers) private setFarmMapControllers!: any
    private isShown = false
    private textTypes = {
        converted: ['По умолчанию', 'Без подписи', 'Номер поля', 'Культура', 'Площадь'],
        selected: 'По умолчанию'
    }
    private telematicsTextTypes = {
        converted: ['Без подписи', 'Госномер', 'Название техники', 'Механизатор'],
        selected: 'Госномер'
    }
    private allowedFields: any = []
    private fieldsDropdown = false

    get IsVisible() {
        if (this.currentRoute) {
            return this.currentRoute.name === 'farm' || this.currentRoute.name === 'planning' || this.currentRoute.name === 'clonecropdata' ||
                (this.currentRoute.name === 'farmtelematics' && this.getTelematicsInfo.geozone.edit === 0)
        } else return false
    }

    @Watch('IsVisible')
    private onchangeVisibility(val: any, old: any) {
        if (val && val !== old) {
            this.setDefault()
        }
    }

    @Watch('getFarmFromRoute')
    private onloadFarm(val: any, old: any) {
        if (val && val !== old) {
            if (this.getFarmFromRoute) {
                this.allowedFields = this.getFarmFromRoute.fields
                this.setDefault()
            }
        }
    }

    private onSelectTelematicsTextType(item: any) {
        this.telematicsTextTypes.selected = item
        const index = this.telematicsTextTypes.converted.findIndex((x: any) => x === item)
        if (index !== -1) {
            this.setFarmMapControllers({telematicsTextType: index})
        }
    }

    private onSelectTextType(item: any) {
        this.textTypes.selected = item
        const index = this.textTypes.converted.findIndex((x: any) => x === item)
        if (index !== -1) {
            this.setFarmMapControllers({textType: index})
        }
    }

    private mounted() {
        this.setDefault()
        if (this.getFarmFromRoute) {
            this.allowedFields = this.getFarmFromRoute.fields
        }
    }

    private setDefault() {
        if (this.$route.name === 'planning') return
        this.setFarmMapControllers({
            selectedFields: this.getFarmFromRoute ? this.getFarmFromRoute.fields.map((x: any) => x.id) : [],
            textType: 0,
            fill: false,
            strokeWidth: 1,
            strokeColor: '#FFFFFF',
            textMaxScale: 4,
            textColor: '#FFFFFF',
            telematicsTextType: 1,
            coverageView: true
        })
    }

    private getSelectedFields() {
        if (this.getFarmMapControllers.selectedFields.length === 0) {
            return 'Выберите поля'
        } else if (this.getFarmMapControllers.selectedFields.length === this.allowedFields.length) {
            return 'Все поля'
        } else {
            let str = ''
            for (const field of this.allowedFields) {
                if (this.getFarmMapControllers.selectedFields.includes(field.id)) {
                    str += `№${field.name}, `
                }
            }
            return str
        }
    }

    private selectAllFields() {
        if (this.allowedFields.length === this.getFarmMapControllers.selectedFields.length) {
            this.getFarmMapControllers.selectedFields = []
        } else {
            for (const field of this.allowedFields) {
                if (!this.getFarmMapControllers.selectedFields.includes(field.id)) {
                    this.getFarmMapControllers.selectedFields.push(field.id)
                }
            }
        }
    }

    private selectField(fieldId: any) {
        const index = this.getFarmMapControllers.selectedFields.findIndex((x: any) => x === fieldId)
        if (index === -1) this.getFarmMapControllers.selectedFields.push(fieldId)
        else this.getFarmMapControllers.selectedFields.splice(index, 1)
    }

    private closeFarmMapControllers() {
        this.isShown = false
    }

    private closeFieldsDropdown() {
        this.fieldsDropdown = false
    }
}
