




































































import {Component, Vue} from 'vue-property-decorator'
import {Getter, Mutation} from 'vuex-class'
import {GetterNames, MutationNames} from '@/store/types'

@Component
export default class CadastreInfoPanel extends Vue {
  @Mutation(MutationNames.setCadastreData) private setCadastreData!: any
  @Getter(GetterNames.getCadastreData) private getCadastreData!: any

  // private changeCadastreLayers(layer: any) {
  //   this.setCadastreData = {
  //     layer:
  //   }
  // }
}
