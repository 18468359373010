









import { Component, Vue } from 'vue-property-decorator'
import FrontComponents from '@/views/FrontComponents/Index.vue'

@Component({
    components: {
        FrontComponents
    }
})
export default class Home extends Vue {
}
