export const permissionTypes = [
    {
        num: 1,
        name: 'Директор'
    },
    {
        num: 2,
        name: 'Менеджер'
    },
    {
        num: 3,
        name: 'Агроном'
    },
    {
        num: 4,
        name: 'Учетчик'
    },
    {
        num: 5,
        name: 'Зав. складом'
    },
    {
        num: 6,
        name: 'Механизатор'
    },
    {
        num: 7,
        name: 'Инженер'
    }
]

export const moduleTypes = [
    {
        container: 'Хозяйство',
        icon: 'farm/farm_icon.svg',
        visibility: false,
        modules: [
            {
                name: 'Добавить/Редактировать хозяйство',
                value: 'group_user_add_edit'
            },
            {
                name: 'Удалить хозяйство',
                value: 'group_user_delete'
            },
            {
                name: 'Оформить подписку',
                value: 'group_user_make_payment'
            },
            {
                name: 'Создать сезон',
                value: 'group_user_create_season'
            },
            {
                name: 'Рейтинг полей',
                value: 'group_user_kletka_rating'
            },
            {
                name: 'Агрохим анализ почвы',
                value: 'group_user_agro_analys'
            },
            {
                name: 'Телематика',
                value: 'group_user_telematica'
            }
        ]
    },
    {
        container: 'Поля',
        icon: 'farm/subfield_icon.svg',
        visibility: false,
        modules: [
            {
                name: 'Редактировать паспорт поля',
                value: 'kletka_passport'
            },
            {
                name: 'Удалить поле',
                value: 'kletka_delete'
            },
            {
                name: 'Просмотр истории поля',
                value: 'kletka_open_history'
            },
            {
                name: 'Добавить историю поля',
                value: 'kletka_add_history'
            },
            {
                name: 'Показатели',
                value: 'kletka_results'
            },
            {
                name: 'Урожайность',
                value: 'kletka_yield_prediction'
            },
            {
                name: 'Просмотр заметки',
                value: 'kletka_open_notice'
            },
            {
                name: 'Управление заметками',
                value: 'kletka_add_edit_delete_notice'
            },
            {
                name: 'Аналитика',
                value: 'kletka_analytics'
            }
        ]
    },
    {
        container: 'Технологическая карта',
        icon: 'techmap_icon.svg',
        visibility: false,
        modules: [
            {
                name: 'Просмотр тех. карты',
                value: 'task_open'
            },
            {
                name: 'Управление тех. картой',
                value: 'task_add_edit_delete'
            },
            {
                name: 'Изменение статуса мероприятие',
                value: 'task_is_done'
            }
        ]
    },
    {
        container: 'Сотрудники',
        icon: 'members_icon.svg',
        visibility: false,
        modules: [
            {
                name: 'Просмотр сотрудников',
                value: 'employee_open'
            },
            {
                name: 'Управление сотрудниками',
                value: 'employee_add_edit'
            }
        ]
    },
    {
        container: 'Автопарк',
        icon: 'farm/tractor_icon.svg',
        visibility: false,
        modules: [
            {
                name: 'Просмотр автопарка',
                value: 'autopark_open'
            },
            {
                name: 'Управление техникой',
                value: 'autopark_add_edit_delete'
            },
            {
                name: 'Просмотр сервисной книги',
                value: 'autopark_open_service_book'
            },
            {
                name: 'Управление записями сервисной книги',
                value: 'autopark_add_edit_delete_service_operation'
            }
        ]
    },
    {
        container: 'Склад',
        icon: 'farm/storage_icon.svg',
        visibility: false,
        modules: [
            {
                name: 'Просмотр склада',
                value: 'storage_open'
            },
            {
                name: 'Управление складом',
                value: 'storage_add_edit_delete'
            },
            {
                name: 'Просмотр остатков по складу',
                value: 'storage_open_remain'
            },
            {
                name: 'Редактировать остатки по складу',
                value: 'storage_edit_remain'
            },
            {
                name: 'Добавить операцию',
                value: 'storage_add_operation'
            },
            {
                name: 'Журнал операций склада',
                value: 'storage_all_operations'
            }
        ]
    },
    {
        container: 'Диспетчерская',
        icon: 'bell_icon.svg',
        visibility: false,
        modules: [
            {
                name: 'Управление диспетчерской',
                value: 'notification_manage'
            }
        ]
    },
    {
        container: 'Профиль',
        icon: 'user_icon.svg',
        visibility: false,
        modules: [
            {
                name: 'Заказы',
                value: 'profile_my_order'
            }
        ]
    }
]

export const permissionModules = [
    {
        num: 2,
        name: 'Менеджер',
        modules: [
            'group_user_add_edit',
            'group_user_delete',
            'group_user_make_payment',
            'group_user_create_season',
            'group_user_kletka_rating',
            'group_user_agro_analys',
            'group_user_telematica',
            'kletka_passport',
            'kletka_delete',
            'kletka_open_history',
            'kletka_add_history',
            'kletka_results',
            'kletka_yield_prediction',
            'kletka_open_notice',
            'kletka_add_edit_delete_notice',
            'kletka_analytics',
            'task_open',
            'task_add_edit_delete',
            'task_is_done',
            'employee_open',
            'employee_add_edit',
            'autopark_open',
            'autopark_add_edit_delete',
            'autopark_open_service_book',
            'autopark_add_edit_delete_service_operation',
            'storage_open',
            'storage_add_edit_delete',
            'storage_open_remain',
            'storage_edit_remain',
            'storage_add_operation',
            'storage_all_operations',
            'profile_my_order',
            'notification_manage'
        ]
    },
    {
        num: 3,
        name: 'Агроном',
        modules: [
            'group_user_create_season',
            'group_user_kletka_rating',
            'group_user_agro_analys',
            'group_user_telematica',
            'kletka_passport',
            'kletka_open_history',
            'kletka_add_history',
            'kletka_results',
            'kletka_yield_prediction',
            'kletka_open_notice',
            'kletka_add_edit_delete_notice',
            'kletka_analytics',
            'task_open',
            'task_add_edit_delete',
            'task_is_done',
            'profile_my_order'
        ]
    },
    {
        num: 4,
        name: 'Учетчик',
        modules: [
            'group_user_telematica',
            'kletka_open_history',
            'kletka_open_notice',
            'task_open',
            'autopark_open',
            'autopark_open_service_book',
            'storage_open',
            'storage_open_remain',
            'storage_all_operations',
            'notification_manage'
        ]
    },
    {
        num: 5,
        name: 'Зав. складом',
        modules: [
            'kletka_open_notice',
            'task_open',
            'autopark_open',
            'autopark_open_service_book',
            'storage_open',
            'storage_add_edit_delete',
            'storage_open_remain',
            'storage_edit_remain',
            'storage_add_operation',
            'storage_all_operations'
        ]
    },
    {
        num: 6,
        name: 'Механизатор',
        modules: [
            'group_user_telematica',
            'kletka_open_notice',
            'task_open',
            'task_is_done',
            'autopark_open',
            'autopark_open_service_book'
        ]
    },
    {
        num: 7,
        name: 'Инженер',
        modules: [
            'group_user_telematica',
            'kletka_open_notice',
            'task_open',
            'task_is_done',
            'autopark_open',
            'autopark_add_edit_delete',
            'autopark_open_service_book',
            'autopark_add_edit_delete_service_operation',
            'storage_open',
            'storage_open_remain',
            'storage_all_operations'
        ]
    }
]