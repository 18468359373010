
























































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { httpGet, httpDelete } from '@/util/http'
import { apiUrl, baseUrl } from '@/domain/constants'
import { Getter, Mutation } from 'vuex-class'
import { GetterNames, MutationNames } from '@/store/types'
import { Route } from 'vue-router'
import i18n from '@/i18n'
import Sideboard from '@/components/Sideboard.vue'
import { notesDetailTable, notesDetailTableByType, notesRegistrationDetails } from '@/data/notesData'
import { downloadExcelData, getBase64ImageFromURL, makePDF, PdfActions } from '@/utils/services'
import Farm from '@/models/Farm'
import { egisticLogo, pdfWaterMark } from '@/data/pdfImageData'

@Component({
    components: {
        Sideboard
    }
})
export default class NoteDetails extends Vue {
    @Mutation(MutationNames.setIsDataLoading) private setIsDataLoading!: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    @Mutation(MutationNames.setPhotosData) private setPhotosData!: any

    @Getter(GetterNames.getCurrentRoute) private currentRoute !: Route
    @Getter(GetterNames.getLoadingDataNames) private getLoadingDataNames!: any
    @Getter(GetterNames.getPhotosData) private getPhotosData!: any
    @Getter(GetterNames.getFarmFromRoute) private getFarmFromRoute!: Farm

    private notesDetailTableByType = notesDetailTableByType
    private notesRegistrationDetails = notesRegistrationDetails
    private moreDropdown = false
    private replyMoreDropdown = -1
    private notesDetailTable = notesDetailTable
    private employeeDropdown = false
    private noteImages: any[] = []
    private note: any = {}

    /*------------------------------ Table Data ----------------------------------*/
    private downloadExcelData = downloadExcelData

    private getTypeName(type: string) {
        if ( type === 'type1' ) {
            return i18n.t('headers.workspace.fields.notes.note_addition.problem.type1')
        } else if ( type === 'type2' ) {
            return i18n.t('headers.workspace.fields.notes.note_addition.problem.type2')
        } else if ( type === 'type3') {
            return i18n.t('headers.workspace.fields.notes.note_addition.problem.type3')
        } else {
            return ''
        }
    }

    get field() {
        if (this.getFarmFromRoute) {
            return this.getFarmFromRoute.fields.find((x: any) => x.id === parseInt(this.$route.params.subfieldId, 10))
        }
        return false
    }

    private printPdf() {
        const pdfMaindata = [
            [
                {
                    label: i18n.t('headers.workspace.farm.title'),
                    data: this.getFarmFromRoute.name
                },
                {
                    label: i18n.t('headers.workspace.fields.field'),
                    data: this.field.name
                },
                {
                    label: i18n.t('headers.workspace.fields.info.crop'),
                    data: this.field.passport ? this.field.passport.culture : null
                },
                {
                    label: i18n.t('headers.workspace.fields.info.date'),
                    data: this.field.passport ? this.field.passport.start_date : null
                },
                {
                    label: i18n.t('headers.workspace.fields.notes.note_addition.date'),
                    data: this.note.round_date
                }
            ],
            [
                {
                    label: i18n.t('headers.workspace.farm.location'),
                    data: this.getFarmFromRoute.oblast ? this.getFarmFromRoute.oblast : ''
                },
                {
                    label: i18n.t('headers.workspace.fields.edit.area'),
                    data: this.field.area + i18n.t('general.units.he')
                },
                {
                    label: i18n.t('headers.workspace.fields.info.sort'),
                    data: this.field.passport ? this.field.passport.sort : null
                },
                {
                    label: i18n.t('headers.workspace.fields.notes.note_addition.phen_stage'),
                    data: this.note.phenophase
                }
            ]
        ]
        const typeDetail = this.note.targets.map((target: any) => {
            return [
                target.name, target.value
            ]
        })
        const toRmv: number[] = []
        const replyTable = this.note.replies.map((reply: any, index: number) => {
            if ( reply.reply_date && reply.text ) {
                return [
                    reply.employee, this.getDatetoString(reply.reply_date), reply.text
                ]
            } else {
                toRmv.push(index)
                return {}
            }
        })
        toRmv.forEach((i: number) => {
            replyTable.splice(i, 1)
        })
        const preparationTable = this.note.preparation_read.map((prep: any) => {
            return [
                prep.name, prep.consumtion_rate, prep.methods_or_restrictions, prep.target
            ]
        })
        const docDefinition: any = {
            pageSize: {
                width: 595,
                height: 842
            },
            pageMargins: [30, 30, 30, 30],
            background: [
                {
                    image: pdfWaterMark,
                    margin: [308, 0, 0, 0],
                    width: 360
                }
            ]
        }
        const content: any = [
            {
                image: egisticLogo,
                margin: [0, 10, 0, 0],
                width: 91.14,
                height: 30,
                alignment: 'center'
            },
            {
                text: i18n.t('headers.workspace.fields.notes.note_details.title'),
                fontSize: 14,
                bold: true,
                margin: [0, 24, 0, 0],
                alignment: 'center'
            },
            {
                text: this.getDatetoString(this.note.created_at),
                fontSize: 14,
                bold: true,
                margin: [0, 8, 0, 24],
                alignment: 'center'
            },
            {
                columns: pdfMaindata.map((col: any) => {
                    return {
                        width: '50%',
                        text: col.map((row: any) => {
                            if ( row.data !== 'null' && row.data ) {
                                return {
                                    text: [
                                        {
                                            text: row.label + ":  ",
                                            fontSize: '12',
                                            bold: true,
                                            lineHeight: 1.6
                                        },
                                        row.data + "\n"
                                    ]
                                }
                            }
                        })
                    }
                })
            },
            this.note.problem !== 'type4' ? {
                text: this.getTypeName(this.note.problem),
                fontSize: '12',
                bold: true,
                margin: [0, 16, 0, 12]
            } : '',
            this.note.problem !== 'type4' ? {
                table: {
                    widths: ['*', '*'],
                    headerRows: 1,
                    body: [
                        [
                            {
                                text: i18n.t(this.targetValues[0].title),
                                bold: true
                            },
                            {
                                text: i18n.t(this.targetValues[1].title),
                                bold: true
                            }
                        ],
                        ...typeDetail
                    ]
                }
            } : '',
            {
                text: i18n.t('headers.workspace.autopark.add_record.note'),
                fontSize: '12',
                bold: true,
                margin: [0, 16, 0, 12]
            },
            {
                table: {
                    widths: ['100%'],
                    body: [
                        [this.note.text]
                    ]
                }
            },
            this.note.is_replied ? {
                text: i18n.t('headers.workspace.fields.notes.reply.title'),
                fontSize: '12',
                bold: true,
                margin: [0, 16, 0, 12]
            } : '',
            this.note.is_replied ? {
                table: {
                    widths: ['*', '*', '50%'],
                    headerRows: 1,
                    body: [
                        [
                            {
                                text: i18n.t('headers.workspace.fields.notes.reply.from'),
                                bold: true
                            },
                            {
                                text: i18n.t('headers.workspace.fields.notes.time'),
                                bold: true
                            },
                            {
                                text: i18n.t('headers.workspace.fields.notes.reply.text'),
                                bold: true
                            }
                        ],
                        ...replyTable
                    ]
                }
            } : '',
            this.note.problem !== 'type4' ? {
                text: i18n.t('headers.workspace.fields.notes.note_addition.problem_details.advice_title'),
                fontSize: '12',
                bold: true,
                margin: [0, 16, 0, 12]
            } : '',
            this.note.problem !== 'type4' ? {
                table: {
                    headerRows: 1,
                    body: [
                        [
                            {
                                text: i18n.t(`headers.workspace.fields.notes.note_addition.problem_details.${this.note.problem}.advice.header`),
                                bold: true
                            },
                            {
                                text: i18n.t('headers.workspace.fields.notes.note_details.consumption_rate'),
                                bold: true
                            },
                            {
                                text: i18n.t('headers.workspace.fields.notes.note_addition.treatment_method'),
                                bold: true
                            },
                            {
                                text: i18n.t('headers.workspace.fields.notes.note_details.target'),
                                bold: true
                            }
                        ],
                        ...preparationTable
                    ]
                },
                pageBreak: this.note.images.length > 0 ? 'after' : ''
            } : '',
            this.note.images.length > 0 ? {
                text: i18n.t('headers.workspace.fields.notes.note_addition.photo'),
                fontSize: '12',
                bold: true,
                margin: [0, 0, 0, 12]
            } : ''
        ]
        const imagesData: any[] = []
        if ( this.note.images.length > 0 ) {
            this.note.images.forEach((img: any, index: number) => {
                getBase64ImageFromURL(this.loadImage(img.image))
                .then((data: any) => {
                    imagesData.push({
                        image: data,
                        width: 420,
                        margin: [0, 16, 0, 20],
                        alignment: 'center'
                    })
                    imagesData.push({
                        columns: [
                            {
                                width: '*',
                                text: [
                                    {
                                        text: i18n.t('headers.workspace.fields.notes.note_addition.photo_info'),
                                        bold: true
                                    },
                                    `\n${i18n.t('headers.workspace.fields.notes.note_addition.added_by')}: ${img.full_name}`
                                ],
                                lineHeight: 1.4
                            },
                            {
                                width: '*',
                                text: [
                                    {
                                        text: img.created_at + "\n",
                                        alignment: 'right'
                                    },
                                    {
                                        text: `${i18n.t('headers.workspace.fields.notes.note_addition.added_from')}: ${img.is_mobile ? i18n.t('headers.workspace.fields.notes.note_addition.from_phone') : i18n.t('headers.workspace.fields.notes.note_addition.from_pc')}`,
                                        alignment: 'right'
                                    }
                                ],
                                lineHeight: 1.8
                            }
                        ]
                    })
                }).then(() => {
                    if ( imagesData.length === this.note.images.length * 2 ) {
                        content.push(imagesData)
                        docDefinition.content = content
                        makePDF(PdfActions.PRINT, docDefinition)
                    }
                })
            })
        } else {
            docDefinition.content = content
            makePDF(PdfActions.PRINT, docDefinition)
        }
    }

    /*---------------------------------- Page Content -----------------------------------*/
    private getAllLoading() {
        return (!this.getLoadingDataNames('noteViewGetCurrentNote') && !this.getLoadingDataNames('noteDetailsDeletingNote'))
    }

    private mounted() {
        // console.log(this.currentRoute, 'note-detail') // this.$route
        this.getCurrentNote()
    }

    private getPhotosBy(id: number) {
        return this.note.images.filter((x: any) => x.user === id)
    }

    get targetValues() {
        if ( this.note.problem === 'type1' ) {
            return this.notesDetailTableByType.type1
        } else if ( this.note.problem === 'type2' ) {
            return this.notesDetailTableByType.type2
        } else if ( this.note.problem === 'type3' ) {
            return this.notesDetailTableByType.type3
        }
        return []
    }

    private closeReplyMoreDropdown() {
        this.replyMoreDropdown = -1
    }

    private closeDropdown() {
        this.moreDropdown = false
    }

    private getDatetoString(date: any) {
        if (date) {
            const months = [ i18n.t('general.dates.months[0]') as string, i18n.t('general.dates.months[1]') as string, i18n.t('general.dates.months[2]') as string, i18n.t('general.dates.months[3]') as string, i18n.t('general.dates.months[4]') as string, i18n.t('general.dates.months[5]') as string, i18n.t('general.dates.months[6]') as string, i18n.t('general.dates.months[7]') as string, i18n.t('general.dates.months[8]') as string, i18n.t('general.dates.months[9]') as string, i18n.t('general.dates.months[10]') as string, i18n.t('general.dates.months[11]') as string]
            return date.substring(8, 10) + ' ' + months[Number(date.substring(5, 7)) - 1] + ', ' + date.substring(11, 16)
        }
        return i18n.t('general.dates.format_date')
    }

    private getCurrentNote() {
        console.log(this.currentRoute.params)
        this.setLoadingDataNames('noteViewGetCurrentNote')
        httpGet({
            url:  `${apiUrl}/acts/${this.currentRoute.params.noteId}/`,
            isSecureRequest: true,
            onSuccess: json => {
                console.log(json)
                this.note = json
                this.note.images.forEach((img: any) => {
                    if ( img.is_owner_image ) {
                        this.noteImages.push(img)
                    }
                })
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('noteViewGetCurrentNote')
            }
        })
    }

    private deleteNote() {
        Vue.alert('', `${this.$t('messages.questions.delete.note')}`, 'warning').then(() => {
            this.setLoadingDataNames('noteDetailsDeletingNote')
            httpDelete({
                url: `${apiUrl}/acts/${this.currentRoute.params.noteId}/`,
                isSecureRequest: true,
                onSuccess: () => {
                    Vue.alert('', `${this.$t('messages.success.note_deleted')}`, 'success')
                    this.setLoadingDataNames('noteDetailsDeletingNote')
                },
                onError: error => {
                    console.log(error)
                },
                doFinally: () => {
                    this.backToNotes()
                }
            })
        })

    }

    private closeTypeDropdown() {
        this.employeeDropdown = false
    }

    private backToNotes() {
        this.$router.push({name: 'subfieldnotes'})
    }

    private editNote() {
        this.$router.push(`/cadastres/farm/${this.currentRoute.params.farmId}/subfields/${this.currentRoute.params.subfieldId}/noteupdate/${this.currentRoute.params.noteId}`)
    }

    private replyNote() {
        this.$router.push(`/cadastres/farm/${this.currentRoute.params.farmId}/subfields/${this.currentRoute.params.subfieldId}/notereply/${this.currentRoute.params.noteId}`)
    }

    private loadImage(url: any) {
        const photo: any = url.image_squeezed || url.image
        return `${baseUrl}${photo}`
    }
}
