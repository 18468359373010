export default {
    welcome: "Welcome",
    whoarewe1: "EGISTIC - your farm's management system",
    whoarewe2: "Farm management system for agricultural producers and agricultural consultants",
    address: "Office 13.3, 53 Kabanbay batyr ave., Nur-Sultan city, Kazakhstan",
    mission: {
        1: "“Our mission is",
        2: "to make farm management easier”",
        content: {
            1: "/En/ Система управления фермой EGISTIC основана на реализации интегрированного решения для мониторинга и управления посевными площадями с использованием технологий дистанционного зондирования, высокоточной спутниковой навигации, геоинформационных систем и технологий машинного обучения.",
            2: "/En/ История «EGISTIC» начинается в 2018 году, когда Жандос Керимкулов (генеральный директор и соучредитель) увидел технологию облегчения системы управления фермой для фермеров в Казахстане с помощью спутниковых снимков с открытым исходным кодом. На казахстанском рынке есть несколько программных сервисов, которые являются зарубежными, дорогими и сложными в использовании. Вот почему только холдинги могли позволить себе пользоваться такими услугами. В течение года команда Egistic создавала простое программное обеспечение, которое помогло бы фермерам проводить простые операции, чтобы начать использовать спутниковые изображения.",
            3: "/En/ Теперь, за два года тестирования и разработки, сервис EGISTIC был расширен, и мы можем сказать, что система имеет полный список сервисов для запуска фермы.",
            3.5: "/En/ У нас более 1 000 пользователей, и мы гордимся тем, что наши фермеры имеют доступ к данным спутниковых снимков и погодным данным за 2016 год в один клик."
        }
    },
    guide_start: "Start guide",
    application: "Egistic mobile application is available. Would you like to install it?",
    personal_info: {
        name: "Name",
        surname: "Surname",
        email: "E-mail",
        number: "Mobile number",
        position: "Position",
        password: "Password",
        permission: "Access type"
    },
    units: {
        he: " he",
        t: " t",
        tenge: " tenge",
        tenge_he: " tenge/he",
        kg_he: " kg/he",
        tg_he: " tg/he",
        mm: " mm",
        tg: " tg",
        centner_he: "c/he",
        t_he: "t/he",
        km_h: " km/h",
        m_s: " m/s",
        l: " l",
        km: " km",
        m: " m",
        h: " h"
    },
    language: "English",
    dates: {
        date: "Date",
        format_date: "DD.MM.YY",
        months: [ "january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"],
        week: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        month: ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"],
        today: "Today",
        from: "from ",
        starting_from: "from ",
        to: "to ",
        up_to: "by ",
        format_time: "HH:mm"
    },
    process: {
        done: "Done",
        cancelled: "Cancelled",
        planned: "Planned",
        in_process: "In process",
        outdated: "Outdated",
        loading: "Loading",
        broadcasting: "Broadcasting",
        connection_failed: "Connection failed"
    },
    bill: {
        check: "An invoice for payment",
        number: "Number:",
        from_date: "From the date:",
        status: "Status:",
        paid: "Paid",
        not_paid: "Not paid",
        total: "Total:"
    },
    download_file: "Download a file"
}