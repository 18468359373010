import CarParkView from '@/views/MainPage/HomeView/Sideboards/CarparkBoard/CarParkView.vue'
import ServiceBookView from '@/views/MainPage/HomeView/Sideboards/CarparkBoard/ServiceBookView.vue'
import CarReportView from '@/views/MainPage/HomeView/Sideboards/CarparkBoard/CarReportView.vue'

const routes = [
    {
        path: 'carpark',
        name: 'carpark',
        component: CarParkView
    },
    {
        path: 'carpark/servicebook/:carId',
        name: 'servicebook',
        component: ServiceBookView
    },
    {
        path: 'carpark/carreport/:carId',
        name: 'carreport',
        component: CarReportView
    }
]

export default routes