




import { Component, Vue, Watch } from 'vue-property-decorator'
import { baseSocketUrl } from '@/domain/constants'
import { GetterNames, MutationNames } from '@/store/types'
import { Getter, Mutation } from 'vuex-class'
import { getAuthToken } from '@/domain/constants'
import i18n from '@/i18n'

@Component
export default class TelematicsSocket extends Vue {
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: any
    @Getter(GetterNames.IS_SIGNED_IN) private isSignedIn !: boolean
    @Getter(GetterNames.getTelematicsInfo) private getTelematicsInfo!: any
    @Getter(GetterNames.getLoadingTractorInfo) private getLoadingTractorInfo!: any
    @Mutation(MutationNames.setTelematicsInfo) private setTelematicsInfo!: any
    @Mutation(MutationNames.setIsDataLoading) private setIsDataLoading !: any
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames !: any
    @Mutation(MutationNames.setLoadingTractorInfo) private setLoadingTractorInfo !: any
    private colors: any = ['#2B4DFF', '#AA27E8', '#F37500', '#06BAF2', '#FFC700', '#5AF5D9', '#7D5EF8', '#FC7BE7', '#ADDE30']
    private coverageColors: any = ['rgba(43,77,255, 0.25)',  'rgba(170,39,232, 0.25)', 'rgba(243,117,0, 0.25)', 'rgba(6,186,242, 0.25)',
            'rgba(255,199,0, 0.25)', 'rgba(90,245,217, 0.25)', 'rgba(125,94,248, 0.25)', 'rgba(252,123,231, 0.25)', 'rgba(173,222,48, 0.25)']

    get getLiveBroadCast() {
        const tractors = this.getTelematicsInfo.tractorsData.map((x: any) => {
            return {
                imei: x.tractorIMEI,
                is_live: x.IS_LIVE
            }
        })
        return tractors
    }

    get getHistoryBroadCast() {
        const tractors = this.getTelematicsInfo.tractorsData.map((x: any) => {
            return {
                imei: x.tractorIMEI,
                is_history: x.IS_HISTORY,
                startDate: x.historyStartDate,
                endDate: x.historyEndDate
            }
        })
        return tractors
    }

    @Watch('getLiveBroadCast', { immediate: true, deep: true })
    private onchangeLiveBroadCasting(val: any, old: any) {
        if (this.getTelematicsInfo.socket && val && old) {
            if (this.getTelematicsInfo.socket.readyState === WebSocket.OPEN) {
                const online = val.filter((x: any) => old.some((y: any) => y.imei === x.imei && x.is_live && !y.is_live))
                    .map((x: any) => x.imei)
                const offline = val.filter((x: any) => old.some((y: any) => y.imei === x.imei && !x.is_live && y.is_live))
                    .map((x: any) => x.imei)
                this.getTelematicsInfo.tractorsData.filter((x: any) => offline.includes(x.tractorIMEI)).forEach((x: any) => {
                    this.getTelematicsInfo.tractorsData[x.index].SOCKET_STATUS = 0
                })
                this.getTelematicsInfo.tractorsData.filter((x: any) => online.includes(x.tractorIMEI) && x.SOCKET_STATUS !== 2)
                    .forEach((x: any) => {
                        this.getTelematicsInfo.tractorsData[x.index].SOCKET_STATUS = 1
                        const colorIndex = this.getLessUsedColor()
                        this.getTelematicsInfo.tractorsData[x.index].roadColor = this.colors[colorIndex]
                        this.getTelematicsInfo.tractorsData[x.index].coverageColor = this.coverageColors[colorIndex]
                    })
                this.getTelematicsInfo.socket.send(JSON.stringify({
                    type: 'enable_imeis',
                    imeis: online
                }))
                this.getTelematicsInfo.socket.send(JSON.stringify({
                    type: 'disable_imeis',
                    imeis: offline
                }))
                this.checkForMainTractor()
            }
        }
    }

    @Watch('getHistoryBroadCast', {immediate: true, deep: true})
    private onchangeHistoryBroadCasting(val: any, old: any) {
        if (this.getTelematicsInfo.socket && val && old) {
            if (this.getTelematicsInfo.socket.readyState === WebSocket.OPEN) {
                const online = val.filter((x: any) => old.some((y: any) => y.imei === x.imei &&
                ((!y.is_history && x.is_history) || (y.startDate !== x.startDate || y.endDate !== x.endDate)))
                || (x.is_history && old.length === 0)).map((x: any) => x.imei)
                if (this.getTelematicsInfo.by_agregate) {
                    const offline = val.filter((x: any) => old.some((y: any) => y.imei === x.imei &&
                    ((y.is_history && !x.is_history) || (y.startDate !== x.startDate || y.endDate !== x.endDate))))
                        .map((x: any) => x.imei)
                    this.getTelematicsInfo.tractorsData.filter((x: any) => offline.includes(x.tractorIMEI) ||
                        (!online.includes(x.tractorIMEI) && x.IS_HISTORY)).forEach((x: any) => {
                            if (x.historyIdentifier && x.historyCurrentCount !== x.historyTotalCount  && x.historyTotalCount !== 0) {
                                this.getTelematicsInfo.socket.send(JSON.stringify({
                                    type: 'cancel_task',
                                    identifier: this.getTelematicsInfo.tractorsData[x.index].historyIdentifier
                                }))
                            }
                            this.getTelematicsInfo.tractorsData[x.index].historyIdentifier = ''
                            this.getTelematicsInfo.tractorsData[x.index].historyTotalCount = 0
                            this.getTelematicsInfo.tractorsData[x.index].historyCurrentCount = 0
                            this.setIsDataLoading(false)
                        })
                }
                this.getTelematicsInfo.tractorsData.filter((x: any) => online.includes(x.tractorIMEI) && x.IS_HISTORY).forEach((x: any) => {
                    this.setTelematicsInfo({
                        arrowsView: false,
                        controlPanelExtension: true
                    })
                    this.getTelematicsInfo.socket.send(JSON.stringify({
                        type: 'history_request',
                        imei: x.tractorIMEI,
                        start_time: x.historyStartDate,
                        end_time: x.historyEndDate,
                        min_speed: x.MIN_SPEED,
                        max_speed: x.MAX_SPEED
                    }))
                    const colorIndex = this.getLessUsedColor()
                    this.getTelematicsInfo.tractorsData[x.index].roadColor = this.colors[colorIndex]
                    this.getTelematicsInfo.tractorsData[x.index].coverageColor = this.coverageColors[colorIndex]
                    this.setIsDataLoading(true)
                })
                this.checkForMainTractor()
            }
        }
    }

    private checkForMainTractor() {
        if (this.getTelematicsInfo.tractorsData.find((x: any) => x.IS_LIVE || x.IS_HISTORY)) {
            if (this.getTelematicsInfo.autoTracking.mainTractorID !== -1) {
                const tractor = this.getTelematicsInfo.tractorsData.find((x: any) =>
                    x.tractorID === this.getTelematicsInfo.autoTracking.mainTractorID)
                if (tractor) {
                    if (!tractor.IS_LIVE && !tractor.IS_HISTORY) {
                        this.setTelematicsInfo({
                            autoTracking: {
                                mainTractorID: -1
                            }
                        })
                        const newTractor = this.getTelematicsInfo.tractorsData.find((x: any) => x.IS_LIVE || x.IS_HISTORY)
                        if (newTractor) {
                            this.setTelematicsInfo({
                                autoTracking: {
                                    mainTractorID: newTractor.tractorID
                                }
                            })
                        }
                    }
                }
            } else {
                const tractor = this.getTelematicsInfo.tractorsData.find((x: any) => x.IS_LIVE || x.IS_HISTORY)
                if (tractor) {
                    this.setTelematicsInfo({
                        autoTracking: {
                            mainTractorID: tractor.tractorID
                        }
                    })
                }
            }
        } else {
            this.setTelematicsInfo({
                autoTracking: {
                    mainTractorID: -1,
                    tracking: false
                }
            })
        }
    }

    private getLessUsedColor() {
        const result = this.getTelematicsInfo.tractorsData.reduce((r: any, v: any) => {
            if (v.roadColor !== '#E0E2D8') {
                if (!r.find((x: any) => x.color === v.roadColor)) {
                    r.push({
                        color: v.roadColor,
                        count: this.getTelematicsInfo.tractorsData.filter((x: any) => x.roadColor === v.roadColor).length
                    })
                }
            }
            return r
        }, [])
        const colorIndex = this.colors.findIndex((x: any) => !result.find((y: any) => y.color === x))
        if (colorIndex !== -1) {
            return colorIndex
        } else {
            const color = result.reduce((r: any, v: any) => {
                if (r.count > v.count) {
                    return v
                } else {
                    return r
                }
            }, result[0])
            const colorIndex2 = this.colors.findIndex((x: any) => color.color === x)
            return colorIndex2
        }
    }

    private clearSocket() {
        if (this.getTelematicsInfo.socket) {
            if (this.getTelematicsInfo.socket.readyState === WebSocket.OPEN) {
                this.getTelematicsInfo.socket.close()
                this.setIsDataLoading(false)
            }
        }
    }

    private createSocket(online: number) {
        if (!this.isSignedIn) return
        if (this.getTelematicsInfo.socket) {
            if (this.getTelematicsInfo.socket.readyState === WebSocket.OPEN) {
                this.getTelematicsInfo.socket.close()
                this.setIsDataLoading(false)
            }
        }
        const currentToken = getAuthToken().split(' ')[1]
        this.getTelematicsInfo.socket = new WebSocket(`${baseSocketUrl}/ws/telematics/`, ['subprotocol', currentToken, Number(this.currentRoute.params.farmId), online])
        this.getTelematicsInfo.socket.onopen = () => {
            console.log(i18n.t('messages.success.connection'))
        }
        this.getTelematicsInfo.socket.onclose = (event: any) => {
            if (event.wasClean) {
                console.log(`[close] Соединение закрыто чисто, код=${event.code} причина=${event.reason}`)
            } else {
                console.log('[close] Соединение прервано')
                for (const [index, tractor] of this.getTelematicsInfo.tractorsData.entries()) {
                    this.getTelematicsInfo.tractorsData[index].SOCKET_STATUS = 3
                }
            }
        }
        this.getTelematicsInfo.socket.onerror = (error: any) => {
            console.log(`[error] ${error.message}`)
        }
        this.getTelematicsInfo.socket.onmessage = (event: any) => {
            if (this.currentRoute.name !== 'farmtelematics' && this.currentRoute.name !== 'fieldtelematics') {
                this.clearSocket()
                return
            }
            const response = JSON.parse(event.data)
            if (response.message !== 'ok' && response.type === 'response') {
                if (!this.getLoadingTractorInfo('no_imei')) {
                    this.setLoadingTractorInfo('no_imei')
                    this.setIsDataLoading(false)
                }
            } else if (response.type === 'response') {
                this.setLoadingTractorInfo('clean')
            }
            if (response.type === 'status') this.handleStatus(response)
            if (response.type === 'online_data') this.handleOnlineData(response)
            if (response.type === 'response') this.haddleResponseMessage(response)
            if (response.type === 'history_response') this.handdleHistoryResponse(response)
            if (response.type === 'min_speed_response') this.handdleMinSpeedResponse(response)
            if (response.type === 'max_speed_response') this.handdleMaxSpeedResponse(response)
            if (response.type === 'stop_interval_response') this.handdleStopPointsResponse(response)
        }
    }

    private handleStatus(response: any) {
        for (const tractor of response.online_status) {
            const index = this.getTelematicsInfo.tractorsData.findIndex((x: any) => x.tractorIMEI === tractor.imei)
            if (index !== -1) {
                this.getTelematicsInfo.tractorsData[index].IS_ONLINE = tractor.is_online
            }
        }
    }

    private handleOnlineData(response: any) {
        const index = this.getTelematicsInfo.tractorsData.findIndex((x: any) => x.tractorIMEI === response.data[0].imei)
        if (index !== -1) {
            if (this.getTelematicsInfo.SOCKET_STATUS !== 0) {
                for (const data of response.data) {
                    this.getTelematicsInfo.tractorsData[index].addLiveData(data)
                    this.getTelematicsInfo.tractorsData[index].SOCKET_STATUS = 2
                    const tractor = this.getTelematicsInfo.tractorsData[index]
                    const newtime = new Date(data.date_time).getTime()
                    const tracktime = Number(this.getTelematicsInfo.tracktime)
                    if (this.getTelematicsInfo.autoplay) {
                        if (Math.abs(newtime - tracktime) < 60000 || tracktime === 0 || tractor.currentIndex === (tractor.data.length - 2) || tractor.data.length === 1) {
                            const newtracktime = this.getTelematicsInfo.tractorsData[index].setCurrentIndex(this.getTelematicsInfo.tractorsData[index].data.length - 1, true)
                            if (this.getTelematicsInfo.tracktime < newtracktime) {
                                this.getTelematicsInfo.tracktime = newtracktime
                            }
                        }
                    }
                }
            }
        }
    }

    private haddleResponseMessage(response: any) {
        if (response.message === 'ok') {
            if (response.hasOwnProperty('identifier')) {
                const index = this.getTelematicsInfo.tractorsData.findIndex((x: any) => x.IS_HISTORY
                    && x.tractorIMEI === response.imei)
                if (index !== -1) {
                    this.getTelematicsInfo.tractorsData[index].historyIdentifier = response.identifier
                    this.getTelematicsInfo.tractorsData[index].historyTotalCount = response.total_count
                    this.setIsDataLoading(response.total_count !== 0)
                }
            }
        }
    }

    private handdleHistoryResponse(response: any) {
        const index = this.getTelematicsInfo.tractorsData.findIndex((x: any) => x.IS_HISTORY
            && x.historyIdentifier === response.identifier)
        if (index !== -1) {
          this.setLoadingTractorInfo('clean')
          for (const data of response.data) {
                this.getTelematicsInfo.tractorsData[index].addLiveData(data)
                const tractor = this.getTelematicsInfo.tractorsData[index]
                const newtime = new Date(data.date_time).getTime()
                const tracktime = Number(this.getTelematicsInfo.tracktime)
                if (this.getTelematicsInfo.autoplay) {
                    if (Math.abs(newtime - tracktime) < 60000 || tracktime === 0 || tractor.currentIndex === (tractor.data.length - 2)) {
                        const newtracktime = this.getTelematicsInfo.tractorsData[index].setCurrentIndex(this.getTelematicsInfo.tractorsData[index].data.length - 1, true)
                        if (this.getTelematicsInfo.tracktime < newtracktime) {
                            this.getTelematicsInfo.tracktime = newtracktime
                        }
                    }
                }
            }
          this.getTelematicsInfo.tractorsData[index].historyCurrentCount = response.indexing + 1
          if (this.getTelematicsInfo.tractorsData[index].historyTotalCount === response.indexing + 1) {
                this.setIsDataLoading(false)
            }
        }
    }

    private handdleMinSpeedResponse(response: any) {
        const index = this.getTelematicsInfo.tractorsData.findIndex((x: any) => x.IS_HISTORY
            && x.historyIdentifier === response.identifier)
        if (index !== -1) {
            for (const data of response.data) {
                this.getTelematicsInfo.tractorsData[index].addMinSpeedData(data)
            }
        }
    }

    private handdleMaxSpeedResponse(response: any) {
        const index = this.getTelematicsInfo.tractorsData.findIndex((x: any) => x.IS_HISTORY
            && x.historyIdentifier === response.identifier)
        if (index !== -1) {
            for (const data of response.data) {
                this.getTelematicsInfo.tractorsData[index].addMaxSpeedData(data)
            }
        }
    }

    private handdleStopPointsResponse(response: any) {
        const index = this.getTelematicsInfo.tractorsData.findIndex((x: any) => x.IS_HISTORY
            && x.historyIdentifier === response.identifier)
        if (index !== -1) {
            if (response.data.length === 0) {
              this.setLoadingTractorInfo('no_data')
            }
            for (const data of response.data) {
                this.getTelematicsInfo.tractorsData[index].addStopPointData(data)
            }
        }
    }

    private handdleNoSignalResponse(response: any) {
        const index = this.getTelematicsInfo.tractorsData.findIndex((x: any) => x.IS_HISTORY
            && x.historyIdentifier === response.identifier)
        if (index !== -1) {
            for (const data of response.data) {
                this.getTelematicsInfo.tractorsData[index].addNosignalData(data)
            }
        }
    }
}
