


























import { Component, Vue, Watch, Emit, Prop } from 'vue-property-decorator'

@Component
export default class BaseDropdownContainer extends Vue {
    @Prop({default: 'Start Typing'}) private placeholder!: string
    @Prop({default: ''}) private model!: string
    @Prop({default: false}) private readonly!: boolean
    @Prop({default: false}) private active!: boolean
    @Prop({default: '48px'}) private height!: string
    @Prop({default: '#F6F7F3'}) private background!: string
    @Prop({default: '#000'}) private color!: string
    @Prop({default: ''}) private dropdownPos!: string

    private id: string = Math.random().toString().substring(10)
    private myModel = this.model

    @Watch('model')
    private modelChange() {
        this.myModel = this.model
    }

    private closeDropdown() {
        this.toggleDropdown(false)
    }

    private toggleDropdown(val: boolean) {
        this.$emit('update:active', val)
    }

    @Emit('confirm')
    private confirm() {
        return {}
    }
}
