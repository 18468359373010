export default {
    temperature: "Температ..",
    humidity: "Влажнос..",
    wind: "Ветер",
    rainfall: "Осадки",
    directions: {
        north: "С",
        north_east: "С-В",
        east: "В",
        south_east: "Ю-В",
        south: "Ю",
        south_west: "Ю-З",
        west: "З",
        north_west: "С-З"
    }
}