










import { Component, Vue, Watch } from 'vue-property-decorator'
import { State, Getter, Action, Mutation, namespace } from 'vuex-class'
import MapContainer from './MapContainer/Index.vue'
import Panels from './Panels/Index.vue'
import SideboardBase from '@/components/SideboardBase.vue'
import { GetterNames, MutationNames, ActionNames } from '@/store/types'
import i18n from '@/i18n'
@Component({
    components: {
        MapContainer,
        SideboardBase,
        Panels
    }
})
export default class HomeView extends Vue {
    @Getter(GetterNames.IS_SIGNED_IN) private isSignedIn!: boolean
    @Getter(GetterNames.getGuideTourActivators) private getGuideTourActivators!: any

    private mounted() {
        if (this.isSignedIn) {
            if (this.$router.currentRoute.name === 'home') {
                this.$router.push('/cadastres').catch(err => {/* */})
            }
        }
    }
}
