import { render, staticRenderFns } from "./FertilizerResult.vue?vue&type=template&id=2a47f97c&scoped=true&"
import script from "./FertilizerResult.vue?vue&type=script&lang=ts&"
export * from "./FertilizerResult.vue?vue&type=script&lang=ts&"
import style0 from "./FertilizerResult.vue?vue&type=style&index=0&id=2a47f97c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a47f97c",
  null
  
)

export default component.exports