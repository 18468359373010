







































import { Component, Vue } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import { GetterNames, MutationNames } from '@/store/types'

@Component
export default class RulerButton extends Vue {
    @Getter(GetterNames.getRulerMode) private getRulerMode!: any
    @Mutation(MutationNames.setRulerMode) private setRulerMode!: any

    private mounted() {
        this.setRulerMode({active: false, isDistance: true, isArea: false, isPoint: false})
    }

}
