















































import { Vue, Component } from "vue-property-decorator"
import { httpGet } from '@/util/http'
import { apiUrl } from '@/domain/constants'
import { Mutation } from "vuex-class"
import { MutationNames } from "@/store/types"
@Component
export default class ClaasStatistics extends Vue {
    @Mutation(MutationNames.setLoadingDataNames) private setLoadingDataNames!: any
    private isShown = false
    private dataList: any = []

    private statisticsDetail(id: any) {
        this.setLoadingDataNames('setLoadingDataNames')
        httpGet({
            url: `${apiUrl}/yields/claas/get/workbooks/${id}`,
            isSecureRequest: true,
            onSuccess: json => {
                console.log(json)
                this.dataList = json
            },
            onError: error => {
                console.log(error)
            },
            doFinally: () => {
                this.setLoadingDataNames('setLoadingDataNames')
            }
        })
    }

    private show(id: any) {
        this.isShown = true
        this.statisticsDetail(id)
    }

    private hide() {
        this.isShown = false
    }
}
