export default {
    title: "Автопарк",
    machines: "Техникалар",
    devices: "Агрегаттар",
    add_machine: {
        title: "техника",
        class: "Марка",
        model: "Модель",
        state_num: "Мемлекеттік нөмірі",
        IMEI: "IMEI",
        capacity: "Бак сыйымдылығы (л)",
        capacity_short: "Сыйымдылығы",
        consumption: "Жанармай шығыны (л/га)",
        photo: "Сурет",
        category: {
            title: "Категория",
            categories: ["Тракторлар", "Комбайндар", "Өздігінен жүретін шөп шапқыш", "Жемшөп жинайтын комбайндар", "Өздігінен жүретін себелеуіштер"]
        }
    },
    add_record: {
        title: " ",
        type_placeholder: "Көріністі таңдаңыз",
        type: "Техникалық қызмет көрсету түрі",
        repair_stage: "Жөндеу кезеңі",
        repair_stage_placeholder: "Жөндеу кезеңін таңдаңыз",
        consumables: "Шығын материалдары мен бөлшектер",
        status: "Мәртебе",
        status_placeholder: "Мәртебені таңдаңыз",
        date_range: "Ұзақтық",
        mileage: "Жүгіріс",
        mileage_placeholder: "Жүгірісті енгізіңіз",
        hours: "Мотосағаттар",
        hours_placeholder: "Мотосағаттарды енгізіңіз",
        sum: "Жалпы құны",
        sum_placeholder: "Жалпы құнын енгізіңіз",
        note: "Аңғарту",
        errors: {
            type: "Техникалық қызмет көрсету түрін таңдаңыз",
            repair_stage: "Жөндеу кезеңін таңдаңыз",
            sum: "Жалпы құнын енгізіңіз",
            date_range: "Ұзақтығын толтырыңыз",
            no_records: "Сізде көкейтесті жазбалар жоқ"
        }
    },
    edit_machine: "Техниканы өзгерту",
    add_device: {
        title: "Агрегат",
        name: "Аты",
        width: "Қамту ені (м)",
        photo: "Сурет"
    },
    edit_device: "Агрегатты өзгерту",
    device_category: "Категория агрегата"
}