













import i18n from '@/i18n'
import { Component, Vue, Prop, Watch} from 'vue-property-decorator'
import Datepicker from 'vuejs-datepicker'
import { ru } from 'vuejs-datepicker/dist/locale'
import {Mutation} from "vuex-class"
import {MutationNames} from "@/store/types"
@Component({
    components: {
        Datepicker
    }
})
export default class DatePicker extends Vue {
    @Prop({default: i18n.t('general.dates.date')}) private headerText !: string
    @Prop({default: '210px'}) private modelWidth!: any
    @Prop({default: false}) private leftPosition!: any
    @Prop({default: ''}) private propDate!: any
    @Prop({default: false}) private hideIcon!: boolean
    @Prop({default: false}) private hideHeader!: boolean
    @Prop({default: 'date_picker-activator'}) private divID!: any
    @Prop({default: false}) private disableDateNow!: boolean

    @Mutation(MutationNames.setNormalDateRound) private setNormalDateRound!: any
    private ru = ru
    private error = ''
    private date = ''

    private disableTimeFrom: any = {
        from: new Date(Date.now())
    }

    private mounted() {
        if ( this.propDate !== '' ) {
            this.setDate(this.propDate)
        }
    }

    private disabledBeforeTodayAndAfterAWeek(date: any) {
        const today = new Date()
        today.setHours(0, 0, 0, 0)

        return date < today || date > new Date(today.getTime() + 7 * 24 * 3600 * 1000)
    }

    @Watch('date')
    private watchDate(val: any) {
        const dateNew = this.formatDate(val)
        this.setNormalDateRound(dateNew)
    }

    private formatDate(date: any) {
        const d = new Date(date)
        let month = '' + (d.getMonth() + 1)
        let day = '' + d.getDate()
        const year = d.getFullYear()

        if (month.length < 2) {
            month = '0' + month
        }
        if (day.length < 2) {
            day = '0' + day
        }

        return [year, month, day].join('-')
        // return d.toLocaleDateString('sv-SE')
    }

    @Watch('propDate')
    private checkonchange(val: any, old: any) {
        if (val && val !== old) {
            this.setDate(this.propDate)
        }
    }

    private setDate(date: any) {
        this.date = date
    }

    private normalizeDate() {
        if (this.date) {
            const date = new Date(this.date)
            const day = date.getDate()
            const month = date.getMonth() + 1
            const year = date.getFullYear()
            return `${day < 10 ? '0' : ''}${day}.${month < 10 ? '0' : ''}${month}.${year}`
        } else {
            return i18n.t('general.dates.format_date')
        }
    }

    private getDate() {
        if (this.date) {
            const date = new Date(this.date)
            const day = date.getDate()
            const month = date.getMonth() + 1
            const year = date.getFullYear()
            return `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`
        } else return ''
    }

    private opendatepicker() {
        const date = this.$refs.selectdate as any
        if (date) {
            date.showCalendar()
        }
    }

    private closeCalendar() {
        const calendar = this.$refs.selectdate as any
        if (calendar) {
            if (calendar.isOpen) {
                calendar.showCalendar()
            }
        }
    }

    private setError(e: any) {
        this.error = e
    }

}
