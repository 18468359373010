




















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Getter, Mutation} from 'vuex-class'
import { GetterNames, MutationNames } from '@/store/types'
@Component
export default class WeatherMap extends Vue {
    public $refs!: {
        wmsLayer: any,
        wmsSource: any
    }
    @Prop({default: null}) private map!: any
    @Getter(GetterNames.getCurrentRoute) private currentRoute !: any
    @Getter(GetterNames.getChroniclePanel) private getChroniclePanel!: any
    @Mutation(MutationNames.setIsDataLoading) private setIsDataLoading!: any
    private loadCounter = 0
    private indicatorsTip: any = {
        active: false,
        value: '',
        coordinates: [0, 0]
    }

    @Watch('getChroniclePanel.status')
    private onchangeStatus(val: any, old: any) {
        const map = this.map.$map
        if (val) {
            map.on('click', (evt: any) => {
                if (this.getChroniclePanel.status && this.loadCounter === 0) {
                    const wmsLayer = map.forEachLayerAtPixel(evt.pixel, (layer: any) => {
                        if (layer.values_.id === 67154543) {
                            return layer
                        }
                    })
                    if (wmsLayer) {
                        const url = wmsLayer.getSource().getGetFeatureInfoUrl(
                            evt.coordinate,
                            map.getView().getResolution(),
                            map.getView().getProjection(),
                            {
                                'INFO_FORMAT': 'application/json',
                                'EXCEPTIONS': 'application/vnd.ogc.se_xml'
                            }
                        )
                        if (url) {
                            fetch(url).then((response: any) => {
                                return response.text()
                            }).then((featureCollections: any) => {
                                const json = JSON.parse(featureCollections)
                                if (json) {
                                    const x = json.features[0].properties.GRAY_INDEX
                                    this.indicatorsTip.active = true
                                    if (this.getChroniclePanel.indicator === 'temperature') {
                                        this.indicatorsTip.value = `${Math.round(((x - 1) / 65534 * 150 - 90) * 10) / 10}°C`
                                    } else if (this.getChroniclePanel.indicator === 'precipitation') {
                                        this.indicatorsTip.value = `${Math.round(((x - 1) / 65534 * 100) * 100) / 100}мм`
                                    } else if (this.getChroniclePanel.indicator === 'cloudiness') {
                                        this.indicatorsTip.value = `${Math.round(x / 65535 * 100)}%`
                                    } else if (this.getChroniclePanel.indicator === 'pressure') {
                                        this.indicatorsTip.value = `${Math.round(((x - 1) / 65534 * 255 + 880) * 7.5) / 10}мм`
                                    }
                                    this.indicatorsTip.coordinates = evt.coordinate
                                }
                            })
                        }
                    }
                }
            })
        } else {
            map.un('click', () => {
                this.indicatorsTip.active = false
            })
        }
    }

    @Watch('getChroniclePanel.layer')
    private onchangeLayer(val: any, old: any) {
        if (val && val !== old) {
            if (this.$refs.wmsSource) {
                this.$refs.wmsSource.refresh()
            }
            this.indicatorsTip.active = false
        }
    }

    @Watch('getChroniclePanel.indicator')
    private onchangeIndicator(val: any, old: any) {
        if (val && val !== old) {
            if (this.$refs.wmsSource) {
                this.$refs.wmsSource.refresh()
            }
            this.indicatorsTip.active = false
        }
    }

    @Watch('loadCounter')
    private onchangeLoadCounter(val: any, old: any) {
        if (val !== null && val !== old) {
            if (val === 0) {
                this.setIsDataLoading(false)
            } else {
                this.setIsDataLoading(true)
            }
        }
    }

    private onLoadStart() {
        this.loadCounter++
    }

    private onLoadEnd() {
        this.loadCounter--
    }
}
