import Vue from 'vue'
import Vuex from 'vuex'
import GeneralModule from './modules/general'
import AuthModule from './modules/auth'
import CadastreModule from './modules/cadastres'
import MapDataModule from './modules/mapData'
import FieldsModule from './modules/fields'
import PanelsModule from './modules/panels'
import WorkspaceModule from './modules/workspace'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    GeneralModule,
    AuthModule,
    CadastreModule,
    MapDataModule,
    FieldsModule,
    PanelsModule,
    WorkspaceModule
  }
})

const getModule = (ModuleName: string) => (state: any) => state[ModuleName]

export const GeneralStore = getModule('GeneralModule')
export const AuthStore = getModule('AuthModule')
export const CadastreStore = getModule('CadastreModule')
export const MapDataStore = getModule('MapDataModule')
export const FieldsStore = getModule('FieldsModule')
export const PanelsStore = getModule('PanelsModule')
export const WorkspaceStore = getModule('WorkspaceModule')
