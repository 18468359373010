










































import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import {
    clgreenGistogram,
    ndviGistogram,
    ndmiGistogram,
    ndviRanges,
    ndmiRanges,
    ndviContrastedGistogram
} from '@/data/gistogramData'
import { GetterNames, ActionNames, MutationNames } from '@/store/types'
import { Getter, Action, Mutation } from 'vuex-class'
import i18n from '@/i18n'

@Component
export default class Gistogram extends Vue {
    @Action(ActionNames.fetchDividedLegends) private fetchDividedLegends !: any
    @Getter(GetterNames.getSubfieldIndicatorData) private getSubfieldIndicatorData!: any
    @Mutation(MutationNames.changeLegendDividingMode) private changeLegendDividingMode!: any
    @Prop() private gistogramData!: any
    @Prop({default: false}) private vertical!: boolean
    @Prop()
    private type!: string
    private rangeNumbers = { clgreen: this.createRangeObject('-∞', '3', '7'), ndvi: this.createRangeObject('-1', '0.35', '1'), contrasted_ndvi: this.createRangeObject('-1', '', '0.54'),
                             gndvi: this.createRangeObject('-1', '0.35', '1'), ndmi: this.createRangeObject('-∞', '0', '1') }
    private ndviRanges = ndviRanges
    private ndmiRanges = ndmiRanges
    private selectedColorIndex: number | boolean = false
    private contrastedNumbers: any = []

    private getRequestById(requestId: number) {
        return this.getSubfieldIndicatorData.data.find((x: any) => requestId === x.id)
    }

    private fetchSelectedColorChanged(type: string, index: number, from: number, to: number) {
        if (!this.getRequestById(this.getSubfieldIndicatorData.currentRequestId).is_present) return
        this.selectedColorIndex = index
        if (from < -10000) from = -2
        const requestBody = {
            celery_request: this.getSubfieldIndicatorData.currentRequestId,
            _from: from,
            _to: to,
            target: type
        }
        // this.fetchDividedLegends(requestBody)
    }
    private createRangeObject(start: string, center: string, end: string) {
        return {start, center, end}
    }

    private mounted() {
        this.getContrastedNdviRange()
    }

    @Watch('getSubfieldIndicatorData.currentRequestId')
    private getContrastedNdviRange() {
        if (this.type === 'contrasted_ndvi') {
            const currentContrastedData = this.getRequestById(this.getSubfieldIndicatorData.currentRequestId).contrasted_ndvi
            if (!currentContrastedData || !currentContrastedData.levels) return
            this.rangeNumbers.contrasted_ndvi = {
                center: '',
                start: (Math.round(currentContrastedData.min * 100) / 100).toString(),
                end: (Math.round(currentContrastedData.max * 100) / 100).toString()
            }
        }
    }

    private getTextsOfType(type: string) {
        if (type === 'clgreen') {
            return i18n.t('headers.workspace.fields.indicators.indicators.chlorophyll.text')
        }
        if (type === 'ndvi') {
            return i18n.t('headers.workspace.fields.indicators.indicators.vegetation.text')
        }
        if (type === 'contrasted_ndvi') {
            return i18n.t('headers.workspace.fields.indicators.indicators.vegetation.text')
        }
        if (type === 'ndmi') {
            return i18n.t('headers.workspace.fields.indicators.indicators.humidity.text')
        }
        if (type === 'gndvi') {
            return i18n.t('headers.workspace.fields.indicators.indicators.nitrogen.text')
        }
    }

    private getRangeText(color: any, ranges: any[] ) {
        let bestFit !: any
        for (const range of ranges) {
            if (!color) return 'not found'
            if (range.from <= color.to && range.to >= color.from) { // if they overlap
                const overlap = (color.to - range.from) + (range.from - color.from)
                if (bestFit != null) {
                    if (bestFit.d < overlap) {
                        bestFit.d = overlap
                        bestFit.text = range.text
                    }
                } else {
                    bestFit = {
                        d: overlap,
                        text:  range.text
                    }
                }
            }
        }
        return bestFit ? bestFit.text : 'not found'
    }
}
