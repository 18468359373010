import { IState } from './'

export type State = IState

export const Action = {
    ON_SIGN: 'ON_SIGN',
    LOG_OUT: 'LOG_OUT'
}

export const Mutation = {
    SET_SIGNED_IN: 'SET_SIGNED_IN'
}

export const Getter = {
    IS_SIGNED_IN: 'IS_SIGNED_IN'
}
